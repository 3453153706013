import { DrillCard } from "@/modules/shared/components";
import { TabGroup, Tabs } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssDrillStepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	overflow-y: hidden;
	height: 100%;
`;

export const CssDrillStepHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 12px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
`;

export const CssTabsWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			padding: 0 24px;
		}
	`
);

export const CssTabs = styled(Tabs)(
	({ theme }) => css`
		flex: 1 1 0%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	`
);

export const CssTabGroup = styled(TabGroup)(
	({ theme }) => css`
		& > .TabGroupContainer {
			justify-content: space-evenly;
		}

		${theme.breakpoints.up("desktopMin")} {
			& > .TabGroupContainer {
				justify-content: flex-start;
			}
		}
	`
);

export const CssListWrapper = styled.div(
	({ theme }) => css`
		padding: 16px 12px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 24px;
		}
	`
);

export const CssDrillCardWithHover = styled(DrillCard)(
	({ theme }) => css`
		transition-property: transform;
		transition-duration: var(--hover-transition-duration);
		transition-timing-function: var(--hover-transition-curve);

		& .drill-card--inner-wrapper {
			transition-property: background;
			transition-duration: var(--hover-transition-duration);
			transition-timing-function: var(--hover-transition-curve);
		}

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);

				& .drill-card--inner-wrapper {
					background: var(--hover-background);
				}
			}
		}
	`
);
