import { analyticsService, persistentStorageService } from "@/dependencies";
import { useServerState } from "@/modules/core/react-query/hooks";
import { MARKET_ROUTES } from "@/modules/market";
import { useGlobalState } from "@/modules/shared/context";
import { PLAYERS_QUERIES } from "@/modules/shared/queries";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ONBOARDING_MODAL_STATE = "ONBOARDING_MODAL_STATE";

export type TransakCurrency = "MSU" | "MATIC";

export const useOnboardingModal = () => {
	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;

	const { address, isConnected, selectedTeam } = useGlobalState();

	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);
	const isModalClosedInLocalStorage = persistentStorageService.getObject<boolean>(ONBOARDING_MODAL_STATE);

	const { data: players, isLoading: isLoadingPlayers } = useServerState(
		PLAYERS_QUERIES.getPlayersByTeamId(selectedTeam?.id),
		{ enabled: Boolean(selectedTeam?.id) }
	);

	useEffect(() => {
		if (!isConnected || isLoadingPlayers) {
			return;
		}
		if ((!players || players.length === 0) && !isOpen && isModalClosedInLocalStorage === null) {
			toggleModal();
		}
	}, [isConnected, players, isOpen, isLoadingPlayers]);

	const toggleModal = () => {
		if (isOpen && isModalClosedInLocalStorage === null) {
			persistentStorageService.save(ONBOARDING_MODAL_STATE, "true");
		}
		setIsOpen((prevState) => !prevState);
	};

	const onMarketClick = (category?: string) => {
		analyticsService.sendEvent("goToMarketFromTutorial", {
			wallet: "wallet:" + address?.toLowerCase(),
			category
		});
		navigate(MARKET_ROUTES.ROOT.buildLongPath());
	};

	return {
		isOpen: !isPlayToAirdropEnabled && isOpen,
		toggleModal,
		onMarketClick
	};
};
