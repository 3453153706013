import { FC } from "react";
import { CssTypography } from "./TournamentStartingDate.styled";

interface TournamentStartingDateProps {
	isLive: boolean;
	startsIn: string;
	endsIn: string;
	variant?: "description" | "captionBold";
	color?: string;
}

export const TournamentStartingDate: FC<TournamentStartingDateProps> = ({
	isLive,
	startsIn,
	endsIn,
	variant = "description",
	color
}) => {
	return (
		<CssTypography variant={variant} color={color}>
			{!isLive && `Starts in ${startsIn}`}
			{isLive && `Ends in ${endsIn}`}
		</CssTypography>
	);
};
