import { TournamentDivisionSimplified } from "@metasoccer/metasoccer-types";
import { FC, useState } from "react";
import { CssPrizePoolWrapper, CssTitle } from "./PrizePool.styled";
import { PrizePoolTabs, PrizePoolValues } from "./components";

interface PrizePoolProps {
	divisions: TournamentDivisionSimplified[];
	selectedDivision?: string;
	hideTitle?: boolean;
}

export const PrizePool: FC<PrizePoolProps> = ({ divisions, selectedDivision, hideTitle = false }) => {
	const [division, setDivision] = useState(divisions.find((div) => div.id === selectedDivision) || divisions[4]);

	const onSelectTab = (index: number) => {
		setDivision(divisions[index]);
	};

	return (
		<CssPrizePoolWrapper hideTitle={hideTitle}>
			{!hideTitle && <CssTitle variant="h6">PRIZE POOL</CssTitle>}
			<PrizePoolTabs divisions={divisions} onSelectTab={onSelectTab} selectedTab={divisions.indexOf(division)} />
			<PrizePoolValues rewards={division.rewards} />
		</CssPrizePoolWrapper>
	);
};
