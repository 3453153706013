import { useHomeState } from "@/modules/home/context";
import { TapbarsLayout } from "@/modules/shared/components/TapbarsLayout";
import { Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { memo, useState } from "react";
import { useLocalStorage } from "react-use";
import { WrapperStyled } from "./TournamentActivity.styles";
import { Matches } from "./components/Matches";
import { Standings } from "./components/Standings";
import { TournamentSelector } from "./components/TournamentSelector";

enum TabOptions {
	MATCHES = "matches",
	STANDINGS = "standings"
}

export const TournamentActivity = memo(() => {
	const { groupOfTeam, groupOfTopMSL } = useHomeState();

	const groupInfo = groupOfTeam ?? groupOfTopMSL;

	const [selectedTournament, setSelectedTournament] = useState(groupInfo?.tournamentEditionGroupId);
	const [showMyMatchesOnly, setShowMyMatchesOnly] = useLocalStorage(
		"home.tournamentActivity.showMyMatchesOnly",
		false
	);

	return (
		<WrapperStyled>
			{groupInfo ? (
				<TapbarsLayout
					tabs={[
						{
							key: TabOptions.MATCHES,
							title: "MATCHES",
							render: () => (
								<Matches groupInfo={groupInfo} showMyMatchesOnly={showMyMatchesOnly ?? false} />
							)
						},
						{
							key: TabOptions.STANDINGS,
							title: "STANDINGS",
							render: () => <Standings groupInfo={groupInfo} />
						}
					]}
					tabsSize="md"
					BottomComponent={
						groupInfo ? (
							<TournamentSelector
								tournaments={[groupInfo]}
								selectedTournamentId={selectedTournament}
								showMyMatchesOnly={showMyMatchesOnly ?? false}
								onShowMyMatchesOnlyChange={setShowMyMatchesOnly}
								onTournamentIdSelected={setSelectedTournament}
							/>
						) : undefined
					}
				/>
			) : (
				<Row alignItems="center" justifyContent="center" style={{ height: "100%", padding: 24 }}>
					<Col gap={8} alignItems="center">
						<Typography textAlign="center" variant="h5">
							MATCHES & STANDINGS
						</Typography>
						<Typography textAlign="center" variant="caption">
							You have to join and start playing a community tournament to see content in this section.
						</Typography>
					</Col>
				</Row>
			)}
		</WrapperStyled>
	);
});
