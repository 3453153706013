import { ConfigService } from "@/modules/shared/services";
import { BlockchainService } from "@/modules/shared/services/Blockchain/BlockchainService";
import ContractDefinitions from "@metasoccer/contracts/types/index.json";
import { NetworkContracts } from "@metasoccer/metasoccer-types";

export class BlockchainServiceFactory {
	public static make(configService: ConfigService): BlockchainService {
		for (let i = 0; i < ContractDefinitions.length; i++) {
			const networkContracts: NetworkContracts = ContractDefinitions[i];
			if (networkContracts.chainId === configService.get().chainId) {
				return new BlockchainService(networkContracts);
			}
		}

		throw new Error(`Cannot find NetworkContracts for Chain Id <${configService.get().chainId}>`);
	}
}
