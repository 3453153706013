import { TypographyProps } from "@metasoccer/metasoccer-ds";
import { ReactElement, ReactNode, memo } from "react";
import { GrowStyled, WrapperStyled } from "./FixedAndGrowLayout.styles";

export interface FixedAndGrowLayoutProps {
	alignItems?: string;
	children: [ReactElement<TypographyProps>, ReactNode];
	enableGrow?: boolean;
	gap?: number;
	overflow?: string;
	reverse?: boolean;
}

export const FixedAndGrowLayout = memo<FixedAndGrowLayoutProps>(
	({ alignItems, children, enableGrow = true, gap, overflow, reverse }) => {
		return (
			<WrapperStyled alignItems={alignItems} gap={gap} reverse={reverse}>
				{children[0]}
				{enableGrow ? <GrowStyled overflow={overflow}>{children[1]}</GrowStyled> : children[1]}
			</WrapperStyled>
		);
	}
);
