import { Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";

interface ColumnHeaderProps {
	title: string;
}

export const ColumnHeader: FC<ColumnHeaderProps> = ({ title }) => {
	return <Typography variant={"body2"}>{title}</Typography>;
};
