export default function getRelativeDate(date: Date): string {
	const now = new Date();
	const diff = date.getTime() - now.getTime();
	const dayInMs = 1000 * 60 * 60 * 24;

	if (diff >= -dayInMs && diff < 0) {
		return "Today";
	} else if (diff >= -dayInMs * 2 && diff < 0) {
		return "Yesterday";
	} else if (diff > 0 && diff <= dayInMs) {
		return "Tomorrow";
	} else if (diff > dayInMs && diff <= dayInMs * 2) {
		return "Day after tomorrow";
	} else if (diff < 0) {
		const daysAgo = Math.ceil(-diff / dayInMs) - 1;
		return `${daysAgo} days ago`;
	} else {
		const daysAhead = Math.floor(diff / dayInMs) + 1;
		return `${daysAhead} days ahead`;
	}
}
