import { playerService } from "@/dependencies";
import { BaseServerStateKeys } from "@/modules/shared/constants";

export const PLAYERS_QUERIES = {
	getPlayersByOwner: (owner: string) => ({
		queryKey: [BaseServerStateKeys.PLAYERS, owner],
		queryFn: () => playerService.getPlayersByOwner(owner)
	}),
	getPlayersByTeamId: (teamId: string) => ({
		queryKey: [BaseServerStateKeys.PLAYERS, teamId],
		queryFn: () => playerService.getPlayersByTeamId(teamId)
	}),
	getPlayersWithShirtNumbersByTeamId: (teamId: string) => ({
		queryKey: [BaseServerStateKeys.PLAYERS_WITH_SHIRT_NUMBER, teamId],
		queryFn: () => playerService.getPlayersWithShirtNumbersByTeamId(teamId)
	}),
	getPlayersByIds: (playerIds: number[]) => ({
		queryKey: [BaseServerStateKeys.PLAYERS, playerIds.join("-")],
		queryFn: () => playerService.getPlayersByIds(playerIds)
	}),
	getLockedPlayersByTeamId: (teamId: string) => ({
		queryKey: [BaseServerStateKeys.LOCKED_PLAYERS, teamId],
		queryFn: () => playerService.getLockedPlayersByTeamId(teamId)
	}),
	getPlayerCardsByTeamId: (teamId: string) => ({
		queryKey: [BaseServerStateKeys.PLAYER_CARDS, teamId],
		queryFn: () => playerService.getPlayerCardsByTeamId(teamId)
	})
};
