import { useIsMobile } from "@/layouts/context";
import { Typography } from "@metasoccer/metasoccer-ds";
import { InjuredLockedPlayer, PvpTeamInfo } from "@metasoccer/metasoccer-types";
import { FC, useState } from "react";
import { ToggleButton } from "../../../ToggleButtonGroup/ToggleButton";
import {
	CssLastLineupsWrapper,
	CssLineupTablesWrapper,
	CssMatchSquadsWrapper,
	CssMobileLineupWrapper,
	CssToggleButtonWrapper,
	NextMatchPreviewStyled
} from "./MatchSquads.styled";
import { LastLineupTable } from "./components";

interface MatchSquadsProps {
	home: PvpTeamInfo;
	away: PvpTeamInfo;
	notices: string[];
	date: number;
	injuredPlayers?: InjuredLockedPlayer[];
}

enum ActiveTab {
	HOME = "HOME",
	AWAY = "AWAY"
}

export const MatchSquads: FC<MatchSquadsProps> = ({ home, away, date, notices, injuredPlayers }) => {
	const isMobile = useIsMobile();
	const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.HOME);
	return (
		<CssMatchSquadsWrapper>
			<NextMatchPreviewStyled
				matchDate={date}
				homeTeamInfo={home}
				awayTeamInfo={away}
				size={"md"}
				notices={notices}
			/>
			<CssLastLineupsWrapper>
				{!isMobile && <Typography variant="h6">Registered Players</Typography>}
				{!isMobile && (
					<CssLineupTablesWrapper>
						<LastLineupTable team={home} injuredPlayers={injuredPlayers} />
						<LastLineupTable team={away} injuredPlayers={injuredPlayers} />
					</CssLineupTablesWrapper>
				)}
				{isMobile && (
					<CssMobileLineupWrapper>
						<CssToggleButtonWrapper>
							<ToggleButton
								isSelected={activeTab === ActiveTab.HOME}
								onClick={() => setActiveTab(ActiveTab.HOME)}>
								{home.teamName}
							</ToggleButton>
							<ToggleButton
								isSelected={activeTab === ActiveTab.AWAY}
								onClick={() => setActiveTab(ActiveTab.AWAY)}>
								{away.teamName}
							</ToggleButton>
						</CssToggleButtonWrapper>
						{activeTab === ActiveTab.HOME && (
							<LastLineupTable team={home} injuredPlayers={injuredPlayers} />
						)}
						{activeTab === ActiveTab.AWAY && (
							<LastLineupTable team={away} injuredPlayers={injuredPlayers} />
						)}
					</CssMobileLineupWrapper>
				)}
			</CssLastLineupsWrapper>
		</CssMatchSquadsWrapper>
	);
};
