import { Copyable } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { ClubLogo, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag, TournamentDetailedStandingRow } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { FC } from "react";
import {
	CssClubContent,
	CssLeftPartWrapper,
	CssPositionTypography,
	CssRightPartWrapper,
	CssStandingRowContent,
	CssStandingRowWrapper
} from "./StandingRow.styled";

interface StandingRowProps {
	borderColor?: string;
	rankedTeam: TournamentDetailedStandingRow;
}

export const StandingRow: FC<StandingRowProps> = ({ borderColor, rankedTeam }) => {
	const adminFeaturesEnabled = useFlag(FeatureFlag.AdminFeatures);

	const { selectedTeam } = useGlobalState();

	const isMyTeam = selectedTeam?.id === rankedTeam.id;

	return (
		<CssStandingRowWrapper borderColor={borderColor}>
			<CssStandingRowContent isMyTeam={isMyTeam}>
				<CssLeftPartWrapper>
					<CssPositionTypography variant="body2">{rankedTeam.position}</CssPositionTypography>
					<CssClubContent>
						<ClubLogo
							color={rankedTeam.clubLogocolor}
							name={rankedTeam.clubLogoName}
							url={rankedTeam.clubLogoUrl}
							size="xs"
						/>
						<Typography variant="body2">{rankedTeam.clubName}</Typography>
						{adminFeaturesEnabled && <Copyable variant="body2" value={rankedTeam.id} />}
					</CssClubContent>
				</CssLeftPartWrapper>
				<CssRightPartWrapper>
					<Typography variant="body2">{rankedTeam.points}</Typography>
				</CssRightPartWrapper>
			</CssStandingRowContent>
		</CssStandingRowWrapper>
	);
};
