import { HttpClientFactory } from "@/modules/core/http/HttpClientFactory";
import { LineupWithSkillService } from "@/modules/lineup/services";
import { getEnvFromDomain } from "@/modules/shared/helpers";
import {
	AnalyticsServiceFactory,
	AuthService,
	BlockchainServiceFactory,
	ConfigService,
	InGameTokenService,
	KoTournamentService,
	OnBoardingService,
	PlayerService,
	RewardService,
	ScoutService,
	StorageService,
	TournamentService
} from "@/modules/shared/services";
import { TeamService } from "@/modules/team";
import { AccountService } from "./modules/account/services";

// Resolve the environment first
const developmentHostname = process.env.REACT_APP_DEV_HOSTNAME || "";
const productionHostname = process.env.REACT_APP_PROD_HOSTNAME || "";
const env = getEnvFromDomain(developmentHostname, productionHostname);

export const configService = new ConfigService(env);
export const httpService = HttpClientFactory.create(configService);

export const accountService = new AccountService(httpService);
export const analyticsService = AnalyticsServiceFactory.make(configService);
export const authService = new AuthService(httpService);
export const blockchainService = BlockchainServiceFactory.make(configService);
export const inGameTokenService = new InGameTokenService(httpService);
export const koTournamentService = new KoTournamentService(httpService);
export const lineupWithSkillService = new LineupWithSkillService(httpService);
export const onBoardingService = new OnBoardingService(httpService);
export const persistentStorageService = new StorageService();
export const playerService = new PlayerService(httpService);
export const rewardsService = new RewardService(httpService);
export const scoutService = new ScoutService(httpService);
export const teamService = new TeamService(httpService, persistentStorageService);
export const tournamentService = new TournamentService(httpService);
export const volatileStorageService = new StorageService("sessionStorage");
