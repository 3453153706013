import styled, { css, keyframes } from "styled-components";

const blinkAnimation = keyframes`
	0% { opacity: 0.68 }
    100% { opacity: 1 }
`;

const growAnimation = keyframes`
	0% { opacity: 1; transform: scale3d(1.2, 1.2, 1); }
    100% { opacity: 0.3; transform: scale3d(2, 2, 1); }
`;

export const CssRedDotWrapper = styled.div(
	({ theme }) => css`
		position: relative;
		width: 12px;
		height: 12px;
		overflow: hidden;
	`
);

export const CssInnerDotWrapper = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 2;

		display: flex;
		justify-content: center;
		align-items: center;
	`
);

export const CssInnerDot = styled.div(
	({ theme }) => css`
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: ${theme.palette.system.amaranth};
		animation: ${blinkAnimation} 1200ms infinite alternate;
	`
);

export const CssOuterDotWrapper = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		display: flex;
		justify-content: center;
		align-items: center;
	`
);

export const CssOuterDot = styled.div(
	({ theme }) => css`
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: ${theme.palette.system.amaranth};
		animation: ${growAnimation} 1s infinite alternate;
	`
);
