import { BaseServerStateKeys } from "@/modules/shared/constants";
import { airdropService } from "../dependencies";

export const AIRDROP_QUERIES = {
	getAvailableBoxes: () => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getAvailableBoxes"],
		queryFn: () => airdropService.getAvailableBoxes()
	}),
	getPass: (address: string) => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getPass"],
		queryFn: () => airdropService.getPass(address)
	}),
	getRanking: () => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getRanking"],
		queryFn: () => airdropService.getRanking()
	}),
	getStarterBox: (address: string) => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getStarterBox"],
		queryFn: () => airdropService.getStarterBox(address)
	}),
	getStarterBoxClaim: (address: string) => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getStarterBoxClaim"],
		queryFn: () => airdropService.getStarterBoxClaim(address)
	}),
	getTasks: () => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getTasks"],
		queryFn: () => airdropService.getTasks()
	}),
	getSuspiciousAddresses: (address: string) => ({
		queryKey: [BaseServerStateKeys.AIRDROP, "getSuspiciousAddresses"],
		queryFn: () => airdropService.getSuspiciousAddress(address)
	})
};
