import { useTheme } from "@metasoccer/metasoccer-ds";
import { ConnectEmbed as ConnectWalletThirdWeb, darkTheme } from "@thirdweb-dev/react";
import { WrapperStyled } from "./ConnectWallet.styles";

export function ConnectWallet() {
	const theme = useTheme();

	return (
		<WrapperStyled>
			<ConnectWalletThirdWeb
				privacyPolicyUrl={"https://metasoccer.com/privacy-policy"}
				termsOfServiceUrl={"https://metasoccer.com/terms-of-service"}
				showThirdwebBranding={false}
				theme={darkTheme({
					colors: {
						accentButtonBg: theme.palette.system.cornflowerBlue,
						accentText: theme.palette.system.cornflowerBlue,
						danger: theme.palette.system.amaranth,
						success: theme.palette.system.pastelGreen
					},
					fontFamily: theme.fonts?.spaceGrotesk
				})}
			/>
		</WrapperStyled>
	);
}
