import { Container, FixedAndGrowLayout } from "@/modules/shared/components";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { ImageStyled } from "./MSU.styled";
import MSUExample from "./assets/MSU.png";

export const MSU = () => {
	return (
		<Container height="100%" paddingY={32}>
			<FixedAndGrowLayout alignItems="center" overflow="hidden">
				<Col alignItems="center" gap={16} style={{ maxWidth: "800px" }}>
					<Typography variant="h4">MSU</Typography>

					<Typography variant="body1" textAlign="center" light>
						The MSU is the token of the MetaSoccer Universe. Use it to scout players, enhance training, and
						upgrade up your stadium. Earn MSU through competition wins or trade it to scale up your club's
						game. Plus, MSU isn't just for the game - you´re free to trade or sell it, adding real stakes to
						your gameplay.
					</Typography>
				</Col>
				<ImageStyled src={MSUExample} alt="MSU" height="100%" />
			</FixedAndGrowLayout>
		</Container>
	);
};
