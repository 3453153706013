import { Divider } from "@/modules/shared/components";
import { MsuToken } from "@/modules/shared/components/tokens";
import { useBalanceContext } from "@/modules/shared/context/hooks";
import { Row, useTheme } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { AirdropRedeem } from "./AirdropRedeem";
import { WrapperStyled } from "./TopBarCoins.styles";

export const TopBarCoins = () => {
	const isAirdropGameplayPhase2Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase2);

	const { msuTokens } = useBalanceContext();

	const theme = useTheme();

	return (
		<WrapperStyled>
			<Row alignItems="center" gap={16}>
				{isAirdropGameplayPhase2Enabled && !msuTokens && (
					<>
						<AirdropRedeem />
						<Divider variant="vertical" />
					</>
				)}
				<MsuToken quantity={msuTokens} />
			</Row>
		</WrapperStyled>
	);
};
