import { httpService } from "@/dependencies";
import { useToast } from "@/modules/shared/hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

export const useReferrer = (address?: string) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const referrerId = searchParams.get("ref");
	const { showErrToast } = useToast();
	const [usedReferrer, setUsedReferrer] = useLocalStorage("usedReferrer", false);

	// Save referrer ID to local storage when the page loads
	useEffect(() => {
		if (referrerId) {
			localStorage.setItem("referrerId", referrerId);
		}
	}, [referrerId]);

	// Call referrer endpoint if the address is available, referrer ID is stored, and the referrer code has not been used
	useEffect(() => {
		const storedReferrerId = localStorage.getItem("referrerId");

		if (address && storedReferrerId && !usedReferrer) {
			httpService
				.post("/account/referrer", { referrerId: storedReferrerId })
				.then(() => {
					setUsedReferrer(true);
					searchParams.delete("ref");
					setSearchParams(searchParams);
				})
				.catch((err) => {
					showErrToast(err.message);
				});
		}
	}, [address, searchParams, setSearchParams, showErrToast, usedReferrer]);
};
