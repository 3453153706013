import { getPveNameByDifficulty } from "@/modules/play-match/helpers/getPveNameByDifficulty";
import { MatchScore } from "@/modules/shared/components";
import { LineupQualityStars } from "@/modules/shared/components/LineupQualityStars";
import { getByPveDifficultyLogo } from "@/modules/shared/helpers";
import { ClubLogo, useTheme } from "@metasoccer/metasoccer-ds";
import { LastTournamentMatch, PveDifficulty } from "@metasoccer/metasoccer-types";
import { FC, memo, useMemo } from "react";
import { CssTeamInfoContent, CssTeamInfoWrapper } from "./ScoreMatchResult.styled";
import { ScoreMatchResultTeam } from "./components";

interface ScoreMatchResultProps {
	match: LastTournamentMatch;
	showQualityLineupSkill?: boolean;
	isMatchResultLayout?: boolean;
}

export const ScoreMatchResult: FC<ScoreMatchResultProps> = memo(
	({ match, showQualityLineupSkill: showOverallLineupSkill = false, isMatchResultLayout = false }) => {
		const theme = useTheme();
		const pveDifficulty: PveDifficulty = match.pveDifficulty;
		const AwayTeamLogo = useMemo(
			() => getByPveDifficultyLogo(pveDifficulty, theme.palette, "xs"),
			[pveDifficulty, theme.palette]
		);

		return (
			<CssTeamInfoWrapper>
				{showOverallLineupSkill && (
					<LineupQualityStars quality={match.homeTeam.overall} qualityPosition="left" size="sm" />
				)}
				<CssTeamInfoContent showOverallLineupSkill={showOverallLineupSkill}>
					<ScoreMatchResultTeam
						teamName={match.homeTeam.name}
						clubLogo={
							<ClubLogo
								color={match.homeTeam.clubLogo.color}
								name={match.homeTeam.clubLogo.name}
								url={match.homeTeam.clubLogo.url}
								size="xs"
							/>
						}
					/>
					<MatchScore
						homeGoals={match.homeTeam.goals}
						awayGoals={match.awayTeam.goals}
						isMatchResultLayout={isMatchResultLayout}
						isFinished
					/>
					<ScoreMatchResultTeam
						teamName={getPveNameByDifficulty(pveDifficulty)}
						clubLogo={AwayTeamLogo}
						isReverseLayout
					/>
				</CssTeamInfoContent>
				{showOverallLineupSkill && (
					<LineupQualityStars quality={match.awayTeam.overall} qualityPosition="right" size="sm" />
				)}
			</CssTeamInfoWrapper>
		);
	}
);
