import { Container, FixedAndGrowLayout } from "@/modules/shared/components";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { ImageStyled } from "./Compete.styled";
import CompeteExample from "./assets/Compete.png";

export const Compete = () => {
	return (
		<Container height="100%" paddingY={32}>
			<FixedAndGrowLayout alignItems="center" overflow="hidden">
				<Col alignItems="center" gap={16} style={{ maxWidth: "800px" }}>
					<Typography variant="h4">Compete</Typography>

					<Typography variant="body1" textAlign="center" light>
						Compete in the MetaSoccer League across the Americas, Asia, and Europe, and go for the win to
						earn MSU rewards. Team up with friends to host your own tournaments, or dive into sponsored
						contests for a shot at awesome prizes. The pitch is calling – it's your time to shine!
					</Typography>
				</Col>
				<ImageStyled src={CompeteExample} alt="Compete" height="100%" />
			</FixedAndGrowLayout>
		</Container>
	);
};
