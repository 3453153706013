import fonts from "./fonts";
var typography = {
  h1: {
    component: "h1",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "6.25rem",
      minHeight: "6.25rem",
      lineHeight: "100%"
    }
  },
  h2: {
    component: "h2",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "4rem",
      minHeight: "4rem",
      lineHeight: "100%"
    }
  },
  h3: {
    component: "h3",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "3.25rem",
      minHeight: "3.25rem",
      lineHeight: "100%"
    }
  },
  h4: {
    component: "h4",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "2.25rem",
      minHeight: "2.25rem",
      lineHeight: "100%"
    }
  },
  h5: {
    component: "h5",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "1.625rem",
      minHeight: "1.625rem",
      lineHeight: "100%"
    }
  },
  h6: {
    component: "h6",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "1.375rem",
      minHeight: "1.375rem",
      lineHeight: "100%"
    }
  },
  subtitle1: {
    component: "h6",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "1.125rem",
      minHeight: "1.125rem",
      lineHeight: "100%"
    }
  },
  subtitle2: {
    component: "h6",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "1rem",
      minHeight: "1rem",
      lineHeight: "100%"
    }
  },
  subtitle2Link: {
    component: "h6",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "1rem",
      minHeight: "1rem",
      lineHeight: "100%",
      textDecoration: "underline"
    }
  },
  body1: {
    component: "p",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "400",
      fontSize: "1rem",
      minHeight: "1.3125rem"
    }
  },
  body1Bold: {
    component: "p",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      fontSize: "1rem",
      minHeight: "1.3125rem"
    }
  },
  body1Link: {
    component: "p",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontSize: "1rem",
      fontWeight: "400",
      minHeight: "1.3125rem",
      textDecoration: "underline"
    }
  },
  body2: {
    component: "p",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "400",
      fontSize: "0.875rem",
      minHeight: "1.125rem"
    }
  },
  body2Bold: {
    component: "p",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      fontSize: "0.875rem",
      minHeight: "1.125rem"
    }
  },
  body2Link: {
    component: "p",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "400",
      fontSize: "0.875rem",
      minHeight: "1.125rem",
      textDecoration: "underline"
    }
  },
  body3: {
    component: "p",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "0.875rem",
      minHeight: "0.875rem",
      lineHeight: "100%"
    }
  },
  buttonLg: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "1rem",
      minHeight: "1.3125rem"
    }
  },
  buttonLgLink: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "1rem",
      minHeight: "1.3125rem",
      textDecoration: "underline"
    }
  },
  buttonMd: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "0.875rem",
      minHeight: "1.125rem"
    }
  },
  buttonMdLink: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "0.875rem",
      minHeight: "1.125rem",
      textDecoration: "underline"
    }
  },
  buttonSm: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "0.75rem",
      minHeight: "1rem"
    }
  },
  buttonSmLink: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "0.75rem",
      minHeight: "1rem",
      textDecoration: "underline"
    }
  },
  caption: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      fontSize: "0.75rem",
      minHeight: "1rem"
    }
  },
  captionBold: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "700",
      fontSize: "0.75rem",
      minHeight: "1rem"
    }
  },
  description: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "400",
      fontSize: "0.75rem",
      minHeight: "1rem",
      verticalAlign: "middle"
    }
  },
  label1: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "0.625rem",
      minHeight: "0.8125rem"
    }
  },
  label2: {
    component: "span",
    style: {
      fontFamily: fonts.pfdInt,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "0.75rem",
      minHeight: "0.75rem",
      lineHeight: "100%"
    }
  },
  tooltip: {
    component: "span",
    style: {
      fontFamily: fonts.spaceGrotesk,
      fontWeight: "500",
      fontSize: "0.625rem",
      minHeight: "0.8125rem"
    }
  }
};
export default typography;