import { configService } from "@/dependencies";
import { Sidebar, SidebarType } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface SidebarOptionProps {
	type: SidebarType;
	disabled?: boolean;
	label: string;
	route: string;
	navigationType?: "relative" | "absolute";
}

export const SidebarOption: FC<SidebarOptionProps> = ({
	type,
	disabled,
	label,
	route,
	navigationType = "absolute"
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const isActive = location.pathname.startsWith(route);

	const navigateTo = (route: string) => () => {
		if (route === "#") {
			return;
		}

		if (route.startsWith("http")) {
			window.location.href = route;
			return;
		}

		if (navigationType === "absolute") {
			window.location.href = `${configService.get().baseURL}${route}`;
			return;
		}

		navigate(route);
	};

	return (
		<Sidebar
			type={type}
			enabled={!disabled}
			label={label}
			active={isActive}
			onClick={!disabled ? navigateTo(route) : () => {}}
		/>
	);
};
