export enum CustomTournamentPrize {
	WINNER_TAKES_ALL = 0,
	PODIUM_ONLY = 1,
	FIRST_SIX = 2,
	FIRST_EIGHT = 3,
	FIRST_TEN = 4,
	FIRST_TWENTY = 5,
	FIRST_FOURTEEN = 6,
	FIRST_TWELVE = 7,
	FIRST_SIXTEEN = 8
}

export const REWARDS_DISTRIBUTION: Record<CustomTournamentPrize, number[]> = {
	[CustomTournamentPrize.WINNER_TAKES_ALL]: [100],
	[CustomTournamentPrize.PODIUM_ONLY]: [50, 30, 20],
	[CustomTournamentPrize.FIRST_SIX]: [40, 25, 15, 10, 6, 4],
	[CustomTournamentPrize.FIRST_EIGHT]: [30, 20, 14, 10, 8, 7, 6, 5],
	[CustomTournamentPrize.FIRST_TEN]: [24, 15, 12, 10, 9, 8, 7, 6, 5, 4],
	[CustomTournamentPrize.FIRST_TWENTY]: [15, 10, 8, 6, 6, 5, 5, 5, 5, 5, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
	[CustomTournamentPrize.FIRST_FOURTEEN]: [18, 13, 10, 9, 8, 7, 6, 5, 4, 4, 4, 4, 4, 4],
	[CustomTournamentPrize.FIRST_TWELVE]: [20, 15, 12, 10, 9, 8, 7, 6, 5, 4, 2, 2],
	[CustomTournamentPrize.FIRST_SIXTEEN]: [12, 11, 10, 9, 8, 7, 6, 5, 4, 4, 4, 4, 4, 4, 4, 4]
};
