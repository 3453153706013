import { EmptyEnergyIcon, NotRegisteredForNextMatch } from "@/modules/lineup/shared/components/PlayerConditions";
import { ConditionLabel } from "@/modules/shared/components";
import { Row, Typography } from "@metasoccer/metasoccer-ds";
import { InjuredLockedPlayer, TournamentCardFrontend } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { InjuryIndicator } from "../../../InjuryIndicator";
import { PlayerCardsIndicator } from "../../../PlayerCardsIndicator";
import { CssPlayerContentBottomPart } from "./PlayerBottomPart.styled";

interface PlayerBottomPartProps {
	condition: number;
	playerNotRegisteredForNextMatch?: boolean;
	playerInjured?: InjuredLockedPlayer;
	playerCards?: TournamentCardFrontend[];
}

export const PlayerBottomPart: FC<PlayerBottomPartProps> = ({
	condition,
	playerNotRegisteredForNextMatch: notRegisteredInNextMatch,
	playerInjured: playerInjured,
	playerCards: playerCards
}) => {
	return (
		<CssPlayerContentBottomPart>
			<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
				<div>
					<Row>
						<Typography variant="body2">Cond.</Typography>
						<ConditionLabel variant="body2" condition={condition}>
							{condition}%
						</ConditionLabel>
					</Row>
				</div>
				<Row gap={4}>
					{playerInjured && <InjuryIndicator injuredPlayer={playerInjured} />}
					{condition < 20 && <EmptyEnergyIcon />}
					{notRegisteredInNextMatch && <NotRegisteredForNextMatch />}
					{playerCards && playerCards.length > 0 && (
						<PlayerCardsIndicator sanctionedPlayerCards={playerCards} size="md" isTable />
					)}
				</Row>
			</Row>
		</CssPlayerContentBottomPart>
	);
};
