import { NextMatchPreview, PrepareMatchModal } from "@/modules/shared/components";
import { ArrowRightIcon, Row, Typography } from "@metasoccer/metasoccer-ds";
import { NextPvpScheduledMatch } from "@metasoccer/metasoccer-types";
import { memo, useState } from "react";
import { HomeButton } from "../../../../../../components/HomeButton";
import { ContentStyled, HeaderStyled } from "./PrepareNextMatch.styles";

interface PrepareNextMatchProps {
	height?: number;
	match: NextPvpScheduledMatch;
	matchInProgress?: boolean;
}

export const PrepareNextMatch = memo<PrepareNextMatchProps>(({ height, match, matchInProgress }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<HomeButton height={height ?? 332} width="100%" onClick={() => setIsModalOpen(true)}>
				<ContentStyled>
					<HeaderStyled>
						{matchInProgress && <Typography variant="h5">Match in progress</Typography>}
						{!matchInProgress && <Typography variant="h5">Prepare next match</Typography>}
						<Typography variant="caption" light textAlign="left">
							{match.editionName}
						</Typography>
					</HeaderStyled>
					<NextMatchPreview
						direction="column"
						size="md"
						showNotices
						awayTeamInfo={match.teams.away}
						homeTeamInfo={match.teams.home}
						matchDate={match.match.date}
						notices={match.notices}
					/>
					<Row gap={4}>
						<Typography variant="buttonMd">Prepare match</Typography>
						<ArrowRightIcon size="sm" />
					</Row>
				</ContentStyled>
			</HomeButton>
			{isModalOpen && (
				<PrepareMatchModal isOpen={isModalOpen} nextMatch={match} onClose={() => setIsModalOpen(false)} />
			)}
		</>
	);
});
