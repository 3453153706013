import styled, { css } from "styled-components";

export const CssContent = styled.div(
	({ theme }) => css`
		height: 100%;
		${theme.breakpoints.up("desktopMin")} {
		}
	`
);

export const CssGroupHeader = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		background: ${theme.palette.backgrounds.codGray};
		height: 40px;
		justify-content: space-between;
		padding: 0 24px;
	`
);

export const CssGroupMatch = styled.div(
	({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};

		& > * {
			padding: 24px;
		}
	`
);
