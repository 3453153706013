import { useIsMobile } from "@/layouts/context";
import { useServerState } from "@/modules/core/react-query/hooks";
import { MAINTENANCE_QUERIES } from "@/modules/maintenance/queries";
import { Loading } from "@/modules/shared/components";
import { Col, Row, Typography, getImgUrl } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { ContainerStyled, HeaderStyled } from "./Maintenance.styles";

export const Maintenance = memo(() => {
	const { data: maintenanceLog = [] } = useServerState(MAINTENANCE_QUERIES.getMaintenanceLog());
	const { data: message } = useServerState(MAINTENANCE_QUERIES.getMessage(maintenanceLog[0]?.id), {
		enabled: maintenanceLog.length > 0
	});

	const isMobile = useIsMobile();

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				height: isMobile ? "auto" : "100%",
				justifyContent: "center",
				padding: 24,
				width: "100%"
			}}>
			<Col style={{ overflow: "auto" }}>
				{isMobile && (
					<Col alignItems="center" justifyContent="center" flex={1}>
						<img src={getImgUrl("https://assets.metasoccer.com/home/maintenance.png")} height={256} />
					</Col>
				)}
				<Row
					gap={24}
					alignItems="center"
					justifyContent="center"
					style={{ maxWidth: 1366, width: "100%", height: "100%" }}>
					<Col alignItems="center" flex={1}>
						<Col>
							<HeaderStyled>
								<Typography variant="h5">Our systems are currently under maintenance</Typography>
							</HeaderStyled>
							<ContainerStyled>
								{message ? (
									<div dangerouslySetInnerHTML={{ __html: message.data }} />
								) : (
									<Loading size="sm" light />
								)}
							</ContainerStyled>
						</Col>
					</Col>
					{!isMobile && (
						<Col alignItems="center" justifyContent="center" flex={1}>
							<img src={getImgUrl("https://assets.metasoccer.com/home/maintenance.png")} width="100%" />
						</Col>
					)}
				</Row>
			</Col>
		</div>
	);
});
