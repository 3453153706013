import styled, { css } from "styled-components";

export const CssRoundPreviewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 100%;
`;

export const CssRoundDateWrapper = styled.div(
	({ theme }) => css`
		width: 100%;
		padding: 12px;
		text-align: center;
		background: ${theme.palette.greyscale.gondola};
	`
);

export const CssMatchesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
