import React, { FC } from "react";
import { Typography, Modal } from "@metasoccer/metasoccer-ds";
import { CssContent, CssContentRow } from "./DivisionSheet.styled";
import { TournamentStructureDivision } from "@metasoccer/metasoccer-types";

interface DivisionSheetProps {
	isOpen: boolean;
	divisions: TournamentStructureDivision[];
	onDivisionSelect: (division: TournamentStructureDivision) => () => void;
	onClose: () => void;
}

export const DivisionSheet: FC<DivisionSheetProps> = ({ isOpen, divisions, onDivisionSelect, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Select division">
			<CssContent>
				{divisions.map((division) => (
					<CssContentRow key={division.categoryId} onClick={onDivisionSelect(division)}>
						<Typography variant="buttonLg">{division.name}</Typography>
					</CssContentRow>
				))}
			</CssContent>
		</Modal>
	);
};
