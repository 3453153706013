import styled, { css } from "styled-components";

export const CssStatsCardWrapper = styled.div(
	({ theme }) => css`
		height: 206px;
		display: flex;
		width: 100%;
		flex-direction: column;
		padding: 0px 24px;
		background-color: ${theme.palette.backgrounds.codGray};
		border: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			height: 211px;
		}
	`
);

export const CssStatsCardContentWrapper = styled.div`
	display: flex;

	${({ theme }) => theme.breakpoints.up("desktopMin")} {
		padding-left: 4px;
	}
`;

export const CssStatsTitleWrapper = styled.div`
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
`;

export const CssClubLogoWrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
`;

export const CssStatsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	${({ theme }) => theme.breakpoints.up("desktopMin")} {
		padding-left: 32px;
	}
`;

export const CssStatsContainerWrapper = styled.div`
	display: flex;
	gap: 8px;
`;
