import { Scouting, ScoutingState } from "@metasoccer/metasoccer-types";
import { getScoutingSecondsRemaining } from "@/modules/assets/views/ScoutProfile/helpers/getScoutingSecondsRemaining";

export const getScoutingState = (scouting: Scouting): ScoutingState => {
	if (scouting.finished && !scouting.claimed) {
		return ScoutingState.BACK_FROM_SCOUTING;
	}

	if (scouting.claimed) {
		return ScoutingState.PLAYERS_CLAIMED;
	}

	const timeLeft = getScoutingSecondsRemaining(scouting);
	if (timeLeft > 0) {
		return ScoutingState.SCOUTING;
	}

	if (scouting.timestamp === 0 && !scouting.finished) {
		return ScoutingState.CANCELLED;
	}

	return ScoutingState.READY_TO_COME_BACK;
};
