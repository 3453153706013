import styled, { css } from "styled-components";

export const ImageStyled = styled.img(
	() => css`
		height: 100%;
		object-fit: contain;
		width: 200%;
		margin-left: -50%;
	`
);
