import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["loader", "palette", "borderRadius", "fonts", "typography", "icons", "breakpoints"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createTheme } from "@peersyst/react-components";
import { deepmerge } from "@peersyst/react-utils";
import palette from "./palette";
import fonts from "./fonts";
import typography from "./typography";
import Loader from "./icon/Loader";
import Copy from "./icon/Copy";
import Close from "./icon/Close";
import Check from "./icon/Check";
import breakpoints from "./breakpoints";
export default function (_ref) {
  var themeLoader = _ref.loader,
    themePalette = _ref.palette,
    themeBorderRadius = _ref.borderRadius,
    themeFonts = _ref.fonts,
    themeTypography = _ref.typography,
    _ref$icons = _ref.icons,
    icons = _ref$icons === void 0 ? {} : _ref$icons,
    themeBreakpoints = _ref.breakpoints,
    rest = _objectWithoutProperties(_ref, _excluded);
  return createTheme(_objectSpread({
    loader: themeLoader || Loader,
    icons: _objectSpread(_objectSpread({}, icons), {}, {
      copy: icons.copy || Copy,
      cross: icons.cross || Close,
      check: icons.check || Check
    }),
    palette: deepmerge(palette, themePalette),
    borderRadius: themeBorderRadius || "0",
    fonts: _objectSpread(_objectSpread({}, fonts), themeFonts),
    breakpoints: {
      values: _objectSpread(_objectSpread({}, breakpoints), (themeBreakpoints == null ? void 0 : themeBreakpoints.values) || {})
    },
    typography: deepmerge(typography, themeTypography)
  }, rest));
}