import { Tabs } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssPrizePoolTabs = styled(Tabs)(
	({ theme }) => css`
		margin-top: 15px;
		margin-bottom: 24px;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			margin-bottom: 12px;
		}
	`
);
