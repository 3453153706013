import { FC, useEffect, useState } from "react";
import {
	CssFiller,
	CssScoutCardWrapper,
	CssScoutContent,
	CssScoutImage,
	CssVerticalBackground,
	CssVerticalBackgroundOverlay,
	CssVerticalContent,
	CssVerticalInfoWrapper,
	CssVerticalNameWrapper,
	CssVerticalScoutInfo,
	CssVerticalQualityPotential,
	CssVerticalSkillPotentialRoleWrapper,
	CssVerticalTop,
	CssVerticalWrapper,
	CssGreenDot,
	CssVerticalBottom,
	CssScoutingTimeRemaining,
	CssScoutingTimeRemainingText,
	CssScoutingCheckPlayers,
	CssScoutingCheckPlayersText,
	CssVerticalNameText
} from "./ScoutCard.styled";
import { FeatureFlag, Scout, Scouting, ScoutingState } from "@metasoccer/metasoccer-types";
import { ScoutUpperPart } from "./components";
import { Button, InfoPill, NumberChip, RoleChip, SearchIcon, Typography } from "@metasoccer/metasoccer-ds";
import {
	getScoutingSecondsRemaining,
	getScoutingState,
	getScoutingTimeRemainingString
} from "@/modules/assets/views/ScoutProfile/helpers";
import { useFlag } from "@unleash/proxy-client-react";

type ScoutMode = "horizontal" | "vertical";

export interface ScoutCardProps {
	scout: Scout;
	scouting: Scouting | null;
	mode?: ScoutMode;
	onClick?: () => void;
	className?: string;
}

export const ScoutCard: FC<ScoutCardProps> = ({
	scout,
	scouting,
	mode = "horizontal",
	onClick = () => {},
	className
}) => {
	const useAssets2 = useFlag(FeatureFlag.UseAssets2);
	const [remainingScoutingTime, setRemainingScoutingTime] = useState<string>("");
	const {
		id,
		overallKnowledge,
		covering,
		name,
		lastName,
		age,
		specialPlayerSkills,
		specialScoutSkills,
		imageMetadata
	} = scout;
	const shortName = `${name.slice(0, 1)}. ${lastName}`;
	const specialAbilitiesCount = specialScoutSkills.length + specialPlayerSkills.length;

	useEffect(() => {
		if (scouting === null) {
			return;
		}

		const interval = setInterval(() => {
			const remainingSeconds = getScoutingSecondsRemaining(scouting);
			setRemainingScoutingTime(() => getScoutingTimeRemainingString(remainingSeconds));

			if (remainingSeconds <= 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [scouting]);

	const scoutImage = useAssets2
		? `https://assets2.metasoccer.com/file/assets2-metasoccer/scouts/cardMobile/${
				imageMetadata.original_filename.split("/")[1]
		  }`
		: `https://assets.metasoccer.com/cdn-cgi/image/width=300,height=360,fit=crop,gravity=1x0,quality=90,metadata=none,format=auto/scouts/${imageMetadata.waist_up_filename}`;

	const verticalImage = useAssets2
		? `https://assets2.metasoccer.com/file/assets2-metasoccer/scouts/assetsIndex/${
				imageMetadata.original_filename.split("/")[1]
		  }`
		: `https://assets.metasoccer.com/cdn-cgi/image/width=300,height=420,fit=crop,gravity=1x0,quality=90,metadata=none,format=auto/scouts/${imageMetadata.waist_up_filename}`;

	if (mode === "vertical") {
		return (
			<CssVerticalWrapper className={className}>
				<CssVerticalBackground imageUrl={verticalImage} />
				<CssVerticalBackgroundOverlay />
				<CssVerticalContent>
					<CssVerticalTop>
						<CssVerticalSkillPotentialRoleWrapper>
							<CssVerticalQualityPotential>
								<Typography variant="h4">{overallKnowledge}</Typography>
							</CssVerticalQualityPotential>
							<RoleChip covering={covering} />
						</CssVerticalSkillPotentialRoleWrapper>
						<CssVerticalScoutInfo>
							<CssVerticalNameWrapper>
								<NumberChip size="sm" number={id} />
								<CssVerticalNameText variant="h5">{shortName}</CssVerticalNameText>
							</CssVerticalNameWrapper>
							<CssVerticalInfoWrapper>
								<InfoPill title="Age" size="md">
									<Typography variant="body1">{age}</Typography>
								</InfoPill>
								<InfoPill title="S. Abil." size="md">
									<Typography variant="body1">{specialAbilitiesCount}</Typography>
								</InfoPill>
							</CssVerticalInfoWrapper>
						</CssVerticalScoutInfo>
					</CssVerticalTop>
					{scouting !== null && (
						<CssVerticalBottom>
							{getScoutingState(scouting) === ScoutingState.SCOUTING && (
								<CssScoutingTimeRemaining>
									<SearchIcon size="sm" />
									<CssScoutingTimeRemainingText variant="body2">{`${remainingScoutingTime} remaining...`}</CssScoutingTimeRemainingText>
								</CssScoutingTimeRemaining>
							)}
							{(getScoutingState(scouting) === ScoutingState.READY_TO_COME_BACK ||
								getScoutingState(scouting) === ScoutingState.BACK_FROM_SCOUTING) && (
								<CssScoutingCheckPlayers>
									<CssGreenDot />
									<CssScoutingCheckPlayersText variant="body2Bold">
										Check new players
									</CssScoutingCheckPlayersText>
								</CssScoutingCheckPlayers>
							)}
						</CssVerticalBottom>
					)}
				</CssVerticalContent>
			</CssVerticalWrapper>
		);
	}

	const MobileScoutingState =
		scouting === null ? (
			<CssFiller />
		) : (
			<>
				{getScoutingState(scouting) === ScoutingState.SCOUTING && (
					<Button
						appearance="secondary"
						size="sm"
						label={`${remainingScoutingTime} remaining...`}
						leftIcon={<SearchIcon size="sm" />}
						onClick={onClick}
					/>
				)}
				{(getScoutingState(scouting) === ScoutingState.READY_TO_COME_BACK ||
					getScoutingState(scouting) === ScoutingState.BACK_FROM_SCOUTING) && (
					<Button
						appearance="primary"
						size="sm"
						label="Check new players"
						leftIcon={<CssGreenDot />}
						onClick={onClick}
						fullWidth
					/>
				)}
			</>
		);

	return (
		<CssScoutCardWrapper className={className} onClick={onClick}>
			<CssScoutImage imageUrl={scoutImage} />
			<CssScoutContent>
				<ScoutUpperPart
					overallKnowledge={overallKnowledge}
					covering={covering}
					shortName={shortName}
					age={age}
					specialAbilitiesCount={specialAbilitiesCount}
				/>
				{MobileScoutingState}
			</CssScoutContent>
		</CssScoutCardWrapper>
	);
};
