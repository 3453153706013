import {
	Response,
	HttpClient,
	MetaSoccerId,
	IdType,
	TournamentDetailedStanding,
	TeamStats,
	FriendlyPvpMatchResult,
	TournamentInfoDTO,
	FixturedMatches
} from "@metasoccer/metasoccer-types";
import {
	PvPCreateTournamentData,
	PvPCreateTournamentResult
} from "../components/PvPCreateTournamentModal/PvPCreateTournamentModal.state";
export class PvpService {
	constructor(private readonly httpService: HttpClient) {}

	async getTournamentInfo(teamId: MetaSoccerId<IdType.Team>): Promise<TournamentInfoDTO> {
		let response: Response<TournamentInfoDTO>;
		try {
			response = await this.httpService.get<TournamentInfoDTO>(`/pvp/tournamentInfo/${teamId}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getTeamStats(teamId: MetaSoccerId<IdType.Team>): Promise<TeamStats> {
		let response: Response<TeamStats>;
		try {
			response = await this.httpService.get<TeamStats>(`/pvp/stats/${teamId}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getScheduledMatches(teamId: MetaSoccerId<IdType.Team>): Promise<FixturedMatches> {
		let response: Response<FixturedMatches>;
		try {
			response = await this.httpService.get<FixturedMatches>(`/pvp/matches-fixtures/${teamId}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getPlayedMatch(matchId: string): Promise<FriendlyPvpMatchResult> {
		let response: Response<FriendlyPvpMatchResult>;
		try {
			response = await this.httpService.get<FriendlyPvpMatchResult>(`/pvp/matches/${matchId}/report`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getStandings(teamId: MetaSoccerId<IdType.Team>): Promise<TournamentDetailedStanding> {
		let response: Response<TournamentDetailedStanding>;
		try {
			response = await this.httpService.get<TournamentDetailedStanding>(`/pvp/standings/${teamId}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async joinTournament(
		teamId: MetaSoccerId<IdType.Team>,
		tournamentId: MetaSoccerId<IdType.Tournament>
	): Promise<void> {
		try {
			await this.httpService.post<{ tournamentId: MetaSoccerId<IdType.Tournament> }>(`/pvp/join/${teamId}`, {
				tournamentId
			});
		} catch (e: any) {
			throw e.message;
		}
	}

	async leaveTournament(teamId: MetaSoccerId<IdType.Team>, tournamentId: MetaSoccerId<IdType.Tournament>) {
		try {
			await this.httpService.post<{ tournamentId: MetaSoccerId<IdType.Tournament> }>(`/pvp/unjoin/${teamId}`, {
				tournamentId
			});
		} catch (e: any) {
			throw e.message;
		}
	}

	async createTournament(tournament: PvPCreateTournamentData): Promise<PvPCreateTournamentResult> {
		try {
			const r = await this.httpService.post<PvPCreateTournamentData>(`/custom-tournament/create`, tournament);
			return { tournamentId: r.data };
		} catch (e: any) {
			const message = e.data?.message && Array.isArray(e.data.message) ? e.data.message.join(", ") : e.message;
			throw message;
		}
	}

	async updateWhitelist(
		tournamentId: MetaSoccerId<IdType.Tournament>,
		isWhitelisted: boolean,
		whitelistedTeams: MetaSoccerId<IdType.Team>[]
	): Promise<void> {
		try {
			await this.httpService.post<{ isWhitelisted: boolean; whitelistedTeams: MetaSoccerId<IdType.Team>[] }>(
				`/custom-tournament/${tournamentId}/invite`,
				{
					isWhitelisted,
					whitelistedTeams
				}
			);
		} catch (e: any) {
			throw e.message;
		}
	}
}
