import { AllowedToken, CustomTournamentPrize } from ".";

export const gameModes = ["League", "KO", "League 2D", "KO 2D"] as const;
export type GameMode = typeof gameModes[number];
export const leagueNumberOfTeams = [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30] as const;
export type LeagueNumberOfTeams = typeof leagueNumberOfTeams[number];
export const koNumberOfTeams = [2, 4, 8, 16, 32] as const;
export type KoNumberOfTeams = typeof koNumberOfTeams[number];

export type TournamentCreateInfoDTO = {
	tournamentName: string;
	gameMode: GameMode;
	token: AllowedToken;
	entryFee: number;
	prizePool: CustomTournamentPrize;
	minOverall: number;
	maxOverall: number;
	numberOfTeams: LeagueNumberOfTeams;
	matchFrequencyHours: number;
	registrationClosingDayTimestamp: number;
};
