import { useCallback, useEffect, useState } from "react";

export function useMouseHold(action: () => void, interval: number = 500) {
	const [isHolding, setIsHolding] = useState<boolean>(false);

	useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null;
		if (isHolding) {
			intervalId = setInterval(action, interval);
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [isHolding, action, interval]);

	const startHolding = useCallback(() => {
		action();
		setIsHolding(true);
	}, [action]);

	const stopHolding = useCallback(() => setIsHolding(false), []);

	return { startHolding, stopHolding };
}
