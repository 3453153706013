import { FC } from "react";
import { CssToggleButtonRoot } from "./ToggleButton.styles";
import { Typography } from "@metasoccer/metasoccer-ds";

interface ToggleButtonProps {
	children: any;
	onClick: () => void;
	isSelected: boolean;
}

export const ToggleButton: FC<ToggleButtonProps> = ({ children, isSelected, onClick }) => {
	return (
		<CssToggleButtonRoot onClick={onClick} isSelected={isSelected}>
			<Typography variant={"subtitle2"}>{children} </Typography>
		</CssToggleButtonRoot>
	);
};
