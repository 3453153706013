export enum ClubLogoName {
	CLUB1 = "Club1",
	CLUB2 = "Club2",
	CLUB3 = "Club3",
	CLUB5 = "Club5",
	CLUB4 = "Club4",
	CLUB6 = "Club6",
	CLUB7 = "Club7",
	CLUB8 = "Club8",
	CLUB9 = "Club9",
	CLUB10 = "Club10",
	CLUB11 = "Club11",
	CLUB12 = "Club12",
	CLUB13 = "Club13",
	CLUB14 = "Club14",
	CLUB15 = "Club15",
	CLUB16 = "Club16",
	CLUB17 = "Club17",
	CLUB18 = "Club18",
	CLUB19 = "Club19",
	CLUB20 = "Club20"
}
