import styled from "styled-components";

export const CssLastMatchesListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	${({ theme }) => theme.breakpoints.up("desktopMin")} {
		overflow-y: auto;
		padding-bottom: 24px;
		gap: 24px;
	}
`;
