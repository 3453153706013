import { HttpClient } from "@metasoccer/metasoccer-types";
import { Catalog, PurchaseSignature } from "../types";

export class StoreService {
	constructor(private readonly httpService: HttpClient) {}

	async getActiveCatalogue(): Promise<Catalog> {
		try {
			const response = await this.httpService.get<Catalog>("/store/catalog/active");
			return response.data;
		} catch (e) {
			throw e;
		}
	}

	async getPurchaseSignature(productId: string): Promise<PurchaseSignature> {
		try {
			const response = await this.httpService.get<PurchaseSignature>(`/store/request/${productId}`);
			return response.data;
		} catch (e) {
			throw e;
		}
	}
}
