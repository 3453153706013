import { analyticsService } from "@/dependencies";
import { useMutableServerState } from "@/modules/core/react-query/hooks";
import { LINEUP_MUTABLE_QUERIES } from "@/modules/lineup/queries/mutableQueries";
import { Formation, LinedPlayer, LineupWithSkill, Player, Team } from "@metasoccer/metasoccer-types";
import { FlatLineup } from "@metasoccer/metasoccer-types/dist/@types/lineup/FlatLineup";

export const useUpdateLineupFormation = (
	lineupWithSkill: LineupWithSkill,
	selectedTeam: Team,
	previousFormation: string,
	callbackFn: (closeModal: boolean) => void
) => {
	const { mutate: updateFormation } = useMutableServerState(LINEUP_MUTABLE_QUERIES.update(), {
		onSuccess: (updatedLineup) => {
			callbackFn(false);
			analyticsService.sendEvent("formationSelected", {
				formationSelected: updatedLineup.lineup.formationId,
				formationBefore: previousFormation,
				teamId: selectedTeam?.id,
				owner: "wallet:" + selectedTeam?.manager,
				manager: "wallet:" + selectedTeam?.manager
			});
		}
	});

	const update = (formation: Formation) => {
		const lineup: FlatLineup = {
			owner: selectedTeam?.owner!,
			teamId: selectedTeam?.id!,
			formationId: formation.id,
			linedPlayers: lineupWithSkill.lineup.linedPlayers.map((linedPlayer: LinedPlayer) => ({
				playerId: linedPlayer.player.id,
				slotId: linedPlayer.slotId
			})),
			playersInBenchIds: lineupWithSkill.lineup.playersInBench.map((playerInBench: Player) => playerInBench.id)
		};
		updateFormation({ lineup, teamId: selectedTeam.id });
	};

	return { update };
};
