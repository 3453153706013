import { useIsMobile } from "@/layouts/context";
import { useGlobalState } from "@/modules/shared/context";
import { memo } from "react";
import { HeaderHoverMenu } from "../HeaderHoverMenu";

export const TopBarMenu = memo(() => {
	const isMobile = useIsMobile();

	const { isConnecting, isUnknown } = useGlobalState();

	if (isConnecting || isUnknown) {
		return <div style={{ width: isMobile ? 56 : 68 }} />;
	} else {
		return <HeaderHoverMenu />;
	}
});
