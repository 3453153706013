import {
	CloseIcon,
	Modal as DesignSystemModal,
	ModalProps as DesignSystemModalProps,
	IconButton,
	ModalSize,
	Typography
} from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { FooterStyled, HeaderStyled, TitleStyled, WrapperStyled } from "./Modal.styles";

export interface ModalProps extends DesignSystemModalProps {
	children: ReactNode;
	fullScreen?: boolean;
	isOpen: boolean;
	mainAction?: ReactNode;
	maxScreenWidth?: boolean;
	size?: ModalSize;
	title: ReactNode | string;
	zIndex?: number;
	onClose: () => void;
}

export const Modal = memo<ModalProps>(
	({ children, fullScreen, isOpen, mainAction, maxScreenWidth, size, title, zIndex, onClose, ...props }) => {
		const footerContent = mainAction ? <FooterStyled>{mainAction}</FooterStyled> : undefined;

		const headerContent = (
			<HeaderStyled>
				<TitleStyled>
					{typeof title === "string" ? <Typography variant="body1">{title}</Typography> : title}
				</TitleStyled>
				<IconButton size="sm" icon={CloseIcon} onClick={onClose} />
			</HeaderStyled>
		);

		return (
			<DesignSystemModal
				{...props}
				isOpen={isOpen}
				fullScreen={fullScreen}
				footerContent={footerContent}
				headerContent={headerContent}
				maxScreenWidth={maxScreenWidth}
				size={size}
				zIndex={zIndex}
				onClose={onClose}>
				<WrapperStyled>{children}</WrapperStyled>
			</DesignSystemModal>
		);
	}
);
