import { useTheme } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { WrapperStyled } from "./Alert.styles";

interface AlertProps {
	variant: "error" | "info" | "success" | "warning";
	children: ReactNode;
}

export const Alert = memo<AlertProps>(({ variant, children }) => {
	const theme = useTheme();

	const { color, border } = {
		error: {
			color: theme.palette.tint.amaranth30,
			border: theme.palette.system.amaranth
		},
		info: {
			color: theme.palette.tint.cornflowerBlue30,
			border: theme.palette.system.cornflowerBlue
		},
		success: {
			color: theme.palette.tint.pastelGreen30,
			border: theme.palette.system.pastelGreen
		},
		warning: {
			color: theme.palette.tint.laserLemon30,
			border: theme.palette.system.laserLemon
		}
	}[variant];

	return (
		<WrapperStyled color={color} border={border}>
			{children}
		</WrapperStyled>
	);
});
