import { useIsMobile } from "@/layouts/context";
import { RedDotLive } from "@/modules/home/views/Deprecated/Playoffs/components/RedDotLive";
import { MatchScore } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { InfoIcon, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { PvpTeamInfo as PvpTeamInfoType } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import {
	CssIconWrapper,
	CssLiveMatchWrapper,
	CssPvpMatchDateWrapper,
	CssTimer,
	CssTimerWrapper
} from "./PvpLiveMatch.styled";

interface PvpLiveMatchProps {
	notices: string[];
	showNotices?: boolean;
	teamAway: PvpTeamInfoType;
	teamHome: PvpTeamInfoType;
	matchScore: [number, number];
	matchTime: [string, string];
}
export const PvpLiveMatch: FC<PvpLiveMatchProps> = ({
	notices,
	showNotices = false,
	teamAway,
	teamHome,
	matchScore,
	matchTime
}) => {
	const { selectedTeam } = useGlobalState();

	const isSelectedTeam = selectedTeam?.id === teamHome.teamId || selectedTeam?.id === teamAway.teamId;
	const selectedTeamSide: "home" | "away" | undefined =
		isSelectedTeam && selectedTeam?.id === teamHome.teamId ? "home" : "away";

	const isMobile = useIsMobile();
	const theme = useTheme();

	const [minutes, seconds] = matchTime;

	return (
		<CssPvpMatchDateWrapper>
			{notices.length > 0 && isMobile && showNotices && (
				<CssIconWrapper>
					<InfoIcon size="md" color={theme.palette.system.amaranth} />
				</CssIconWrapper>
			)}

			<CssLiveMatchWrapper>
				<RedDotLive />
				<Typography variant="body2Bold">LIVE</Typography>
			</CssLiveMatchWrapper>

			<MatchScore
				homeGoals={matchScore ? matchScore[0] : 0}
				awayGoals={matchScore ? matchScore[1] : 0}
				isMatchResultLayout={true}
				isFinished={isSelectedTeam}
				side={selectedTeamSide}
			/>

			<CssTimerWrapper>
				<CssTimer variant="caption" position="L">
					{minutes}
				</CssTimer>
				<CssTimer variant="caption" position="C">
					:
				</CssTimer>
				<CssTimer variant="caption" position="R">
					{seconds}
				</CssTimer>
			</CssTimerWrapper>
		</CssPvpMatchDateWrapper>
	);
};
