import React, { FC } from "react";
import { Typography } from "@metasoccer/metasoccer-ds";
import { CssDrillFooterContent, CssDrillFooterLeftWrapper, CssDrillFooterWrapper } from "./DrillFooter.styled";
import { TrainingToken } from "@/modules/shared/components/tokens";

interface DrillFooterProps {
	conditionCost: number;
	maxPlayers: number;
	playerCost: number;
	selectedPlayerCount: number;
}

export const DrillFooter: FC<DrillFooterProps> = ({ conditionCost, maxPlayers, playerCost, selectedPlayerCount }) => {
	return (
		<CssDrillFooterWrapper>
			<CssDrillFooterLeftWrapper>
				<CssDrillFooterContent>
					<Typography variant="body2" light>
						Cond.
					</Typography>
					<Typography variant="body2">{`-${conditionCost}%`}</Typography>
				</CssDrillFooterContent>
				<CssDrillFooterContent>
					<Typography variant="body2" light>
						Players
					</Typography>
					<Typography variant="body2">
						{selectedPlayerCount > 0 && `${selectedPlayerCount}/`}
						{maxPlayers}
					</Typography>
				</CssDrillFooterContent>
			</CssDrillFooterLeftWrapper>
			{playerCost > 0 && (
				<CssDrillFooterContent>
					<Typography variant="body2" light>
						Cost/player
					</Typography>
					<TrainingToken quantity={playerCost} />
				</CssDrillFooterContent>
			)}
		</CssDrillFooterWrapper>
	);
};
