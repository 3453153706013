import { Row } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const WrapperStyled = styled.div<{ onClick?: Function }>(
	({ onClick, theme }) => css`
		display: flex;
		background-color: ${theme.palette.backgrounds.codGray};
		flex-direction: column;
		height: 100%;
		width: 100%;

		${onClick &&
		`
			cursor: pointer;
			transition-property: transform;
			transition-duration: var(--hover-transition-duration);
			transition-timing-function: var(--hover-transition-curve);

			@media (hover: hover) {
				&:hover {
					background-color: ${theme.palette.greyscale.gondola};
					transform: translate3d(0, var(--hover-translate-y), 0);
				}
			}
		`}
	`
);

export const FooterStyled = styled(Row)(
	({ theme }) => css`
		align-items: center;
		border-top: 1px solid ${theme.palette.greyscale.gondola};
		justify-content: flex-end;
		padding: 12px 24px;
	`
);

export const HeaderStyled = styled(Row)(
	({ theme }) => css`
		align-items: center;
		justify-content: space-between;
		padding: 24px;
	`
);
