import { Row, TextField, TextFieldProps, Typography } from "@metasoccer/metasoccer-ds";
import { CSSProperties, ReactElement, memo } from "react";

interface TextFieldWithCoinDecoratorProps extends TextFieldProps {
	coinIcon: ReactElement;
	coinName: string;
}

export const TextFieldWithCoinDecorator = memo(({ coinIcon, coinName, ...rest }: TextFieldWithCoinDecoratorProps) => {
	const wrapperStyle: CSSProperties = {
		display: "flex",
		position: "absolute",
		alignItems: "center",
		background: "black",
		height: 38,
		minWidth: 54,
		right: 12,
		top: 4
	};

	return (
		<div style={{ position: "relative" }}>
			<TextField {...rest} />
			<div style={wrapperStyle}>
				<Row gap={4} alignItems="center">
					{coinIcon}
					<Typography variant="body2">{coinName}</Typography>
				</Row>
			</div>
		</div>
	);
});
