import { FC } from "react";
import { CssNoticeIconWrapper, CssNoticesWrapper, CssNoticeWrapper } from "./LineupNotices.styled";
import { InfoIcon, Typography, useTheme } from "@metasoccer/metasoccer-ds";

export const LineupNotices: FC<{ notices: string[] }> = ({ notices }) => {
	const theme = useTheme();
	return (
		<CssNoticesWrapper>
			{notices.map((n, key) => {
				return (
					<CssNoticeWrapper key={key}>
						<CssNoticeIconWrapper>
							<InfoIcon size="sm" color={theme.palette.system.amaranth} />
						</CssNoticeIconWrapper>
						<Typography variant="body2">{n}</Typography>
					</CssNoticeWrapper>
				);
			})}
		</CssNoticesWrapper>
	);
};
