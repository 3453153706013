import "./dependencies";

import { App } from "@/App";
import { GlobalContextProvider } from "@/modules/shared/context";
import { buildCustomConsoleError } from "@/modules/shared/utils";
import { MetasoccerConfigProvider, ToastProvider, createConfig, createTheme } from "@metasoccer/metasoccer-ds";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FlagProvider } from "@unleash/proxy-client-react";
import React from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LayoutContextProvider } from "./layouts/context";
import { getUnleashProxyUrl } from "./modules/core/unleash";
import { Web3Provider } from "./modules/web3";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./styles/global";

const designSystemConfig = createConfig({
	projectName: "metasoccer",
	theme: createTheme({
		breakpoints: {
			values: {
				mobileMax: 520,
				desktopMin: 1280,
				desktopMax: 1440
			}
		}
	})
});

const queryClient = new QueryClient();

const unleashConfig = {
	url: getUnleashProxyUrl(),
	clientKey: "metasoccer",
	appName: "metasoccer"
};

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://a0dfa1cf8eca7d65be823a71cb180d5f@o1163319.ingest.sentry.io/4506356158693376",
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [/^https:\/\/app\.metasoccer\.com/]
			}),
			new Sentry.Replay({
				maskAllInputs: false,
				maskAllText: false
			})
		],
		tracesSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 0.1
	});
}

console.error = buildCustomConsoleError();

createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<BrowserRouter>
			<FlagProvider config={unleashConfig}>
				<MetasoccerConfigProvider config={designSystemConfig}>
					<QueryClientProvider client={queryClient}>
						<Web3Provider>
							<ToastProvider>
								<LayoutContextProvider>
									<GlobalContextProvider>
										<GlobalStyles />
										<App />
									</GlobalContextProvider>
								</LayoutContextProvider>
							</ToastProvider>
						</Web3Provider>
					</QueryClientProvider>
				</MetasoccerConfigProvider>
			</FlagProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
