import styled from "styled-components";

export const CssLoadingWrapper = styled.div<{ cover?: "hv" | "h" | "v"; light?: boolean }>`
	display: flex;
	align-items: center;
	height: ${({ cover }) => (cover?.includes("v") ? "100%" : "fit-content")};
	justify-content: center;
	opacity: ${({ light }) => (light ? 0.42 : 1)};
	position: relative;
	width: ${({ cover }) => (cover?.includes("h") ? "100%" : "fit-content")};
`;
