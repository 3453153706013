import { InfoIcon, Row, RowProps } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { Tooltip } from "../Tooltip";

export interface WithInfoProps extends RowProps {
	children: ReactNode;
	info: string;
	showInfo?: boolean;
}

export const WithInfo = memo<WithInfoProps>(({ children, info, showInfo, ...props }) => {
	return (
		<Row alignItems="center" gap={4} {...props}>
			{children}
			{(showInfo === undefined || showInfo === true) && (
				<Tooltip text={info}>
					<InfoIcon size="sm" />
				</Tooltip>
			)}
		</Row>
	);
});
