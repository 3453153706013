import { HomeButton } from "@/modules/home/components";
import styled, { css } from "styled-components";

export const HomeButtonStyled = styled(HomeButton)(
	({ theme }) => css`
		padding: 0;
		width: 100% !important;

		${theme.breakpoints.up("desktopMin")} {
			height: 100% !important;
		}
	`
);

export const ContentStyled = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		min-height: 512px;
		padding: 24px;

		${theme.breakpoints.up("desktopMin")} {
			height: 100%;
			min-height: unset;
		}
	`
);
