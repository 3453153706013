import styled, { css } from "styled-components";

export type LineupBenchVariant = "horizontal" | "vertical";

export const CssWrapper = styled.div<{ variant: LineupBenchVariant }>(
	({ variant }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
		height: ${variant === "vertical" ? "initial" : "142px"};
		width: ${variant === "vertical" ? "142px" : "initial"};
	`
);

export const CssLabel = styled.div(
	({ theme }) => css`
		padding: 0 12px;

		${theme.breakpoints.up("desktopMin")} {
			padding: 0;
		}
	`
);

export const CssBench = styled.div(
	({ theme }) => css`
		background: ${theme.palette.backgrounds.codGray};
		border: 2px solid ${theme.palette.greyscale.scorpion};
		height: 100%;
		position: relative;
		width: 100%;
	`
);
