import { AxiosHttpClient } from "@/modules/core/http/AxiosHttpClient";
import {
	RequestAuthenticationInterceptor,
	ResponseResultInterceptor,
	ResponseUnauthenticatedInterceptor
} from "@/modules/core/http/interceptors";
import { ConfigService } from "@/modules/shared/services";
import { HttpClient, RequestConfig } from "@metasoccer/metasoccer-types";

export class HttpClientFactory {
	static create(configService: ConfigService): HttpClient {
		const config: RequestConfig = {
			baseUrl: configService.get().baseApiURL,
			timeout: 30000,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json"
			}
		};
		const requestAuthenticationInterceptor = new RequestAuthenticationInterceptor();
		const responseUnauthenticatedInterceptor = new ResponseUnauthenticatedInterceptor();
		const responseResultInterceptor = new ResponseResultInterceptor();

		const httpClient = new AxiosHttpClient(config);
		httpClient.appendRequestInterceptor(requestAuthenticationInterceptor);
		httpClient.appendResponseInterceptor(responseUnauthenticatedInterceptor);
		httpClient.appendResponseInterceptor(responseResultInterceptor);

		return httpClient;
	}
}
