import { BaseServerStateKeys } from "@/modules/shared/constants";
import { koTournamentService } from "@/dependencies";

export const KO_TOURNAMENT_QUERIES = {
	getBracketBranchByGroupAndBranchIds: (groupId: string, branchId: number) => ({
		queryKey: [BaseServerStateKeys.KO_TOURNAMENT, groupId, `${branchId}`],
		queryFn: () => koTournamentService.getBracketBranchByGroupAndBranchIds(groupId, branchId)
	}),

	getGroupsList: (tournamentId: string) => ({
		queryKey: [BaseServerStateKeys.KO_TOURNAMENT, tournamentId],
		queryFn: () => koTournamentService.getGroupList(tournamentId)
	}),

	getLast: () => ({
		queryKey: [BaseServerStateKeys.KO_TOURNAMENT, "last"],
		queryFn: () => koTournamentService.getLast()
	})
};
