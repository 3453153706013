import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { FooterStyled, HeaderStyled, WrapperStyled } from "./Card.styles";

export interface CardProps {
	children: ReactNode;
	className?: string;
	subtitle?: string | ReactNode;
	title: string;
	Footer?: ReactNode;
	HeaderRight?: ReactNode;
	onClick?: () => void;
}

export const Card = memo<CardProps>(({ children, className, subtitle, title, Footer, HeaderRight, onClick }) => {
	return (
		<WrapperStyled className={className} onClick={onClick}>
			<HeaderStyled>
				<Col gap={4}>
					<Typography variant="h5">{title}</Typography>
					{subtitle && typeof subtitle === "string" ? (
						<Typography variant="caption" light>
							{subtitle}
						</Typography>
					) : (
						subtitle
					)}
				</Col>
				{HeaderRight}
			</HeaderStyled>
			{children}
			{Footer && <FooterStyled>{Footer}</FooterStyled>}
		</WrapperStyled>
	);
});
