import { AnalyticsService } from "./AnalyticsService";

export class DummyAnalyticsService implements AnalyticsService {
	constructor(private readonly isEmitLogsEnabled: boolean) {}

	identifyUser(userId: string) {
		if (!this.isEmitLogsEnabled) {
			return;
		}

		console.log(`[DummyAnalyticsService] Identifying user '${userId}`);
	}

	sendEvent(eventName: string, payload?: Record<string, unknown> | undefined): void {
		if (!this.isEmitLogsEnabled) {
			return;
		}

		console.log(`[DummyAnalyticsService] Sending event '${eventName}'${payload ? "with payload: " : ""}`, payload);
	}

	sendBigEvent(eventName: string, payload?: Record<string, unknown> | undefined): void {
		if (!this.isEmitLogsEnabled) {
			return;
		}

		console.log(
			`[DummyAnalyticsService] Sending big event '${eventName}'${payload ? " with payload: " : ""}`,
			payload
		);
	}
}
