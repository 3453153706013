import { AxiosHttpClient } from "@/modules/core/http";

export class SquidService {
	constructor(
		private readonly apiClient = new AxiosHttpClient({ baseUrl: "https://squid.metasoccer.com/api/graphql" })
	) {}

	async query(query: string): Promise<any> {
		try {
			const { data } = await this.apiClient.post("", { query });
			return data;
		} catch (err) {
			throw err;
		}
	}

	async passByAddress(address: string): Promise<any> {
		const query = `
			{
				metaSoccerPasses(where: { owner_eq: "${address}" }) { id }
			}
		`;

		try {
			const { data } = await this.apiClient.post("", { query });
			return data;
		} catch (err) {
			throw err;
		}
	}

	async scoutingsByScoutId(scoutId: number): Promise<any> {
		const query = `
			{
				scoutingStarteds(where: { scoutId_eq: "${scoutId}" }) {
					id
					scoutId
					scoutOwner
					scoutingPeriod
					scoutingTimestamp
					scoutRole
					scoutLevel
					blockHash
					blockNumber
					blockTimestamp
					transactionIndex
				}
				scoutingFinisheds(where: { scoutId_eq: "${scoutId}" }) {
					id
				}
				scoutingClaimeds(where: { scoutId_eq: "${scoutId}" }) {
					id
				}
			}
		`;

		try {
			const { data } = await this.apiClient.post("", { query });
			return data;
		} catch (err) {
			throw err;
		}
	}

	async starterBoxByAddress(address: string): Promise<any> {
		const query = `
			{
				starterBoxes(where: { owner_eq: "${address}" }) { id }
			}
		`;

		try {
			const { data } = await this.apiClient.post("", { query });
			return data;
		} catch (err) {
			throw err;
		}
	}

	async starterBoxClaimByAddress(address: string): Promise<any> {
		const query = `
			{
				starterBoxClaims(where: { maker_eq: "${address}" }) { id }
			}
		`;

		try {
			const { data } = await this.apiClient.post("", { query });
			return data;
		} catch (err) {
			throw err;
		}
	}

	async tournamentRegistrations(tournamentIds: string[]): Promise<Array<{ tournamentId: string; teamId: string }>> {
		const query = `
			query TournamentRegistrations($tournamentIds: [String!]) {
				tournamentRegistrations(where: { tournamentId_in: $tournamentIds }) {
					tournamentId,
					teamId
				}
			}
		`;

		try {
			const { data } = await this.apiClient.post("", {
				query,
				variables: { tournamentIds }
			});
			return data.data.tournamentRegistrations;
		} catch (err) {
			console.error("Error fetching tournament registrations:", err);
			throw err;
		}
	}

	async tournamentRegistrationsByTeam(teamId: string): Promise<Array<{ tournamentId: string }>> {
		const query = `
			query TournamentRegistrations($teamId: String!) {
				tournamentRegistrations(where: { teamId_eq: $teamId }) {
					tournamentId
				}
			}
		`;

		try {
			const { data } = await this.apiClient.post("", {
				query,
				variables: { teamId }
			});
			return data.data.tournamentRegistrations;
		} catch (err) {
			console.error("Error fetching tournament registrations:", err);
			throw err;
		}
	}

	async tournamentSponsorships(
		tournamentIds: string[]
	): Promise<Array<{ tournamentId: string; sponsor: string; amount: number }>> {
		const query = `
			query TournamentSponsorships($tournamentIds: [String!]) {
				tournamentSponsorships(where: { tournamentId_in: $tournamentIds }) {
					tournamentId,
					sponsor,
					amount
				}
			}
		`;

		try {
			const { data } = await this.apiClient.post("", {
				query,
				variables: { tournamentIds }
			});
			return data.data.tournamentSponsorships;
		} catch (err) {
			console.error("Error fetching tournament sponsorships:", err);
			throw err;
		}
	}

	async tournaments(tournamentIds: string[]): Promise<
		Array<{
			id: string;
			numberOfTeams: number;
			entryFee: BigInt;
			entryToken: string;
			registrationCloseTimestamp: number;
			refunded: boolean;
			rewardsClaimed: boolean;
			owner: string;
		}>
	> {
		const query = `
			query Tournaments($tournamentIds: [String!]) {
				tournaments(where: { id_in: $tournamentIds }) {
					id,
					numberOfTeams,
					entryFee,
					entryToken,
					registrationCloseTimestamp,
					refunded,
					rewardsClaimed,
					owner
				}
			}
		`;

		try {
			const { data } = await this.apiClient.post("", {
				query,
				variables: { tournamentIds }
			});

			return data.data.tournaments;
		} catch (err) {
			console.error("Error fetching tournaments:", err);
			throw err;
		}
	}
}
