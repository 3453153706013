import { useHomeState } from "@/modules/home/context";
import { useSelectedTeamState } from "@/modules/shared/context";
import { useLiveMatchState } from "@/modules/shared/context/hooks/useLiveMatchState";
import { memo } from "react";
import { JoinTournament } from "./components/JoinTournament";
import { LiveMatch } from "./components/LiveMatch";
import { PrepareNextMatch } from "./components/PrepareNextMatch";

export const NextMatch = memo(() => {
	const { teamInfo } = useSelectedTeamState();
	const { currentMatch } = useLiveMatchState();
	const { groupOfTeam } = useHomeState();

	if (currentMatch) {
		return <LiveMatch match={currentMatch} />;
	} else if (teamInfo?.nextMatch) {
		return <PrepareNextMatch match={teamInfo.nextMatch} />;
	} else {
		return <JoinTournament callToAction={groupOfTeam ? "exploreTournaments" : "joinMSL"} />;
	}
});
