import { inGameTokenService } from "@/dependencies";
import { BaseServerStateKeys } from "@/modules/shared/constants";
import { InGameToken } from "@metasoccer/metasoccer-types";

export const SHARED_QUERIES = {
	inGameTokens: {
		getKnowledge: (owner: string) => ({
			queryKey: [BaseServerStateKeys.IN_GAME_TOKENS, InGameToken.SCOUT_KNOWLEDGE, owner],
			queryFn: async () => {
				const { balance } = await inGameTokenService.getKnowledgeBalance();
				const txs = await inGameTokenService.getKnowledgeTransactions();
				return { balance, txs };
			}
		}),
		getTraining: (owner: string) => ({
			queryKey: [BaseServerStateKeys.IN_GAME_TOKENS, InGameToken.TRAINING, owner],
			queryFn: async () => {
				const { balance } = await inGameTokenService.getTrainingBalance();
				const txs = await inGameTokenService.getTrainingTransactions();
				return { balance, txs };
			}
		})
	}
};
