export function compact<T>(array: (T | null | undefined)[]): T[] {
	const result: T[] = [];

	for (const value of array) {
		if (value) {
			result.push(value);
		}
	}

	return result;
}
