import { useIsMobile } from "@/layouts/context";
import { useServerState } from "@/modules/core/react-query/hooks";
import { getCoveringBySpecificRole } from "@/modules/player/helpers/getCoveringBySpecificRole";
import { useGlobalState } from "@/modules/shared/context";
import { PLAYERS_QUERIES } from "@/modules/shared/queries";
import { CloseIcon, IconButton, Modal, RoleTypography, Typography } from "@metasoccer/metasoccer-ds";
import { LineupWithSkill, LockedPlayer, Player, PlayerSpecificRole } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { LineupSquad } from "../../../LineupSquad";
import { CssContent, CssHeader, CssTitle } from "./ChangePlayerModal.styles";

interface ChangePlayerModalProps {
	currentPlayer?: Player;
	isOpen: boolean;
	lineupWithSkill: LineupWithSkill;
	lockedPlayers?: LockedPlayer[];
	showSlotId: boolean;
	slotId: string;
	slotSpecificRoles?: PlayerSpecificRole[];
	onClose: () => void;
	onPlayerSelected: (player: Player) => void;
}

export const ChangePlayerModal: FC<ChangePlayerModalProps> = ({
	isOpen,
	currentPlayer,
	lineupWithSkill,
	lockedPlayers,
	showSlotId,
	slotId,
	slotSpecificRoles,
	onClose,
	onPlayerSelected
}) => {
	const { selectedTeam } = useGlobalState();

	const { data } = useServerState(PLAYERS_QUERIES.getPlayersWithShirtNumbersByTeamId(selectedTeam?.id), {
		enabled: !!selectedTeam
	});

	const players = data?.players ?? [];
	const shirtNumbers = data?.shirtNumbers ?? {};

	const isMobile = useIsMobile();

	const headerContent = (
		<CssHeader>
			<CssTitle>
				{showSlotId && (
					<Typography variant="body1" light>
						{slotId}
					</Typography>
				)}
				{slotSpecificRoles && (
					<RoleTypography
						covering={getCoveringBySpecificRole(slotSpecificRoles[0])}
						specificRoles={slotSpecificRoles}
					/>
				)}
				{(showSlotId || slotSpecificRoles?.length) && <Typography variant="body1">-</Typography>}
				<Typography variant="body1">
					{currentPlayer
						? `Changing ${currentPlayer.name.charAt(0)}. ${currentPlayer.lastName}`
						: "Select player"}
				</Typography>
			</CssTitle>
			<IconButton size="sm" icon={CloseIcon} onClick={onClose} />
		</CssHeader>
	);

	return (
		<Modal isOpen={isOpen} fullScreen={isMobile} headerContent={headerContent} size="xl" onClose={onClose}>
			<CssContent>
				<LineupSquad
					lineupWithSkill={lineupWithSkill}
					lockedPlayers={lockedPlayers}
					players={players}
					shirtNumbers={shirtNumbers}
					tableOptions={{ linkToPlayer: false }}
					onPlayerSelected={onPlayerSelected}
				/>
			</CssContent>
		</Modal>
	);
};
