import { Button, CloseIcon, IconButton, Modal, Typography } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { CssContent, CssHeader } from "./ErrorModal.styles";

export interface ErrorModalProps {
	children: ReactNode;
	title: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ErrorModal = memo<ErrorModalProps>(({ children, title, isOpen, onClose }) => {
	const footerContent = <Button fullWidth label="Ok" onClick={onClose} />;
	const headerContent = (
		<CssHeader>
			<Typography variant="body1Bold">{title}</Typography>
			<IconButton size="sm" icon={CloseIcon} onClick={onClose} />
		</CssHeader>
	);

	return (
		<Modal size="sm" isOpen={isOpen} onClose={onClose} footerContent={footerContent} headerContent={headerContent}>
			<CssContent>{children}</CssContent>
		</Modal>
	);
});
