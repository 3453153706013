import { TrainingHistoryCard } from "@/modules/training/views/Root/components/TrainingHistoryCard";
import { Modal, TrainingIcon, Typography } from "@metasoccer/metasoccer-ds";
import { TrainingGroup, TrainingHistory } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { CssContent, CssListWrapper, CssNoHistoryWrapper } from "./TrainingHistoryDialog.styled";

export interface TrainingHistoryDialogProps {
	isOpen: boolean;
	trainingHistory: TrainingHistory[];
	onLoadTrainingClicked: (trainingHistory: TrainingHistory) => void;
	onClose?: () => void;
}

export const TrainingHistoryDialog: FC<TrainingHistoryDialogProps> = ({
	isOpen,
	trainingHistory,
	onLoadTrainingClicked,
	onClose = () => {}
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} maxScreenWidth title="Training history" size="xxl">
			<CssContent>
				{trainingHistory.length > 0 && (
					<CssListWrapper>
						{trainingHistory.map((th, idx) => {
							const playerCount = th.trainingPlan.trainingGroups.reduce(
								(acc: number[], cur: TrainingGroup) => {
									const playerIds = cur.playerIds.filter((pid: number) => acc.indexOf(pid) < 0);
									return [...acc, ...playerIds];
								},
								[]
							).length;

							return (
								<TrainingHistoryCard
									key={idx}
									totalAttack={th.totalAttackProgression}
									totalDefense={th.totalDefenseProgression}
									totalMentalAndPhysical={th.totalMentalAndPhysicalProgression}
									drillCount={th.drillHistory.length}
									playerCount={playerCount}
									conditionCost={th.aggregateConditionCost}
									cost={th.trainingTokenCost}
									timestamp={th.timestamp}
									onLoadTrainingClicked={() => onLoadTrainingClicked(th)}
								/>
							);
						})}
					</CssListWrapper>
				)}

				{trainingHistory.length === 0 && (
					<CssNoHistoryWrapper>
						<TrainingIcon selected={false} style={{ width: "42px", height: "42px", cursor: "default" }} />
						<Typography variant="caption" light>
							No trainings yet
						</Typography>
					</CssNoHistoryWrapper>
				)}
			</CssContent>
		</Modal>
	);
};
