import styled from "styled-components";

export const CssNoticesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const CssNoticeWrapper = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`;

export const CssNoticeIconWrapper = styled.div`
	margin-right: 4px;
`;
