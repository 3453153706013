import { getCoveringBySpecificRole } from "@/modules/player/helpers/getCoveringBySpecificRole";
import { InjuryIndicator } from "@/modules/shared/components/InjuryIndicator";
import { RoleChip, Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { createColumnHelper } from "@tanstack/react-table";
import { LineupTablePlayer } from "../LastLineupTable";

const columnHelper = createColumnHelper<LineupTablePlayer>();

export const LAST_LINEUP_TABLE_COLUMNS = [
	columnHelper.accessor("specificRole", {
		id: "specificRole",
		header: () => (
			<div style={{ display: "flex", alignItems: "start", paddingLeft: "8px" }}>
				<Typography variant={"body2"}>Lineup</Typography>
			</div>
		),
		cell: ({ getValue }) => {
			return <RoleChip covering={getCoveringBySpecificRole(getValue())} specificRole={getValue()} />;
		}
	}),
	columnHelper.accessor("name", {
		id: "name",
		header: () => (
			<div style={{ display: "flex", alignItems: "start", paddingLeft: "8px" }}>
				<Typography variant={"body2"}>Player</Typography>
			</div>
		),
		cell: ({ row }) => {
			const player = row.original;
			return (
				<div
					style={{
						padding: "8px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%"
					}}>
					<Typography variant="subtitle2">{player.name}</Typography>
					{player.isInjured ? <InjuryIndicator injuredPlayer={player.isInjured} /> : null}
				</div>
			);
		}
	}),
	columnHelper.accessor("specialAbilities", {
		id: "specialAbilities",
		header: () => (
			<div style={{ display: "flex", alignItems: "start", justifyContent: "center", paddingLeft: "8px" }}>
				<Typography variant={"body2"}>S.A</Typography>
			</div>
		),
		cell: ({ getValue }) => {
			return (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Tooltip position="center">
						{{
							popper: getValue().map((a, key) => (
								<Typography key={key} variant={"body2"}>
									{a}
								</Typography>
							)),
							content: <Typography variant={"body2"}>{getValue().length}</Typography>
						}}
					</Tooltip>
				</div>
			);
		}
	}),
	columnHelper.accessor("overall", {
		id: "overall",
		header: () => (
			<div style={{ display: "flex", alignItems: "start", paddingLeft: "8px" }}>
				<Typography variant={"body2"}>Overall</Typography>
			</div>
		),
		cell: ({ getValue }) => {
			return (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Typography variant="body2">{getValue()}</Typography>
				</div>
			);
		}
	})
];
