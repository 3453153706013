import { useIsMobile } from "@/layouts/context";
import { MARKET_ROUTES } from "@/modules/market";
import { Button, Col, Row, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { useNavigate } from "react-router-dom";

import SquadPng from "./assets/Squad.png";

export const GetYourSquad = () => {
	const navigate = useNavigate();

	const isMobile = useIsMobile();
	const theme = useTheme();

	const goToMarketPage = () => {
		navigate(MARKET_ROUTES.ROOT.buildPath());
	};

	return (
		<div
			style={{
				background: theme.palette.backgrounds.codGray,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100%",
				padding: "12px 24px",
				width: "100%"
			}}>
			<Col gap={32} alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
				<Col gap={16} style={{ maxWidth: isMobile ? "100%" : "50%" }}>
					<Typography variant={isMobile ? "h5" : "h4"} textAlign="center">
						Get your squad
					</Typography>
					<Col gap={12} alignItems="center">
						<Typography variant="body2" textAlign="center">
							You need 11 players to start competing
						</Typography>
						<Typography variant="body2" light textAlign="center">
							The easiest way to start is to buy a bundle of players. So go to our marketplace, explore
							the bundles and get your first squad!
						</Typography>
					</Col>
				</Col>
				<Row justifyContent="center">
					<img src={SquadPng} height="392px" alt="Get your squad" />
				</Row>
				<Button variant="filled" label="Go to market" onClick={goToMarketPage} />
			</Col>
		</div>
	);
};
