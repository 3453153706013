import { MSL_ROUTES, TabOptions, useMSLState } from "@/modules/msl";
import getMSLRankBorderColors from "@/modules/msl/utils/getMSLRankBorderColors";
import { InfoMessage, StandingsList } from "@/modules/shared/components";
import { ArrowRightIcon, useTheme } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { CardStyled } from "./StandingsCard.styles";

export const StandingsCard = memo(() => {
	const navigate = useNavigate();
	const theme = useTheme();

	const { group, groupId } = useMSLState();

	const navigateToStandings = () => navigate(`${MSL_ROUTES.GROUP.buildPath(groupId)}?tab=${TabOptions.STANDINGS}`);

	return (
		<CardStyled
			title="Standings"
			subtitle={group?.name}
			HeaderRight={<ArrowRightIcon size="md" />}
			onClick={navigateToStandings}>
			{group?.standings ? (
				<StandingsList
					borderColors={getMSLRankBorderColors(theme.palette)}
					standing={group.standings}
					sliceTeams={false}
				/>
			) : (
				<InfoMessage message="Standings will appear when the tournament starts" />
			)}
		</CardStyled>
	);
});
