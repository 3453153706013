import { CardList, PlayerCard } from "@/modules/shared/components";
import { FormationSlot, LinedPlayer, LineupWithSkill, LockedPlayer, Player } from "@metasoccer/metasoccer-types";
import { FC, useCallback } from "react";

interface PlayerCardListProps {
	lineupWithSkill?: LineupWithSkill;
	lockedPlayers?: LockedPlayer[];
	players: Player[];
	selectable?: boolean;
	selectMultiple?: boolean;
	onItemClick?: (player: Player) => void;
	onSelectionChange?: (players: Player[]) => void;
}

export const PlayerCardList: FC<PlayerCardListProps> = ({
	lineupWithSkill,
	players,
	selectable,
	selectMultiple,
	onItemClick,
	onSelectionChange
}) => {
	const getSlotByPlayerId = useCallback(
		(playerId: number) => {
			const linedPlayers: LinedPlayer[] = lineupWithSkill?.lineup.linedPlayers;
			const benchedPlayers: Player[] = lineupWithSkill?.lineup.playersInBench;

			const linedPlayer = linedPlayers?.find(({ player }) => player.id === playerId);
			const benchedPlayerIndex = benchedPlayers?.findIndex(({ id }) => id === playerId);

			if (linedPlayer) {
				const slot: FormationSlot = lineupWithSkill!.lineup.formation.slots[linedPlayer.slotId];

				return {
					slotId: `L${linedPlayer.slotId + 1}`,
					slotRole: slot.role,
					slotSpecificRoles: slot.playerSpecificRoles
				};
			} else if (benchedPlayerIndex >= 0) {
				return {
					slotId: `B${benchedPlayerIndex + 1}`
				};
			} else {
				return undefined;
			}
		},
		[lineupWithSkill]
	);

	return (
		<CardList
			items={players}
			selectable={selectable}
			selectMultiple={selectMultiple}
			getItemId={({ id }) => `${id}`}
			onCardClick={onItemClick}
			onSelectionChange={onSelectionChange}>
			{(player) => {
				const slot = getSlotByPlayerId(player.id);

				return (
					<PlayerCard
						player={player}
						slotId={slot?.slotId}
						slotRole={slot?.slotRole}
						slotSpecificRoles={slot?.slotSpecificRoles}
					/>
				);
			}}
		</CardList>
	);
};
