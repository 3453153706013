import React, { FC } from "react";
import { CheckIcon } from "@metasoccer/metasoccer-ds";
import { CssOptionWrapper } from "./DropdownItem.styled";
import { useTheme } from "styled-components";

interface DropdownItemProps {
	children?: JSX.Element;
	isSelected?: boolean;
	onClick?: () => void;
}

export const DropdownItem: FC<DropdownItemProps> = ({ children, isSelected = false, onClick }) => {
	const theme = useTheme();

	const handleClick = () => {
		if (!onClick) {
			return;
		}

		onClick();
	};

	return (
		<CssOptionWrapper onClick={handleClick}>
			{children}
			{isSelected && <CheckIcon size="sm" color={theme.palette.system.perfume} />}
		</CssOptionWrapper>
	);
};
