import { BaseServerStateKeys } from "@/modules/shared/constants";
import { scoutService } from "@/dependencies";

export const SCOUTS_QUERIES = {
	getScoutById: (scoutId: number) => ({
		queryKey: [BaseServerStateKeys.SCOUTS, String(scoutId)],
		queryFn: () => scoutService.getScoutById(scoutId)
	}),
	getScoutsByIds: (scoutIds: number[]) => ({
		queryKey: [BaseServerStateKeys.SCOUTS, `:${scoutIds.join("-")}`],
		queryFn: () => scoutService.getScoutsByIds(scoutIds)
	})
};
