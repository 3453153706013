export enum MatchSituationType {
	// Plays
	SimpleGoal = "SimpleGoal",
	GoalFromCorner = "GoalFromCorner",
	GoalFromDirectFoul = "GoalFromDirectFoul",

	// Shots
	ShotOnTarget = "ShotOnTarget",
	ShotOffTarget = "ShotOffTarget",

	// Moments
	MatchStart = "MatchStart",
	HalfTimeStart = "HalfTimeStart",
	HalfTimeEnd = "HalfTimeEnd",
	MatchEnd = "MatchEnd",
	KoPenalties = "KoPenalties"
}
