export enum IdType {
	Team = "T",
	Club = "C",
	Tournament = "TN",
	Match = "M",
	TrainingGroup = "TG",
	Purchase = "P",
	Item = "I",
	Inventory = "INV",
	MatchScheduled = "MS",
	TournamentEdition = "TNE",
	TournamentGroup = "TNG"
}
