import { useIsMobile } from "@/layouts/context";
import { Row, Tab, TabsSize, Tapbar } from "@metasoccer/metasoccer-ds";
import { ReactElement, ReactNode, memo, useEffect, useMemo } from "react";
import { FixedAndGrowLayout } from "../FixedAndGrowLayout";
import {
	ContentWrapperStyled,
	InnerWrapperStyled,
	OuterWrapperStyled,
	RightWrapperStyled,
	TabGroupStyled,
	TabsStyled,
	TabsWrapperStyled
} from "./TapbarsLayout.styles";
import useSyncWithQS from "./useSyncWithQS";

export interface TapbarSpec {
	key: string;
	title: string;
	preventUnmount?: boolean;
	AfterTitle?: ReactElement;
	render: (hidden?: boolean) => ReactNode;
}

export interface TapbarsLayoutProps {
	syncWithQS?: boolean;
	tabs: TapbarSpec[];
	tabsPadding?: number | string;
	tabsSize?: TabsSize;
	BottomComponent?: ReactNode;
	LeftComponent?: ReactNode;
	RightComponent?: ReactNode;
	onActiveTabChange?: (activeTab: string) => void;
}

export const TapbarsLayout = memo<TapbarsLayoutProps>(
	({
		syncWithQS = false,
		tabs,
		tabsPadding,
		tabsSize,
		BottomComponent,
		LeftComponent,
		RightComponent,
		onActiveTabChange
	}) => {
		const isMobile = useIsMobile();
		const { activeTabIndex, setActiveTabIndex } = useSyncWithQS(tabs, syncWithQS);

		useEffect(() => {
			if (!onActiveTabChange) return;
			onActiveTabChange(tabs[activeTabIndex].key);
		}, [activeTabIndex]);

		const [tapbarSize, TapbarOrTab] = useMemo(
			() => [(isMobile ? "md" : "lg") as "md" | "lg", isMobile ? Tab : Tapbar],
			[isMobile]
		);

		return (
			<FixedAndGrowLayout gap={0}>
				<OuterWrapperStyled>
					<InnerWrapperStyled>
						<TabsWrapperStyled tabsPadding={tabsPadding}>
							{LeftComponent}
							<TabsStyled index={activeTabIndex} onIndexChange={(tab: number) => setActiveTabIndex(tab)}>
								<TabGroupStyled size={isMobile ? "md" : tabsSize}>
									{tabs.map(({ key, title, AfterTitle }, index) => (
										<TapbarOrTab key={key} index={index} size={tapbarSize}>
											<Row gap={8} alignItems="center">
												{title}
												{AfterTitle}
											</Row>
										</TapbarOrTab>
									))}
								</TabGroupStyled>
							</TabsStyled>
						</TabsWrapperStyled>
						{RightComponent && <RightWrapperStyled>{RightComponent}</RightWrapperStyled>}
					</InnerWrapperStyled>
					{BottomComponent}
				</OuterWrapperStyled>
				<>
					{tabs.map(({ key, preventUnmount, render }) =>
						(tabs[activeTabIndex] && tabs[activeTabIndex].key === key) || preventUnmount ? (
							<ContentWrapperStyled key={key}>
								{render(tabs[activeTabIndex].key !== key)}
							</ContentWrapperStyled>
						) : undefined
					)}
				</>
			</FixedAndGrowLayout>
		);
	}
);
