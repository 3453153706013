import { Divider, RedIcon, Tooltip, Typography, YellowIcon } from "@metasoccer/metasoccer-ds";
import { CustomTournamentTeamInfoDTO, TournamentCardFrontend } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import groupBy from "../../utils/groupBy";
import { MultiplierDecorator } from "../MultiplierDecorator";
import { Paragraph } from "../Paragraph";

interface PlayerCardsIndicatorProps {
	sanctionedPlayerCards: TournamentCardFrontend[];
	size?: "sm" | "md";
	isTable?: boolean;
	teamInfo?: CustomTournamentTeamInfoDTO;
}

export const PlayerCardsIndicator = memo<PlayerCardsIndicatorProps>(
	({ sanctionedPlayerCards, size = "sm", isTable }) => {
		const redCards = sanctionedPlayerCards.filter((card) => card.type === "Red");
		const yellowCards = sanctionedPlayerCards.filter((card) => card.type === "Yellow");
		const yellowGroupedCards = groupBy(yellowCards, "tournamentName");

		return (
			<>
				{!isTable && (
					<div style={{ display: "flex", alignItems: "center", flexGrow: 0 }}>
						{redCards.length > 0 ? (
							<RedIcon size={size} />
						) : (
							yellowCards.length > 0 && <YellowIcon size={size} />
						)}
					</div>
				)}

				{isTable && (
					<>
						{yellowCards.length > 0 && (
							<Tooltip position="center">
								{{
									popper: (
										<>
											{Object.entries(yellowGroupedCards).map(
												([tournamentName, cards], index, array) => (
													<div key={index}>
														<Typography variant="captionBold">
															{`${cards.length} / ${cards[0].maxYellowCards}  ·  ${tournamentName}`}
														</Typography>
														{index < array.length - 1 && (
															<div style={{ padding: "8px" }}>
																<Divider color="greyscale.gondola" />
															</div>
														)}
													</div>
												)
											)}
										</>
									),
									content: (
										<MultiplierDecorator n={isTable ? yellowCards.length : 1}>
											<YellowIcon size={size} />
										</MultiplierDecorator>
									)
								}}
							</Tooltip>
						)}
						{redCards.length > 0 && (
							<Tooltip position="center">
								{{
									popper: (
										<>
											{redCards.map((card, index) => (
												<div key={index}>
													<div style={{ paddingBottom: "4px" }}>
														<Typography variant="captionBold">
															{card.tournamentName}
														</Typography>
													</div>
													<Paragraph variant="description" light>
														{`${card.sanctionedGames} game suspension`}
													</Paragraph>
													{index < redCards.length - 1 && (
														<div style={{ padding: "8px" }}>
															<Divider color="greyscale.gondola" />
														</div>
													)}
												</div>
											))}
										</>
									),
									content: <RedIcon size={size} />
								}}
							</Tooltip>
						)}
					</>
				)}
			</>
		);
	}
);
