export default function getRoleInitials(role) {
  switch (role) {
    case "Right Wing-Back":
      return "RWB";
    case "Right Back":
      return "RB";
    case "Center Back":
      return "CB";
    case "Left Back":
      return "LB";
    case "Left Wing-Back":
      return "LWB";
    case "Central Defensive Midfielder":
      return "CDM";
    case "Right Midfielder":
      return "RM";
    case "Center Midfielder":
      return "CM";
    case "Left Midfielder":
      return "LM";
    case "Central Attacking Midfielder":
      return "CAM";
    case "Right Forward":
      return "RF";
    case "Center Forward":
      return "CF";
    case "Left Forward":
      return "LF";
    case "Right Wing":
      return "RW";
    case "Striker":
      return "ST";
    case "Left Wing":
      return "LW";
    case "Goalkeeper":
      return "GK";
    default:
      return "-";
  }
}