import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";

export const HOME_ROUTES = {
	ROOT: {
		path: "/home",
		buildPath: () => "/home"
	},

	PLAY: {
		path: "/play",
		buildPath: () => `${HOME_ROUTES.ROOT.path}/play`
	},
	PVE_ROOT: {
		path: "/welcomeleague",
		buildPath: () => `${HOME_ROUTES.ROOT.path}/welcomeleague`
	},
	RESULTS: {
		path: "/results/:tournamentId",
		buildPath: (tournamentId: MetaSoccerId<IdType.Tournament>) => `${HOME_ROUTES.ROOT.path}/results/${tournamentId}`
	},
	STANDINGS: {
		path: "/standings/:tournamentId",
		buildPath: (tournamentId: MetaSoccerId<IdType.Tournament>) =>
			`${HOME_ROUTES.ROOT.path}/standings/${tournamentId}`
	},
	PVP_ROOT: {
		path: "/pvp",
		buildPath: () => `${HOME_ROUTES.ROOT.path}/pvp`
	},
	PLAYOFFS: {
		path: "/worldcup-playoffs",
		buildPath: () => `${HOME_ROUTES.ROOT.path}${HOME_ROUTES.PLAYOFFS.path}`
	},
	PLAYOFFS_INFO: {
		path: "/worldcup-playoffs/info",
		buildPath: () => `${HOME_ROUTES.ROOT.path}${HOME_ROUTES.PLAYOFFS_INFO.path}`
	},
	PVP_TOURNAMENTS: {
		path: "/pvp/tournaments",
		buildPath: () => `${HOME_ROUTES.ROOT.path}/pvp/tournaments`
	},
	PVP_DIVISIONS: {
		path: "/pvp/divisions",
		buildPath: () => `${HOME_ROUTES.ROOT.path}/pvp/divisions`
	}
};
