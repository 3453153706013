import { Loading } from "@/modules/shared/components";
import { NavigateBackButton } from "@/modules/shared/components/NavigateBackButton";
import { TapbarsLayout } from "@/modules/shared/components/TapbarsLayout";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { useNavigate } from "react-router-dom";
import { MSL_ROUTES } from "../..";
import { useMSLState } from "../../context";
import { Overview, Standings } from "./components";
import { GroupSelector } from "./components/GroupSelector";

export enum TabOptions {
	OVERVIEW = "overview",
	STANDINGS = "standings",
	MATCHES = "matches"
}

export const MSLPage = () => {
	const navigate = useNavigate();

	const { isLoading, group, groupId } = useMSLState();

	if (isLoading) {
		return <Loading />;
	}

	const navigateToGroup = (groupId: MetaSoccerId<IdType.TournamentGroup>) => {
		navigate({
			pathname: MSL_ROUTES.GROUP.buildPath(groupId),
			search: window.location.search
		});
	};

	return (
		<TapbarsLayout
			syncWithQS
			tabs={[
				{
					key: TabOptions.OVERVIEW,
					title: "Overview",
					render: () => <Overview />
				},
				{
					key: TabOptions.STANDINGS,
					title: "Standings",
					render: () => <Standings group={group!} />
				}
			]}
			LeftComponent={<NavigateBackButton />}
			RightComponent={
				<GroupSelector initialGroup={group!} selectedGroupId={groupId} onGroupSelected={navigateToGroup} />
			}
		/>
	);
};
