import { AxiosError } from "axios";
import { makeUnauthorizedAPIRequestEvent } from "@/modules/core/http/events";
import { ResponseInterceptor, Response } from "@metasoccer/metasoccer-types";

const HTTP_STATUS_CODE_UNAUTHORIZED = 401;

export class ResponseUnauthenticatedInterceptor implements ResponseInterceptor {
	public onSuccess(response: Response): Response | Promise<Response> {
		return response;
	}

	public onError(error: any): any {
		// Ignore non-axios errors
		if (!(error instanceof AxiosError)) {
			return Promise.reject(error);
		}

		const axiosError = error as AxiosError;
		if (!axiosError.response) {
			return Promise.reject(error);
		}

		if (axiosError.response.status === HTTP_STATUS_CODE_UNAUTHORIZED) {
			document.dispatchEvent(makeUnauthorizedAPIRequestEvent(error));
		}

		const responseError: Response = {
			data: axiosError.response.data,
			status: axiosError.response.status
		};

		return Promise.reject(responseError);
	}
}
