import styled, { css } from "styled-components";

export const CssScheduledMatchPreviewWrapper = styled.div<{ isCancelled?: boolean; isForfeited?: boolean }>(
	({ theme, isCancelled, isForfeited }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 14px;

		padding: 10px 0;
		opacity: ${isCancelled ? 0.4 : 1};

		${!isCancelled &&
		!isForfeited &&
		`
		cursor: pointer;
		transition-property: transform;
		transition-duration: var(--hover-transition-duration);
		transition-timing-function: var(--hover-transition-curve);

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);
				background-color: ${theme.palette.greyscale.gondola};
			}
		}`}
	`
);

export const CssDateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	min-width: 90px;
`;

export const CssTeamWrapper = styled.div<{ reverse?: boolean }>(
	({ theme, reverse }) => css`
		display: flex;
		align-items: center;
		justify-content: ${reverse ? "start" : "end"};
		gap: 4px;
		width: 35%;
	`
);
