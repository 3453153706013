import { Col, InjuredIcon, Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { InjuredLockedPlayer } from "@metasoccer/metasoccer-types";
import { format } from "date-fns";
import { memo } from "react";
import { convertTimestampToDays } from "../../helpers/convertTimestampToDays";

interface InjuryIndicatorProps {
	injuredPlayer: InjuredLockedPlayer;
}

export const InjuryIndicator = memo<InjuryIndicatorProps>(({ injuredPlayer }) => {
	return (
		<Tooltip position="center">
			{{
				popper: (
					<Col gap={8}>
						<Typography variant="captionBold">{injuredPlayer?.injury}</Typography>
						<Col gap={4}>
							<Typography variant="description" light>
								{convertTimestampToDays(injuredPlayer?.expirationTimestamp)} recovery days (
								{format(injuredPlayer?.expirationTimestamp, "Pp")}).
							</Typography>
						</Col>
					</Col>
				),
				content: (
					<div style={{ display: "flex", alignItems: "center", flexGrow: 0 }}>
						<InjuredIcon size="sm" />
					</div>
				)
			}}
		</Tooltip>
	);
});
