import { Container, Loading, TapbarsLayout } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { Col, Row } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

enum TabOptions {
	ACCOUNT = "account"
}

export const AccountPage = memo(() => {
	const { address } = useGlobalState();

	if (!address) {
		return <Loading />;
	}

	return (
		<TapbarsLayout
			tabsSize="lg"
			tabs={[
				{
					key: TabOptions.ACCOUNT,
					title: address,
					render: () => (
						<Container paddingY={0}>
							<Col>Cool stuff coming soon</Col>
							<Row gap={24}>
								<Col>Discord</Col>
								<Col>X</Col>
							</Row>
						</Container>
					)
				}
			]}
		/>
	);
});
