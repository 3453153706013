import styled, { css } from "styled-components";

export const CssMatchTeamLineupWrapper = styled.div`
	flex-direction: column;
	display: flex;
	width: 100%;
`;

export const CssFormationTableHeader = styled("div")(
	({ theme }) => css`
		height: 40px;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0 8px;
		justify-content: space-between;
		border: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssMatchTeamLineupTableHeader = styled("div")(
	({ theme }) => css`
		height: 40px;
		width: 100%;
		background-color: ${theme.palette.backgrounds.codGray};
		display: flex;
		align-items: center;
		padding: 0 8px;
		justify-content: space-between;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssClubContent = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const CssTbodyTr = styled.tr(
	({ theme }) => css`
		height: 40px;

		& td:first-child {
			padding-left: 8px;
			width: 62px;
			border-left: 1px solid ${theme.palette.greyscale.gondola};
		}

		& td:last-child {
			width: 60px;
		}
	`
);

export const CssTBody = styled("tbody")(
	({ theme }) => css`
		background-color: ${theme.palette.backgrounds.cinder};
	`
);

export const CssTHead = styled("thead")(
	({ theme }) => css`
		background-color: ${theme.palette.backgrounds.codGray};
	`
);

export const CssTbodyTd = styled.td(
	({ theme }) => css`
		border-right: 1px solid ${theme.palette.greyscale.gondola};
		border-left: 1px solid ${theme.palette.greyscale.gondola};
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssTheadTr = styled.tr(
	({ theme }) => css`
		height: 40px;

		th {
			border-bottom: 1px solid ${theme.palette.greyscale.gondola};
			border-left: 1px solid ${theme.palette.greyscale.gondola};
			border-left: 1px solid ${theme.palette.greyscale.gondola};
		}

		& th:last-child {
			border-right: 1px solid ${theme.palette.greyscale.gondola};
			padding: 0 8px;
		}
	`
);

export const CssTableSeparator = styled.th(
	({ theme }) => css`
		border-left: 1px solid ${theme.palette.greyscale.gondola};
		border-right: 1px solid ${theme.palette.greyscale.gondola};
	`
);
