import styled, { css } from "styled-components";

export const CssFilteredStandingWrapper = styled.div`
	flex: 1 1 0%;
	overflow-y: auto;
	height: 100%;
`;

export const CssFiltersWrapper = styled.div`
	display: flex;
	padding: 24px;
	justify-content: space-between;
`;

export const CssClubNameWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

interface CssPositionProps {
	color: string;
}

export const CssPosition = styled.div<CssPositionProps>(
	({ color }) => css`
		display: flex;
		height: 60px;
		justify-content: center;
		align-items: center;
		border-left: 4px solid ${color};
	`
);
