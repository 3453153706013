import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled, { css } from "styled-components";
export var CssStepperModalHeaderWrapper = styled.div(function (_ref) {
  var theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n\t\tpadding: 12px;\n\t\tgap: 12px;\n\t\tborder-bottom: 1px solid ", ";\n\n\t\t", " {\n\t\t\tpadding: 24px;\n\t\t\tgap: 24px;\n\t\t}\n\t"])), function (_ref2) {
    var theme = _ref2.theme;
    return theme.palette.greyscale.gondola;
  }, theme.breakpoints.up("desktopMin"));
});
export var CssHorizontalStepsWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tflex: 1 1 0%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 24px;\n"])));
export var CssHorizontalStepWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 24px;\n"])));
export var CssHorizontalSeparator = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\twidth: 56px;\n\theight: 2px;\n\tbackground: ", ";\n"])), function (_ref3) {
  var theme = _ref3.theme;
  return theme.palette.backgrounds.tundora;
});
export var CssHeaderAfterWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 8px;\n"])));