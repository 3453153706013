import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
export var useIsMobile = function useIsMobile() {
  var _useState = useState(window.innerWidth),
    _useState2 = _slicedToArray(_useState, 2),
    windowWidth = _useState2[0],
    setWindowWidth = _useState2[1];
  var theme = useTheme();
  useEffect(function () {
    // const debouncedWidthUpdate = debounce(() => {
    // 	setWindowWidth(window.innerWidth);
    // }, 0);
    var listener = function listener() {
      return setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", listener);
    return function () {
      window.removeEventListener("resize", listener);
    };
  }, []);
  return windowWidth < theme.breakpoints.values.desktopMin;
};