import { Typography } from "@metasoccer/metasoccer-ds";
import React, { FC } from "react";
import { CssScoreMatchResultTeam } from "./ScoreMatchResultTeam.styles";

interface ScoreMatchResultTeamProps {
	teamName: string;
	clubLogo: React.ReactNode;
	isReverseLayout?: boolean;
}
export const ScoreMatchResultTeam: FC<ScoreMatchResultTeamProps> = ({
	teamName,
	clubLogo,
	isReverseLayout = false
}) => {
	return (
		<CssScoreMatchResultTeam isReverseLayout={isReverseLayout}>
			<Typography variant="caption">{teamName}</Typography>
			{clubLogo}
		</CssScoreMatchResultTeam>
	);
};
