import { useServerState } from "@/modules/core/react-query/hooks";
import { HOME_QUERIES } from "@/modules/home";
import { MSL_QUERIES } from "@/modules/msl";
import { useGlobalState } from "@/modules/shared/context";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import React from "react";
import { useParams } from "react-router-dom";

type MSLContextProviderProps = { children: React.ReactNode };

const useMSLState = () => {
	const { groupId } = useParams();

	if (!groupId) {
		throw new Error("groupId is required");
	}

	const { selectedTeam } = useGlobalState();

	const hasTeam = Boolean(!!selectedTeam?.id);

	const { data: group, isLoading: isLoadingGroup } = useServerState(
		MSL_QUERIES.getGroup(groupId as MetaSoccerId<IdType.TournamentGroup>)
	);

	const {
		data: groupOfTeam,
		isLoading: isLoadingGroupOfTeam,
		refetch: refetchGroupOfTeam
	} = useServerState(HOME_QUERIES.groupInfoByTeam(selectedTeam?.id), { enabled: hasTeam });

	const { data: groupOfTopMSL, isLoading: isLoadingGroupOfTopMSL } = useServerState(HOME_QUERIES.groupInfoOfTopMSL());

	const {
		data: joined,
		isLoading: isLoadingJoined,
		refetch: refetchJoined
	} = useServerState(MSL_QUERIES.getJoined(selectedTeam?.id), { enabled: hasTeam });

	const { data: teamInfo, isLoading: isLoadingTeamInfo } = useServerState(
		MSL_QUERIES.getGroupTeamInfo(groupId as MetaSoccerId<IdType.TournamentGroup>, selectedTeam?.id),
		{ enabled: hasTeam }
	);

	return {
		isLoading:
			isLoadingGroup || isLoadingGroupOfTeam || isLoadingGroupOfTopMSL || isLoadingJoined || isLoadingTeamInfo,
		group,
		groupId: groupId as MetaSoccerId<IdType.TournamentGroup>,
		groupOfTeam,
		groupOfTopMSL,
		joined,
		nextMatch: teamInfo?.nextMatch,
		teamStats: teamInfo?.teamStats,
		refetchGroupOfTeam,
		refetchJoined
	};
};

const MSLContext = React.createContext<ReturnType<typeof useMSLState> | undefined>(undefined);

const MSLContextProvider = ({ children }: MSLContextProviderProps) => {
	const mslState = useMSLState();

	return <MSLContext.Provider value={mslState}>{children}</MSLContext.Provider>;
};

export { MSLContext, MSLContextProvider };
