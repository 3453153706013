import { useIsMobile } from "@/layouts/context";
import { CoinSize, MsuCoin, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { FC } from "react";
import { getImgUrl } from "../../utils";
import { CssTokenRow } from "./Tokens.styled";

interface MsuTokenProps {
	quantity: number;
	size?: CoinSize;
	minWidth?: string;
	labelWidth?: string;
}

export const MsuToken: FC<MsuTokenProps> = ({ quantity, size = "sm", minWidth, labelWidth = "auto" }) => {
	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;

	const isMobile = useIsMobile();

	const sizeToPx: Record<CoinSize, number> = {
		xs: 12,
		sm: 20,
		md: 28
	};

	const variant = isMobile ? (size === "xs" ? "caption" : "body2") : "body2";

	return (
		<CssTokenRow size={size} minWidth={minWidth}>
			{isPlayToAirdropEnabled ? (
				<img
					alt="MetaSoccer Silver"
					src={getImgUrl("https://assets.metasoccer.com/tokens/mss.png")}
					width={sizeToPx[size]}
					height={sizeToPx[size]}
				/>
			) : (
				<MsuCoin size={size} />
			)}
			<Typography variant={variant} style={{ width: labelWidth }}>
				{quantity.toLocaleString(undefined, {
					maximumFractionDigits: 2
				})}
			</Typography>
		</CssTokenRow>
	);
};
