import React, { FC } from "react";
import { TournamentMatchResult } from "@metasoccer/metasoccer-types";
import { CssLastMatchesWrapper } from "./LastMatches.styled";
import { ResultChip } from "@metasoccer/metasoccer-ds";

interface LastMatchesProps {
	results: TournamentMatchResult[];
}
export const LastMatches: FC<LastMatchesProps> = ({ results }) => (
	<CssLastMatchesWrapper>
		{results.map((result, idx) => (
			<ResultChip key={`${idx}-${result}`} result={result} />
		))}
	</CssLastMatchesWrapper>
);
