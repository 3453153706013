export class WalletAddress {
	constructor(private readonly wallet: string) {
		this.ensureItsValidWalletAddress();
	}

	get address(): string {
		return this.wallet.toLowerCase();
	}

	private ensureItsValidWalletAddress(): void {
		const addressRegex = /^(0x)?[0-9a-f]{40}$/i;
		if (!addressRegex.test(this.wallet)) {
			console.error(`Wallet "${this.wallet}" is not a valid Ethereum wallet address`);
		}
	}

	equals(maybeWallet: unknown): boolean {
		if (!(maybeWallet instanceof WalletAddress)) {
			return false;
		}

		return this.wallet === maybeWallet.wallet;
	}
}
