import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import { Expandable, Typography } from "@peersyst/react-components";
import styled, { css } from "styled-components";
export var AccordionRoot = styled(Expandable)(function (_ref) {
  var theme = _ref.theme,
    disabled = _ref.disabled;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\t", ";\n\n\t\t.ExpandableDisplay {\n\t\t\tborder-bottom: 1px solid ", ";\n\t\t\t.Typography {\n\t\t\t\t", ";\n\t\t\t}\n\t\t}\n\t\t.ExpandableDisplay:hover {\n\t\t\tbackground: ", ";\n\t\t}\n\t"])), disabled ? "pointer-events: none;color:".concat(theme.palette.greyscale.scorpion, ";") : "", theme.palette.greyscale.gondola, disabled ? "color:".concat(theme.palette.greyscale.scorpion, ";") : "", theme.palette.backgrounds.codGray);
});
export var TypographyAccordion = styled(Typography)(function (_ref2) {
  var _theme$fonts;
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tfont-size: 0.875rem;\n\t\tfont-family: ", ";\n\t\tcolor: ", ";\n\t"])), (_theme$fonts = theme.fonts) == null ? void 0 : _theme$fonts.spaceGrotesk, theme.palette.greyscale.mercury);
});