import styled, { css } from "styled-components";
import { Typography } from "@metasoccer/metasoccer-ds";

export const CssScoutCardWrapper = styled.div`
	height: 125px;
	width: 100%;
	display: flex;
	background-color: ${({ theme }) => theme.palette.backgrounds.codGray};
	position: relative;
`;

interface CssScoutImageProps {
	imageUrl: string;
}

export const CssScoutImage = styled.div<CssScoutImageProps>`
	flex: 0 0 auto;
	height: 100%;
	width: 100px;
	display: flex;
	background: ${({ imageUrl }) => `url('${imageUrl}')`};
	background-repeat: no-repeat;
	background-size: 90%;
	background-position: center bottom;
	transform: scale3d(-1, 1, 1);
`;

export const CssScoutContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 0 0 8px;
`;

export const CssFiller = styled.div`
	flex: 1 1 0%;
`;

export const CssGreenDot = styled.div(
	({ theme }) => css`
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: ${theme.palette.system.pastelGreen};
	`
);

export const CssVerticalWrapper = styled.div(
	({ theme }) => css`
		position: relative;
		min-width: 204px;
		max-width: 360px;
		height: 288px;
		background: ${theme.palette.backgrounds.codGray};
		cursor: pointer;

		transition-property: transform, background;
		transition-duration: var(--hover-transition-duration);
		transition-timing-function: var(--hover-transition-curve);

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);
				background: var(--hover-background);
			}
		}
	`
);

export const CssVerticalBackground = styled.div<CssScoutImageProps>(
	({ imageUrl, theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: ${`url('${imageUrl}')`};
		background-repeat: no-repeat;
		background-size: 80%;
		background-position: 40px bottom;
	`
);

export const CssVerticalBackgroundOverlay = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 20%;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;

		background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0.8) 45%, #151515 100%);
	`
);

export const CssVerticalContent = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;

		display: flex;
		flex-direction: column;
	`
);

export const CssVerticalTop = styled.div(
	({ theme }) => css`
		flex: 1 1 0%;
		padding: 20px 16px;
		display: flex;
		flex-direction: column;
		gap: 32px;
	`
);

export const CssVerticalSkillPotentialRoleWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
	`
);

export const CssVerticalQualityPotential = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: flex-end;
		gap: 2px;
	`
);

export const CssVerticalScoutInfo = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 12px;
	`
);

export const CssVerticalNameWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
	`
);

export const CssVerticalNameText = styled(Typography)(
	({ theme }) => css`
		white-space: nowrap;
		text-overflow: ellipsis;
	`
);

export const CssVerticalInfoWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: 24px;
	`
);

export const CssVerticalBottom = styled.div(
	({ theme }) => css`
		border-top: 1.5px solid ${theme.palette.backgrounds.cinder};
		background: ${theme.palette.backgrounds.codGray};
	`
);

export const CssScoutingTimeRemaining = styled.div(
	({ theme }) => css`
		width: 100%;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	`
);

export const CssScoutingTimeRemainingText = styled(Typography)(
	({ theme }) => css`
		text-transform: uppercase;
	`
);

export const CssScoutingCheckPlayers = styled.div(
	({ theme }) => css`
		width: 100%;
		height: 45px;
		background: ${theme.palette.greyscale.white};
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	`
);

export const CssScoutingCheckPlayersText = styled(Typography)(
	({ theme }) => css`
		text-transform: uppercase;
		color: ${theme.palette.backgrounds.cinder};
	`
);
