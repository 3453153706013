import { CssShirtLayer, CssTextLayer, CssTypography, CssWrapper } from "./ConnectWalletShirt.styled";
import { useSpring } from "react-spring";

export const ConnectWalletShirt = () => {
	const growSpring = useSpring({
		from: {
			scale3d: [0.25, 0.25, 1]
		},
		to: {
			scale3d: [1, 1, 1]
		},
		config: {
			mass: 0.75,
			friction: 20
		}
	});

	return (
		<CssWrapper style={{ transform: growSpring.scale3d.to((x, y, z) => `scale3d(${x}, ${y}, ${z})`) }}>
			<CssShirtLayer />
			<CssTextLayer>
				<CssTypography variant="h5">Connect your wallet to play MetaSoccer</CssTypography>
			</CssTextLayer>
		</CssWrapper>
	);
};
