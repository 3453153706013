import { PlayerRoleColor } from "@/modules/shared/constants/colors";
import { Role } from "@metasoccer/metasoccer-types";
import styled, { css } from "styled-components";

interface CssPlayerCardWrapperProps {
	readonly isActive: boolean;
	readonly isSelectable: boolean;
	readonly isForbiddenToBeSelected: boolean;
	readonly indicatorColor?: string;
	readonly selected: boolean;
}

export const CssPlayerCardWrapper = styled.div<CssPlayerCardWrapperProps>`
	height: 124px;
	width: 100%;
	display: flex;
	background-color: ${({ theme }) => theme.palette.backgrounds.codGray};
	border: 2px solid ${({ isActive, theme }) => (isActive ? theme.palette.greyscale.white : "transparent")};
	outline: ${({ selected }) => selected && "1.5px solid white"};
	position: relative;
	opacity: ${({ isForbiddenToBeSelected }) => isForbiddenToBeSelected && "0.4"};
	overflow: hidden;

	${({ isSelectable }) =>
		isSelectable &&
		css`
			cursor: pointer;
		`}

	${({ indicatorColor }) =>
		indicatorColor &&
		`
		&:after {
			border-color: ${indicatorColor} transparent transparent transparent;
			border-style: solid;
			border-width: 24px 24px 0 0;
			content: '';
			height: 0;
			left: -2px;
			position: absolute;
			top: -2px;
			width: 0;
		}
	`}
`;

interface CssPlayerImageProps {
	imageUrl: string;
}

export const CssPlayerImage = styled.div<CssPlayerImageProps>`
	width: 100px;
	display: flex;
	background: ${({ imageUrl }) => `url('${imageUrl}')`};
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 52% bottom;
	flex-shrink: 0;
`;

export const CssPlayerContent = styled.div`
	display: flex;
	border-left: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
	flex-direction: column;
	padding: 0 12px;
	width: 100%;
`;

export const CssTopLeftTriangle = styled.div<{ playerRole: Role }>(
	({ playerRole, theme }) => css`
		position: absolute;
		top: 0;
		left: 0;

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 24px 24px 0 0;
		border-color: ${theme.palette.system[PlayerRoleColor[playerRole as Role] as keyof typeof theme.palette.system]}
			transparent transparent transparent;
		z-index: 10;
	`
);

export const CssVerticalWrapper = styled.div<{ indicatorColor?: string; isActive?: boolean }>(
	({ indicatorColor, isActive, theme }) => css`
		position: relative;
		min-width: 204px;
		max-width: 360px;
		height: 288px;
		background: ${theme.palette.backgrounds.codGray};
		border: 1px solid ${isActive ? theme.palette.greyscale.white : "transparent"};
		cursor: pointer;

		transition-property: transform, background;
		transition-duration: var(--hover-transition-duration);
		transition-timing-function: var(--hover-transition-curve);

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);
				background: var(--hover-background);
			}
		}

		${indicatorColor &&
		`
			&:after {
				border-color: ${indicatorColor} transparent transparent transparent;
				border-style: solid;
				border-width: 24px 24px 0 0;
				content: '';
				height: 0;
				left: -2px;
				position: absolute;
				top: -2px;
				width: 0;
			}
		`}
	`
);

export const CssVerticalBackground = styled.div<CssPlayerImageProps>(
	({ imageUrl, theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: ${`url('${imageUrl}')`};
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 40% bottom;
	`
);

export const CssVerticalBackgroundOverlay = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 20%;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;

		background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0.8) 45%, #151515 100%);
	`
);

export const CssVerticalContent = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;

		display: flex;
		flex-direction: column;
	`
);

export const CssVerticalTop = styled.div(
	({ theme }) => css`
		flex: 1 1 0%;
		padding: 20px 16px;
		display: flex;
		flex-direction: column;
		gap: 32px;
	`
);

export const CssVerticalSkillPotentialRoleWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
	`
);

export const CssVerticalQualityPotential = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: flex-end;
		gap: 2px;
	`
);

export const CssVerticalPlayerInfo = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 12px;
	`
);

export const CssVerticalNameWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
	`
);

export const CssVerticalInfoWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: 24px;
	`
);

export const CssVerticalBottom = styled.div(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.greyscale.gondola};
		background: ${theme.palette.backgrounds.codGray};
		padding: 13.5px 16px;
	`
);
