import { playService } from "@/modules/play/dependencies";
import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId, PveDifficulty } from "@metasoccer/metasoccer-types";

export const PLAY_MUTABLE_QUERIES = {
	playPvEMatch: (teamId: MetaSoccerId<IdType.Team>) => ({
		mutationKey: [BaseServerStateKeys.PLAY_PVE],
		mutationFn: (difficulty: PveDifficulty) => playService.playPvEMatch(teamId, difficulty)
	})
};
