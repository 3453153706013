import React, { FC, useMemo } from "react";
import { Dropdown, DropdownItem } from "@/modules/shared/components";
import { Typography } from "@metasoccer/metasoccer-ds";
import { CssFiltersWrapper } from "./Filters.styled";
import { StandingsFilters } from "../../../../../../Standings.state";
import { TournamentStructureEdition } from "@metasoccer/metasoccer-types";

interface FiltersProps {
	tournamentStructureEditions: TournamentStructureEdition[];
	filters: StandingsFilters;
	onEditionChange: (editionId: number) => () => void;
	onDivisionChange: (divisionId: string) => () => void;
	onGroupChange: (groupId: number) => () => void;
}

export const Filters: FC<FiltersProps> = ({
	tournamentStructureEditions,
	filters,
	onEditionChange,
	onDivisionChange,
	onGroupChange
}) => {
	const divisions = useMemo(
		() => tournamentStructureEditions.find((edition) => edition.id === filters.editionId)?.divisions,
		[tournamentStructureEditions, filters.editionId]
	);
	const groups = useMemo(
		() => divisions?.find((division) => division.categoryId === filters.divisionId)?.groups,
		[divisions, filters.divisionId]
	);

	return (
		<CssFiltersWrapper>
			<Dropdown selectedValue={`Edition ${filters.editionName}`} placeholder="Edition">
				{tournamentStructureEditions.map((edition) => (
					<DropdownItem
						key={edition.id}
						isSelected={filters.editionId === edition.id}
						onClick={onEditionChange(edition.id)}>
						<Typography variant="buttonMd">{edition.name}</Typography>
					</DropdownItem>
				))}
			</Dropdown>
			{divisions && (
				<Dropdown selectedValue={filters.divisionName} placeholder="Division">
					{divisions.map((division) => (
						<DropdownItem
							key={division.categoryId}
							isSelected={filters.divisionId === division.categoryId}
							onClick={onDivisionChange(division.categoryId)}>
							<Typography variant="buttonMd">{division.name}</Typography>
						</DropdownItem>
					))}
				</Dropdown>
			)}
			{groups && (
				<Dropdown selectedValue={`Group ${filters.groupName}`} placeholder="Group">
					{groups.map((group) => (
						<DropdownItem
							key={group.id}
							isSelected={filters.groupId === group.id}
							onClick={onGroupChange(group.id)}>
							<Typography variant="buttonMd">{group.name.split(" ")[1]}</Typography>
						</DropdownItem>
					))}
				</Dropdown>
			)}
		</CssFiltersWrapper>
	);
};
