import { MscToken, MsuToken } from "@/modules/shared/components/tokens";
import { getNumberOrdinal } from "@/modules/shared/helpers";
import { Typography } from "@metasoccer/metasoccer-ds";
import { TournamentDivisionReward } from "@metasoccer/metasoccer-types";
import { FC, ReactNode } from "react";
import { DefaultTheme, useTheme } from "styled-components";
import { CssContentWrapper, CssPrizePoolValuesItemWrapper } from "./PrizePoolValuesItem.styled";

interface PrizePoolValuesItemProps {
	borderColor?: string;
	decorator?: ReactNode;
	reward: TournamentDivisionReward;
	index: number;
}

function getDefaultBorderColorByPosition(currentPosition: number, theme: DefaultTheme): string {
	switch (currentPosition) {
		case 0:
			return theme.palette.system.laserLemon;
		case 1:
			return theme.palette.greyscale.gray;
		case 2:
			return theme.palette.system.neonCarrot;
		case 3:
			return theme.palette.system.cornflowerBlue;
		default:
			return theme.palette.system.perfume;
	}
}

export const PrizePoolValuesItem: FC<PrizePoolValuesItemProps> = ({ borderColor, decorator, reward, index }) => {
	const theme = useTheme();
	const position = getNumberOrdinal(index + 1);
	const effectiveBorderColor = borderColor ?? getDefaultBorderColorByPosition(index, theme);
	const iconStackMinWidth = "60px";

	return (
		<CssPrizePoolValuesItemWrapper borderColor={effectiveBorderColor}>
			<CssContentWrapper>
				<Typography variant="subtitle2">{position}</Typography>
			</CssContentWrapper>
			{decorator}
			<CssContentWrapper>
				{reward.msu > 0 && <MsuToken size="xs" quantity={reward.msu} minWidth={iconStackMinWidth} />}
				{reward.msc > 0 && <MscToken size="xs" quantity={reward.msc} minWidth={iconStackMinWidth} />}
			</CssContentWrapper>
		</CssPrizePoolValuesItemWrapper>
	);
};
