import { InjuredLockedPlayer, Player } from "@metasoccer/metasoccer-types";
import { LineupTablePlayer } from "../LastLineupTable";

export const getLastBenchPlayers = (players: Player[], injuredPlayers: InjuredLockedPlayer[]): LineupTablePlayer[] => {
	function getInjuredPlayer(player: Player, injuredPlayers: InjuredLockedPlayer[]): InjuredLockedPlayer | undefined {
		return injuredPlayers.find((injuredPlayer) => injuredPlayer.playerId === player.id);
	}

	return players.map((p) => ({
		id: p.id,
		specificRole: p.specificRole,
		name: `${p.name.charAt(0)}. ${p.lastName}`,
		isInjured: getInjuredPlayer(p, injuredPlayers),
		specialAbilities: p.specialAbilities,
		overall: `${p.overall}/${p.potential}`
	}));
};
