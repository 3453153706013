import { useIsMobile } from "@/layouts/context";
import { Container, TapbarsLayout } from "@/modules/shared/components";
import { FixedAndGrowLayout } from "@/modules/shared/components/FixedAndGrowLayout";
import { Modal } from "@/modules/shared/components/Modal";
import { Paragraph } from "@/modules/shared/components/Paragraph";
import { TwoColumnLayout } from "@/modules/shared/components/TwoColumnLayout";
import { Typography } from "@metasoccer/metasoccer-ds";
import { memo, useMemo, useState } from "react";
import { Info } from "../Info";
import { InfoRewards } from "../InfoRewards";
import { SuccessModal } from "./components";
import { JoinButton } from "./components/JoinButton";

enum TabOptions {
	INFO = "info",
	REWARDS = "rewards"
}

export interface JoinModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

export const JoinModal = memo<JoinModalProps>(({ isOpen, onClose, onSuccess }) => {
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isMobile = useIsMobile();

	const closeSuccessModalAndExecuteOnClose = () => {
		setIsSuccessModalOpen(false);
		onClose();
	};

	const joinMSLButton = useMemo(
		() => (
			<JoinButton
				onSuccess={() => {
					onSuccess?.();
					setIsSuccessModalOpen(true);
				}}
			/>
		),
		[setIsSuccessModalOpen]
	);

	return (
		<>
			<Modal
				isOpen={isOpen}
				fullScreen
				mainAction={joinMSLButton}
				maxScreenWidth
				size="xxl"
				title="Join MetaSoccer League"
				onClose={onClose}>
				{isMobile ? (
					<TapbarsLayout
						tabs={[
							{
								key: TabOptions.INFO,
								title: "Info",
								render: () => (
									<Container>
										<Info
											After={[
												<Typography variant="body2">JOIN THE ACTION!</Typography>,
												<Paragraph variant="body2" light>
													<p>
														Take the next step and be the team that climbs all the way to
														the top. Join the MetaSoccer League today!
													</p>
												</Paragraph>
											]}
										/>
									</Container>
								)
							},
							{
								key: TabOptions.REWARDS,
								title: "Rewards",
								render: () => (
									<Container height="100%" paddingY={0}>
										<InfoRewards />
									</Container>
								)
							}
						]}
					/>
				) : (
					<TwoColumnLayout>
						<FixedAndGrowLayout>
							<Typography variant="h6">MetaSoccer League Info</Typography>
							<Info
								After={[
									<Typography variant="body2">JOIN THE ACTION!</Typography>,
									<Paragraph variant="body2" light>
										<p>
											Take the next step and be the team that climbs all the way to the top. Join
											the MetaSoccer League today!
										</p>
									</Paragraph>
								]}
							/>
						</FixedAndGrowLayout>
						<InfoRewards />
					</TwoColumnLayout>
				)}
			</Modal>
			{isSuccessModalOpen && (
				<SuccessModal isOpen={isSuccessModalOpen} onClose={closeSuccessModalAndExecuteOnClose} />
			)}
		</>
	);
});
