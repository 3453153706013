export const Marketplace = {
  "name": "Marketplace",
  "address": "0x8b7b78C1452B63f1C1aD95Be538C3f252Ad83689",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_acceptedToken"
        },
        {
          "type": "uint256",
          "name": "_ownerCutPerMillion"
        },
        {
          "type": "address",
          "name": "_admin"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ChangedOwnerCutPerMillion",
      "inputs": [
        {
          "type": "uint256",
          "name": "ownerCutPerMillion",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ChangedPublicationFee",
      "inputs": [
        {
          "type": "uint256",
          "name": "publicationFee",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "MetaTransactionExecuted",
      "inputs": [
        {
          "type": "address",
          "name": "userAddress",
          "indexed": false
        },
        {
          "type": "address",
          "name": "relayerAddress",
          "indexed": false
        },
        {
          "type": "bytes",
          "name": "functionSignature",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OrderCancelled",
      "inputs": [
        {
          "type": "bytes32",
          "name": "id",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "assetId",
          "indexed": true
        },
        {
          "type": "address",
          "name": "seller",
          "indexed": true
        },
        {
          "type": "address",
          "name": "nftAddress",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OrderCreated",
      "inputs": [
        {
          "type": "bytes32",
          "name": "id",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "assetId",
          "indexed": true
        },
        {
          "type": "address",
          "name": "seller",
          "indexed": true
        },
        {
          "type": "address",
          "name": "nftAddress",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "priceInWei",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "expiresAt",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OrderSuccessful",
      "inputs": [
        {
          "type": "bytes32",
          "name": "id",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "assetId",
          "indexed": true
        },
        {
          "type": "address",
          "name": "seller",
          "indexed": true
        },
        {
          "type": "address",
          "name": "nftAddress",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "totalPrice",
          "indexed": false
        },
        {
          "type": "address",
          "name": "buyer",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Paused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Unpaused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "function",
      "name": "ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "ERC721_Interface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "InterfaceId_ValidateFingerprint",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "acceptedToken",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "cancelOrder",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "nftAddress"
        },
        {
          "type": "uint256",
          "name": "assetId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "cancelOrders",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address[]",
          "name": "nftAddresses"
        },
        {
          "type": "uint256[]",
          "name": "assetIds"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "createOrder",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "nftAddress"
        },
        {
          "type": "uint256",
          "name": "assetId"
        },
        {
          "type": "uint256",
          "name": "priceInWei"
        },
        {
          "type": "uint256",
          "name": "expiresAt"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "createOrders",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "tuple[]",
          "name": "orderParams",
          "components": [
            {
              "type": "address",
              "name": "nftAddress"
            },
            {
              "type": "uint256",
              "name": "assetId"
            },
            {
              "type": "uint256",
              "name": "priceInWei"
            },
            {
              "type": "uint256",
              "name": "expiresAt"
            }
          ]
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "domainSeparator",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "executeMetaTransaction",
      "constant": false,
      "stateMutability": "payable",
      "payable": true,
      "inputs": [
        {
          "type": "address",
          "name": "userAddress"
        },
        {
          "type": "bytes",
          "name": "functionSignature"
        },
        {
          "type": "bytes32",
          "name": "sigR"
        },
        {
          "type": "bytes32",
          "name": "sigS"
        },
        {
          "type": "uint8",
          "name": "sigV"
        }
      ],
      "outputs": [
        {
          "type": "bytes"
        }
      ]
    },
    {
      "type": "function",
      "name": "executeOrder",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "nftAddress"
        },
        {
          "type": "uint256",
          "name": "assetId"
        },
        {
          "type": "uint256",
          "name": "price"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "executeOrders",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "tuple[]",
          "name": "executeOrderParams",
          "components": [
            {
              "type": "address",
              "name": "nftAddress"
            },
            {
              "type": "uint256",
              "name": "assetId"
            },
            {
              "type": "uint256",
              "name": "price"
            },
            {
              "type": "bytes",
              "name": "fingerprint"
            }
          ]
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "getChainId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getNonce",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "user"
        }
      ],
      "outputs": [
        {
          "type": "uint256",
          "name": "nonce"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "orderByAssetId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "bytes32",
          "name": "id"
        },
        {
          "type": "address",
          "name": "seller"
        },
        {
          "type": "address",
          "name": "nftAddress"
        },
        {
          "type": "uint256",
          "name": "price"
        },
        {
          "type": "uint256",
          "name": "expiresAt"
        }
      ]
    },
    {
      "type": "function",
      "name": "ownerCutPerMillion",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "paused",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "publicationFeeInWei",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeExecuteOrder",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "nftAddress"
        },
        {
          "type": "uint256",
          "name": "assetId"
        },
        {
          "type": "uint256",
          "name": "price"
        },
        {
          "type": "bytes",
          "name": "fingerprint"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeExecuteOrders",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "tuple[]",
          "name": "executeOrderParams",
          "components": [
            {
              "type": "address",
              "name": "nftAddress"
            },
            {
              "type": "uint256",
              "name": "assetId"
            },
            {
              "type": "uint256",
              "name": "price"
            },
            {
              "type": "bytes",
              "name": "fingerprint"
            }
          ]
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setOwnerCutPerMillion",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_ownerCutPerMillion"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPublicationFee",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_publicationFee"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    }
  ]
}