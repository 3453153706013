import styled, { css } from "styled-components";

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const WrapperStyled = styled.div`
	padding: 0 20px;
`;

export const CssFlex = styled("div")<{
	direction?: "column" | "row" | "column-reverse" | "row-reverse";
	gap?: number;
	justifyContent?: Required<DivProps>["style"]["justifyContent"];
	verticalAlign?: Required<DivProps>["style"]["verticalAlign"];
	width?: Required<DivProps>["style"]["width"];
}>(
	({ direction = "row", gap = 0, justifyContent = "normal", verticalAlign = "baseline", width = "auto" }) => css`
		display: flex;
		flex-direction: ${direction};
		gap: ${gap}px;
		justify-content: ${justifyContent};
		vertical-align: ${verticalAlign};
		width: ${width};
	`
);

export const CssMenuItem = styled.div`
	align-items: center;
	padding: 16px;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.palette.greyscale.gondola};
	}
`;

export const CssUserMenuDivider = styled.hr(
	({ theme }) => css`
		border: 0;
		border-top: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssContent = styled.div`
	flex: 1 1 0%;
	overflow-y: auto;
	height: 100%;
`;
