import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssStandingsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

export const CssListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${({ theme }) => theme.breakpoints.up("desktopMin")} {
		overflow-y: auto;
		padding-bottom: 24px;
	}
`;

export const CssPositionTypography = styled(Typography)`
	display: flex;
	justify-content: center;
	width: 40px;
`;

export const HeaderStyled = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex-grow: 0;
		justify-content: space-between;
		padding: 24px;
	`
);
