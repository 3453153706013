import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled, { css } from "styled-components";
import { ENTERED, ENTERING, EXITED, EXITING, UNMOUNTED } from "react-transition-group/Transition";
var inAnimationMixin = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\ttransform: translate3d(0, 0, 0);\n"])));
var outAnimationMixin = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\ttransform: translate3d(0, 100%, 0);\n"])));
export var ANIMATION_DURATION = 200;
var animationStatesStyles = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ENTERING, inAnimationMixin), ENTERED, inAnimationMixin), EXITING, outAnimationMixin), EXITED, outAnimationMixin), UNMOUNTED, css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""]))));
export var CssAnimationWrapper = styled.div(function (_ref) {
  var animationState = _ref.animationState,
    zIndex = _ref.zIndex;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\tposition: fixed;\n\t\ttop: 0;\n\t\t/* left: 0; */\n\t\tz-index: ", ";\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tmin-height: 100vh;\n\t\tmin-height: -moz-available;\n\t\tmin-height: -webkit-fill-available;\n\t\tmin-height: fill-available;\n\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\n\t\ttransform: translate3d(0, 0, 0);\n\t\ttransition: transform ", "ms ease-in-out;\n\n\t\t", "\n\t"])), zIndex, ANIMATION_DURATION, animationStatesStyles[animationState]);
});