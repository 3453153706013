import styled, { css } from "styled-components";

export const CssPrizePoolValuesItemWrapper = styled.div<{ color: string }>(
	({ theme, color }) => css`
		height: 44px;
		display: flex;
		justify-content: space-between;
		border-left: 4px solid ${color};
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		align-items: center;
		padding-left: 12px;
	`
);

export const CssRewardsWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	gap: 16px;
`;

export const CssTokensWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;
