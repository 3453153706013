export const StarterBoxRedeemer = {
  "name": "StarterBoxRedeemer",
  "address": "0x1a063CE883a7d2d643204ADdff35C5C3e022ED0A",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_starterBoxNFT"
        },
        {
          "type": "address",
          "name": "_passNFT"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Claimed",
      "inputs": [
        {
          "type": "address",
          "name": "user",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "EmergencyWithdrawn",
      "inputs": [
        {
          "type": "address",
          "name": "admin",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OwnershipTransferred",
      "inputs": [
        {
          "type": "address",
          "name": "previousOwner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "newOwner",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "PassNFTUpdated",
      "inputs": [
        {
          "type": "address",
          "name": "newAddress",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "StarterBoxNFTUpdated",
      "inputs": [
        {
          "type": "address",
          "name": "newAddress",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Withdrawn",
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": false
        },
        {
          "type": "address",
          "name": "admin",
          "indexed": true
        }
      ]
    },
    {
      "type": "function",
      "name": "availableTokenIds",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "claim",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "depositStarterBox",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "emergencyWithdrawAll",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "getAllAvailableTokenIds",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "hasAddressClaimed",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "user"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "hasClaimed",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "onERC721Received",
      "constant": true,
      "stateMutability": "pure",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "operator"
        },
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        },
        {
          "type": "bytes",
          "name": "data"
        }
      ],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "owner",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "passNFT",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceOwnership",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "starterBoxNFT",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "transferOwnership",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newOwner"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "updatePassNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "updateStarterBoxNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawUnclaimedStarterBox",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    }
  ]
}