var backgrounds = {
  cinder: "#06060A",
  codGray: "#151515",
  tundora: "#424242"
};
var greyscale = {
  white: "#FFFFFF",
  mercury: "#E5E5E5",
  wildSand: "#F6F6F6",
  gray: "#8D8D8D",
  gondola: "#1C1C1C",
  scorpion: "#5A5A5A",
  cinderGradient: "linear-gradient(180deg, rgba(6, 6, 10, 0) 0%, rgba(6, 6, 10, 0.8) 45.31%, #06060A 100%)",
  codGrayGradient: "linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0.8) 45.31%, #151515 100%)"
};
var system = {
  viking: "#4DD2CA",
  pastelGreen: "#6AD56A",
  laserLemon: "#FDF764",
  neonCarrot: "#FE9D43",
  amaranth: "#F2384E",
  hotPink: "#FF64B1",
  perfume: "#B180FC",
  cornflowerBlue: "#3D72FD"
};
var tint = {
  viking30: "#1B4344",
  viking60: "#30807D",
  pastelGreen30: "#244427",
  pastelGreen60: "#428244",
  laserLemon30: "#504E25",
  laserLemon60: "#9A9640",
  neonCarrot30: "#50331B",
  neonCarrot60: "#9A602C",
  amaranth30: "#4D151E",
  amaranth60: "#932433",
  hotPink30: "#50223C",
  hotPink60: "#9B3E6E",
  perfume30: "#3A2C53",
  perfume60: "#6C4F9B",
  cornflowerBlue30: "#162653",
  cornflowerBlue60: "#27469C",
  tundora30: "#18181B",
  tundora60: "#2A2A2C",
  tundora90: "#424242E5",
  white15: "#2B2B2F"
};
var palette = {
  mode: "dark",
  backgrounds: backgrounds,
  greyscale: greyscale,
  system: system,
  tint: tint,
  //Internal values
  background: backgrounds.cinder,
  text: greyscale.white,
  primary: greyscale.white,
  secondary: greyscale.gray,
  disabled: greyscale.scorpion,
  status: {
    success: system.pastelGreen,
    warning: system.laserLemon,
    error: system.amaranth,
    info: system.cornflowerBlue
  }
};
export default palette;