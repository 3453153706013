import { Tooltip } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssPrizePoolValuesWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		border-top: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
		overflow-y: scroll;
	`
);

export const CssOverallInfo = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

export const CssDivider = styled.div(
	({ theme }) => css`
		width: 100%;
		min-height: 1px;
		background-color: ${({ theme }) => theme.palette.greyscale.gondola};
	`
);

export const CssTooltip = styled(Tooltip)`
	margin-top: 4px;
`;
