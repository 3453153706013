import styled, { css } from "styled-components";

export const LinkStyled = styled.a(
	({ theme }) => css`
		color: ${theme.palette.text};
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	`
);
