import React, { FC } from "react";
import { Typography, Modal } from "@metasoccer/metasoccer-ds";
import { CssContent, CssContentRow } from "./EditionSheet.styled";
import { TournamentStructureEdition } from "@metasoccer/metasoccer-types";

interface EditionSheetProps {
	isOpen: boolean;
	editions: TournamentStructureEdition[];
	onEditionSelect: (edition: TournamentStructureEdition) => () => void;
	onClose: () => void;
}

export const EditionSheet: FC<EditionSheetProps> = ({ isOpen, editions, onEditionSelect, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Select edition">
			<CssContent>
				{editions.map((edition) => (
					<CssContentRow key={edition.id} onClick={onEditionSelect(edition)}>
						<Typography variant="buttonLg">{edition.name}</Typography>
					</CssContentRow>
				))}
			</CssContent>
		</Modal>
	);
};
