import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled, { css } from "styled-components";
import { Chip } from "../Chip";
export var DrillChipRoot = styled(Chip)(function (_ref) {
  var theme = _ref.theme,
    drill = _ref.drill;
  var DRILL_COLORS = {
    attack: theme.palette.system.hotPink,
    defense: theme.palette.system.cornflowerBlue,
    "mental-physical": theme.palette.system.perfume
  };
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\t&& {\n\t\t\tbackground-color: ", ";\n\n\t\t\t.ChipLabel {\n\t\t\t\tcolor: ", ";\n\t\t\t\tfont-weight: 700;\n\t\t\t}\n\t\t}\n\t"])), DRILL_COLORS[drill], theme.palette.backgrounds.cinder);
});