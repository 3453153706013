export enum FootballMatchEvent {
	NONE,
	FOUL,
	OFFSIDE,
	START,
	SECOND_HALF,
	CORNER_KICK,
	GOAL,
	GOAL_KICK,
	THROW_IN,
	PENALTY,
	PENALTY_KICK,
	SUBSTITUTION,
	SWAP,
	SLIDING,
	SHORT_PASS,
	HIGH_PASS,
	LONG_PASS,
	SHOT,
	INTERFERE,
	DEFLECT,
	BALL_CONTROL,
	TRAP,
	YELLOW_CARD,
	RED_CARD
}

export enum OtherSignEvents {
	SUBSTITUTED_PLAYER = 100,
	BENCH_PLAYER_TO_FIELD
}

export type SignEvent = FootballMatchEvent | OtherSignEvents;
