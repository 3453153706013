import { EMPTY_SHOOTER_INSTRUCTIONS, TeamInstructions } from "./TeamInstructions";

export const EMPTY_TEAM_STRATEGY: TeamStrategy = {
	instructions: {
		shooters: EMPTY_SHOOTER_INSTRUCTIONS
	}
};

export interface TeamStrategy {
	instructions: TeamInstructions;
}
