import { decimalToIntPercentage, getImgUrl } from "@/modules/shared/utils";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { AttackZones } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { AttackZoneBar } from "./AttackZoneBar";
import { BackgroundStyled, BarsWrapperStyled, ContentStyled } from "./PvpAttackZones.styles";

export const PvpAttackZones: FC<{
	homeAttackZones: AttackZones;
	awayAttackZones: AttackZones;
	homeColor: string;
	awayColor: string;
	title: string;
}> = ({ homeAttackZones, awayAttackZones, homeColor, awayColor, title }) => {
	const homeAttackZonesTotal = homeAttackZones.left + homeAttackZones.center + homeAttackZones.right;
	const awayAttackZonesTotal = awayAttackZones.left + awayAttackZones.center + awayAttackZones.right;

	const homeAttackZonesLeft =
		homeAttackZonesTotal === 0 ? 0 : decimalToIntPercentage(homeAttackZones.left / homeAttackZonesTotal);
	const homeAttackZonesCenter =
		homeAttackZonesTotal === 0 ? 0 : decimalToIntPercentage(homeAttackZones.center / homeAttackZonesTotal);
	const homeAttackZonesRight =
		homeAttackZonesTotal === 0 ? 0 : decimalToIntPercentage(homeAttackZones.right / homeAttackZonesTotal);
	const awayAttackZonesLeft =
		awayAttackZonesTotal === 0 ? 0 : decimalToIntPercentage(awayAttackZones.left / awayAttackZonesTotal);
	const awayAttackZonesCenter =
		awayAttackZonesTotal === 0 ? 0 : decimalToIntPercentage(awayAttackZones.center / awayAttackZonesTotal);
	const awayAttackZonesRight =
		awayAttackZonesTotal === 0 ? 0 : decimalToIntPercentage(awayAttackZones.right / awayAttackZonesTotal);

	return (
		<Col alignItems="center" gap={12} style={{ width: "100%" }}>
			<Typography variant="buttonSm" light>
				{title}
			</Typography>
			<BackgroundStyled
				background={getImgUrl("https://assets.metasoccer.com/ui/lineup/Field-Full.svg", { height: 384 })}>
				<ContentStyled aspectRatio={248 / 352}>
					<BarsWrapperStyled>
						<AttackZoneBar value={homeAttackZonesLeft} color={homeColor} />
						<AttackZoneBar value={homeAttackZonesCenter} color={homeColor} />
						<AttackZoneBar value={homeAttackZonesRight} color={homeColor} />
					</BarsWrapperStyled>

					<BarsWrapperStyled reverse>
						<AttackZoneBar value={awayAttackZonesRight} color={awayColor} reverse />
						<AttackZoneBar value={awayAttackZonesCenter} color={awayColor} reverse />
						<AttackZoneBar value={awayAttackZonesLeft} color={awayColor} reverse />
					</BarsWrapperStyled>
				</ContentStyled>
			</BackgroundStyled>
		</Col>
	);
};
