interface CommunityTournamentPartner {
	address: string[];
	iconUrl: string;
	name: string;
}

export const COMMUNITY_TOURNAMENT_PARTNERS: Record<string, CommunityTournamentPartner> = {
	"sura-gaming": {
		address: ["0xca2caf2db9c5dc78be401872f1918869c993f9d0", "0xe852d1fb364f7b2766109c51e21881b1723d09f9"],
		iconUrl: "https://assets.metasoccer.com/competitions/MetaSoccerStarsBySura.png",
		name: "Sura Gaming"
	}
};
