import { Container } from "@/modules/shared/components/Container";
import styled, { css } from "styled-components";

export const WrapperStyled = styled(Container)(
	({ theme }) =>
		css`
			${theme.breakpoints.up("desktopMin")} {
				padding-top: 0;
			}
		`
);

export const CssBottomRowWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 12px;
		justify-content: center;
		width: 100%;

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
			gap: 24px;
		}
	`
);

export const CssFirstColumnWrapper = styled.div(
	({ theme }) => css`
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;

		${theme.breakpoints.up("desktopMin")} {
			gap: 24px;
		}
	`
);

export const CssHeaderWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
			border-bottom: none;
		}
	`
);

export const CssKoWrapper = styled.div(
	({ theme }) => css`
		border: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssBracketsWrapper = styled.div(
	({ theme }) => css`
		max-width: 100%;
		overflow-y: auto;
	`
);
