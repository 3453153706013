import { LinedPlayer, LineupPlayerSkill, LineupWithSkill } from "@metasoccer/metasoccer-types";

export const getPlayerSkillBySlot = (lineupWithSkill: LineupWithSkill, slotIndex: number): LineupPlayerSkill | null => {
	let skill = null;
	lineupWithSkill.lineup.linedPlayers.forEach((linedPlayer: LinedPlayer, i: number) => {
		if (linedPlayer.slotId === slotIndex) {
			skill = lineupWithSkill?.skill.linedPlayersSkills[i];
		}
	});

	return skill;
};
