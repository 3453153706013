import { TournamentDivisionReward } from "@metasoccer/metasoccer-types";
import { FC, ReactNode } from "react";
import { CssPrizePoolValuesWrapper } from "./PrizePoolValues.styled";
import { PrizePoolValuesItem } from "./components";

interface PrizePoolValuesProps {
	borders?: string[];
	decorators?: ReactNode[];
	rewards: TournamentDivisionReward[];
}

export const PrizePoolValues: FC<PrizePoolValuesProps> = ({ borders = [], decorators = [], rewards }) => {
	return (
		<CssPrizePoolValuesWrapper>
			{rewards.map((reward: TournamentDivisionReward, idx: number) => {
				return (
					<PrizePoolValuesItem
						key={idx}
						borderColor={borders[idx]}
						decorator={decorators[idx]}
						reward={reward}
						index={idx}
					/>
				);
			})}
		</CssPrizePoolValuesWrapper>
	);
};
