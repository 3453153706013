import { useTheme } from "@metasoccer/metasoccer-ds";
import React, { useEffect, useState } from "react";

type HomeContextProviderProps = { children: React.ReactNode };

export type SafeAreaInsets = Array<"bottom" | "top">;

const useLayoutState = () => {
	const theme = useTheme();

	const [isRunningStandalone, setIsRunningStandalone] = useState(false);
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	useEffect(() => {
		const listener = () => setWindowWidth(window.innerWidth);

		window.addEventListener("resize", listener);

		return () => {
			window.removeEventListener("resize", listener);
		};
	}, []);

	useEffect(() => {
		const isInWebAppiOS = (window.navigator as any).standalone;
		const isInWebAppChrome = window.matchMedia("(display-mode: standalone)").matches;
		setIsRunningStandalone(isInWebAppiOS || isInWebAppChrome || true);
	}, []);

	const isMobile = windowWidth < theme.breakpoints.values.desktopMin;

	return {
		isMobile,
		isRunningStandalone,
		safeAreaInsets: (isMobile && isRunningStandalone ? ["bottom", "top"] : []) as SafeAreaInsets
	};
};

const LayoutContext = React.createContext<ReturnType<typeof useLayoutState> | undefined>(undefined);

const LayoutContextProvider = ({ children }: HomeContextProviderProps) => {
	const layoutState = useLayoutState();

	return <LayoutContext.Provider value={layoutState}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutContextProvider };
