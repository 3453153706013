import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled, { css } from "styled-components";
export var CssStepWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 12px;\n"])));
export var TextValue = styled("text")(function (_ref) {
  var _theme$fonts;
  var theme = _ref.theme,
    x = _ref.x,
    y = _ref.y;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tx: ", ";\n\t\ty: ", ";\n\t\tdominant-baseline: central;\n\t\ttext-anchor: middle;\n\t\tfont-size: 0.75rem;\n\t\tfill: ", ";\n\t\tfont-family: ", ";\n\t\tfont-weight: 500;\n\t"])), x, y, theme.palette.greyscale.white, (_theme$fonts = theme.fonts) == null ? void 0 : _theme$fonts.spaceGrotesk);
});
export var SvgBase = styled("svg")(function () {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\twidth: 40px;\n\t\theight: 40px;\n\t\toverflow: visible;\n\t"])));
});
export var CircleBase = styled("circle")(function (_ref2) {
  var theme = _ref2.theme,
    appearance = _ref2.appearance;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\twidth: 38px;\n\t\theight: 38px;\n\t\tstroke: ", ";\n\t\tstroke-width: ", ";\n\t\tfill: transparent;\n\t\tstroke-linecap: round;\n\t\ttransition: stroke-dashoffset 350ms ease-in-out;\n\t"])), appearance === "progress" ? theme.palette.greyscale.white : theme.palette.backgrounds.tundora, appearance === "progress" ? "3px" : "1px");
});