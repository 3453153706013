import { PvpTeamInfo as PvpTeamInfoType } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { CssNextMatchBodyWrapper } from "./NextMatchBody.styled";
import { PvpLiveMatch } from "./components/PvpLiveMatch";
import { PvpMatchDate } from "./components/PvpMatchDate";
import { PvpTeamInfo } from "./components/PvpTeamInfo";

interface NextMatchPreviewProps {
	className?: string;
	awayTeamInfo: PvpTeamInfoType;
	direction?: "column" | "row";
	homeTeamInfo: PvpTeamInfoType;
	liveMatch?: boolean;
	matchDate: number;
	matchScore?: [number, number];
	matchTime?: [string, string];
	notices: string[];
	showNotices?: boolean;
	size?: "md" | "2lg";
}

export const NextMatchPreview: FC<NextMatchPreviewProps> = ({
	className,
	awayTeamInfo,
	direction = "row",
	homeTeamInfo,
	liveMatch,
	matchDate,
	matchScore,
	matchTime,
	notices,
	showNotices = false,
	size = "2lg"
}) => {
	return (
		<CssNextMatchBodyWrapper className={className}>
			<PvpTeamInfo teamInfo={homeTeamInfo} direction={direction} size={size} />
			{liveMatch ? (
				<PvpLiveMatch
					notices={notices}
					showNotices={showNotices}
					teamHome={homeTeamInfo}
					teamAway={awayTeamInfo}
					matchScore={matchScore ?? [0, 0]}
					matchTime={matchTime ?? ["00", "00"]}
				/>
			) : (
				<PvpMatchDate date={matchDate} notices={notices} showNotices={showNotices} />
			)}
			<PvpTeamInfo teamInfo={awayTeamInfo} direction={direction} size={size} reverse />
		</CssNextMatchBodyWrapper>
	);
};
