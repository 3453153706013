import { AxiosHttpClient } from "@/modules/core/http";
import { HttpClient, InboxMessage } from "@metasoccer/metasoccer-types";

const DATABASE_ID = "401ae79fe0c4420c97c194d05137849f";

interface DatabasePage {
	id: string;
	properties: Record<
		string,
		{
			id: string;
			type: string;
		} & any
	>;
}

interface PageDetails {
	data: string;
	pageId: string;
	subject: string;
}

export class InboxService {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly notionToMdClient = new AxiosHttpClient({ baseUrl: "https://inbox.metasoccer.com" })
	) {}

	async getInbox(): Promise<DatabasePage[]> {
		try {
			const { data } = await this.notionToMdClient.get<DatabasePage[]>(`/database?database_id=${DATABASE_ID}`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getMessage(messageId: string): Promise<PageDetails> {
		try {
			const { data } = await this.notionToMdClient.get<PageDetails>(`/json?page_id=${messageId}&html=true`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getReadMessages(): Promise<InboxMessage[]> {
		try {
			const { data } = await this.httpClient.get(`/inbox/read`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async markMessageAsRead(messageId: string): Promise<void> {
		try {
			const { data } = await this.httpClient.post(`/inbox/markAsRead`, { messageId: messageId });
			return data;
		} catch (err) {
			throw err;
		}
	}
}
