export interface PlayerSections {
	technical: TechnicalSection;
	tactical: TacticalSection;
	condition: ConditionSection;
	goalkeeping?: GoalkeepingSection;
}

export type Abilities = TechnicalAbilities | TacticalAbilities | ConditionAbilities | GoalkeepingAbilities;

export interface PlayerSection {
	name: string;
	overall: number;
	abilities: { [key in Abilities]?: number };
}

export interface TechnicalSection extends PlayerSection {
	abilities: { [key in TechnicalAbilities]?: number };
}

export enum TechnicalAbilities {
	Passing = "Passing",
	Dribbling = "Dribbling",
	Crossing = "Crossing",
	Shooting = "Shooting",
	Finishing = "Finishing",
	Heading = "Heading"
}

export interface TacticalSection extends PlayerSection {
	abilities: { [key in TacticalAbilities]?: number };
}

export enum TacticalAbilities {
	StandingTackle = "Standing Tackle",
	SlidingTackle = "Sliding Tackle",
	Marking = "Marking",
	Positioning = "Positioning",
	Vision = "Vision",
	Bravery = "Bravery"
}

export interface ConditionSection extends PlayerSection {
	abilities: { [key in ConditionAbilities]?: number };
}

export enum ConditionAbilities {
	Fitness = "Fitness",
	Strength = "Strength",
	Speed = "Speed",
	Creativity = "Creativity",
	Concentration = "Concentration",
	Aggression = "Aggression"
}

export interface GoalkeepingSection extends PlayerSection {
	abilities: { [key in GoalkeepingAbilities]: number };
}

export enum GoalkeepingAbilities {
	Diving = "Diving",
	Handling = "Handling",
	Kicking = "Kicking",
	Punching = "Punching",
	Throwing = "Throwing",
	Reflexes = "Reflexes"
}
