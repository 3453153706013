import styled, { css } from "styled-components";

export const CssTournamentRulesWrapper = styled.div`
	margin-top: 24px;
	flex-direction: column;
	overflow-y: scroll;
`;

export const CssTournamentRulesContainer = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 16px;

		${theme.breakpoints.up("desktopMin")} {
			gap: 24px;
		}
	`
);

export const CssTournamentRule = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
