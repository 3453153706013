import styled, { css } from "styled-components";

export const CssTbodyTr = styled.tr<{
	height?: number;
	indicatorColor?: string;
	translateY?: number;
	onClick?: () => void;
}>(
	({ height, indicatorColor, translateY, onClick, theme }) => css`
		cursor: ${onClick ? "pointer" : "auto"};
		display: flex;
		height: ${height ?? 40}px;
		position: absolute;
		transform: ${translateY ? `translateY(${translateY}px)` : "none"};
		width: 100%;

		& td:first-child {
			border-left: 1px solid ${theme.palette.greyscale.gondola};
		}

		${indicatorColor &&
		`
			& td:first-child {
				position: relative;

				&::before {
					background-color: ${indicatorColor};
					content: "";
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 4px;
				}
			}
		`}
	`
);

export const CssTbodyTd = styled.td<{ width: number }>(
	({ width, theme }) => css`
		align-items: center;
		border-right: 1px solid ${theme.palette.greyscale.gondola};
		border-left: 1px solid ${theme.palette.greyscale.gondola};
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		display: flex;
		padding: 0 8px;
		width: ${width}px;
	`
);

export const CssTbodyTdContent = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: 4px;
	`
);

export const CssDrag = styled.div(
	({ theme }) => css`
		height: 24px;
		position: relative;
	`
);

export const CssDragHook = styled.div(
	({ theme }) => css`
		cursor: grab;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
	`
);
