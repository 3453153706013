import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { useToast } from "@/modules/shared/hooks";
import { Button, Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { format } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { HeaderStyled, TextFieldStyled } from "./AirdropGetStarted.styles";

export const AirdropGetStarted = memo(() => {
	const ref1 = useRef<HTMLInputElement>();
	const ref2 = useRef<HTMLInputElement>();
	const ref3 = useRef<HTMLInputElement>();
	const ref4 = useRef<HTMLInputElement>();
	const ref5 = useRef<HTMLInputElement>();
	const ref6 = useRef<HTMLInputElement>();

	const refs = [ref1, ref2, ref3, ref4, ref5, ref6];

	const { airdropStartDate, hasAirdropStarted, hasPass, isClaimingPass, claimPass } = useAirdrop();

	const [code, setCode] = useState<string[]>(new Array(6).fill(""));

	const { showErrToast } = useToast();

	const claimStarterBox = useCallback(() => {
		claimPass("MS-" + code.join("").trim()).catch((err) => showErrToast(err.message));
	}, [code]);

	const handleOnChange = useCallback(
		(value, index) => {
			const uppercaseValue = value.toUpperCase();
			if (uppercaseValue.length === 1 && /[A-Z0-9]/.test(uppercaseValue)) {
				const newCode = [...code];
				newCode[index] = uppercaseValue;
				setCode(newCode);

				if (index < refs.length - 1) {
					refs[index + 1].current!.focus();
				}
			} else if (uppercaseValue === "") {
				if (index > 0) {
					refs[index - 1].current!.focus();
				}
				const newCode = [...code];
				newCode[index] = uppercaseValue;
				setCode(newCode);
			}
		},
		[code, refs]
	);

	const handlePaste = useCallback(
		(event) => {
			const pasteData = event.clipboardData.getData("Text").toUpperCase().replace(/^MS-/, "");
			if (pasteData.length === 6) {
				const newCode = pasteData.split("");
				setCode(newCode);
				refs.forEach((ref, index) => {
					if (ref.current) {
						ref.current.value = newCode[index];
					}
				});
				event.preventDefault(); // Prevent the default paste behavior
			}
		},
		[refs]
	);

	useEffect(() => {
		const currentRef = ref1.current;
		if (currentRef) {
			currentRef.addEventListener("paste", handlePaste);
		}
		return () => {
			if (currentRef) {
				currentRef.removeEventListener("paste", handlePaste);
			}
		};
	}, [handlePaste]);

	if (hasAirdropStarted) {
		return <></>;
	}

	if (hasPass) {
		return (
			<Col style={{ paddingBottom: 24 }}>
				<HeaderStyled>
					<Typography variant="h5">Play-to-Airdrop Begins Soon</Typography>
				</HeaderStyled>
				<Col gap={24} style={{ padding: "0 24px" }}>
					<Typography variant="body2" light>
						{`Play to Airdrop begins on ${format(
							airdropStartDate,
							"PPpp"
						)} UTC. Once it starts, you'll be able to open your Starter Box and receive your perks.`}
					</Typography>
				</Col>
			</Col>
		);
	}

	return (
		<Col style={{ paddingBottom: 24 }}>
			<HeaderStyled>
				<Typography variant="h5">Claim your MetaSoccer Pass</Typography>
			</HeaderStyled>
			<Col gap={8} style={{ padding: "0 24px" }}>
				<Col gap={16}>
					<Row justifyContent="space-between">
						<TextFieldStyled disabled value="M" />
						<TextFieldStyled disabled value="S" />
						{refs.map((ref, index) => (
							<TextFieldStyled
								key={index}
								ref={ref}
								value={code[index]}
								onChange={(value: any) => handleOnChange(value, index)}
							/>
						))}
					</Row>
					<Button
						appearance="primary"
						disabled={code.join("").trim().length < 6}
						label="Claim"
						loading={isClaimingPass}
						onClick={claimStarterBox}
					/>
				</Col>
			</Col>
		</Col>
	);
});
