import styled, { css } from "styled-components";

export const CssPrepareMatchModalWrapper = styled.div(
	({ theme }) =>
		css`
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;

			${theme.breakpoints.up("desktopMin")} {
				height: 600px;
			}
		`
);

export const CssTabsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

export const CssModalContentWrapper = styled.div`
	width: 100%;
	overflow-y: scroll;
`;
