const ScoutLevelToPlayerPotentialOverallRange: Record<number, string> = {
	1: "40-49",
	2: "50-59",
	3: "60-69",
	4: "70-79",
	5: "80-89",
	6: "90-99"
};

export const getPlayerPotentialOverallRange = (scoutLevel: number): string => {
	return ScoutLevelToPlayerPotentialOverallRange[scoutLevel];
};
