export const PlayerBundleBuyer = {
  "name": "PlayerBundleBuyer",
  "address": "0xB61cd84E1264bD6Fed7981F9194A44C60441E5F6",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_erc20TokenAddress"
        },
        {
          "type": "address",
          "name": "_nftContractAddress"
        },
        {
          "type": "address",
          "name": "_marketplaceAddress"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "BundleBought",
      "inputs": [
        {
          "type": "address",
          "name": "nftAddress",
          "indexed": false
        },
        {
          "type": "address",
          "name": "buyer",
          "indexed": false
        },
        {
          "type": "uint256[]",
          "name": "assetIds",
          "indexed": false
        },
        {
          "type": "uint256[]",
          "name": "prices",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "bundleIsPurchasable",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256[]",
          "name": "_assetIds"
        },
        {
          "type": "uint256",
          "name": "_totalPrice"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "erc20TokenAddress",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getBundlePrice",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256[]",
          "name": "_assetIds"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMember",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMemberCount",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "marketplaceAddress",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "nftContractAddress",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "onERC721Received",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "operator"
        },
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        },
        {
          "type": "bytes",
          "name": "data"
        }
      ],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "purchaseBundle",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256[]",
          "name": "_assetIds"
        },
        {
          "type": "uint256",
          "name": "_totalPrice"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setErc20TokenAddress",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setMarketplaceAddress",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setnftContractAddress",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "withdrawERC20",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    }
  ]
}