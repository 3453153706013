import { ClubLogo } from "..";
import { ClubLogoName } from "../club";

export enum PveDifficulty {
	STARTER = 0,
	AMATEUR = 1,
	BEGINNER = 2,
	SEMI_PRO = 3,
	PRO = 4,
	LEGEND = 5
}

export function getByPveDifficultyLogo(
	difficulty: PveDifficulty,
	possibleClubLogoColors: [string, string, string, string, string, string, string, string, string]
): ClubLogo {
	switch (difficulty) {
		case PveDifficulty.STARTER:
			return { name: ClubLogoName.CLUB8, color: possibleClubLogoColors[1] }; //<ClubLogo name="Club3" color={palette.system.laserLemon} size={size} isMatch />;
		case PveDifficulty.AMATEUR:
			return { name: ClubLogoName.CLUB3, color: possibleClubLogoColors[3] }; //<ClubLogo name="Club3" color={palette.system.laserLemon} size={size} isMatch />;
		case PveDifficulty.BEGINNER:
			return { name: ClubLogoName.CLUB4, color: possibleClubLogoColors[5] }; //<ClubLogo name="Club4" color={palette.system.hotPink} size={size} isMatch />;
		case PveDifficulty.PRO:
			return { name: ClubLogoName.CLUB7, color: possibleClubLogoColors[6] }; //<ClubLogo name="Club7" color={palette.system.amaranth} size={size} isMatch />;
		case PveDifficulty.SEMI_PRO:
			return { name: ClubLogoName.CLUB5, color: possibleClubLogoColors[2] }; //<ClubLogo name="Club5" color={palette.system.pastelGreen} size={size} isMatch />;
		case PveDifficulty.LEGEND:
			return { name: ClubLogoName.CLUB20, color: possibleClubLogoColors[1] }; //<ClubLogo name="Club20" color={palette.system.cornflowerBlue} size={size} isMatch />;
		default:
			return { name: ClubLogoName.CLUB3, color: possibleClubLogoColors[3] }; //<ClubLogo name="Club3" color={palette.system.laserLemon} size={size} isMatch />;
	}
}
