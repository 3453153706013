import styled from "styled-components";

export const CssScoutContentUpperPart = styled.div`
	width: 100%;
	display: flex;
	padding: 16px 4px;
	gap: 24px;
`;

export const CssScoutContentUpperPartWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 12px;
`;

export const CssScoutContentUpperPartOverall = styled.div`
	display: flex;
	align-items: baseline;
`;

export const CssScoutContentUpperPartInfoWrapper = styled.div`
	display: flex;
	gap: 24px;
`;

export const CssScoutContentUpperPartInfo = styled.div`
	display: flex;
	gap: 4px;
`;
