import { useIsMobile } from "@/layouts/context";
import { useMSLState } from "@/modules/msl/context";
import { useGlobalState } from "@/modules/shared/context";
import { defineIf } from "@/modules/shared/utils";
import { Col } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { JoinMSLAlert } from "..";
import { CssBottomRowWrapper, CssFirstColumnWrapper, WrapperStyled } from "./Overview.styles";
import { MainBanner, MatchesCard, StandingsCard, StatsCard, TournamentCard } from "./components";

export const Overview = memo(() => {
	const { selectedTeam } = useGlobalState();
	const { groupOfTeam, refetchGroupOfTeam, refetchJoined } = useMSLState();

	const isMobile = useIsMobile();

	return (
		<WrapperStyled>
			<Col gap={isMobile ? 12 : 24}>
				{defineIf(selectedTeam && !groupOfTeam, () => (
					<JoinMSLAlert
						onSuccess={() => {
							refetchGroupOfTeam();
							refetchJoined();
						}}
					/>
				))}
				<MainBanner />
				<CssBottomRowWrapper>
					<CssFirstColumnWrapper>
						<TournamentCard />
						<StatsCard />
					</CssFirstColumnWrapper>
					<StandingsCard />
					<MatchesCard />
				</CssBottomRowWrapper>
			</Col>
		</WrapperStyled>
	);
});
