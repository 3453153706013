import styled, { css } from "styled-components";

export const CssAttackZoneBarWrapper = styled.div<{ reverse?: boolean }>(
	({ theme, reverse }) => css`
		height: 33%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: ${reverse ? "end" : "start"};
		flex-direction: ${reverse ? "row-reverse" : "row"};
	`
);

export const CssBarArrowHead = styled("div")<{ color: string; reverse?: boolean }>(
	({ color, reverse }) => css`
		color: ${color};
		height: 100%;
		clip-path: ${reverse ? "polygon(0 50%, 100% 100%, 100% 0)" : "polygon(100% 50%, 0 0, 0 100%)"};
		background: ${color};
		width: 24px;
	`
);

export const CssBar = styled.div<{ value: number; color: string }>(
	({ theme, value, color }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: ${value > 15 ? value : 15}%;
		background: ${color};
		color: ${theme.palette.backgrounds.cinder};
		padding: 4px;
	`
);
