import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { clubService } from "../dependencies";

export const CLUB_MUTABLE_QUERIES = {
	updateLogoAndColor: () => ({
		mutationKey: [BaseServerStateKeys.CLUB, "logo"],
		mutationFn: ({ clubId, name, color }: { clubId: MetaSoccerId<IdType.Club>; name: string; color: string }) =>
			clubService.updateTeamLogoAndColor(clubId, name, color)
	}),
	updateNameAndAcronym: () => ({
		mutationKey: [BaseServerStateKeys.CLUB, "team"],
		mutationFn: ({ clubId, name, acronym }: { clubId: MetaSoccerId<IdType.Club>; name: string; acronym: string }) =>
			clubService.updateTeamNameAndAcronym(clubId, name, acronym)
	})
};
