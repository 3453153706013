import styled, { css } from "styled-components";

export const CssListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	overflow-y: auto;
	height: 100%;
`;

export const CssResultsListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 12px 0px;

	${({ theme }) => theme.breakpoints.up("desktopMin")} {
		gap: 12px;
	}
`;

export const CssDate = styled.div(
	({ theme }) => css`
		text-align: center;
		padding: 12px 0px;

		${theme.breakpoints.down("mobileMax")} {
			background-color: ${theme.palette.backgrounds.codGray};
		}
	`
);

export const CssContent = styled.div`
	padding: 0px 24px;
`;

export const CssMatchesWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		border-bottom: 0.5px solid ${theme.palette.greyscale.gondola};

		${({ theme }) => theme.breakpoints.up("desktopMin")} {
			display: grid;
			grid-template-columns: auto auto 1fr 108px;
			gap: 12px;
		}
	`
);
