export function getColorByEnergy(energy: number): string {
	if (energy === 100) {
		return "#4DD2CA";
	} else if (energy < 100 && energy >= 75) {
		return "#6AD56A";
	} else if (energy < 75 && energy >= 50) {
		return "#FDF764";
	} else if (energy < 50 && energy >= 25) {
		return "#FE9D43";
	} else {
		return "#F2384E";
	}
}
