import { Checkbox, Row } from "@metasoccer/metasoccer-ds";
import {
	ColumnDef,
	ColumnFiltersState,
	RowSelectionState,
	SortingState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { CssTBody, CssTHead, CssTheadTh, CssTheadTr } from "./Table.styles";
import { TableColumnMenu, TableRow } from "./components";
import { TableOptions } from "./useTable";

interface TableProps<T> {
	children: ColumnDef<T>[];
	rowHeight?: number;
	items: T[];
	options?: TableOptions;
	getRowIndicatorColor?: (item: T) => string;
	getRowId?: (item: T) => string;
	onRowClick?: (item: T) => void;
	onSelectionChange?: (items: T[]) => void;
}

function buildSelectionColumn<T>(setRowSelection: Dispatch<SetStateAction<RowSelectionState>>): ColumnDef<T> {
	return {
		id: "select",
		maxSize: 32,
		header: ({ table }) => (
			<Checkbox
				size="md"
				style={{ alignSelf: "auto" }}
				value={table.getIsAllRowsSelected()}
				onChange={(value) =>
					setRowSelection(
						table.getRowModel().rows.reduce((prev, curr) => ({ ...prev, [curr.id]: value }), {})
					)
				}
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				disabled={!row.getCanSelect()}
				size="md"
				style={{ alignSelf: "auto" }}
				value={row.getIsSelected()}
				onChange={(value) => setRowSelection((prev) => ({ ...prev, [row.id]: value }))}
			/>
		)
	};
}

export function Table<T>({
	children,
	rowHeight = 40,
	items,
	options,
	getRowId,
	getRowIndicatorColor,
	onRowClick,
	onSelectionChange
}: TableProps<T>) {
	const tableContainerRef = React.useRef<HTMLDivElement>(null);

	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
	const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
	const [sorting, setSorting] = React.useState<SortingState>([]);

	const columns = options?.enableRowSelection ? [buildSelectionColumn<T>(setRowSelection), ...children] : children;

	const table = useReactTable({
		columns,
		data: items,
		debugTable: true,
		enableFilters: true,
		enableSorting: true,
		state: { columnFilters, rowSelection, sorting },
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		onSortingChange: setSorting,
		...options
	});

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		measureElement:
			typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
		overscan: 20,
		estimateSize: () => rowHeight,
		getScrollElement: () => tableContainerRef.current
	});

	useEffect(() => {
		const selectedItems = Object.entries(rowSelection)
			.filter(([_, selected]) => selected)
			.map(([rowId]) => table.getRowModel().rowsById[rowId].original);
		onSelectionChange?.(selectedItems);
	}, [rowSelection]);

	return (
		<div ref={tableContainerRef} style={{ height: "100%", width: "100%", overflowY: "auto" }}>
			<table cellSpacing={0} cellPadding={0} style={{ display: "grid" }}>
				<CssTHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<CssTheadTr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<CssTheadTh
									key={header.id}
									// @ts-ignore
									style={header.column.columnDef.meta?.thStyle}
									width={header.column.getSize()}>
									<Row
										alignItems="center"
										gap={4}
										justifyContent="space-between"
										style={{
											width: "100%",
											// @ts-ignore
											...(header.column.columnDef.meta?.thContentStyle ?? {})
										}}>
										{flexRender(header.column.columnDef.header, header.getContext())}
										{(header.column.getCanFilter() || header.column.getCanSort()) && (
											<TableColumnMenu column={header.column} />
										)}
									</Row>
								</CssTheadTh>
							))}
						</CssTheadTr>
					))}
				</CssTHead>
				<CssTBody height={rowVirtualizer.getTotalSize()}>
					{rowVirtualizer.getVirtualItems().map((virtualRow) => {
						const row = table.getRowModel().rows[virtualRow.index];
						if (!row) return;
						return (
							<TableRow
								ref={(node: Element | null) => rowVirtualizer.measureElement(node)}
								key={row.id}
								data-index={virtualRow.index}
								draggable={options?.isDraggable}
								height={rowHeight}
								indicatorColor={getRowIndicatorColor?.(row.original)}
								row={row}
								translateY={virtualRow.start}
								getId={getRowId}
								onClick={onRowClick ? () => onRowClick(row.original) : undefined}
							/>
						);
					})}
				</CssTBody>
			</table>
		</div>
	);
}
