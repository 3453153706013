import { useSelectedTeamState } from "@/modules/shared/context";
import { MissedIcon, Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

export const NotRegisteredForNextMatch = memo(() => {
	const { teamInfo } = useSelectedTeamState();

	const isMSL = teamInfo?.nextMatch?.editionName?.includes("MetaSoccer League");
	const tournamentName = teamInfo?.nextMatch ? teamInfo.nextMatch.tournamentName : "Custom tournament";

	return (
		<Tooltip position="center">
			{{
				popper: isMSL ? (
					<Typography variant={"body2"}>Not registered in MSL</Typography>
				) : (
					<Typography variant={"body2"}>Not registered for {tournamentName}</Typography>
				),
				content: <MissedIcon size="sm" />
			}}
		</Tooltip>
	);
});
