import { Container } from "@/modules/shared/components";
import styled, { css } from "styled-components";

export const HeaderStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		padding: 24px;
	`
);

export const DotStyled = styled.div(
	({ theme }) => css`
		background: ${theme.palette.primary};
		border-radius: 50%;
		height: 6px;
		width: 6px;
	`
);

export const ContainerStyled = styled(Container)(
	({ theme }) => css`
		color: ${theme.palette.primary};
		font-family: ${theme.typography.body2.style.fontFamily};
		font-size: ${theme.typography.body2.style.fontSize};
		font-weight: ${theme.typography.body2.style.fontWeight};
		line-height: ${theme.typography.body2.style.lineHeight};
		padding: 16px;

		& img {
			max-width: 100%;
			height: auto;
		}

		& p {
			margin-bottom: 16px;
			color: ${theme.palette.primary};
		}

		& ul {
			padding-left: 18px;
			margin-bottom: 16px;

			& li {
				margin-bottom: 8px;
			}
		}

		& ol {
			padding-left: 18px;
			margin-bottom: 16px;

			& li {
				margin-bottom: 8px;
			}
		}

		& a {
			color: ${theme.palette.primary};
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		& table {
			width: 100%;
			border-collapse: collapse;
			margin-bottom: 16px;

			& th,
			& td {
				padding: 8px;
				text-align: left;
				border-bottom: 1px solid ${theme.palette.greyscale.gondola};
			}

			& th {
				background-color: ${theme.palette.greyscale.gondola};
				color: ${theme.palette.primary};
			}

			& tbody tr:hover {
				background-color: ${theme.palette.greyscale.gondola};
			}
		}

		& form {
			& input,
			& select,
			& textarea {
				width: 100%;
				padding: 8px;
				margin-bottom: 16px;
				border: 1px solid ${theme.palette.greyscale.gondola};
				border-radius: 4px;
				font-family: ${theme.typography.body2.style.fontFamily};
				font-size: ${theme.typography.body2.style.fontSize};
				font-weight: ${theme.typography.body2.style.fontWeight};
			}

			& label {
				display: block;
				margin-bottom: 8px;
				font-weight: ${theme.typography.body1.style.fontWeight};
				color: ${theme.palette.primary};
			}

			& button {
				margin-top: 8px;
			}
		}
	`
);
