import { SplitPayloadInChunks } from "@/modules/shared/helpers";
import { Analytics, logEvent, setUserId } from "firebase/analytics";
import { AnalyticsService } from "./AnalyticsService";

export class FirebaseAnalyticsService implements AnalyticsService {
	constructor(private analytics: Analytics | null, private splitPayloadInChunks: SplitPayloadInChunks) {}

	identifyUser(userId: string) {
		if (this.analytics) {
			setUserId(this.analytics, userId);
		}
	}

	sendEvent(eventName: string, payload?: Record<string, unknown> | undefined): void {
		if (this.analytics) {
			logEvent(this.analytics, eventName, payload);
		}
	}

	sendBigEvent(eventName: string, payload?: Record<string, unknown> | undefined): void {
		const chunks = this.splitPayloadInChunks({ payload, chunkSize: 80 }) || [];

		chunks.forEach((chunk, i) => {
			this.sendEvent(eventName, {
				chunkNumber: i,
				payload: chunk
			});
		});
	}
}
