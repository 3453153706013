import { HttpClient, IdType, MatchedTeam, Matchmake, MetaSoccerId, PveDifficulty } from "@metasoccer/metasoccer-types";

export class PlayService {
	constructor(private readonly httpService: HttpClient) {}

	async findTeamsByClubName(nameLike: string): Promise<MatchedTeam[]> {
		try {
			const { data } = await this.httpService.get<MatchedTeam[]>(
				`/play/findOpponentByClubName?nameLike=${nameLike}`
			);
			return data;
		} catch (e: any) {
			throw e.message;
		}
	}

	async playMatch(teamId: MetaSoccerId<IdType.Team>, opponentTeamId: MetaSoccerId<IdType.Team>): Promise<Matchmake> {
		try {
			const { data } = await this.httpService.post(`/play`, { teamId, opponentTeamId });
			return data;
		} catch (e: any) {
			throw e.message;
		}
	}

	async playPvEMatch(teamId: MetaSoccerId<IdType.Team>, difficulty: PveDifficulty): Promise<Matchmake> {
		try {
			const { data } = await this.httpService.post(`/play/pve`, { teamId, difficulty });
			return data;
		} catch (e: any) {
			throw e.message;
		}
	}
}
