import { Button } from "@metasoccer/metasoccer-ds";
import { useWallet } from "@thirdweb-dev/react";

export const ExportPrivateKeyItem = () => {
	const wallet = useWallet();

	if (wallet?.walletId === "embeddedWallet") {
		return (
			<Button
				appearance="secondary"
				label="Export Private Key"
				size="sm"
				onClick={() =>
					window.open(
						"https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=a8fd5d4d91d0da0f0485ac27da84b578",
						"_blank"
					)
				}
			/>
		);
	} else {
		return <></>;
	}
};
