import styled, { css } from "styled-components";

const WIDTH = 90;
const HEIGHT = 108;

export const CssWrapper = styled.div<{
	isDisabled?: boolean;
	isOver: boolean;
	parentWidth: number;
	parentHeight: number;
	x: number;
	y: number;
}>(
	({ isDisabled, isOver, parentHeight, parentWidth, x, y }) => css`
		cursor: ${isDisabled ? "not-allowed" : "pointer"};
		position: absolute;
		top: ${(1 - x) * parentHeight - HEIGHT / 2}px;
		left: ${y * parentWidth - WIDTH / 2}px;

		align-items: center;
		display: flex;
		flex-direction: column;
		height: ${HEIGHT}px;
		justify-content: center;
		opacity: ${isDisabled ? 0.2 : isOver ? 0.5 : 1};
		padding: 10px;
		width: ${WIDTH}px;

		${!isDisabled &&
		`
			@media (hover: hover) {
				&:hover {
					animation-name: scale;
					animation-duration: 0.2s;
					animation-fill-mode: forwards;
				}
			}

			@keyframes scale {
				to {
					transform: scale(1.2);
				}
			}
		`}
	`
);

export const CssContent = styled.div`
	flex: 1 1 0%;
	overflow-y: auto;
	height: 100%;
`;
