import { useServerState } from "@/modules/core/react-query/hooks";
import { LINEUP_QUERIES } from "@/modules/lineup/queries";
import { useGlobalState } from "@/modules/shared/context";
import { PLAYERS_QUERIES } from "@/modules/shared/queries";

export const useSquadState = () => {
	const { address, selectedTeam } = useGlobalState();

	const { data: lineupWithSkill, isLoading: isLoadingLineupWithSkill } = useServerState(
		LINEUP_QUERIES.lineupsWithSkill.byOwnerAndTeamId(address, selectedTeam?.id),
		{ enabled: !!selectedTeam?.id }
	);

	const { data: lockedPlayers } = useServerState(PLAYERS_QUERIES.getLockedPlayersByTeamId(selectedTeam?.id), {
		enabled: !!selectedTeam?.id
	});

	const { data: shirtNumbersResponse, isLoading: isLoadingShirtNumbers } = useServerState(
		PLAYERS_QUERIES.getPlayersWithShirtNumbersByTeamId(selectedTeam?.id),
		{ enabled: !!selectedTeam?.id }
	);

	const isLoading = isLoadingLineupWithSkill || isLoadingShirtNumbers;

	const shirtNumbers = shirtNumbersResponse?.shirtNumbers ?? {};

	return {
		isLoading,
		lineupWithSkill: lineupWithSkill,
		lockedPlayers: lockedPlayers ?? [],
		shirtNumbers: shirtNumbers
	};
};
