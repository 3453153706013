import { ClubLogo, Col } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.backgrounds.codGray};
		background-size: cover;
		cursor: pointer;
		height: 330px;
		overflow: hidden;
		padding: 24px;
		position: relative;
		width: 100%;

		transition-duration: var(--hover-transition-duration);
		transition-property: transform;
		transition-timing-function: var(--hover-transition-curve);

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);
				background-color: ${theme.palette.greyscale.gondola};
			}
		}
	`
);

export const BackgroundStyled = styled.div(
	({ theme }) => css`
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
	`
);

export const ClubLogoStyled = styled(ClubLogo)<{ reverse?: boolean }>(
	({ reverse, theme }) => css`
		position: absolute;
		bottom: 0;
		left: ${reverse ? "unset" : "-128px"};
		right: ${reverse ? "-128px" : "unset"};
		top: -35px;
		filter: grayscale(100%);
		height: 400px;
		opacity: 0.02;
		width: 400px;
	`
);

export const ColStyled = styled(Col)(
	({ theme }) => css`
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: space-between;
	`
);
