import { MATCH_ROUTES } from "@/modules/match/match.routes";
import { PVP_ROUTES } from "@/modules/pvp/pvp.routes";
import { Typography } from "@metasoccer/metasoccer-ds";
import { GameMode, IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Round, formatDate } from "../../helpers";
import { ScheduledMatchPreview } from "../ScheduledMatchPreview";
import { CssMatchesWrapper, CssRoundDateWrapper, CssRoundPreviewWrapper } from "./RoundPreview.styled";

interface RoundPreviewProps {
	round: Round;
	roundNumber: number;
	selectedTeamId: MetaSoccerId<IdType.Team>;
	tournamentType?: GameMode;
	buildItemId?: (date: number) => string;
}
export const RoundPreview: FC<RoundPreviewProps> = ({
	round,
	roundNumber,
	selectedTeamId,
	tournamentType,
	buildItemId
}) => {
	const navigate = useNavigate();
	const navigateToMatchReport = (matchId: MetaSoccerId<IdType.MatchScheduled>) => () =>
		tournamentType === "League 2D"
			? navigate(MATCH_ROUTES.ROOT.buildPath(matchId))
			: navigate(PVP_ROUTES.VIEW_PLAYED_MATCH.buildPath(matchId));

	return (
		<CssRoundPreviewWrapper id={buildItemId?.(round.date)}>
			<CssRoundDateWrapper>
				<Typography variant="body2">
					Round {roundNumber} · {formatDate(round.date)}
				</Typography>
			</CssRoundDateWrapper>
			<CssMatchesWrapper>
				{round.matches.map((m, i) => (
					<ScheduledMatchPreview
						key={m.matchId}
						scheduledMatchPreviewInfo={m}
						selectedTeamId={selectedTeamId}
						onClick={navigateToMatchReport(m.matchId)}
					/>
				))}
			</CssMatchesWrapper>
		</CssRoundPreviewWrapper>
	);
};
