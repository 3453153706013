import createHmac from "create-hmac";
var CONFIG = JSON.parse(Buffer.from("eyJpbWdQcm94eUtleSI6IjFiMjQyYWIxOTAzODExZTUzNDk4NGFkOTEyYTNlNDY5ZjNmMWQyY2ZmNTNkZGE5MTNhZTdjMWRhMGU5MGQ2NmY3NTNhZjdlMzAzMWVjYTk3MTIzM2EyNDg1OTFiZDBlZTk5YTdjYzRjODVhZTRkMDE2NzY1NmQwZjY1NzI0NThmIiwiaW1nUHJveHlTYWx0IjoiYjUzYmQ4YjEwNTk0ZmVkZjI5ZTk0ODI2YTUxZmY5OTBhYmQxZGM4Y2VmNTE4YmQ1Y2VlNDY1ZDZkZGQzNzRkMThlN2U3ZjViZDIwMmE3ZTI2MDRkMDI0NzlmYTBkZTlmMmRmZDEyYzMyM2UyMWE2ZDNhNjRlNjM0MjAyNjA0NDUifQ==", "base64").toString("ascii"));
var urlSafeBase64 = function urlSafeBase64(buffer) {
  return buffer.toString("base64").replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
};
var sign = function sign(target) {
  var imgProxyKey = CONFIG.imgProxyKey,
    imgProxySalt = CONFIG.imgProxySalt;
  var hexDecode = function hexDecode(hex) {
    return Buffer.from(hex, "hex");
  };
  var hmac = createHmac("sha256", hexDecode(imgProxyKey));
  hmac.update(hexDecode(imgProxySalt));
  hmac.update(target);
  return urlSafeBase64(hmac.digest());
};
export var getImgUrl = function getImgUrl(url, config) {
  try {
    var encodedUrl = urlSafeBase64(Buffer.from(url));
    var options = [];
    if (config != null && config.format) {
      options.push("f:".concat(config.format));
    }
    if (config != null && config.crop) {
      options.push("crop:".concat(config.crop));
    }
    if (config != null && config.gravity) {
      options.push("gravity:".concat(config.gravity));
    }
    if (config != null && config.size) {
      options.push("s:".concat(config.size, ":").concat(config.size));
    } else {
      if (config != null && config.height) {
        options.push("h:".concat(config.height));
      }
      if (config != null && config.width) {
        options.push("w:".concat(config.width));
      }
    }
    if (config != null && config.resize) {
      options.push("resize:".concat(config.resize));
    }
    if (config != null && config.trim) {
      options.push("t:0.3:".concat(typeof config.trim === "boolean" ? "FF00FF" : config.trim));
    }
    var path = "/".concat(options.join("/"), "/").concat(encodedUrl).replaceAll("//", "/");
    var signature = sign(path);
    return "https://img.metasoccer.com/".concat(signature).concat(path);
  } catch (err) {
    console.error(err);
    return url;
  }
};