import { useServerState } from "@/modules/core/react-query/hooks";
import { MSL_QUERIES } from "@/modules/msl";
import { useGlobalState } from "@/modules/shared/context";
import React from "react";
import { HOME_QUERIES } from "../queries";

type HomeContextProviderProps = { children: React.ReactNode };

const useHomeState = () => {
	const { selectedTeam } = useGlobalState();

	const hasTeam = Boolean(!!selectedTeam?.id);

	const { data: groupOfTopMSL, isLoading: isLoadingGroupOfTopMSL } = useServerState(HOME_QUERIES.groupInfoOfTopMSL());

	const { data: groupOfTeam, isLoading: isLoadingGroupOfTeam } = useServerState(
		HOME_QUERIES.groupInfoByTeam(selectedTeam?.id),
		{ enabled: hasTeam }
	);

	const { data: joined, isLoading: isLoadingJoined } = useServerState(MSL_QUERIES.getJoined(selectedTeam?.id), {
		enabled: hasTeam
	});

	return {
		isLoading: isLoadingGroupOfTopMSL || isLoadingGroupOfTeam || isLoadingJoined,
		groupOfTeam,
		groupOfTopMSL,
		joined: joined?.joined
	};
};

const HomeContext = React.createContext<ReturnType<typeof useHomeState> | undefined>(undefined);

const HomeContextProvider = ({ children }: HomeContextProviderProps) => {
	const homeState = useHomeState();

	return <HomeContext.Provider value={homeState}>{children}</HomeContext.Provider>;
};

export { HomeContext, HomeContextProvider };
