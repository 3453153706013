import getMSLRankBorderColors from "@/modules/msl/utils/getMSLRankBorderColors";
import { ColumnHeader, InfoMessage, LastMatches, Table } from "@/modules/shared/components";
import { ClubLogo, Typography } from "@metasoccer/metasoccer-ds";
import { TournamentDetailedStandingRow } from "@metasoccer/metasoccer-types";
import { FC, useCallback } from "react";
import { useTheme } from "styled-components";
import { CssClubNameWrapper, CssStandingsWrapper } from "./StandingDesktop.styled";

interface FilteredStandingDesktopProps {
	rankedTeams?: TournamentDetailedStandingRow[];
}

export const StandingDesktop: FC<FilteredStandingDesktopProps> = ({ rankedTeams }) => {
	const theme = useTheme();
	const borderColors = getMSLRankBorderColors(theme.palette);

	const getRowIndicatorColor = useCallback(
		(row: TournamentDetailedStandingRow) => {
			const position = row.position;
			return borderColors[position - 1];
		},
		[borderColors]
	);

	return (
		<CssStandingsWrapper>
			{!rankedTeams ? (
				<InfoMessage message="There are no standings to show" />
			) : (
				<Table items={rankedTeams ?? []} getRowIndicatorColor={getRowIndicatorColor}>
					{[
						{
							id: "position",
							maxSize: 48,
							header: () => (
								<Typography variant={"body2"} style={{ paddingLeft: 12 }}>
									#
								</Typography>
							),
							cell: ({ row }) => (
								<Typography variant={"body2"} light style={{ paddingLeft: 12 }}>
									{row.original.position}
								</Typography>
							)
						},
						{
							id: "team",
							meta: { tdStyle: { flexGrow: 1 }, thStyle: { flexGrow: 1 } },
							header: () => <ColumnHeader title="Team" />,
							cell: ({ row }) => {
								return (
									<CssClubNameWrapper>
										<ClubLogo
											color={row.original.clubLogocolor}
											name={row.original.clubLogoName}
											url={row.original.clubLogoUrl}
											size="xs"
										/>
										<Typography variant={"body2"}>{row.original.clubName}</Typography>
									</CssClubNameWrapper>
								);
							}
						},
						{
							id: "played",
							maxSize: 56,
							header: () => <ColumnHeader title="Played" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.played}</Typography>
						},
						{
							id: "won",
							maxSize: 56,
							header: () => <ColumnHeader title="Won" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.won}</Typography>
						},
						{
							id: "tied",
							maxSize: 56,
							header: () => <ColumnHeader title="Tied" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.tied}</Typography>
						},
						{
							id: "lost",
							maxSize: 56,
							header: () => <ColumnHeader title="Lost" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.lost}</Typography>
						},
						{
							id: "goalsForward",
							maxSize: 56,
							header: () => <ColumnHeader title="GF" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.goalsForward}</Typography>
						},
						{
							id: "goalsAgainst",
							maxSize: 56,
							header: () => <ColumnHeader title="GA" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.goalsAgainst}</Typography>
						},
						{
							id: "goalDifference",
							maxSize: 56,
							header: () => <ColumnHeader title="GD" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.goalsDifference}</Typography>
						},
						{
							id: "points",
							maxSize: 56,
							header: () => <ColumnHeader title="Points" />,
							cell: ({ row }) => <Typography variant={"body2"}>{row.original.points}</Typography>
						},
						{
							id: "lastMatches",
							maxSize: 152,
							header: () => <ColumnHeader title="Last Matches" />,
							cell: ({ row }) => <LastMatches results={row.original.lastMatches} />
						}
					]}
				</Table>
			)}
		</CssStandingsWrapper>
	);
};
