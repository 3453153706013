import { RequestConfig } from "@/http/RequestConfig";
import { RequestInterceptor } from "@/http/RequestInterceptor";
import { ResponseInterceptor } from "@/http/ResponseInterceptor";
import { Response } from "@/http/Response";

export abstract class HttpClient {
	protected constructor(private readonly config: RequestConfig) {}

	abstract appendRequestInterceptor(interceptor: RequestInterceptor): void;
	abstract appendResponseInterceptor(interceptor: ResponseInterceptor): void;

	abstract get<R = any>(url: string, config?: RequestConfig): Promise<Response<R>>;
	abstract post<P, R = any>(url: string, data?: P, config?: RequestConfig): Promise<Response<R>>;
}
