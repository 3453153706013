import { Navbar, Topbar } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";
import { SafeAreaInsets } from "../context";

export const CssWrapper = styled.div`
	display: flex;
	justify-content: center;
	min-height: 100%;
	min-width: 320px;
	width: 100%;
`;

export const CssContainer = styled.div<{ safeAreaInsets: SafeAreaInsets }>(
	({ safeAreaInsets, theme }) => css`
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		/* Prevent content growing affect top and side bars behavior. Each page should handle their own scroll */
		overflow: hidden;
		padding-top: ${safeAreaInsets.includes("top") ? "calc(64px + env(safe-area-inset-top))" : "64px"};
		padding-bottom: ${safeAreaInsets.includes("bottom") ? "calc(64px + env(safe-area-inset-bottom))" : "64px"};

		${safeAreaInsets.includes("bottom") &&
		`
			& .NavBar {
				box-sizing: content-box;
				padding-bottom: env(safe-area-inset-bottom);
			}
		`}

		${safeAreaInsets.includes("top") &&
		`
			& .Topbar {
				box-sizing: content-box;
				padding-top: env(safe-area-inset-top);
			}
		`}

		@media (min-width: ${theme.breakpoints.values.mobileMax}px) {
			max-width: ${theme.breakpoints.values.mobileMax}px;
			border-left: 0.063rem solid ${theme.palette.greyscale.gondola};
			border-right: 0.063rem solid ${theme.palette.greyscale.gondola};
		}

		@media (min-width: ${theme.breakpoints.values.desktopMin}px) {
			height: 100vh;
			max-width: ${theme.breakpoints.values.desktopMax}px;
			border-left: none;
			border-right: none;
			padding-top: 64px;
			padding-left: 64px;
			padding-bottom: 0;
		}

		@media (min-width: ${theme.breakpoints.values.desktopMax}px) {
			border-left: 0.063rem solid ${theme.palette.greyscale.gondola};
			border-right: 0.063rem solid ${theme.palette.greyscale.gondola};
		}
	`
);

export const CssTopbar = styled(Topbar)(
	({ theme }) => css`
		position: fixed;

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			max-width: ${theme.breakpoints.values.mobileMax}px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		@media (min-width: ${theme.breakpoints.values.desktopMin}px) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	`
);

export const CssSidebar = styled(Navbar)(
	({ theme }) => css`
		position: fixed;

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			max-width: ${theme.breakpoints.values.mobileMax}px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		@media (min-width: ${theme.breakpoints.values.desktopMin}px) {
			top: 64px;
			bottom: 0;
			left: 0;
			position: absolute;
		}
	`
);
