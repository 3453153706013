import { useIsMobile } from "@/layouts/context";
import { TrainingToken } from "@/modules/shared/components/tokens";
import { useBalanceContext } from "@/modules/shared/context";
import { AvailableSort } from "@/modules/training/constants";
import { SelectPlayersStep } from "@/modules/training/views/Root/components/TrainingStepperModal/components/SelectPlayersStep";
import { StepContent, StepperModal } from "@metasoccer/metasoccer-ds";
import { Drill, LockedPlayer, Player, Role } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { SelectDrillStep } from "./components";

export interface TrainingStepperModalProps {
	isOpen: boolean;
	onClose?: () => void;
	activeStep: number;
	selectedDrill: Drill | null;
	selectedPlayers: Player[];
	canBack?: boolean;

	/* Select drill */
	drills: Drill[];
	onDrillClicked: (drill: Drill) => void;
	onDrillsInfoClicked: () => void;

	/* Select players */
	players: Player[];
	lockedPlayers: LockedPlayer[];
	onPlayerClicked: (player: Player) => void;
	onPlayersStepSelectAllClicked: (role?: Role, sort?: AvailableSort) => void;
	onPlayersStepClear: () => void;
	onPlayersStepBack: () => void;
	onPlayersStepContinue: () => void;
}

export const TrainingStepperModal: FC<TrainingStepperModalProps> = ({
	isOpen,
	onClose = () => {},
	activeStep,
	selectedDrill,
	selectedPlayers,
	canBack = true,
	drills,
	lockedPlayers,
	onDrillClicked,
	onDrillsInfoClicked,
	players,
	onPlayerClicked,
	onPlayersStepSelectAllClicked,
	onPlayersStepClear,
	onPlayersStepBack,
	onPlayersStepContinue
}) => {
	const { trainingTokens } = useBalanceContext();

	const isMobile = useIsMobile();

	return (
		<StepperModal
			isOpen={isOpen}
			onClose={onClose}
			activeStep={activeStep}
			headerBeforeStepper={!isMobile ? <TrainingToken quantity={trainingTokens} /> : undefined}
			fullScreen
			maxScreenWidth>
			<StepContent label="Select a drill">
				<SelectDrillStep
					isOpen={isOpen}
					drills={drills}
					onDrillClicked={onDrillClicked}
					onDrillsInfoClicked={onDrillsInfoClicked}
				/>
			</StepContent>
			<StepContent label="Select players">
				{selectedDrill && (
					<SelectPlayersStep
						isOpen={isOpen}
						players={players}
						selectedDrill={selectedDrill}
						selectedPlayers={selectedPlayers}
						canBack={canBack}
						onPlayerClicked={onPlayerClicked}
						onSelectAllClicked={onPlayersStepSelectAllClicked}
						onClear={onPlayersStepClear}
						onBack={onPlayersStepBack}
						onContinue={onPlayersStepContinue}
					/>
				)}
			</StepContent>
		</StepperModal>
	);
};
