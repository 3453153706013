import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";

export const MSL_ROUTES = {
	ROOT: {
		path: "/msl"
	},
	GROUP: {
		path: "/msl/:groupId",
		buildPath: (groupId: MetaSoccerId<IdType.TournamentGroup>) =>
			`${MSL_ROUTES.GROUP.path.replace(":groupId", groupId)}`
	}
};
