import styled, { css } from "styled-components";

export const ImageStyled = styled.div<{ backgroundImage: string }>(
	({ backgroundImage }) => css`
		background-image: url(${backgroundImage});
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	`
);

export const PathToGloryWrapperStyled = styled.div<{ direction?: "row" | "column" }>(
	({ direction }) => css`
		justify-content: ${direction === "row" ? "center" : "flex-start"};
		display: flex;
		flex-direction: ${direction ?? "row"};
		gap: 32px;
		align-items: center;
		height: 100%;
	`
);
