import { useIsMobile } from "@/layouts/context";
import { CoinSize, TrainingCoin, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssTokenRow } from "./Tokens.styled";

interface TrainingTokenProps {
	quantity: number;
	size?: CoinSize;
}

export const TrainingToken: FC<TrainingTokenProps> = ({ quantity, size = "sm" }) => {
	const isMobile = useIsMobile();
	const variant = isMobile ? (size === "xs" ? "caption" : "body2") : "body2";
	return (
		<CssTokenRow size={size}>
			<TrainingCoin size={size} />
			<Typography variant={variant}>
				{quantity.toLocaleString(undefined, {
					maximumFractionDigits: 2
				})}
			</Typography>
		</CssTokenRow>
	);
};
