import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import { Row } from "@peersyst/react-components";
import styled, { css } from "styled-components";
var mdSize = css(function () {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        padding: 0.25rem;\n        height: 1rem;\n        width: 1rem;\n    "])));
});
var lgSize = css(function () {
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        padding: 0.5rem;\n        height: 1.5rem;\n        width: 1.5rem;\n    "])));
});
var checkboxIconSizes = {
  md: mdSize,
  lg: lgSize
};
export var CheckboxIconRoot = styled(Row).attrs({
  alignItems: "center",
  justifyContent: "center"
})(function (_ref) {
  var checked = _ref.checked,
    theme = _ref.theme,
    size = _ref.size;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n            svg {\n                ", ";\n            }\n\n            svg path {\n                transition: fill 0.1s, stroke 0.1s;\n            }\n            transition: background 0.1s ease-in;\n\n            &.Checked {\n                color: ", ";\n                svg path {\n                    fill: currentColor;\n                    stroke: transparent;\n                }\n            }\n            &.UnChecked {\n                color: ", ";\n                svg path {\n                    fill: transparent;\n                    stroke: currentColor;\n                }\n            }\n\n            &.Checked:hover {\n                background: ", ";\n                svg path {\n                    fill: currentColor;\n                }\n            }\n            &.UnChecked:hover {\n                background: transparent;\n                svg path {\n                    fill: ", ";\n                }\n            }\n            &.Disabled {\n                color: ", ";\n            }\n            &.Disabled:hover {\n                background: transparent;\n                svg path {\n                    fill: ", ";\n                }\n            }\n        "])), checkboxIconSizes[size != null ? size : "lg"], theme.palette.greyscale.white, theme.palette.greyscale.white, theme.palette.backgrounds.codGray, theme.palette.greyscale.scorpion, theme.palette.greyscale.scorpion, checked ? "currentColor" : "transparent");
});