import { PlayerCard } from "@/modules/shared/components";
import { Tooltip } from "@metasoccer/metasoccer-ds";
import { Player } from "@metasoccer/metasoccer-types";
import { WrapperStyled } from "./PlayerCardTooltip.styles";

export interface PlayerCardTooltipProps {
	children: React.ReactNode;
	fullWidth?: boolean;
	player: Player;
	position?: "center" | "left" | "right";
}

const PlayerCardTooltip = ({ children, fullWidth, player, position, ...rest }: PlayerCardTooltipProps): JSX.Element => (
	<Tooltip
		{...rest}
		fullWidth={fullWidth}
		hoverOnPopper
		maxWidth="unset"
		mouseEnterDelay={1000}
		position={position ?? "center"}
		padding="0">
		{{
			popper: (
				<WrapperStyled>
					<PlayerCard player={player} />
				</WrapperStyled>
			),
			content: children
		}}
	</Tooltip>
);

export default PlayerCardTooltip;
