import { CopyIcon, IconButton, Row, Typography, TypographyProps } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";

export interface CopyableProps extends TypographyProps {
	children?: ReactNode;
	value?: string;
}

export const Copyable = memo<CopyableProps>(({ children, value, ...props }) => {
	if (!children && !value) {
		throw new Error("Copyable component must have either children or value prop");
	}

	return (
		<Row alignItems="center" gap={4}>
			{typeof children === "string" ? <Typography {...props}>{children}</Typography> : children}
			{(value || children) && (
				<IconButton
					icon={CopyIcon}
					size="sm"
					onClick={() => {
						navigator.clipboard.writeText(value ?? children?.toString() ?? "IMPOSSIBLE_VALUE");
					}}
				/>
			)}
		</Row>
	);
});
