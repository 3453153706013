import { InfoMessage, Loading } from "@/modules/shared/components";
import { Modal } from "@metasoccer/metasoccer-ds";
import { IdType, MetaSoccerId, TournamentDetailedStanding } from "@metasoccer/metasoccer-types";
import { useNavigate, useParams } from "react-router-dom";
import { HOME_ROUTES } from "../../../home.routes";
import { useStandings } from "./Standings.state";
import { FilteredStanding } from "./components";

export interface StandingsModalProps {
	isOpen: boolean;
	onClose: () => void;
	tournamentId: MetaSoccerId<IdType.Tournament>;
	standing: TournamentDetailedStanding;
}

const StandingsPage = () => {
	const { tournamentId } = useParams();
	const navigate = useNavigate();

	const {
		standingsFilters,
		isLoading,
		isLoadingFilteredStanding,
		isError,
		tournamentStructureEditions,
		filteredStanding,
		divisionsWithRewards,
		onEditionChange,
		onDivisionChange,
		onGroupChange,
		onFilterChange
	} = useStandings(tournamentId as MetaSoccerId<IdType.Tournament>);

	const hasEditions = tournamentStructureEditions && tournamentStructureEditions.length > 0;
	const hasData = hasEditions && divisionsWithRewards && divisionsWithRewards.length > 0 && standingsFilters;

	const handleClose = () => navigate(HOME_ROUTES.PVE_ROOT.buildPath());

	return (
		<Modal isOpen onClose={handleClose} title="Standings" fullScreen maxScreenWidth navigationType="arrow">
			{isLoading ? (
				<Loading />
			) : isError || !hasData ? (
				<InfoMessage type="error" />
			) : (
				<FilteredStanding
					isLoadingFilteredStanding={isLoadingFilteredStanding}
					tournamentStructureEditions={tournamentStructureEditions}
					filters={standingsFilters}
					rankedTeams={filteredStanding?.rankedTeams}
					divisionsWithRewards={divisionsWithRewards}
					onEditionChange={onEditionChange}
					onDivisionChange={onDivisionChange}
					onGroupChange={onGroupChange}
					onFilterChange={onFilterChange}
				/>
			)}
		</Modal>
	);
};

export default StandingsPage;
