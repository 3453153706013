import { HttpClient, IdType, MetaSoccerId, Response, TournamentEditionOfInterest } from "@metasoccer/metasoccer-types";

export class TournamentService {
	constructor(private readonly httpService: HttpClient) {}

	async findTournamentEditionOfInterest(teamId?: MetaSoccerId<IdType.Team>): Promise<TournamentEditionOfInterest> {
		let response: Response<{ editionOfInterest: TournamentEditionOfInterest }>;
		try {
			response = await this.httpService.get<{ editionOfInterest: TournamentEditionOfInterest }>(
				`/tournament/editions/of-interest/${teamId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data.editionOfInterest;
	}
}
