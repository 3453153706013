import styled, { css } from "styled-components";

export const CssLink = styled.a`
	text-decoration: none;
	color: inherit;
`;

export const CssTBody = styled("tbody")<{ height: number }>(
	({ height, theme }) => css`
		background-color: ${theme.palette.backgrounds.cinder};
		display: grid;
		height: ${height}px;
		position: relative;
	`
);

export const CssTHead = styled("thead")(
	({ theme }) => css`
		background-color: ${theme.palette.backgrounds.codGray};
		position: sticky;
		top: 0;
		z-index: 1;
	`
);

export const CssTheadTr = styled.tr(
	({ theme }) => css`
		display: flex;
		height: 40px;
		width: 100%;

		th {
			border-bottom: 1px solid ${theme.palette.greyscale.gondola};
			border-left: 1px solid ${theme.palette.greyscale.gondola};
			border-left: 1px solid ${theme.palette.greyscale.gondola};
		}

		& th:last-child {
			border-right: 1px solid ${theme.palette.greyscale.gondola};
		}
	`
);

export const CssTheadTh = styled.th<{ width: number }>(
	({ width, theme }) => css`
		align-items: center;
		display: flex;
		text-align: left;
		padding: 0 8px;
		width: ${width}px;
	`
);
