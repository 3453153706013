import { useIsMobile } from "@/layouts/context";
import { HomeButton } from "@/modules/home";
import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { Container, Modal } from "@/modules/shared/components";
import { defineIf } from "@/modules/shared/utils";
import { ArrowRightIcon, CheckIcon, Col, Row, Typography, getImgUrl } from "@metasoccer/metasoccer-ds";
import { memo, useState } from "react";
import { useTheme } from "styled-components";
import { AccordionStyled, ArrowWrapperStyled, HeaderStyled } from "./AirdropTasks.styles";
import { AirdropTask, useTasks } from "./hooks/useTasks";

export const AirdropTasks = memo(() => {
	const { airdropTasks, hasAirdropStarted, hasPass } = useAirdrop();
	const tasks = useTasks(airdropTasks);

	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
	const [selectedTask, setSelectedTask] = useState<AirdropTask>();

	const isMobile = useIsMobile();
	const theme = useTheme();

	const goToTwitter = () => {
		window.open("https://metasoccer.com/twitter", "_blank");
	};

	const goToDiscord = () => {
		window.open("https://metasoccer.com/discord", "_blank");
	};

	if (hasAirdropStarted) {
		const buildTaskTitle = (task: AirdropTask, size = 40) => (
			<Row alignItems="center" gap={16}>
				<img alt={task.title} src={task.icon} width={size} height={size} />
				<Col gap={2}>
					<Typography variant="buttonLg" style={{ lineHeight: 1, minHeight: 0 }}>
						{task.title}
					</Typography>
					{defineIf(task.points || task.maxPoints, () => (
						<Row alignItems="center" gap={4}>
							<Typography variant="caption" light>
								POINTS:
							</Typography>
							<Row alignItems="center">
								<Typography variant="caption" light>
									{`${task.points ?? 0}`}
								</Typography>
								{task.maxPoints && (
									<Typography variant="caption" light style={{ opacity: 0.42 }}>
										{`/${task.maxPoints}`}
									</Typography>
								)}
							</Row>
						</Row>
					))}
				</Col>
			</Row>
		);

		const closeTaskModal = () => {
			setIsTaskModalOpen(false);
			setTimeout(() => {
				setSelectedTask(undefined);
			}, 0);
		};

		const openTaskModal = (task: AirdropTask) => {
			setSelectedTask(task);
			setTimeout(() => {
				setIsTaskModalOpen(true);
			}, 0);
		};

		return (
			<Col style={{ flexGrow: 1, height: isMobile ? "100vh" : 0, overflow: "hidden" }}>
				<HeaderStyled>
					<Typography variant="h5">Tasks</Typography>
				</HeaderStyled>
				<Col style={{ flexGrow: 1, height: 0, overflow: "auto" }}>
					{tasks.map((task, i) => (
						<>
							{i > 0 && (
								<div
									key={`${task.key}-divider`}
									style={{
										borderBottom: `1px solid ${theme.palette.greyscale.gondola}`,
										margin: "0 16px"
									}}
								/>
							)}
							<HomeButton
								disabled={task.disabled}
								key={task.key}
								style={{ minHeight: 64 }}
								onClick={() => openTaskModal(task)}>
								<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
									{buildTaskTitle(task)}
									<ArrowWrapperStyled>
										{airdropTasks[task.key]?.[0]?.completed ? (
											<CheckIcon color={theme.palette.system.pastelGreen} size="sm" />
										) : (
											<ArrowRightIcon size="sm" />
										)}
									</ArrowWrapperStyled>
								</Row>
							</HomeButton>
						</>
					))}
				</Col>
				{selectedTask && (
					<Modal
						isOpen={isTaskModalOpen}
						mainAction={selectedTask.buildAction({ closeParentModal: closeTaskModal })}
						title={buildTaskTitle(selectedTask, 32)}
						onClose={closeTaskModal}>
						<Col>
							{selectedTask.buildPointsInfo && (
								<AccordionStyled title={`Points: ${airdropTasks[selectedTask.key]?.[0]?.points ?? 0}`}>
									{selectedTask.buildPointsInfo()}
								</AccordionStyled>
							)}
							<Container>{selectedTask.buildInfo()}</Container>
						</Col>
					</Modal>
				)}
			</Col>
		);
	}

	return (
		<Col>
			<HeaderStyled>
				<Typography variant="h5">{hasPass ? "In the meantime" : "Get your MetaSoccer Pass"}</Typography>
			</HeaderStyled>
			<Col>
				<HomeButton height={64} onClick={goToTwitter}>
					<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
						<Row alignItems="center" gap={16}>
							<img
								alt="Follow us on X"
								src={getImgUrl("https://assets.metasoccer.com/social/x.png", {
									width: 128
								})}
								width={40}
							/>
							<Col gap={2}>
								<Typography variant="buttonLg">Follow us on X</Typography>
							</Col>
						</Row>
						<ArrowWrapperStyled>
							<ArrowRightIcon size="sm" />
						</ArrowWrapperStyled>
					</Row>
				</HomeButton>
				<div style={{ borderBottom: `1px solid ${theme.palette.greyscale.gondola}`, margin: "0 16px" }} />
				<HomeButton height={64} onClick={goToDiscord}>
					<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
						<Row alignItems="center" gap={16}>
							<img
								alt="Join our Community on Discord"
								src={getImgUrl("https://assets.metasoccer.com/social/discord.png", {
									width: 128
								})}
								width={40}
							/>
							<Col gap={2}>
								<Typography variant="buttonLg">Join us on Discord</Typography>
							</Col>
						</Row>
						<ArrowWrapperStyled>
							<ArrowRightIcon size="sm" />
						</ArrowWrapperStyled>
					</Row>
				</HomeButton>
			</Col>
		</Col>
	);
});
