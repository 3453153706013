import { Mark } from "@metasoccer/metasoccer-ds";
import { Spinner } from "../Spinner";
import { CssLoadingWrapper } from "./Loading.styled";

export const Loading = ({ cover, light, size }: { cover?: "hv" | "h" | "v"; light?: boolean; size?: "sm" | "md" }) => {
	const actualSize = size ?? "md";

	return (
		<CssLoadingWrapper cover={cover ?? "hv"} light={light}>
			<Mark style={{ height: `${{ md: 20, sm: 8 }[actualSize]}px` }} />
			<Spinner size={actualSize} />
		</CssLoadingWrapper>
	);
};
