import { LineupView } from "@/modules/lineup/components/LineupView";
import { useSelectedTeamState } from "@/modules/shared/context";
import { Typography } from "@metasoccer/metasoccer-ds";
import { LineupWithSkill, LockedPlayer, PlayerShirtNumberDict } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { ContentStyled, HeaderStyled } from "./SquadPreview.styles";

interface SquadPreviewProps {
	lineupWithSkill: LineupWithSkill;
	lockedPlayers: LockedPlayer[];
	shirtNumbers: PlayerShirtNumberDict;
}

export const SquadPreview = memo<SquadPreviewProps>(({ lineupWithSkill, lockedPlayers, shirtNumbers }) => {
	const { players } = useSelectedTeamState();

	return (
		<ContentStyled>
			<HeaderStyled>
				<Typography variant="h5">Your squad</Typography>
			</HeaderStyled>
			<LineupView
				variant="small"
				lineupWithSkill={lineupWithSkill}
				lockedPlayers={lockedPlayers}
				players={players}
				shirtNumbers={shirtNumbers}
			/>
		</ContentStyled>
	);
});
