import { analyticsService } from "@/dependencies";
import { useServerState } from "@/modules/core/react-query/hooks";
import { LINEUP_QUERIES } from "@/modules/lineup/queries";
import { ModalLineupSelector } from "@/modules/lineup/shared/components/ModalLineupSelector";
import { CoreButton, Modal } from "@metasoccer/metasoccer-ds";
import { Formation, LineupWithSkill } from "@metasoccer/metasoccer-types";
import { FC, useState } from "react";
import { CssLineupSelectorWrapper } from "./LinupSelector.styles";

interface LineupSelectorProps {
	currentLineup: LineupWithSkill;
	onFormationSelected: (formation: Formation) => void;
}

export const LineupSelector: FC<LineupSelectorProps> = ({ currentLineup, onFormationSelected }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { data: formations = [] } = useServerState(LINEUP_QUERIES.formations.all());

	const handleChangeFormation = () => {
		setIsModalOpen(true);
		analyticsService.sendEvent("formationClicked");
	};

	return (
		<>
			<CssLineupSelectorWrapper>
				<CoreButton size={"md"} appearance={"secondary"} onClick={handleChangeFormation}>
					{currentLineup.lineup.formation.name}
				</CoreButton>
			</CssLineupSelectorWrapper>

			{isModalOpen && (
				<Modal title={"Change formation"} size="sm" isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
					<ModalLineupSelector
						formations={formations}
						selectedValueId={currentLineup.lineup.formation.id}
						onClick={(formation) => {
							onFormationSelected(formation);
							setIsModalOpen(false);
						}}
					/>
				</Modal>
			)}
		</>
	);
};
