import { METASOCCER_DAY_MS, METASOCCER_WEEK_MS } from "../time";

export type Injury = {
	name: INJURY_TYPES;
	minDurationMs: number;
	maxDurationMs: number;
};

export enum INJURY_TYPES {
	MUSCLE_OVERLOAD = "Muscle Overload",
	CONCUSSION = "Concussion",
	STOMACH_FLU = "Stomach Flu",
	FLU = "Flu",
	ANKLE_SPRAIN = "Ankle Sprain",
	FIBRILLAR_RUPTURE = "Fibrillar Rupture",
	LIGAMENT_STRAIN = "Ligament Strain",
	MUSCLE_STRAIN = "Muscle Strain",
	TWISTED_ANKLE = "Twisted Ankle",
	CERVICAL_CONTRACTURE = "Cervical Contracture",
	ABDUCTOR_STRETCH = "Abductor Stretch",
	MENISCUS_TEAR = "Meniscus Tear",
	ACHILLES_TENDON_RUPTURE = "Achilles Tendon Rupture",
	FRACTURED_NOSE = "Fractured Nose",
	KNEE_SPRAIN = "Knee Sprain",
	TIBIA_AND_FIBULA_FRACTURE = "Tibia and Fibula Fracture",
	TORN_LIGAMENT = "Torn Ligament",
	TRIAD = "Triad"
}

export const INJURIES: Injury[] = [
	{
		name: INJURY_TYPES.MUSCLE_OVERLOAD,
		minDurationMs: METASOCCER_WEEK_MS + 1 * METASOCCER_DAY_MS,
		maxDurationMs: METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.CONCUSSION,
		minDurationMs: METASOCCER_WEEK_MS + 1 * METASOCCER_DAY_MS,
		maxDurationMs: METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.STOMACH_FLU,
		minDurationMs: METASOCCER_WEEK_MS + 1 * METASOCCER_DAY_MS,
		maxDurationMs: METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.FLU,
		minDurationMs: METASOCCER_WEEK_MS + 1 * METASOCCER_DAY_MS,
		maxDurationMs: METASOCCER_WEEK_MS + 1 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.ANKLE_SPRAIN,
		minDurationMs: 2 * METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS,
		maxDurationMs: 2 * METASOCCER_WEEK_MS + 4 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.FIBRILLAR_RUPTURE,
		minDurationMs: 3 * METASOCCER_WEEK_MS + 3 * METASOCCER_DAY_MS,
		maxDurationMs: 3 * METASOCCER_WEEK_MS + 6 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.LIGAMENT_STRAIN,
		minDurationMs: 5 * METASOCCER_WEEK_MS + 3 * METASOCCER_DAY_MS,
		maxDurationMs: 5 * METASOCCER_WEEK_MS + 6 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.MUSCLE_STRAIN,
		minDurationMs: 2 * METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS,
		maxDurationMs: 2 * METASOCCER_WEEK_MS + 4 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.TWISTED_ANKLE,
		minDurationMs: METASOCCER_WEEK_MS + 1 * METASOCCER_DAY_MS,
		maxDurationMs: METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.CERVICAL_CONTRACTURE,
		minDurationMs: 2 * METASOCCER_WEEK_MS,
		maxDurationMs: 2 * METASOCCER_WEEK_MS
	},
	{
		name: INJURY_TYPES.ABDUCTOR_STRETCH,
		minDurationMs: 2 * METASOCCER_WEEK_MS + 2 * METASOCCER_DAY_MS,
		maxDurationMs: 2 * METASOCCER_WEEK_MS + 4 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.MENISCUS_TEAR,
		minDurationMs: 3 * METASOCCER_WEEK_MS + 3 * METASOCCER_DAY_MS,
		maxDurationMs: 3 * METASOCCER_WEEK_MS + 6 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.ACHILLES_TENDON_RUPTURE,
		minDurationMs: 18 * METASOCCER_WEEK_MS + 7 * METASOCCER_DAY_MS,
		maxDurationMs: 18 * METASOCCER_WEEK_MS + 28 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.FRACTURED_NOSE,
		minDurationMs: 6 * METASOCCER_WEEK_MS + 3 * METASOCCER_DAY_MS,
		maxDurationMs: 6 * METASOCCER_WEEK_MS + 6 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.KNEE_SPRAIN,
		minDurationMs: 5 * METASOCCER_WEEK_MS + 3 * METASOCCER_DAY_MS,
		maxDurationMs: 5 * METASOCCER_WEEK_MS + 6 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.TIBIA_AND_FIBULA_FRACTURE,
		minDurationMs: 25 * METASOCCER_WEEK_MS + 7 * METASOCCER_DAY_MS,
		maxDurationMs: 25 * METASOCCER_WEEK_MS + 28 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.TORN_LIGAMENT,
		minDurationMs: 20 * METASOCCER_WEEK_MS + 7 * METASOCCER_DAY_MS,
		maxDurationMs: 20 * METASOCCER_WEEK_MS + 28 * METASOCCER_DAY_MS
	},
	{
		name: INJURY_TYPES.TRIAD,
		minDurationMs: 40 * METASOCCER_WEEK_MS + 7 * METASOCCER_DAY_MS,
		maxDurationMs: 40 * METASOCCER_WEEK_MS + 28 * METASOCCER_DAY_MS
	}
];

export function getInjuryByType(type: INJURY_TYPES): Injury {
	return INJURIES.find((injury) => injury.name === type)!;
}

// knee sprain
// Tibia and fibula fracture
// Torn ligament
// Triad
