import { useServerState } from "@/modules/core/react-query/hooks";
import { LINEUP_QUERIES } from "@/modules/lineup/queries";
import { PVE_TEAM_SUFFIX_BY_DIFFICULTY } from "@/modules/shared/constants";
import { useGlobalState } from "@/modules/shared/context";
import { PveDifficulty, getByPveDifficultyLogo, pveDifficultyIntervals } from "@/modules/shared/helpers";
import { ClubLogo } from "@metasoccer/metasoccer-ds";
import { Team } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { useTheme } from "styled-components";
import { FriendlyMatchBoardTeam } from "./FriendlyMatchBoardTeam";
import { CssTeamsBoardWrapper, CssVs } from "./TeamsBoard.styles";

interface TeamsBoardProps {
	selectedTeam: Team;
	difficulty: PveDifficulty;
}

export const TeamsBoard: FC<TeamsBoardProps> = ({ selectedTeam, difficulty }) => {
	const theme = useTheme();
	const { address } = useGlobalState();
	const { data: lineupWithSkill } = useServerState(
		LINEUP_QUERIES.lineupsWithSkill.byOwnerAndTeamId(address, selectedTeam?.id),
		{ enabled: !!selectedTeam }
	);

	const homeTeam = {
		name: selectedTeam.clubAcronym,
		overall: lineupWithSkill?.skill.overallSkill,
		clubLogo: <ClubLogo isMatch={true} name={selectedTeam.clubLogo.name} color={selectedTeam.clubLogo.color} />
	};
	const awayTeam = {
		name: `PVE ${PVE_TEAM_SUFFIX_BY_DIFFICULTY[difficulty]}`,
		awayOverall: pveDifficultyIntervals[difficulty].join("-"),
		overall:
			pveDifficultyIntervals[difficulty].reduce((a, b) => a + b, 0) / pveDifficultyIntervals[difficulty].length,
		clubLogo: getByPveDifficultyLogo(difficulty, theme.palette)
	};

	return lineupWithSkill ? (
		<CssTeamsBoardWrapper>
			<FriendlyMatchBoardTeam team={homeTeam} />
			<CssVs variant={"h5"}>VS</CssVs>
			<FriendlyMatchBoardTeam team={awayTeam} isAway={true} />
		</CssTeamsBoardWrapper>
	) : (
		<></>
	);
};
