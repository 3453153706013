import { Sort } from "@/modules/shared/helpers/sorting-comparators/Sort";

type ThingWithId = any & { id: number };

export function makePlayersByIdComparator(
	sort: Sort = Sort.ASC,
	selectorA: (a: any) => number = (a) => a.id,
	selectorB: (b: any) => number = (b) => b.id
): (a: ThingWithId, b: ThingWithId) => number {
	return (a: ThingWithId, b: ThingWithId): number => {
		if (sort === Sort.ASC) {
			return selectorA(a) - selectorB(b);
		}

		return selectorB(b) - selectorA(a);
	};
}
