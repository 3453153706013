import { useIsMobile } from "@/layouts/context";
import { PrizePool } from "@/modules/shared/components";
import { Modal } from "@metasoccer/metasoccer-ds";
import { TournamentDivisionSimplified } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { CssContent } from "./RewardsInfoModal.styled";

interface RewardsInfoModalProps {
	isOpen: boolean;
	divisions: TournamentDivisionSimplified[];
	selectedDivision: string;
	onClose: () => void;
}

export const RewardsInfoModal: FC<RewardsInfoModalProps> = ({ isOpen, onClose, divisions, selectedDivision }) => {
	const isMobile = useIsMobile();

	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Rewards info" size="lg" fullScreen={isMobile}>
			<CssContent>
				<PrizePool divisions={divisions} selectedDivision={selectedDivision} />
			</CssContent>
		</Modal>
	);
};
