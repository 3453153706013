import { persistentStorageService } from "@/dependencies";
import { GlobalState } from "@/modules/shared/context";
import { StorageService } from "@/modules/shared/services";
import {
	HttpClient,
	IdType,
	ManagedTeamStats,
	MatchedTeam,
	MetaSoccerId,
	Response,
	Squad,
	Team,
	TeamDelegationRequest
} from "@metasoccer/metasoccer-types";
import React from "react";
import { TEAM_STORAGE_KEY } from "../constants";

export class TeamService {
	constructor(private readonly httpService: HttpClient, private readonly persistentStorageService: StorageService) {}

	async cancelDelegateTeam(teamId: MetaSoccerId<IdType.Team>): Promise<Team> {
		try {
			const { data } = await this.httpService.post<{ teamId: MetaSoccerId<IdType.Team> }, Team>(
				"/teams/cancelDelegate",
				{ teamId }
			);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async createTeamFromPlayerIds(playerIds: number[]): Promise<Team> {
		try {
			const { data } = await this.httpService.post<{ playerIds: number[] }, Team>("/teams/from-playerIds", {
				playerIds
			});
			return data;
		} catch (e) {
			throw e;
		}
	}

	async delegateTeam(teamDelegationRequest: TeamDelegationRequest): Promise<Team> {
		try {
			const { data } = await this.httpService.post<TeamDelegationRequest, Team>(
				"/teams/delegate",
				teamDelegationRequest
			);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async deleteTeam(teamId: MetaSoccerId<IdType.Team>): Promise<void> {
		try {
			await this.httpService.post(`/teams/delete/${teamId}`);
		} catch (e) {
			throw e;
		}
	}

	async renounceDelegation(teamId: MetaSoccerId<IdType.Team>): Promise<Team> {
		let response: Response<Team>;
		try {
			response = await this.httpService.post<{ teamId: MetaSoccerId<IdType.Team> }>("/teams/renounceDelegation", {
				teamId
			});
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async setSelectedTeam(address: string, setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>) {
		const managedTeams: Team[] = await this.getManagedTeams(address);

		const teamIdFromStorage = persistentStorageService.getString(TEAM_STORAGE_KEY);

		if (teamIdFromStorage) {
			const teamFromRequest = managedTeams.find((team) => team.id === teamIdFromStorage);

			if (teamFromRequest) {
				this.saveSelectedTeam(teamFromRequest, setGlobalState);
			} else {
				this.saveSelectedTeam(managedTeams[0], setGlobalState);
			}
		} else {
			this.saveSelectedTeam(managedTeams[0], setGlobalState);
		}

		if (managedTeams[0].name === "[CREATING]") {
			setTimeout(() => {
				this.setSelectedTeam(address, setGlobalState);
			}, 5000);
		}
	}

	async getManagedTeams(userAddress: string): Promise<Team[]> {
		let response: Response<{ managedTeams: Team[] }>;
		try {
			response = await this.httpService.get<{ managedTeams: Team[] }>(`/teams/managed/${userAddress}`);
		} catch (e) {
			throw e;
		}

		return response.data.managedTeams;
	}

	async getOwnedTeams(userAddress: string): Promise<{ defaultTeam: Team; otherTeams: Team[] }> {
		try {
			const response = await this.httpService.get<{ defaultTeam: Team; otherTeams: Team[] }>(
				`/teams/owned/${userAddress}`
			);
			return response.data;
		} catch (e) {
			throw e;
		}
	}

	async getTeamSquadsByOwner(userAddress: string): Promise<Squad[]> {
		try {
			const { data } = await this.httpService.get<{ squads: Squad[] }>(`/teams/squad/owned/${userAddress}`);
			return data.squads;
		} catch (e) {
			throw e;
		}
	}

	async getTeamStatsByOwner(userAddress: string): Promise<ManagedTeamStats[]> {
		try {
			const { data } = await this.httpService.get<{ teamStats: ManagedTeamStats[] }>(`/teams/stats`);
			return data.teamStats;
		} catch (e) {
			throw e;
		}
	}

	async findTeamsByNameLike(nameLike: string): Promise<MatchedTeam[]> {
		type ResponseType = { collection: MatchedTeam[] };
		let response: Response<ResponseType>;
		try {
			if (nameLike.length < 3) {
				return [];
			}

			response = await this.httpService.get<ResponseType>(`/teams/search?nameLike=${nameLike}`);
		} catch (e) {
			throw e;
		}

		return response.data.collection;
	}

	async updateSquadPlayers(teamId: MetaSoccerId<IdType.Team>, playerIds: number[]): Promise<Squad> {
		try {
			const { data } = await this.httpService.post<{ playerIds: number[] }, Squad>(
				`/teams/squad/${teamId}/players/update`,
				{
					playerIds
				}
			);
			return data;
		} catch (e) {
			throw e;
		}
	}

	private saveSelectedTeam(team: Team, setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>) {
		this.persistentStorageService.save(TEAM_STORAGE_KEY, team.id);

		setGlobalState((prevState) => ({ ...prevState, selectedTeam: team }));
	}
}
