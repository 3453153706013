import { LineupPlayerSkill, Player } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { CssWrapper } from "./LineupPlayerDragOverlay.styles";
import { AssignedPlayerSlot } from "./components/AssignedPlayerSlot";

interface LineupPlayerDragOverlayProps {
	player: Player;
	playerSkill: LineupPlayerSkill;
	shirtNumber: number;
	slotId: string;
}

export const LineupPlayerDragOverlay = memo<LineupPlayerDragOverlayProps>(
	({ player, playerSkill, shirtNumber, slotId }) => {
		return (
			<CssWrapper>
				<AssignedPlayerSlot
					shirtNumber={shirtNumber}
					isPlayerRegisteredInPve={false}
					player={player}
					playerSkill={playerSkill}
					showPlayerSpecificRole
					showSlotId={false}
					slotId={slotId}
				/>
			</CssWrapper>
		);
	}
);
