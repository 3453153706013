import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { deprecatedHomeService, homeService } from "../dependencies";

export const HOME_QUERIES = {
	groupInfoByTeam: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.HOME, "groupInfoByTeam", teamId],
		queryFn: () => homeService.getGroupInfoByTeam(teamId)
	}),
	groupInfoOfTopMSL: () => ({
		queryKey: [BaseServerStateKeys.HOME, "groupInfoOfTopMSL"],
		queryFn: () => homeService.getGroupInfoOfTopMSL()
	}),
	teamInfo: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.HOME, "teamInfo", teamId],
		queryFn: () => homeService.getTeamInfo(teamId)
	}),
	// deprecated
	statsByTournamentAndTeamId: (teamId: MetaSoccerId<IdType.Team>, tournamentId: MetaSoccerId<IdType.Tournament>) => ({
		queryKey: [BaseServerStateKeys.TOURNAMENTS, "stats", tournamentId, teamId],
		queryFn: () => deprecatedHomeService.getAllStats(teamId, tournamentId)
	}),
	rewardsByTournamentAndTeamId: (
		teamId: MetaSoccerId<IdType.Team>,
		tournamentId: MetaSoccerId<IdType.Tournament>
	) => ({
		queryKey: [BaseServerStateKeys.TOURNAMENTS, "rewards", tournamentId, teamId],
		queryFn: () => deprecatedHomeService.getTournamentRewards(teamId, tournamentId)
	}),
	lastMatchesByTeamId: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.TOURNAMENTS, "lastMatches", teamId],
		queryFn: () => deprecatedHomeService.getLastTournamentMatchesByTeamId(teamId)
	}),
	lastMatches: () => ({
		queryKey: [BaseServerStateKeys.TOURNAMENTS, "lastMatches", "of-interest"],
		queryFn: () => deprecatedHomeService.getLastTournamentMatches()
	}),
	detailedStandings: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [
			BaseServerStateKeys.TOURNAMENTS,
			BaseServerStateKeys.TOURNAMENT_EDITIONS,
			BaseServerStateKeys.STANDINGS,
			teamId,
			"detailedStandings"
		],
		queryFn: () => deprecatedHomeService.getDetailedStandings(teamId)
	}),
	tournamentStructureEditions: (tournamentId: MetaSoccerId<IdType.Tournament>) => ({
		queryKey: [BaseServerStateKeys.TOURNAMENTS, tournamentId],
		queryFn: () => deprecatedHomeService.getTournamentStructureEditions(tournamentId)
	}),
	filteredDetailedStandings: (
		tournamentId: MetaSoccerId<IdType.Tournament>,
		editionId?: number,
		divisionId?: string,
		groupId?: number
	) => ({
		queryKey: [
			BaseServerStateKeys.TOURNAMENTS,
			tournamentId,
			editionId ? editionId.toString() : "",
			divisionId ?? "",
			groupId ? groupId.toString() : "",
			"filteredDetailedStandings"
		],
		queryFn: () => deprecatedHomeService.getDetailedStandingsFiltered(tournamentId, editionId, divisionId, groupId)
	}),
	rewardsByTournamentAndEdition: (tournamentId: MetaSoccerId<IdType.Tournament>, editionId?: number) => ({
		queryKey: [
			BaseServerStateKeys.TOURNAMENTS,
			BaseServerStateKeys.TOURNAMENT_EDITIONS,
			tournamentId,
			editionId ? editionId.toString() : "",
			"rewards"
		],
		queryFn: () => deprecatedHomeService.getRewardsByTournamentAndEdition(tournamentId, editionId)
	})
};
