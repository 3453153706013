import { useIsMobile } from "@/layouts/context";
import { useMutableServerState, useServerState } from "@/modules/core/react-query/hooks";
import { useUpdateLineupPlayer } from "@/modules/lineup/hooks";
import { LINEUP_MUTABLE_QUERIES } from "@/modules/lineup/queries/mutableQueries";
import { LineupWithSkill, LockedPlayer, Player, PlayerShirtNumberDict } from "@metasoccer/metasoccer-types";
import { FC, useCallback } from "react";
import { LineupBench } from "../LineupBench";
import { LineupHeader } from "../LineupHeader";
import { useUpdateLineupFormation } from "../LineupHeader/components/LineupSelector/hooks";
import { LineupOnThePitch } from "../LineupOnThePitch";

import { useGlobalState } from "@/modules/shared/context";
import { useToast } from "@/modules/shared/hooks";
import { sortBy } from "@/modules/shared/utils";
import groupBy from "@/modules/shared/utils/groupBy";
import { LINEUP_QUERIES } from "../../queries";
import { LineupDndContext } from "../LineupDndContext";
import { LineupSquad } from "../LineupSquad";
import {
	CssPitchAndBenchOuterWrapper,
	CssPitchAndBenchWrapper,
	CssSquadWrapper,
	CssWrapper
} from "./LineupView.styles";

interface LineupViewProps {
	variant?: "small" | "default";
	lineupWithSkill: LineupWithSkill;
	lockedPlayers?: LockedPlayer[];
	players: Player[];
	shirtNumbers: PlayerShirtNumberDict;
}

export const LineupView: FC<LineupViewProps> = ({
	variant = "default",
	lineupWithSkill,
	players,
	shirtNumbers,
	lockedPlayers
}) => {
	const { showErrToast, showToast } = useToast();
	const isMobile = useIsMobile();
	const isDesktop = !isMobile;

	const { selectedTeam } = useGlobalState();

	const { data: formations = [] } = useServerState(LINEUP_QUERIES.formations.all());

	const { mutate: updateLineup } = useMutableServerState(LINEUP_MUTABLE_QUERIES.update());

	const { update: updateLineupPlayer, updateBench } = useUpdateLineupPlayer(lineupWithSkill, updateLineup);
	const { update: updateFormation } = useUpdateLineupFormation(
		lineupWithSkill,
		selectedTeam!,
		lineupWithSkill.lineup.formation.id,
		() => {}
	);

	const autoLineup = useCallback(() => {
		if (!selectedTeam) return;

		let formationId: string | undefined;
		let linedPlayers: number[] = [];

		for (const formation of formations) {
			formationId = formation.id;
			linedPlayers = [];

			formation.slots.forEach((slot, index) => {
				const unusedPlayers = players.filter((player) => !linedPlayers.includes(player.id));
				const playersByRole = groupBy(unusedPlayers, (p) => p.role);
				const sortedPlayers = playersByRole[slot.role]
					? sortBy(playersByRole[slot.role], (a) => a.overall, true)
					: [];
				const candidate = sortedPlayers.shift();
				if (candidate) {
					linedPlayers.push(candidate.id);
				}
			});

			if (linedPlayers.length === 11) {
				break;
			}
		}

		if (formationId && linedPlayers.length === 11) {
			updateLineup({
				lineup: {
					owner: selectedTeam.owner!,
					teamId: selectedTeam.id,
					formationId: formationId,
					linedPlayers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => ({
						playerId: linedPlayers[index],
						slotId: index
					})),
					playersInBenchIds: []
				},
				teamId: selectedTeam.id
			});

			showToast("Lineup completed automagically");
		} else {
			showErrToast(`Couldn't complete a proper lineup`);
		}
	}, [formations, lineupWithSkill, players, selectedTeam]);

	return (
		<LineupDndContext
			players={players}
			shirtNumbers={shirtNumbers}
			onUpdateBench={updateBench}
			onUpdateLineup={updateLineupPlayer}>
			<CssWrapper>
				<CssPitchAndBenchOuterWrapper>
					<CssPitchAndBenchWrapper variant={variant}>
						<LineupHeader
							variant={variant}
							lineupWithSkill={lineupWithSkill}
							onAutoLineupClick={autoLineup}
							onFormationSelected={updateFormation}
						/>
						<LineupOnThePitch
							variant={variant}
							lineupWithSkill={lineupWithSkill}
							lockedPlayers={lockedPlayers}
							playersIdsOff={[]}
							shirtNumbers={shirtNumbers}
							onPlayerSelected={updateLineupPlayer}
						/>
						{variant === "default" && (
							<LineupBench
								lineupWithSkill={lineupWithSkill}
								lockedPlayers={lockedPlayers}
								playersIdsOff={[]}
								shirtNumbers={shirtNumbers}
								onPlayerSelected={updateBench}
							/>
						)}
					</CssPitchAndBenchWrapper>
				</CssPitchAndBenchOuterWrapper>
				{variant === "default" && isDesktop && (
					<CssSquadWrapper>
						<LineupSquad
							lineupWithSkill={lineupWithSkill}
							lockedPlayers={lockedPlayers}
							players={players}
							shirtNumbers={shirtNumbers}
							tableOptions={{ isDraggable: true }}
						/>
					</CssSquadWrapper>
				)}
			</CssWrapper>
		</LineupDndContext>
	);
};
