export enum PlayMatchParams {
	TOURNAMENT_PVE = "tournament-pve",
	FRIENDLY_PVP = "friendly-pvp",
	FRIENDLY = "friendly"
}

export enum PLAY_MATCH_ROUTES {
	ROOT = "/play-match",
	ROOT_FRIENDLY = "/play-match?type=friendly",
	ROOT_TOURNAMENT_PVE = "/play-match?type=tournament-pve",
	ROOT_FRIENDLY_PVP = "/play-match?type=friendly-pvp"
}
