import { Christmify } from "@/modules/christmas";
import { HomeButton } from "@/modules/home/components";
import { JoinModal } from "@/modules/msl/components/JoinModal";
import { MSLIcon } from "@/modules/shared/components";
import { ArrowRightIcon, CheckIcon, Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentStyled, MSLIconWrapperStyled } from "./JoinTournament.styles";

import { useAccountGuard } from "@/modules/auth";
import { TOURNAMENT_ROUTES } from "@/modules/tournament/tournament.routes";
import { ReactComponent as BallSvg } from "./assets/ball.svg";

interface JoinTournamentProps {
	callToAction: "exploreTournaments" | "joinMSL";
}

export const JoinTournament = memo<JoinTournamentProps>(({ callToAction }) => {
	const isChristmasEnabled = useFlag(FeatureFlag.Christmas);

	const navigate = useNavigate();
	const { executeIfTeam } = useAccountGuard();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const action = useMemo(() => {
		return {
			exploreTournaments: () => navigate(TOURNAMENT_ROUTES.ROOT.buildPath()),
			joinMSL: () => executeIfTeam(() => setIsModalOpen(true))
		}[callToAction];
	}, [callToAction, executeIfTeam, setIsModalOpen]);

	return (
		<>
			<HomeButton height={332} onClick={action}>
				{callToAction === "exploreTournaments" && (
					<ContentStyled>
						<Christmify if={isChristmasEnabled}>
							<MSLIconWrapperStyled>
								<MSLIcon width={90} />
							</MSLIconWrapperStyled>
						</Christmify>
						<Col gap={8}>
							<Typography textAlign="center" variant="caption">
								Get ready for the upcoming MetaSoccer League's edition! Explore our community
								tournaments and get in on the action today.
							</Typography>
						</Col>
						<Row gap={4}>
							<CheckIcon size="sm" />
							<Typography variant="buttonMd">Joined</Typography>
						</Row>
					</ContentStyled>
				)}
				{callToAction === "joinMSL" && (
					<ContentStyled>
						<Col gap={8}>
							<Typography textAlign="center" variant="h5">
								Play a match
							</Typography>
							<Typography textAlign="center" variant="caption">
								Your team does not have any next match. Join a competition and start winning prizes
							</Typography>
						</Col>
						<BallSvg width={120} />
						<Row gap={4}>
							<Typography variant="buttonMd">Join MetaSoccer League</Typography>
							<ArrowRightIcon size="sm" />
						</Row>
					</ContentStyled>
				)}
			</HomeButton>
			{isModalOpen && <JoinModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
		</>
	);
});
