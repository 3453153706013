const ScoutLevelToPlayerCount: Record<number, number> = {
	1: 1,
	2: 1,
	3: 1,
	4: 1,
	5: 1,
	6: 1
};

export const getPlayerCountByScoutLevel = (scoutLevel: number): number => {
	return ScoutLevelToPlayerCount[scoutLevel];
};
