import { NextPvpScheduledMatch } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { PvpAttackZones } from "../../../PvpAttackZones";
import { NextMatchPreviewStyled, TwoColumnLayoutStyled, WrapperStyled } from "./MatchPreparation.styled";
import { LineupNotices } from "./components/LineupNotices";
import { NextMatchStatsPreview } from "./components/NextMatchStatsPreview";

interface MatchPreparationProps {
	nextMatch: NextPvpScheduledMatch;
}

export const MatchPreparation: FC<MatchPreparationProps> = ({ nextMatch }) => {
	return (
		<WrapperStyled>
			<NextMatchPreviewStyled
				matchDate={nextMatch.match.date}
				homeTeamInfo={nextMatch.teams.home}
				awayTeamInfo={nextMatch.teams.away}
				notices={nextMatch.notices}
				size={"md"}
			/>
			{nextMatch.notices.length > 0 && <LineupNotices notices={nextMatch.notices} />}
			<TwoColumnLayoutStyled divider={false}>
				<NextMatchStatsPreview home={nextMatch.teams.home} away={nextMatch.teams.away} />
				<PvpAttackZones
					homeAttackZones={nextMatch.teams.home.attackZones}
					awayAttackZones={nextMatch.teams.away.attackZones}
					homeColor={nextMatch.teams.home.clubLogo.color}
					awayColor={nextMatch.teams.away.clubLogo.color}
					title="AVG. PLAY ZONES"
				/>
			</TwoColumnLayoutStyled>
		</WrapperStyled>
	);
};
