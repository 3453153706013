import { useEffect, useState } from "react";
import { useGlobalState } from "../../shared/context";

export const useAccountGuard = () => {
	const { isConnected, isConnecting, selectedTeam } = useGlobalState();

	const [onConnectedActionQueue, setOnConnectedActionQueue] = useState<Function[]>([]);
	const [onTeamActionQueue, setOnTeamActionQueue] = useState<Function[]>([]);

	const executeConnected = async (action: Function) => {
		if (isConnecting) {
			// DO NOTHING
		} else if (!isConnected) {
			onConnectedActionQueue.push(action);
			document.dispatchEvent(new CustomEvent("connectWalletIntent"));
		} else {
			action();
		}
	};

	const executeIfTeam = async (action: Function) => {
		if (isConnecting) {
			// DO NOTHING
		} else if (!selectedTeam) {
			onTeamActionQueue.push(action);
			document.dispatchEvent(new CustomEvent("connectWalletIntent"));
		} else {
			action();
		}
	};

	useEffect(() => {
		if (isConnected) {
			setTimeout(() => {
				onConnectedActionQueue.forEach((action) => action());
			}, 100);
		}
		setOnConnectedActionQueue([]);
	}, [isConnected]);

	useEffect(() => {
		if (selectedTeam) {
			setTimeout(() => {
				onTeamActionQueue.forEach((action) => action());
			}, 100);
		}
		setOnTeamActionQueue([]);
	}, [selectedTeam]);

	return { executeConnected, executeIfTeam };
};
