import { ScheduledMatchPreviewInfo } from "@metasoccer/metasoccer-types";

export type Round = { date: number; matches: ScheduledMatchPreviewInfo[] };

export const getRoundsFromScheduledMatches = (scheduledMatches: ScheduledMatchPreviewInfo[]): Round[] => {
	const orderedByDateAsc = scheduledMatches.sort((a, b) => a.date - b.date);
	const grouped = groupBy(orderedByDateAsc, "date");

	const rounds: Round[] = [];
	for (const [key, value] of Object.entries(grouped)) {
		rounds.push({ date: Number(key), matches: value as ScheduledMatchPreviewInfo[] });
	}

	return rounds;
};

var groupBy = function (xs: ScheduledMatchPreviewInfo[], key: string) {
	return xs.reduce((rv: any, x: any) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};
