import { useIsMobile } from "@/layouts/context";
import { Paragraph } from "@/modules/shared/components";
import { Button, Col, Mark } from "@metasoccer/metasoccer-ds";
import { useState } from "react";
import { ContentStyled, VignetteStyled, WrapperStyled, YouTubeStyled } from "./Welcome.styled";

interface WelcomeProps {
	handleNextStep: () => void;
}

export const Welcome: React.FC<WelcomeProps> = ({ handleNextStep }) => {
	const isMobile = useIsMobile();

	const [showVideo, setShowVideo] = useState(false);

	return (
		<WrapperStyled>
			<YouTubeStyled
				videoId="5zBUmca9-JY"
				hidden={!showVideo}
				isMobile={isMobile}
				opts={{
					width: "100%",
					height: "100%",
					playerVars: {
						autoplay: 1,
						controls: 0,
						disablekb: 1,
						iv_load_policy: 3,
						loop: 1,
						modestbranding: 1,
						mute: 1,
						playlist: "5zBUmca9-JY",
						playsinline: 1,
						rel: 0
					}
				}}
				onPlay={() => setShowVideo(true)}
			/>
			<VignetteStyled>
				<ContentStyled>
					<Col alignItems="center" gap={32} style={{ maxWidth: 800 }}>
						<Mark style={{ height: 80 }} />

						<Paragraph variant="h4" textAlign="center">
							Welcome to Metasoccer
						</Paragraph>

						<Paragraph variant="body1" textAlign="center" light>
							Build your club from the ground up, handpick your squad, and lead them to victory. Every
							player, every strategy, every win is yours.
						</Paragraph>

						<Paragraph variant="body1" textAlign="center" light>
							Ready to own the game? Let's get started!
						</Paragraph>

						<Button size="lg" appearance="primary" label="Start playing" onClick={handleNextStep} />
					</Col>
				</ContentStyled>
			</VignetteStyled>
		</WrapperStyled>
	);
};
