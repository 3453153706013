import { configService } from "@/dependencies";
import { MourinhoMatch } from "@/modules/match/types/MourinhoMatch";
import { useGlobalState, useSelectedTeamState } from "@/modules/shared/context";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";

const MATCH_UPDATE_INTERVAL_MS = 500;

type LiveMatchContextProviderProps = { children: React.ReactNode };

const useLiveMatchState = () => {
	const config = configService.get();

	const timeoutRef = useRef<NodeJS.Timeout>();

	const { selectedTeam } = useGlobalState();
	const { teamInfo } = useSelectedTeamState();

	const nextMatchDate = teamInfo?.nextMatch?.match?.date;

	const [currentMatch, setCurrentMatch] = useState<MourinhoMatch>();

	const updateCurrentMatch = useCallback(() => {
		if (!selectedTeam?.id) return;

		axios
			.get<MourinhoMatch>(`${config.playMourinhoBaseUrl}/match?teamId=${selectedTeam.id}`)
			.then((res) => setCurrentMatch(res.data));
	}, [selectedTeam]);

	useEffect(() => {
		if (!selectedTeam) return;

		updateCurrentMatch();
	}, [selectedTeam]);

	useEffect(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		if (currentMatch) {
			timeoutRef.current = setTimeout(() => {
				updateCurrentMatch();
			}, MATCH_UPDATE_INTERVAL_MS);
		} else if (nextMatchDate) {
			const now = new Date().getTime();
			const timeToMatch = nextMatchDate - now;

			timeoutRef.current = setTimeout(() => {
				updateCurrentMatch();
			}, timeToMatch);
		}
	}, [currentMatch, nextMatchDate]);

	return {
		currentMatch,
		updateCurrentMatch
	};
};

const LiveMatchContext = React.createContext<ReturnType<typeof useLiveMatchState> | undefined>(undefined);

const LiveMatchContextProvider = ({ children }: LiveMatchContextProviderProps) => {
	const homeState = useLiveMatchState();

	return <LiveMatchContext.Provider value={homeState}>{children}</LiveMatchContext.Provider>;
};

export { LiveMatchContext, LiveMatchContextProvider };
