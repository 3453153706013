import { useIsMobile } from "@/layouts/context";
import { FlexScrollingContainer } from "@/modules/shared/components";
import { AVAILABLE_SORTS, AvailableSort } from "@/modules/training/constants";
import { Button, Col, PlayerIcon, Row, Select, ToggleButton, Typography } from "@metasoccer/metasoccer-ds";
import { Drill, Player, Role } from "@metasoccer/metasoccer-types";
import { FC, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import {
	CssFooterActions,
	CssListWrapper,
	CssNoPlayersWrapper,
	CssPlayerCardWithHover,
	CssPlayersStepWrapper,
	CssSelectAllWrapper,
	CssSelectionInfo,
	CssSortHeaderWrapper,
	CssTogglebar,
	CssTogglebarWrapper
} from "./SelectPlayersStep.styled";

type ToggleName = "All" | Role.GK | Role.DF | Role.MF | Role.FW;
type ToggleStates = Record<ToggleName, boolean>;
export interface SelectPlayersStepProps {
	isOpen: boolean;
	players: Player[];
	selectedDrill: Drill;
	selectedPlayers: Player[];
	canBack?: boolean;
	onPlayerClicked?: (player: Player) => void;
	onSelectAllClicked?: (role?: Role, sort?: AvailableSort) => void;
	onClear?: () => void;
	onBack?: () => void;
	onContinue?: () => void;
}

export const SelectPlayersStep: FC<SelectPlayersStepProps> = ({
	isOpen,
	players,
	selectedDrill,
	selectedPlayers,
	canBack = true,
	onPlayerClicked = () => {},
	onSelectAllClicked = () => {},
	onClear = () => {},
	onBack = () => {},
	onContinue = () => {}
}) => {
	const [toggleStates, setToggleStates] = useState<ToggleStates>({
		All: true,
		[Role.GK]: false,
		[Role.DF]: false,
		[Role.MF]: false,
		[Role.FW]: false
	});
	const [visiblePlayers, setVisiblePlayers] = useState<Player[]>([]);
	const [selectedSort, setSelectedSort] = useLocalStorage<AvailableSort>(
		"METASOCCER_TRAINING_SELECTED_SORT",
		AVAILABLE_SORTS[0]
	);
	const isMobile = useIsMobile();

	useEffect(() => {
		const visiblePlayers = players.filter((player) => {
			if (toggleStates.All) {
				return true;
			}

			return toggleStates[player.role as ToggleName];
		});

		if (selectedSort === "Condition Asc.") {
			visiblePlayers.sort((a, b) => a.condition - b.condition);
		} else if (selectedSort === "Condition Desc.") {
			visiblePlayers.sort((a, b) => b.condition - a.condition);
		} else if (selectedSort === "Overall Asc.") {
			visiblePlayers.sort((a, b) => a.overall - b.overall);
		} else if (selectedSort === "Overall Desc.") {
			visiblePlayers.sort((a, b) => b.overall - a.overall);
		}

		setVisiblePlayers(() => visiblePlayers);
	}, [toggleStates, selectedSort, players]);

	const handleToggleChange = (toggleName: ToggleName) => {
		const newState = {
			All: false,
			[Role.GK]: false,
			[Role.DF]: false,
			[Role.MF]: false,
			[Role.FW]: false,
			...{ [toggleName]: !toggleStates[toggleName] }
		};

		if (!newState.All && !newState.Goalkeeper && !newState.Defender && !newState.Midfielder && !newState.Forward) {
			newState.All = true;
		}

		setToggleStates((state) => ({ ...state, ...newState }));
	};
	const handleSelectAll = () => {
		if (toggleStates.All) {
			onSelectAllClicked(undefined, selectedSort);
			return;
		}

		const selectedRole = Object.keys(toggleStates).filter((toggleKey) => toggleStates[toggleKey as ToggleName]);
		onSelectAllClicked(selectedRole[0] as Role, selectedSort);
	};

	const isPlayerSelected = (player: Player) => selectedPlayers.some((p) => p.id === player.id);

	return (
		<CssPlayersStepWrapper>
			<CssTogglebarWrapper>
				<CssTogglebar>
					<ToggleButton onChange={() => handleToggleChange("All")} selected={toggleStates["All"]}>
						All
					</ToggleButton>
					<ToggleButton onChange={() => handleToggleChange(Role.GK)} selected={toggleStates[Role.GK]}>
						Goalkeepers
					</ToggleButton>
					<ToggleButton onChange={() => handleToggleChange(Role.DF)} selected={toggleStates[Role.DF]}>
						Defenders
					</ToggleButton>
					<ToggleButton onChange={() => handleToggleChange(Role.MF)} selected={toggleStates[Role.MF]}>
						Midfielders
					</ToggleButton>
					<ToggleButton onChange={() => handleToggleChange(Role.FW)} selected={toggleStates[Role.FW]}>
						Forwards
					</ToggleButton>
				</CssTogglebar>
				{!isMobile && (
					<Row gap={"32px"} alignItems={"center"}>
						<Row gap={"8px"} alignItems={"center"}>
							<Typography variant="caption">Sort by</Typography>
							<CssSortHeaderWrapper>
								<Select
									value={selectedSort}
									options={AVAILABLE_SORTS.map((option) => {
										return { label: option, value: option };
									})}
									onChange={(selected: any) => {
										setSelectedSort(selected);
									}}
								/>
							</CssSortHeaderWrapper>
						</Row>
						<Button appearance="secondary" size="sm" label="Select All" onClick={handleSelectAll} />
					</Row>
				)}
			</CssTogglebarWrapper>
			{isMobile && (
				<CssSelectAllWrapper>
					<CssSortHeaderWrapper>
						<Select
							value={selectedSort}
							options={AVAILABLE_SORTS.map((option) => {
								return { label: option, value: option };
							})}
							onChange={(selected: any) => {
								setSelectedSort(selected);
							}}
						/>
					</CssSortHeaderWrapper>
					<Button
						appearance="secondary"
						size="sm"
						label="Select All"
						onClick={handleSelectAll}
						style={{ width: "50%" }}
					/>
				</CssSelectAllWrapper>
			)}
			<FlexScrollingContainer>
				{visiblePlayers.length > 0 && (
					<Col gap={"8px"}>
						<CssListWrapper>
							{visiblePlayers.map((player, idx) => {
								return (
									<CssPlayerCardWithHover
										key={idx}
										player={player}
										selected={isPlayerSelected(player)}
										onClick={() => onPlayerClicked(player)}
									/>
								);
							})}
						</CssListWrapper>
					</Col>
				)}
				{visiblePlayers.length === 0 && (
					<CssNoPlayersWrapper>
						<PlayerIcon size="md" style={{ width: "42px", height: "42px" }} />
						<Typography variant="caption" light>
							There are no players in this team
						</Typography>
					</CssNoPlayersWrapper>
				)}
			</FlexScrollingContainer>
			{selectedPlayers.length > 0 && (
				<CssSelectionInfo>
					<Typography variant="body1">
						{selectedPlayers.length}/{selectedDrill.rules.maxPlayers} Players selected
					</Typography>
					<Button appearance="text" label="Clear" size="sm" onClick={onClear} />
				</CssSelectionInfo>
			)}
			<CssFooterActions>
				{canBack && <Button appearance="secondary" label="Back" onClick={onBack} />}
				<Button
					appearance="primary"
					label="Continue"
					disabled={selectedPlayers.length === 0}
					onClick={onContinue}
				/>
			</CssFooterActions>
		</CssPlayersStepWrapper>
	);
};
