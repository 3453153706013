import { configService } from "@/dependencies";
import { CLUB_ROUTES } from "@/modules/club/club.routes";
import { MARKET_ROUTES } from "@/modules/market";
import { MATCH_ROUTES } from "@/modules/match/match.routes";
import { ScheduledMatchPreview } from "@/modules/pvp/views/CustomTournament/components/MatchesCard/components/ScheduledMatchPreview";
import { Alert } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { useToast } from "@/modules/shared/hooks";
import { compact, defineIf, getImgUrl } from "@/modules/shared/utils";
import { FriendlyMatch } from "@/modules/squad/views/SquadPage/components/FriendlyMatch";
import { TOURNAMENT_ROUTES } from "@/modules/tournament/tournament.routes";
import { TRAINING_ROUTES } from "@/modules/training/training.routes";
import { TrainingHistoryCard } from "@/modules/training/views/Root/components";
import { Button, Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag, ScheduledMatchPreviewInfo, TrainingHistory } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { formatDate } from "date-fns";
import { ReactElement, useCallback } from "react";
import { useNavigate } from "react-router-dom";

type BuildActionArgs = { closeParentModal?: () => void };

type TaskKey = string;

export interface AirdropTask {
	key: TaskKey;
	title: string;
	enableAfter?: TaskKey;
	disabled?: boolean;
	icon: string;
	points?: number;
	maxPoints?: number;
	buildAction: ({ closeParentModal }: BuildActionArgs) => ReactElement;
	buildInfo: () => ReactElement;
	buildPointsInfo?: () => ReactElement;
}

export function useTasks(
	airdropTasks: Record<
		TaskKey,
		Array<{ key: string; completed: boolean; claimed: boolean; data?: any; points: number }>
	>
) {
	const isAirdropGameplayPhase2Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase2);
	const isAirdropGameplayPhase3Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase3);
	const isAirdropGameplayPhase4Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase4);
	const isAirdropGameplayPhase5Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase5);
	const isAirdropGameplayPhase6Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase6);

	const { account, selectedTeam } = useGlobalState();

	const navigate = useNavigate();

	const { showToast } = useToast();

	const copyToClipboard = useCallback((text: string, successMessage: string) => {
		navigator.clipboard.writeText(text);
		showToast(successMessage);
	}, []);

	const loginWithDiscord = () => {
		const clientId = "1229091313333309480";
		const redirectUri = `${configService.get().baseURL}/account/discord/oauth-callback`;

		const discordLoginUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
			redirectUri
		)}&response_type=code&scope=identify%20email`;

		window.open(discordLoginUrl, "_blank");
	};

	return compact([
		defineIf(isAirdropGameplayPhase5Enabled, () => ({
			key: "discord",
			title: "Connect Discord account",
			icon: getImgUrl("https://assets.metasoccer.com/social/discord.png?v=3", {
				width: 128
			}),
			maxPoints: 0,
			points: 0,
			buildAction: ({ closeParentModal }) => (
				<Button
					label="Login with Discord"
					onClick={() => {
						loginWithDiscord();
						closeParentModal?.();
					}}
				/>
			),
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">
						Connect your Discord account to get your Starter Box and 10,000 MSS.
					</Typography>
				</Col>
			),
			buildPointsInfo: () => (
				<div style={{ padding: "12px 24px" }}>
					{airdropTasks["discord"] ? (
						<Typography variant="body2">
							{airdropTasks["discord"][0]?.data
								? `Discord ID: ${airdropTasks["discord"][0]?.data}`
								: "You haven't connected your Discord account."}
						</Typography>
					) : account?.discord_account ? (
						<Typography variant="body2">
							Your Discord account is now connected, and your ranking will be adjusted shortly.
						</Typography>
					) : undefined}
				</div>
			)
		})),
		defineIf(isAirdropGameplayPhase6Enabled, () => ({
			key: "tournament",
			title: "Community Tournaments",
			icon: getImgUrl("https://assets.metasoccer.com/competitions/CommunityTournament.png?v=3", {
				width: 128
			}),
			points: airdropTasks["tournament"]?.[0]?.points ?? 0,
			buildAction: () => (
				<Button label="Go to Tournaments" onClick={() => navigate(TOURNAMENT_ROUTES.ROOT.buildPath())} />
			),
			buildInfo: () => (
				<Col gap={32}>
					<Col gap={8}>
						<Typography variant="body2">
							Each tournament participation awards points according to your final position. The points are
							calculated using this formula:
						</Typography>
						<Typography variant="body2" textAlign="center">
							(TPP / 100) * M * P
						</Typography>
						<Typography variant="body2">Where:</Typography>
						<ul style={{ paddingLeft: 32 }}>
							<li>
								<Typography variant="body2">TPP = Tournament Prize Pool</Typography>
							</li>
							<li>
								<Typography variant="body2">M = Max Number of Scheduled Matches per Team</Typography>
							</li>
							<li>
								<Typography variant="body2">P = Percentage Allocated to Your Position</Typography>
							</li>
						</ul>
					</Col>
					<Alert variant="info">
						<Col gap={8}>
							<Typography variant="body2">Example:</Typography>
							<Typography variant="body2">
								Let's consider a 6-team single round-robin league tournament with a total prize pool of
								MSS 6000 (entry fee of MSS 1000). Each team plays 5 matches.
							</Typography>
							<Typography variant="body2">Assuming the percentage allocations are:</Typography>
							<ul style={{ paddingLeft: 32 }}>
								<li>
									<Typography variant="body2">1st Place: 40%</Typography>
								</li>
								<li>
									<Typography variant="body2">2nd Place: 25%</Typography>
								</li>
								<li>
									<Typography variant="body2">3rd Place: 15%</Typography>
								</li>
								<li>
									<Typography variant="body2">4th Place: 10%</Typography>
								</li>
								<li>
									<Typography variant="body2">5th Place: 6%</Typography>
								</li>
								<li>
									<Typography variant="body2">6th Place: 4%</Typography>
								</li>
							</ul>
							<Typography variant="body2">
								The points for each position can be calculated as follows:
							</Typography>
							<ul style={{ paddingLeft: 32 }}>
								<li>
									<Typography variant="body2">
										(6000 / 100) * 5 * 0.4 = 300 * 0.4 = 120 points
									</Typography>
								</li>
								<li>
									<Typography variant="body2">
										(6000 / 100) * 5 * 0.25 = 300 * 0.25 = 75 points
									</Typography>
								</li>
								<li>
									<Typography variant="body2">
										(6000 / 100) * 5 * 0.15 = 300 * 0.15 = 45 points
									</Typography>
								</li>
								<li>
									<Typography variant="body2">
										(6000 / 100) * 5 * 0.10 = 300 * 0.10 = 30 points
									</Typography>
								</li>
								<li>
									<Typography variant="body2">
										(6000 / 100) * 5 * 0.06 = 300 * 0.06 = 18 points
									</Typography>
								</li>
								<li>
									<Typography variant="body2">
										(6000 / 100) * 5 * 0.04 = 300 * 0.04 = 12 points
									</Typography>
								</li>
							</ul>
						</Col>
					</Alert>
				</Col>
			),
			buildPointsInfo: () => (
				<div style={{ padding: "12px 24px" }}>
					<Typography variant="body2">
						{airdropTasks["tournament"][0].data
							? "You haven't completed any tournament."
							: `Points: ${airdropTasks["tournament"][0].data}`}
					</Typography>
				</div>
			)
		})),
		{
			key: "zealy",
			title: "Complete Zealy Quests",
			icon: getImgUrl("https://assets.metasoccer.com/social/zealy.png?v=3", {
				width: 128
			}),
			buildAction: ({ closeParentModal }) => (
				<Button
					label="Go to Zealy"
					onClick={() => {
						window.open("https://zealy.io/cw/metasoccer", "_blank");
						closeParentModal?.();
					}}
				/>
			),
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">
						Connect your Zealy account and complete tasks to earn experience. 5% of your Zealy XP will be
						converted to airdrop points.
					</Typography>
					<Typography variant="body2">
						To connect your Zealy account enter your MetaSoccer Account ID as your Zealy Skale Address:
					</Typography>
					<Typography variant="body2">
						<img src={"https://assets.metasoccer.com/airdrop/zealy.gif"} width="100%" />
					</Typography>
				</Col>
			),
			buildPointsInfo: () => (
				<div style={{ padding: "12px 24px" }}>
					<Typography variant="body2">
						{airdropTasks["referral"][0].data
							? "You haven't connected your Zealy account."
							: `Zealy XP: ${airdropTasks["referral"][0].data}`}
					</Typography>
				</div>
			)
		},
		{
			key: "referral",
			title: "Refer friends",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/referral.png?v=3", {
				width: 128
			}),
			maxPoints: 1040,
			buildAction: () => (
				<Button
					label="Copy referrer link"
					onClick={() =>
						copyToClipboard(
							`https://airdrop.metasoccer.com?ref=${airdropTasks["referral"][0].data.code}`,
							"Link copied"
						)
					}
				/>
			),
			buildInfo: () => (
				<Col gap={32}>
					<Col gap={8}>
						<Typography variant="body2">
							Share this code with your friends and invite them to play:
						</Typography>
						<Col alignItems="center">
							<Typography variant="h4">{airdropTasks["referral"][0].data.code}</Typography>
						</Col>
					</Col>
					<Col gap={8}>
						<Typography variant="body2">
							The more friends you invite, the more points you accumulate:
						</Typography>
						<ul style={{ paddingLeft: 32 }}>
							<li>
								<Typography variant="body2">1 friend: 40 points</Typography>
							</li>
							<li>
								<Typography variant="body2">5 friends: 60 points</Typography>
							</li>
							<li>
								<Typography variant="body2">10 friends: 100 points</Typography>
							</li>
							<li>
								<Typography variant="body2">25 friends: 160 points</Typography>
							</li>
							<li>
								<Typography variant="body2">50 friends: 260 points</Typography>
							</li>
							<li>
								<Typography variant="body2">+100 friends: 420 points</Typography>
							</li>
						</ul>
						<Typography variant="body2">
							** Friends are only counted as a referral after they have earned 100 points.
						</Typography>
					</Col>
				</Col>
			),
			buildPointsInfo: () => (
				<div style={{ padding: "12px 24px" }}>
					<Typography variant="body2">
						{airdropTasks["referral"][0].data.referrals
							? "You don't have any referrals yet."
							: `Referrals: ${airdropTasks["referral"][0].data.referrals
									.map((referral: any) => referral.address)
									.join(", ")}`}
					</Typography>
				</div>
			)
		},
		{
			key: "win-pve",
			title: "Win a PvE match",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/tournament.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase4Enabled,
			maxPoints: 2720,
			points: airdropTasks["win-pve"]?.[0]?.points ?? 0,
			buildAction: () => <FriendlyMatch label="Play PvE Match" />,
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">
						Win friendly matches against MetaSoccer's PvE teams. Higher PvE ratings increase the points
						earned.
					</Typography>
					<ul style={{ paddingLeft: 32 }}>
						<li>
							<Typography variant="body2">Starter: 100 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Amateur: 160 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Begineer: 260 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Semi Pro: 420 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Pro: 680 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Legend: 1100 points</Typography>
						</li>
					</ul>
					<Typography variant="body2">
						Points are awarded based on the first match won by each PvE difficulty. For instance, the first
						match you win to PVE #1 (Starter) earns you 100 points. The first match you win to PVE #2
						(Amateur) earns you 160 points, and so on.
					</Typography>
				</Col>
			),
			buildPointsInfo: () => (
				<Col>
					{compact(
						Object.values(airdropTasks["win-pve"][0].data as Record<string, ScheduledMatchPreviewInfo>)
					).map((match: ScheduledMatchPreviewInfo) => (
						<ScheduledMatchPreview
							isMatchResultLayout
							scheduledMatchPreviewInfo={match}
							selectedTeamId={selectedTeam?.id}
							onClick={() => navigate(MATCH_ROUTES.ROOT.buildPath(match.matchId))}
						/>
					))}
				</Col>
			)
		},
		{
			key: "scouting",
			title: "Scout a youth player",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/scouting.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase2Enabled,
			maxPoints: 640,
			points: airdropTasks["scouting"]?.[0]?.points ?? 0,
			buildAction: () => (
				<Button label="Go to Scouts" onClick={() => navigate(CLUB_ROUTES.ROOT.buildPath("scouts"))} />
			),
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">
						Use a scout to discover new youth players. The higher the level of your scout, the more points
						you earn.
					</Typography>
					<ul style={{ paddingLeft: 32 }}>
						<li>
							<Typography variant="body2">Level 1: 20 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Level 2: 40 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Level 3: 60 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Level 4: 100 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Level 5: 160 points</Typography>
						</li>
						<li>
							<Typography variant="body2">Level 6: 260 points</Typography>
						</li>
					</ul>
					<Typography variant="body2">
						Points are awarded after the first scouting session you perform with a scout of a certain level.
						For example, the first scouting session with a level 1 scout earns you 20 points. The first
						scouting session with a level 2 scout earns you 40 points, and so on.
					</Typography>
				</Col>
			),
			buildPointsInfo: () => (
				<div style={{ padding: "12px 24px" }}>
					<Col gap={8}>
						{(airdropTasks["scouting"][0].data as any[]).map((scouting) => (
							<Row key={scouting.id} gap={4}>
								<Typography variant="body2" light>
									{formatDate(scouting.startingTsMillis, "Pp")}
								</Typography>
								<Typography variant="body2" light>
									-
								</Typography>
								<Typography variant="body2">
									{scouting.scoutAttributes._name} {scouting.scoutAttributes._lastName} #
									{scouting.scoutId}
								</Typography>
							</Row>
						))}
					</Col>
				</div>
			)
		},
		{
			key: "score-diff",
			title: "Score difference",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/goals.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase4Enabled,
			maxPoints: 2720,
			points: airdropTasks["score-diff"]?.[0]?.points ?? 0,
			buildAction: () => <FriendlyMatch label="Play PvE Match" />,
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">Earn points based on the goal difference in a single match.</Typography>
					<ul style={{ paddingLeft: 32 }}>
						<li>
							<Typography variant="body2">1 goal difference: 100 points</Typography>
						</li>
						<li>
							<Typography variant="body2">2 goals difference: 160 points</Typography>
						</li>
						<li>
							<Typography variant="body2">3 goals difference: 260 points</Typography>
						</li>
						<li>
							<Typography variant="body2">5 goals difference: 420 points</Typography>
						</li>
						<li>
							<Typography variant="body2">8 goals difference: 680 points</Typography>
						</li>
						<li>
							<Typography variant="body2">13 goals difference: 1100 points</Typography>
						</li>
					</ul>
					<Typography variant="body2">
						Points are awarded based on the first match won by each goal difference. For instance, the first
						match you win by a 1-goal difference earns you 100 points. The first match you win by a 2-goal
						difference earns you 160 points, and so on.
					</Typography>
				</Col>
			),
			buildPointsInfo: () => {
				return (
					<Col>
						{compact(
							Object.values(
								airdropTasks["score-diff"][0].data as Record<string, ScheduledMatchPreviewInfo>
							)
						).map((match: ScheduledMatchPreviewInfo) => (
							<ScheduledMatchPreview
								isMatchResultLayout
								scheduledMatchPreviewInfo={match}
								selectedTeamId={selectedTeam?.id}
								onClick={() => navigate(MATCH_ROUTES.ROOT.buildPath(match.matchId))}
							/>
						))}
					</Col>
				);
			}
		},
		{
			key: "clean-sheet",
			title: "Keep a clean sheet",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/clean-sheet.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase4Enabled,
			maxPoints: 620,
			points: airdropTasks["clean-sheet"]?.[0]?.points ?? 0,
			buildAction: () => <FriendlyMatch label="Play PvE Match" />,
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">
						Keep a clean sheet (no goals conceded) in matches. Consecutive clean sheets increase the points
						earned:
					</Typography>
					<ul style={{ paddingLeft: 32 }}>
						<li>
							<Typography variant="body2">1 clean sheet: 40 points</Typography>
						</li>
						<li>
							<Typography variant="body2">2 consecutive clean sheets: 60 points</Typography>
						</li>
						<li>
							<Typography variant="body2">3 consecutive clean sheets: 100 points</Typography>
						</li>
						<li>
							<Typography variant="body2">4 consecutive clean sheets: 160 points</Typography>
						</li>
						<li>
							<Typography variant="body2">5 consecutive clean sheets: 260 points</Typography>
						</li>
					</ul>
				</Col>
			),
			buildPointsInfo: () => (
				<Col>
					{airdropTasks["clean-sheet"][0].data.map((match: ScheduledMatchPreviewInfo) => (
						<ScheduledMatchPreview
							isMatchResultLayout
							scheduledMatchPreviewInfo={match}
							selectedTeamId={selectedTeam?.id}
							onClick={() => navigate(MATCH_ROUTES.ROOT.buildPath(match.matchId))}
						/>
					))}
				</Col>
			)
		},
		{
			key: "training",
			title: "Train player",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/training.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase2Enabled,
			maxPoints: 380,
			points: airdropTasks["training"]?.[0]?.points ?? 0,
			buildAction: () => <Button label="Go to Training" onClick={() => navigate(TRAINING_ROUTES.ROOT)} />,
			buildInfo: () => (
				<Col gap={8}>
					<Typography variant="body2">
						Train players to improve their skills. Consecutive days of training increase the points earned.
					</Typography>
					<ul style={{ paddingLeft: 32 }}>
						<li>
							<Typography variant="body2">1 training session: 20 points</Typography>
						</li>
						<li>
							<Typography variant="body2">2 consecutive days of training: 40 points</Typography>
						</li>
						<li>
							<Typography variant="body2">3 consecutive days of training: 60 points</Typography>
						</li>
						<li>
							<Typography variant="body2">4 consecutive days of training: 100 points</Typography>
						</li>
						<li>
							<Typography variant="body2">5 consecutive days of training: 160 points</Typography>
						</li>
					</ul>
				</Col>
			),
			buildPointsInfo: () => (
				<Col>
					{airdropTasks["training"][0].data.map((th: TrainingHistory) => (
						<TrainingHistoryCard
							key={th.timestamp}
							variant="small"
							conditionCost={th.aggregateConditionCost}
							cost={th.trainingTokenCost}
							drillCount={th.drillHistory.length}
							playerCount={1}
							timestamp={th.timestamp}
							totalAttack={th.totalAttackProgression}
							totalDefense={th.totalDefenseProgression}
							totalMentalAndPhysical={th.totalMentalAndPhysicalProgression}
						/>
					))}
				</Col>
			)
		},
		{
			key: "tactics",
			title: "Experiment with different formations",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/tactics.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase4Enabled,
			maxPoints: 200,
			points: airdropTasks["tactics"]?.[0]?.points ?? 0,
			buildAction: () => <FriendlyMatch label="Play PvE Match" />,
			buildInfo: () => (
				<Typography variant="body2">
					Earn 20 points every time you start a match with a different formation.
				</Typography>
			),
			buildPointsInfo: () => (
				<Col>
					{airdropTasks["tactics"][0].data.map(({ match }: { match: ScheduledMatchPreviewInfo }) => (
						<ScheduledMatchPreview
							isMatchResultLayout
							scheduledMatchPreviewInfo={match}
							selectedTeamId={selectedTeam?.id}
							onClick={() => navigate(MATCH_ROUTES.ROOT.buildPath(match.matchId))}
						/>
					))}
				</Col>
			)
		},
		{
			key: "market-offer",
			title: "Use the market",
			icon: getImgUrl("https://assets.metasoccer.com/airdrop/market.png?v=3", {
				width: 128
			}),
			disabled: !isAirdropGameplayPhase3Enabled,
			maxPoints: 200,
			points: airdropTasks["market-offer"]?.[0]?.points ?? 0,
			buildAction: () => (
				<Button label="Go to Market" onClick={() => navigate(MARKET_ROUTES.ROOT.buildPath("players"))} />
			),
			buildInfo: () => (
				<Typography variant="body2">
					Engage with the in-game market by selling players and/or scouts. Each offer earns you 20 points up
					to a maximum of 200.
				</Typography>
			),
			buildPointsInfo: () => (
				<div style={{ padding: "12px 24px" }}>
					<Typography variant="body2">{`Offers created: ${airdropTasks["market-offer"][0].data}`}</Typography>
				</div>
			)
		}
		// {
		// 	key: "bench",
		// 	title: "Perform a substitution",
		// 	icon: getImgUrl("https://assets.metasoccer.com/airdrop/substitution.png?v=3", {
		// 		width: 128
		// 	}),
		// 	points: 100,
		// 	buildAction: () => <FriendlyMatch label="Play PvE Match" />,
		// 	buildInfo: () => <Typography variant="body2">Earn points by utilizing bench players in matches.</Typography>
		// }
	] as Array<AirdropTask | undefined>);
}
