import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssTeam = styled("div")<{ isAway: boolean }>(
	({ theme, isAway }) => css`
		width: 143px;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: ${isAway ? "row-reverse" : "row"};
		}
	`
);

export const CssTeamInfo = styled("div")<{ isAway: boolean }>(
	({ theme, isAway = false }) => css`
		display: flex;
		flex-direction: column;
		margin: 4px 0 0 0;
		align-items: center;

		${theme.breakpoints.up("desktopMin")} {
			margin: ${isAway ? "0 0 0 12px" : "0 12px 0 0"};
			align-items: ${isAway ? "flex-start" : "flex-end"};
		}
	`
);

export const CssOverallTypography = styled(Typography)<{ isAway: boolean }>(
	({ theme, isAway }) => css`
		margin-right: ${isAway ? "4px" : "12px"};
		color: ${theme.palette.greyscale.gray};
	`
);

export const CssTeamOverall = styled("div")(
	() => css`
		display: flex;
		flex-direction: row;
		margin-top: 4px;
	`
);

export const CssTeamName = styled(Typography)(() => css``);

export const CssAwayOverall = styled("div")(
	({}) => css`
		border: 1px solid #8d8d8d;
		border-radius: 100px;
		padding: 0 6px;
		width: 43px;
		height: 20px;
		display: flex;
		align-items: center;
	`
);

export const CssAwayOverallTypo = styled(Typography)(
	() => css`
		font-size: 10px;
	`
);
