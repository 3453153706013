import { Container } from "@/modules/shared/components";
import { Modal } from "@/modules/shared/components/Modal";
import { Button, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

export interface SuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const SuccessModal = memo<SuccessModalProps>(({ isOpen, onClose }) => {
	return (
		<Modal
			isOpen={isOpen}
			mainAction={<Button variant="filled" label="Let's go!" onClick={onClose} />}
			title="Join tournament"
			onClose={onClose}>
			<Container>
				<Typography variant="body2" textAlign="center">
					You have joined the tournament successfully.
				</Typography>
				<Typography variant="body2" light textAlign="center">
					Next edition will start soon. Enjoy it and good luck.
				</Typography>
			</Container>
		</Modal>
	);
});
