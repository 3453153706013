import { useIsMobile } from "@/layouts/context";
import { GetYourSquad, Loading } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { defineIf } from "@/modules/shared/utils";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { memo } from "react";
import { useHomeState } from "../../context";
import { useAirdrop } from "../../hooks/useAirdrop";
import { AirdropGetStarted, AirdropInbox, AirdropInventory, AirdropTasks } from "./components";
import { AirdropRanking } from "./components/AirdropRanking";
import { CommunityTournaments } from "./components/CommunityTournaments";
import { HomeLayout } from "./components/HomeLayout";
import { MSL } from "./components/MSL";
import { NextMatch } from "./components/NextMatch";
import { Squad } from "./components/Squad";
import { TournamentActivity } from "./components/TournamentActivity";
import { ContentWithDividersStyled } from "./home.page.styles";

export const HomePage = memo(() => {
	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;

	const { address, isConnected, isDisconnected, selectedTeam } = useGlobalState();

	const {
		hasAirdropStarted,
		hasOpenedStarterBox,
		hasPass,
		hasStarterBox,
		isLoading: isAirdropLoading
	} = useAirdrop();
	const { isLoading: isHomeLoading } = useHomeState();

	const isLoading = isAirdropLoading || isHomeLoading;
	const isMobile = useIsMobile();

	if (!((isConnected && selectedTeam) || isDisconnected) || isLoading) {
		return <Loading />;
	}

	if (isPlayToAirdropEnabled) {
		if (!address) {
			return <GetYourSquad />;
		} else {
			return (
				<HomeLayout>
					<ContentWithDividersStyled>
						<AirdropInbox />
						<AirdropGetStarted />
						<AirdropTasks />
					</ContentWithDividersStyled>
					{hasAirdropStarted && hasPass && hasStarterBox && hasOpenedStarterBox ? (
						<Squad />
					) : (
						<AirdropInventory />
					)}
					{defineIf(hasAirdropStarted && hasPass, () => (
						<AirdropRanking />
					))}
				</HomeLayout>
			);
		}
	}

	return (
		<HomeLayout>
			<ContentWithDividersStyled>
				<NextMatch />
				<MSL />
				<CommunityTournaments />
			</ContentWithDividersStyled>
			<Squad />
			{!isMobile && <TournamentActivity />}
		</HomeLayout>
	);
});
