import { Sort } from "@/modules/shared/helpers/sorting-comparators/Sort";

type ThingWithOverall = any & { overall: number };

export function makePlayersByCurrentOverallComparator(
	sort: Sort = Sort.ASC,
	selectorA: (a: any) => number = (a) => a.overall,
	selectorB: (b: any) => number = (b) => b.overall
): (a: ThingWithOverall, b: ThingWithOverall) => number {
	return (a: ThingWithOverall, b: ThingWithOverall): number => {
		if (sort === Sort.ASC) {
			return selectorA(a) - selectorB(b);
		}

		return selectorB(b) - selectorA(a);
	};
}
