import { Divider as MsDivider, useTheme } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

interface DividerProps {
	variant?: "horizontal" | "vertical";
	marginX?: number;
	marginY?: number;
}

export const Divider = memo(({ variant = "horizontal", marginX = 0, marginY = 0 }: DividerProps) => {
	const theme = useTheme();
	if (variant === "horizontal") {
		return <MsDivider color="greyscale.gondola" style={{ margin: `${marginY}px ${marginX}px` }} />;
	} else {
		return (
			<div
				style={{
					borderLeft: `1px solid ${theme.palette.greyscale.gondola}`,
					margin: `${marginY}px ${marginX}px`
				}}
			/>
		);
	}
});
