import { HttpClient, IdType, LineupWithSkill, MetaSoccerId, Response } from "@metasoccer/metasoccer-types";

export class LineupWithSkillService {
	constructor(private readonly httpService: HttpClient) {}

	async getByOwnerAndTeamId(owner?: string, selectedTeamId?: MetaSoccerId<IdType.Team>): Promise<LineupWithSkill> {
		let response: Response<LineupWithSkill>;
		try {
			response = await this.httpService.get<LineupWithSkill>(
				`/teams/lineup-with-skill/${owner}/${selectedTeamId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getByManagerAndTeamId(
		manager?: string,
		selectedTeamId?: MetaSoccerId<IdType.Team>
	): Promise<LineupWithSkill> {
		let response: Response<LineupWithSkill>;
		try {
			response = await this.httpService.get<LineupWithSkill>(
				`/teams/lineup-with-skill/${manager}/${selectedTeamId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
