import React from "react";
import { MSLContext } from "./MSLContext";

const useMSLState = () => {
	const context = React.useContext(MSLContext);
	if (context === undefined) {
		throw new Error("useMSLState must be used within a MSLContextProvider");
	}
	return context;
};

export { useMSLState };
