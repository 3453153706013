import React, { FC } from "react";
import { Chip, Typography } from "@metasoccer/metasoccer-ds";
import { CssDrillChipsRow, CssDrillChipsWrapper, CssDrillContent } from "./DrillContent.styled";
import { useTheme } from "styled-components";

interface DrillContentProps {
	name: string;
	primaryAbilities: string[];
	secondaryAbilities: string[];
}

export const DrillContent: FC<DrillContentProps> = ({ name, primaryAbilities, secondaryAbilities }) => {
	const theme = useTheme();
	const customColor = theme.palette.backgrounds.tundora;

	return (
		<CssDrillContent>
			<Typography variant="h6">{name}</Typography>
			<CssDrillChipsWrapper>
				<CssDrillChipsRow>
					{primaryAbilities.map((value) => (
						<Chip key={value} customColor={customColor} label={value} />
					))}
				</CssDrillChipsRow>
				<CssDrillChipsRow>
					{secondaryAbilities.map((value) => (
						<Chip key={value} customColor={theme.palette.backgrounds.codGray} label={value} />
					))}
				</CssDrillChipsRow>
			</CssDrillChipsWrapper>
		</CssDrillContent>
	);
};
