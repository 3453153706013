import styled from "styled-components";

export const WrapperStyled = styled.div<{ color: string; border: string }>`
	display: flex;
	align-items: center;
	background-color: ${({ color }) => color};
	border-left: 4px solid ${({ border }) => border};
	justify-content: space-between;
	padding: 12px 16px;
	width: 100%;
`;
