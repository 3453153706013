type Comparator<T> = (item: T) => any;

export function sortBy<T>(array: T[], callback: Comparator<T>, descending = false): T[] {
	return [...array].sort((a, b) => {
		const keyA = callback(a);
		const keyB = callback(b);

		if (keyA < keyB) {
			return descending ? 1 : -1;
		}
		if (keyA > keyB) {
			return descending ? -1 : 1;
		}
		return 0;
	});
}
