import { FC } from "react";
import { Button, Typography, Modal } from "@metasoccer/metasoccer-ds";
import { CssContent, CssCost, CssCostLabel } from "./StartTrainingModal.styled";
import { TrainingToken } from "@/modules/shared/components/tokens";

export interface StartTrainingModalProps {
	isOpen: boolean;
	cost: number;
	onClose?: () => void;
	onContinue?: () => void;
}

export const StartTrainingModal: FC<StartTrainingModalProps> = ({
	isOpen,
	cost,
	onClose = () => {},
	onContinue = () => {}
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Start training"
			footerContent={
				<>
					<Button appearance="secondary" label="Not now" fullWidth onClick={onClose} />
					<Button appearance="primary" label="Yes, continue" fullWidth onClick={onContinue} />
				</>
			}>
			<CssContent>
				<Typography variant="body1" light>
					The training has improved drills. It will have an additional cost. Do you agree with that?
				</Typography>
			</CssContent>
			<CssCost>
				<CssCostLabel>
					<Typography variant="body1" light>
						Total cost
					</Typography>
				</CssCostLabel>
				<TrainingToken quantity={cost} />
			</CssCost>
		</Modal>
	);
};
