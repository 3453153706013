import { getCoveringBySpecificRole } from "@/modules/player/helpers/getCoveringBySpecificRole";
import { Player, PlayerSpecificRole } from "@metasoccer/metasoccer-types";

export default function getBestSpecificRoleForPlayer(specificRoles: PlayerSpecificRole[], player: Player) {
	// The specific role of the player is one of the specific roles in the array
	const specificRoleIndex = specificRoles.indexOf(player.specificRole);
	if (specificRoleIndex >= 0) {
		return specificRoles[specificRoleIndex];
	}

	// The specific role of the player has the same covering than one of the specific roles in the arrray
	const playerCovering = getCoveringBySpecificRole(player.specificRole);
	specificRoles.forEach((specificRole) => {
		if (getCoveringBySpecificRole(specificRole) === playerCovering) {
			return specificRole;
		}
	});

	// Else
	return specificRoles[0];
}
