import styled, { css } from "styled-components";

interface CssPrizePoolValuesItemWrapperProps {
	borderColor: string;
}

export const CssPrizePoolValuesItemWrapper = styled.div<CssPrizePoolValuesItemWrapperProps>(
	({ theme, borderColor }) => css`
		min-height: 44px;
		display: flex;
		justify-content: space-between;
		border-left: 4px solid ${borderColor};
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		align-items: center;
		padding-left: 12px;
	`
);

export const CssContentWrapper = styled.div`
	display: flex;
	min-width: 64px;
`;
