import {
	HttpClient,
	LockedPlayer,
	Player,
	PlayerShirtNumberDict,
	Response,
	TournamentCardFrontend
} from "@metasoccer/metasoccer-types";

export class PlayerService {
	constructor(private readonly httpService: HttpClient) {}

	async getPlayersByOwner(owner: string): Promise<Player[]> {
		let response: Response<{ players: Player[] }>;
		try {
			response = await this.httpService.get<{ players: Player[] }>(`/v2/players/owned/${owner}`);
		} catch (e) {
			throw e;
		}

		return response.data.players;
	}

	async getPlayersByTeamId(teamId: string): Promise<Player[]> {
		let response: Response<{ players: Player[] }>;
		try {
			response = await this.httpService.get<{ players: Player[] }>(`/v2/players/team/${teamId}`);
		} catch (e) {
			throw e;
		}

		return response.data.players;
	}

	async getLockedPlayersByTeamId(teamId: string): Promise<LockedPlayer[]> {
		let response: Response<{ lockedPlayers: LockedPlayer[] }>;
		try {
			response = await this.httpService.get<{ lockedPlayers: LockedPlayer[] }>(
				`v2/players/team/${teamId}/locked`
			);
		} catch (e) {
			throw e;
		}

		return response.data.lockedPlayers;
	}

	async getPlayerCardsByTeamId(teamId: string): Promise<TournamentCardFrontend[]> {
		let response: Response<{ cards: TournamentCardFrontend[] }>;
		try {
			response = await this.httpService.get<{ cards: TournamentCardFrontend[] }>(
				`v2/players/team/${teamId}/cards`
			);
		} catch (e) {
			throw e;
		}

		return response.data.cards;
	}

	async getPlayersWithShirtNumbersByTeamId(
		teamId: string
	): Promise<{ players: Player[]; shirtNumbers?: PlayerShirtNumberDict }> {
		let response: Response<{ players: Player[]; shirtNumbers?: PlayerShirtNumberDict }>;
		try {
			response = await this.httpService.get<{ players: Player[] }>(`/v2/players/team/${teamId}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getPlayersByIds(playerIds: number[]): Promise<Player[]> {
		let response: Response<{ players: Player[] }>;
		try {
			response = await this.httpService.post<{ playerIds: number[] }, { players: Player[] }>(`/v2/players`, {
				playerIds
			});
		} catch (e) {
			throw e;
		}

		return response.data.players;
	}
}
