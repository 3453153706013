import { Outlet, Route, Routes } from "react-router-dom";
import { MSLContextProvider } from "./context";
import { MSLPage } from "./views";

const MSLRouter = () => {
	return (
		<>
			<Routes>
				<Route
					path=":groupId"
					element={
						<MSLContextProvider>
							<MSLPage />
						</MSLContextProvider>
					}
				/>
			</Routes>
			<Outlet />
		</>
	);
};

export default MSLRouter;
