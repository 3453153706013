import { useIsMobile } from "@/layouts/context";
import { TournamentGroupInfoDTO } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { CssContainer } from "./Standings.styles";
import { StandingDesktop } from "./components/StandingDesktop";
import { StandingMobile } from "./components/StandingMobile";

interface StandingsProps {
	group: TournamentGroupInfoDTO;
}

export const Standings = memo<StandingsProps>(({ group }) => {
	const isMobile = useIsMobile();

	return (
		<CssContainer>
			{isMobile ? (
				<StandingMobile rankedTeams={group.standings.rankedTeams} />
			) : (
				<StandingDesktop rankedTeams={group.standings.rankedTeams} />
			)}
		</CssContainer>
	);
});
