import styled from "styled-components";

export const CssLineupNoticesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 24px;
	border-top: 1px solid rgba(255, 255, 255, 0.15);
`;

export const CssNoticesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
`;
export const CssNoticeWrapper = styled.div`
	display: flex;
	gap: 10px;
`;
