import React, { FC } from "react";
import { CssSpinner } from "./Spinner.styled";

interface SpinnerProps {
	position?: "absolute" | "relative";
	size?: "sm" | "md";
}

export const Spinner: FC<SpinnerProps> = ({ position = "absolute", size = "md" }) => (
	<CssSpinner viewBox="0 0 50 50" position={position} size={size}>
		<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
	</CssSpinner>
);
