import { ClubOverview, HttpClient, IdType, MetaSoccerId, Response, Team } from "@metasoccer/metasoccer-types";

export class ClubService {
	constructor(private readonly httpService: HttpClient) {}

	async getClubByOwner(ownerAddress: string): Promise<{ id: MetaSoccerId<IdType.Club> }> {
		let response: Response<{ id: MetaSoccerId<IdType.Club> }>;
		try {
			response = await this.httpService.get<{ id: MetaSoccerId<IdType.Club> }>(`/clubs/owned/${ownerAddress}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getClubOverviewByOwner(ownerAddress: string): Promise<ClubOverview> {
		let response: Response<ClubOverview>;
		try {
			response = await this.httpService.get<ClubOverview>(`/clubs/owned/${ownerAddress}/overview`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async updateTeamLogoAndColor(clubId: MetaSoccerId<IdType.Club>, name: string, color: string): Promise<Team> {
		let response: Response<Team>;
		try {
			response = await this.httpService.post<{ clubId: MetaSoccerId<IdType.Club>; name: string; color: string }>(
				"/clubs/logo",
				{
					clubId,
					name,
					color
				}
			);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
	async updateTeamNameAndAcronym(clubId: MetaSoccerId<IdType.Club>, name: string, acronym: string): Promise<Team> {
		let response: Response<Team>;
		try {
			response = await this.httpService.post<{
				clubId: MetaSoccerId<IdType.Club>;
				name: string;
				acronym: string;
			}>("/clubs", {
				clubId,
				name,
				acronym
			});
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
