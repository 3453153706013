export const Multiwrap = {
  "name": "Multiwrap",
  "address": "0x1d53E862C1ea68765a0497C8D5A2d17041a52Fa6",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_nativeTokenWrapper"
        },
        {
          "type": "address",
          "name": "_defaultAdmin"
        },
        {
          "type": "string",
          "name": "_name"
        },
        {
          "type": "string",
          "name": "_symbol"
        },
        {
          "type": "string",
          "name": "_contractURI"
        },
        {
          "type": "address[]",
          "name": "_trustedForwarders"
        },
        {
          "type": "address",
          "name": "_royaltyRecipient"
        },
        {
          "type": "uint256",
          "name": "_royaltyBps"
        }
      ]
    },
    {
      "type": "error",
      "name": "ContractMetadataUnauthorized",
      "inputs": []
    },
    {
      "type": "error",
      "name": "CurrencyTransferLibMismatchedValue",
      "inputs": [
        {
          "type": "uint256",
          "name": "expected"
        },
        {
          "type": "uint256",
          "name": "actual"
        }
      ]
    },
    {
      "type": "error",
      "name": "OwnableUnauthorized",
      "inputs": []
    },
    {
      "type": "error",
      "name": "PermissionsAlreadyGranted",
      "inputs": [
        {
          "type": "address",
          "name": "account"
        },
        {
          "type": "bytes32",
          "name": "role"
        }
      ]
    },
    {
      "type": "error",
      "name": "PermissionsInvalidPermission",
      "inputs": [
        {
          "type": "address",
          "name": "expected"
        },
        {
          "type": "address",
          "name": "actual"
        }
      ]
    },
    {
      "type": "error",
      "name": "PermissionsUnauthorizedAccount",
      "inputs": [
        {
          "type": "address",
          "name": "account"
        },
        {
          "type": "bytes32",
          "name": "neededRole"
        }
      ]
    },
    {
      "type": "error",
      "name": "RoyaltyExceededMaxFeeBps",
      "inputs": [
        {
          "type": "uint256",
          "name": "max"
        },
        {
          "type": "uint256",
          "name": "actual"
        }
      ]
    },
    {
      "type": "error",
      "name": "RoyaltyInvalidRecipient",
      "inputs": [
        {
          "type": "address",
          "name": "recipient"
        }
      ]
    },
    {
      "type": "error",
      "name": "RoyaltyUnauthorized",
      "inputs": []
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Approval",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "approved",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ApprovalForAll",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "operator",
          "indexed": true
        },
        {
          "type": "bool",
          "name": "approved",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ContractURIUpdated",
      "inputs": [
        {
          "type": "string",
          "name": "prevURI",
          "indexed": false
        },
        {
          "type": "string",
          "name": "newURI",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "DefaultRoyalty",
      "inputs": [
        {
          "type": "address",
          "name": "newRoyaltyRecipient",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "newRoyaltyBps",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Initialized",
      "inputs": [
        {
          "type": "uint8",
          "name": "version",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OwnerUpdated",
      "inputs": [
        {
          "type": "address",
          "name": "prevOwner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "newOwner",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoyaltyForToken",
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        },
        {
          "type": "address",
          "name": "royaltyRecipient",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "royaltyBps",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "TokensUnwrapped",
      "inputs": [
        {
          "type": "address",
          "name": "unwrapper",
          "indexed": true
        },
        {
          "type": "address",
          "name": "recipientOfWrappedContents",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenIdOfWrappedToken",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "TokensWrapped",
      "inputs": [
        {
          "type": "address",
          "name": "wrapper",
          "indexed": true
        },
        {
          "type": "address",
          "name": "recipientOfWrappedToken",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenIdOfWrappedToken",
          "indexed": true
        },
        {
          "type": "tuple[]",
          "name": "wrappedContents",
          "indexed": false,
          "components": [
            {
              "type": "address",
              "name": "assetContract"
            },
            {
              "type": "uint8",
              "name": "tokenType"
            },
            {
              "type": "uint256",
              "name": "tokenId"
            },
            {
              "type": "uint256",
              "name": "totalAmount"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Transfer",
      "inputs": [
        {
          "type": "address",
          "name": "from",
          "indexed": true
        },
        {
          "type": "address",
          "name": "to",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "approve",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "balanceOf",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "contractType",
      "constant": true,
      "stateMutability": "pure",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "contractURI",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "contractVersion",
      "constant": true,
      "stateMutability": "pure",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint8"
        }
      ]
    },
    {
      "type": "function",
      "name": "getApproved",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getDefaultRoyaltyInfo",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        },
        {
          "type": "uint16"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMember",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "address",
          "name": "member"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMemberCount",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "uint256",
          "name": "count"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoyaltyInfoForToken",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        },
        {
          "type": "uint16"
        }
      ]
    },
    {
      "type": "function",
      "name": "getTokenCountOfBundle",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_bundleId"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getTokenOfBundle",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_bundleId"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "tuple",
          "components": [
            {
              "type": "address",
              "name": "assetContract"
            },
            {
              "type": "uint8",
              "name": "tokenType"
            },
            {
              "type": "uint256",
              "name": "tokenId"
            },
            {
              "type": "uint256",
              "name": "totalAmount"
            }
          ]
        }
      ]
    },
    {
      "type": "function",
      "name": "getUriOfBundle",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_bundleId"
        }
      ],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "getWrappedContents",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": [
        {
          "type": "tuple[]",
          "name": "contents",
          "components": [
            {
              "type": "address",
              "name": "assetContract"
            },
            {
              "type": "uint8",
              "name": "tokenType"
            },
            {
              "type": "uint256",
              "name": "tokenId"
            },
            {
              "type": "uint256",
              "name": "totalAmount"
            }
          ]
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "hasRoleWithSwitch",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isApprovedForAll",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "address",
          "name": "operator"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isTrustedForwarder",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "forwarder"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "multicall",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes[]",
          "name": "data"
        }
      ],
      "outputs": [
        {
          "type": "bytes[]",
          "name": "results"
        }
      ]
    },
    {
      "type": "function",
      "name": "name",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "nextTokenIdToMint",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "onERC1155BatchReceived",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "address"
        },
        {
          "type": "uint256[]"
        },
        {
          "type": "uint256[]"
        },
        {
          "type": "bytes"
        }
      ],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "onERC1155Received",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "address"
        },
        {
          "type": "uint256"
        },
        {
          "type": "uint256"
        },
        {
          "type": "bytes"
        }
      ],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "onERC721Received",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "address"
        },
        {
          "type": "uint256"
        },
        {
          "type": "bytes"
        }
      ],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "owner",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "ownerOf",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "royaltyInfo",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        },
        {
          "type": "uint256",
          "name": "salePrice"
        }
      ],
      "outputs": [
        {
          "type": "address",
          "name": "receiver"
        },
        {
          "type": "uint256",
          "name": "royaltyAmount"
        }
      ]
    },
    {
      "type": "function",
      "name": "safeTransferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeTransferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        },
        {
          "type": "bytes",
          "name": "data"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setApprovalForAll",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "operator"
        },
        {
          "type": "bool",
          "name": "approved"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setContractURI",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "string",
          "name": "_uri"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setDefaultRoyaltyInfo",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_royaltyRecipient"
        },
        {
          "type": "uint256",
          "name": "_royaltyBps"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setOwner",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_newOwner"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setRoyaltyInfoForToken",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        },
        {
          "type": "address",
          "name": "_recipient"
        },
        {
          "type": "uint256",
          "name": "_bps"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "symbol",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenByIndex",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenOfOwnerByIndex",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenURI",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "totalSupply",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "transferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "unwrap",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        },
        {
          "type": "address",
          "name": "_recipient"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "wrap",
      "constant": false,
      "stateMutability": "payable",
      "payable": true,
      "inputs": [
        {
          "type": "tuple[]",
          "name": "_tokensToWrap",
          "components": [
            {
              "type": "address",
              "name": "assetContract"
            },
            {
              "type": "uint8",
              "name": "tokenType"
            },
            {
              "type": "uint256",
              "name": "tokenId"
            },
            {
              "type": "uint256",
              "name": "totalAmount"
            }
          ]
        },
        {
          "type": "string",
          "name": "_uriForWrappedToken"
        },
        {
          "type": "address",
          "name": "_recipient"
        }
      ],
      "outputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ]
    }
  ]
}