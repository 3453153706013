import { ACCOUNT_ROUTES } from "@/modules/account/account.routes";
import { CLUB_ROUTES } from "@/modules/club/club.routes";
import { HOME_ROUTES } from "@/modules/home/home.routes";
import { MARKET_ROUTES } from "@/modules/market";
import { MATCH_ROUTES } from "@/modules/match/match.routes";
import { MSL_ROUTES } from "@/modules/msl/msl.routes";
import { PLAY_MATCH_ROUTES } from "@/modules/play-match/play-match.routes";
import { PLAYER_ROUTES } from "@/modules/player/player.routes";
import { PVP_ROUTES } from "@/modules/pvp/pvp.routes";
import { SCOUT_ROUTES } from "@/modules/scout/scout.routes";
import { SHOP_ROUTES } from "@/modules/shop/shop.routes";
import { SQUAD_ROUTES } from "@/modules/squad/squad.routes";
import { TOURNAMENT_ROUTES } from "@/modules/tournament/tournament.routes";
import { TRAINING_ROUTES } from "@/modules/training/training.routes";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MigratedRoutes } from "./MigratedRoutes";

const AccountRouter = React.lazy(() => import("../modules/account/account.router"));
const ClubRouter = React.lazy(() => import("../modules/club/club.router"));
const HomeRouter = React.lazy(() => import("../modules/home/home.router"));
const MarketRouter = React.lazy(() => import("../modules/market/market.router"));
const MatchRouter = React.lazy(() => import("../modules/match/match.router"));
const MSLRouter = React.lazy(() => import("../modules/msl/msl.router"));
const PlayMatchRouter = React.lazy(() => import("../modules/play-match/play-match.router"));
const PlayerRouter = React.lazy(() => import("../modules/player/player.router"));
const PvpRouter = React.lazy(() => import("../modules/pvp/pvp.router"));
const ScoutRouter = React.lazy(() => import("../modules/scout/scout.router"));
const ShopRouter = React.lazy(() => import("../modules/shop/shop.router"));
const SquadRouter = React.lazy(() => import("../modules/squad/squad.router"));
const TournamentRouter = React.lazy(() => import("../modules/tournament/tournament.router"));
const TrainingRouter = React.lazy(() => import("../modules/training/training.router"));

const Router = () => {
	return (
		<React.Suspense fallback={""}>
			<Routes>
				{MigratedRoutes}
				<Route index element={<Navigate to={HOME_ROUTES.ROOT.path} />} />
				<Route path={`${ACCOUNT_ROUTES.ROOT.path}/*`} element={<AccountRouter />} />
				<Route path={`${CLUB_ROUTES.ROOT.path}/*`} element={<ClubRouter />} />
				<Route path={`${HOME_ROUTES.ROOT.path}/*`} element={<HomeRouter />} />
				<Route path={`${MARKET_ROUTES.ROOT.path}/*`} element={<MarketRouter />} />
				<Route path={`${MARKET_ROUTES.ROOT.pathLong}/*`} element={<MarketRouter />} />
				<Route path={`${MSL_ROUTES.ROOT.path}/*`} element={<MSLRouter />} />
				<Route path={`${MATCH_ROUTES.ROOT.path}/*`} element={<MatchRouter />} />
				<Route path={`${PLAY_MATCH_ROUTES.ROOT}/*`} element={<PlayMatchRouter />} />
				<Route path={`${PLAYER_ROUTES.ROOT.path}/*`} element={<PlayerRouter />} />
				<Route path={`${PVP_ROUTES.ROOT.path}/*`} element={<PvpRouter />} />
				<Route path={`${SCOUT_ROUTES.ROOT.path}/*`} element={<ScoutRouter />} />
				<Route path={`${SHOP_ROUTES.ROOT.path}/*`} element={<ShopRouter />} />
				<Route path={`${SQUAD_ROUTES.ROOT.path}/*`} element={<SquadRouter />} />
				<Route path={`${TOURNAMENT_ROUTES.ROOT.path}/*`} element={<TournamentRouter />} />
				<Route path={`${TRAINING_ROUTES.ROOT}/*`} element={<TrainingRouter />} />
			</Routes>
		</React.Suspense>
	);
};

export default Router;
