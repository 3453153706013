import { useIsMobile } from "@/layouts/context";
import { InfoPill, Typography } from "@metasoccer/metasoccer-ds";
import { LastTournamentMatch, TournamentStructureEdition } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { ScoreMatchResult } from "../ResultsCard/components";
import { CssContent, CssDate, CssListWrapper, CssMatchesWrapper, CssResultsListWrapper } from "./ResultsList.styled";

interface ResultsListProps {
	lastMatches: LastTournamentMatch[];
	tournamentStructureEditions: TournamentStructureEdition[];
}

export const ResultsList: FC<ResultsListProps> = ({ lastMatches, tournamentStructureEditions }) => {
	const isMobile = useIsMobile();
	// const defaultEdition = tournamentStructureEditions.filter((edition) => edition.initialized).slice(-1)[0];
	const lastMatches2 = [...lastMatches, ...lastMatches, ...lastMatches];
	const sortedMatches = lastMatches2.sort((a, b) => b.matchDatetime - a.matchDatetime);
	const groupedMatchesByDay = sortedMatches.reduce((acc: Record<string, LastTournamentMatch[]>, match) => {
		const matchLocaleDateString = new Date(match.matchDatetime).toLocaleDateString(window.navigator.language, {
			weekday: "long",
			month: "short",
			day: "numeric",
			year: "numeric"
		});
		if (typeof acc[matchLocaleDateString] === "undefined") {
			acc[matchLocaleDateString] = [];
		}

		acc[matchLocaleDateString].push(match);
		return acc;
	}, {});

	return (
		<CssListWrapper>
			{Object.keys(groupedMatchesByDay).map((dateString) => (
				<CssResultsListWrapper key={dateString}>
					<CssDate>
						<Typography variant="body2">{dateString}</Typography>
					</CssDate>
					<CssContent>
						{groupedMatchesByDay[dateString].map((match) => (
							<CssMatchesWrapper key={match.matchDatetime}>
								{!isMobile && <InfoPill title="Division">{match.divisionName}</InfoPill>}
								{!isMobile && <InfoPill title="Group">{String(match.groupNumber)}</InfoPill>}
								<ScoreMatchResult match={match} showQualityLineupSkill isMatchResultLayout />
								{!isMobile && <span></span>}
							</CssMatchesWrapper>
						))}
					</CssContent>
				</CssResultsListWrapper>
			))}
		</CssListWrapper>
	);
};
