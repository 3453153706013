export enum Type {
	RESULT = "result",
	ERROR = "error"
}

export enum MatchTypeLS {
	TOURNAMENT_MATCH = "tournamentMatch",
	FRIENDLY_PVP_MATCH = "friendlyPvPMatch",
	FRIENDLY_MATCH = "friendlyMatch"
}

export function getMatchKeyForStorage(key: Type, matchType: MatchTypeLS, wallet: string, teamId?: string): string {
	return `wallet:${wallet.toLowerCase()}-team:${teamId}-${matchType}-${key}`;
}
