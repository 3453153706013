import styled, { css } from "styled-components";
import { Typography } from "@metasoccer/metasoccer-ds";

interface CssTypographyProps {
	color?: string;
}

export const CssTypography = styled(Typography)<CssTypographyProps>(
	({ color }) => css`
		color: ${color};
	`
);
