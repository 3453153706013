import { Covering, PlayerSpecificRole } from "@metasoccer/metasoccer-types";

export const DEFAULT_ROLE_SORTING_MAP = {
	[Covering.NONE]: -1,
	[Covering.GK]: 1,
	[Covering.DF]: 2,
	[Covering.MF]: 3,
	[Covering.FW]: 4
};

export const DEFAULT_SPECIFIC_ROLE_SORTING_MAP = {
	[PlayerSpecificRole.GK]: 1,
	[PlayerSpecificRole.CB]: 2,
	[PlayerSpecificRole.LB]: 3,
	[PlayerSpecificRole.LWB]: 4,
	[PlayerSpecificRole.RB]: 5,
	[PlayerSpecificRole.RWB]: 6,
	[PlayerSpecificRole.CDM]: 7,
	[PlayerSpecificRole.CM]: 8,
	[PlayerSpecificRole.CAM]: 9,
	[PlayerSpecificRole.LM]: 10,
	[PlayerSpecificRole.RM]: 11,
	[PlayerSpecificRole.CF]: 12,
	[PlayerSpecificRole.LF]: 13,
	[PlayerSpecificRole.LW]: 14,
	[PlayerSpecificRole.RF]: 15,
	[PlayerSpecificRole.RW]: 16,
	[PlayerSpecificRole.ST]: 17
};
