import ResponsiveLayout from "@/layouts/ResponsiveLayout/ResponsiveLayout";
import Router from "@/router/Router";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { useEffect } from "react";
import { AirdropContextProvider } from "./modules/home/hooks/useAirdrop";
import { Loading, Login } from "./modules/shared/components";
import { SelectedTeamContextProvider, useGlobalState } from "./modules/shared/context";
import { BalanceContextProvider } from "./modules/shared/context/BalanceContext";
import { LiveMatchContextProvider } from "./modules/shared/context/LiveMatchContext";

export const App = () => {
	const { address, isDisconnected } = useGlobalState();

	const updateContext = useUnleashContext();

	useEffect(() => {
		updateContext({ userId: address });
	}, [address, updateContext]);

	if (isDisconnected) {
		return <Login />;
	}

	if (!address) {
		return <Loading />;
	}

	return (
		<SelectedTeamContextProvider>
			<LiveMatchContextProvider>
				<BalanceContextProvider>
					<AirdropContextProvider>
						<ResponsiveLayout>
							<Router />
						</ResponsiveLayout>
					</AirdropContextProvider>
				</BalanceContextProvider>
			</LiveMatchContextProvider>
		</SelectedTeamContextProvider>
	);
};
