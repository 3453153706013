import { NotRegisteredForNextMatch } from "@/modules/lineup/shared/components/PlayerConditions";
import { getPlayerImageUrl } from "@/modules/player/helpers";
import { getPlayerShortName } from "@/modules/player/helpers/getPlayerShortName";
import { getImgUrl } from "@/modules/shared/utils";
import { Row, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { InjuryIndicator } from "../../InjuryIndicator";
import { PlayerCardsIndicator } from "../../PlayerCardsIndicator";
import { PlayerLink } from "../../PlayerLink";
import { PlayerTableItem } from "../PlayerTable";

export const TitleCell = memo<{ player: PlayerTableItem; showPlayerPip?: boolean; useLinks?: boolean }>(
	({ player, showPlayerPip = true, useLinks = true }) => {
		const shortName = getPlayerShortName(player._player.name, player._player.lastName);

		return (
			<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
				<Row alignItems="center" gap={12}>
					{showPlayerPip && (
						<div style={{ position: "relative", width: 24, height: 24, overflow: "hidden" }}>
							<img
								alt={shortName}
								src={getImgUrl(getPlayerImageUrl(player._player, true), { size: 256 })}
								style={{ position: "absolute", top: 0, left: "50%", transform: "translate(-50%, -4%)" }}
								width={96}
								height={96}
							/>
						</div>
					)}
					{useLinks ? (
						<PlayerLink player={player._player} />
					) : (
						<Typography variant="body2">{shortName}</Typography>
					)}
				</Row>
				<Row alignItems="center" gap={4}>
					{player.sanctionedPlayerCards && player.sanctionedPlayerCards.length > 0 && (
						<PlayerCardsIndicator sanctionedPlayerCards={player.sanctionedPlayerCards} isTable />
					)}
					{player.injuredPlayer && <InjuryIndicator injuredPlayer={player.injuredPlayer} />}
					{!player.playerRegisteredForNextMatch && <NotRegisteredForNextMatch />}
				</Row>
			</Row>
		);
	}
);
