import { FC } from "react";
import { TabGroup, Tapbar } from "@metasoccer/metasoccer-ds";
import { TournamentDivisionSimplified } from "@metasoccer/metasoccer-types";
import { CssPrizePoolTabs } from "@/modules/shared/components/PrizePool/components/PrizePoolTabs/PrizePoolTabs.styled";

interface PrizePoolTabsProps {
	divisions: TournamentDivisionSimplified[];
	selectedTab: number;
	onSelectTab: (index: number) => void;
}

export const PrizePoolTabs: FC<PrizePoolTabsProps> = ({ divisions, selectedTab, onSelectTab }) => {
	return (
		<CssPrizePoolTabs onIndexChange={onSelectTab} index={selectedTab}>
			<TabGroup>
				{divisions.map((division, index) => (
					<Tapbar key={division.id} index={index} size={"sm"}>
						{division.name}
					</Tapbar>
				))}
			</TabGroup>
		</CssPrizePoolTabs>
	);
};
