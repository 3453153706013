import {
	CaptainIcon,
	ClutchTimePlayerIcon,
	Col,
	CornerSpecialistIcon,
	DribblerIcon,
	InsuperableIcon,
	KaiserIcon,
	KickerGoalkeeperIcon,
	LeaderIcon,
	MagicianIcon,
	PenaltyStopperIcon,
	Row,
	SpeakerGoalkeeperIcon,
	SuperSubstituteIcon,
	TheMagicianIcon,
	Typography,
	WarriorIcon
} from "@metasoccer/metasoccer-ds";
import { SpecialPlayerSkill } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { Tooltip } from "../Tooltip";

const ICON_BY_SPECIAL_ABILITY = {
	[SpecialPlayerSkill.Captain]: () => <CaptainIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.ClutchTimePlayer]: () => <ClutchTimePlayerIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.CoachOnThePitch]: () => <MagicianIcon style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.CornerSpecialist]: () => <CornerSpecialistIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.Dribbler]: () => <DribblerIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.FoulKicker]: () => <MagicianIcon style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.Insuperable]: () => <InsuperableIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.Kaiser]: () => <KaiserIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.KickerGK]: () => <KickerGoalkeeperIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.Killer]: () => <MagicianIcon style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.Leader]: () => <LeaderIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.PenaltyKicker]: () => <MagicianIcon style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.PenaltyStopper]: () => <PenaltyStopperIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.SpeakerGK]: () => <SpeakerGoalkeeperIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.SuperSubstitute]: () => <SuperSubstituteIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.TeamPlayer]: () => <TheMagicianIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.TheMagician]: () => <TheMagicianIcon size="sm" style={{ width: 18, height: 18 }} />,
	[SpecialPlayerSkill.Warrior]: () => <WarriorIcon size="sm" style={{ width: 18, height: 18 }} />
};

const specialAbilityDescription = {
	[SpecialPlayerSkill.Captain]: "+3 overall; +1 overall of all players in the same line",
	[SpecialPlayerSkill.ClutchTimePlayer]: "+6 overall",
	[SpecialPlayerSkill.CoachOnThePitch]: "+3 overall; +1 overall of all players in the same line",
	[SpecialPlayerSkill.CornerSpecialist]: "+6 overall",
	[SpecialPlayerSkill.Dribbler]: "+6 overall",
	[SpecialPlayerSkill.FoulKicker]: "+6 overall",
	[SpecialPlayerSkill.Insuperable]: "+6 overall",
	[SpecialPlayerSkill.Kaiser]: "+3 overall; +1 overall of all players in the same line",
	[SpecialPlayerSkill.KickerGK]: "+6 overall",
	[SpecialPlayerSkill.Killer]: "+6 overall",
	[SpecialPlayerSkill.Leader]: "+3 overall; +1 overall of all players in the same line",
	[SpecialPlayerSkill.PenaltyKicker]: "+6 overall",
	[SpecialPlayerSkill.PenaltyStopper]: "+6 overall",
	[SpecialPlayerSkill.SpeakerGK]: "+3 overall; +1 overall of all players in the same line",
	[SpecialPlayerSkill.SuperSubstitute]: "+6 overall",
	[SpecialPlayerSkill.TeamPlayer]: "+3 overall; +1 overall of all players in the same line",
	[SpecialPlayerSkill.TheMagician]: "+6 overall",
	[SpecialPlayerSkill.Warrior]: "+3 overall; +1 overall of all players in the same line"
};

export interface SpecialAbilitiesProps {
	hideLabel?: boolean;
	specialAbilities: SpecialPlayerSkill[];
}

export const SpecialAbilities = memo<SpecialAbilitiesProps>(({ hideLabel, specialAbilities }) => {
	return (
		<Row gap={4}>
			{!hideLabel && (
				<Typography variant="body2" light>
					S.Abilit.
				</Typography>
			)}
			{specialAbilities.length > 0 ? (
				specialAbilities.map((ability, index) => {
					const IconComponent = ICON_BY_SPECIAL_ABILITY[ability];
					const TextComponent = (
						<Col gap={8}>
							<Typography variant="body2">{ability}</Typography>
							<Typography variant="description" light>
								{specialAbilityDescription[ability]}
							</Typography>
						</Col>
					);
					return (
						<Tooltip text={TextComponent}>
							<IconComponent key={index} />
						</Tooltip>
					);
				})
			) : (
				<Typography variant="body2" light>
					-
				</Typography>
			)}
		</Row>
	);
});
