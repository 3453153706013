import { Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssLiveWrapper, CssPulse } from "./Live.styled";

export const Live: FC = () => {
	return (
		<CssLiveWrapper>
			<CssPulse></CssPulse>
			<Typography variant="body2Bold">LIVE</Typography>
		</CssLiveWrapper>
	);
};
