import { useMSLState } from "@/modules/msl";
import { memo } from "react";
import { EditionBanner, NextMatchBanner } from "./components";

export const MainBanner = memo(() => {
	const { nextMatch } = useMSLState();

	if (nextMatch) {
		return <NextMatchBanner />;
	} else {
		return <EditionBanner />;
	}
});
