import { Typography } from "@metasoccer/metasoccer-ds";
import { LineupWithSkill, LockedPlayer, Player, PlayerShirtNumberDict } from "@metasoccer/metasoccer-types";
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { LineupPlayer } from "../LineupPlayer";
import { CssBench, CssLabel, CssWrapper, LineupBenchVariant } from "./LineupBench.styles";

const BENCH_SIZE = 4;

interface LineupBenchProps {
	variant?: LineupBenchVariant;
	disableEmptySlots?: boolean;
	lineupWithSkill: LineupWithSkill;
	playersIdsOff: number[];
	shirtNumbers: PlayerShirtNumberDict;
	lockedPlayers?: LockedPlayer[];
	onPlayerSelected: (player: Player, slotIndex: number) => void;
}

export const LineupBench = memo<LineupBenchProps>(
	({
		variant = "horizontal",
		disableEmptySlots,
		lineupWithSkill,
		playersIdsOff,
		shirtNumbers,
		lockedPlayers,
		onPlayerSelected
	}) => {
		const wrapperRef = useRef<HTMLDivElement>(null);

		const [height, setHeight] = useState(0);
		const [width, setWidth] = useState(0);

		const getPositionByIndex = useCallback(
			(index: number) =>
				variant === "horizontal"
					? { position: { y: 1 / BENCH_SIZE / 2 + (1 / BENCH_SIZE) * index, x: 0.5 } }
					: { position: { x: 1 - (1 / BENCH_SIZE / 2 + (1 / BENCH_SIZE) * index), y: 0.5 } },
			[variant]
		);

		useLayoutEffect(() => {
			setHeight(wrapperRef?.current?.clientHeight ?? 0);
			setWidth(wrapperRef?.current?.clientWidth ?? 0);
		}, [wrapperRef?.current]);

		return (
			<CssWrapper variant={variant}>
				{variant === "horizontal" && (
					<CssLabel>
						<Typography variant="h6">Bench</Typography>
					</CssLabel>
				)}
				<CssBench ref={wrapperRef}>
					{[...Array(BENCH_SIZE).keys()].map((index) => {
						const player = lineupWithSkill.lineup.playersInBench[index];
						const playerIsOff = player && playersIdsOff.includes(player.id);
						const slotIsEmpty = !player;

						return (
							<LineupPlayer
								key={index}
								disabled={playerIsOff || (disableEmptySlots && slotIsEmpty)}
								lineupWithSkill={lineupWithSkill}
								lockedPlayers={lockedPlayers}
								parentWidth={width}
								parentHeight={height}
								player={player}
								playerSkill={{ bonus: 0, penalty: 0, skill: player?.overall }}
								shirtNumber={player ? shirtNumbers[player.id] : undefined}
								showPlayerSpecificRole
								showSlotId
								slot={getPositionByIndex(index)}
								slotId={`B${index + 1}`}
								onPlayerSelected={(player) => onPlayerSelected(player, index)}
							/>
						);
					})}
				</CssBench>
			</CssWrapper>
		);
	}
);
