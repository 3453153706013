import { useMSLState } from "@/modules/msl";
import { InfoRewards } from "@/modules/msl/components";
import { Info } from "@/modules/msl/components/Info";
import { ContinueButton, Modal, TwoColumnLayout } from "@/modules/shared/components";
import { getRemainingTime } from "@/modules/shared/helpers";
import { MSLIcon } from "@metasoccer/metasoccer-ds";
import { useMemo, useState } from "react";
import { CssDate } from "../MainBanner/components/PvpJoinTournament/PvpJoinTournament.style";
import { CardStyled, ContainerStyled } from "./TournamentCard.styles";

export const TournamentCard = () => {
	const { group } = useMSLState();
	if (!group) throw new Error("Group is undefined");

	const [isModalOpen, setIsModalOpen] = useState(false);

	const startTimestamp = group.tournamentEdition.startDate;
	const endTimestamp = group.tournamentEdition.endDate;
	const hasStarted = startTimestamp < Date.now();
	const hasEnded = endTimestamp && endTimestamp < Date.now();

	const Subtitle = useMemo(
		() => (
			<>
				{hasStarted && !hasEnded && endTimestamp && (
					<CssDate variant="caption">{`Ends in ${getRemainingTime(endTimestamp)}`}</CssDate>
				)}
				{!hasStarted && <CssDate variant="caption">{`Starts in ${getRemainingTime(startTimestamp)}`}</CssDate>}
			</>
		),
		[hasEnded, hasStarted, endTimestamp, startTimestamp]
	);

	return (
		<>
			<CardStyled
				title="MSL"
				subtitle={Subtitle}
				HeaderRight={<MSLIcon size="md" />}
				onClick={() => setIsModalOpen(true)}>
				<ContainerStyled>
					<ContinueButton text="Info" />
				</ContainerStyled>
			</CardStyled>
			{isModalOpen && (
				<Modal
					isOpen={isModalOpen}
					maxScreenWidth
					size="xxl"
					title="MSL Info"
					onClose={() => setIsModalOpen(false)}>
					<TwoColumnLayout>
						<Info />
						<InfoRewards />
					</TwoColumnLayout>
				</Modal>
			)}
		</>
	);
};
