import { compact } from "@/modules/shared/utils";
import { ReactNode, memo } from "react";
import { SectionStyled, WrapperStyled } from "./HomeLayout.styles";

interface HomeLayoutProps {
	children: [ReactNode, ReactNode, ReactNode] | [ReactNode, ReactNode];
}

export const HomeLayout = memo<HomeLayoutProps>(({ children }) => {
	return (
		<WrapperStyled>
			{compact(children).length === 3 ? (
				<>
					<SectionStyled>{children[0]}</SectionStyled>
					<SectionStyled>{children[1]}</SectionStyled>
					<SectionStyled>{children[2]}</SectionStyled>
				</>
			) : (
				<>
					<SectionStyled>{children[0]}</SectionStyled>
					<SectionStyled flex={2}>{children[1]}</SectionStyled>
				</>
			)}
		</WrapperStyled>
	);
});
