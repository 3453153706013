import { Typography } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";

export const MultiplierDecorator = memo<{ n: number; children: ReactNode }>(({ n, children }) => (
	<div style={{ display: "flex", alignItems: "center" }}>
		{n > 1 && (
			<Typography variant="description" light>
				{n}x
			</Typography>
		)}
		{children}
	</div>
));
