import { useServerState } from "@/modules/core/react-query/hooks";
import { HOME_QUERIES } from "@/modules/home";
import { MSL_QUERIES } from "@/modules/msl";
import { useGlobalState } from "@/modules/shared/context";
import { InjuredLockedPlayer } from "@metasoccer/metasoccer-types";
import React, { useCallback, useMemo } from "react";
import { PLAYERS_QUERIES } from "../queries";
import { defineIf } from "../utils";

type SelectedTeamContextProviderProps = { children: React.ReactNode };

const useSelectedTeamState = () => {
	const { selectedTeam } = useGlobalState();

	const hasTeam = Boolean(!!selectedTeam?.id);

	const {
		data: teamInfo,
		isLoading: isLoadingTeamInfo,
		refetch: refetchTeamInfo
	} = useServerState(HOME_QUERIES.teamInfo(selectedTeam?.id), {
		enabled: hasTeam
	});

	const hasNextMatch =
		Boolean(teamInfo?.nextMatch?.editionId) && (teamInfo?.nextMatch?.match?.date ?? 0) > Date.now();

	const { data: playersData, refetch: refetchPlayersData } = useServerState(
		PLAYERS_QUERIES.getPlayersWithShirtNumbersByTeamId(selectedTeam?.id),
		{
			enabled: hasTeam
		}
	);

	const { data: lockedPlayers, refetch: refetchLockedPlayers } = useServerState(
		PLAYERS_QUERIES.getLockedPlayersByTeamId(selectedTeam?.id),
		{
			enabled: hasTeam
		}
	);

	const { data: playersCards, refetch: refetchPlayersCards } = useServerState(
		PLAYERS_QUERIES.getPlayerCardsByTeamId(selectedTeam?.id),
		{
			enabled: hasTeam
		}
	);

	const nextMatchTournamentId = useMemo(
		() =>
			defineIf(teamInfo?.nextMatch?.editionId, () =>
				teamInfo!.nextMatch.editionId !== "unknown" ? teamInfo!.nextMatch.editionId : undefined
			),
		[teamInfo]
	);

	const {
		data: playersRegisteredForNextMatch = undefined,
		isLoading: isLoadingRegisteredPlayers,
		refetch: refetchRegisteredPlayers
	} = useServerState(MSL_QUERIES.getRegisteredPlayers(nextMatchTournamentId, selectedTeam?.id), {
		enabled: hasNextMatch && hasTeam
	});

	const getPlayerCards = useCallback(
		(playerId: number) => {
			return playersCards?.filter((card) => card.playerId === playerId) ?? [];
		},
		[playersCards]
	);

	const isPlayerInjured = useCallback(
		(playerId: number) => {
			let injuredPlayer: InjuredLockedPlayer | undefined = undefined;

			const injuredLockedPlayer = lockedPlayers?.find(
				({ playerId: lockedPlayerId, reason }) => lockedPlayerId === playerId && reason === "Injured"
			);

			if (
				injuredLockedPlayer &&
				"injury" in injuredLockedPlayer &&
				"expirationTimestamp" in injuredLockedPlayer
			) {
				injuredPlayer = injuredLockedPlayer as InjuredLockedPlayer;
			}

			return injuredPlayer;
		},
		[lockedPlayers]
	);

	const isPlayerRegisteredForNextMatch = useCallback(
		(playerId: number) => {
			if (!nextMatchTournamentId) return true;
			return playersRegisteredForNextMatch?.includes(playerId) ?? true;
		},
		[nextMatchTournamentId, playersRegisteredForNextMatch]
	);

	return {
		isLoading: isLoadingTeamInfo || isLoadingRegisteredPlayers,
		players: playersData?.players ?? [],
		teamInfo,
		getPlayerCards,
		isPlayerInjured,
		isPlayerRegisteredForNextMatch,
		refetchPlayersData
	};
};

const SelectedTeamContext = React.createContext<ReturnType<typeof useSelectedTeamState> | undefined>(undefined);

const SelectedTeamContextProvider = ({ children }: SelectedTeamContextProviderProps) => {
	const homeState = useSelectedTeamState();

	return <SelectedTeamContext.Provider value={homeState}>{children}</SelectedTeamContext.Provider>;
};

export { SelectedTeamContext, SelectedTeamContextProvider };
