import { useIsMobile } from "@/layouts/context";
import { LineupQualityStars } from "@/modules/shared/components";
import { ClubLogo, Col, ResultChip, Typography } from "@metasoccer/metasoccer-ds";
import { PvpTeamInfo as PvpTeamInfoType } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { CssPvpTeamInfo, CssPvpTeamInfoWrapper, CssPvpTeamName, CssStreakContainer } from "./PvpTeamInfo.styled";

interface PvpTeamInfoProps {
	teamInfo: PvpTeamInfoType;
	direction?: "column" | "row";
	reverse?: boolean;
	size?: "md" | "2lg";
}

export const PvpTeamInfo: FC<PvpTeamInfoProps> = ({
	teamInfo,
	direction: initialDirection = "row",
	reverse = false,
	size = "2lg"
}) => {
	const isMobile = useIsMobile();
	const isSmall = isMobile || size === "md";

	const direction = isMobile ? "column" : initialDirection;

	const getLogoSize = () => {
		if (size === "2lg" && isMobile) {
			return "lg";
		}

		return size;
	};

	return (
		<CssPvpTeamInfoWrapper direction={direction} reverse={reverse}>
			<CssPvpTeamInfo reverse={reverse}>
				<Col
					alignItems={direction === "column" ? "center" : reverse ? "flex-start" : "flex-end"}
					gap={4}
					style={{ width: "100%" }}>
					<CssPvpTeamName reverse={reverse}>
						<Typography variant={"body1"}>{isSmall ? teamInfo.teamAcronym : teamInfo.teamName}</Typography>
					</CssPvpTeamName>
					<LineupQualityStars quality={teamInfo.overall} qualityPosition={reverse ? "right" : "left"} />
					<Typography variant={"caption"} light>
						{teamInfo.streakText}
					</Typography>
				</Col>
				{teamInfo.streak.length > 0 && (
					<CssStreakContainer>
						{teamInfo.streak.map((r, index) => (
							<ResultChip result={r} key={index} size={isSmall ? "sm" : "lg"} />
						))}
					</CssStreakContainer>
				)}
			</CssPvpTeamInfo>
			<ClubLogo
				color={teamInfo.clubLogo.color}
				name={teamInfo.clubLogo.name}
				url={teamInfo.clubLogo.url}
				size={getLogoSize()}
			/>
		</CssPvpTeamInfoWrapper>
	);
};
