import { Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";

const tooltipToPlayerTableTitles: FC<{ description: string; title: string }> = ({ description, title }) => (
	<Tooltip position="center">
		{{
			popper: (
				<Typography variant="description" light>
					{description}
				</Typography>
			),
			content: <Typography variant="body2">{title}</Typography>
		}}
	</Tooltip>
);

export default tooltipToPlayerTableTitles;
