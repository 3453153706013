import { useIsMobile } from "@/layouts/context";
import { ClubLogo, Typography } from "@metasoccer/metasoccer-ds";
import { Team, TeamStats } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { InfoMessage } from "../InfoMessage";
import {
	CssClubLogoWrapper,
	CssStatsCardContentWrapper,
	CssStatsCardWrapper,
	CssStatsContainerWrapper,
	CssStatsTitleWrapper,
	CssStatsWrapper
} from "./StatsCard.styled";
import { Stat } from "./components/Stat";

interface StatsCardProps {
	stats?: TeamStats;
	selectedTeam?: Team;
	isError?: boolean;
	contextText?: string;
	error?: string;
}

export const StatsCard: FC<StatsCardProps> = ({ stats, selectedTeam, isError, contextText, error }) => {
	const isMobile = useIsMobile();
	return (
		<CssStatsCardWrapper>
			<CssStatsTitleWrapper>
				<Typography variant="h5">Team stats</Typography>
				{contextText && (
					<Typography variant="caption" light>
						{contextText}
					</Typography>
				)}
			</CssStatsTitleWrapper>

			{isError && !error ? (
				<InfoMessage type="error" />
			) : !stats && !error ? (
				<InfoMessage message="There are no stats to show" />
			) : error ? (
				<InfoMessage message={error} />
			) : (
				stats && (
					<CssStatsCardContentWrapper>
						{!isMobile && (
							<CssClubLogoWrapper>
								<ClubLogo
									name={selectedTeam?.clubLogo.name}
									color={selectedTeam?.clubLogo.color}
									url={selectedTeam?.clubLogo.url}
									size="lg"
								/>
							</CssClubLogoWrapper>
						)}
						<CssStatsWrapper>
							<CssStatsContainerWrapper>
								<Stat title="Played" value={String(stats.playedMatches)} />
								<Stat title="Won" value={String(stats.wonMatches)} />
								<Stat title="Tied" value={String(stats.tiedMatches)} />
								<Stat title="Lost" value={String(stats.lostMatches)} />
							</CssStatsContainerWrapper>
							<CssStatsContainerWrapper>
								<Stat title="GF" value={String(stats.goalsFor)} />
								<Stat title="GA" value={String(stats.goalsAgainst)} />
								<Stat
									title="Win Rate"
									value={
										stats.winRate === undefined ? undefined : `${Math.round(stats.winRate * 100)}%`
									}
								/>
							</CssStatsContainerWrapper>
						</CssStatsWrapper>
					</CssStatsCardContentWrapper>
				)
			)}
		</CssStatsCardWrapper>
	);
};
