import styled, { css } from "styled-components";
import { Typography } from "@metasoccer/metasoccer-ds";

export const CssTournamentInfoWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		padding: 24px 12px 0 12px;

		${theme.breakpoints.up("desktopMin")} {
			flex: 1 1 0%;
			overflow-y: auto;
			padding: 24px;
			border-right: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
		}
	`
);

export const CssTitle = styled(Typography)`
	min-height: 22px;
`;
