import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssPvpMatchDateWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
	text-align: center;
	min-width: 40px;
`;

export const CssIconWrapper = styled.div`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 8px;
	width: 24px;
`;

export const CssLiveMatchWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
	`
);

export const CssTimerWrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 12px;
	padding-top: 1px;
	position: relative;
	width: 100%;
`;

export const CssTimer = styled(Typography)<{ position?: "L" | "C" | "R" }>(
	({ position, theme }) => css`
		color: ${theme.palette.system.pastelGreen};
		line-height: 1;
		${position === "L" &&
		`
			margin-right: 2px;
			position: absolute;
			right: 50%;
		`}
		${position === "R" &&
		`
			margin-left: 2px;
			position: absolute;
			left: 50%;
		`}
	`
);
