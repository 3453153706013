import { useCallback, useEffect, useState } from "react";
import { useDeepCompareEffect } from "react-use";

interface UsePromiseReturnType<T> {
	data: T;
	isFetchedAfterMount: boolean;
	isLoading: boolean;
	refetch: () => void;
}

interface UsePromiseOptions {
	refetchInterval?: number; // Interval in milliseconds
}

export function usePromise<T, D = T>(
	promiseBuilder: () => Promise<T> | undefined,
	defaultValue: D,
	deps?: any[],
	options?: UsePromiseOptions
): UsePromiseReturnType<T | D> {
	const { refetchInterval } = options || {};
	const [isLoading, setIsLoading] = useState(false);
	const [isFetchedAfterMount, setIsFetchedAfterMount] = useState(false);
	const [value, setValue] = useState<T | D>(defaultValue);

	const refetch = useCallback(() => {
		const promise = promiseBuilder();
		if (!promise) return;
		setIsLoading(true);
		promise.then(setValue).finally(() => {
			setIsFetchedAfterMount(true);
			setIsLoading(false);
		});
	}, deps ?? []);

	useDeepCompareEffect(() => {
		refetch();
	}, deps ?? []);

	useEffect(() => {
		if (refetchInterval) {
			const intervalId = setInterval(refetch, refetchInterval);
			return () => clearInterval(intervalId);
		}
	}, [refetchInterval, refetch]);

	return { data: value, isFetchedAfterMount, isLoading, refetch };
}
