import React, { FC } from "react";
import { CloseIcon, DrillChip, Typography } from "@metasoccer/metasoccer-ds";
import { DrillContent, DrillFooter } from "./components";
import {
	CssDeleteWrapper,
	CssDrillChip,
	CssDrillImageWrapper,
	CssDrillTag,
	CssDrillWrapper,
	CssInnerWrapper
} from "./DrillCard.styled";
import { Drill, FeatureFlag } from "@metasoccer/metasoccer-types";
import { getDrillByType, getDrillQualityText } from "./helpers";
import { useFlag } from "@unleash/proxy-client-react";

interface DrillCardProps {
	drill: Drill;
	selectedPlayerCount?: number;
	isDeletable?: boolean;
	onDelete?: () => void;
	onClick?: (drill: Drill) => void;
	className?: string;
}
export const DrillCard: FC<DrillCardProps> = ({
	drill,
	selectedPlayerCount = 0,
	isDeletable = false,
	onDelete = () => {},
	onClick = () => {},
	className
}) => {
	const useAssets2 = useFlag(FeatureFlag.UseAssets2);

	const imageUrl = useAssets2
		? `https://assets2.metasoccer.com/file/assets2-metasoccer/training/formatted/drillCard/${drill.id}.png`
		: `https://assets.metasoccer.com/cdn-cgi/image/width=992,height=272,quality=90,metadata=none,format=auto/training/drills/${drill.id}.png`;
	return (
		<CssDrillWrapper className={className}>
			{isDeletable && (
				<CssDeleteWrapper>
					<CloseIcon size="sm" onClick={onDelete} />
				</CssDeleteWrapper>
			)}
			<CssInnerWrapper className="drill-card--inner-wrapper" onClick={() => onClick(drill)}>
				<CssDrillChip>
					<DrillChip drill={getDrillByType(drill.type)} />
				</CssDrillChip>
				<CssDrillTag quality={drill.quality}>
					<Typography variant="body3">{getDrillQualityText(drill.quality)}</Typography>
				</CssDrillTag>
				<CssDrillImageWrapper imageUrl={imageUrl} />
				<DrillContent
					name={drill.name}
					primaryAbilities={drill.primaryAbilities}
					secondaryAbilities={drill.secondaryAbilities}
				/>
				<DrillFooter
					conditionCost={drill.conditionCost}
					maxPlayers={drill.rules.maxPlayers}
					playerCost={drill.rules.trainingTokenCostPerPlayer}
					selectedPlayerCount={selectedPlayerCount}
				/>
			</CssInnerWrapper>
		</CssDrillWrapper>
	);
};
