import { ClubLogo, CoreButton } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const ClubLogoStyled = styled(ClubLogo)`
	width: 200px;
	height: 200px;
`;

export const ColorButtonStyled = styled(CoreButton)`
	padding: 0px;
	border: none;
	width: 56px;
	height: 56px;
`;

export const SquareStyled = styled.div<{ color: string }>(
	({ color }) => css`
		background: ${color};
		height: 40px;
		width: 40px;
	`
);
