import { Accordion } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const HeaderStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		flex-grow: 0;
		padding: 24px;
	`
);

export const ArrowWrapperStyled = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16px;
	`
);

export const AccordionStyled = styled(Accordion)<{
	contentBorderBottom?: string;
	contentPadding?: string;
	padding?: string;
}>(
	({ contentBorderBottom, contentPadding, padding, theme }) => css`
		& .ExpandableDisplay {
			padding: ${padding ?? "0 24px"};
		}

		& .ExpandableContent {
			border-bottom: 1px solid ${contentBorderBottom ?? theme.palette.greyscale.gondola};
			padding: ${contentPadding ?? 0};
		}
	`
);
