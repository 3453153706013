import { ArrowDownIcon, Row, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

export const RelegationDecorator = memo(() => {
	const theme = useTheme();

	return (
		<Row gap={24} alignItems="center">
			<ArrowDownIcon size="sm" color={theme.palette.system.amaranth} />
			<Typography variant="body2">RELEGATION</Typography>
			<ArrowDownIcon size="sm" color={theme.palette.system.amaranth} />
		</Row>
	);
});
