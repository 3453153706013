import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { mslService } from "../dependencies";

export const MSL_MUTABLE_QUERIES = {
	join: () => ({
		mutationKey: [BaseServerStateKeys.MSL, "join"],
		mutationFn: (teamId: MetaSoccerId<IdType.Team>) => mslService.join(teamId)
	})
};
