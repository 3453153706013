export const getTimeIntegersFromMilliseconds = (milliseconds: number, showSeconds = false): string => {
	let seconds = Math.floor(milliseconds / 1000);
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.abs(Math.floor(hours / 24));

	seconds = seconds % 60;
	minutes = Math.abs(minutes % 60);
	hours = Math.abs(hours % 24);
	seconds = seconds === 0 ? 0 : Math.abs(seconds);

	const output = ` ${days}d ${hours}h ${minutes}m ${showSeconds ? `${seconds}s` : ""}`;

	return output;
};
