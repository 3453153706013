import { PlayerCard } from "@/modules/shared/components";
import styled, { css } from "styled-components";

export const CssPlayersStepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	overflow-y: hidden;
	height: 100%;
`;

export const CssTogglebarWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		padding: 16px 12px;
		border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
		overflow-x: auto;

		${theme.breakpoints.up("desktopMin")} {
			padding: 16px 24px;
		}
	`
);

export const CssTogglebar = styled.div(
	({ theme }) => css`
		flex: 1 1 0%;
		display: flex;
		gap: 12px;
	`
);

export const CssSelectAllWrapper = styled.div`
	display: flex;
	padding: 12px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
	gap: 32px;
`;

export const CssListWrapper = styled.div(
	({ theme }) => css`
		padding: 16px 12px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: minmax(min-content, max-content);
			gap: 24px;
		}
	`
);

export const CssNoPlayersWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
		height: 100%;
	`
);

export const CssSelectionInfo = styled.div`
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
`;

export const CssFooterActions = styled.div(
	({ theme }) => css`
		padding: 16px 12px;
		display: flex;
		gap: 16px;
		border-top: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};

		& > button {
			width: 100%;
		}

		${theme.breakpoints.up("desktopMin")} {
			justify-content: flex-end;

			& > button {
				width: auto;
			}
		}
	`
);

export const CssPlayerCardWithHover = styled(PlayerCard)(
	({ theme }) => css`
		transition-property: transform, background;
		transition-duration: var(--hover-transition-duration);
		transition-timing-function: var(--hover-transition-curve);

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);
				background: var(--hover-background);
			}
		}
	`
);

export const CssSortHeaderWrapper = styled.div(
	({ theme }) => css`
		width: 180px;
		position: relative;
		top: -2px;

		${theme.breakpoints.up("desktopMin")} {
			top: -18px;
		}

		& > * {
			max-height: 32px;
			width: 180px;
			position: fixed;
			z-index: 8;
			font-family: ${({ theme }) => theme.fonts.spaceGrotesk};
		}
	`
);
