import { useIsMobile } from "@/layouts/context";
import { getMatchHour } from "@/modules/shared/helpers";
import { InfoIcon, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssIconWrapper, CssPvpMatchDateWrapper, CssTimeTypography } from "./PvpMatchDate.styled";
import { getMatchDay } from "./helpers";

interface PvpMatchDateProps {
	date: number;
	notices: string[];
	showNotices?: boolean;
}
export const PvpMatchDate: FC<PvpMatchDateProps> = ({ date, notices, showNotices = false }) => {
	const isMobile = useIsMobile();
	const theme = useTheme();
	return (
		<CssPvpMatchDateWrapper>
			{notices.length > 0 && isMobile && showNotices && (
				<CssIconWrapper>
					<InfoIcon size="md" color={theme.palette.system.amaranth} />
				</CssIconWrapper>
			)}
			<Typography variant="h5">VS</Typography>
			<Typography variant="label2" light>
				{getMatchDay(date)}
			</Typography>
			<CssTimeTypography variant="subtitle2">{getMatchHour(date)}</CssTimeTypography>
		</CssPvpMatchDateWrapper>
	);
};
