import {
	HttpClient,
	IdType,
	LastTournamentMatch,
	MetaSoccerId,
	Response,
	TournamentDetailedStanding,
	TournamentDivisionSimplified,
	TournamentReward,
	TournamentStructureEdition,
	TournamentTeamStats
} from "@metasoccer/metasoccer-types";

export class DeprecatedHomeService {
	constructor(private readonly httpService: HttpClient) {}

	async getAllStats(
		teamId: MetaSoccerId<IdType.Team>,
		tournamentId: MetaSoccerId<IdType.Tournament>
	): Promise<TournamentTeamStats> {
		let response: Response<TournamentTeamStats>;
		try {
			response = await this.httpService.get<TournamentTeamStats>(`/tournament/${tournamentId}/stats/${teamId}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getTournamentRewards(
		teamId: MetaSoccerId<IdType.Team>,
		tournamentId: MetaSoccerId<IdType.Tournament>
	): Promise<TournamentReward[]> {
		let response: Response<{ tournamentRewardsDTO: TournamentReward[] }>;
		try {
			response = await this.httpService.get<{ tournamentRewardsDTO: TournamentReward[] }>(
				`/tournament/${tournamentId}/rewards/${teamId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data.tournamentRewardsDTO;
	}

	async getLastTournamentMatchesByTeamId(teamId: MetaSoccerId<IdType.Team>): Promise<LastTournamentMatch[]> {
		let response: Response<{ matches: LastTournamentMatch[] }>;
		try {
			response = await this.httpService.get<{ matches: LastTournamentMatch[] }>(
				`/tournament-view/last-matches/${teamId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data.matches;
	}

	async getLastTournamentMatches(): Promise<LastTournamentMatch[]> {
		let response: Response<{ matches: LastTournamentMatch[] }>;
		try {
			response = await this.httpService.get<{ matches: LastTournamentMatch[] }>("/tournament-view/last-matches");
		} catch (e) {
			throw e;
		}

		return response.data.matches;
	}

	async getDetailedStandings(teamId: MetaSoccerId<IdType.Team>): Promise<TournamentDetailedStanding> {
		let response: Response<TournamentDetailedStanding>;
		try {
			response = await this.httpService.get<TournamentDetailedStanding>(
				`/tournament-view/detailed-standing/of-interest/${teamId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getTournamentStructureEditions(
		tournamentId: MetaSoccerId<IdType.Tournament>
	): Promise<TournamentStructureEdition[]> {
		let response: Response<{ tournamentId: string; editions: TournamentStructureEdition[] }>;
		try {
			response = await this.httpService.get<{ tournamentId: string; editions: TournamentStructureEdition[] }>(
				`/tournament-view/detailed-standing/structure/${tournamentId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data.editions;
	}

	async getDetailedStandingsFiltered(
		tournamentId: MetaSoccerId<IdType.Tournament>,
		editionId?: number,
		divisionId?: string,
		groupId?: number
	): Promise<TournamentDetailedStanding> {
		let response: Response<TournamentDetailedStanding>;
		try {
			response = await this.httpService.get<TournamentDetailedStanding>(
				`/tournament-view/detailed-standing/${tournamentId}/${editionId}/${divisionId}/${groupId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getRewardsByTournamentAndEdition(
		tournamentId: MetaSoccerId<IdType.Tournament>,
		editionId?: number
	): Promise<TournamentDivisionSimplified[]> {
		let response: Response<{ divisions: TournamentDivisionSimplified[] }>;
		try {
			response = await this.httpService.get<{ divisions: TournamentDivisionSimplified[] }>(
				`/tournament-view/divisions/rewards/${tournamentId}/${editionId}`
			);
		} catch (e) {
			throw e;
		}

		return response.data.divisions;
	}
}
