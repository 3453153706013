import styled from "styled-components";

export const CssTournamentAlertWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	height: "40px";
	align-items: center;
	width: 100%;
	border-left: 4px solid ${({ theme }) => theme.palette.system.cornflowerBlue};
	background-color: ${({ theme }) => theme.palette.tint.cornflowerBlue30};
	padding: 12px 16px;
	margin: 24px 0px;
`;
