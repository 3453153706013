import { persistentStorageService } from "@/dependencies";
import { useServerState } from "@/modules/core/react-query/hooks";
import { Loading } from "@/modules/shared/components";
import { SELECTED_TEAM_STORAGE_KEY } from "@/modules/shared/constants";
import { useGlobalState, useSetGlobalState } from "@/modules/shared/context";
import { TEAM_QUERIES } from "@/modules/team";
import { CheckIcon, ClubLogo, Modal, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { useTheme } from "styled-components";
import { CssContent, CssLoading, CssTeamContent, CssTeamWrapper } from "./TeamsSelectorSheet.styled";

export interface TeamsSelectorSheetProps {
	isOpen: boolean;
	onClose: () => void;
}

export const TeamsSelectorSheet: FC<TeamsSelectorSheetProps> = ({ isOpen, onClose }) => {
	const { address, selectedTeam } = useGlobalState();
	const setGlobalState = useSetGlobalState();
	const theme = useTheme();
	const { data: managedTeams = [] } = useServerState(TEAM_QUERIES.getManagedTeams(address!), {
		enabled: !!address
	});

	const handleOnChange = (teamId: string) => () => {
		const findSelectedTeam = managedTeams.find((managedTeam) => managedTeam.id === teamId);

		if (findSelectedTeam) {
			persistentStorageService.save(SELECTED_TEAM_STORAGE_KEY, findSelectedTeam.id);
			setGlobalState((prevState) => ({ ...prevState, selectedTeam: findSelectedTeam }));
		}
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Select a team">
			<CssContent>
				{managedTeams.length === 0 ? (
					<CssLoading>
						<Loading />
					</CssLoading>
				) : (
					managedTeams.map((team) => (
						<CssTeamWrapper key={team.id} onClick={handleOnChange(team.id)}>
							<CssTeamContent>
								<ClubLogo
									name={team?.clubLogo.name}
									color={team?.clubLogo.color}
									url={team?.clubLogo.url}
									size="xs"
								/>
								<Typography variant="buttonLg">{team.name}</Typography>
							</CssTeamContent>
							{selectedTeam?.id === team.id && (
								<CheckIcon size="sm" color={theme.palette.system.perfume} />
							)}
						</CssTeamWrapper>
					))
				)}
			</CssContent>
		</Modal>
	);
};
