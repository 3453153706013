import { HttpClient, InGameToken, InGameTokenBalance, InGameTokenTx } from "@metasoccer/metasoccer-types";

export class InGameTokenService {
	constructor(private readonly httpService: HttpClient) {}

	async getKnowledgeBalance(): Promise<InGameTokenBalance> {
		try {
			const { data } = await this.httpService.get<InGameTokenBalance>(
				`/in-game-tokens/balance-of?currency=${InGameToken.SCOUT_KNOWLEDGE}`
			);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async getKnowledgeTransactions(): Promise<InGameTokenTx[]> {
		try {
			const { data } = await this.httpService.get<InGameTokenTx[]>(
				`/in-game-tokens/txs?currency=${InGameToken.SCOUT_KNOWLEDGE}`
			);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async getTrainingBalance(): Promise<InGameTokenBalance> {
		try {
			const { data } = await this.httpService.get<InGameTokenBalance>(
				`/in-game-tokens/balance-of?currency=${InGameToken.TRAINING}`
			);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async getTrainingTransactions(): Promise<InGameTokenTx[]> {
		try {
			const { data } = await this.httpService.get<InGameTokenTx[]>(
				`/in-game-tokens/txs?currency=${InGameToken.TRAINING}`
			);
			return data;
		} catch (e) {
			throw e;
		}
	}
}
