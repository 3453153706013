export enum FeatureFlag {
	AdminFeatures = "admin_features",
	BuyMSUUsingStripe = "buy_msu_using_stripe",
	Christmas = "christmas",
	CreateCustomTournaments = "create_custom_tournaments",
	CustomTournamentsKo2D = "custom_tournaments_ko_2d",
	CustomTournamentsRegistration = "custom_tournaments_registration",
	EmbeddedMarket = "embedded_market",
	EmbeddedStore = "embedded_store",
	FasterScouting = "faster_scouting",
	KoTournamentSpendsCondition = "ko_tournament_spends_condition",
	LandsPreSale = "lands_presale",
	Maintenance = "maintenance",
	MourinhoBeta = "mourinho_beta",
	MourinhoGameStateIntegration = "mourinho_game_state_integration",
	MourinhoInjuries = "mourinho_injuries",
	MourinhoSpendsCondition = "mourinho_spends_condition",
	PlayToAirdrop = "play_to_airdrop",
	PlayToAirdropGameplay = "play_to_airdrop_gameplay",
	PlayToAirdropGameplayPhase2 = "play_to_airdrop_gameplay_phase_ii",
	PlayToAirdropGameplayPhase3 = "play_to_airdrop_gameplay_phase_iii",
	PlayToAirdropGameplayPhase4 = "play_to_airdrop_gameplay_phase_iv",
	PlayToAirdropGameplayPhase5 = "play_to_airdrop_gameplay_phase_v",
	PlayToAirdropGameplayPhase6 = "play_to_airdrop_gameplay_phase_vi",
	ScoutingRevamp = "scouting_revamp",
	ShowDivisionsTab = "show_divisions_tab",
	SponsorTournaments = "sponsor_tournaments",
	UseAssets2 = "use_assets_2",
	UserMenuViewTutorial = "user_menu_view_tutorial"
}
