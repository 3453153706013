import { useIsMobile } from "@/layouts/context";
import { TapbarsLayout } from "@/modules/shared/components";
import { PrizePoolValues } from "@/modules/shared/components/PrizePool/components";
import { defineIf } from "@/modules/shared/utils";
import { Col, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import getMSLDecorators from "../../utils/getMSLDecorators";
import getMSLRankBorderColorsWithDivision from "../../utils/getMSLRankBorderColorsWithDivisions";
import getMSLRewards from "../../utils/getMSLRewards";

export const InfoRewards = memo(() => {
	const theme = useTheme();
	const isMobile = useIsMobile();
	const isDesktop = !isMobile;

	return (
		<Col style={{ width: "100%", height: "100%" }}>
			{isDesktop && <Typography variant="h6">Rewards</Typography>}
			<TapbarsLayout
				tabsPadding={defineIf(isDesktop, () => "20px 0")}
				tabsSize="md"
				tabs={"ABCD".split("").map((division, index) => ({
					key: division,
					title: "Division " + division,
					render: () => (
						<PrizePoolValues
							borders={getMSLRankBorderColorsWithDivision(index, theme.palette)}
							decorators={getMSLDecorators(index)}
							rewards={getMSLRewards(index)}
						/>
					)
				}))}
			/>
		</Col>
	);
});
