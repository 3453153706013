import {
	InfoPill,
	NumberChip,
	RoleChip,
	RoleTypography,
	Row,
	Typography,
	getRoleColor
} from "@metasoccer/metasoccer-ds";
import { Covering, FeatureFlag, Player, PlayerSpecificRole } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { FC } from "react";
import { useTheme } from "styled-components";
import { useSelectedTeamState } from "../../context";
import { SpecialAbilities } from "../SpecialAbilities";
import {
	CssPlayerCardWrapper,
	CssPlayerContent,
	CssPlayerImage,
	CssVerticalBackground,
	CssVerticalBackgroundOverlay,
	CssVerticalContent,
	CssVerticalInfoWrapper,
	CssVerticalNameWrapper,
	CssVerticalPlayerInfo,
	CssVerticalQualityPotential,
	CssVerticalSkillPotentialRoleWrapper,
	CssVerticalTop,
	CssVerticalWrapper
} from "./PlayerCard.styled";
import { PlayerBottomPart, PlayerUpperPart } from "./components";

type PlayerMode = "horizontal" | "vertical";

export interface PlayerCardProps {
	className?: string;
	isActive?: boolean;
	isForbiddenToBeSelected?: boolean;
	isSelectable?: boolean;
	mode?: PlayerMode;
	player: Player;
	selected?: boolean;
	slotId?: string;
	slotRole?: Covering;
	slotSpecificRoles?: PlayerSpecificRole[];
	onClick?: () => void;
}

export const PlayerCard: FC<PlayerCardProps> = ({
	className,
	isActive = false,
	isForbiddenToBeSelected = false,
	isSelectable = true,
	mode = "horizontal",
	player,
	selected = false,
	slotId,
	slotRole,
	slotSpecificRoles,
	onClick = () => {}
}) => {
	const {
		age,
		condition,
		id,
		imageName,
		lastName,
		name,
		overall: currentOverall,
		potential: potentialOverall,
		role,
		specialAbilities,
		specificRole
	} = player;

	const { getPlayerCards, isPlayerInjured, isPlayerRegisteredForNextMatch } = useSelectedTeamState();

	const playerCards = getPlayerCards(player.id);
	const playerInjured = isPlayerInjured(player.id);
	const playerNotRegisteredForNextMatch = !isPlayerRegisteredForNextMatch(player.id);

	const theme = useTheme();

	const indicatorColor =
		slotRole && slotRole !== "None"
			? theme.palette.system[getRoleColor(slotRole)]
			: slotId
			? theme.palette.greyscale.gray
			: undefined;

	const shortName = `${name.slice(0, 1)}. ${lastName}`;

	const useAssets2 = useFlag(FeatureFlag.UseAssets2);

	const playerImage = useAssets2
		? `https://assets2.metasoccer.com/file/assets2-metasoccer/players/training/${imageName}`
		: `https://assets.metasoccer.com/cdn-cgi/image/width=600,height=240,fit=crop,gravity=1x0,quality=90,metadata=none,format=auto/players/originals/${imageName}`;

	const verticalImage = useAssets2
		? `https://assets2.metasoccer.com/file/assets2-metasoccer/players/assetsIndex/${imageName}`
		: `https://assets.metasoccer.com/cdn-cgi/image/width=600,height=300,fit=crop,gravity=1x0,quality=90,metadata=none,format=auto/players/originals/${imageName}`;

	if (mode === "vertical") {
		return (
			<CssVerticalWrapper className={className} indicatorColor={indicatorColor} isActive={isActive}>
				<CssVerticalBackground imageUrl={verticalImage} />
				<CssVerticalBackgroundOverlay />
				<CssVerticalContent>
					<CssVerticalTop>
						<CssVerticalSkillPotentialRoleWrapper>
							<CssVerticalQualityPotential>
								<Typography variant="h4">{currentOverall}</Typography>
								<Typography variant="h6" light>
									/{potentialOverall}
								</Typography>
							</CssVerticalQualityPotential>
							<Row gap={4} alignItems="center">
								{slotRole && slotSpecificRoles && (
									<RoleTypography covering={slotRole} specificRoles={slotSpecificRoles} />
								)}
								{!slotRole && slotId && (
									<Typography variant="buttonMd" light>
										{slotId}
									</Typography>
								)}
								<RoleChip covering={role} specificRole={specificRole} />
							</Row>
						</CssVerticalSkillPotentialRoleWrapper>
						<CssVerticalPlayerInfo>
							<CssVerticalNameWrapper>
								<NumberChip size="sm" number={id} />
								<Typography variant="h5">{shortName}</Typography>
							</CssVerticalNameWrapper>
							<CssVerticalInfoWrapper>
								<InfoPill title="Age" size="md">
									<Typography variant="body1">{age}</Typography>
								</InfoPill>
								<SpecialAbilities specialAbilities={specialAbilities} />
							</CssVerticalInfoWrapper>
						</CssVerticalPlayerInfo>
					</CssVerticalTop>
					{/*<CssVerticalBottom></CssVerticalBottom>*/}
				</CssVerticalContent>
			</CssVerticalWrapper>
		);
	}

	return (
		<CssPlayerCardWrapper
			className={className}
			isActive={isActive}
			isForbiddenToBeSelected={isForbiddenToBeSelected}
			isSelectable={isSelectable}
			indicatorColor={indicatorColor}
			selected={selected}>
			<CssPlayerImage imageUrl={playerImage} onClick={onClick} />
			<CssPlayerContent>
				<div onClick={onClick}>
					<PlayerUpperPart
						age={age}
						condition={condition}
						currentOverall={currentOverall}
						isForbiddenToBeSelected={isForbiddenToBeSelected}
						potentialOverall={potentialOverall}
						role={role}
						shortName={shortName}
						slotId={slotId}
						slotRole={slotRole}
						slotSpecificRoles={slotSpecificRoles}
						specialAbilities={specialAbilities}
						specificRole={specificRole}
					/>
				</div>
				<PlayerBottomPart
					condition={condition}
					playerInjured={playerInjured}
					playerCards={playerCards}
					playerNotRegisteredForNextMatch={playerNotRegisteredForNextMatch}
				/>
			</CssPlayerContent>
		</CssPlayerCardWrapper>
	);
};
