import { ArrowRightIcon, Row, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

interface ContinueButtonProps {
	text: string;
}

export const ContinueButton = memo<ContinueButtonProps>(({ text }) => {
	return (
		<Row gap={8}>
			<Typography variant="buttonMd">{text}</Typography>
			<ArrowRightIcon size="sm" />
		</Row>
	);
});
