import { useGlobalState } from "@/modules/shared/context";
import { TournamentDetailedStanding } from "@metasoccer/metasoccer-types";
import { FC, useMemo } from "react";
import { CssListWrapper, CssStandingsWrapper } from "./StandingsList.styled";
import { StandingRow } from "./components";

interface StandingsListProps {
	borderColors?: string[];
	standing: TournamentDetailedStanding;
	sliceTeams?: boolean;
}

export const StandingsList: FC<StandingsListProps> = ({ borderColors = [], standing, sliceTeams = true }) => {
	const { selectedTeam } = useGlobalState();

	const rankedTeams = standing.rankedTeams;

	const rankedTeamsFiltered = useMemo(() => {
		const rankedTeamsOrderedByPosition = rankedTeams.sort((teamA, teamB) => teamA.position - teamB.position);
		const myTeamIndex = rankedTeamsOrderedByPosition.findIndex((team) => team.id === selectedTeam?.id);

		if (myTeamIndex === -1) {
			return sliceTeams ? rankedTeams.slice(0, 3) : rankedTeams;
		}

		if (myTeamIndex === 0 && sliceTeams) {
			return rankedTeamsOrderedByPosition.slice(0, 3);
		}

		if (myTeamIndex + 1 === rankedTeams.length && sliceTeams) {
			return rankedTeamsOrderedByPosition.slice(myTeamIndex - 2, myTeamIndex + 1);
		}

		return sliceTeams ? rankedTeamsOrderedByPosition.slice(myTeamIndex - 1, myTeamIndex + 2) : rankedTeams;
	}, [rankedTeams, selectedTeam, sliceTeams]);

	return (
		<CssStandingsWrapper>
			<CssListWrapper>
				{rankedTeamsFiltered.map((rankedTeam) => (
					<StandingRow
						key={rankedTeam.id}
						borderColor={borderColors[rankedTeam.position - 1]}
						rankedTeam={rankedTeam}
					/>
				))}
			</CssListWrapper>
		</CssStandingsWrapper>
	);
};
