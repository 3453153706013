import { BaseServerStateKeys } from "@/modules/shared/constants";
import { clubService } from "../dependencies";

export const CLUB_QUERIES = {
	getClubByOwner: (ownerAddress: string) => ({
		queryKey: [BaseServerStateKeys.CLUB, "getClubByOwner", ownerAddress],
		queryFn: () => clubService.getClubByOwner(ownerAddress)
	}),
	getClubOverviewByOwner: (ownerAddress: string) => ({
		queryKey: [BaseServerStateKeys.CLUB, "getClubOverviewByOwner", ownerAddress],
		queryFn: () => clubService.getClubOverviewByOwner(ownerAddress)
	})
};
