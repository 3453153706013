import styled, { css } from "styled-components";

export const CssWrapper = styled.div<{ background: string }>(
	({ background, theme }) => css`
		display: flex;
		background: url(${background}) no-repeat;
		background-size: 100%;
		width: 100%;

		box-sizing: border-box;
		max-width: 100%;
	`
);

export const CssWrapperContent = styled.div<{ aspectRatio: number }>(
	({ aspectRatio, theme }) => css`
		width: 100%;
		padding-bottom: ${aspectRatio * 100}%;
		position: relative;
	`
);

export const CssPitch = styled.div(
	({ theme }) => css`
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	`
);
