import styled, { css } from "styled-components";

export const CssNextMatchCardHeaderWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		width: 100%;
		position: relative;
	`
);

export const CssTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 3px;
`;

export const CssIconWrapper = styled.div`
	position: absolute;
	right: 0;
`;
