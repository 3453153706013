import React, { FC, useState } from "react";
import {
	CssCardWrapper,
	CssContent,
	CssImage,
	CssLoadingBar,
	CssLoadingWrapper,
	CssVerticalBackground,
	CssVerticalBackgroundOverlay,
	CssVerticalContent,
	CssVerticalLoadingBar,
	CssVerticalLoadingWrapper,
	CssVerticalWrapper
} from "./GeneratingAttributesCard.styled";
import { Typography } from "@metasoccer/metasoccer-ds";
import { useSpring } from "react-spring";

type ScoutMode = "horizontal" | "vertical";

export interface GeneratingAttributesCardProps {
	mode?: ScoutMode;
	className?: string;
}

export const GeneratingAttributesCard: FC<GeneratingAttributesCardProps> = ({ mode = "horizontal", className }) => {
	const [flipDirection, setFlipDirection] = useState(false);
	const animatedBarStyleSpring = useSpring({
		from: {
			width: "1%"
		},
		to: {
			width: "35%"
		},
		reverse: flipDirection,
		onRest: () => setFlipDirection(!flipDirection),
		config: {
			duration: 1600
		}
	});

	if (mode === "vertical") {
		return (
			<CssVerticalWrapper className={className}>
				<CssVerticalBackground />
				<CssVerticalBackgroundOverlay />
				<CssVerticalLoadingWrapper>
					<CssVerticalLoadingBar style={animatedBarStyleSpring} />
				</CssVerticalLoadingWrapper>
				<CssVerticalContent>
					<Typography variant="body2">Generating attributes...</Typography>
				</CssVerticalContent>
			</CssVerticalWrapper>
		);
	}

	return (
		<CssCardWrapper className={className}>
			<CssImage />
			<CssLoadingWrapper>
				<CssLoadingBar style={animatedBarStyleSpring} />
			</CssLoadingWrapper>
			<CssContent>
				<Typography variant="h6">Generating attributes...</Typography>
			</CssContent>
		</CssCardWrapper>
	);
};
