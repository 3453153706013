import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			border-bottom: none;
			height: 100%;
		}
	`
);
