import styled, { css } from "styled-components";

export const CssWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-height: 100%;

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
			gap: 0;
			padding: 0;
		}
	`
);

export const CssPitchAndBenchOuterWrapper = styled.div(
	({ theme }) => css`
		flex: 1;
		overflow-y: auto;
	`
);

export const CssPitchAndBenchWrapper = styled.div<{ variant: "small" | "default" }>(
	({ variant, theme }) => css`
		display: flex;
		flex-direction: column;
		height: fit-content;
		gap: 20px;
		overflow: hidden;
		padding: ${variant === "default" ? "0 24px 24px 24px" : "0"};

		${theme.breakpoints.up("desktopMin")} {
			gap: 16px;
		}
	`
);

export const CssSquadWrapper = styled.div(
	({ theme }) => css`
		flex: 1;
	`
);
