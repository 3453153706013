export const MetaSoccerStore = {
  "name": "MetaSoccerStore",
  "address": "0xE06E5605AF7e402FeE820B965d39C2eB44823730",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_beneficiary"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Airdrop",
      "inputs": [
        {
          "type": "uint256",
          "name": "airdropType",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "quantity",
          "indexed": false
        },
        {
          "type": "address[]",
          "name": "addresses",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Paused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "PurchaseCompleted",
      "inputs": [
        {
          "type": "uint256",
          "name": "id",
          "indexed": true
        },
        {
          "type": "address",
          "name": "buyer",
          "indexed": true
        },
        {
          "type": "tuple",
          "name": "purchase",
          "indexed": false,
          "components": [
            {
              "type": "uint256",
              "name": "id"
            },
            {
              "type": "address",
              "name": "buyer"
            },
            {
              "type": "address",
              "name": "paymentToken"
            },
            {
              "type": "uint256",
              "name": "price"
            },
            {
              "type": "string",
              "name": "description"
            },
            {
              "type": "uint256",
              "name": "timestamp"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Unpaused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "function",
      "name": "AIRDROP_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "PAUSER_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "SIGN_PURCHASE_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "airdrop",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_airdropType"
        },
        {
          "type": "uint256",
          "name": "_quantity"
        },
        {
          "type": "address[]",
          "name": "_addresses"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "beneficiary",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getPurchaseIdsByBuyer",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_buyer"
        }
      ],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getPurchaseIndexesByBuyer",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_buyer"
        }
      ],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getPurchasesByBuyerLength",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_buyer"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getPurchasesLength",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMember",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMemberCount",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "paused",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "purchase",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_purchaseId"
        },
        {
          "type": "address",
          "name": "_paymentToken"
        },
        {
          "type": "uint256",
          "name": "_price"
        },
        {
          "type": "string",
          "name": "_description"
        },
        {
          "type": "uint256",
          "name": "_expirationTimestamp"
        },
        {
          "type": "bytes",
          "name": "_signature"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "purchases",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256",
          "name": "id"
        },
        {
          "type": "address",
          "name": "buyer"
        },
        {
          "type": "address",
          "name": "paymentToken"
        },
        {
          "type": "uint256",
          "name": "price"
        },
        {
          "type": "string",
          "name": "description"
        },
        {
          "type": "uint256",
          "name": "timestamp"
        }
      ]
    },
    {
      "type": "function",
      "name": "purchasesByBuyer",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setBeneficiary",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_beneficiary"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPause",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bool",
          "name": "pause"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "withdrawERC20",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    }
  ]
}