import { Outlet, Route, Routes } from "react-router-dom";
import { ShopPage } from "./views";

const ShopRouter = () => {
	return (
		<>
			<Routes>
				<Route path="" element={<ShopPage />} />
			</Routes>
			<Outlet />
		</>
	);
};

export default ShopRouter;
