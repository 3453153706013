import React from "react";
import { HomeContext } from "./HomeContext";

const useHomeState = () => {
	const context = React.useContext(HomeContext);
	if (context === undefined) {
		throw new Error("useHomeState must be used within a HomeContextProvider");
	}
	return context;
};

export { useHomeState };
