import { Covering, PlayerSpecificRole } from "@metasoccer/metasoccer-types";

export const getCoveringBySpecificRole = (specificRole: PlayerSpecificRole): Covering => {
	switch (specificRole) {
		case PlayerSpecificRole.GK:
			return Covering.GK;
		case PlayerSpecificRole.CB:
		case PlayerSpecificRole.LB:
		case PlayerSpecificRole.RB:
		case PlayerSpecificRole.LWB:
		case PlayerSpecificRole.RWB:
			return Covering.DF;
		case PlayerSpecificRole.CAM:
		case PlayerSpecificRole.CDM:
		case PlayerSpecificRole.CM:
		case PlayerSpecificRole.LM:
		case PlayerSpecificRole.RM:
			return Covering.MF;
		case PlayerSpecificRole.CF:
		case PlayerSpecificRole.LF:
		case PlayerSpecificRole.LW:
		case PlayerSpecificRole.RF:
		case PlayerSpecificRole.RW:
		case PlayerSpecificRole.ST:
			return Covering.FW;
		default:
			return Covering.NONE;
	}
};
