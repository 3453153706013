import { persistentStorageService } from "@/dependencies";
import { MatchResultInLocalStorage } from "../types/MatchResultInLocalStorage";
import { getMatchKeyForStorage, MatchTypeLS, Type } from "./getMatchKey";

export function getNotExpiredMatchResultFromLS(
	now: number,
	address: string,
	teamIds: string[],
	matchType: MatchTypeLS
): MatchResultInLocalStorage[] {
	if (teamIds.length) {
		return teamIds
			.map((teamId: string) => {
				const key = getMatchKeyForStorage(Type.RESULT, matchType, address, teamId);
				const itemFromStorage = persistentStorageService.getObject<MatchResultInLocalStorage>(key);

				if (itemFromStorage) {
					if (itemFromStorage.expiration <= now) {
						persistentStorageService.remove(key);
					} else {
						return {
							difficulty: itemFromStorage.difficulty,
							matchResult: itemFromStorage.matchResult,
							startsAt: itemFromStorage.startsAt,
							expiration: itemFromStorage.expiration
						};
					}
				}
				return undefined;
			})
			.filter((matchResult): matchResult is MatchResultInLocalStorage => !!matchResult);
	} else {
		return [];
	}
}
