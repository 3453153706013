import { Response, HttpClient } from "@metasoccer/metasoccer-types";

export class OnBoardingService {
	constructor(private readonly httpService: HttpClient) {}

	async getClubAcronym(name: string): Promise<string> {
		let response: Response<string>;
		try {
			response = await this.httpService.get<string>(`/teams/acronym/${name}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
