import { Col } from "@metasoccer/metasoccer-ds";
import { ReactNode, useEffect, useState } from "react";
import { CardWrapperStyled } from "./CardList.styles";

interface CardListProps<T> {
	children: (item: T) => ReactNode;
	items: T[];
	selectable?: boolean;
	selectMultiple?: boolean;
	getItemId: (item: T) => string;
	onCardClick?: (item: T) => void;
	onSelectionChange?: (item: T[]) => void;
}

export function CardList<T>({
	children,
	items,
	selectable,
	selectMultiple,
	getItemId,
	onCardClick,
	onSelectionChange
}: CardListProps<T>) {
	const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});

	useEffect(() => {
		if (selectable && onSelectionChange) {
			onSelectionChange(items.filter((item) => selectedItems[getItemId(item)]));
		}
	}, [items, selectable, selectedItems, onSelectionChange]);

	return (
		<Col gap={16}>
			{items.map((item) => {
				const id = getItemId(item);
				return (
					<CardWrapperStyled
						key={id}
						isActive={selectable && selectedItems[id]}
						onClick={() => {
							onCardClick && onCardClick(item);

							setSelectedItems((prevState) => ({
								...(selectMultiple ? prevState : {}),
								[id]: !prevState[id]
							}));
						}}>
						{children(item)}
					</CardWrapperStyled>
				);
			})}
		</Col>
	);
}
