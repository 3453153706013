import React, { FC } from "react";
import { Typography } from "@metasoccer/metasoccer-ds";
import { CssInfoMessageWrapper } from "./InfoMessage.styled";

type InfoMessageType = "info" | "error";

interface InfoMessageProps {
	type?: InfoMessageType;
	message?: string;
}

export const InfoMessage: FC<InfoMessageProps> = ({ type = "info", message }) => {
	return (
		<CssInfoMessageWrapper>
			<Typography variant={"body2"} style={{ color: type === "error" ? "red" : "white" }}>
				{message ?? "There was an error here"}
			</Typography>
		</CssInfoMessageWrapper>
	);
};
