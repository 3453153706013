import { DrillCard } from "@/modules/shared/components";
import { ConnectWalletShirt } from "@/modules/shared/components/ConnectWalletShirt";
import { Drill, TrainingGroup } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import {
	CssTrainingContentImage,
	CssTrainingContentListWrapper,
	CssTrainingContentWrapper
} from "./TrainingContent.styled";

interface TrainingContentProps {
	isLoading: boolean;
	drills: Drill[];
	trainingGroups: TrainingGroup[];
	onTrainingGroupDelete: (index: number) => void;
	onAddDrillsClicked: () => void;
	onDrillClicked: (index: number) => void;
}

export const TrainingContent: FC<TrainingContentProps> = ({
	isLoading,
	drills,
	trainingGroups,
	onTrainingGroupDelete,
	onAddDrillsClicked,
	onDrillClicked
}) => {
	if (isLoading) {
		return <ConnectWalletShirt />;
	}

	if (trainingGroups.length === 0) {
		return (
			<CssTrainingContentWrapper onClick={onAddDrillsClicked}>
				<CssTrainingContentImage
					alt={"Training"}
					src={"https://assets.metasoccer.com/training/add_drill.png"}
				/>
			</CssTrainingContentWrapper>
		);
	}

	return (
		<CssTrainingContentListWrapper>
			{trainingGroups.map((trainingGroup, idx) => {
				const drill: Drill = drills.find((d) => d.id === trainingGroup.drillId)!;
				return (
					<DrillCard
						key={idx}
						drill={drill}
						isDeletable
						onDelete={() => onTrainingGroupDelete(idx)}
						selectedPlayerCount={trainingGroup.playerIds.length}
						onClick={() => onDrillClicked(idx)}
					/>
				);
			})}
		</CssTrainingContentListWrapper>
	);
};
