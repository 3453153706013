import { BaseServerStateKeys } from "@/modules/shared/constants";
import { MetaSoccerId, IdType, FeatureFlag } from "@metasoccer/metasoccer-types";
import { PvPCreateTournamentData } from "../components/PvPCreateTournamentModal/PvPCreateTournamentModal.state";
import { pvpService } from "../dependencies";

export const PVP_MUTABLE_QUERIES = {
	joinTournament: () => ({
		mutationKey: [BaseServerStateKeys.JOIN_PVP_TOURNAMENT],
		mutationFn: ({
			teamId,
			tournamentId
		}: {
			teamId: MetaSoccerId<IdType.Team>;
			tournamentId: MetaSoccerId<IdType.Tournament>;
		}) => pvpService.joinTournament(teamId, tournamentId)
	}),

	leaveTournament: () => ({
		mutationKey: [BaseServerStateKeys.JOIN_PVP_TOURNAMENT],
		mutationFn: ({
			teamId,
			tournamentId
		}: {
			teamId: MetaSoccerId<IdType.Team>;
			tournamentId: MetaSoccerId<IdType.Tournament>;
		}) => pvpService.leaveTournament(teamId, tournamentId)
	}),

	createTournament: () => ({
		mutationKey: [BaseServerStateKeys.CREATE_PVP_TOURNAMENT],
		mutationFn: (tournament: PvPCreateTournamentData) => pvpService.createTournament(tournament)
	}),

	inviteTeams: () => ({
		mutationKey: [BaseServerStateKeys.CUSTOM_TOURNAMENT_INVITE_TEAMS],
		mutationFn: ({
			tournamentId,
			isWhitelisted,
			whitelistedTeams
		}: {
			tournamentId: MetaSoccerId<IdType.Tournament>;
			isWhitelisted: boolean;
			whitelistedTeams: MetaSoccerId<IdType.Team>[];
		}) => pvpService.updateWhitelist(tournamentId, isWhitelisted, whitelistedTeams)
	})
};
