import { inGameTokenService } from "@/dependencies";
import { useAccountGuard } from "@/modules/auth";
import { useMutableServerState } from "@/modules/core/react-query/hooks";
import ERC20Abi from "@/modules/market/module/common/abi/ERC20Abi";
import { Container, Modal } from "@/modules/shared/components";
import { useBalanceContext, useGlobalState, useServerRefresh } from "@/modules/shared/context";
import { useMetaSoccerContract } from "@/modules/shared/hooks/useMetaSoccerContract";
import { getImgUrl } from "@/modules/shared/utils";
import { Button, Row, Typography } from "@metasoccer/metasoccer-ds";
import { ContractName } from "@metasoccer/metasoccer-types";
import { useSigner } from "@thirdweb-dev/react";
import { Contract } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { memo, useCallback, useMemo, useState } from "react";
import { SHOP_MUTABLE_QUERIES } from "../../queries";
import { Product as ProductType } from "../../types";
import { Product } from "../Product";

interface BuyCatalogProps {
	product: ProductType;
}

export const BuyCatalog = memo(({ product }: BuyCatalogProps) => {
	const { executeConnected } = useAccountGuard();

	const [_, serverRefresh] = useServerRefresh();
	const { address } = useGlobalState();
	const { msuTokens } = useBalanceContext();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);

	const { mutateAsync: requestSignature } = useMutableServerState(SHOP_MUTABLE_QUERIES.getPurchaseSignature());

	const signer = useSigner();
	const { contract: storeContract } = useMetaSoccerContract(ContractName.STORE);

	const isLoading = isProcessing || isWaitingForConfirmation;

	const waitForPurchaseConfirmation = useCallback(async (purchaseId) => {
		let confirmed = false;
		while (!confirmed) {
			const txs = await inGameTokenService.getTrainingTransactions();
			if (txs.some((tx) => tx.op === "ADD" && tx.memo?.split(",")?.[2] === `P:${purchaseId}`)) {
				confirmed = true;
			} else {
				await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds before checking again
			}
		}
	}, []);

	const startPurchase = useCallback(async () => {
		if (!address) throw new Error("Address not found");
		if (!signer) throw new Error("Signer not found");
		if (!storeContract) throw new Error("Contract not found");

		try {
			setIsProcessing(true);

			// request backend signature
			const signedMessage = await requestSignature(product.id);

			// allowance
			const paymentTokenContract = new Contract(signedMessage.paymentToken, ERC20Abi, signer);
			const allowedAmount = await paymentTokenContract.allowance(address, storeContract.getAddress());
			const parsedAllowedAmount = parseFloat(formatUnits(allowedAmount, 18));

			if (parsedAllowedAmount < product.price) {
				const tx = await paymentTokenContract.approve(storeContract.getAddress(), "9999999999999999999999999");
				await tx.wait();
			}

			// complete purchase
			await storeContract.call(
				"purchase",
				[
					signedMessage.purchaseId,
					signedMessage.paymentToken,
					signedMessage.price,
					signedMessage.description,
					signedMessage.expirationTimestamp,
					signedMessage.signature
				],
				{ gasLimit: 42000000 }
			);

			setIsProcessing(false);
			setIsWaitingForConfirmation(true);

			// wait for backend confirmation
			await waitForPurchaseConfirmation(signedMessage.purchaseId);

			// refresh
			await serverRefresh();
		} catch (err) {
			console.error(err);
		} finally {
			setIsWaitingForConfirmation(false);
			setIsProcessing(false);
			setIsModalOpen(false);
		}
	}, [signer, storeContract, requestSignature, address, product.id, product.price, waitForPurchaseConfirmation]);

	const mainAction = useMemo(
		() =>
			product.price < msuTokens ? (
				<Row gap={8} flex={1}>
					{!isLoading && (
						<Button appearance="secondary" fullWidth label="No" onClick={() => setIsModalOpen(false)} />
					)}
					<Button
						appearance="primary"
						disabled={isLoading}
						fullWidth
						label="Yes"
						loading={isLoading}
						onClick={() => startPurchase()}
					/>
				</Row>
			) : (
				<Button appearance="primary" fullWidth label="Ok" onClick={() => setIsModalOpen(false)} />
			),
		[isLoading, msuTokens, setIsModalOpen, startPurchase]
	);

	return (
		<>
			<Product
				backgroundColor={product.productCardBackground}
				callToAction={
					<Row gap={8} alignItems="center">
						<img
							alt="MSU"
							src={getImgUrl(product.paymentCurrencyIconUrl.replace("icons/msu", "tokens/mss"), {
								height: 80
							})}
							height="16"
						/>
						<Typography variant="body2" numberOfLines={1} textAlign="center">
							{product.price}
						</Typography>
					</Row>
				}
				iconUrl={getImgUrl(product.productIconUrl, { height: 256 })}
				name={product.name}
				quantity={product.quantity}
				onClick={() => executeConnected(() => setIsModalOpen(true))}
			/>
			<Modal
				isOpen={isModalOpen}
				mainAction={mainAction}
				title={`Buy ${product.quantity} ${product.name}`.replace("  ", "")}
				onClose={() => setIsModalOpen(false)}>
				<Container>
					<Row alignItems="center" justifyContent="center" style={{ minHeight: 64 }}>
						{isProcessing ? (
							<Typography variant="body2" textAlign="center">
								Sign the transaction to complete the purchase
							</Typography>
						) : isWaitingForConfirmation ? (
							<Typography variant="body2" light textAlign="center">
								Waiting for the purchase to be processed...
							</Typography>
						) : product.price < msuTokens ? (
							<Typography variant="body2" textAlign="center">
								Are you sure you want to buy this product?
							</Typography>
						) : (
							<Typography variant="body2" textAlign="center">
								You don't have enough MSU to complete this purchase. Buy some MSU and try again!
							</Typography>
						)}
					</Row>
				</Container>
			</Modal>
		</>
	);
});
