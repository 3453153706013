import { useIsMobile } from "@/layouts/context";
import { PVP_ROUTES } from "@/modules/pvp/pvp.routes";
import { Col, Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CssCreateTournamentButton } from "./CreateTournamentButton.styled";

export const CreateTournamentButton: FC = () => {
	const navigate = useNavigate();
	const handleClick = async () => {
		navigate(PVP_ROUTES.CREATE.buildPath());
	};

	const isMobile = useIsMobile();
	return (
		<>
			<Tooltip>
				{{
					popper: (
						<Col gap={"8px"}>
							<Typography variant="body2">Disabled</Typography>
							<Typography variant="description" light>
								"Create Tournament" is temporaly disabled.
							</Typography>
						</Col>
					),
					content: (
						<div>
							<CssCreateTournamentButton
								disabled={true}
								onClick={handleClick}
								appearance={isMobile ? "secondary" : "primary"}
								fullWidth={isMobile}>
								{"Create Tournament"}
							</CssCreateTournamentButton>
						</div>
					)
				}}
			</Tooltip>
		</>
	);
};
