import { Typography, TypographyProps } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export interface ConditionLabelProps extends TypographyProps {
	condition: number;
}

export const ConditionLabel = styled(Typography)<ConditionLabelProps>(
	({ condition, theme }) => css`
		${condition > 0 &&
		condition <= 24 &&
		css`
			color: ${theme.palette.system.amaranth};
		`};

		${condition >= 25 &&
		condition <= 49 &&
		css`
			color: ${theme.palette.system.neonCarrot};
		`};

		${condition >= 50 &&
		condition <= 74 &&
		css`
			color: ${theme.palette.system.laserLemon};
		`};

		${condition >= 75 &&
		condition <= 98 &&
		css`
			color: ${theme.palette.system.pastelGreen};
		`};

		${condition >= 99 &&
		css`
			color: ${theme.palette.system.viking};
		`};
	`
);
