import { useIsMobile } from "@/layouts/context";
import { useMutableServerState } from "@/modules/core/react-query/hooks";
import { MSL_MUTABLE_QUERIES } from "@/modules/msl/queries/mutableQueries";
import { useGlobalState } from "@/modules/shared/context";
import { Button } from "@metasoccer/metasoccer-ds";
import { memo, useCallback } from "react";

export interface JoinButtonProps {
	onSuccess: () => void;
}

export const JoinButton = memo<JoinButtonProps>(({ onSuccess }) => {
	const { selectedTeam } = useGlobalState();

	const { mutateAsync: joinMSL, isPending: isLoading } = useMutableServerState(MSL_MUTABLE_QUERIES.join());

	const joinMSLAndExecuteOnClick = useCallback(async () => {
		try {
			await joinMSL(selectedTeam?.id);
			onSuccess();
		} catch (err) {
			console.error(err);
		}
	}, [selectedTeam, onSuccess]);

	const isMobile = useIsMobile();

	return (
		<Button
			variant="filled"
			fullWidth={isMobile}
			label="Join MSL"
			loading={isLoading}
			onClick={joinMSLAndExecuteOnClick}
		/>
	);
});
