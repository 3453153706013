import React from "react";
import { BalanceContext } from "../BalanceContext";

const useBalanceContext = () => {
	const context = React.useContext(BalanceContext);
	if (context === undefined) {
		throw new Error("useBalanceContext must be used within a BalanceContextProvider");
	}
	return context;
};

export { useBalanceContext };
