import { useGlobalState } from "@/modules/shared/context";
import { formatMoney } from "@/modules/shared/utils";
import { ClubLogo, Typography } from "@metasoccer/metasoccer-ds";
import { MatchedTeam } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import {
	CssAirdropRankingItemContent,
	CssAirdropRankingItemWrapper,
	CssClubContent,
	CssLeftPartWrapper,
	CssPositionTypography,
	CssRightPartWrapper
} from "./AirdropRankingItem.styles";

interface AirdropRankingItemProps {
	team: MatchedTeam;
	position: number;
	points: number;
}

export const AirdropRankingItem: FC<AirdropRankingItemProps> = ({ team, position, points }) => {
	const { selectedTeam } = useGlobalState();

	const isMyTeam = selectedTeam?.id === team.id;

	return (
		<CssAirdropRankingItemWrapper>
			<CssAirdropRankingItemContent isMyTeam={isMyTeam}>
				<CssLeftPartWrapper>
					<CssPositionTypography variant="body2">{position}</CssPositionTypography>
					<CssClubContent>
						<ClubLogo
							color={team.clubLogo.color}
							name={team.clubLogo.name}
							url={team.clubLogo.url}
							size="xs"
						/>
						<Typography variant="body2">{team.name}</Typography>
					</CssClubContent>
				</CssLeftPartWrapper>
				<CssRightPartWrapper>
					<Typography variant="body2">{formatMoney(points)}</Typography>
				</CssRightPartWrapper>
			</CssAirdropRankingItemContent>
		</CssAirdropRankingItemWrapper>
	);
};
