import { useMSLState } from "@/modules/msl";
import { ContinueButton, NextMatchPreview, PrepareMatchModal } from "@/modules/shared/components";
import { memo, useState } from "react";
import { BackgroundStyled, ClubLogoStyled, ColStyled, WrapperStyled } from "./NextMatchBanner.styles";
import { NextMatchCardHeader } from "./components/NextMatchCardHeader";

export const NextMatchBanner = memo(() => {
	const { group, nextMatch } = useMSLState();
	if (!group) throw new Error("No group");
	if (!nextMatch) throw new Error("No next match");
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<WrapperStyled onClick={() => setIsModalOpen(true)}>
				<BackgroundStyled>
					<ClubLogoStyled
						color={nextMatch.teams.home.clubLogo.color}
						name={nextMatch.teams.home.clubLogo.name}
						url={nextMatch.teams.home.clubLogo.url}
					/>
					<ClubLogoStyled
						reverse
						color={nextMatch.teams.away.clubLogo.color}
						name={nextMatch.teams.away.clubLogo.name}
						url={nextMatch.teams.away.clubLogo.url}
					/>
				</BackgroundStyled>
				<ColStyled>
					<NextMatchCardHeader tournamentName={group.name} notices={nextMatch?.notices} />
					<NextMatchPreview
						matchDate={nextMatch.match.date}
						homeTeamInfo={nextMatch.teams.home}
						awayTeamInfo={nextMatch.teams.away}
						notices={nextMatch.notices}
						showNotices
					/>
					<ContinueButton text="Prepare match" />
				</ColStyled>
			</WrapperStyled>
			{isModalOpen && (
				<PrepareMatchModal isOpen={isModalOpen} nextMatch={nextMatch} onClose={() => setIsModalOpen(false)} />
			)}
		</>
	);
});
