import { AddIcon, IconButton, RoleTypography, Row, Typography } from "@metasoccer/metasoccer-ds";
import { Covering, PlayerSpecificRole } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { CssWrapper } from "./NoPlayer.styles";

interface NoPlayerProps {
	disabled?: boolean;
	showSlotId?: boolean;
	slotId: string;
	slotRole: Covering;
	slotSpecificRoles: PlayerSpecificRole[];
	onClick: () => void;
}

export const NoPlayer = memo<NoPlayerProps>(
	({ disabled, showSlotId, slotId, slotRole, slotSpecificRoles, onClick }) => {
		return (
			<CssWrapper>
				<IconButton appearance="primary" disabled={disabled} icon={AddIcon} size="sm" onClick={onClick} />
				<Row gap={4} alignItems="center">
					{showSlotId && (
						<Typography variant="buttonMd" light>
							{slotId}
						</Typography>
					)}
					{slotRole && slotSpecificRoles && (
						<RoleTypography covering={slotRole} specificRoles={slotSpecificRoles} />
					)}
				</Row>
			</CssWrapper>
		);
	}
);
