export const MARKET_ROUTES = {
	ROOT: {
		path: "/market",
		pathLong: "/marketplace",
		buildPath: (tab?: "players" | "scouts") => `${MARKET_ROUTES.ROOT.path}${tab ? `&tab=${tab}` : ""}`,
		buildLongPath: () => MARKET_ROUTES.ROOT.pathLong
	},
	BUNDLES: {
		path: "/bundle",
		buildPath: (bundleId: number) => {
			const suffix = `/${bundleId}`;
			return `${MARKET_ROUTES.ROOT.path}${MARKET_ROUTES.BUNDLES.path}${suffix}`;
		}
	},
	PLAYERS: {
		path: "/player",
		buildPath: (playerId: number) => {
			const suffix = `/${playerId}`;
			return `${MARKET_ROUTES.ROOT.path}${MARKET_ROUTES.PLAYERS.path}${suffix}`;
		}
	},
	SCOUTS: {
		path: "/scout",
		buildPath: (scoutId: number) => {
			const suffix = `/${scoutId}`;
			return `${MARKET_ROUTES.ROOT.path}${MARKET_ROUTES.SCOUTS.path}${suffix}`;
		}
	}
};
