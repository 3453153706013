import { PveDifficulty } from "@/modules/shared/helpers";
import { ClubLogo, ThemePalette } from "@metasoccer/metasoccer-ds";

export const getByPveDifficultyLogo = (
	difficulty: PveDifficulty,
	palette: ThemePalette,
	size?: "xs" | "sm" | "md" | "lg"
) => {
	switch (difficulty) {
		case PveDifficulty.STARTER:
			return <ClubLogo name="Club8" color={palette.system.viking} size={size} isMatch />;
		case PveDifficulty.AMATEUR:
			return <ClubLogo name="Club3" color={palette.system.laserLemon} size={size} isMatch />;
		case PveDifficulty.BEGINNER:
			return <ClubLogo name="Club4" color={palette.system.hotPink} size={size} isMatch />;
		case PveDifficulty.PRO:
			return <ClubLogo name="Club7" color={palette.system.amaranth} size={size} isMatch />;
		case PveDifficulty.SEMI_PRO:
			return <ClubLogo name="Club5" color={palette.system.pastelGreen} size={size} isMatch />;
		case PveDifficulty.LEGEND:
			return <ClubLogo name="Club20" color={palette.system.cornflowerBlue} size={size} isMatch />;
		default:
			return <ClubLogo name="Club3" color={palette.system.laserLemon} size={size} isMatch />;
	}
};
