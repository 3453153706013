import styled, { css } from "styled-components";

export const CssTeamInfoWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;

		${theme.breakpoints.up("desktopMin")} {
			gap: 16px;
		}
	`
);

interface CssTeamInfoContentProps {
	showOverallLineupSkill: boolean;
}

export const CssTeamInfoContent = styled.div<CssTeamInfoContentProps>(
	({ theme, showOverallLineupSkill }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		gap: ${showOverallLineupSkill ? "8px" : "12px"};

		${theme.breakpoints.up("desktopMin")} {
			gap: 12px;
		}
	`
);
