import React from "react";
import { LayoutContext } from "./LayoutContext";

const usePWA = () => {
	const context = React.useContext(LayoutContext);
	if (context === undefined) {
		throw new Error("usePWA must be used within a LayoutContextProvider");
	}
	return {
		isRunningStandalone: context.isRunningStandalone,
		safeAreaInsets: context.safeAreaInsets
	};
};

export { usePWA };
