import { useIsMobile } from "@/layouts/context";
import { Container, FixedAndGrowLayout } from "@/modules/shared/components";
import { ArrowRightIcon, Button, Col, Row, TextField, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { ImageStyled, PathToGloryWrapperStyled } from "./GetYourTeam.styled";

import guildImage from "./assets/Guild.png";
import teamImage from "./assets/Market.png";

export interface GetYourTeamProps {
	onMarketClick?: () => void;
}

export const GetYourTeam: FC<GetYourTeamProps> = ({ onMarketClick }) => {
	const isMobile = useIsMobile();

	return (
		<Container height="100%" paddingY={32}>
			<FixedAndGrowLayout alignItems="center" enableGrow={!isMobile} overflow="hidden">
				<Col alignItems="center" gap={32} style={{ maxWidth: 600 }}>
					<Typography variant="h4" textAlign="center">
						Get your team!
					</Typography>

					<Typography variant="body1" textAlign="center" light>
						Ready to hit the field? You'll need your own squad of 11 to play. Choose your path to glory:
					</Typography>
				</Col>

				<PathToGloryWrapperStyled direction={isMobile ? "column" : "row"} style={{ maxWidth: 800 }}>
					<Col alignItems="center" justifyContent="center" gap={24}>
						<Row style={{ width: "360px", height: "280px" }}>
							<ImageStyled backgroundImage={teamImage} />
						</Row>

						<Typography variant="body1" light textAlign="center">
							Be the boss. Buy your team on the market and fully own your lineup. This way, you can trade
							players. Get started with teams available from just 1000 MSU (about US$ 2).
						</Typography>

						<Row style={{ padding: "4px" }}>
							<Button
								appearance="primary"
								label="Explore the market"
								rightIcon={<ArrowRightIcon size="sm" />}
								onClick={onMarketClick}
							/>
						</Row>
					</Col>

					<Col alignItems="center" justifyContent="center" gap={24}>
						<Row style={{ width: "360px", height: "280px" }}>
							<ImageStyled backgroundImage={guildImage} />
						</Row>

						<Typography variant="body1" light textAlign="center">
							Join the game with zero cost. Play with a team owned by someone else and split the winnings.
							It's a smart play to get into the game and learn the ropes before you invest.
						</Typography>

						<Row alignItems="flex-start" gap={24}>
							<TextField
								name="messageToBeSent"
								placeholder="Scolarship code"
								customValidators={[{ message: "Invalid code", validate: (v: string) => false }]}
							/>

							<Row style={{ padding: "4px" }}>
								<Button appearance="secondary" label="Redeem" />
							</Row>
						</Row>
					</Col>
				</PathToGloryWrapperStyled>
			</FixedAndGrowLayout>
		</Container>
	);
};
