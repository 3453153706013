import { useIsMobile } from "@/layouts/context";
import { FlexScrollingContainer } from "@/modules/shared/components";
import { TrainingToken } from "@/modules/shared/components/tokens";
import { useBalanceContext } from "@/modules/shared/context";
import { Button, Tab } from "@metasoccer/metasoccer-ds";
import { Drill } from "@metasoccer/metasoccer-types";
import { FC, useEffect, useState } from "react";
import {
	CssDrillCardWithHover,
	CssDrillStepHeader,
	CssDrillStepWrapper,
	CssListWrapper,
	CssTabGroup,
	CssTabs,
	CssTabsWrapper
} from "./SelectDrillStep.styled";

const DRILL_TYPE_TO_TAB_INDEX: Record<string, number> = {
	ATTACK: 0,
	DEFENSE: 1,
	PHYSICAL_AND_MENTAL: 2
};

export interface SelectDrillStepProps {
	isOpen: boolean;
	drills: Drill[];
	onDrillClicked?: (drill: Drill) => void;
	onDrillsInfoClicked?: () => void;
}

export const SelectDrillStep: FC<SelectDrillStepProps> = ({
	isOpen,
	drills,
	onDrillClicked = () => {},
	onDrillsInfoClicked = () => {}
}) => {
	const { trainingTokens } = useBalanceContext();

	const [selectedTab, setSelectedTab] = useState<number>(0);
	const visibleDrills = drills.filter((drill: Drill) => {
		return DRILL_TYPE_TO_TAB_INDEX[drill.type] === selectedTab;
	});

	const isMobile = useIsMobile();

	useEffect(() => {
		if (!isOpen) {
			setSelectedTab(0);
		}
	}, [isOpen]);

	return (
		<CssDrillStepWrapper>
			{isMobile && (
				<CssDrillStepHeader>
					<TrainingToken quantity={trainingTokens} />
					<Button appearance="secondary" size="sm" label="Drills info" onClick={onDrillsInfoClicked} />
				</CssDrillStepHeader>
			)}
			<CssTabsWrapper>
				<CssTabs index={selectedTab} onIndexChange={(idx) => setSelectedTab(idx)}>
					<CssTabGroup>
						<Tab index={0}>Attack</Tab>
						<Tab index={1}>Defense</Tab>
						<Tab index={2}>{isMobile ? "Men. & Phy." : "Mental & Physical"}</Tab>
					</CssTabGroup>
				</CssTabs>
				{!isMobile && (
					<Button appearance="secondary" size="sm" label="Drills info" onClick={onDrillsInfoClicked} />
				)}
			</CssTabsWrapper>
			<FlexScrollingContainer>
				<CssListWrapper>
					{visibleDrills.map((drill, idx) => (
						<CssDrillCardWithHover key={idx} drill={drill} onClick={onDrillClicked} />
					))}
				</CssListWrapper>
			</FlexScrollingContainer>
		</CssDrillStepWrapper>
	);
};
