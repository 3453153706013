import {
	ArrowDownIcon,
	ArrowUpIcon,
	ClickAwayListener,
	Col,
	DownIcon,
	Menu,
	Row,
	Typography,
	UpIcon
} from "@metasoccer/metasoccer-ds";
import { Column } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { MenuItemStyled } from "./TableColumnMenu.styles";

interface TableColumnMenuProps {
	column: Column<any, unknown>;
}

export const TableColumnMenu = ({ column }: TableColumnMenuProps) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleSortingAndClose = useCallback(
		(desc: boolean) => {
			column.toggleSorting(desc);
			setIsMenuOpen(false);
		},
		[column, setIsMenuOpen]
	);

	const toggleIsMenuOpen = useCallback(() => {
		setIsMenuOpen(!isMenuOpen);
	}, [isMenuOpen]);

	const menuComponent = useMemo(
		() =>
			isMenuOpen ? (
				<UpIcon size="sm" color="gray" cursor="pointer" onClick={toggleIsMenuOpen} />
			) : (
				<DownIcon size="sm" color="gray" cursor="pointer" onClick={toggleIsMenuOpen} />
			),
		[isMenuOpen]
	);

	return (
		<ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
			<div style={{ height: 16, width: 16 }}>
				<Menu
					isMenuOpen={isMenuOpen}
					contentStyle={{ transform: "translate(8px, 10px)" }}
					menuComponent={menuComponent}
					position="right">
					<div style={{ minWidth: 192, padding: "8px 0" }}>
						<Col gap={8}>
							{/* 
							// TODO: ADD FILTERING
							<div style={{ padding: 8 }}>
								{column.getCanFilter() && (
									<></>
								)}
							</div>
							<Divider />
							*/}
							{column.getCanSort() && (
								<Col>
									<MenuItemStyled onClick={() => toggleSortingAndClose(false)}>
										<Row alignItems="center" gap={8}>
											<ArrowDownIcon size="sm" />
											<Typography variant="tooltip" style={{ whiteSpace: "no-break" }}>
												Sort Ascending
											</Typography>
										</Row>
									</MenuItemStyled>
									<MenuItemStyled onClick={() => toggleSortingAndClose(true)}>
										<Row alignItems="center" gap={8}>
											<ArrowUpIcon size="sm" />
											<Typography variant="tooltip" style={{ whiteSpace: "no-break" }}>
												Sort Descending
											</Typography>
										</Row>
									</MenuItemStyled>
								</Col>
							)}
						</Col>
					</div>
				</Menu>
			</div>
		</ClickAwayListener>
	);
};
