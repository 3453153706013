import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled, { css } from "styled-components";
import { Chip } from "@peersyst/react-components";
import { alpha } from "@peersyst/react-utils";
export var squaredChipStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tpadding: 0;\n\tjustify-content: center;\n\n\t&.Lg {\n\t\twidth: 1.5rem;\n\t}\n\t&.Md {\n\t\twidth: 1.25rem;\n\t}\n\t&.Sm {\n\t\twidth: 1rem;\n\t}\n"])));
export var ChipRoot = styled(Chip)(function (_ref) {
  var theme = _ref.theme,
    customColor = _ref.customColor;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tpadding: 0 var(--chipPadding);\n\n\t\t.ChipLabel {\n\t\t\tfont-weight: 500;\n\t\t\tfont-family: ", ";\n\t\t\ttext-transform: uppercase;\n\t\t}\n\n\t\t&.Lg {\n\t\t\theight: 1.5rem;\n\t\t\tpadding: 0 0.375rem;\n\n\t\t\t.ChipLabel {\n\t\t\t\tfont-size: 0.75rem;\n\t\t\t}\n\t\t}\n\t\t&.Md {\n\t\t\theight: 1.25rem;\n\t\t\tpadding: 0 0.375rem;\n\n\t\t\t.ChipLabel {\n\t\t\t\tfont-size: 0.75rem;\n\t\t\t}\n\t\t}\n\t\t&.Sm {\n\t\t\theight: 1rem;\n\t\t\tpadding: 0 0.25rem;\n\n\t\t\t.ChipLabel {\n\t\t\t\tfont-size: 0.625rem;\n\t\t\t}\n\t\t}\n\n\t\t&.Filled {\n\t\t\tbackground-color: ", ";\n\t\t\tcolor: ", ";\n\t\t}\n\t\t&.Outlined {\n\t\t\tbackground-color: transparent;\n\t\t\tcolor: ", ";\n\n\t\t\t&.Lg {\n\t\t\t\tborder-width: 1.25px;\n\t\t\t}\n\t\t\t&.Md,\n\t\t\t&.Sm {\n\t\t\t\tborder-width: 1px;\n\t\t\t}\n\t\t}\n\n\t\t&.Disabled {\n\t\t\tcolor: ", ";\n\t\t\tbackground-color: ", ";\n\n\t\t\t&.Outlined {\n\t\t\t\tborder-color: ", ";\n\t\t\t}\n\t\t}\n\n\t\t&.Clickable:not(.Disabled) {\n\t\t\t&:hover {\n\t\t\t\tfilter: brightness(1.2);\n\t\t\t}\n\n\t\t\t&:active {\n\t\t\t\tfilter: brightness(0.9);\n\t\t\t}\n\t\t}\n\t"])), theme.fonts.spaceGrotesk, customColor || theme.palette.backgrounds.tundora, theme.palette.greyscale.white, theme.palette.greyscale.white, theme.palette.disabled, alpha(theme.palette.disabled, 0.2), alpha(theme.palette.disabled, 0.4));
});