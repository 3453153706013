import styled, { css } from "styled-components";

export const CssBar = styled("div")<any>(
	({ isFullSize }) => css`
		width: ${isFullSize ? "100%" : "73px"};
		@media (max-width: 400px) {
			width: 60px;
		}
	`
);
