import { FC } from "react";
import { Button, Typography, Modal } from "@metasoccer/metasoccer-ds";
import { CssContent, CssCost, CssCostLabel } from "./NotEnoughFundsModal.styled";
import { TrainingToken } from "@/modules/shared/components/tokens";

export interface NotEnoughFundsModalProps {
	isOpen: boolean;
	cost: number;
	onClose?: () => void;
	onContinue?: () => void;
}

export const NotEnoughFundsModal: FC<NotEnoughFundsModalProps> = ({
	isOpen,
	cost,
	onClose = () => {},
	onContinue = () => {}
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Not enough funds"
			footerContent={
				<>
					<Button appearance="secondary" label="Not now" fullWidth onClick={onClose} />
					<Button appearance="primary" label="Go to store" fullWidth onClick={onContinue} />
				</>
			}>
			<CssContent>
				<Typography variant="body1" light>
					You don't have enough training tokens. Get some in the store!
				</Typography>
			</CssContent>
			<CssCost>
				<CssCostLabel>
					<Typography variant="body1" light>
						Total cost
					</Typography>
				</CssCostLabel>
				<TrainingToken quantity={cost} />
			</CssCost>
		</Modal>
	);
};
