import { maintenanceService } from "../dependencies";

export const MAINTENANCE_QUERIES = {
	getMaintenanceLog: () => ({
		queryKey: ["MAINTENANCE_QUERIES.getMaintenanceLog"],
		queryFn: () => maintenanceService.getMaintenanceLog()
	}),
	getMessage: (messageId: string) => ({
		queryKey: ["MAINTENANCE_QUERIES.getMessage"],
		queryFn: () => maintenanceService.getMessage(messageId)
	})
};
