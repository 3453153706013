import { PlayerConditionBar } from "@/modules/lineup/shared/components/PlayerConditionBar";
import { Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { PlayerTableItem } from "../PlayerTable";

export const ConditionCell = memo<{ player: PlayerTableItem }>(({ player }) => {
	return (
		<>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					padding: "0 8px",
					width: "100%"
				}}>
				<Typography variant="body2" textAlign="center">
					{Math.round(player.condition)}
				</Typography>
			</div>
			<div style={{ position: "absolute", bottom: 0, width: "100%" }}>
				<PlayerConditionBar condition={player.condition} isFullSize />
			</div>
		</>
	);
});
