export const PLAYER_ROUTES = {
	ROOT: {
		path: "/player",
		buildPath: () => "/player"
	},
	PROFILE: {
		path: "/player/:id",
		buildPath: (id: number) => `/player/${id}`
	}
};
