import { FC } from "react";
import { Size } from "../../types";
import { STAR_OFFSET_MD, STAR_OFFSET_SM, StarImageStyled, StarsWrapperStyled } from "./StarGroup.styles";

interface StarGroupProps {
	size: Size;
	starsUrls: string[];
}

export const StarGroup: FC<StarGroupProps> = ({ starsUrls, size }) => {
	const starImages = starsUrls?.map((url, index) => {
		const left =
			size === "sm"
				? STAR_OFFSET_SM * (index - 1) + STAR_OFFSET_SM
				: STAR_OFFSET_MD * (index - 1) + STAR_OFFSET_MD;

		const style = {
			left: `${index === 0 ? 0 : -left}px`,
			top: `${size === "sm" ? -1 : 1}px`
		};

		return <StarImageStyled key={index} src={url} alt="Star" style={style} size={size} />;
	});

	return <StarsWrapperStyled size={size}>{starImages}</StarsWrapperStyled>;
};
