import { flexRender, Table } from "@tanstack/react-table";
import { FC } from "react";
import { useGlobalState } from "../../context";
import { CssTable, CssTBody, CssTBodyTd, CssTBodyTr, CssTHead, CssTHeadTh, CssTHeadTr } from "./BaseTeamsTable.styled";

interface BaseTeamsTableProps {
	table: Table<any>;
}

export const BaseTeamsTable: FC<BaseTeamsTableProps> = ({ table }) => {
	const { selectedTeam } = useGlobalState();

	return (
		<CssTable>
			<CssTHead>
				{table.getHeaderGroups().map((headerGroup) => (
					<CssTHeadTr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<CssTHeadTh key={header.id} width={header.getSize()}>
								{flexRender(header.column.columnDef.header, header.getContext())}
							</CssTHeadTh>
						))}
					</CssTHeadTr>
				))}
			</CssTHead>
			<CssTBody>
				{table.getRowModel().rows.map((row) => (
					<CssTBodyTr key={row.id} isSelected={row.original.id === selectedTeam?.id}>
						{row.getVisibleCells().map((cell) => (
							<CssTBodyTd key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</CssTBodyTd>
						))}
					</CssTBodyTr>
				))}
			</CssTBody>
		</CssTable>
	);
};
