import styled, { css } from "styled-components";

const backgroundImage = require("./assets/Background.png");

export const WrapperStyled = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: stretch;
		background-color: ${theme.palette.backgrounds.codGray};
		background-image: url(${backgroundImage});
		background-size: cover;
		justify-content: center;
		height: 330px;
		padding: 24px;
		width: 100%;
	`
);
