import { accountService, analyticsService, teamService, volatileStorageService } from "@/dependencies";
import { useAuth } from "@/modules/auth";
import { EventTypes } from "@/modules/core/http/events";
import { VolatileStorageKeys, WalletAddress } from "@/modules/shared/types";
import { useConnectionStatus } from "@thirdweb-dev/react";
import React, { useEffect, useState } from "react";
import { GlobalState } from "./GlobalState";

type GlobalContextProviderProps = { children: React.ReactNode };

const useGlobalState = (): [GlobalState, React.Dispatch<React.SetStateAction<GlobalState>>] => {
	const status = useConnectionStatus();

	const { address, authenticated, authenticate } = useAuth();

	const [globalState, setGlobalState] = useState<GlobalState>({
		account: undefined,
		address: undefined,
		isAuthenticated: false,
		isConnected: false,
		isConnecting: false,
		isDisconnected: false,
		isUnknown: true,
		selectedTeam: undefined,
		serverRefresh: false,
		showOnboarding: false
	});

	useEffect(() => {
		setGlobalState((prev) => ({
			...prev,
			isConnected: status === "connected",
			isConnecting: status === "connecting",
			isDisconnected: status === "disconnected",
			isUnknown: status === "unknown"
		}));

		if (["connecting", "disconnected"].includes(status)) {
			setGlobalState((prev) => ({
				...prev,
				address: undefined,
				selectedTeam: undefined
			}));
		}
	}, [status]);

	useEffect(() => {
		if (address) {
			const addr = new WalletAddress(address).address;

			accountService
				.checkIn(addr)
				.then((showOnboarding) => setGlobalState((prev) => ({ ...prev, showOnboarding })));
			analyticsService.identifyUser(addr);
			volatileStorageService.save(VolatileStorageKeys.USER_WALLET_ADDRESS, addr);

			authenticate();
		}
	}, [address]);

	useEffect(() => {
		if (!address || !authenticated) return;

		accountService.get().then((account) => {
			setGlobalState((prev) => ({
				...prev,
				account,
				address,
				isAuthenticated: true
			}));

			teamService.setSelectedTeam(address, setGlobalState);
		});

		document.addEventListener(EventTypes.UNAUTHORIZED_API_REQUEST, authenticate);
		return () => {
			document.removeEventListener(EventTypes.UNAUTHORIZED_API_REQUEST, authenticate);
		};
	}, [address, authenticated]);

	return [globalState, setGlobalState];
};

const GlobalContext = React.createContext<
	| {
			globalState: GlobalState;
			setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
	  }
	| undefined
>(undefined);

const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
	const [globalState, setGlobalState] = useGlobalState();

	return <GlobalContext.Provider value={{ globalState, setGlobalState }}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
