import { useIsMobile } from "@/layouts/context";
import { InfoIcon, Tooltip, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { LineupNotices } from "../LineupNotices";
import { CssIconWrapper, CssNextMatchCardHeaderWrapper, CssTitleWrapper } from "./NextMatchCardHeader.styled";

interface NextMatchCardHeaderProps {
	tournamentName: string;
	notices?: string[];
}
export const NextMatchCardHeader: FC<NextMatchCardHeaderProps> = ({ tournamentName, notices = [] }) => {
	const theme = useTheme();
	const isMobile = useIsMobile();
	return (
		<CssNextMatchCardHeaderWrapper>
			<CssTitleWrapper>
				<Typography variant={"h5"}>MSL • Next match</Typography>
				<Typography variant={"caption"} light>{`${tournamentName}`}</Typography>
			</CssTitleWrapper>
			{notices.length > 0 && !isMobile && (
				<CssIconWrapper>
					<Tooltip>
						{{
							popper: <LineupNotices notices={notices} />,
							content: <InfoIcon size="md" color={theme.palette.system.amaranth} />
						}}
					</Tooltip>
				</CssIconWrapper>
			)}
		</CssNextMatchCardHeaderWrapper>
	);
};
