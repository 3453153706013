import React, { FC } from "react";
import { RoleChip, Typography } from "@metasoccer/metasoccer-ds";
import {
	CssScoutContentUpperPart,
	CssScoutContentUpperPartInfo,
	CssScoutContentUpperPartInfoWrapper,
	CssScoutContentUpperPartOverall,
	CssScoutContentUpperPartWrapper
} from "./ScoutUpperPart.styled";
import { Covering } from "@metasoccer/metasoccer-types";

interface ScoutUpperPartProps {
	overallKnowledge: number;
	covering: Covering;
	shortName: string;
	age: number;
	specialAbilitiesCount: number;
}

export const ScoutUpperPart: FC<ScoutUpperPartProps> = ({
	overallKnowledge,
	covering,
	shortName,
	age,
	specialAbilitiesCount
}) => {
	return (
		<CssScoutContentUpperPart>
			<CssScoutContentUpperPartWrapper>
				<CssScoutContentUpperPartOverall>
					<Typography variant="h6">{overallKnowledge}</Typography>
				</CssScoutContentUpperPartOverall>
				<RoleChip covering={covering} />
			</CssScoutContentUpperPartWrapper>
			<CssScoutContentUpperPartWrapper>
				<Typography variant="h6">{shortName}</Typography>
				<CssScoutContentUpperPartInfoWrapper>
					<CssScoutContentUpperPartInfo>
						<Typography variant="body2" light>
							Age
						</Typography>
						<Typography variant="body2">{age}</Typography>
					</CssScoutContentUpperPartInfo>
					<CssScoutContentUpperPartInfo>
						<Typography variant="body2" light>
							S. Abil.
						</Typography>
						<Typography variant="body2">{specialAbilitiesCount}</Typography>
					</CssScoutContentUpperPartInfo>
				</CssScoutContentUpperPartInfoWrapper>
			</CssScoutContentUpperPartWrapper>
		</CssScoutContentUpperPart>
	);
};
