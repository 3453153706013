import { Row } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

// DEBT: height shouldn't be fixed
export const CssContent = styled.div(
	({ theme }) => css`
		flex: 1;
		max-height: 100%;
		overflow-y: auto;
		padding: 12px 0;

		${theme.breakpoints.up("desktopMin")} {
			height: 600px;
			padding: 0;
		}
	`
);

export const CssHeader = styled(Row)(
	({ theme }) => css`
		align-items: center;
		justify-content: space-between;
		padding: 24px;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssTitle = styled(Row)(
	({ theme }) => css`
		align-items: center;
		gap: 4px;
	`
);
