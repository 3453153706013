export const getMatchDay = (matchDate: number): string => {
	const date = new Date(matchDate);
	const now = new Date();

	if (date.getDay() === now.getDay()) {
		return "Today";
	}

	if (date.getDay() === now.getDay() + 1) {
		return "Tomorrow";
	}

	return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getDay()];
};
