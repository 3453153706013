import { BaseTeamsTable, ColumnHeader, InfoMessage, LastMatches, Loading } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { getPrizeColorByPosition } from "@/modules/shared/helpers";
import { Button, ClubLogo, Typography } from "@metasoccer/metasoccer-ds";
import {
	TournamentDetailedStandingRow,
	TournamentDivisionSimplified,
	TournamentStructureEdition
} from "@metasoccer/metasoccer-types";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { FC, useMemo } from "react";
import { useTheme } from "styled-components";
import { StandingsFilters } from "../../../../Standings.state";
import {
	CssClubNameWrapper,
	CssFilteredStandingWrapper,
	CssFiltersWrapper,
	CssPosition
} from "./FilteredStandingDesktop.styled";
import { Filters } from "./components";

interface FilteredStandingDesktopProps {
	isLoadingFilteredStanding: boolean;
	tournamentStructureEditions: TournamentStructureEdition[];
	filters: StandingsFilters;
	rankedTeams?: TournamentDetailedStandingRow[];
	divisionsWithRewards: TournamentDivisionSimplified[];
	onEditionChange: (editionId: number) => () => void;
	onDivisionChange: (divisionId: string) => () => void;
	onGroupChange: (groupId: number) => () => void;
	toggleModal: () => void;
}

const columnHelper = createColumnHelper<TournamentDetailedStandingRow>();

export const FilteredStandingDesktop: FC<FilteredStandingDesktopProps> = ({
	isLoadingFilteredStanding,
	tournamentStructureEditions,
	filters,
	rankedTeams,
	divisionsWithRewards,
	onEditionChange,
	onDivisionChange,
	onGroupChange,
	toggleModal
}) => {
	const { selectedTeam } = useGlobalState();
	const theme = useTheme();
	const rewards = useMemo(
		() => divisionsWithRewards.find((division) => division.id === filters.divisionId)?.rewards ?? [],
		[divisionsWithRewards, filters.divisionId]
	);

	const columns = useMemo(
		() => [
			columnHelper.accessor("position", {
				id: "position",
				header: () => <Typography variant={"body2"}>#</Typography>,
				cell: (info) => {
					const position = info.getValue();
					const isMyTeam = info.row.original.id === selectedTeam?.id;
					const color = getPrizeColorByPosition(position, isMyTeam, rewards, theme.palette);

					return (
						<CssPosition color={color}>
							<Typography variant={"body2"} light>
								{position}
							</Typography>
						</CssPosition>
					);
				}
			}),
			columnHelper.accessor("clubName", {
				id: "team",
				header: () => <ColumnHeader title="Team" />,
				cell: ({ getValue, row }) => {
					return (
						<CssClubNameWrapper>
							<ClubLogo
								color={row.original.clubLogocolor}
								name={row.original.clubLogoName}
								url={row.original.clubLogoUrl}
								size="xs"
							/>
							<Typography variant={"body2"}>{getValue()}</Typography>
						</CssClubNameWrapper>
					);
				},
				size: 2000
			}),
			columnHelper.accessor("played", {
				id: "played",
				header: () => <ColumnHeader title="Played" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("won", {
				id: "won",
				header: () => <ColumnHeader title="Won" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("tied", {
				id: "tied",
				header: () => <ColumnHeader title="Tied" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("lost", {
				id: "lost",
				header: () => <ColumnHeader title="Lost" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("goalsForward", {
				id: "goalsForward",
				header: () => <ColumnHeader title="GF" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("goalsAgainst", {
				id: "goalsAgainst",
				header: () => <ColumnHeader title="GA" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("goalsDifference", {
				id: "goalsDifference",
				header: () => <ColumnHeader title="GD" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("points", {
				id: "points",
				header: () => <ColumnHeader title="Points" />,
				cell: ({ getValue }) => <Typography variant={"body2"}>{getValue()}</Typography>
			}),
			columnHelper.accessor("lastMatches", {
				id: "lastMatches",
				header: () => <ColumnHeader title="Last Matches" />,
				cell: ({ getValue }) => <LastMatches results={getValue()} />,
				size: 250
			})
		],
		[selectedTeam?.id, rewards, theme.palette]
	);

	const table = useReactTable({
		data: rankedTeams ?? [],
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	return (
		<CssFilteredStandingWrapper>
			<CssFiltersWrapper>
				<Filters
					tournamentStructureEditions={tournamentStructureEditions}
					filters={filters}
					onEditionChange={onEditionChange}
					onDivisionChange={onDivisionChange}
					onGroupChange={onGroupChange}
				/>
				<Button label="Rewards info" appearance="secondary" onClick={toggleModal} />
			</CssFiltersWrapper>
			{isLoadingFilteredStanding ? (
				<Loading />
			) : !rankedTeams ? (
				<InfoMessage message="There are no standings to show" />
			) : (
				<BaseTeamsTable table={table} />
			)}
		</CssFilteredStandingWrapper>
	);
};
