import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: auto;

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
		}
	`
);

export const SectionStyled = styled.div<{ flex?: number }>(
	({ flex, theme }) => css`
		min-height: calc(100vh - 64px);

		${theme.breakpoints.up("desktopMin")} {
			border-right: 1px solid ${theme.palette.greyscale.gondola};
			flex: ${flex ?? 1};
			height: 100%;

			&:last-child {
				border-right: none;
			}
		}
	`
);
