// URL of each type of star
// C=Current, N=None, P=Potential, F=Full, H=Half
// Example 1: StarCCF = Star Current Current Full

import { getImgUrl } from "@metasoccer/metasoccer-ds";

// Example 2: StarNNH = Star None None Half
const STAR_URLS = {
	StarCCF: "https://assets.metasoccer.com/home/star/Star-CCF.svg",
	StarCCH: "https://assets.metasoccer.com/home/star/Star-CCH.svg",
	StarCNF: "https://assets.metasoccer.com/home/star/Star-CNF.svg",
	StarCNH: "https://assets.metasoccer.com/home/star/Star-CNH.svg",
	StarCPF: "https://assets.metasoccer.com/home/star/Star-CPF.svg",
	StarCPH: "https://assets.metasoccer.com/home/star/Star-CPH.svg",
	StarNNF: "https://assets.metasoccer.com/home/star/Star-NNF.svg",
	StarNNH: "https://assets.metasoccer.com/home/star/Star-NNH.svg",
	StarPPF: "https://assets.metasoccer.com/home/star/Star-PPF.svg",
	StarPPH: "https://assets.metasoccer.com/home/star/Star-PPH.svg",
	StarPNF: "https://assets.metasoccer.com/home/star/Star-PNF.svg",
	StarPNH: "https://assets.metasoccer.com/home/star/Star-PNH.svg"
};

const STARS = {
	"0 star": [STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"0 1/2 star": [STAR_URLS.StarCNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"1 star": [STAR_URLS.StarCCH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"1 1/2 star": [STAR_URLS.StarCCH, STAR_URLS.StarCNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"2 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"2 1/2 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"3 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"3 1/2 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCNH, STAR_URLS.StarNNF],
	"4 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarNNF],
	"4 1/2 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCNF],
	"5 star": [STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCH, STAR_URLS.StarCCF]
};

const STARS_POTENTIAL = {
	"0 star": [STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"0 1/2 star": [STAR_URLS.StarPNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"1 star": [STAR_URLS.StarPPH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"1 1/2 star": [STAR_URLS.StarPPH, STAR_URLS.StarPNH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"2 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarNNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"2 1/2 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPNH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"3 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarNNH, STAR_URLS.StarNNF],
	"3 1/2 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPNH, STAR_URLS.StarNNF],
	"4 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarNNF],
	"4 1/2 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPNF],
	"5 star": [STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPH, STAR_URLS.StarPPF]
};

function overallToStars(overall: number): number {
	if (overall <= 0) {
		return 0;
	}
	if (overall >= 100) {
		return 5;
	}
	return roundToNearestHalf(5 * (overall / 100) ** 0.9);
}

function roundToNearestHalf(num: number): number {
	// Multiply the number by 2 to round to the nearest 0.5
	const rounded = Math.round(num * 2) / 2;
	return rounded;
}

function getStarsByQuality(overall: number): string[] {
	const stars = overallToStars(overall);

	if (stars === 0) {
		return STARS["0 star"];
	} else if (stars === 0.5) {
		return STARS["0 1/2 star"];
	} else if (stars === 1) {
		return STARS["1 star"];
	} else if (stars === 1.5) {
		return STARS["1 1/2 star"];
	} else if (stars === 2) {
		return STARS["2 star"];
	} else if (stars === 2.5) {
		return STARS["2 1/2 star"];
	} else if (stars === 3) {
		return STARS["3 star"];
	} else if (stars === 3.5) {
		return STARS["3 1/2 star"];
	} else if (stars === 4) {
		return STARS["4 star"];
	} else if (stars === 4.5) {
		return STARS["4 1/2 star"];
	} else if (stars === 5) {
		return STARS["5 star"];
	}
	return [];
}

function getStarByPotential(potential: number): string[] {
	const stars = overallToStars(potential);

	if (stars === 0) {
		return STARS_POTENTIAL["0 star"];
	}
	if (stars === 0.5) {
		return STARS_POTENTIAL["0 1/2 star"];
	} else if (stars === 1) {
		return STARS_POTENTIAL["1 star"];
	} else if (stars === 1.5) {
		return STARS_POTENTIAL["1 1/2 star"];
	} else if (stars === 2) {
		return STARS_POTENTIAL["2 star"];
	} else if (stars === 2.5) {
		return STARS_POTENTIAL["2 1/2 star"];
	} else if (stars === 3) {
		return STARS_POTENTIAL["3 star"];
	} else if (stars === 3.5) {
		return STARS_POTENTIAL["3 1/2 star"];
	} else if (stars === 4) {
		return STARS_POTENTIAL["4 star"];
	} else if (stars === 4.5) {
		return STARS_POTENTIAL["4 1/2 star"];
	} else if (stars === 5) {
		return STARS_POTENTIAL["5 star"];
	}

	return [];
}

function getStarsByQualityAndPotentialArrays(stars1: string[], stars2: string[]): string[] {
	const transformedUrls = [];
	for (let i = 0; i < stars1.length; i++) {
		const star1 = stars1[i];
		const star2 = stars2[i];
		if (i < 5 && star1 === STAR_URLS.StarNNH && star2 === STAR_URLS.StarNNH) {
			transformedUrls.splice(i, 0, STAR_URLS.StarNNH);
		} else if (star1 === star2) {
			transformedUrls.splice(i, 0, star1);
		} else if (star1 === STAR_URLS.StarNNH) {
			transformedUrls.splice(i, 0, star2);
		} else if (star1 === STAR_URLS.StarNNH) {
			transformedUrls.splice(i, 0, star1);
		} else if (i < 4 && star1 === STAR_URLS.StarNNH) {
			transformedUrls.splice(i, 0, star2);
		} else if (star1 === STAR_URLS.StarCCH) {
			transformedUrls.push(star1);
		} else if (star1 === STAR_URLS.StarCNH) {
			if (star2 === STAR_URLS.StarPPH) {
				transformedUrls.splice(i, 0, STAR_URLS.StarCPH);
			} else if (star2 !== STAR_URLS.StarPPH) {
				transformedUrls.splice(i, 0, STAR_URLS.StarCNH);
			}
		} else if (i === stars1.length - 1 && star1 === STAR_URLS.StarCCF) {
			transformedUrls.splice(i, 0, star1);
		} else if (i === stars1.length - 1 && star1 === STAR_URLS.StarCNF && star2 === STAR_URLS.StarPPF) {
			transformedUrls.splice(i, 0, STAR_URLS.StarCPF);
		} else if (i === stars1.length - 1 && star1 === STAR_URLS.StarNNF) {
			transformedUrls.splice(i, 0, star2 !== STAR_URLS.StarNNF ? star2 : STAR_URLS.StarCNH);
		} else if (i === stars1.length - 1 && star1 === STAR_URLS.StarCNF && star2 !== STAR_URLS.StarPPF) {
			transformedUrls.splice(i, 0, STAR_URLS.StarCNF);
		} else if (i === stars1.length - 1 && star1 === STAR_URLS.StarNNF && star2 === STAR_URLS.StarNNF) {
			transformedUrls.splice(i, 0, STAR_URLS.StarNNF);
		} else if (i === stars1.length - 1 && star1 === STAR_URLS.StarNNF && star2 === STAR_URLS.StarNNF) {
			transformedUrls.splice(i, 0, STAR_URLS.StarNNF);
		}
	}
	return transformedUrls;
}

export function getStarsByQualityAndPotential(overall: number, potential: number): string[] {
	const stars1 = getStarsByQuality(overall);

	let stars2 = [];
	if (!potential) {
		stars2 = getStarsByQuality(overall);
	} else {
		stars2 = getStarByPotential(potential);
	}

	const starsResult = getStarsByQualityAndPotentialArrays(stars1, stars2);

	return starsResult.map((url) => getImgUrl(url));
}
