import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { customTournamentsService, pvpService, worldCupService } from "../dependencies";

export const PVP_QUERIES = {
	tournamentInfo: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.PVP_NEXT_MATCH, teamId ?? "no_team"],
		queryFn: () => pvpService.getTournamentInfo(teamId)
	}),
	getTeamStats: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.PVP_TEAM_STATS, teamId ?? "no_team"],
		queryFn: () => pvpService.getTeamStats(teamId)
	}),
	getScheduledMatches: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.PVP_SCHEDULED_MATCHES, teamId ?? "no_team"],
		queryFn: () => pvpService.getScheduledMatches(teamId)
	}),
	getPlayedMatch: (matchId: string) => ({
		queryKey: [BaseServerStateKeys.PVP_SCHEDULED_MATCHES, "match", matchId],
		queryFn: () => pvpService.getPlayedMatch(matchId)
	}),
	getStandings: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.PVP_STANDING, teamId ?? "no_team"],
		queryFn: () => pvpService.getStandings(teamId)
	})
};

export const PVP_WORLD_CUP_QUERIES = {
	playoffsInfo: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.PLAYOFFS_INFO, teamId ?? "no_team"],
		queryFn: () => worldCupService.getPlayoffsInfo(teamId)
	}),
	getGroupsAndBranches: (teamId?: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.PLAYOFFS_GROUPS, teamId ?? "no_team"],
		queryFn: () => worldCupService.getGroupsAndBranches(teamId)
	})
};

export const CUSTOM_PVP_QUERIES = {
	tournamensOfInterest: (teamId: MetaSoccerId<IdType.Team>, pendingIds: string[]) => ({
		queryKey: [BaseServerStateKeys.CUSTOM_TOURNAMENTS_OF_INTEREST, teamId ?? "no_team", pendingIds.join(",")],
		queryFn: () => customTournamentsService.getTournamentsOfInterest(teamId, pendingIds)
	}),
	tournamentTeamInfo: (tournamentId: string, teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [
			BaseServerStateKeys.CUSTOM_TOURNAMENT_TEAM_INFO,
			tournamentId ?? "no_tournament",
			teamId ?? "no_team"
		],
		queryFn: () => customTournamentsService.getTournamentTeamInfo(tournamentId, teamId)
	}),
	tournamentInfo: (tournamentId: string) => ({
		queryKey: [BaseServerStateKeys.CUSTOM_TOURNAMENT_INFO, tournamentId ?? "no_tournament"],
		queryFn: () => customTournamentsService.getTournamentInfo(tournamentId)
	}),
	teamsSearch: (nameLike: string) => ({
		queryKey: [BaseServerStateKeys.CUSTOM_TOURNAMENT_SEARCH_TEAMS, nameLike],
		queryFn: () => customTournamentsService.getTeamsForWhitelist(nameLike)
	}),
	teamsSearchByIds: (ids: MetaSoccerId<IdType.Team>[]) => ({
		queryKey: [BaseServerStateKeys.CUSTOM_TOURNAMENT_SEARCH_TEAMS_IDS, ids.join(",")],
		queryFn: () => customTournamentsService.searchTeamsById(ids)
	}),
	registrationSignature: (tournamentId: string, teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [
			BaseServerStateKeys.CUSTOM_TOURNAMENT_REGISTRATION,
			tournamentId ?? "no_tournament",
			teamId ?? "no_team"
		],
		queryFn: () => customTournamentsService.getRegistrationSignature(tournamentId, teamId)
	}),
	rewardsSignature: (tournamentId: string) => ({
		queryKey: [BaseServerStateKeys.CUSTOM_TOURNAMENT_REWARDS, tournamentId ?? "no_tournament"],
		queryFn: () => customTournamentsService.getRewardsSignature(tournamentId)
	}),
	bestPlayersOverall: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.BEST_PLAYERS_OVERALL, teamId ?? "no_team"],
		queryFn: () => customTournamentsService.getBestPlayersOverall(teamId)
	})
};
