import { useIsMobile } from "@/layouts/context";
import { useDraggable } from "@dnd-kit/core";
import { CSS, useUniqueId } from "@dnd-kit/utilities";
import { DragHibridIcon, useTheme } from "@metasoccer/metasoccer-ds";
import { Row, flexRender } from "@tanstack/react-table";
import React, { forwardRef } from "react";
import { CssDrag, CssDragHook, CssTbodyTd, CssTbodyTdContent, CssTbodyTr } from "./TableRow.styles";

interface TableRowProps<T> {
	draggable?: boolean;
	height?: number;
	indicatorColor?: string;
	row: Row<T>;
	translateY?: number;
	getId?: (item: T) => string;
	onClick?: () => void;
}

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps<any>>(function TableRow<T>(
	{ draggable = false, height, indicatorColor, row, translateY, getId, onClick }: TableRowProps<T>,
	ref: React.Ref<HTMLTableRowElement>
) {
	const isMobile = useIsMobile();
	const theme = useTheme();

	const uniqueId = useUniqueId("TR");
	const rowId = getId ? getId(row.original) : uniqueId;
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: rowId,
		disabled: isMobile || !draggable
	});

	const transformCss = CSS.Translate.toString(transform);

	return (
		<CssTbodyTr
			ref={ref}
			key={row.id}
			height={height}
			indicatorColor={indicatorColor}
			translateY={translateY}
			onClick={onClick}>
			{row.getVisibleCells().map((cell, index) => {
				const isDragIconCell = index === 0 && draggable;
				// @ts-ignore
				const tdStyle = cell.column.columnDef.meta?.tdStyle;

				return (
					<CssTbodyTd key={cell.id} style={tdStyle} width={cell.column.getSize()}>
						{isDragIconCell ? (
							<CssTbodyTdContent>
								<CssDrag>
									<DragHibridIcon color={theme.palette.greyscale.scorpion} />
									<CssDragHook
										ref={setNodeRef}
										style={{ transform: transformCss }}
										{...attributes}
										{...listeners}
									/>
								</CssDrag>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</CssTbodyTdContent>
						) : (
							flexRender(cell.column.columnDef.cell, cell.getContext())
						)}
					</CssTbodyTd>
				);
			})}
		</CssTbodyTr>
	);
});

export default TableRow;
