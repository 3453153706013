import { useMSLState } from "@/modules/msl";
import { Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { WrapperStyled } from "./EditionInProgress.styles";

export const EditionInProgress = memo(() => {
	const { group } = useMSLState();

	if (!group) throw new Error("No group");

	return (
		<WrapperStyled>
			<Typography variant="h5">{group.name}</Typography>
		</WrapperStyled>
	);
});
