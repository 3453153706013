import { Typography, useToast as useOriginalToast } from "@metasoccer/metasoccer-ds";
import { useCallback } from "react";

export function useToast(): Omit<ReturnType<typeof useOriginalToast>, "showToast"> & {
	showErrToast: (err: any) => void;
	showToast: (message: string) => void;
} {
	const { toastActive, showToast, hideToast } = useOriginalToast();

	const errToMessage = useCallback((err: any) => {
		if (err.message) return err.message;
		return err.toString();
	}, []);

	return {
		toastActive,
		showErrToast: (err: any) =>
			showToast(<Typography variant="body2">{errToMessage(err)}</Typography>, {
				type: "error",
				position: "bottom-center"
			}),
		showToast: (message: string) =>
			showToast(<Typography variant="body2">{message}</Typography>, {
				type: "success",
				position: "bottom-center"
			}),
		hideToast
	};
}
