import { InjuredLockedPlayer, Player, PlayerSpecificRole } from "@metasoccer/metasoccer-types";
import { LineupTablePlayer } from "../LastLineupTable";

export const getLastLineupPlayers = (
	playersWithFormationSpecificRole: {
		player: Player;
		slotSpecificRole: PlayerSpecificRole;
	}[],
	injuredPlayers: InjuredLockedPlayer[]
): LineupTablePlayer[] => {
	function getInjuredPlayer(player: Player, injuredPlayers: InjuredLockedPlayer[]): InjuredLockedPlayer | undefined {
		return injuredPlayers.find((injuredPlayer) => injuredPlayer.playerId === player.id);
	}

	return playersWithFormationSpecificRole.map((p) => ({
		id: p.player.id,
		specificRole: p.slotSpecificRole,
		name: `${p.player.name.charAt(0)}. ${p.player.lastName}`,
		isInjured: getInjuredPlayer(p.player, injuredPlayers),
		specialAbilities: p.player.specialAbilities,
		overall: `${p.player.overall}/${p.player.potential}`
	}));
};
