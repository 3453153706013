import getRelativeDate from "@/modules/home/views/HomePage/components/TournamentActivity/utils/getRelativeDate";
import groupBy from "@/modules/shared/utils/groupBy";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { TournamentGroupInfoDTO } from "@metasoccer/metasoccer-types";
import { memo } from "react";

import { MATCH_ROUTES } from "@/modules/match/match.routes";
import { ScheduledMatchPreview } from "@/modules/pvp/views/CustomTournament/components/MatchesCard/components/ScheduledMatchPreview";
import { useGlobalState } from "@/modules/shared/context";
import { useNavigate } from "react-router-dom";
import { CssContent, CssGroupHeader, CssGroupMatch } from "./Matches.styles";

interface MatchesProps {
	groupInfo: TournamentGroupInfoDTO;
	showMyMatchesOnly: boolean;
}

export const Matches = memo<MatchesProps>(({ groupInfo, showMyMatchesOnly }) => {
	const navigate = useNavigate();

	const { selectedTeam } = useGlobalState();

	const matches = showMyMatchesOnly
		? groupInfo.matches.filter((m) => m.teams.home.id === selectedTeam?.id || m.teams.away.id === selectedTeam?.id)
		: groupInfo.matches;

	const groupedMatches = groupBy(matches, (m) => getRelativeDate(new Date(m.date)));

	return (
		<CssContent>
			{Object.entries(groupedMatches).map(([groupKey, groupValues]) => (
				<Col key={groupKey}>
					<CssGroupHeader>
						<Typography variant="body1">{groupKey}</Typography>
					</CssGroupHeader>
					{groupValues.map((match, index) => (
						<CssGroupMatch key={index}>
							<ScheduledMatchPreview
								isMatchResultLayout
								scheduledMatchPreviewInfo={match}
								selectedTeamId={selectedTeam?.id}
								onClick={() => navigate(MATCH_ROUTES.ROOT.buildPath(match.matchId))}
							/>
						</CssGroupMatch>
					))}
				</Col>
			))}
		</CssContent>
	);
});
