import { Tooltip as MsTooltip, Typography } from "@metasoccer/metasoccer-ds";
import { ReactElement, memo } from "react";

export interface TooltipProps {
	children: ReactElement;
	enabled?: boolean;
	text: string | ReactElement;
}

export const Tooltip = memo<TooltipProps>(({ children, enabled = true, text }) => {
	if (enabled) {
		return (
			<MsTooltip position="center">
				{{
					popper: typeof text === "string" ? <Typography variant="body2">{text}</Typography> : text,
					content: children
				}}
			</MsTooltip>
		);
	} else {
		return children;
	}
});
