import { NextMatchPreview } from "@/modules/shared/components/NextMatchPreview";
import { TwoColumnLayout } from "@/modules/shared/components/TwoColumnLayout";
import styled, { css } from "styled-components";

export const WrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const NextMatchPreviewStyled = styled(NextMatchPreview)(
	({ theme }) => css`
		padding: 40px 0;
	`
);

export const TwoColumnLayoutStyled = styled(TwoColumnLayout)(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.greyscale.gondola};
	`
);
