import styled, { css } from "styled-components";

interface CssSpinnerProps {
	position: "absolute" | "relative";
	size: "sm" | "md";
}

export const CssSpinner = styled.svg<CssSpinnerProps>(
	({ position, size }) => css`
		position: ${position};
		animation: rotate 2s linear infinite;
		width: ${size === "md" ? "50px" : "24px"};
		height: ${size === "md" ? "50px" : "24px"};

		& .path {
			stroke: white;
			stroke-linecap: round;
			animation: dash 1.5s ease-in-out infinite;
		}

		@keyframes rotate {
			100% {
				transform: rotate(360deg);
			}
		}
		@keyframes dash {
			0% {
				stroke-dasharray: 1, 150;
				stroke-dashoffset: 0;
			}
			50% {
				stroke-dasharray: 90, 150;
				stroke-dashoffset: -35;
			}
			100% {
				stroke-dasharray: 90, 150;
				stroke-dashoffset: -124;
			}
		}
	`
);
