import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { useGlobalState } from "@/modules/shared/context";
import { useMetaSoccerContract, useToast } from "@/modules/shared/hooks";
import { Button, Morale1Icon } from "@metasoccer/metasoccer-ds";
import { ContractName } from "@metasoccer/metasoccer-types";
import { memo, useCallback, useState } from "react";

export const AirdropRedeem = memo(() => {
	const { address } = useGlobalState();

	const [isRedeeming, setIsRedeeming] = useState(false);

	const { hasPass } = useAirdrop();

	const { contract: erc20RedeemerContract } = useMetaSoccerContract(ContractName.ERC20_REDEEMER);

	const { showToast, showErrToast } = useToast();

	const claimERC20 = useCallback(async () => {
		if (!address) return;
		if (isRedeeming) return;
		if (!erc20RedeemerContract) return;

		setIsRedeeming(true);

		try {
			await erc20RedeemerContract.call("claim", [], { gasLimit: 42000000 });
			showToast("Great! You've just received MSS 10,000. You’ll see your balance updated shortly. Enjoy!");
		} catch (err) {
			showErrToast("Couldn't claim your gift. Try again in a few minutes.");
		}

		setIsRedeeming(false);
	}, [address, isRedeeming, erc20RedeemerContract]);

	if (!hasPass) {
		return <></>;
	}

	return (
		<Button
			appearance="secondary"
			label="Claim gift!"
			loading={isRedeeming}
			leftIcon={!isRedeeming ? <Morale1Icon size="sm" /> : undefined}
			size="sm"
			onClick={claimERC20}
		/>
	);
});
