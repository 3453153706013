import styled, { css } from "styled-components";

export const CssToggleButtonRoot = styled.div<{ isSelected: boolean }>(
	({ theme, isSelected }) => css`
		display: flex;
		align-items: center;
		width: 100%;
		height: 40px;
		border: 1px solid ${isSelected ? "white" : theme.palette.greyscale.gondola};
		justify-content: center;
		cursor: pointer;

		background-color: ${isSelected ? "rgba(255, 255, 255, 0.15)" : ""};
	`
);
