import styled from "styled-components";

export const CssDrillContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px 12px;
	height: 118px;
`;

export const CssDrillChipsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const CssDrillChipsRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
