import { HomeButton } from "@/modules/home";
import { TOURNAMENT_ROUTES } from "@/modules/tournament/tournament.routes";
import { ArrowRightIcon, Col, Row, Typography, getImgUrl } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { ArrowWrapperStyled, HeaderStyled } from "./CommunityTournaments.styles";

export const CommunityTournaments = memo(() => {
	const theme = useTheme();

	const navigate = useNavigate();

	const goToCommunityTournamentsPage = () => {
		navigate(TOURNAMENT_ROUTES.ROOT.buildPath());
	};

	const goToMetaSoccerStarsBySuraPage = () => {
		navigate(TOURNAMENT_ROUTES.PARTNER.buildPath("sura-gaming"));
	};

	return (
		<Col>
			<HeaderStyled>
				<Typography variant="h5">Competitions</Typography>
			</HeaderStyled>
			<Col>
				<HomeButton height={64} onClick={goToCommunityTournamentsPage}>
					<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
						<Row alignItems="center" gap={16}>
							<img
								alt="Community Tournaments"
								src={getImgUrl("https://assets.metasoccer.com/competitions/CommunityTournament.png", {
									width: 128
								})}
								width={40}
							/>
							<Typography variant="buttonLg">Community Tournaments</Typography>
						</Row>
						<ArrowWrapperStyled>
							<ArrowRightIcon size="sm" />
						</ArrowWrapperStyled>
					</Row>
				</HomeButton>
				<div style={{ borderBottom: `1px solid ${theme.palette.greyscale.gondola}`, margin: "0 16px" }} />
				<HomeButton height={64} onClick={goToMetaSoccerStarsBySuraPage}>
					<Row alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
						<Row alignItems="center" gap={16}>
							<img
								alt="Sura Gaming Tournaments"
								src={getImgUrl("https://assets.metasoccer.com/competitions/MetaSoccerStarsBySura.png", {
									width: 128
								})}
								width={40}
							/>
							<Typography variant="buttonLg">Sura Gaming Tournaments</Typography>
						</Row>
						<ArrowWrapperStyled>
							<ArrowRightIcon size="sm" />
						</ArrowWrapperStyled>
					</Row>
				</HomeButton>
			</Col>
		</Col>
	);
});
