import { Scouting } from "@metasoccer/metasoccer-types";
import { getScoutingSecondsRemaining } from "@/modules/assets/views/ScoutProfile/helpers/getScoutingSecondsRemaining";

export const getScoutingTimeRemainingString = (lastScoutingOrSeconds?: Scouting | number): string => {
	if (!lastScoutingOrSeconds) {
		return "unknown";
	}

	const remainingSeconds =
		typeof lastScoutingOrSeconds === "number"
			? lastScoutingOrSeconds
			: getScoutingSecondsRemaining(lastScoutingOrSeconds);

	const days = remainingSeconds / 60 / 60 / 24;
	if (days > 1) {
		return `${Math.ceil(days)} days`;
	}

	const hours = remainingSeconds / 60 / 60;
	if (hours > 1) {
		return `${Math.ceil(hours)} hours`;
	}

	const minutes = remainingSeconds / 60;
	if (minutes > 1) {
		return `${Math.ceil(minutes)} minutes`;
	}

	return `${remainingSeconds} seconds`;
};
