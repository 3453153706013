import styled, { css } from "styled-components";

export const BackgroundStyled = styled.div<{ background: string }>(
	({ background, theme }) => css`
		display: flex;
		background: url(${background}) no-repeat;
		background-size: 100%;
		width: 100%;

		box-sizing: border-box;
		max-width: 100%;
	`
);

export const ContentStyled = styled.div<{ aspectRatio: number }>(
	({ aspectRatio, theme }) => css`
		width: 100%;
		padding-bottom: ${aspectRatio * 100}%;
		position: relative;
	`
);

export const BarsWrapperStyled = styled.div<{ reverse?: boolean }>(
	({ reverse, theme }) => css`
		display: flex;
		align-items: start;
		flex-direction: column;
		gap: 8px;
		height: 100%;
		left: ${reverse ? "50%" : "0"};
		position: absolute;
		width: 50%;
	`
);
