import { Response, HttpClient, MetaSoccerId, IdType, PlayoffsInfo, Division } from "@metasoccer/metasoccer-types";

export const PLAYOFF_START_TIME = 1671102000000;
export const PLAYOFF_END_TIME = 1671397200000;

export type KoGroup = {
	id: MetaSoccerId<IdType.TournamentGroup>;
	tournamentId: MetaSoccerId<IdType.Tournament>;
	name: string;
	division: Division;
	branches: string[];
};

export type GroupsResponse = {
	groups: KoGroup[];
	selectedGroupIndex: number;
	selectedBranchIndex: number;
};

export class WorldCupService {
	constructor(private readonly httpService: HttpClient) {}

	async getPlayoffsInfo(teamId: MetaSoccerId<IdType.Team>): Promise<PlayoffsInfo> {
		let response: Response<PlayoffsInfo>;
		try {
			response = await this.httpService.get<PlayoffsInfo>(`/world-cup/playoffs-info/${teamId}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getGroupsAndBranches(teamId?: MetaSoccerId<IdType.Team>) {
		let response: Response<GroupsResponse>;
		try {
			response = await this.httpService.get<PlayoffsInfo>(`/world-cup/groups?${teamId && `teamId=${teamId}`}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}
}
