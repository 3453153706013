import { ReactElement, memo } from "react";

import SantaHatWebP from "../assets/santa-hat.webp";

interface ChristmifyProps {
	if: boolean;
	children: ReactElement;
}

export const Christmify = memo<ChristmifyProps>(({ if: condition, children }) => {
	if (!condition) {
		return children;
	}

	return (
		<div style={{ position: "relative", display: "flex" }}>
			{children}
			<div
				style={{
					position: "absolute",
					top: "-42%",
					left: "42%",
					transform: "scaleX(-1) rotate(-18deg)",
					width: "100%"
				}}>
				<img src={SantaHatWebP} width="100%" />
			</div>
		</div>
	);
});
