import styled, { css } from "styled-components";

export const HeaderStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		padding: 24px;
	`
);

export const ArrowWrapperStyled = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
	`
);
