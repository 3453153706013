import { useIsMobile } from "@/layouts/context";
import { PlayerConditionBar } from "@/modules/lineup/shared/components/PlayerConditionBar";
import { EmptyEnergyIcon, NotRegisteredForNextMatch } from "@/modules/lineup/shared/components/PlayerConditions";
import { getCoveringBySpecificRole } from "@/modules/player/helpers/getCoveringBySpecificRole";
import { getPlayerShortName } from "@/modules/player/helpers/getPlayerShortName";
import { PlayerCardsIndicator } from "@/modules/shared/components/PlayerCardsIndicator";
import { useSelectedTeamState } from "@/modules/shared/context";
import { useDraggable } from "@dnd-kit/core";
import { InjuredIcon, RoleChip, RoleTypography, Row, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { Covering, LineupPlayerSkill, Player, PlayerSpecificRole } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { Shirt } from "../Shirt";
import {
	CssDragHook,
	CssPlayerCondition,
	CssPlayerInfo,
	CssPlayerInjurieOrRedcard,
	CssPlayerQuality,
	CssPlayerQualityMods,
	CssPlayerinjurieOrRedcardMods,
	CssWrapper
} from "./AssignedPlayerSlot.styles";

interface AssignedPlayerSlotProps {
	draggable?: boolean;
	isPlayerRegisteredInPve: boolean;
	player: Player;
	playerSkill: LineupPlayerSkill;
	shirtNumber: number;
	showPlayerSpecificRole?: boolean;
	showSlotId?: boolean;
	slotId: string;
	slotRole?: Covering;
	slotSpecificRoles?: PlayerSpecificRole[];
	onClick?: () => void;
}

export const AssignedPlayerSlot = memo<AssignedPlayerSlotProps>(
	({
		draggable,
		player,
		playerSkill,
		shirtNumber,
		showPlayerSpecificRole,
		showSlotId,
		slotId,
		slotRole,
		slotSpecificRoles,
		onClick
	}) => {
		const { getPlayerCards, isPlayerInjured, isPlayerRegisteredForNextMatch } = useSelectedTeamState();

		const playerCards = getPlayerCards(player.id);
		const playerInjured = isPlayerInjured(player.id);
		const playerNotRegisteredForNextMatch = !isPlayerRegisteredForNextMatch(player.id);

		const isMobile = useIsMobile();
		const theme = useTheme();

		const { attributes, listeners, transform, setNodeRef } = useDraggable({
			id: `S:${player.id}`,
			disabled: isMobile || !draggable
		});

		return (
			<CssWrapper onClick={onClick}>
				<Shirt number={shirtNumber} isGK={slotRole === Covering.GK} />
				<CssPlayerInfo>
					<PlayerConditionBar condition={player.condition} />
					<Typography variant="body2" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
						{getPlayerShortName(player.name, player.lastName)}
					</Typography>
					<Row gap={4} alignItems="center">
						{showSlotId && (
							<Typography variant="buttonMd" light>
								{slotId}
							</Typography>
						)}
						{slotRole && slotSpecificRoles && (
							<RoleTypography covering={slotRole} specificRoles={slotSpecificRoles} />
						)}
						{showPlayerSpecificRole && (
							<RoleChip
								size="sm"
								covering={getCoveringBySpecificRole(player.specificRole)}
								specificRole={player.specificRole}
							/>
						)}
					</Row>
				</CssPlayerInfo>

				<CssPlayerCondition>
					{player.condition < 20 && <EmptyEnergyIcon />}
					{playerNotRegisteredForNextMatch && <NotRegisteredForNextMatch />}
				</CssPlayerCondition>
				<CssPlayerQuality>
					<CssPlayerQualityMods>
						{playerSkill.penalty > 0 && (
							<Typography variant="caption" style={{ color: theme.palette.system.amaranth }}>
								{`-${playerSkill.penalty}`}
							</Typography>
						)}
						{playerSkill.bonus > 0 && (
							<Typography variant="caption" style={{ color: theme.palette.system.pastelGreen }}>
								{`+${playerSkill.bonus}`}
							</Typography>
						)}
					</CssPlayerQualityMods>
					<Typography variant="subtitle1">{playerSkill.skill}</Typography>
				</CssPlayerQuality>
				<CssPlayerInjurieOrRedcard>
					<CssPlayerinjurieOrRedcardMods>
						{playerCards && playerCards.length > 0 && (
							<PlayerCardsIndicator sanctionedPlayerCards={playerCards} />
						)}
						{playerInjured && <InjuredIcon size="sm" />}
					</CssPlayerinjurieOrRedcardMods>
				</CssPlayerInjurieOrRedcard>

				{draggable && <CssDragHook ref={setNodeRef} {...attributes} {...listeners} transform={transform} />}
			</CssWrapper>
		);
	}
);
