import React from "react";
import { LiveMatchContext } from "../LiveMatchContext";

const useLiveMatchState = () => {
	const context = React.useContext(LiveMatchContext);
	if (context === undefined) {
		throw new Error("useLiveMatchState must be used within a LiveMatchContextProvider");
	}
	return context;
};

export { useLiveMatchState };
