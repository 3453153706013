import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor } from "@dnd-kit/core";
import { Player, PlayerShirtNumberDict } from "@metasoccer/metasoccer-types";
import { FC, ReactNode, useState } from "react";

import { LineupPlayerDragOverlay } from "../LineupPlayer/LineupPlayerDragOverlay";

interface LineupDndContextProps {
	children: ReactNode;
	players: Player[];
	shirtNumbers: PlayerShirtNumberDict;
	onUpdateBench: (player: Player, slotIndex: number, removeIfSourceEqualsTarget: boolean) => void;
	onUpdateLineup: (player: Player, slotIndex: number, removeIfSourceEqualsTarget: boolean) => void;
}

export const LineupDndContext: FC<LineupDndContextProps> = ({
	children,
	players,
	shirtNumbers,
	onUpdateBench,
	onUpdateLineup
}) => {
	const [dragOverlay, setDragOverlay] = useState(false);
	const [draggingPlayer, setDraggingPlayer] = useState<Player>();

	const pointerSensor = useSensor(PointerSensor, {
		activationConstraint: {
			distance: 2
		}
	});

	const handleOnDragEnd = ({ over }: DragEndEvent) => {
		if (draggingPlayer && over?.id) {
			const slotId = over?.id?.toString();
			const slotType = slotId.charAt(0);
			const slotIndex = parseInt(slotId.substring(1)) - 1;
			const player = players.find(({ id }) => id === draggingPlayer.id)!;

			if (slotType === "L") onUpdateLineup(player, slotIndex, false);
			if (slotType === "B") onUpdateBench(player, slotIndex, false);

			setDragOverlay(false);
		}

		setDraggingPlayer(undefined);
	};

	const handleOnDragStart = ({ active }: DragStartEvent) => {
		const [_, id] = active.id.toString().split(":");
		const draggingPlayerId = parseInt(id);
		const player = players.find(({ id }) => id === draggingPlayerId);
		setDraggingPlayer(player);
		setDragOverlay(true);
	};

	return (
		<DndContext sensors={[pointerSensor]} onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
			{children}
			{dragOverlay && (
				<DragOverlay>
					{draggingPlayer && (
						<LineupPlayerDragOverlay
							shirtNumber={shirtNumbers[draggingPlayer.id]}
							player={draggingPlayer}
							playerSkill={{ bonus: 0, penalty: 0, skill: draggingPlayer.overall }}
							slotId="Dragging"
						/>
					)}
				</DragOverlay>
			)}
		</DndContext>
	);
};
