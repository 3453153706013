import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;

		& > *:only-child {
			margin: auto;
		}
	`
);
