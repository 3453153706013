import { Card } from "@/modules/shared/components/Card";
import styled, { css } from "styled-components";

export const CardStyled = styled(Card)(
	({ theme }) => css`
		height: 600px;
	`
);

export const RoundsWrapperStyled = styled.div<{ visible?: boolean; limitHeight?: boolean; hideScroll?: boolean }>(
	({ hideScroll, limitHeight = true, theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 46px;
		max-height: ${limitHeight ? "500px" : "100%"};
		overflow-x: hidden;
		overflow-y: ${hideScroll ? "hidden" : "scroll"};
		padding-bottom: 24px;
		width: 100%;
	`
);

export const WrapperStyled = styled.div(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.greyscale.gondola};
		padding: 12px;
	`
);
