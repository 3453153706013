import { useDroppable } from "@dnd-kit/core";
import { LineupPlayerSkill, LineupWithSkill, LockedPlayer, Player, Slot } from "@metasoccer/metasoccer-types";
import { FC, useCallback, useState } from "react";
import { CssWrapper } from "./LineupPlayer.styles";
import { AssignedPlayerSlot } from "./components/AssignedPlayerSlot";
import { ChangePlayerModal } from "./components/ChangePlayerModal";
import { NoPlayer } from "./components/NoPlayer";

interface LineupPlayerProps {
	disabled?: boolean;
	lineupWithSkill: LineupWithSkill;
	lockedPlayers?: LockedPlayer[];
	parentHeight: number;
	parentWidth: number;
	player?: Player;
	playerSkill?: LineupPlayerSkill;
	shirtNumber?: number;
	showPlayerSpecificRole: boolean;
	showSlotId: boolean;
	slot: Slot;
	slotId: string;
	onPlayerSelected: (player: Player) => void;
}

export const LineupPlayer: FC<LineupPlayerProps> = ({
	disabled,
	lineupWithSkill,
	lockedPlayers,
	parentHeight,
	parentWidth,
	player = undefined,
	playerSkill = undefined,
	shirtNumber,
	showPlayerSpecificRole,
	showSlotId,
	slot,
	slotId,
	onPlayerSelected
}) => {
	const { isOver, setNodeRef } = useDroppable({ id: slotId, disabled });

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = useCallback(() => {
		if (!disabled) {
			setIsModalOpen(true);
		}
	}, [disabled]);

	return (
		<CssWrapper
			ref={setNodeRef}
			isDisabled={disabled}
			isOver={isOver}
			parentHeight={parentHeight}
			parentWidth={parentWidth}
			x={slot.position.x}
			y={slot.position.y}>
			{player ? (
				<AssignedPlayerSlot
					draggable={!disabled}
					isPlayerRegisteredInPve={false}
					player={player}
					playerSkill={playerSkill!}
					shirtNumber={shirtNumber!}
					showPlayerSpecificRole={showPlayerSpecificRole}
					showSlotId={showSlotId}
					slotId={slotId}
					slotRole={slot.role}
					slotSpecificRoles={slot.playerSpecificRoles}
					onClick={openModal}
				/>
			) : (
				<NoPlayer
					disabled={disabled}
					slotRole={slot.role}
					showSlotId={slotId.startsWith("B")}
					slotId={slotId}
					slotSpecificRoles={slot.playerSpecificRoles ?? []}
					onClick={openModal}
				/>
			)}

			{isModalOpen && (
				<ChangePlayerModal
					isOpen={isModalOpen}
					currentPlayer={player}
					lineupWithSkill={lineupWithSkill}
					lockedPlayers={lockedPlayers}
					showSlotId={slotId.startsWith("B")}
					slotId={slotId}
					slotSpecificRoles={slot.playerSpecificRoles}
					onClose={() => setIsModalOpen(false)}
					onPlayerSelected={(player) => {
						onPlayerSelected(player);
						setIsModalOpen(false);
					}}
				/>
			)}
		</CssWrapper>
	);
};
