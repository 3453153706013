import { AuthenticationRequest, HttpClient, Response } from "@metasoccer/metasoccer-types";

export class AuthService {
	constructor(private readonly httpService: HttpClient) {}

	async authenticate(eip1271Address: string | undefined, signature: string, expiration: number): Promise<string> {
		let response: Response<string>;
		try {
			response = await this.httpService.post<AuthenticationRequest>("/authentication/login/", {
				eip1271Address,
				expiration,
				signature
			});
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
