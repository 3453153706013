import { memo } from "react";

interface MSLIconProps {
	width?: number | string;
	height?: number | string;
	fill?: string;
}

export const MSLIcon = memo<MSLIconProps>(({ width, height, fill }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 332 208" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_7123_79026)">
				<path
					d="M231.943 117.624L166 0L100.057 117.624L0 62.4652L49.6972 208H166H282.303L332 62.4652L231.943 117.624Z"
					fill={fill ?? "white"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_7123_79026">
					<rect width="332" height="208" fill="black" />
				</clipPath>
			</defs>
		</svg>
	);
});
