const defaultDomain = "localhost";

export const getDomain = (host?: string) => {
	if (!host) {
		if (typeof window !== "undefined") {
			host = window.location.hostname;
		} else {
			console.error("Domain not found");
			return defaultDomain;
		}
	}

	const chunks = host.split(".");
	if (chunks.length === 1 || chunks.length === 2) {
		return host;
	}

	if (chunks.length > 2) {
		return chunks.slice(chunks.length - 2).join(".");
	}
};
