import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import { Select as SelectBase } from "@peersyst/react-components";
import styled, { css } from "styled-components";
export var Select = styled(SelectBase)(function (_ref) {
  var theme = _ref.theme,
    _ref$variant = _ref.variant,
    variant = _ref$variant === void 0 ? "regular" : _ref$variant;
  if (variant == "regular") return;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tfont-family: ", ";\n\t\tcolor: ", ";\n\n\t\t.SelectDisplay {\n\t\t\tborder: none;\n\t\t\tbackground-color: ", ";\n\t\t\tmin-height: 48px;\n\t\t\tfont-family: ", ";\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t.SelectMenu {\n\t\t\tbackground-color: ", ";\n\t\t\tbackground-image: none;\n\t\t}\n\t"])), theme.typography.body1.style, theme.palette.greyscale.white, theme.palette.greyscale.gondola, theme.typography.body2.style.fontFamily, theme.typography.body2.style.color, theme.palette.greyscale.gondola);
});