import styled, { css } from "styled-components";

export const CssNextMatchBodyWrapper = styled.div(
	({ theme }) =>
		css`
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
			position: relative;

			${theme.breakpoints.up("desktopMin")} {
				gap: 40px;
			}
		`
);
