import { useIsMobile } from "@/layouts/context";
import { TrainingToken } from "@/modules/shared/components/tokens";
import { useGlobalState } from "@/modules/shared/context";
import { AddIcon, Button } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssTrainingFooterWrapper } from "./TrainingFooter.styled";

interface TrainingFooterProps {
	isAddDisabled: boolean;
	isStartDisabled: boolean;
	totalTrainingTokenCost: number;
	onAddDrillsClicked?: () => void;
	onStartClicked?: () => void;
}

export const TrainingFooter: FC<TrainingFooterProps> = ({
	isAddDisabled,
	isStartDisabled,
	totalTrainingTokenCost,
	onAddDrillsClicked = () => {},
	onStartClicked = () => {}
}) => {
	const isMobile = useIsMobile();
	const { isConnected } = useGlobalState();

	return (
		<CssTrainingFooterWrapper>
			<Button
				appearance="secondary"
				size={isMobile ? "md" : "lg"}
				label="Add drills"
				leftIcon={<AddIcon size="sm" />}
				fullWidth={isMobile}
				disabled={isAddDisabled || !isConnected}
				onClick={onAddDrillsClicked}
			/>
			<Button
				appearance="primary"
				size={isMobile ? "md" : "lg"}
				label="Start"
				rightIcon={totalTrainingTokenCost ? <TrainingToken quantity={totalTrainingTokenCost} /> : <></>}
				fullWidth={isMobile}
				disabled={isStartDisabled || !isConnected}
				onClick={onStartClicked}
			/>
		</CssTrainingFooterWrapper>
	);
};
