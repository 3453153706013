import { configService } from "@/dependencies";
import { useGlobalState } from "@/modules/shared/context";
import { DiscordIcon, Typography } from "@metasoccer/metasoccer-ds";
import { useTheme } from "styled-components";
import { CssFlex, CssMenuItem } from "../HeaderHoverMenu.styled";

export const UserMenuDiscordItem = () => {
	const theme = useTheme();

	const { account } = useGlobalState();

	const goToDiscord = () => {
		window.open("https://metasoccer.com/discord", "_blank");
	};

	const loginWithDiscord = () => {
		const clientId = "1229091313333309480";
		const redirectUri = `${configService.get().baseURL}/account/discord/oauth-callback`;

		window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
			redirectUri
		)}&response_type=code&scope=identify%20email`;
	};

	if (account?.discord_account) {
		return (
			<CssMenuItem onClick={() => goToDiscord()}>
				<CssFlex gap={12} verticalAlign="middle">
					<DiscordIcon fill={theme.palette.greyscale.gray} style={{ width: 16, height: 16 }} />
					<Typography variant="body2" light>
						{account.discord_account.username}
					</Typography>
				</CssFlex>
			</CssMenuItem>
		);
	} else {
		return (
			<CssMenuItem onClick={() => loginWithDiscord()}>
				<CssFlex gap={12} verticalAlign="middle">
					<DiscordIcon fill={theme.palette.greyscale.gray} style={{ width: 16, height: 16 }} />
					<Typography variant="body2">Connect Discord</Typography>
				</CssFlex>
			</CssMenuItem>
		);
	}
};
