export enum AllowedToken {
	MSU = 0,
	MSC = 1,
	BTC = 2,
	ETH = 3,
	DAI = 4,
	USDC = 5,
	USDT = 6,
	WMATIC = 7
}
