import getMSLRankBorderColors from "@/modules/msl/utils/getMSLRankBorderColors";
import { StandingsList } from "@/modules/shared/components";
import { useTheme } from "@metasoccer/metasoccer-ds";
import { TournamentGroupInfoDTO } from "@metasoccer/metasoccer-types";
import { memo, useMemo } from "react";

interface StandingsProps {
	groupInfo: TournamentGroupInfoDTO;
}

export const Standings = memo<StandingsProps>(({ groupInfo }) => {
	const theme = useTheme();

	const isMSL = groupInfo.tournamentEdition.tournamentId.startsWith("TN:MSL+");

	const borderColors = useMemo(() => {
		if (isMSL) {
			return getMSLRankBorderColors(theme.palette);
		} else {
			return undefined;
		}
	}, [isMSL]);

	return <StandingsList borderColors={borderColors} standing={groupInfo.standings} sliceTeams={false} />;
});
