import React from "react";
import { LayoutContext } from "./LayoutContext";

const useIsMobile = () => {
	const context = React.useContext(LayoutContext);
	if (context === undefined) {
		throw new Error("useIsMobile must be used within a LayoutContextProvider");
	}
	return context.isMobile;
};

export { useIsMobile };
