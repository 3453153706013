import styled, { css } from "styled-components";

export const CssContent = styled.div(
	({ theme }) => css`
		height: 100%;
		flex: 1 1 0%;
		overflow-y: auto;
		padding: 16px 12px;
		display: flex;
		flex-direction: column;
		align-items: center;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
		}
	`
);

export const CssListWrapper = styled.div(
	({ theme }) => css`
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;

		${theme.breakpoints.up("desktopMin")} {
			gap: 16px;
		}
	`
);

export const CssNoHistoryWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
		height: 100%;
	`
);
