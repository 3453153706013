import {
	CssInnerDot,
	CssInnerDotWrapper,
	CssOuterDot,
	CssOuterDotWrapper,
	CssRedDotWrapper
} from "@/modules/home/views/Deprecated/Playoffs/components/RedDotLive/RedDotLive.styled";
import { FC } from "react";

export interface RedDotLiveProps {}

export const RedDotLive: FC<RedDotLiveProps> = () => {
	return (
		<CssRedDotWrapper>
			<CssInnerDotWrapper>
				<CssInnerDot />
			</CssInnerDotWrapper>
			<CssOuterDotWrapper>
				<CssOuterDot />
			</CssOuterDotWrapper>
		</CssRedDotWrapper>
	);
};
