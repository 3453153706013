import { Alert, Container, Divider, Modal } from "@/modules/shared/components";
import { useBalanceContext } from "@/modules/shared/context";
import { useMetaSoccerContract, useToast } from "@/modules/shared/hooks";
import { formatMoney } from "@/modules/shared/utils";
import { SHOP_ROUTES } from "@/modules/shop";
import {
	Button,
	Col,
	HoverMenu,
	KnowledgeCoin,
	MsuCoin,
	Paper,
	Row,
	TextField,
	TrainingCoin,
	Typography,
	WalletIcon
} from "@metasoccer/metasoccer-ds";
import { ContractName, FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { parseEther } from "ethers/lib/utils";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WrapperStyled } from "./WalletMenu.styles";

export const WalletMenu = () => {
	const isAdminFeaturesEnabled = useFlag(FeatureFlag.AdminFeatures);

	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;

	const navigate = useNavigate();

	const balance = useBalanceContext();

	const [isLoading, setIsLoading] = useState(false);
	const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
	const [withdrawAddress, setWithdrawAddress] = useState("");
	const [withdrawAmount, setWithdrawAmount] = useState(0);

	const { contract } = useMetaSoccerContract(ContractName.MSU);

	const { showToast } = useToast();

	const isWithdrawInputValid =
		withdrawAmount > 0 && withdrawAddress.length === 42 && withdrawAddress.startsWith("0x");

	const withdraw = useCallback(async () => {
		if (!contract) return;
		if (!isWithdrawInputValid) return;

		setIsLoading(true);
		await contract.call("transfer", [withdrawAddress, parseEther(withdrawAmount.toString())]);
		setIsLoading(false);
		setIsWithdrawModalOpen(false);
		showToast("Your transfer has been successfully processed");
	}, [contract, isWithdrawInputValid, withdrawAddress, withdrawAmount]);

	return (
		<>
			<HoverMenu
				position="right"
				menuComponent={
					<WrapperStyled>
						<WalletIcon size="md" />
					</WrapperStyled>
				}>
				<Paper style={{ padding: 16 }}>
					<Col gap={16} style={{ width: 256 }}>
						<>
							<Col gap={8}>
								<Row alignItems="center" justifyContent="space-between">
									<Row alignItems="center" gap={8}>
										<MsuCoin size="sm" />
										<Typography variant="body2">MSS</Typography>
									</Row>
									<Typography variant="body2">{formatMoney(balance.msuTokens)}</Typography>
								</Row>
								<Col gap={8}>
									<Typography variant="caption" light>
										MSS is an ERC20 token with no monetary value, intended only as the exchange coin
										during the airdrop. Do not use it for anything else.
									</Typography>
									{(!isPlayToAirdropEnabled || isAdminFeaturesEnabled) && (
										<Row gap={8}>
											<Button
												appearance="primary"
												fullWidth
												label="Buy"
												size="sm"
												onClick={() => navigate(SHOP_ROUTES.ROOT.buildPath())}
											/>
											<Button
												appearance="secondary"
												fullWidth
												label="Transfer"
												size="sm"
												onClick={() => setIsWithdrawModalOpen(true)}
											/>
										</Row>
									)}
								</Col>
							</Col>
							<Divider />
						</>
						<Row alignItems="center" justifyContent="space-between">
							<Row alignItems="center" gap={8}>
								<KnowledgeCoin size="sm" />
								<Typography variant="body2">Knowledge Token</Typography>
							</Row>
							<Typography variant="body2">{formatMoney(balance.knowledgeTokens)}</Typography>
						</Row>
						<Divider />
						<Col gap={8}>
							<Row alignItems="center" justifyContent="space-between">
								<Row alignItems="center" gap={8}>
									<TrainingCoin size="sm" />
									<Typography variant="body2">Training Token</Typography>
								</Row>
								<Typography variant="body2">{formatMoney(balance.trainingTokens)}</Typography>
							</Row>
							<Button
								appearance="secondary"
								label="Buy Training Token"
								size="sm"
								onClick={() => navigate(SHOP_ROUTES.ROOT.buildPath())}
							/>
						</Col>
					</Col>
				</Paper>
			</HoverMenu>
			<Modal
				isOpen={isWithdrawModalOpen}
				mainAction={
					<Button
						disabled={!isWithdrawInputValid}
						fullWidth
						label="Transfer"
						loading={isLoading}
						onClick={withdraw}
					/>
				}
				title="Transfer MetaSoccer Gold"
				onClose={() => setIsWithdrawModalOpen(false)}>
				<Container>
					<Col gap={24}>
						<TextField
							type="number"
							min="0"
							step=".0001"
							name="amount"
							label="Amount"
							required
							value={withdrawAmount}
							validators={{
								lt: [balance.msuTokens, "Not enough balance"]
							}}
							onChange={setWithdrawAmount}
						/>
						<TextField
							type="text"
							name="address"
							label="Recipient"
							placeholder="Enter public address (0x)"
							required
							value={withdrawAddress}
							onChange={setWithdrawAddress}
						/>
						<Alert variant="warning">
							<Typography variant="body2">
								Transferring MSS is a final action! Ensure the wallet address you input is absolutely
								accurate. Mistakes cannot be reversed, so double-check every detail to prevent any loss
								of your assets.
							</Typography>
						</Alert>
					</Col>
				</Container>
			</Modal>
		</>
	);
};
