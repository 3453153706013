import { PveDifficulty } from "..";

export const PVE_TEAM_SUFFIX_BY_DIFFICULTY: Record<PveDifficulty, string> = {
	[PveDifficulty.STARTER]: "#1",
	[PveDifficulty.AMATEUR]: "#2",
	[PveDifficulty.BEGINNER]: "#3",
	[PveDifficulty.SEMI_PRO]: "#4",
	[PveDifficulty.PRO]: "#5",
	[PveDifficulty.LEGEND]: "#6"
};
