import { BracketRound, GroupsList, HttpClient, Response } from "@metasoccer/metasoccer-types";

export class KoTournamentService {
	constructor(private readonly httpService: HttpClient) {}
	async getBracketBranchByGroupAndBranchIds(groupId: string, branchId: number): Promise<BracketRound[]> {
		let response: Response<BracketRound[]>;
		try {
			response = await this.httpService.get<BracketRound[]>(`/ko/group/${groupId}/branch/${branchId}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getGroupList(tournamentId: string): Promise<GroupsList> {
		let response: Response<GroupsList>;
		try {
			response = await this.httpService.get<GroupsList>(`/ko/${tournamentId}/bracket/groups`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getLast(): Promise<string> {
		let response: Response<string>;
		try {
			response = await this.httpService.get<string>(`/ko/last`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
