import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { FC } from "react";
import { UserMenuDisconnectItem, UserMenuDiscordItem, UserMenuUserItem } from ".";
import { CssFlex, CssUserMenuDivider } from "../HeaderHoverMenu.styled";

interface UserMenuProps {
	toggleSheet: () => void;
}

export const UserMenu: FC<UserMenuProps> = ({ toggleSheet }) => {
	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;

	return (
		<CssFlex direction={"column"} gap={0} width={"100%"}>
			<UserMenuUserItem />
			<CssUserMenuDivider />
			{!isPlayToAirdropEnabled && <UserMenuDiscordItem />}
			<CssUserMenuDivider />
			<UserMenuDisconnectItem toggleSheet={toggleSheet} />
		</CssFlex>
	);
};
