import { Row, TabGroup, Tabs } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const OuterWrapperStyled = styled.div(({ theme }) => css``);

export const InnerWrapperStyled = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: flex-start;
		box-sizing: content-box;
		flex-direction: column;
		gap: 12px;

		${theme.breakpoints.up("desktopMin")} {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			gap: 24px;
		}
	`
);

export const RightWrapperStyled = styled.div(
	({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		padding: 0 12px 12px 12px;
		width: 100%;

		${theme.breakpoints.up("desktopMin")} {
			display: flex;
			align-items: center;
			border-bottom: none;
			max-height: 84px;
			padding: 24px;
			width: auto;
		}
	`
);

export const TabsWrapperStyled = styled(Row)<{ tabsPadding?: number | string }>(
	({ tabsPadding, theme }) => css`
		align-items: center;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		gap: 12px;
		min-height: 40px;
		padding: ${tabsPadding ?? "0 12px"};
		width: 100%;

		.TabGroupContainer {
			gap: 0;
		}

		.TabsTab {
			width: 120px;
		}

		${theme.breakpoints.up("desktopMin")} {
			border-bottom: none;
			padding: ${tabsPadding ?? "24px"};
			width: auto;
		}
	`
);

export const TabsStyled = styled(Tabs)(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		flex-grow: 1;
		max-width: 100%;
	`
);

export const TabGroupStyled = styled(TabGroup)(
	({ size, theme }) => css`
		height: auto;
		max-width: initial;
		min-width: initial;
		padding: 0;
		width: 100%;

		.TabGroupContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;

			${theme.breakpoints.up("desktopMin")} {
				gap: 24px;
				justify-content: unset;
			}
		}

		.TabIndicator {
			${theme.breakpoints.up("desktopMin")} {
				display: none;
			}
		}

		.TabsTab {
			width: auto;

			${theme.breakpoints.up("desktopMin")} {
				${size === "md" && "font-size: 1.625rem"};
			}
		}
	`
);

export const ContentWrapperStyled = styled.div(
	({ theme }) => css`
		height: 100%;

		${theme.breakpoints.up("desktopMin")} {
			& > * {
				padding-top: 0 !important;
			}
		}
	`
);
