import { CSS, Transform } from "@dnd-kit/utilities";
import styled, { css } from "styled-components";

export const CssWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 90px;
	position: relative;
	width: 100%;
`;

export const CssPlayerCondition = styled.div`
	position: absolute;
	right: 0;
	top: 25px;
`;

export const CssPlayerQuality = styled.div`
	position: absolute;
	left: 0;
	top: 25px;
`;

export const CssPlayerQualityMods = styled.div`
	position: absolute;
	bottom: 100%;
`;
export const CssPlayerInjurieOrRedcard = styled.div`
	position: absolute;
	right: 14px;
	top: 23px;
`;
export const CssPlayerinjurieOrRedcardMods = styled.div`
	position: absolute;
	bottom: 100%;
`;

export const CssPlayerInfo = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 4px;
	position: absolute;
	top: 44px;
`;

export const CssDragHook = styled.div<{ transform: Transform | null }>(
	({ transform }) => css`
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		transform: ${transform ? CSS.Transform.toString(transform) : "none"};
	`
);
