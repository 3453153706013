import styled, { css } from "styled-components";

export const CssWrapper = styled.div<{ variant: "small" | "default" }>(
	({ variant, theme }) => css`
		display: flex;
		align-items: center;
		height: 40px;
		justify-content: space-between;
		padding: ${variant === "default" ? 0 : "0 24px"};
	`
);
