import {
	CssAwayOverall,
	CssAwayOverallTypo,
	CssOverallTypography,
	CssTeam,
	CssTeamInfo,
	CssTeamName,
	CssTeamOverall
} from "./FriendlyMatchBoardTeam.styles";
import { LineupQualityStars } from "@/modules/shared/components/LineupQualityStars";
import React, { FC } from "react";

interface FriendlyMatchBoardTeam {
	team: { name: string; overall: number; clubLogo: React.ReactNode };
	isAway?: boolean;
}

export const FriendlyMatchBoardTeam: FC<FriendlyMatchBoardTeam> = ({ team, isAway = false }) => {
	return (
		<CssTeam isAway={isAway}>
			<CssTeamInfo isAway={isAway}>
				<CssTeamName variant={"body2"}>{team.name}</CssTeamName>
				{isAway ? (
					<LineupQualityStars quality={Math.round(team.overall)} qualityPosition="left" size="sm" />
				) : (
					<LineupQualityStars quality={team.overall} qualityPosition="right" size="sm" />
				)}
			</CssTeamInfo>
			<div style={{ width: "40px", height: "40px" }}>{team.clubLogo}</div>
		</CssTeam>
	);
};
