import { useServerState } from "@/modules/core/react-query/hooks";
import { HOME_QUERIES } from "@/modules/home/queries";
import { useGlobalState } from "@/modules/shared/context";
import {
	IdType,
	MetaSoccerId,
	TournamentDetailedStanding,
	TournamentDivisionSimplified,
	TournamentStructureEdition,
	isMetaSoccerId
} from "@metasoccer/metasoccer-types";
import { useEffect, useState } from "react";

interface UseStandingsState {
	standingsFilters: StandingsFilters | undefined;
	isLoading: boolean;
	isLoadingFilteredStanding: boolean;
	isError: boolean;
	tournamentStructureEditions?: TournamentStructureEdition[];
	filteredStanding?: TournamentDetailedStanding;
	divisionsWithRewards?: TournamentDivisionSimplified[];
	onEditionChange: (editionId: number) => () => void;
	onDivisionChange: (divisionId: string) => () => void;
	onGroupChange: (groupId: number) => () => void;
	onFilterChange: (filter: StandingsFilters) => void;
}

export interface StandingsFilters {
	divisionId: string;
	divisionName: string;
	editionId: number;
	editionName: string;
	groupId: number;
	groupName: string;
}

export const useStandings = (tournamentId: MetaSoccerId<IdType.Tournament>): UseStandingsState => {
	const { selectedTeam } = useGlobalState();

	const [standingsFilters, setStandingsFilters] = useState<StandingsFilters>();

	const standingsQuery = HOME_QUERIES.detailedStandings(selectedTeam?.id);
	const {
		data: standing,
		isLoading: isStandingLoading,
		isError: isStandingError
	} = useServerState(standingsQuery, { enabled: !!selectedTeam });

	useEffect(() => {
		if (standing && !standingsFilters) {
			setStandingsFilters({
				divisionId: standing.divisionId,
				divisionName: standing.divisionName,
				editionId: standing.editionId,
				editionName: standing.editionName.split(" ")[1],
				groupId: standing.groupId,
				groupName: standing.groupName.split(" ")[1]
			});
		}
	}, [standing, standingsFilters]);

	const tournamentStructureQuery = HOME_QUERIES.tournamentStructureEditions(tournamentId);
	const {
		data: tournamentStructureEditions,
		isLoading: isLoadingTournamentStructure,
		isError: isErrorTournamentStructure
	} = useServerState(tournamentStructureQuery, {
		enabled: !!tournamentId && isMetaSoccerId(tournamentId, IdType.Tournament)
	});

	const rankingsQuery = HOME_QUERIES.filteredDetailedStandings(
		tournamentId,
		standingsFilters?.editionId,
		standingsFilters?.divisionId,
		standingsFilters?.groupId
	);
	const { data: filteredStanding, isLoading: isLoadingFilteredStanding } = useServerState(rankingsQuery, {
		enabled: !!standingsFilters && !!tournamentId && isMetaSoccerId(tournamentId, IdType.Tournament)
	});

	const divisionsWithRewardsQuery = HOME_QUERIES.rewardsByTournamentAndEdition(
		tournamentId,
		standingsFilters?.editionId
	);
	const {
		data: divisionsWithRewards,
		isLoading: isLoadingDivisionWithRewards,
		isError: isErrorDivisionWithRewards
	} = useServerState(divisionsWithRewardsQuery, {
		enabled: !!standingsFilters && !!tournamentId && isMetaSoccerId(tournamentId, IdType.Tournament)
	});

	const onEditionChange = (editionId: number) => () => {
		const edition = tournamentStructureEditions?.find((edition) => edition.id === editionId);
		if (!edition) {
			return;
		}

		if (edition.divisions.length > 0) {
			setStandingsFilters({ ...standingsFilters!, editionId: edition.id, editionName: edition.name });
			return;
		}

		const firstDivision = edition.divisions[0];
		if (firstDivision.groups.length === 0) {
			setStandingsFilters({
				...standingsFilters!,
				editionId: edition.id,
				editionName: edition.name,
				divisionId: firstDivision.categoryId,
				divisionName: firstDivision.name
			});
			return;
		}

		const firstGroup = firstDivision.groups[0];
		setStandingsFilters({
			...standingsFilters!,
			editionId: edition.id,
			editionName: edition.name,
			divisionId: firstDivision.categoryId,
			divisionName: firstDivision.name,
			groupId: firstGroup.id,
			groupName: firstGroup.name.split(" ")[1]
		});
	};

	const onDivisionChange = (divisionId: string) => () => {
		const divisions = tournamentStructureEditions?.find(
			(edition) => edition.id === standingsFilters?.editionId
		)?.divisions;
		if (!divisions) {
			return;
		}

		const division = divisions.find((division) => division.categoryId === divisionId);
		if (!division) {
			return;
		}

		if (division.groups.length === 0) {
			setStandingsFilters({ ...standingsFilters!, divisionId: division.categoryId, divisionName: division.name });
			return;
		}

		const firstGroup = division.groups[0];
		setStandingsFilters({
			...standingsFilters!,
			divisionId: division.categoryId,
			divisionName: division.name,
			groupId: firstGroup.id,
			groupName: firstGroup.name.split(" ")[1]
		});
	};

	const onGroupChange = (groupId: number) => () => {
		const groups = tournamentStructureEditions
			?.find((edition) => edition.id === standingsFilters?.editionId)
			?.divisions.find((division) => division.categoryId === standingsFilters?.divisionId)?.groups;
		if (!groups) {
			return;
		}

		const group = groups.find((group) => group.id === groupId);
		if (!group) {
			return;
		}

		setStandingsFilters({ ...standingsFilters!, groupId: group.id, groupName: group.name.split(" ")[1] });
	};

	const onFilterChange = (filter: StandingsFilters) => setStandingsFilters(filter);

	const isLoading = isLoadingTournamentStructure || isLoadingDivisionWithRewards || isStandingLoading;
	const isError =
		isErrorTournamentStructure ||
		isErrorDivisionWithRewards ||
		isStandingError ||
		!tournamentId ||
		!isMetaSoccerId(tournamentId, IdType.Tournament);

	return {
		standingsFilters,
		isLoading,
		isLoadingFilteredStanding,
		isError,
		tournamentStructureEditions,
		filteredStanding,
		divisionsWithRewards,
		onEditionChange,
		onDivisionChange,
		onGroupChange,
		onFilterChange
	};
};
