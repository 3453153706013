import styled, { css } from "styled-components";

export const CssModalWrapper = styled.div(
	({ theme }) => css`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
	`
);

const gridColumn = css(
	({ theme }) => css`
		align-items: center;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		cursor: pointer;
		display: flex;
		height: 60px;
		justify-content: center;
		text-align: center;
		width: 100%;

		&:hover {
			background-color: ${theme.palette.greyscale.scorpion};
		}

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			width: 100%;
		}
	`
);

export const CssLineupColumnItem = styled.div<{ columnStart: number; isSelected: boolean }>(
	({ columnStart, theme, isSelected }) => css`
		${gridColumn};
		background-color: ${isSelected ? theme.palette.tint.white15 : ``};
		border-right: ${columnStart === 1 ? `1px solid ${theme.palette.greyscale.gondola}` : ``};
		${isSelected && `border: 1px solid ${theme.palette.greyscale.white};`}
		grid-column-start: ${columnStart};
	`
);

export const CssLineupColumnItemLast = styled.div<{ isSelected: boolean }>(
	({ isSelected, theme }) => css`
		${gridColumn};
		background-color: ${isSelected ? theme.palette.greyscale.scorpion : ``};
		grid-column-start: 1;
		grid-column-end: 3;
	`
);
