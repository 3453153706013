import { teamService } from "@/dependencies";
import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId, TeamDelegationRequest } from "@metasoccer/metasoccer-types";

export const TEAM_MUTABLE_QUERIES = {
	cancelDelegation: () => ({
		mutationKey: [BaseServerStateKeys.TEAM, "cancelDelegation"],
		mutationFn: (teamId: MetaSoccerId<IdType.Team>) => teamService.cancelDelegateTeam(teamId)
	}),
	createFromPlayerIds: () => ({
		mutationKey: [BaseServerStateKeys.TEAM, "createFromPlayerIds"],
		mutationFn: (playerIds: number[]) => teamService.createTeamFromPlayerIds(playerIds)
	}),
	delegateTeam: () => ({
		mutationKey: [BaseServerStateKeys.TEAM, "delegateTeam"],
		mutationFn: (teamDelegationRequest: TeamDelegationRequest) => teamService.delegateTeam(teamDelegationRequest)
	}),
	deleteTeam: () => ({
		mutationKey: [BaseServerStateKeys.TEAM, "deleteTeam"],
		mutationFn: (teamId: MetaSoccerId<IdType.Team>) => teamService.deleteTeam(teamId)
	}),
	renounceDelegation: () => ({
		mutationKey: [BaseServerStateKeys.TEAM, "renounceDelegation"],
		mutationFn: ({ teamId }: { teamId: MetaSoccerId<IdType.Team> }) => teamService.renounceDelegation(teamId)
	}),
	updateSquadPlayers: () => ({
		mutationKey: [BaseServerStateKeys.TEAM, "updateSquadPlayers"],
		mutationFn: ({ teamId, playerIds }: { teamId: MetaSoccerId<IdType.Team>; playerIds: number[] }) =>
			teamService.updateSquadPlayers(teamId, playerIds)
	})
};
