import { Formation, HttpClient, Response } from "@metasoccer/metasoccer-types";
import { FlatLineup } from "@metasoccer/metasoccer-types/dist/@types/lineup/FlatLineup";

export class LineupService {
	constructor(private readonly httpService: HttpClient) {}

	async getAllFormations(): Promise<Formation[]> {
		let response: Response<{ formations: Formation[] }>;
		try {
			response = await this.httpService.get<{ formations: Formation[] }>(`/formations/all`);
		} catch (e) {
			throw e;
		}

		return response.data.formations;
	}

	async updateLineup(lineup: FlatLineup, teamId: string): Promise<any> {
		let response: Response<any>;
		try {
			response = await this.httpService.post<any>(`/lineups/${teamId}`, lineup);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
