import styled, { css } from "styled-components";

export const CssPvpTeamInfoWrapper: any = styled.div<{ direction?: "column" | "row"; reverse?: boolean }>(
	({ direction, reverse, theme }) => css`
		display: flex;
		align-items: center;
		gap: 16px;

		${direction === "column" && "flex-direction: column-reverse;"}
		${direction === "row" && (reverse ? "flex-direction: row-reverse;" : "flex-direction: row;")}
		justify-content: ${direction === "column" ? "center" : "flex-end"};
		width: 40%;
	`
);
export const CssPvpTeamInfo: any = styled.div<{ reverse?: boolean }>(
	({ theme, reverse }) => css`
		display: flex;
		flex-direction: column;
		gap: 16px;
	`
);

export const CssPvpTeamName: any = styled.div<{ reverse?: boolean }>(
	({ reverse }) => css`
		display: flex;
		flex-direction: ${reverse ? "row-reverse" : "row"};
		gap: 8px;
	`
);

export const CssStreakContainer = styled.div`
	display: flex;
	gap: 4px;
	justify-content: flex-end;
`;
