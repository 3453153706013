import { Outlet, Route, Routes } from "react-router-dom";
import { AccountPage, DiscordOAuthCallbackPage } from "./views";

const AccountRouter = () => {
	return (
		<>
			<Routes>
				<Route path="" element={<AccountPage />} />
				<Route path="discord/oauth-callback" element={<DiscordOAuthCallbackPage />} />
			</Routes>
			<Outlet />
		</>
	);
};

export default AccountRouter;
