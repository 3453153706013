import { useIsMobile } from "@/layouts/context";
import { Col, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { getImgUrl } from "../../utils";
import { ConnectWallet } from "../ConnectWallet";

export const Login = () => {
	const theme = useTheme();
	const isMobile = useIsMobile();

	const randomNumber = 1; // Math.floor(Math.random() * 6) + 1;

	return (
		<div
			style={{
				background: theme.palette.backgrounds.codGray,
				inset: 0,
				position: "absolute",
				zIndex: 10000
			}}>
			<div
				style={{
					display: "flex",
					alignItems: "stretch",
					flexDirection: isMobile ? "column" : "row",
					height: "100%",
					justifyContent: "center",
					width: "100%"
				}}>
				<Col
					flex={1.42}
					gap={16}
					style={{
						background: `url(${getImgUrl(
							"https://assets.metasoccer.com/ui/login/" + randomNumber + ".png"
						)})`,
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						height: "100%",
						padding: 32
					}}
				/>
				<Col flex={1} alignItems="center" justifyContent="center" style={{ height: "100%", padding: 32 }}>
					<Col gap={32} alignItems="center" justifyContent="center" style={{ width: 384, maxWidth: "100%" }}>
						<img src={require("./assets/Manag3r.png")} height={28} alt="MetaSoccer Manag3r" />
						{!isMobile && (
							<Typography variant="body2" light textAlign="center">
								Sign in now to create your club in MetaSoccer, where your management skills and strategy
								earn real rewards. Start your journey as a true club owner today!
							</Typography>
						)}
						<ConnectWallet />
					</Col>
				</Col>
			</div>
		</div>
	);
};
