export enum BaseServerStateKeys {
	AIRDROP = "airdrop",
	BEST_PLAYERS_OVERALL = "best_players_overall",
	BUY_WITH_CC = "buyWithCC",
	CLUB = "club",
	CREATE_PVP_TOURNAMENT = "createPvpTournament",
	CUSTOM_TOURNAMENT_INFO = "custom_tournament_info",
	CUSTOM_TOURNAMENT_INVITE_TEAMS = "custom_tournament_invite_teams",
	CUSTOM_TOURNAMENT_REGISTRATION = "custom_tournament_registration",
	CUSTOM_TOURNAMENT_REWARDS = "custom_tournament_rewards",
	CUSTOM_TOURNAMENT_SEARCH_TEAMS = "custom_tournament_search_teams",
	CUSTOM_TOURNAMENT_SEARCH_TEAMS_IDS = "custom_tournament_search_teams_ids",
	CUSTOM_TOURNAMENT_TEAM_INFO = "custom_tournament_team_info",
	CUSTOM_TOURNAMENTS_EXPLORE = "custom_tournaments_explore",
	CUSTOM_TOURNAMENTS_OF_INTEREST = "custom_tournaments_of_interest",
	FORMATIONS = "formations",
	HOME = "home",
	INBOX = "inbox",
	IN_GAME_TOKENS = "inGameTokens",
	JOIN_PVP_TOURNAMENT = "joinPvpTournament",
	JOIN_TOURNAMENT = "joinTournament",
	KO_TOURNAMENT = "koTournament",
	LINEUP_WITH_SKILL = "lineupWithSkill",
	LOCKED_PLAYERS = "locked_players",
	MANAGED_TEAMS = "managedTeams",
	MATCH = "match",
	MSL = "msl",
	PLAY = "play",
	PLAY_PVE = "play_pve",
	PLAYER_CARDS = "player_cards",
	PLAYERS = "players",
	PLAYERS_WITH_SHIRT_NUMBER = "playersWithShirtNumber",
	PLAYOFFS_GROUPS = "playoffsGroups",
	PLAYOFFS_INFO = "playoffsInfo",
	PVP_NEXT_MATCH = "pvpNextMatch",
	PVP_SCHEDULED_MATCHES = "pvpScheduledMatches",
	PVP_STANDING = "pvpStanding",
	PVP_TEAM_STATS = "pvpTeamStats",
	REWARDS = "rewards",
	SCOUTING = "scouting",
	SCOUTS = "scouts",
	STANDINGS = "standings",
	STRATEGY = "strategy",
	TEAM = "team",
	TOURNAMENT_EDITIONS = "tournamentEditions",
	TOURNAMENTS = "tournaments",
	TRAINING = "training"
}
