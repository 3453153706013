import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import { TabGroup } from "@peersyst/react-components";
import styled, { css } from "styled-components";
var lgStyles = css(function (_ref) {
  var theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\theight: 80px;\n\t\tpadding: 16px 24px;\n\t"])));
});
var mdStyles = css(function (_ref2) {
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\theight: 52px;\n\t"])));
});
var sizeStyles = {
  lg: lgStyles,
  md: mdStyles
};
export var TabGroupRoot = styled(TabGroup)(function (_ref3) {
  var theme = _ref3.theme,
    size = _ref3.size;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\t", ";\n\n\t\t.TabGroupContainer {\n\t\t\tcolumn-gap: ", ";\n\t\t\tpadding: 0;\n\t\t\toverflow-x: auto;\n\t\t\theight: 100%;\n\n\t\t\t.TabIndicator {\n\t\t\t\tbackground-color: ", ";\n\t\t\t\theight: 0.188rem;\n\t\t\t}\n\t\t}\n\t"])), sizeStyles[size], size === "md" ? "24px" : "32px", theme.palette.greyscale.white);
});