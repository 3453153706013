import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import { Col } from "@peersyst/react-components";
import styled, { css } from "styled-components";
export var SidebarRoot = styled(Col)(function (_ref) {
  var theme = _ref.theme,
    active = _ref.active,
    enabled = _ref.enabled,
    type = _ref.type;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tcursor: pointer;\n\t\twidth: 100%;\n\t\tgap: 4px;\n\t\talign-items: center;\n\t\tflex: 1 1 0%;\n\n\t\t&.Enabled:hover {\n\t\t\t* {\n\t\t\t\t", "\n\t\t\t\t", "\n\t\t\t}\n\t\t\t.club.Enabled {\n\t\t\t\tpath {\n\t\t\t\t\tfill: ", "!important;\n\t\t\t\t\tstroke: none;\n\t\t\t\t}\n\t\t\t}\n\t\t\t.market.Enabled {\n\t\t\t\tpath {\n\t\t\t\t\tfill: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t\t.training.Enabled {\n\t\t\t\tpath:nth-child(2) {\n\t\t\t\t\tfill: ", "!important;\n\t\t\t\t\tstroke: none;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\t.Typography {\n\t\t\ttext-align: center;\n\t\t\t", "\n\t\t}\n\n\t\t&.Disabled {\n\t\t\topacity: 0.42;\n\t\t\t.Typography {\n\t\t\t\tcolor: ", ";\n\t\t\t}\n\t\t}\n\t"])), !active && enabled ? "stroke:".concat(theme.palette.greyscale.mercury, ";") : "", !active && enabled && type !== "club" ? "color:".concat(theme.palette.greyscale.mercury, ";") : "", theme.palette.greyscale.mercury, theme.palette.greyscale.mercury, theme.palette.greyscale.mercury, active ? "color:".concat(theme.palette.greyscale.mercury, ";") : "", theme.palette.greyscale.scorpion);
});
export var CssIconWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\twidth: 40px;\n\theight: 32px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n"])));