import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
import { Button } from "@peersyst/react-components";
import styled, { css } from "styled-components";
var primaryAppearance = css(function (_ref) {
  var theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tbackground-color: ", ";\n\t\tborder-color: ", ";\n\t\tcolor: ", ";\n\n\t\t&:hover {\n\t\t\tbackground-color: ", ";\n\t\t\tborder-color: ", ";\n\t\t}\n\n\t\t&:active {\n\t\t\tbackground-color: ", ";\n\t\t\tborder-color: ", ";\n\t\t}\n\n\t\t&:disabled {\n\t\t\tbackground-color: ", ";\n\t\t\tborder-color: ", ";\n\t\t\tcolor: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.white, theme.palette.greyscale.white, theme.palette.backgrounds.cinder, theme.palette.greyscale.mercury, theme.palette.greyscale.mercury, theme.palette.greyscale.wildSand, theme.palette.greyscale.wildSand, theme.palette.greyscale.gondola, theme.palette.greyscale.gondola, theme.palette.greyscale.scorpion);
});
var secondaryAppearance = css(function (_ref2) {
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tbackground-color: transparent;\n\t\tcolor: ", ";\n\t\tborder-color: ", ";\n\n\t\t&:hover {\n\t\t\tbackground-color: ", ";\n\t\t}\n\n\t\t&:active {\n\t\t\tbackground-color: transparent;\n\t\t\tborder-color: ", ";\n\t\t}\n\n\t\t&:disabled {\n\t\t\tborder-color: transparent;\n\t\t\tbackground-color: ", ";\n\t\t\tcolor: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.white, theme.palette.greyscale.gray, theme.palette.backgrounds.codGray, theme.palette.greyscale.white, theme.palette.greyscale.gondola, theme.palette.greyscale.scorpion);
});
var tertiaryAppearance = css(function (_ref3) {
  var theme = _ref3.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\tbackground-color: transparent;\n\t\tcolor: ", ";\n\t\tborder-color: transparent;\n\n\t\t&:hover {\n\t\t\tbackground-color: ", ";\n\t\t}\n\n\t\t&:active {\n\t\t\tbackground-color: transparent;\n\t\t\tborder-color: ", ";\n\t\t}\n\n\t\t&:disabled {\n\t\t\tborder-color: transparent;\n\t\t\tcolor: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.white, theme.palette.backgrounds.codGray, theme.palette.greyscale.white, theme.palette.greyscale.scorpion);
});
var textAppearance = css(function (_ref4) {
  var theme = _ref4.theme;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\tbackground-color: transparent;\n\t\tcolor: ", ";\n\t\tborder-color: transparent;\n\n\t\t&:hover {\n\t\t\ttext-decoration: underline;\n\t\t}\n\n\t\t&:active {\n\t\t\ttext-decoration: none;\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t&:disabled {\n\t\t\ttext-decoration: none;\n\t\t\tcolor: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.white, theme.palette.greyscale.gray, theme.palette.greyscale.scorpion);
});
var groupAppearance = css(function (_ref5) {
  var theme = _ref5.theme;
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\t\tbackground-color: transparent;\n\t\tcolor: ", ";\n\t\tborder-color: ", ";\n\n\t\t&:hover {\n\t\t\tbackground-color: ", ";\n\t\t}\n\n\t\t&:active {\n\t\t\tbackground-color: ", ";\n\t\t\tborder-color: ", ";\n\t\t\tborder-color: ", ";\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t&:disabled {\n\t\t\tbackground-color: ", ";\n\t\t\tcolor: ", ";\n\t\t\tborder-color: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.white, theme.palette.greyscale.gondola, theme.palette.backgrounds.codGray, theme.palette.greyscale.white, theme.palette.greyscale.white, theme.palette.greyscale.white, theme.palette.backgrounds.cinder, theme.palette.greyscale.gondola, theme.palette.greyscale.scorpion, theme.palette.greyscale.gondola);
});
var buttonAppearances = {
  primary: primaryAppearance,
  secondary: secondaryAppearance,
  tertiary: tertiaryAppearance,
  text: textAppearance,
  group: groupAppearance
};
var hugeStyle = css(function (_ref6) {
  var theme = _ref6.theme;
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\t\theight: 5rem;\n\t\tpadding: 0 2rem;\n\t\t", ";\n\t"])), theme.typography.buttonLg.style);
});
var xlStyle = css(function (_ref7) {
  var theme = _ref7.theme;
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\t\theight: 4rem;\n\t\tpadding: 0 1.5rem;\n\t\t", ";\n\t"])), theme.typography.buttonLg.style);
});
var lgStyle = css(function (_ref8) {
  var theme = _ref8.theme;
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\t\theight: 3rem;\n\t\tpadding: 0 1rem;\n\t\t", ";\n\t"])), theme.typography.buttonLg.style);
});
var mdStyle = css(function (_ref9) {
  var theme = _ref9.theme;
  return css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n\t\theight: 2.5rem;\n\t\tpadding: 0 0.75rem;\n\t\t", ";\n\t"])), theme.typography.buttonMd.style);
});
var smStyle = css(function (_ref10) {
  var theme = _ref10.theme;
  return css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n\t\theight: 2rem;\n\t\tpadding: 0 0.5rem;\n\t\t", ";\n\t"])), theme.typography.buttonSm.style);
});
var sizeStyles = {
  huge: hugeStyle,
  xl: xlStyle,
  lg: lgStyle,
  md: mdStyle,
  sm: smStyle
};
export var CoreButtonRoot = styled(Button)(function (_ref11) {
  var appearance = _ref11.appearance,
    size = _ref11.size,
    noPadding = _ref11.noPadding;
  return css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n\t\t--iconSize: 1rem;\n\n\t\tborder: 1px solid;\n\t\ttransition: background-color 0.1s, color 0.1s, border-color 0.1s;\n\t\tmin-width: unset;\n\t\tbox-sizing: border-box;\n\n\t\t", ";\n\t\t", ";\n\n\t\t", ";\n\n\t\t&:disabled {\n\t\t\tfilter: unset;\n\t\t\topacity: 1;\n\t\t}\n\n\t\t&.Loading {\n\t\t\topacity: 1;\n\t\t}\n\n\t\t:active {\n\t\t\tbox-shadow: none;\n\t\t}\n\t"])), buttonAppearances[appearance], sizeStyles[size], noPadding && css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n\t\t\tpadding: 0;\n\t\t"]))));
});