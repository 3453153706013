export enum PveDifficulty {
	STARTER = 0,
	AMATEUR = 1,
	BEGINNER = 2,
	SEMI_PRO = 3,
	PRO = 4,
	LEGEND = 5
}

export const pveDifficultyIntervals: Record<PveDifficulty, [number, number]> = {
	[PveDifficulty.STARTER]: [24, 26],
	[PveDifficulty.AMATEUR]: [32, 34],
	[PveDifficulty.BEGINNER]: [42, 44],
	[PveDifficulty.SEMI_PRO]: [66, 68],
	[PveDifficulty.PRO]: [80, 82],
	[PveDifficulty.LEGEND]: [96, 98]
};

export const pveDifficultyDisplayOrder = [
	PveDifficulty.STARTER,
	PveDifficulty.AMATEUR,
	PveDifficulty.BEGINNER,
	PveDifficulty.SEMI_PRO,
	PveDifficulty.PRO,
	PveDifficulty.LEGEND
];

export function pveDifficultyToString(pveDifficulty: PveDifficulty): string {
	switch (pveDifficulty) {
		case PveDifficulty.STARTER:
			return "Starter";
		case PveDifficulty.AMATEUR:
			return "Amateur";
		case PveDifficulty.BEGINNER:
			return "Beginner";
		case PveDifficulty.SEMI_PRO:
			return "Semi Pro";
		case PveDifficulty.PRO:
			return "Pro";
		case PveDifficulty.LEGEND:
			return "Legend";
	}
}
