import { Typography } from "@metasoccer/metasoccer-ds";
import styled from "styled-components";

export const CssWrapper = styled.div`
	position: relative;
	height: 48px;
`;

export const CssNumber = styled(Typography)`
	color: black;
	left: 50%;
	margin-left: -1px;
	position: absolute;
	top: 11px;
	transform: translateX(-50%);
`;
