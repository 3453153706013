import styled from "styled-components";

export const CssContent = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
`;

export const CssContentRow = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px 32px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
	cursor: pointer;
`;

export const CssButtonWrapper = styled.div`
	padding: 20px 12px;
`;
