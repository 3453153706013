import { DEFAULT_SPECIFIC_ROLE_SORTING_MAP } from "@/modules/player/constants";
import { getCoveringBySpecificRole } from "@/modules/player/helpers/getCoveringBySpecificRole";
import { sortPlayersBySpecificRole } from "@/modules/player/helpers/sortPlayersBySpecificRole";
import { ColumnDef, RoleChip, Typography } from "@metasoccer/metasoccer-ds";
import {
	InjuredLockedPlayer,
	LineupWithSkill,
	LockedPlayer,
	Player,
	PlayerShirtNumberDict,
	PlayerSpecificRole,
	SpecialPlayerSkill,
	TournamentCardFrontend
} from "@metasoccer/metasoccer-types";
import { memo, useMemo } from "react";
import { useSelectedTeamState } from "../../context";
import { compact, defineIf } from "../../utils";
import { Table, TableOptions } from "../Table";
import { ConditionCell, LineupCell, QualityCell, SpecialAbilitiesCell, TitleCell } from "./components";
import { playerToPlayerTableItem } from "./helpers";
import tooltipToPlayerTableTitles from "./helpers/tooltipToPlayerTableTitles";

export enum PlayerTableColumn {
	"slotSpecificRoles" = "slotSpecificRoles",
	"fullName" = "fullName",
	"specificRole" = "specificRole",
	"specialAbilities" = "specialAbilities",
	"overall" = "overall",
	"age" = "age",
	"condition" = "condition"
}

const DEFAULT_COLUMNS = [
	PlayerTableColumn.slotSpecificRoles,
	PlayerTableColumn.fullName,
	PlayerTableColumn.specificRole,
	PlayerTableColumn.specialAbilities,
	PlayerTableColumn.overall,
	PlayerTableColumn.age,
	PlayerTableColumn.condition
];

interface PlayerTableProps {
	columns?: PlayerTableColumn[];
	lineupWithSkill?: LineupWithSkill;
	lockedPlayers?: LockedPlayer[];
	players: Player[];
	shirtNumbers?: PlayerShirtNumberDict;
	tableOptions?: PlayerTableOptions;
	onRowClick?: (player: Player) => void;
	onSelectionChange?: (players: Player[]) => void;
}

export interface PlayerTableItem {
	id: number;
	_player: Player;
	age: number;
	condition: number;
	fullName: string;
	injuredPlayer?: InjuredLockedPlayer;
	overall: number;
	playerRegisteredForNextMatch?: boolean;
	potential: number;
	sanctionedPlayerCards?: TournamentCardFrontend[];
	shirtNumber?: number;
	slotBenchTag?: string;
	slotSpecificRole?: PlayerSpecificRole;
	specialAbilities: SpecialPlayerSkill[];
	specificRole: PlayerSpecificRole;
}

export interface PlayerTableOptions extends TableOptions {
	linkToPlayer?: boolean;
	showPlayerPip?: boolean;
}

export const PlayerTable = memo<PlayerTableProps>(
	({
		columns = DEFAULT_COLUMNS,
		lineupWithSkill,
		players,
		shirtNumbers = {},
		tableOptions,
		lockedPlayers,
		onRowClick,
		onSelectionChange
	}) => {
		const { getPlayerCards, isPlayerInjured, isPlayerRegisteredForNextMatch } = useSelectedTeamState();

		const items = useMemo(
			() =>
				players.sort(sortPlayersBySpecificRole).map((player) => {
					return playerToPlayerTableItem(
						player,
						isPlayerInjured(player.id),
						lineupWithSkill,
						!isPlayerRegisteredForNextMatch(player.id),
						getPlayerCards(player.id),
						shirtNumbers[player.id]
					);
				}),
			[
				lineupWithSkill,
				lockedPlayers,
				players,
				shirtNumbers,
				getPlayerCards,
				isPlayerInjured,
				isPlayerRegisteredForNextMatch,
				sortPlayersBySpecificRole
			]
		);

		const adaptedOnRowClick = defineIf(
			onRowClick,
			() =>
				({ _player }: PlayerTableItem) =>
					onRowClick!(_player)
		);

		const adaptedOnSeleectionChange = defineIf(
			onSelectionChange,
			() => (players: PlayerTableItem[]) => onSelectionChange!(players.map(({ _player }) => _player))
		);

		const columnDefs = useMemo<Array<ColumnDef<PlayerTableItem>>>(
			() =>
				compact([
					defineIf(columns.includes(PlayerTableColumn.slotSpecificRoles), () => ({
						id: PlayerTableColumn.slotSpecificRoles,
						maxSize: tableOptions?.isDraggable ? 100 : 84,
						accessorFn: (row) => {
							if (row.slotSpecificRole) return DEFAULT_SPECIFIC_ROLE_SORTING_MAP[row.slotSpecificRole];
							if (row.slotBenchTag) return 20 + parseInt(row.slotBenchTag.replace("B", ""));
							return 30;
						},
						header: () => <Typography variant="body2">Lineup</Typography>,
						cell: ({ row }) => <LineupCell player={row.original} />
					})),
					defineIf(columns.includes(PlayerTableColumn.fullName), () => ({
						id: PlayerTableColumn.fullName,
						accessorKey: "fullName",
						meta: { tdStyle: { flexGrow: 1 }, thStyle: { flexGrow: 1 } },
						header: () => <Typography variant="body2">Player</Typography>,
						cell: ({ row }) => (
							<TitleCell
								player={row.original}
								showPlayerPip={tableOptions?.showPlayerPip}
								useLinks={tableOptions?.linkToPlayer}
							/>
						)
					})),
					defineIf(columns.includes(PlayerTableColumn.specificRole), () => ({
						id: PlayerTableColumn.specificRole,
						maxSize: 80,
						accessorFn: (row) => DEFAULT_SPECIFIC_ROLE_SORTING_MAP[row.specificRole],
						header: () =>
							tooltipToPlayerTableTitles({
								description: "The specific position a player is best suited for on the field.",
								title: "S.Role"
							}),
						cell: ({ row }) => (
							<RoleChip
								covering={getCoveringBySpecificRole(row.original.specificRole)}
								specificRole={row.original.specificRole}
							/>
						)
					})),
					defineIf(columns.includes(PlayerTableColumn.specialAbilities), () => ({
						id: PlayerTableColumn.specialAbilities,
						maxSize: 64,
						accessorFn: (row) => row.specialAbilities.length,
						header: () =>
							tooltipToPlayerTableTitles({
								description:
									"Special abilities that enhance a player’s performance. Not all players have these.",
								title: "S.A"
							}),
						cell: ({ row }) => <SpecialAbilitiesCell player={row.original} />
					})),
					defineIf(columns.includes(PlayerTableColumn.overall), () => ({
						id: PlayerTableColumn.overall,
						accessorKey: "overall",
						maxSize: 88,
						header: () =>
							tooltipToPlayerTableTitles({
								description: "The current skill level of your player and their potential for growth.",
								title: "Quality"
							}),
						cell: ({ row }) => <QualityCell player={row.original} />
					})),
					defineIf(columns.includes(PlayerTableColumn.age), () => ({
						id: PlayerTableColumn.age,
						accessorKey: "age",
						maxSize: 64,
						header: () =>
							tooltipToPlayerTableTitles({
								description: "The player´s age. Impacts career length.",
								title: "Age"
							}),
						cell: ({ row }) => <Typography variant="body2">{row.original.age}</Typography>
					})),
					defineIf(columns.includes(PlayerTableColumn.condition), () => ({
						id: PlayerTableColumn.condition,
						accessorKey: "condition",
						maxSize: 104,
						meta: { tdStyle: { padding: 0, position: "relative" } },
						header: () =>
							tooltipToPlayerTableTitles({
								description:
									"Measures your player’s energy. Players need to recharge to play or train the next day.",
								title: "Condition"
							}),
						cell: ({ row }) => <ConditionCell player={row.original} />
					}))
				]),
			[columns, tableOptions]
		);

		return (
			<Table
				items={items}
				options={tableOptions}
				getRowId={({ id }) => `T:${id}`}
				onRowClick={adaptedOnRowClick}
				onSelectionChange={adaptedOnSeleectionChange}>
				{columnDefs}
			</Table>
		);
	}
);
