import { useIsMobile } from "@/layouts/context";
import { PlayerCardList, PlayerTable, PlayerTableOptions } from "@/modules/shared/components";
import { LineupWithSkill, LockedPlayer, Player, PlayerShirtNumberDict } from "@metasoccer/metasoccer-types";
import { memo } from "react";

interface LineupSquadProps {
	lineupWithSkill: LineupWithSkill;
	players: Player[];
	shirtNumbers: PlayerShirtNumberDict;
	tableOptions?: PlayerTableOptions;
	lockedPlayers?: LockedPlayer[];
	onPlayerSelected?: (player: Player) => void;
}

export const LineupSquad = memo<LineupSquadProps>(
	({ lineupWithSkill, players, shirtNumbers, tableOptions, lockedPlayers, onPlayerSelected }) => {
		const isMobile = useIsMobile();

		if (isMobile) {
			return (
				<div style={{ padding: "0 12px" }}>
					<PlayerCardList
						lineupWithSkill={lineupWithSkill}
						lockedPlayers={lockedPlayers}
						players={players}
						onItemClick={onPlayerSelected}
					/>
				</div>
			);
		} else {
			return (
				<PlayerTable
					lineupWithSkill={lineupWithSkill}
					lockedPlayers={lockedPlayers}
					players={players}
					shirtNumbers={shirtNumbers}
					tableOptions={tableOptions}
					onRowClick={onPlayerSelected}
				/>
			);
		}
	}
);
