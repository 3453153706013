import { Typography, TypographyProps } from "@metasoccer/metasoccer-ds";
import { format, formatDistance } from "date-fns";
import { memo } from "react";
import { Tooltip } from "../Tooltip";

export const DateTypography = memo<TypographyProps & { children: number }>(({ children, ...props }) => {
	const now = new Date();
	return (
		<Tooltip text={format(children, "Pp")}>
			<Typography {...props}>{formatDistance(new Date(children), now, { addSuffix: true })}</Typography>
		</Tooltip>
	);
});
