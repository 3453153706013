import styled, { css } from "styled-components";

export const ContentWithDividersStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;

		& > * {
			border-bottom: 1px solid ${theme.palette.greyscale.gondola} !important;
		}

		${theme.breakpoints.up("desktopMin")} {
			height: 100%;
		}
	`
);
