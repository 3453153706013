import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssPlayerContentUpperPart = styled.div`
	display: flex;
	gap: 24px;
	height: 84px;
	padding: 16px 0;
	width: 100%;
`;

export const CssPlayerContentUpperPartWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const CssPlayerContentUpperPartOverall = styled.div`
	display: flex;
	align-items: baseline;
`;

export const CssPlayerContentUpperPartInfoWrapper = styled.div`
	display: flex;
	gap: 24px;
	min-height: 20px;
`;

export const CssPlayerContentUpperPartInfo = styled.div`
	display: flex;
	gap: 4px;
`;

export const CssNameWrapper = styled.div`
	display: flex;
	gap: 4px;
`;

interface CssTypographyProps {
	hasConditionsActive: boolean;
}

export const CssTypography = styled(Typography)<CssTypographyProps>(
	({ theme, hasConditionsActive }) => css`
		width: ${hasConditionsActive ? "160px" : "180px"};
		text-overflow: ellipsis;
		white-space: nowrap;

		${theme.breakpoints.up("desktopMin")} {
			width: unset;
			text-overflow: unset;
			white-space: unset;
		}
	`
);

export const CssConditionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
`;
