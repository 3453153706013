export const TicketsV2 = {
  "name": "TicketsV2",
  "address": "0x1940d87EeA6280e1914869b1E48e0120b8a68A29",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_mintingToken"
        },
        {
          "type": "address",
          "name": "_beneficiary"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Approval",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "approved",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ApprovalForAll",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "operator",
          "indexed": true
        },
        {
          "type": "bool",
          "name": "approved",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "NewDropCreated",
      "inputs": [
        {
          "type": "bytes32",
          "name": "drop",
          "indexed": false
        },
        {
          "type": "bytes32",
          "name": "root",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OwnershipTransferred",
      "inputs": [
        {
          "type": "address",
          "name": "previousOwner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "newOwner",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Paused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Transfer",
      "inputs": [
        {
          "type": "address",
          "name": "from",
          "indexed": true
        },
        {
          "type": "address",
          "name": "to",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Unpaused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "approve",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "balanceOf",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "beneficiary",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "burnToken",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_token_id"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "burnedCount",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "buyVouchers",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "drop"
        },
        {
          "type": "bytes32[]",
          "name": "proof"
        },
        {
          "type": "uint16",
          "name": "qtyStarters"
        },
        {
          "type": "uint16",
          "name": "qtyBronzes"
        },
        {
          "type": "uint16",
          "name": "qtySilvers"
        },
        {
          "type": "uint16",
          "name": "qtyGolds"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "buysPerDrop",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "bytes32"
        },
        {
          "type": "string"
        }
      ],
      "outputs": [
        {
          "type": "uint16"
        }
      ]
    },
    {
      "type": "function",
      "name": "drops",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32"
        }
      ],
      "outputs": [
        {
          "type": "bytes32",
          "name": "root"
        },
        {
          "type": "uint16",
          "name": "minPurchase"
        },
        {
          "type": "uint16",
          "name": "maxPurchase"
        },
        {
          "type": "bool",
          "name": "started"
        },
        {
          "type": "tuple",
          "name": "starters",
          "components": [
            {
              "type": "uint16",
              "name": "totalNumber"
            },
            {
              "type": "uint16",
              "name": "soldNum"
            },
            {
              "type": "uint16",
              "name": "maxAllowed"
            },
            {
              "type": "uint256",
              "name": "price"
            }
          ]
        },
        {
          "type": "tuple",
          "name": "bronzes",
          "components": [
            {
              "type": "uint16",
              "name": "totalNumber"
            },
            {
              "type": "uint16",
              "name": "soldNum"
            },
            {
              "type": "uint16",
              "name": "maxAllowed"
            },
            {
              "type": "uint256",
              "name": "price"
            }
          ]
        },
        {
          "type": "tuple",
          "name": "silvers",
          "components": [
            {
              "type": "uint16",
              "name": "totalNumber"
            },
            {
              "type": "uint16",
              "name": "soldNum"
            },
            {
              "type": "uint16",
              "name": "maxAllowed"
            },
            {
              "type": "uint256",
              "name": "price"
            }
          ]
        },
        {
          "type": "tuple",
          "name": "golds",
          "components": [
            {
              "type": "uint16",
              "name": "totalNumber"
            },
            {
              "type": "uint16",
              "name": "soldNum"
            },
            {
              "type": "uint16",
              "name": "maxAllowed"
            },
            {
              "type": "uint256",
              "name": "price"
            }
          ]
        }
      ]
    },
    {
      "type": "function",
      "name": "getAllOwned",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_owner"
        }
      ],
      "outputs": [
        {
          "type": "string[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getAllVouchers",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getApproved",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getDropsById",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32[]",
          "name": "dropIds"
        }
      ],
      "outputs": [
        {
          "type": "tuple[]",
          "components": [
            {
              "type": "bytes32",
              "name": "root"
            },
            {
              "type": "uint16",
              "name": "minPurchase"
            },
            {
              "type": "uint16",
              "name": "maxPurchase"
            },
            {
              "type": "bool",
              "name": "started"
            },
            {
              "type": "tuple",
              "name": "starters",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            },
            {
              "type": "tuple",
              "name": "bronzes",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            },
            {
              "type": "tuple",
              "name": "silvers",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            },
            {
              "type": "tuple",
              "name": "golds",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isApprovedForAll",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "address",
          "name": "operator"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "mintForEcosystem",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_to"
        },
        {
          "type": "uint256",
          "name": "_num_to_mint"
        },
        {
          "type": "string",
          "name": "_type"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "mintingToken",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "name",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "owner",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "ownerOf",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "pause",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bool",
          "name": "stop"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "paused",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceOwnership",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeTransferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeTransferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        },
        {
          "type": "bytes",
          "name": "_data"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setAnimationUrl",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "string",
          "name": "_covering"
        },
        {
          "type": "string",
          "name": "_url"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setApprovalForAll",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "operator"
        },
        {
          "type": "bool",
          "name": "approved"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setBeneficiary",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_beneficiary"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setDrop",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "drop"
        },
        {
          "type": "tuple",
          "name": "_drop",
          "components": [
            {
              "type": "bytes32",
              "name": "root"
            },
            {
              "type": "uint16",
              "name": "minPurchase"
            },
            {
              "type": "uint16",
              "name": "maxPurchase"
            },
            {
              "type": "bool",
              "name": "started"
            },
            {
              "type": "tuple",
              "name": "starters",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            },
            {
              "type": "tuple",
              "name": "bronzes",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            },
            {
              "type": "tuple",
              "name": "silvers",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            },
            {
              "type": "tuple",
              "name": "golds",
              "components": [
                {
                  "type": "uint16",
                  "name": "totalNumber"
                },
                {
                  "type": "uint16",
                  "name": "soldNum"
                },
                {
                  "type": "uint16",
                  "name": "maxAllowed"
                },
                {
                  "type": "uint256",
                  "name": "price"
                }
              ]
            }
          ]
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setDropWhitelist",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_drop"
        },
        {
          "type": "bytes32",
          "name": "_root"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setExternalUrl",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "string",
          "name": "_url"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setImageUrl",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "string",
          "name": "_covering"
        },
        {
          "type": "string",
          "name": "_url"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setMintingToken",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_new_minting_token"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "_interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "symbol",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "toggleDropStarted",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_drop"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "tokenByIndex",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenOfOwnerByIndex",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenURI",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "totalSupply",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "transferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "transferOwnership",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newOwner"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "voucherTypes",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "withdrawERC20",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    }
  ]
}