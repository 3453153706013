import { CoreButtonProps } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { CoreButtonStyled } from "./HomeButton.styles";

interface HomeButtonProps extends CoreButtonProps {
	background?: string;
	children: React.ReactNode;
	height?: string | number;
	width?: string | number;
}

export const HomeButton = memo<HomeButtonProps>(({ background, children, height, width, ...props }) => {
	return (
		<CoreButtonStyled
			{...props}
			appearance="group"
			style={{
				...props.style,
				height: height ?? "auto",
				width: width ?? "auto"
			}}>
			{children}
		</CoreButtonStyled>
	);
});
