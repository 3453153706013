import { Tab, TabGroup, Tabs } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo, useEffect, useState } from "react";
import { CssTabs, CssWrapper } from "./TabsLayout.styles";

export interface TabSpec {
	key: string;
	title: string;
	preventUnmount?: boolean;
	render: (hidden?: boolean) => ReactNode;
}

export interface TabsLayoutProps {
	centerTabs?: boolean;
	tabs: TabSpec[];
	tabWidth?: number;
	onActiveTabChange?: (activeTab: string) => void;
}

export const TabsLayout = memo<TabsLayoutProps>(({ centerTabs, tabs, tabWidth, onActiveTabChange }) => {
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	useEffect(() => {
		if (!onActiveTabChange) return;
		onActiveTabChange(tabs[activeTabIndex].key);
	}, [activeTabIndex]);

	return (
		<CssWrapper>
			<CssTabs justify={centerTabs ? "center" : "unset"}>
				<Tabs index={activeTabIndex} onIndexChange={(index) => setActiveTabIndex(index)}>
					<TabGroup>
						{tabs.map(({ key, title }, index) => (
							<Tab key={key} index={index} style={{ width: tabWidth }}>
								{title}
							</Tab>
						))}
					</TabGroup>
				</Tabs>
			</CssTabs>
			{tabs.map(({ key, preventUnmount, render }) =>
				tabs[activeTabIndex].key === key || preventUnmount ? (
					<div key={key}>{render(tabs[activeTabIndex].key !== key)}</div>
				) : undefined
			)}
		</CssWrapper>
	);
});
