import { Typography } from "@metasoccer/metasoccer-ds";
import styled from "styled-components";

export const CssPvpMatchDateWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 4px;
	text-align: center;
`;

export const CssIconWrapper = styled.div`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: -2px;
	width: 24px;
`;

export const CssTimeTypography = styled(Typography)`
	white-space: nowrap;
`;
