import { getImgUrl as getImgUrlFromDs, ImgConfig as ImgConfigFromDs } from "@metasoccer/metasoccer-ds";

export interface ImgConfig extends ImgConfigFromDs {
	useAssets2?: boolean;
}

export const getImgUrl = (url: string, config?: ImgConfig) => {
	try {
		const imgUrl = config?.useAssets2 ? url.replace("assets.metasoccer.com", "assets2.metasoccer.com") : url;
		return getImgUrlFromDs(imgUrl, config);
	} catch (err) {
		console.error(err);
		return url;
	}
};
