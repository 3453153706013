import {
	EntropyManager,
	ERC20Redeemer,
	Marketplace,
	MetaSoccerERC20,
	MetaSoccerPass,
	MetaSoccerPlayers,
	MetaSoccerScouting,
	MetaSoccerStaking,
	MetaSoccerStore,
	MetaSoccerTournaments,
	MetaSoccerTournamentsHelper,
	MetaSoccerTournamentsV2,
	Multiwrap,
	PlayerBundleBuyer,
	StarterBoxRedeemer,
	TicketsRedeemer,
	TicketsRedeemerV2,
	TicketsV2,
	YouthScouts
} from "@metasoccer/contracts/types/1482601649";
import { ContractName } from "@metasoccer/metasoccer-types";
import { useContract } from "@thirdweb-dev/react";

export const typeOfContract = {
	[ContractName.CUSTOM_TOURNAMENTS]: MetaSoccerTournaments,
	[ContractName.CUSTOM_TOURNAMENTS_HELPER]: MetaSoccerTournamentsHelper,
	[ContractName.CUSTOM_TOURNAMENTS_V2]: MetaSoccerTournamentsV2,
	[ContractName.ENTROPY_MANAGER]: EntropyManager,
	[ContractName.ERC20_REDEEMER]: ERC20Redeemer,
	[ContractName.MARKETPLACE]: Marketplace,
	[ContractName.MSC]: MetaSoccerERC20,
	[ContractName.MSU]: MetaSoccerERC20,
	[ContractName.PASS]: MetaSoccerPass,
	[ContractName.PLAYER_BUNDLE_BUYER]: PlayerBundleBuyer,
	[ContractName.PLAYERS]: MetaSoccerPlayers,
	[ContractName.SCOUTING]: MetaSoccerScouting,
	[ContractName.SCOUT_STAKING]: MetaSoccerStaking,
	[ContractName.STORE]: MetaSoccerStore,
	[ContractName.STARTER_BOX]: Multiwrap,
	[ContractName.STARTER_BOX_REDEEMER]: StarterBoxRedeemer,
	[ContractName.TICKETS]: TicketsV2,
	[ContractName.TICKETS_REDEEMER]: TicketsRedeemer,
	[ContractName.TICKETS_REDEEMER_V2]: TicketsRedeemerV2,
	[ContractName.TICKETS_V2]: TicketsV2,
	[ContractName.YOUTH_SCOUTS]: YouthScouts
};

export function useMetaSoccerContract(contractName: ContractName) {
	return useContract(typeOfContract[contractName].address, typeOfContract[contractName].abi);
}
