export enum PlayerSpecificRole {
	CAM = "Central Attacking Midfielder",
	CB = "Center Back",
	CDM = "Central Defensive Midfielder",
	CF = "Center Forward",
	CM = "Center Midfielder",
	GK = "Goalkeeper",
	LB = "Left Back",
	LF = "Left Forward",
	LM = "Left Midfielder",
	LW = "Left Wing",
	LWB = "Left Wing-Back",
	RB = "Right Back",
	RF = "Right Forward",
	RM = "Right Midfielder",
	RW = "Right Wing",
	RWB = "Right Wing-Back",
	ST = "Striker"
}
