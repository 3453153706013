import { accountService, configService, httpService } from "@/dependencies";
import { HOME_ROUTES } from "@/modules/home";
import { Loading } from "@/modules/shared/components";
import { useGlobalState, useSetGlobalState } from "@/modules/shared/context";
import { useToast } from "@/modules/shared/hooks";
import { memo, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const DiscordOAuthCallbackPage = memo(() => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { showToast, showErrToast } = useToast();

	const { address } = useGlobalState();
	const setGlobalState = useSetGlobalState();

	useEffect(() => {
		if (!address) return;
		if (!searchParams.get("code")) return;

		const redirectUri = `${configService.get().baseURL}/account/discord/oauth-callback`;

		httpService
			.post("/account/discord", { code: searchParams.get("code"), redirectUri })
			.then(() => {
				accountService.get().then((account) => setGlobalState((prev) => ({ ...prev, account })));
				showToast("Discord account connected succesfully");
			})
			.catch(() => showErrToast("An error ocurred when trying to connect your Discord account"))
			.finally(() => {
				navigate(HOME_ROUTES.ROOT.buildPath(), { replace: true });
			});
	}, [address, searchParams]);

	return <Loading />;
});
