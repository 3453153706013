import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { mslService } from "../dependencies";

export const MSL_QUERIES = {
	getGroup: (groupId: MetaSoccerId<IdType.TournamentGroup>) => ({
		queryKey: [BaseServerStateKeys.MSL, "getGroup", groupId],
		queryFn: () => mslService.getGroup(groupId)
	}),
	getGroupTeamInfo: (groupId: MetaSoccerId<IdType.TournamentGroup>, teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.MSL, "getGroupTeamInfo", groupId, teamId],
		queryFn: () => mslService.getGroupTeamInfo(groupId, teamId)
	}),
	getGroupsOfEdition: (editionId: MetaSoccerId<IdType.TournamentEdition>) => ({
		queryKey: [BaseServerStateKeys.MSL, "getGroupsOfEdition", editionId],
		queryFn: () => mslService.getGroupsOfEdition(editionId)
	}),
	getJoined: (teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.MSL, "getJoined", teamId],
		queryFn: () => mslService.getJoined(teamId)
	}),
	getRegisteredPlayers: (editionId: MetaSoccerId<IdType.TournamentEdition>, teamId: MetaSoccerId<IdType.Team>) => ({
		queryKey: [BaseServerStateKeys.MSL, "getRegisteredPlayers", editionId, teamId],
		queryFn: () => mslService.getRegisteredPlayers(editionId, teamId)
	})
};
