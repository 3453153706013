import { MSL_ROUTES } from "@/modules/msl";
import { Typography } from "@metasoccer/metasoccer-ds";
import { TournamentGroupInfoDTO } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { MSLButton } from "../MSLButton";

interface MSLPreviewProps {
	groupOfTeam: TournamentGroupInfoDTO;
}

export const MSLPreview = memo<MSLPreviewProps>(({ groupOfTeam }) => {
	const navigate = useNavigate();

	const goToMSLPage = () => {
		navigate(MSL_ROUTES.GROUP.buildPath(groupOfTeam.tournamentEditionGroupId!));
	};

	return (
		<MSLButton onClick={goToMSLPage}>
			<Typography variant="caption" textAlign="left" light>
				{groupOfTeam.tournamentEdition.name}
			</Typography>
		</MSLButton>
	);
});
