import { useIsMobile } from "@/layouts/context";
import { PveDifficulty, pveDifficultyDisplayOrder, pveDifficultyToString } from "@/modules/shared/helpers";
import { Tapbar } from "@metasoccer/metasoccer-ds";
import { FC, useState } from "react";
import { CssTabGroup, CssTabsWrapper } from "./DifficultyTabs.styles";

interface DifficultyTabsProps {
	onClick: (difficulty: PveDifficulty) => void;
}

export const DifficultyTabs: FC<DifficultyTabsProps> = ({ onClick }) => {
	const isMobile = useIsMobile();
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleChangeIndex = (index: number) => {
		setCurrentIndex(index);
		onClick(index);
	};

	return (
		<CssTabsWrapper onIndexChange={handleChangeIndex} index={currentIndex}>
			<CssTabGroup size="md">
				{pveDifficultyDisplayOrder.map((difficulty, index) => {
					return (
						<Tapbar
							key={index}
							index={difficulty}
							size={isMobile ? "sm" : "md"}
							onClick={() => onClick(difficulty)}>
							{pveDifficultyToString(difficulty)}
						</Tapbar>
					);
				})}
			</CssTabGroup>
		</CssTabsWrapper>
	);
};
