import { Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssAttackZoneBarWrapper, CssBar, CssBarArrowHead } from "./AttackZoneBar.styles";
import { getHexColorWithAlpha } from "./helpers";

export const AttackZoneBar: FC<{ value: number; color: string; style?: any; reverse?: boolean }> = ({
	value,
	color,
	style,
	reverse
}) => {
	return (
		<CssAttackZoneBarWrapper style={style} reverse={reverse}>
			<CssBar value={value} color={reverse ? getHexColorWithAlpha(color, 0.6) : color}>
				<Typography variant={"caption"} textAlign={reverse ? "right" : "left"}>
					{value}%
				</Typography>
			</CssBar>
			<CssBarArrowHead
				color={reverse ? getHexColorWithAlpha(color, 0.6) : color}
				reverse={reverse}></CssBarArrowHead>
		</CssAttackZoneBarWrapper>
	);
};
