import { Container } from "@/modules/shared/components";
import { Card } from "@/modules/shared/components/Card";
import styled from "styled-components";

export const CardStyled = styled(Card)`
	height: auto;
`;

export const ContainerStyled = styled(Container)`
	padding: 24px;
	padding-top: 0;
`;
