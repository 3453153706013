import styled, { css } from "styled-components";

export const Wrapper: any = styled.div(
	({ theme }: any) => css`
		height: 80px;
		width: 100%;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		display: flex;
		align-items: center;

		@media (max-width: ${theme.breakpoints.values.md}px) {
			height: 52px;
		}
	`
);
