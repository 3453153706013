import { Bar } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssBar } from "./PlayerConditionBar.styles";

interface PlayerConditionBarProps {
	condition: number;
	isFullSize?: boolean;
}

export const PlayerConditionBar: FC<PlayerConditionBarProps> = ({ condition, isFullSize }) => {
	const getProgressColorName = (progress: number): string => {
		if (progress >= 99) {
			return "#4DD2CA";
		} else if (progress <= 98 && progress >= 75) {
			return "#6AD56A";
		} else if (progress <= 74 && progress >= 50) {
			return "#FDF764";
		} else if (progress <= 49 && progress >= 25) {
			return "#FE9D43";
		} else {
			return "#F2384E";
		}
	};

	return (
		<CssBar isFullSize={isFullSize}>
			<Bar progress={condition / 100} type={"simple"} label={""} hexColorA={getProgressColorName(condition)} />
		</CssBar>
	);
};
