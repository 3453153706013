import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TapbarSpec } from "./TapbarsLayout";

export default function useSyncWithQS(tabs: TapbarSpec[], sync: boolean) {
	const [searchParams, setSearchParams] = useSearchParams();

	const tabInQS = searchParams.get("tab");
	const tabIndexFromQS = Math.max(
		tabs.findIndex(({ key }) => key === tabInQS),
		0
	);

	const [activeTabIndex, setActiveTabIndex] = useState(tabIndexFromQS);

	useEffect(() => {
		if (!sync) return;

		if (tabs[activeTabIndex]) {
			const tabBySearchParams = searchParams.get("tab");
			const tabIndex = tabs.findIndex(({ key }) => key === tabBySearchParams);

			if (tabIndex !== undefined && tabIndex !== activeTabIndex) {
				setSearchParams({ tab: tabs[activeTabIndex].key }, { replace: true });
			}
		}
	}, [activeTabIndex, sync]);

	useEffect(() => {
		if (!sync) return;

		if (tabIndexFromQS !== undefined && tabIndexFromQS !== activeTabIndex) {
			setActiveTabIndex(tabIndexFromQS);
		}
	}, [tabIndexFromQS, sync]);

	return { activeTabIndex, setActiveTabIndex };
}
