import { useMutableServerState, useServerState } from "@/modules/core/react-query/hooks";
import { HomeButton } from "@/modules/home/components";
import { INBOX_MUTABLE_QUERIES, INBOX_QUERIES } from "@/modules/inbox/queries";
import { Loading, Modal } from "@/modules/shared/components";
import { Col, Row, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { memo, useEffect, useState } from "react";
import { ContainerStyled, DotStyled, HeaderStyled } from "./Inbox.styles";

export const Inbox = memo(() => {
	const theme = useTheme();

	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
	const [selectedMessageId, setSelectedMessageId] = useState<string>();

	const { data: inbox = [] } = useServerState(INBOX_QUERIES.getInbox());
	const { data: readMessages, refetch: refetchReadMessages } = useServerState(INBOX_QUERIES.getReadMessages());
	const { data: selectedMessage } = useServerState(INBOX_QUERIES.getMessage(selectedMessageId!), {
		enabled: Boolean(selectedMessageId)
	});

	const { mutateAsync: markMessageAsRead } = useMutableServerState(INBOX_MUTABLE_QUERIES.markMessageAsRead());

	useEffect(() => {
		setIsMessageModalOpen(Boolean(selectedMessageId));
		if (selectedMessageId) {
			markMessageAsRead(selectedMessageId).then(() => refetchReadMessages());
		}
	}, [selectedMessageId]);

	return (
		<>
			<Col style={{ paddingBottom: 24, minHeight: 310 }}>
				<HeaderStyled>
					<Typography variant="h5">Inbox</Typography>
				</HeaderStyled>
				<Col>
					{inbox.slice(0, 3).map((message, i) => {
						const messageRead = readMessages?.find(
							(readMessage) => readMessage.message_id === message.id
						)?.opened;
						return (
							<>
								{i > 0 && (
									<div
										style={{
											borderBottom: `1px solid ${theme.palette.greyscale.gondola}`,
											margin: "0 16px"
										}}
									/>
								)}
								<HomeButton
									height="auto"
									style={{ justifyContent: "flex-start", padding: "16px 24px" }}
									onClick={() => setSelectedMessageId(message.id)}>
									<Col gap={4}>
										<Row alignItems="center" gap={8}>
											{!messageRead && <DotStyled />}
											<Typography variant="body2" light={messageRead}>
												{message.properties.Name.title[0].plain_text}
											</Typography>
										</Row>
										<Typography variant="caption" light>
											{message.properties.Blurb.rich_text[0].plain_text}
										</Typography>
									</Col>
								</HomeButton>
							</>
						);
					})}
				</Col>
			</Col>
			{selectedMessageId && (
				<Modal
					isOpen={isMessageModalOpen}
					size="lg"
					title={inbox.find(({ id }) => id === selectedMessageId)!.properties.Name.title[0].plain_text}
					onClose={() => setSelectedMessageId(undefined)}>
					<ContainerStyled>
						{selectedMessage ? (
							<div dangerouslySetInnerHTML={{ __html: selectedMessage.data }} />
						) : (
							<Loading size="sm" light />
						)}
					</ContainerStyled>
				</Modal>
			)}
		</>
	);
});
