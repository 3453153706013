import { useIsMobile } from "@/layouts/context";
import { useGlobalState } from "@/modules/shared/context";
import { Col, CopyIcon, Typography, UserIcon } from "@metasoccer/metasoccer-ds";
import { useTheme } from "styled-components";
import { CssFlex, CssMenuItem } from "../HeaderHoverMenu.styled";
import { ExportPrivateKeyItem } from "./ExportPrivateKeyItem";

export const UserMenuUserItem = () => {
	const theme = useTheme();
	const isMobile = useIsMobile();
	const { address } = useGlobalState();
	const copyWallet = () => navigator.clipboard.writeText(address!);
	const shortWallet = `${address?.slice(0, 5)}...${address?.slice(address.length - 4, address.length)}`;

	if (isMobile) {
		return (
			<CssMenuItem onClick={copyWallet}>
				<Col gap={16}>
					<CssFlex justifyContent={"space-between"} width={"100%"}>
						<CssFlex direction={"row"} gap={12}>
							<UserIcon size={"sm"} color={theme.palette.greyscale.gray} />
							<Typography variant={"body2"}>User</Typography>
							<CssFlex direction={"column"} justifyContent="center">
								<Typography
									variant={"label1"}
									style={{ color: theme.palette.greyscale.gray }}
									textTransform={"none"}>
									{shortWallet}
								</Typography>
							</CssFlex>
						</CssFlex>
						<CopyIcon size={"sm"} />
					</CssFlex>
					<ExportPrivateKeyItem />
				</Col>
			</CssMenuItem>
		);
	}

	return (
		<CssMenuItem onClick={copyWallet}>
			<Col gap={16}>
				<CssFlex direction={"row"} gap={12}>
					<UserIcon size={"sm"} color={theme.palette.greyscale.gray} />
					<CssFlex justifyContent={"space-between"} width={"100%"}>
						<CssFlex direction={"column"}>
							<Typography variant={"body2"}>Account ID</Typography>
							<Typography
								variant={"label1"}
								style={{ color: theme.palette.greyscale.gray }}
								textTransform={"none"}>
								{shortWallet}
							</Typography>
						</CssFlex>
						<CssFlex direction={"column"} justifyContent="center">
							<CopyIcon size={"sm"} />
						</CssFlex>
					</CssFlex>
				</CssFlex>
				<ExportPrivateKeyItem />
			</Col>
		</CssMenuItem>
	);
};
