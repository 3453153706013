import { FC } from "react";
import { CssChip } from "./ExtraImprovementChip.styled";

export interface ExtraImprovementChipProps {
	size?: "sm" | "md" | "lg";
}

export const ExtraImprovementChip: FC<ExtraImprovementChipProps> = ({ size = "lg" }) => {
	return <CssChip size={size} label="50% Results" />;
};
