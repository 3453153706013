import { CoinSize } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

interface CssTokenRowProps {
	size: CoinSize;
	minWidth?: string;
}

export const CssTokenRow = styled.div<CssTokenRowProps>(
	({ size, minWidth }) => css`
		display: flex;
		align-items: center;
		gap: ${size === "xs" ? "4px" : "8px"};
		min-width: ${minWidth};
		text-align: right;
	`
);
