import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssTeamsBoardWrapper = styled("div")(
	() => css`
		margin: 40px 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	`
);

export const CssVs = styled(Typography)(
	() => css`
		display: flex;
		margin: 0 24px;
	`
);

export const CssHomeClubLogo = styled.div(
	({ theme }) => css`
		@media (max-width: ${theme.breakpoints.values.desktopMax}px) {
			width: 40px;
			height: 40px;
		}
	`
);
