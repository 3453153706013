import { BaseServerStateKeys } from "@/modules/shared/constants";
import { lineupService } from "@/modules/lineup/dependencies";
import { FlatLineup } from "@metasoccer/metasoccer-types/dist/@types/lineup/FlatLineup";

export const LINEUP_MUTABLE_QUERIES = {
	update: () => ({
		mutationKey: [BaseServerStateKeys.LINEUP_WITH_SKILL],
		mutationFn: ({ lineup, teamId }: { lineup: FlatLineup; teamId: string }) =>
			lineupService.updateLineup(lineup, teamId)
	})
};
