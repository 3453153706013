import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { useGlobalState, useLiveMatchState, useSelectedTeamState } from "@/modules/shared/context";
import { getImgUrl } from "@/modules/shared/utils";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { Inbox } from "../Inbox";
import { LiveMatch } from "../NextMatch/components/LiveMatch";
import { PrepareNextMatch } from "../NextMatch/components/PrepareNextMatch";
import { HeaderStyled } from "./AirdropInbox.styles";

export const AirdropInbox = memo(() => {
	const { address } = useGlobalState();

	const { hasAirdropStarted, hasPass } = useAirdrop();
	const { currentMatch } = useLiveMatchState();
	const { teamInfo } = useSelectedTeamState();

	if (currentMatch) {
		return <LiveMatch height={282} match={currentMatch} />;
	} else if (teamInfo?.nextMatch) {
		return <PrepareNextMatch height={282} match={teamInfo.nextMatch} />;
	}

	if (hasAirdropStarted) {
		return <Inbox />;
	}

	if (hasPass) {
		return (
			<Col style={{ paddingBottom: 24, minHeight: 282 }}>
				<HeaderStyled>
					<Typography variant="h5">You're ready!</Typography>
				</HeaderStyled>
				<Col gap={24} style={{ padding: "0 24px" }}>
					<img
						src={getImgUrl("https://assets.metasoccer.com/airdrop/metasoccer-pass.png?v=2")}
						width="100%"
					/>
				</Col>
			</Col>
		);
	}

	return (
		<Col style={{ paddingBottom: 24 }}>
			<HeaderStyled>
				<Typography variant="h5">Get Started</Typography>
			</HeaderStyled>
			<Col gap={24} style={{ padding: "0 24px" }}>
				<Typography variant="body2" light>
					{`To participate in MetaSoccer, you'll need to claim your MetaSoccer Pass.`}
				</Typography>
				<Typography variant="body2" light>
					{`The MetaSoccer Pass allows you to redeem a Starter Box containing players and everything you need to start playing.`}
				</Typography>
			</Col>
		</Col>
	);
});
