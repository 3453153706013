import styled, { css } from "styled-components";

export const CssFilteredStandingMobileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	overflow: hidden;
	height: 100%;
`;

export const CssHeaderWrapper = styled.div`
	display: flex;
	padding: 12px;
	gap: 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
`;

export const CssTitleWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 16px;
`;

export const CssTableWrapper = styled.div`
	flex: 1 1 0%;
	overflow-y: auto;
`;

interface CssPositionProps {
	color: string;
}

export const CssPosition = styled.div<CssPositionProps>(
	({ color }) => css`
		display: flex;
		height: 60px;
		justify-content: center;
		align-items: center;
		border-left: 4px solid ${color};
	`
);

export const CssPointsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
