import { ArrowLeftIcon } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const NavigateBackButton = memo(() => {
	const location = useLocation();
	const navigate = useNavigate();

	const canGoBack = location.key !== "default";

	if (!canGoBack) {
		return <></>;
	}

	return <ArrowLeftIcon size="md" style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />;
});
