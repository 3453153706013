import styled, { css } from "styled-components";

export const WrapperStyled = styled.div<{ alignItems?: string; gap?: number; reverse?: boolean }>(
	({ alignItems, gap, reverse }) => css`
		align-items: ${alignItems ?? "unset"};
		display: flex;
		flex-direction: ${reverse ? "column-reverse" : "column"};
		gap: ${gap ?? 24}px;
		height: 100%;
		width: 100%;
	`
);

export const GrowStyled = styled.div<{ overflow?: string }>(
	({ overflow, theme }) => css`
		flex-grow: 1;
		height: 0;
		overflow: ${overflow ?? "auto"};
	`
);
