import { ReactNode, memo } from "react";
import { ContainerStyled, WrapperStyled } from "./TwoColumnLayout.styles";

export interface TwoColumnLayoutProps {
	children: [ReactNode, ReactNode];
	className?: string;
	divider?: boolean;
	gap?: number;
	padding?: number;
}

export const TwoColumnLayout = memo<TwoColumnLayoutProps>(({ children, className, divider, gap, padding }) => {
	return (
		<WrapperStyled className={className} gap={gap}>
			<ContainerStyled divider={divider} paddingX={padding} paddingY={padding}>
				{children[0]}
			</ContainerStyled>
			<ContainerStyled divider={divider} paddingX={padding} paddingY={padding}>
				{children[1]}
			</ContainerStyled>
		</WrapperStyled>
	);
});
