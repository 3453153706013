import styled from "styled-components";
import { animated } from "react-spring";
import { Typography } from "@metasoccer/metasoccer-ds";

export const CssWrapper = styled(animated.div)`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.palette.backgrounds.codGray};
	transform: scale3d(0.5, 0.5, 1);
`;

export const CssShirtLayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.5;

	background: url("https://assets2.metasoccer.com/file/assets2-metasoccer/img/formatted/shirt.avif") center no-repeat;

	z-index: 1;
`;

export const CssTextLayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 2;
`;

export const CssTypography = styled(Typography)`
	text-align: center;
`;
