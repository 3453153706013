import { Chip } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssChip = styled(Chip)(
	({ theme }) => css`
		background: linear-gradient(0deg, rgba(106, 213, 106, 0.3), rgba(106, 213, 106, 0.3)), #06060a;
		color: ${theme.palette.system.pastelGreen};

		&.Filled {
			color: ${theme.palette.system.pastelGreen};
		}

		& .ChipLabel {
			font-family: ${theme.fonts.pfdInt};
			font-size: 0.875rem;
			line-height: 100%;
			text-transform: uppercase;
		}
	`
);
