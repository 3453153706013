import { Goal, Shot, ShotType } from "@metasoccer/metasoccer-types";

interface Stat {
	name: string;
	home: number;
	away: number;
}

export const isBeforeOrAtMinute = (item: { minute: number }, minute: number): boolean => {
	return item.minute <= minute;
};

export const clockToMinute = (clock: number): number => {
	return Math.floor(clock / 60) as number;
};

export function goalsAtRealTime(goals: Goal[], matchClock: number): Goal[] {
	if (typeof goals === "undefined") {
		return [];
	}

	return goals.filter((goal) => isBeforeOrAtMinute(goal, clockToMinute(matchClock)));
}

export const getTotalShotsAtMinute = (homeShots: Shot[], awayShots: Shot[], clock: number): Stat => {
	const minute = clockToMinute(clock);
	const filteredHomeShots = homeShots.filter((i) => isBeforeOrAtMinute(i, minute));
	const filteredAwayShots = awayShots.filter((i) => isBeforeOrAtMinute(i, minute));

	const result: Stat = {
		name: "Total Shots",
		home: filteredHomeShots.length,
		away: filteredAwayShots.length
	};
	return result;
};

export const getTotalShotsOnGoalAtMinute = (homeShots: Shot[], awayShots: Shot[], clock: number): Stat => {
	const minute = clockToMinute(clock);
	const filteredHomeShots = homeShots.filter(
		(i) => isBeforeOrAtMinute(i, minute) && i.shotType === ShotType.ON_TARGET
	);
	const filteredAwayShots = awayShots.filter(
		(i) => isBeforeOrAtMinute(i, minute) && i.shotType === ShotType.ON_TARGET
	);

	const result: Stat = {
		name: "Shots on Target",
		home: filteredHomeShots.length,
		away: filteredAwayShots.length
	};
	return result;
};
