import { ScoutDto } from "@/modules/market/module/api/service";
import { HttpClient, Response, Scout, ScoutImprovementHistory } from "@metasoccer/metasoccer-types";

export enum ScoutKnowledgeArea {
	Goalkeeping = "Goalkeeping",
	Defending = "Defending",
	Attacking = "Attacking",
	Mental = "Mental",
	Physical = "Physical"
}

export class ScoutService {
	constructor(private readonly httpService: HttpClient) {}

	async getScoutById(scoutId: number): Promise<Scout> {
		let response: Response<Scout>;
		try {
			response = await this.httpService.get<Scout>(`/v2/scouts/${scoutId}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}

	async getScoutsByIds(scoutIds: number[]): Promise<Scout[]> {
		let response: Response<{ scouts: Scout[] }>;
		try {
			response = await this.httpService.post<{ scoutIds: number[] }, { scouts: Scout[] }>(`/v2/scouts`, {
				scoutIds
			});
		} catch (e) {
			throw e;
		}

		return response.data.scouts;
	}

	async getImprovements(scoutId: number): Promise<ScoutImprovementHistory> {
		try {
			const { data } = await this.httpService.get<ScoutImprovementHistory>(`/v2/scouts/${scoutId}/improvements`);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async getById(scoutId: number): Promise<ScoutDto> {
		try {
			const { data } = await this.httpService.get(`/scout/${scoutId}`);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async estimate(scoutId: number, improvement: Record<ScoutKnowledgeArea, number>): Promise<number> {
		try {
			const { data } = await this.httpService.post(`/scout/${scoutId}/estimate`, improvement);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async estimateRetirement(scoutId: number): Promise<number> {
		try {
			const { data } = await this.httpService.post(`/scout/${scoutId}/estimateRetirement`);
			return data;
		} catch (e) {
			throw e;
		}
	}

	async improve(
		scoutId: number,
		improvement: Record<ScoutKnowledgeArea, number>,
		improveWithCredit?: boolean
	): Promise<void> {
		try {
			await this.httpService.post(`/scout/${scoutId}/improve`, { improvement, improveWithCredit });
		} catch (e) {
			throw e;
		}
	}

	async knowledgeCredit(scoutId: number): Promise<number> {
		try {
			const { data } = await this.httpService.get(`/scout/${scoutId}/knowledgeCredit`);
			return data;
		} catch (e) {
			throw e;
		}
	}
}
