import styled, { css, keyframes } from "styled-components";

export const CssLiveWrapper = styled.div`
	min-width: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
`;

const pulseAnimation = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }`;

export const CssPulse = styled.div(
	({ theme }) => css`
		background: ${theme.palette.system.amaranth};
		border-radius: 50%;
		margin: 10px;
		height: 16px;
		width: 16px;

		box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
		transform: scale(1);
		animation: ${pulseAnimation} 2s infinite;
	`
);
