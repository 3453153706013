import styled, { css } from "styled-components";

export const CssWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
	`
);

export const CssTabs = styled.div<{ justify: "center" | "unset" }>(
	({ justify, theme }) => css`
		display: flex;
		justify-content: ${justify};
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		z-index: 1;

		.TabGroupContainer {
			gap: 0;
		}

		.TabsTab,
		.TabIndicator {
			text-align: center;
		}
	`
);
