import { Tooltip, Typography } from "@metasoccer/metasoccer-ds";
export const EmptyEnergyIcon = () => {
	return (
		<Tooltip position="center">
			{{
				popper: <Typography variant={"body2"}>Exhausted. Unable to play</Typography>,
				content: (
					<div style={{ display: "flex", alignItems: "center" }}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<mask
								id="mask0_2030_4719"
								style={{ maskType: "alpha" }}
								maskUnits="userSpaceOnUse"
								x="1"
								y="2"
								width="16"
								height="14">
								<path
									d="M12.2184 11.9623C11.1442 13.0688 10.0701 14.1753 8.99608 15.2817L2.51738 8.59193L2.51731 8.59186C1.17363 7.20495 1.16068 4.97992 2.48046 3.57998L2.51735 3.54198C3.86572 2.15269 6.02875 2.15265 7.37739 3.542C7.37739 3.542 7.37739 3.54201 7.37739 3.54201L8.64504 4.84795L9.0038 5.21756L9.36258 4.84796L10.6224 3.55016L10.6224 3.55012C11.9708 2.16083 14.1338 2.16079 15.4825 3.55015C16.8392 4.94783 16.8392 7.20217 15.4825 8.59985L15.8008 8.9089L15.4825 8.59985C14.3945 9.72067 13.3064 10.8415 12.2184 11.9623Z"
									fill="#C1DEC0"
									stroke="#E84A74"
								/>
							</mask>
							<g mask="url(#mask0_2030_4719)">
								<path
									d="M2.51697 3.54182L2.517 3.54179C3.86534 2.15253 6.02872 2.15262 7.37739 3.542C7.37739 3.542 7.37739 3.54201 7.37739 3.54201L8.64504 4.84795L9.0038 5.21756L9.36258 4.84796L10.6224 3.55016L10.6224 3.55012C11.9708 2.16083 14.1338 2.16079 15.4825 3.55015C16.8392 4.94783 16.8392 7.20217 15.4825 8.59985C14.396 9.71917 13.3093 10.8385 12.2227 11.9578C11.1471 13.0658 10.0716 14.1738 8.99608 15.2817L2.51738 8.59193L2.51731 8.59186C1.16135 7.19227 1.16055 4.93919 2.51697 3.54182Z"
									fill="#E84A74"
									fillOpacity="0.1"
									stroke="#E84A74"
								/>
							</g>
							<rect
								x="16.4121"
								y="0.730652"
								width="1.5"
								height="19.8227"
								transform="rotate(46.0484 16.4121 0.730652)"
								fill="#E84A74"
							/>
						</svg>
					</div>
				)
			}}
		</Tooltip>
	);
};
