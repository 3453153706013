import { memo } from "react";
import { useSquadState } from "./Squad.state";
import { WrapperStyled } from "./Squad.styles";
import { GetYourSquad } from "./components/GetYourSquad";
import { SquadPreview } from "./components/SquadPreview";

export const Squad = memo(() => {
	const { lineupWithSkill, lockedPlayers, shirtNumbers } = useSquadState();

	return (
		<WrapperStyled>
			{lineupWithSkill?.lineup ? (
				<SquadPreview
					lineupWithSkill={lineupWithSkill}
					lockedPlayers={lockedPlayers}
					shirtNumbers={shirtNumbers}
				/>
			) : (
				<GetYourSquad />
			)}
		</WrapperStyled>
	);
});
