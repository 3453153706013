import { configService } from "@/dependencies";
import { useServerState } from "@/modules/core/react-query/hooks";
import { useGlobalState } from "@/modules/shared/context";
import { InGameTokenTx } from "@metasoccer/metasoccer-types";
import { useBalance } from "@thirdweb-dev/react";
import React, { useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";
import { TOKENS_ADDRESSES } from "../constants";
import { SHARED_QUERIES } from "../queries";

export const EMPTY_BALANCE_OBJECT = {
	msuTokens: 0,
	knowledgeTokens: 0,
	knowledgeTokensTxs: [] as InGameTokenTx[],
	trainingTokens: 0,
	trainingTokensTxs: [] as InGameTokenTx[]
};

type BalanceContextProviderProps = { children: React.ReactNode };

const useBalanceState = () => {
	const environment = configService.get().env;

	const { address } = useGlobalState();

	const [balances, setBalances] = useLocalStorage<typeof EMPTY_BALANCE_OBJECT>(
		`useBalanceContext.${environment}.${address}`
	);

	// msuTokens

	const { data: msuBalance, isFetchedAfterMount: msuBalanceIsFetchedAfterMount } = useBalance(
		TOKENS_ADDRESSES[environment].MSU
	);
	const msuTokens = Boolean(msuBalance?.displayValue) ? Number(msuBalance?.displayValue) : 0;

	// knowledgeTokens

	const knowledgeTokensQuery = SHARED_QUERIES.inGameTokens.getKnowledge(address ?? "0x0");
	const { data: knowledgeTokensData, isFetchedAfterMount: knowledgeTokensIsFetchedAfterMount } = useServerState(
		knowledgeTokensQuery,
		{ enabled: !!address }
	);
	const knowledgeTokens = knowledgeTokensData?.balance ?? 0;
	const knowledgeTokensTxs = useMemo(() => knowledgeTokensData?.txs ?? [], [knowledgeTokensData?.txs]);

	// trainingTokens

	const trainingTokensQuery = SHARED_QUERIES.inGameTokens.getTraining(address ?? "0x0");
	const { data: trainingTokensData, isFetchedAfterMount: trainingTokensIsFetchedAfterMount } = useServerState(
		trainingTokensQuery,
		{ enabled: !!address }
	);
	const trainingTokens = trainingTokensData?.balance ?? 0;
	const trainingTokensTxs = useMemo(() => trainingTokensData?.txs ?? [], [trainingTokensData?.txs]);

	// return

	const isFetchedAfterMount =
		msuBalanceIsFetchedAfterMount && knowledgeTokensIsFetchedAfterMount && trainingTokensIsFetchedAfterMount;

	useEffect(() => {
		if (isFetchedAfterMount) {
			setBalances({
				msuTokens,
				knowledgeTokens,
				knowledgeTokensTxs,
				trainingTokens,
				trainingTokensTxs
			});
		}
	}, [
		isFetchedAfterMount,
		msuTokens,
		knowledgeTokens,
		knowledgeTokensTxs,
		trainingTokens,
		trainingTokensTxs,
		setBalances
	]);

	return balances ?? EMPTY_BALANCE_OBJECT;
};

const BalanceContext = React.createContext<ReturnType<typeof useBalanceState> | undefined>(undefined);

const BalanceContextProvider = ({ children }: BalanceContextProviderProps) => {
	const state = useBalanceState();

	return <BalanceContext.Provider value={state}>{children}</BalanceContext.Provider>;
};

export { BalanceContext, BalanceContextProvider };
