import { useIsMobile } from "@/layouts/context";
import { CLUB_QUERIES } from "@/modules/club/queries";
import { CLUB_MUTABLE_QUERIES } from "@/modules/club/queries/mutableQueries";
import { useMutableServerState, useServerState } from "@/modules/core/react-query/hooks";
import { useGlobalState, useSetGlobalState } from "@/modules/shared/context";
import { TEAM_QUERIES } from "@/modules/team";
import { ArrowRightIcon, Button, StepContent, StepperModal } from "@metasoccer/metasoccer-ds";
import { FC, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { renderIf } from "../../../shared/utils/renderIf";
import { Compete, CreateYourClub, GetYourTeam, MSU, Welcome } from "./components";
import { convertToAcronym } from "./utils";

enum OnboardingStep {
	WELCOME,
	CREATE_YOUR_CLUB,
	COMPETE,
	MSU,
	GET_YOUR_TEAM
}

interface OnboardingModalProps {
	isOpen: boolean;
	onClose: () => void;
	onMarketClick?: () => void;
}

export const OnboardingStepperModal: FC<OnboardingModalProps> = ({ isOpen, onClose, onMarketClick }) => {
	const isMobile = useIsMobile();
	const theme = useTheme();

	const { address, isAuthenticated, selectedTeam } = useGlobalState();
	const setGlobalState = useSetGlobalState();

	const { data: club } = useServerState(CLUB_QUERIES.getClubByOwner(address ?? "0x"), { enabled: Boolean(address) });

	const [activeStep, setActiveStep] = useState(0);
	const [isSaving, setIsSaving] = useState(false);

	const [clubName, setClubName] = useState("");
	const [isClubNameValid, setIsClubNameValid] = useState(false);
	const [selectedColor, setSelectedColor] = useState(theme.palette.greyscale.white);
	const [selectedLogoIndex, setSelectedLogoIndex] = useState(0);

	const { mutateAsync: updateLogoAndColor } = useMutableServerState(CLUB_MUTABLE_QUERIES.updateLogoAndColor());
	const { mutateAsync: updateNameAndAcronym } = useMutableServerState(CLUB_MUTABLE_QUERIES.updateNameAndAcronym());
	const { data: managedTeams, refetch: refetchManagedTeams } = useServerState(
		TEAM_QUERIES.getManagedTeams(address!),
		{ enabled: !!address }
	);

	useEffect(() => {
		if (managedTeams) {
			setGlobalState((prev) => ({ ...prev, selectedTeam: managedTeams[0] }));
		}
	}, [managedTeams]);

	const handleNextStep = () => {
		if (activeStep === OnboardingStep.CREATE_YOUR_CLUB) {
			setIsSaving(true);
			saveTeam()
				.then(() => {
					setActiveStep(activeStep + 1);
					setIsSaving(false);
				})
				.catch(() => {
					setIsSaving(false);
				});
		} else {
			setActiveStep(activeStep + 1);
		}
	};

	const saveTeam = async () => {
		if (!club) {
			throw new Error("Club not found");
		}

		if (clubName.length < 3) {
			throw new Error("Club name must be at least 3 characters long");
		}

		const selectedLogo = `Club${selectedLogoIndex + 1}`;

		if (typeof selectedLogo !== "string") {
			console.error("selectedLogo must be a string");
			return;
		}

		const clubAcronym = convertToAcronym(clubName);

		await updateLogoAndColor({ clubId: club.id, name: selectedLogo, color: selectedColor });

		await updateNameAndAcronym({
			clubId: selectedTeam?.clubId,
			name: clubName.trim(),
			acronym: clubAcronym
		});

		await refetchManagedTeams();
	};

	const setSanitizedClubName = (name: string) => {
		setClubName(name.replace(/\s+/g, " "));
	};

	const footerContent = renderIf(![OnboardingStep.WELCOME, OnboardingStep.GET_YOUR_TEAM].includes(activeStep), () => (
		<Button
			appearance="primary"
			fullWidth={isMobile}
			disabled={!isAuthenticated || !isClubNameValid}
			label={isAuthenticated ? "Continue" : "Getting ready..."}
			loading={isSaving}
			rightIcon={<ArrowRightIcon size="sm" />}
			onClick={handleNextStep}
		/>
	));

	return (
		<StepperModal
			activeStep={activeStep}
			footerContent={footerContent}
			fullScreen
			hideHeader={activeStep === 0}
			isOpen={isOpen}
			maxScreenWidth={activeStep !== OnboardingStep.WELCOME}
			setActiveStep={setActiveStep}
			onClose={onClose}>
			<StepContent label="Welcome">
				<Welcome handleNextStep={handleNextStep} />
			</StepContent>

			<StepContent label="Create your Club">
				<CreateYourClub
					clubName={clubName}
					selectedColor={selectedColor}
					selectedLogoIndex={selectedLogoIndex}
					setClubName={setSanitizedClubName}
					setSelectedColor={setSelectedColor}
					setSelectedLogoIndex={setSelectedLogoIndex}
					onClubNameValidation={setIsClubNameValid}
					onSubmit={handleNextStep}
				/>
			</StepContent>

			<StepContent label="Competitions">
				<Compete />
			</StepContent>

			<StepContent label="MSU">
				<MSU />
			</StepContent>

			<StepContent label="Get your team!">
				<GetYourTeam onMarketClick={onMarketClick} />
			</StepContent>
		</StepperModal>
	);
};
