import { TabGroup, Tabs } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssTabsWrapper = styled(Tabs)(
	({ theme }) => css`
		padding: 0 12px;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;

		${theme.breakpoints.up("desktopMin")} {
			padding: 0 24px;
		}
	`
);

export const CssTabGroup = styled(TabGroup)(
	() => css`
		min-width: auto;
	`
);
