import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled, { css } from "styled-components";
export var CssMenu = styled("div")(function (_ref) {
  var isMenuOpen = _ref.isMenuOpen,
    zIndex = _ref.zIndex,
    theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tbackground: ", ";\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tcursor: default;\n\t\tposition: absolute;\n\t\tz-index: ", ";\n\t\t.SelectItem {\n\t\t\tsvg {\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t}\n\t"])), isMenuOpen ? theme.palette.backgrounds.codGray : "none", zIndex);
});
export var CssMenuContent = styled("div")(function (_ref2) {
  var theme = _ref2.theme,
    isMenuOpen = _ref2.isMenuOpen,
    position = _ref2.position;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tbackground-color: ", ";\n\t\tposition: absolute;\n\t\tright: ", ";\n\t\tleft: ", ";\n\t\tdisplay: ", ";\n\t\ttop: 100%;\n\t"])), theme.palette.backgrounds.codGray, position === "right" ? 0 : "auto", position === "left" ? 0 : "auto", isMenuOpen ? "block" : "none");
});