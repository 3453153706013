import { TypographyProps } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { TypographyStyled } from "./Paragraph.styles";

export interface ParagraphProps extends TypographyProps {
	className?: string;
	children: ReactNode;
}

export const Paragraph = memo<ParagraphProps>(({ className, children, ...props }) => {
	return (
		<TypographyStyled className={className} {...props}>
			{children}
		</TypographyStyled>
	);
});
