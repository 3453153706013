import { useIsMobile } from "@/layouts/context";
import { SignoutIcon, Typography } from "@metasoccer/metasoccer-ds";
import { useDisconnect } from "@thirdweb-dev/react";
import { FC } from "react";
import { useTheme } from "styled-components";
import { CssFlex, CssMenuItem } from "../HeaderHoverMenu.styled";

interface UserMenuDisconnectItemProps {
	toggleSheet: () => void;
}

export const UserMenuDisconnectItem: FC<UserMenuDisconnectItemProps> = ({ toggleSheet }) => {
	const isMobile = useIsMobile();
	const theme = useTheme();
	const disconnect = useDisconnect();

	return (
		<CssMenuItem
			onClick={() => {
				if (isMobile) {
					toggleSheet();
				}
				disconnect();
			}}>
			<CssFlex gap={12}>
				<SignoutIcon size={"sm"} color={theme.palette.greyscale.gray} />
				<Typography variant={"body2"}>Disconnect</Typography>
			</CssFlex>
		</CssMenuItem>
	);
};
