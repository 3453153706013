import styled, { css } from "styled-components";

export const ContentStyled = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 32px;
		justify-content: space-between;
		padding: 24px 0;
		height: 100%;
		width: 100%;
	`
);

export const HeaderStyled = styled.div(
	({ theme }) => css`
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 100%;
	`
);

export const CssLiveMatchWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
	`
);
