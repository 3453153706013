import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		height: 600px;

		& > * {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			height: 0;

			& > *:nth-child(1) {
				border-bottom: 1px solid ${theme.palette.greyscale.gondola};
			}

			& > *:nth-child(2) {
				flex-grow: 1;
				height: 0;
				overflow-y: auto;
			}

			& > *:only-child {
				border: none;
			}
		}

		${theme.breakpoints.up("desktopMin")} {
			height: 100%;
		}
	`
);
