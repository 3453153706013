import { useMSLState } from "@/modules/msl";
import { memo } from "react";
import { WrapperStyled } from "./EditionBanner.styles";
import { EditionFinished, EditionInProgress } from "./components";

export const EditionBanner = memo(() => {
	const { group } = useMSLState();

	if (!group) throw new Error("No group");

	const editionFinished = group.tournamentEdition.endDate < new Date().getTime();

	return <WrapperStyled>{editionFinished ? <EditionFinished /> : <EditionInProgress />}</WrapperStyled>;
});
