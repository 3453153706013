import { ContractDefinition, ContractName, NetworkContracts } from "@metasoccer/metasoccer-types";

export class BlockchainService {
	constructor(private networkContracts: NetworkContracts) {}

	get chainId(): number {
		return this.networkContracts.chainId;
	}

	get contracts(): ContractDefinition[] {
		return this.networkContracts.contracts;
	}

	get contractsByName(): Record<string, ContractDefinition> {
		return this.networkContracts.contracts.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
	}

	getDefinition(contractName: ContractName): ContractDefinition {
		for (let i = 0; i < this.contracts.length; i++) {
			const contractDefinition = this.contracts[i];
			if (contractDefinition.name === contractName) {
				return contractDefinition;
			}
		}

		throw new Error(
			`Cannot find ContractDefinition <${contractName}> in NetworkContracts with Chain Id <${this.chainId}>`
		);
	}
}
