import { authService, configService, persistentStorageService } from "@/dependencies";
import { usePartner, useReferrer } from "@/modules/account";
import { getAuthKey } from "@/modules/core/auth";
import { decodeJWT, defineIf } from "@/modules/shared/utils";
import { LOGIN_DURATION_MS, LOGIN_MESSAGE } from "@metasoccer/metasoccer-types";
import { useAddress, useChainId, useSigner, useSwitchChain } from "@thirdweb-dev/react";
import { useCallback, useEffect, useState } from "react";

export const useAuth = () => {
	const [authenticated, setAuthenticated] = useState(false);

	const address = useAddress();
	const chainId = useChainId();
	const signer = useSigner();
	const switchChain = useSwitchChain();

	useEffect(() => {
		if (chainId) {
			switchChain(configService.get().chainId);
		}
	}, [chainId]);

	const authenticate = useCallback(async () => {
		if (!signer) return;

		try {
			const address = await signer.getAddress();
			const authKey = getAuthKey(address);

			const existingToken = persistentStorageService.getString(authKey);
			const existingTokenExpiration = defineIf(existingToken, () => decodeJWT(existingToken!).payload.expiration);

			if (!existingTokenExpiration || existingTokenExpiration < new Date().getTime()) {
				const expiration = Date.now() + LOGIN_DURATION_MS;
				const message = LOGIN_MESSAGE + expiration;
				const signedMessage = await signer.signMessage(message);
				const token = await authService.authenticate(undefined, signedMessage, expiration);
				persistentStorageService.save(authKey, token);
			}

			setAuthenticated(true);

			return address;
		} catch (e) {
			console.error(e);
		}
	}, [signer]);

	usePartner(address);
	useReferrer(address);

	return { address, authenticated, authenticate };
};
