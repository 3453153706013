export enum ContractName {
	CUSTOM_TOURNAMENTS = "MetaSoccerTournaments",
	CUSTOM_TOURNAMENTS_HELPER = "MetaSoccerTournamentsHelper",
	CUSTOM_TOURNAMENTS_V2 = "MetaSoccerTournamentsV2",
	ENTROPY_MANAGER = "EntropyManager",
	ERC20_REDEEMER = "ERC20Redeemer",
	MARKETPLACE = "Marketplace",
	MSC = "MetaSoccerCash",
	MSU = "MetaSoccerERC20",
	PASS = "MetaSoccerPass",
	PLAYER_BUNDLE_BUYER = "PlayerBundleBuyer",
	PLAYERS = "MetaSoccerPlayers",
	SCOUT_STAKING = "MetaSoccerStaking",
	SCOUTING = "MetaSoccerScouting",
	STARTER_BOX = "Multiwrap",
	STARTER_BOX_REDEEMER = "StarterBoxRedeemer",
	STORE = "MetaSoccerStore",
	TICKETS = "Vouchers",
	TICKETS_REDEEMER = "TicketsRedeemer",
	TICKETS_REDEEMER_V2 = "TicketsRedeemerV2",
	TICKETS_V2 = "TicketsV2",
	YOUTH_SCOUTS = "YouthScouts"
}
