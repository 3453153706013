import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import { Tab } from "@peersyst/react-components";
import styled, { css } from "styled-components";
var lgStyles = css(function (_ref) {
  var _theme$fonts;
  var theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tfont-size: 2.25rem;\n\t\tfont-family: ", ";\n\t\tcolor: ", ";\n\t"])), (_theme$fonts = theme.fonts) == null ? void 0 : _theme$fonts.pfdInt, theme.palette.greyscale.scorpion);
});
var mdStyles = css(function (_ref2) {
  var _theme$fonts2;
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tpadding: 0 16px;\n\t\theight: 100%;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\n\t\tfont-size: 1rem;\n\t\tfont-family: ", ";\n\t\tfont-weight: 500;\n\t\tmin-height: 2.313rem;\n\t"])), (_theme$fonts2 = theme.fonts) == null ? void 0 : _theme$fonts2.spaceGrotesk);
});
var smStyles = css(function (_ref3) {
  var _theme$fonts3;
  var theme = _ref3.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\tfont-size: 0.875rem;\n\t\tfont-family: ", ";\n\t\tfont-weight: 500;\n\t"])), (_theme$fonts3 = theme.fonts) == null ? void 0 : _theme$fonts3.spaceGrotesk);
});
var tapBarStyles = {
  lg: lgStyles,
  md: mdStyles,
  sm: smStyles
};
export var TapBarRoot = styled(Tab)(function (_ref4) {
  var theme = _ref4.theme,
    size = _ref4.size;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\tcolor: ", ";\n\t\ttext-transform: uppercase;\n\t\t&.Active {\n\t\t\tcolor: ", ";\n\t\t}\n\t\t", ";\n\t"])), theme.palette.greyscale.gray, theme.palette.greyscale.white, tapBarStyles[size]);
});