import { Covering, RoleChip, RoleTypography, Row, Typography } from "@metasoccer/metasoccer-ds";
import { PlayerSpecificRole, Role, SpecialPlayerSkill } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { SpecialAbilities } from "../../../SpecialAbilities";
import {
	CssNameWrapper,
	CssPlayerContentUpperPart,
	CssPlayerContentUpperPartInfo,
	CssPlayerContentUpperPartInfoWrapper,
	CssPlayerContentUpperPartOverall,
	CssPlayerContentUpperPartWrapper,
	CssTypography
} from "./PlayerUpperPart.styled";

interface PlayerUpperPartProps {
	currentOverall: number;
	potentialOverall: number;
	role: Role;
	specificRole: PlayerSpecificRole;
	shortName: string;
	age: number;
	condition: number;
	isForbiddenToBeSelected?: boolean;
	slotId?: string;
	slotRole?: Covering;
	slotSpecificRoles?: PlayerSpecificRole[];
	specialAbilities: SpecialPlayerSkill[];
}

export const PlayerUpperPart: FC<PlayerUpperPartProps> = ({
	currentOverall,
	potentialOverall,
	role,
	specificRole,
	shortName,
	age,
	condition = 0,
	isForbiddenToBeSelected = false,
	slotId,
	slotRole,
	slotSpecificRoles,
	specialAbilities
}) => {
	const hasConditionsActive = isForbiddenToBeSelected || condition < 20;

	return (
		<CssPlayerContentUpperPart>
			<CssPlayerContentUpperPartWrapper>
				<CssPlayerContentUpperPartOverall>
					<Typography variant="h6" className="player-card--current-overall">
						{currentOverall}
					</Typography>
					<Typography variant="subtitle2" light>
						/{potentialOverall}
					</Typography>
				</CssPlayerContentUpperPartOverall>
				<Row gap={4} alignItems="center">
					{slotRole && slotSpecificRoles && (
						<RoleTypography covering={slotRole} specificRoles={slotSpecificRoles} />
					)}
					{!slotRole && slotId && (
						<Typography variant="buttonMd" light>
							{slotId}
						</Typography>
					)}
					<RoleChip covering={role} specificRole={specificRole} />
				</Row>
			</CssPlayerContentUpperPartWrapper>
			<CssPlayerContentUpperPartWrapper>
				<CssNameWrapper>
					<CssTypography variant="h6" hasConditionsActive={hasConditionsActive}>
						{shortName}
					</CssTypography>
				</CssNameWrapper>
				<CssPlayerContentUpperPartInfoWrapper>
					<CssPlayerContentUpperPartInfo>
						<Typography variant="body2" light>
							Age
						</Typography>
						<Typography variant="body2">{age}</Typography>
					</CssPlayerContentUpperPartInfo>
					<CssPlayerContentUpperPartInfo>
						<SpecialAbilities specialAbilities={specialAbilities} />
					</CssPlayerContentUpperPartInfo>
				</CssPlayerContentUpperPartInfoWrapper>
			</CssPlayerContentUpperPartWrapper>
		</CssPlayerContentUpperPart>
	);
};
