import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";

export const PVP_ROUTES = {
	ROOT: {
		path: "/pvp",
		buildPath: () => "/pvp"
	},
	VIEW_PLAYED_MATCH: {
		path: "/matches/:matchId",
		buildPath: (matchId: MetaSoccerId<IdType.MatchScheduled | IdType.Match>) =>
			`${PVP_ROUTES.ROOT.path}/matches/${matchId}`
	},
	JOIN: {
		path: `/join`,
		buildPath: () => `${PVP_ROUTES.ROOT.path}/join`
	},
	CREATE: {
		path: `/create`,
		buildPath: () => `${PVP_ROUTES.ROOT.path}/create`
	},
	CUSTOM_TOURNAMENT: {
		path: "/tournament/:tournamentId",
		buildPath: (tournamentId: MetaSoccerId<IdType.Tournament>) =>
			`${PVP_ROUTES.ROOT.path}/tournament/${tournamentId}`
	}
};
