import { getImgUrl } from "@/modules/shared/utils";
import { FC } from "react";
import { CssNumber, CssWrapper } from "./Shirt.styles";

interface ShirtProps {
	isGK: boolean;
	number: number;
}

export const Shirt: FC<ShirtProps> = ({ number, isGK }) => {
	return (
		<CssWrapper>
			<img
				src={getImgUrl(
					`https://assets.metasoccer.com/ui/lineup/${isGK ? "Goalkeeper.svg" : "NonGoalkeeper.svg"}`,
					{ height: 96, format: "png" }
				)}
				height="100%"
			/>
			<CssNumber variant="subtitle2">{number}</CssNumber>
		</CssWrapper>
	);
};
