export default function groupBy<T>(array: T[], iteratee: ((value: T) => string) | string): Record<string, T[]> {
	const result: Record<string, T[]> = {};

	const getKey = (value: T) => {
		if (typeof iteratee === "function") {
			return iteratee(value);
		} else {
			// @ts-ignore
			return value[iteratee];
		}
	};

	for (const value of array) {
		const key = getKey(value);

		if (!result[key]) {
			result[key] = [];
		}

		result[key].push(value);
	}

	return result;
}
