import styled, { css } from "styled-components";

interface CssScoreMatchResultTeamProps {
	isReverseLayout: boolean;
}

export const CssScoreMatchResultTeam = styled.div<CssScoreMatchResultTeamProps>(
	({ isReverseLayout }) => css`
		display: flex;
		align-items: center;
		gap: 4px;
		flex-direction: ${isReverseLayout ? "row-reverse" : "row"};
	`
);
