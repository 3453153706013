import { Formation } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { CssLineupColumnItem, CssLineupColumnItemLast, CssModalWrapper } from "./ModalLineupSelector.styles";
import { Typography } from "@metasoccer/metasoccer-ds";

interface ModalLineupSelectorProps {
	formations: Formation[];
	selectedValueId: string;
	onClick: (formation: Formation) => void;
}

export const ModalLineupSelector: FC<ModalLineupSelectorProps> = ({ formations, selectedValueId, onClick }) => {
	const formationsLength = formations.length;

	return (
		<CssModalWrapper>
			{formations.map((formation: Formation, index: number) => {
				const columnStart = index % 2 === 0 ? 1 : 2;
				const isLastItem = index === formationsLength - 1;
				return !isLastItem || formations.length % 2 == 0 ? (
					<CssLineupColumnItem
						onClick={() => onClick(formation)}
						key={index}
						columnStart={columnStart}
						isSelected={selectedValueId === formation.id}>
						<Typography variant={"body2"}>{formation.name}</Typography>
					</CssLineupColumnItem>
				) : (
					<CssLineupColumnItemLast
						key={index}
						isSelected={selectedValueId === formation.id}
						onClick={() => onClick(formation)}>
						<Typography variant={"body2"}>{formation.name}</Typography>
					</CssLineupColumnItemLast>
				);
			})}
		</CssModalWrapper>
	);
};
