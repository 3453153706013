import styled, { css } from "styled-components";

export const CssNextMatchStatsPreviewWrapper = styled.div(
	({ theme }) => css`
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 24px;
	`
);

export const CssStatsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;
