import { useServerState } from "@/modules/core/react-query/hooks";
import { MSL_QUERIES } from "@/modules/msl/queries";
import { Dropdown, DropdownItem } from "@/modules/shared/components";
import { uniq } from "@/modules/shared/utils";
import { Row, Typography } from "@metasoccer/metasoccer-ds";
import { IdType, MetaSoccerId, MetaSoccerLeagueDivision, TournamentGroupInfoDTO } from "@metasoccer/metasoccer-types";
import { memo, useCallback, useEffect, useState } from "react";

interface GroupSelectorProps {
	initialGroup: TournamentGroupInfoDTO;
	selectedGroupId: MetaSoccerId<IdType.TournamentGroup>;
	onGroupSelected: (groupId: MetaSoccerId<IdType.TournamentGroup>) => void;
}

export const GroupSelector = memo<GroupSelectorProps>(({ initialGroup, selectedGroupId, onGroupSelected }) => {
	const [selectedDivision, setSelectedDivision] = useState(initialGroup.division);

	const { data: groupsFromServer } = useServerState(
		MSL_QUERIES.getGroupsOfEdition(initialGroup.tournamentEdition.tournamentEditionId!)
	);

	const divisions = groupsFromServer
		? uniq(groupsFromServer.map(({ division }) => division as MetaSoccerLeagueDivision)).sort()
		: [initialGroup.division];
	const groups = groupsFromServer ?? [
		{ division: initialGroup.division!, groupdId: selectedGroupId, name: initialGroup.name }
	];
	const groupsOfDivision = groups.filter(({ division }) => division === selectedDivision);

	const normalizeGroupName = useCallback(
		(name: string) => {
			return name.replace(`${selectedDivision} -`, "");
		},
		[selectedDivision]
	);

	const renderSelectedValue = useCallback(
		(selectedGroupId: string) => {
			const group = groups.find(({ groupdId }) => groupdId === selectedGroupId);
			if (group) {
				return <Typography variant="body2">{normalizeGroupName(group.name)}</Typography>;
			} else {
				return (
					<Typography variant="body2" light>
						Invalid group
					</Typography>
				);
			}
		},
		[selectedGroupId]
	);

	useEffect(() => {
		if (!groupsOfDivision.find(({ groupdId }) => groupdId === selectedGroupId)) {
			onGroupSelected(groupsOfDivision[0].groupdId);
		}
	}, [groupsOfDivision]);

	return (
		<Row gap={12}>
			<Dropdown placeholder="Division" selectedValue={selectedDivision}>
				{divisions.map((division) => (
					<DropdownItem key={division} onClick={() => setSelectedDivision(division)}>
						<Typography variant="body2">{division}</Typography>
					</DropdownItem>
				))}
			</Dropdown>
			<Dropdown placeholder="Group" selectedValue={selectedGroupId} renderSelectedValue={renderSelectedValue}>
				{groupsOfDivision.map((group) => (
					<DropdownItem key={group.groupdId} onClick={() => onGroupSelected(group.groupdId)}>
						<Typography variant="body2">{normalizeGroupName(group.name)}</Typography>
					</DropdownItem>
				))}
			</Dropdown>
		</Row>
	);
});
