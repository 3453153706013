import styled from "styled-components";

export const MenuItemStyled = styled.div`
	align-items: center;
	padding: 8px;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.palette.greyscale.gondola};
	}
`;
