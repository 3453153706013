import { MatchScore } from "@/modules/shared/components";
import { MATCH_SIMULATION_TIME_SECONDS } from "@/modules/shared/constants";
import { getMatchHour } from "@/modules/shared/helpers";
import { ClubLogo, Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { IdType, MetaSoccerId, ScheduledMatchPreviewInfo } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { formatDate } from "../../helpers";
import { CssDateWrapper, CssScheduledMatchPreviewWrapper, CssTeamWrapper } from "./ScheduledMatchPreview.styled";
import { Live } from "./components";

interface ScheduledMatchPreviewProps {
	scheduledMatchPreviewInfo: ScheduledMatchPreviewInfo;
	selectedTeamId: MetaSoccerId<IdType.Team>;
	onClick?: () => void;
}

export const ScheduledMatchPreview: FC<ScheduledMatchPreviewProps> = ({
	scheduledMatchPreviewInfo,
	selectedTeamId,
	onClick
}) => {
	const { status, teams, date, goals, reason } = scheduledMatchPreviewInfo;
	const isSelectedTeam = selectedTeamId === teams.home.id || selectedTeamId === teams.away.id;
	const selectedTeamSide: "home" | "away" | undefined =
		isSelectedTeam && selectedTeamId === teams.home.id ? "home" : "away";
	const isCancelled = status === "Suspended";
	const hasMatchReport = status === "Played";
	const isScheduled = status === "Scheduled";
	const isMatchBeingPlayed =
		status === "Played" && scheduledMatchPreviewInfo.liveOffsetSeconds <= MATCH_SIMULATION_TIME_SECONDS;

	const renderResult = () => {
		return (
			<CssDateWrapper>
				<MatchScore
					homeGoals={goals?.home || 0}
					awayGoals={goals?.away || 0}
					isMatchResultLayout={false}
					isFinished={isSelectedTeam}
					side={selectedTeamSide}
				/>
			</CssDateWrapper>
		);
	};

	return (
		<CssScheduledMatchPreviewWrapper
			isCancelled={isCancelled}
			isForfeited={status === "Forfeited"}
			onClick={() => hasMatchReport && onClick && onClick()}>
			<CssTeamWrapper>
				<Typography variant="caption">{teams.home.acronym}</Typography>
				<ClubLogo name={teams.home.logo.name} color={teams.home.logo.color} url={teams.home.logo.url} />
			</CssTeamWrapper>
			{status === "Forfeited" && (
				<Tooltip>
					{{
						popper: <Typography variant="description">Not played due to improper lineup.</Typography>,
						content: renderResult()
					}}
				</Tooltip>
			)}
			{status === "Played" && !isMatchBeingPlayed && renderResult()}
			{status === "Played" && isMatchBeingPlayed && <Live />}
			{isScheduled && (
				<CssDateWrapper>
					<Typography variant={"tooltip"} light>
						{formatDate(date)}
					</Typography>
					<Typography variant={"description"} light>
						{getMatchHour(date)}
					</Typography>
				</CssDateWrapper>
			)}
			{isCancelled && (
				<CssDateWrapper>
					<Tooltip>
						{{
							popper: <Typography variant="body2">{reason}</Typography>,
							content: (
								<Typography variant="body2" light>
									Canceled
								</Typography>
							)
						}}
					</Tooltip>
				</CssDateWrapper>
			)}
			<CssTeamWrapper reverse>
				<ClubLogo name={teams.away.logo.name} color={teams.away.logo.color} url={teams.away.logo.url} />
				<Typography variant="caption">{teams.away.acronym}</Typography>
			</CssTeamWrapper>
		</CssScheduledMatchPreviewWrapper>
	);
};
