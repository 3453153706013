import { useIsMobile } from "@/layouts/context";
import { CoinSize, MscCoin, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssTokenRow } from "./Tokens.styled";

interface MscTokenProps {
	quantity: number;
	size?: CoinSize;
	minWidth?: string;
}

export const MscToken: FC<MscTokenProps> = ({ quantity, size = "sm", minWidth }) => {
	const isMobile = useIsMobile();
	const variant = isMobile ? (size === "xs" ? "caption" : "body2") : "body2";
	return (
		<CssTokenRow size={size} minWidth={minWidth}>
			<MscCoin size={size} />
			<Typography variant={variant}>
				{quantity.toLocaleString(undefined, {
					maximumFractionDigits: 2
				})}
			</Typography>
		</CssTokenRow>
	);
};
