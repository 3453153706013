import { analyticsService, configService } from "@/dependencies";
import { useAccountGuard } from "@/modules/auth";
import { Container, Modal } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { getImgUrl } from "@/modules/shared/utils";
import {
	Accordion,
	ArrowRightIcon,
	Button,
	Col,
	InfoIcon,
	MsuCoin,
	Row,
	TimeIcon,
	Tooltip,
	Typography
} from "@metasoccer/metasoccer-ds";
import axios from "axios";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Product } from "../Product";
import { TextFieldWithCoinDecorator } from "./components";

const CHECKOUT_BASE_URL = configService.get().checkoutBaseUrl;
const MAX_PURCHASE_USD = 1000000;
const MIN_PURCHASE_USD = 5;
const PROCESSING_FEE_PERCENTAGE = 0.05;
const TEN_SECS_MS = 10000;

export const BuyMSU = memo(() => {
	const initialExchangeRate = 0.004;
	const initialAmountUSD = MIN_PURCHASE_USD * 2;

	const amountMSURef = useRef(Math.round((initialAmountUSD * (1 - PROCESSING_FEE_PERCENTAGE)) / initialExchangeRate));
	const amountUSDRef = useRef(initialAmountUSD);
	const exchangeRateRef = useRef(initialExchangeRate);

	const { address } = useGlobalState();
	const { executeConnected } = useAccountGuard();

	const [amountMSU, setAmountMSU] = useState(amountMSURef.current);
	const [amountUSD, setAmountUSD] = useState(amountUSDRef.current);
	const [maxPurchaseMSU, setMaxPurchaseMSU] = useState(MAX_PURCHASE_USD / exchangeRateRef.current);
	const [minPurchaseMSU, setMinPurchaseMSU] = useState(MIN_PURCHASE_USD / exchangeRateRef.current);
	const [timeToNextExchangeRateUpdateInterval, setTimeToNextExchangeRateUpdateInterval] = useState<NodeJS.Timeout>();
	const [timeToNextExchangeRateUpdateMs, setTimeToNextExchangeRateUpdateMs] = useState(0);

	const [amountUpdated, setAmountUpdated] = useState(0);
	const [isCreatingSession, setIsCreatingSession] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		setAmountMSU(amountMSURef.current || 0);
		setAmountUSD(amountUSDRef.current || 0);
	}, [amountUpdated]);

	useEffect(() => {
		fetchAndUpdateExchangeRate();

		if (timeToNextExchangeRateUpdateInterval) {
			clearInterval(timeToNextExchangeRateUpdateInterval);
		}

		if (isModalOpen) {
			analyticsService.sendEvent("openBuyMSUModal", { address });

			setTimeToNextExchangeRateUpdateInterval(
				setInterval(() => {
					setTimeToNextExchangeRateUpdateMs((prev) => Math.max(prev - 1000, 0));
				}, 1000)
			);
		}
	}, [isModalOpen]);

	const createCheckoutSession = useCallback(() => {
		if (!address) throw new Error("No address found");

		setIsCreatingSession(true);

		axios
			.post(`${CHECKOUT_BASE_URL}/create-checkout-session`, {
				address,
				amount: amountUSD,
				redirectUrl: window.location.href
			})
			.then(({ data: redirectUrl }) => {
				analyticsService.sendEvent("buyMSUCheckoutSuccess", { address, amountUSD });
				window.location.href = redirectUrl;
			})
			.catch((error) => {
				analyticsService.sendEvent("buyMSUCheckoutError", { address, amountUSD });
				console.error(error);
			})
			.finally(() => {
				setIsCreatingSession(false);
			});
	}, [address, amountUSD]);

	const fetchAndUpdateExchangeRate = useCallback(() => {
		axios
			.get(`${CHECKOUT_BASE_URL}/exchange-rate`)
			.then(({ data }) => {
				const { exchangeRate, exchangeRateLastUpdate } = data;
				const timeToNextUpdateMs = exchangeRateLastUpdate + TEN_SECS_MS - Date.now();

				updateExchangeRate(exchangeRate);

				if (isModalOpen) {
					setTimeToNextExchangeRateUpdateMs(timeToNextUpdateMs);
					setTimeout(() => {
						fetchAndUpdateExchangeRate();
					}, timeToNextUpdateMs);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [isModalOpen]);

	const updateAmountMSU = useCallback(
		(value: number) => {
			amountMSURef.current = value;
			amountUSDRef.current = value * exchangeRateRef.current * (1 + PROCESSING_FEE_PERCENTAGE);
			setAmountUpdated((prev) => prev + 1);
		},
		[exchangeRateRef.current]
	);

	const updateAmountUSD = useCallback(
		(value: number) => {
			amountUSDRef.current = value;
			amountMSURef.current = Math.round((value * (1 - PROCESSING_FEE_PERCENTAGE)) / exchangeRateRef.current);
			setAmountUpdated((prev) => prev + 1);
		},
		[exchangeRateRef.current]
	);

	const updateExchangeRate = useCallback(
		(value: number) => {
			exchangeRateRef.current = value;
			amountMSURef.current = Math.round((amountUSDRef.current * (1 - PROCESSING_FEE_PERCENTAGE)) / value);
			setMaxPurchaseMSU(MAX_PURCHASE_USD / value);
			setMinPurchaseMSU(MIN_PURCHASE_USD / value);
			setAmountUpdated((prev) => prev + 1);
		},
		[exchangeRateRef, isModalOpen]
	);

	return (
		<>
			<Product
				backgroundColor={"linear-gradient(0deg, rgba(61, 114, 253, 0.3), rgba(61, 114, 253, 0.3)), #06060A;"}
				callToAction={
					<Typography variant="body2" numberOfLines={1} textAlign="center">
						Buy with Credit Card
					</Typography>
				}
				iconUrl={getImgUrl("https://assets.metasoccer.com/icons/msu.png", {
					height: 80
				})}
				name="MSU"
				onClick={() => executeConnected(() => setIsModalOpen(true))}
			/>
			<Modal isOpen={isModalOpen} title="Buy MSU" onClose={() => setIsModalOpen(false)}>
				<Container>
					<Col gap={36}>
						<Col gap={24}>
							<TextFieldWithCoinDecorator
								required
								type="number"
								coinIcon={
									<img
										alt="USD"
										src={getImgUrl("https://assets.metasoccer.com/icons/usd.svg")}
										width={20}
										height={20}
									/>
								}
								coinName="US$"
								name="usd"
								value={`${amountUSD}`}
								validators={{ gt: [1, `Minimum purchase is US$ ${MIN_PURCHASE_USD}`] }}
								onChange={(v) => updateAmountUSD(parseFloat(v))}
							/>
							<TextFieldWithCoinDecorator
								required
								type="number"
								coinIcon={<MsuCoin size="sm" />}
								coinName="MSU"
								name="msu"
								value={`${amountMSU}`}
								validators={{ gt: [1, `Minimum purchase is MSU ${minPurchaseMSU}`] }}
								onChange={(v) => updateAmountMSU(parseFloat(v))}
							/>
							<Row gap={12}>
								{[1, 2, 5, 10, 20].map((multiplier) => (
									<Button
										key={multiplier}
										appearance="secondary"
										label={`US$ ${MIN_PURCHASE_USD * multiplier}`}
										style={{ flex: 1 }}
										onClick={() => updateAmountUSD(MIN_PURCHASE_USD * multiplier)}
									/>
								))}
							</Row>

							<Accordion
								title={`You get MSU ${amountMSU.toLocaleString(
									"en-US"
								)} for US$ ${amountUSD.toLocaleString("en-US")}`}>
								<Col gap={4}>
									<Row alignItems="center" justifyContent="space-between">
										<Row gap={4} alignItems="center">
											<Typography variant="caption">
												MSU {amountMSU.toLocaleString("en-US")}
											</Typography>
											<Typography variant="caption" light>
												@ US$ {exchangeRateRef.current.toLocaleString("en-US")}
											</Typography>
										</Row>
										<Typography variant="caption">
											US$ {(amountUSD * (1 - PROCESSING_FEE_PERCENTAGE)).toLocaleString("en-US")}
										</Typography>
									</Row>
									<Row alignItems="center" justifyContent="space-between">
										<Row gap={4} alignItems="center">
											<Typography variant="caption">Processing fee</Typography>
											<Tooltip>
												{{
													popper: (
														<Typography variant="caption">
															This includes the amount charged by Stripe to cover the
															costs associated with payment processing, as well as the
															amount paid to Polygon miners for securely processing the
															transaction on the blockchain.
														</Typography>
													),
													content: <InfoIcon size="sm" />
												}}
											</Tooltip>
										</Row>
										<Typography variant="caption">
											US$ {(amountUSD * PROCESSING_FEE_PERCENTAGE).toLocaleString("en-US")}
										</Typography>
									</Row>
								</Col>
							</Accordion>

							<Row gap={4} alignItems="center" justifyContent="center">
								<TimeIcon size="sm" opacity={0.42} />
								<Typography variant="caption" light>
									Quote updates in {Math.round(timeToNextExchangeRateUpdateMs / 1000)} seconds
								</Typography>
							</Row>
						</Col>
						<Col gap={12}>
							<Button
								variant="filled"
								label="Pay"
								loading={isCreatingSession}
								rightIcon={<ArrowRightIcon size="sm" />}
								onClick={createCheckoutSession}
							/>
							<Typography variant="caption" textAlign="center">
								After clicking 'Pay', you will be redirected to Stripe to complete your payment. Please
								follow the instructions on their platform to finalize your transaction.
							</Typography>
						</Col>
					</Col>
				</Container>
			</Modal>
		</>
	);
});
