import { httpService } from "@/dependencies";
import { useToast } from "@/modules/shared/hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const usePartner = (address?: string) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const ogcUserId = searchParams.get("ogc");

	const { showErrToast } = useToast();

	useEffect(() => {
		if (address && ogcUserId) {
			httpService
				.post("/account/partner", { key: "OGC", id: ogcUserId })
				.then(() => {
					searchParams.delete("ogc");
					setSearchParams(searchParams);
				})
				.catch((err) => {
					showErrToast(err.message);
				});
		}
	}, [address, ogcUserId]);
};
