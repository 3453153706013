import { configService } from "@/dependencies";
import { Polygon, SkaleNebulaHub } from "@thirdweb-dev/chains";
import {
	ThirdwebProvider,
	ThirdwebProviderProps,
	embeddedWallet,
	metamaskWallet,
	smartWallet,
	walletConnect
} from "@thirdweb-dev/react";
import { ReactNode } from "react";
import { Environment } from "../shared/types";
import { magicWallet } from "./wallets";

interface Web3ProviderProps {
	children: ReactNode;
}

export const Web3Provider = ({ children }: Web3ProviderProps) => {
	const { chainId, env, smartWalletFactoryAddress } = configService.get();

	const chainByChainId: Record<number, object> = {
		137: Polygon,
		1482601649: SkaleNebulaHub
	};

	const dAppMeta = {
		name: "MetaSoccer",
		url: "https://metasoccer.com"
	};

	const thirdwebProps: ThirdwebProviderProps<any> =
		env === Environment.PROD
			? {
					supportedWallets: [
						smartWallet(embeddedWallet({ recommended: true }), {
							factoryAddress: smartWalletFactoryAddress,
							gasless: true
						}),
						magicWallet(),
						metamaskWallet(),
						walletConnect()
					]
			  }
			: {
					supportedWallets: [embeddedWallet(), metamaskWallet({ recommended: true }), walletConnect()]
			  };

	return (
		<ThirdwebProvider
			activeChain={chainByChainId[chainId]}
			clientId="a8fd5d4d91d0da0f0485ac27da84b578"
			dAppMeta={dAppMeta}
			{...thirdwebProps}>
			{children}
		</ThirdwebProvider>
	);
};
