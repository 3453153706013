import { Outlet, Route, Routes } from "react-router-dom";
import { HomeContextProvider } from "./context";
import { HOME_ROUTES } from "./home.routes";
import { HomePage } from "./views";
import ResultsPage from "./views/Deprecated/Results/Results.page";
import StandingsPage from "./views/Deprecated/Standings/Standings.page";

const HomeRouter = () => {
	return (
		<HomeContextProvider>
			<Routes>
				<Route path="" element={<HomePage />} />
				<Route path={HOME_ROUTES.RESULTS.path} element={<ResultsPage />} />
				<Route path={HOME_ROUTES.STANDINGS.path} element={<StandingsPage />} />
			</Routes>
			<Outlet />
		</HomeContextProvider>
	);
};

export default HomeRouter;
