import { Row } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		flex: 1;
		height: 100%;
	`
);

export const FooterStyled = styled(Row)(
	({ theme }) => css`
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	`
);

export const HeaderStyled = styled(Row)(
	({ theme }) => css`
		align-items: center;
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		justify-content: space-between;
		padding: 24px;
	`
);

export const TitleStyled = styled(Row)(
	({ theme }) => css`
		align-items: center;
		gap: 4px;
	`
);
