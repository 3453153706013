import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssAirdropRankingItemWrapper = styled.div<{ borderColor?: string }>(
	({ borderColor, theme }) => css`
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		border-left: 4px solid ${borderColor ?? "transparent"};
	`
);

interface CssAirdropRankingItemContentProps {
	isMyTeam: boolean;
}

export const CssAirdropRankingItemContent = styled.div<CssAirdropRankingItemContentProps>(
	({ theme, isMyTeam }) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 0px;
		background-color: ${isMyTeam && theme.palette.greyscale.gondola};
	`
);

export const CssLeftPartWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-left: 4px;
`;

export const CssPositionTypography = styled(Typography)`
	display: flex;
	justify-content: center;
	width: 40px;
`;

export const CssRightPartWrapper = styled.div`
	padding-right: 24px;
`;

export const CssClubContent = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const CssTypography = styled(Typography)`
	padding: 0px 18px;
`;
