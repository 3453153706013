import styled, { css } from "styled-components";

export const CssContent = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		justify-content: space-between;
	`
);

export const CssArrowWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
	`
);

export const CssLogoWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		height: 66px;
		width: 66px;
	`
);
