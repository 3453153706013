import styled from "styled-components";

export const CssDrillFooterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
	padding: 12px;
	border-top: 1px solid ${(props) => props.theme.palette.greyscale.gondola};
`;

export const CssDrillFooterLeftWrapper = styled.div`
	display: flex;
	gap: 16px;
`;

export const CssDrillFooterContent = styled.div`
	display: flex;
	gap: 4px;
`;
