import { ClubLogo, Typography } from "@metasoccer/metasoccer-ds";
import { InjuredLockedPlayer, PlayerSpecificRole, PvpTeamInfo } from "@metasoccer/metasoccer-types";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { FC, memo, useMemo } from "react";

import { Stats } from "../../../../../Stats";
import { LAST_LINEUP_TABLE_COLUMNS } from "./constants";
import { getLastBenchPlayers, getLastLineupPlayers } from "./helpers";
import {
	CssClubContent,
	CssFormationTableHeader,
	CssMatchTeamLineupTableHeader,
	CssMatchTeamLineupWrapper,
	CssTableSeparator,
	CssTBody,
	CssTbodyTd,
	CssTbodyTr,
	CssTHead,
	CssTheadTr
} from "./LastLineupTable.styled";

export type LineupTablePlayer = {
	specificRole: PlayerSpecificRole;
	name: string;
	specialAbilities: string[];
	overall: string;
	id: number;
	isInjured?: InjuredLockedPlayer;
};
interface LineupTableProps {
	team: PvpTeamInfo;
	injuredPlayers?: InjuredLockedPlayer[];
}

export const LastLineupTable: FC<LineupTableProps> = memo(({ team, injuredPlayers }) => {
	const columns = useMemo(() => LAST_LINEUP_TABLE_COLUMNS, []);

	const linedTable = useReactTable({
		data: getLastLineupPlayers(team.lastLinedPlayers, injuredPlayers || []),
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	const benchTable = useReactTable({
		data: getLastBenchPlayers(team.registeredNonLinedPlayers, injuredPlayers || []),
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	return (
		<CssMatchTeamLineupWrapper>
			<CssFormationTableHeader>
				<Typography variant="buttonSm" light>
					FORMATION
				</Typography>
				<Typography variant="buttonSm">{(team as any).formation || "-"}</Typography>
			</CssFormationTableHeader>
			<CssMatchTeamLineupTableHeader>
				<CssClubContent>
					<ClubLogo
						size={"xs"}
						name={team.clubLogo.name}
						color={team.clubLogo.color}
						url={team.clubLogo.url}></ClubLogo>
					<Typography variant={"body1Bold"}>{team.teamName}</Typography>
				</CssClubContent>
				<Stats stat={team.overall}></Stats>
			</CssMatchTeamLineupTableHeader>
			<table style={{ borderCollapse: "collapse", width: "100%" }}>
				<CssTHead>
					{linedTable.getHeaderGroups().map((headerGroup) => (
						<CssTheadTr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<th key={header.id}>
									{flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</CssTheadTr>
					))}
				</CssTHead>
				<CssTBody>
					{linedTable.getRowModel().rows.map((row) => (
						<CssTbodyTr key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<CssTbodyTd key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</CssTbodyTd>
							))}
						</CssTbodyTr>
					))}
					<CssTableSeparator colSpan={4}>
						<CssMatchTeamLineupTableHeader>
							<Typography variant="body2">Bench</Typography>
						</CssMatchTeamLineupTableHeader>
					</CssTableSeparator>

					{benchTable.getRowModel().rows.map((row) => (
						<CssTbodyTr key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<CssTbodyTd key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</CssTbodyTd>
							))}
						</CssTbodyTr>
					))}
				</CssTBody>
			</table>
		</CssMatchTeamLineupWrapper>
	);
});
