import { MARKET_ROUTES } from "@/modules/market";
import { ContinueButton } from "@/modules/shared/components";
import { getImgUrl } from "@/modules/shared/utils";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ContentStyled, HomeButtonStyled } from "./GetYourSquad.styles";

export const GetYourSquad = memo(() => {
	const isChristmas = useFlag(FeatureFlag.Christmas);

	const navigate = useNavigate();

	const goToMarketPage = () => {
		navigate(MARKET_ROUTES.ROOT.buildPath());
	};

	return (
		<HomeButtonStyled onClick={goToMarketPage}>
			<ContentStyled>
				<Col gap={8}>
					<Typography textAlign="center" variant="h5">
						Get your squad
					</Typography>
					<Typography textAlign="center" variant="caption">
						Go to the market to get your first squad!
					</Typography>
				</Col>
				<img
					alt="Squad"
					src={getImgUrl(
						`https://assets.metasoccer.com/squad/${isChristmas ? "squad-christmas.svg" : "squad.svg"}`
					)}
				/>
				<ContinueButton text="Go to market" />
			</ContentStyled>
		</HomeButtonStyled>
	);
});
