import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

interface CssPrizePoolWrapperProps {
	hideTitle: boolean;
}

export const CssPrizePoolWrapper = styled.div<CssPrizePoolWrapperProps>(
	({ theme, hideTitle }) => css`
		display: flex;
		flex-direction: column;
		padding: ${hideTitle ? "16px 0px 0px 0px" : "24px 12px"};
		max-height: 600px;

		${theme.breakpoints.up("desktopMin")} {
			flex: 1 1 0%;
			padding: ${hideTitle ? "16px 0px 0px 0px" : "24px"};
		}
	`
);

export const CssPrizePoolDivision = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding: 16px 12px;
		max-height: 40px;
		height: 100%;
		width: 100%;
		background-color: ${theme.palette.backgrounds.codGray};
		align-items: center;
	`
);

export const CssOverall = styled.div`
	border: 1px solid #8d8d8d;
	border-radius: 100px;
	padding: 0px 6px;
	display: flex;
	text-align: center;
	height: 19px;
	align-items: center;
`;

export const CssOverallWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const CssTitle = styled(Typography)`
	min-height: 22px;
`;
