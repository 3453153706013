import { IconButton, MagicianIcon, Row } from "@metasoccer/metasoccer-ds";
import { Formation, LineupWithSkill } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { LineupQualityStars } from "../../../shared/components/LineupQualityStars/LineupQualityStars";
import { CssWrapper } from "./LineupHeader.styles";
import { LineupSelector } from "./components/LineupSelector";

interface LineupHeaderProps {
	variant?: "small" | "default";
	lineupWithSkill: LineupWithSkill;
	onAutoLineupClick?: () => void;
	onFormationSelected: (formation: Formation) => void;
}

export const LineupHeader = memo<LineupHeaderProps>(
	({ variant = "default", lineupWithSkill, onAutoLineupClick, onFormationSelected }) => {
		return (
			<CssWrapper variant={variant}>
				<LineupQualityStars
					quality={lineupWithSkill.skill.overallSkill}
					qualityPosition="center"
					size="md"
					potential={lineupWithSkill.skill.overallSkill}
				/>
				<Row gap={8}>
					<IconButton appearance="secondary" icon={MagicianIcon} size="md" onClick={onAutoLineupClick} />
					<LineupSelector currentLineup={lineupWithSkill!} onFormationSelected={onFormationSelected} />
				</Row>
			</CssWrapper>
		);
	}
);
