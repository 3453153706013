import { useIsMobile } from "@/layouts/context";
import { Christmify } from "@/modules/christmas";
import { OnboardingStepperModal } from "@/modules/home";
import { useOnboardingModal } from "@/modules/home/hooks/useOnboardingModal";
import { useGlobalState } from "@/modules/shared/context";
import { HoverMenu, Modal, TopbarLogo } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { FC, useEffect, useState } from "react";
import { CssContent, CssFlex } from "./HeaderHoverMenu.styled";
import { UserMenu } from "./components";

export const HeaderHoverMenu: FC<{}> = () => {
	const isChristmasEnabled = useFlag(FeatureFlag.Christmas);

	const { isConnected, showOnboarding } = useGlobalState();

	const [isSheetOpen, setIsSheetOpen] = useState(false);
	const toggleSheet = () => setIsSheetOpen((prevState) => !prevState);

	const isMobile = useIsMobile();

	const { isOpen, toggleModal, onMarketClick } = useOnboardingModal();

	useEffect(() => {
		if (showOnboarding) {
			toggleModal();
		}
	}, [showOnboarding]);

	return (
		<>
			{isMobile ? (
				<>
					<CssFlex
						justifyContent={"center"}
						verticalAlign={"middle"}
						style={{ padding: "0 12px" }}
						onClick={() => isConnected && toggleSheet()}>
						<Christmify if={isChristmasEnabled}>
							<TopbarLogo size="sm" className="logo" />
						</Christmify>
					</CssFlex>
					{isSheetOpen && (
						<Modal isOpen={isSheetOpen} onClose={toggleSheet} title="Profile">
							<CssContent>
								<UserMenu toggleSheet={toggleSheet} />
							</CssContent>
						</Modal>
					)}
				</>
			) : (
				<HoverMenu
					position={"right"}
					menuComponent={
						<CssFlex justifyContent={"center"} verticalAlign={"middle"} style={{ padding: "0 16px" }}>
							<Christmify if={isChristmasEnabled}>
								<TopbarLogo size="sm" className="logo" />
							</Christmify>
						</CssFlex>
					}>
					<div
						style={{
							width: "200px"
						}}>
						{<UserMenu toggleSheet={toggleSheet} />}
					</div>
				</HoverMenu>
			)}
			<OnboardingStepperModal
				isOpen={isOpen}
				onClose={toggleModal}
				onMarketClick={() => {
					onMarketClick();
					toggleModal();
				}}
			/>
		</>
	);
};
