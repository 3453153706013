import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";
import { BaseServerStateKeys } from "@/modules/shared/constants";
import { lineupWithSkillService } from "@/dependencies";
import { lineupService } from "@/modules/lineup/dependencies";

export const LINEUP_QUERIES = {
	lineupsWithSkill: {
		byOwnerAndTeamId: (owner?: string, selectedTeamId?: MetaSoccerId<IdType.Team>) => ({
			queryKey: [BaseServerStateKeys.LINEUP_WITH_SKILL, owner ?? "no_wallet", selectedTeamId ?? "no_team"],
			queryFn: () => lineupWithSkillService.getByOwnerAndTeamId(owner, selectedTeamId)
		}),
		byManagerAndTeamId: (manager?: string, selectedTeamId?: MetaSoccerId<IdType.Team>) => ({
			queryKey: [BaseServerStateKeys.LINEUP_WITH_SKILL, manager ?? "no_wallet", selectedTeamId ?? "no_team"],
			queryFn: () => lineupWithSkillService.getByManagerAndTeamId(manager, selectedTeamId)
		})
	},
	formations: {
		all: () => ({
			queryKey: [BaseServerStateKeys.FORMATIONS],
			queryFn: () => lineupService.getAllFormations()
		})
	}
};
