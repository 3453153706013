import { CoinSize, KnowledgeCoin, Row, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { formatMoney } from "../../utils";

interface KnowledgeTokenProps {
	quantity: number;
	size?: CoinSize;
}

export const KnowledgeToken: FC<KnowledgeTokenProps> = ({ quantity, size = "sm" }) => {
	return (
		<Row alignItems="center" gap={8}>
			<KnowledgeCoin size={size} />
			<Typography variant="body2">{formatMoney(quantity)}</Typography>
		</Row>
	);
};
