import styled, { css } from "styled-components";

export const CssPrizePoolValuesWrapper = styled.div(
	({ theme }) => css`
		height: 100%;
		display: flex;
		flex-direction: column;
		border-top: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
		overflow-y: scroll;
		border-bottom: 1px solid #1c1c1c;
	`
);
