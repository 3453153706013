import styled, { css } from "styled-components";

export const CssContent = styled.div(
	({ theme }) => css`
		flex: 1 1 0%;
		padding: 16px 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		max-width: 520px;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
		}
	`
);

export const CssCost = styled.div(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.greyscale.gondola};
		max-width: 520px;
		padding: 24px;
		display: flex;
	`
);

export const CssCostLabel = styled.div`
	flex: 1 1 0%;
`;
