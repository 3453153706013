import {
	CustomTournamentTeamInfoDTO,
	GroupOfEdition,
	HttpClient,
	IdType,
	MetaSoccerId,
	Response,
	TournamentGroupInfoDTO
} from "@metasoccer/metasoccer-types";

export class MSLService {
	constructor(private readonly httpService: HttpClient) {}

	async getGroup(groupId: MetaSoccerId<IdType.TournamentGroup>): Promise<TournamentGroupInfoDTO> {
		try {
			const { data } = await this.httpService.get<TournamentGroupInfoDTO>(`/msl/group/${groupId}`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getGroupTeamInfo(
		groupId: MetaSoccerId<IdType.TournamentGroup>,
		teamId: MetaSoccerId<IdType.Team>
	): Promise<CustomTournamentTeamInfoDTO> {
		try {
			const { data } = await this.httpService.get<TournamentGroupInfoDTO>(
				`/msl/group/${groupId}/teamInfo/${teamId}`
			);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getGroupsOfEdition(editionId: MetaSoccerId<IdType.TournamentEdition>): Promise<GroupOfEdition[]> {
		try {
			const { data } = await this.httpService.get<GroupOfEdition[]>(`/msl/groupsOfEdition/${editionId}`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getJoined(teamId: MetaSoccerId<IdType.Team>): Promise<{ joined: boolean }> {
		try {
			const { data } = await this.httpService.get<{ joined: boolean }>(`/msl/joined/${teamId}`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getRegisteredPlayers(
		editionId: MetaSoccerId<IdType.TournamentEdition>,
		teamId: MetaSoccerId<IdType.Team>
	): Promise<number[]> {
		try {
			const { data } = await this.httpService.get<number[]>(
				`/msl/edition/${editionId}/registeredPlayers/${teamId}`
			);
			return data || [];
		} catch (err) {
			return [];
		}
	}

	async join(teamId: string): Promise<void> {
		let response: Response<void>;
		try {
			response = await this.httpService.post<void>(`/msl/join/${teamId}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
