import { PlayToHaveFunPvEModal } from "@/modules/play/components/PlayToHaveFunModal";
import { Button } from "@metasoccer/metasoccer-ds";
import { memo, useState } from "react";

interface FriendlyMatchProps {
	fullWidth?: boolean;
	label?: string;
}

export const FriendlyMatch = memo(({ fullWidth, label }: FriendlyMatchProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<Button
				variant="filled"
				fullWidth={fullWidth}
				label={label ?? "Play"}
				onClick={() => setIsModalOpen(true)}
			/>
			{isModalOpen && <PlayToHaveFunPvEModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
		</>
	);
});
