import { IdType, MetaSoccerId } from "@/id";

export const MSL_AMERICA: MetaSoccerId<IdType.Tournament> = "TN:MSL+AM";
export const MSL_ASIA: MetaSoccerId<IdType.Tournament> = "TN:MSL+AS";
export const MSL_EUROPA: MetaSoccerId<IdType.Tournament> = "TN:MSL+EU";
export const MSL_WORLDWIDE: MetaSoccerId<IdType.Tournament> = "TN:MSL+WW";

export const MSL_MSU_REWARDS_TOTAL = [300000, 80000, 20000, 5000];
export const MSL_MSU_REWARDS_DISTRIBUTION = [
	[0.3, 0.25, 0.15, 0.1, 0.04, 0.035, 0.03, 0.025, 0.02, 0.015, 0.015, 0.01, 0.005, 0.005, 0, 0],
	[0.3, 0.25, 0.15, 0.1, 0.04, 0.035, 0.03, 0.025, 0.02, 0.015, 0.015, 0.01, 0.005, 0.005, 0, 0],
	[0.3, 0.25, 0.15, 0.1, 0.04, 0.035, 0.03, 0.025, 0.02, 0.015, 0.015, 0.01, 0.005, 0.005, 0, 0],
	[0.3, 0.2, 0.125, 0.125, 0.075, 0.075, 0.05, 0.05, 0, 0, 0, 0, 0, 0, 0, 0]
];

export const MSL_PROMOTION_POSITION = [0, 1];
export const MSL_RELEGATION_POSITION = [
	[8, 9, 10, 11, 12, 13, 14, 15],
	[8, 9, 10, 11, 12, 13, 14, 15],
	[8, 9, 10, 11, 12, 13, 14, 15],
	[]
];

export enum MetaSoccerLeagueDivision {
	DIVISION_A = "Division A",
	DIVISION_B = "Division B",
	DIVISION_C = "Division C",
	DIVISION_D = "Division D"
}

export function getDivisionIndexByName(division: MetaSoccerLeagueDivision) {
	switch (division) {
		case MetaSoccerLeagueDivision.DIVISION_A:
			return 0;
		case MetaSoccerLeagueDivision.DIVISION_B:
			return 1;
		case MetaSoccerLeagueDivision.DIVISION_C:
			return 2;
		case MetaSoccerLeagueDivision.DIVISION_D:
			return 3;
	}
}
