import styled, { css } from "styled-components";

const WIDTH = 90;
const HEIGHT = 108;

export const CssWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		cursor: grabbing;
		display: flex;
		flex-direction: column;
		height: ${HEIGHT}px;
		justify-content: center;
		padding: 10px;
		position: absolute;
		width: ${WIDTH}px;
	`
);
