import { MSLLogo } from "@/modules/shared/components";
import { ArrowRightIcon, Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import { HomeButton } from "../../../../../../components/HomeButton";
import { CssArrowWrapper, CssContent, CssLogoWrapper } from "./MSLButton.styles";

interface MSLButtonProps {
	children?: ReactNode;
	onClick?: () => void;
}

export const MSLButton = memo<MSLButtonProps>(({ children, onClick }) => {
	return (
		<HomeButton height={98} onClick={onClick}>
			<CssContent>
				<Row gap={24} alignItems="center">
					<CssLogoWrapper>
						<MSLLogo width={64} />
					</CssLogoWrapper>
					<Col gap={8}>
						<Typography variant="h5" textAlign="left">
							MSL
						</Typography>
						{children}
					</Col>
				</Row>
				<CssArrowWrapper>
					<ArrowRightIcon size="md" />
				</CssArrowWrapper>
			</CssContent>
		</HomeButton>
	);
});
