export const MetaSoccerScouting = {
  "name": "MetaSoccerScouting",
  "address": "0xb2B7443f968c46ac296b58f417857CAf001fA123",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_scouts"
        },
        {
          "type": "address",
          "name": "_players"
        },
        {
          "type": "address",
          "name": "_entropyManager"
        },
        {
          "type": "string",
          "name": "_nftName"
        },
        {
          "type": "string",
          "name": "_nftSymbol"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Approval",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "approved",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ApprovalForAll",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "operator",
          "indexed": true
        },
        {
          "type": "bool",
          "name": "approved",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "EntropySetted",
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "index",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "value",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Paused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ScoutingCancelled",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutId",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutingId",
          "indexed": false
        },
        {
          "type": "tuple",
          "name": "scouting",
          "indexed": false,
          "components": [
            {
              "type": "bool",
              "name": "finished"
            },
            {
              "type": "bool",
              "name": "claimed"
            },
            {
              "type": "uint8",
              "name": "level"
            },
            {
              "type": "string",
              "name": "role"
            },
            {
              "type": "address",
              "name": "owner"
            },
            {
              "type": "uint256",
              "name": "timestamp"
            },
            {
              "type": "uint256",
              "name": "scoutId"
            },
            {
              "type": "uint256[]",
              "name": "playerIds"
            },
            {
              "type": "uint256",
              "name": "scoutingPeriod"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ScoutingClaimed",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutId",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutingId",
          "indexed": false
        },
        {
          "type": "tuple",
          "name": "scouting",
          "indexed": false,
          "components": [
            {
              "type": "bool",
              "name": "finished"
            },
            {
              "type": "bool",
              "name": "claimed"
            },
            {
              "type": "uint8",
              "name": "level"
            },
            {
              "type": "string",
              "name": "role"
            },
            {
              "type": "address",
              "name": "owner"
            },
            {
              "type": "uint256",
              "name": "timestamp"
            },
            {
              "type": "uint256",
              "name": "scoutId"
            },
            {
              "type": "uint256[]",
              "name": "playerIds"
            },
            {
              "type": "uint256",
              "name": "scoutingPeriod"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ScoutingFinished",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutId",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutingId",
          "indexed": false
        },
        {
          "type": "tuple",
          "name": "scouting",
          "indexed": false,
          "components": [
            {
              "type": "bool",
              "name": "finished"
            },
            {
              "type": "bool",
              "name": "claimed"
            },
            {
              "type": "uint8",
              "name": "level"
            },
            {
              "type": "string",
              "name": "role"
            },
            {
              "type": "address",
              "name": "owner"
            },
            {
              "type": "uint256",
              "name": "timestamp"
            },
            {
              "type": "uint256",
              "name": "scoutId"
            },
            {
              "type": "uint256[]",
              "name": "playerIds"
            },
            {
              "type": "uint256",
              "name": "scoutingPeriod"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ScoutingStarted",
      "inputs": [
        {
          "type": "address",
          "name": "owner",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutId",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "scoutingId",
          "indexed": false
        },
        {
          "type": "tuple",
          "name": "scouting",
          "indexed": false,
          "components": [
            {
              "type": "bool",
              "name": "finished"
            },
            {
              "type": "bool",
              "name": "claimed"
            },
            {
              "type": "uint8",
              "name": "level"
            },
            {
              "type": "string",
              "name": "role"
            },
            {
              "type": "address",
              "name": "owner"
            },
            {
              "type": "uint256",
              "name": "timestamp"
            },
            {
              "type": "uint256",
              "name": "scoutId"
            },
            {
              "type": "uint256[]",
              "name": "playerIds"
            },
            {
              "type": "uint256",
              "name": "scoutingPeriod"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Transfer",
      "inputs": [
        {
          "type": "address",
          "name": "from",
          "indexed": true
        },
        {
          "type": "address",
          "name": "to",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "tokenId",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Unpaused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "VerifySignature",
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutId",
          "indexed": false
        },
        {
          "type": "uint8",
          "name": "_level",
          "indexed": false
        },
        {
          "type": "string",
          "name": "_role",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "_scoutingPeriod",
          "indexed": false
        },
        {
          "type": "uint256",
          "name": "_expirationTimestamp",
          "indexed": false
        },
        {
          "type": "bytes",
          "name": "_signature",
          "indexed": false
        },
        {
          "type": "bytes32",
          "name": "digest",
          "indexed": false
        },
        {
          "type": "address",
          "name": "signer",
          "indexed": false
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "SET_ENTROPY_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "SIGN_ATTRIBUTES_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "approve",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "balanceOf",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "beneficiary",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "entropyManager",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "entropyStorage",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "bool",
          "name": "finished"
        },
        {
          "type": "uint256",
          "name": "value"
        }
      ]
    },
    {
      "type": "function",
      "name": "finishScouting",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "forceWithdraw",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "getApproved",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getEntropy",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        },
        {
          "type": "uint256",
          "name": "_index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getLastScoutingIdByScoutId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutId"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getOwnedTokenIds",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_owner"
        }
      ],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingIdsByAddress",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_owner"
        }
      ],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingIdsByScoutId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutId"
        }
      ],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingPlayerIds",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutingId"
        }
      ],
      "outputs": [
        {
          "type": "uint256[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingSeed",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutingId"
        },
        {
          "type": "uint256",
          "name": "_index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingsByOwnerLength",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_owner"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingsByScoutIdLength",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutId"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getScoutingsLength",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasEntropy",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        },
        {
          "type": "uint256",
          "name": "_index"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isApprovedForAll",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "address",
          "name": "operator"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isScoutingForAddress",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_address"
        },
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isScoutingTime",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "mintPlayers",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutingId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "name",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "onERC721Received",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "operator"
        },
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        },
        {
          "type": "bytes"
        }
      ],
      "outputs": [
        {
          "type": "bytes4"
        }
      ]
    },
    {
      "type": "function",
      "name": "ownerOf",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "paused",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "paymentTokens",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "players",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "playersPerLevel",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint8"
        }
      ],
      "outputs": [
        {
          "type": "uint8"
        }
      ]
    },
    {
      "type": "function",
      "name": "priceByLevel",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "uint8"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "requiredPlayerSeeds",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint8"
        }
      ]
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeTransferFrom",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "from"
        },
        {
          "type": "address",
          "name": "to"
        },
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "safeTransferFrom",
      "constant": true,
      "stateMutability": "pure",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "address"
        },
        {
          "type": "uint256"
        },
        {
          "type": "bytes"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "scoutings",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "bool",
          "name": "finished"
        },
        {
          "type": "bool",
          "name": "claimed"
        },
        {
          "type": "uint8",
          "name": "level"
        },
        {
          "type": "string",
          "name": "role"
        },
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "uint256",
          "name": "timestamp"
        },
        {
          "type": "uint256",
          "name": "scoutId"
        },
        {
          "type": "uint256",
          "name": "scoutingPeriod"
        }
      ]
    },
    {
      "type": "function",
      "name": "scoutingsByOwner",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "scoutingsByScout",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "scouts",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "sendToScouting",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_scoutId"
        },
        {
          "type": "uint8",
          "name": "_level"
        },
        {
          "type": "string",
          "name": "_role"
        },
        {
          "type": "uint256",
          "name": "_scoutingPeriod"
        },
        {
          "type": "uint256",
          "name": "_expirationTimestamp"
        },
        {
          "type": "bytes",
          "name": "_signature"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setApprovalForAll",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "operator"
        },
        {
          "type": "bool",
          "name": "approved"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setBeneficiary",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_beneficiary"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setEntropy",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "_tokenId"
        },
        {
          "type": "uint256",
          "name": "_index"
        },
        {
          "type": "uint256",
          "name": "_randomness"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setEntropyManager",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_entropyManager"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPause",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bool",
          "name": "pause"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPaymentTokens",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address[]",
          "name": "_tokens"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPlayersPerLevel",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint8",
          "name": "_level"
        },
        {
          "type": "uint8",
          "name": "_nPlayers"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPrice",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint8",
          "name": "_level"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "_interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "symbol",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenByIndex",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenOfOwnerByIndex",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "tokenURI",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "tokenId"
        }
      ],
      "outputs": [
        {
          "type": "string"
        }
      ]
    },
    {
      "type": "function",
      "name": "totalSupply",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "transferFrom",
      "constant": true,
      "stateMutability": "pure",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "address"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawERC20",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_address"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    }
  ]
}