import styled, { css } from "styled-components";

export const CssContent = styled.div(
	({ theme }) => css`
		flex: 1 1 0%;
		overflow-y: auto;
		padding: 16px 12px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		height: 100%;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
		}
	`
);

export const CssTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
