import { ThemePalette } from "@metasoccer/metasoccer-ds";
import {
	MSL_MSU_REWARDS_DISTRIBUTION,
	MSL_PROMOTION_POSITION,
	MSL_RELEGATION_POSITION
} from "@metasoccer/metasoccer-types";

export default function getMSLRankBorderColors(palette: ThemePalette) {
	return MSL_MSU_REWARDS_DISTRIBUTION[0].map((_, index) =>
		MSL_PROMOTION_POSITION.includes(index)
			? palette.system.pastelGreen
			: MSL_RELEGATION_POSITION[0].includes(index)
			? palette.system.amaranth
			: palette.greyscale.gondola
	);
}
