import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.greyscale.gondola};
		height: 100vh;
		position: relative;

		${theme.breakpoints.up("desktopMin")} {
			border-bottom: none;
			height: 100%;
		}
	`
);

export const ModelViewerWrapperStyled = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		height: 100%;
		justity-content: center;
		left: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 0;
		width: 100%;
	`
);
