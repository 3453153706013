import { Bar } from "@metasoccer/metasoccer-ds";
import { PvpTeamInfo } from "@metasoccer/metasoccer-types";
import { FC } from "react";
import { CssNextMatchStatsPreviewWrapper, CssStatsWrapper } from "./NextMatchStatsPreview.styled";

interface NextMatchStatsPreviewProps {
	home: PvpTeamInfo;
	away: PvpTeamInfo;
}

export const NextMatchStatsPreview: FC<NextMatchStatsPreviewProps> = ({ home, away }) => {
	return (
		<CssNextMatchStatsPreviewWrapper>
			<CssStatsWrapper>
				<Bar
					progress={home.avgPossession}
					type={"double"}
					label={"AVG. POSSESSION (%)"}
					maxValue={home.avgPossession + away.avgPossession}
					hexColorA={home.clubLogo.color}
					hexColorB={away.clubLogo.color}
				/>
				<Bar
					progress={home.winRate * 100}
					type={"double"}
					label={"AVG. WINRATE (%)"}
					maxValue={home.winRate * 100 + away.winRate * 100}
					hexColorA={home.clubLogo.color}
					hexColorB={away.clubLogo.color}
				/>
				<Bar
					progress={home.avgGoals}
					type={"double"}
					label={"AVG. GOALS PER MATCH"}
					maxValue={home.avgGoals + away.avgGoals}
					hexColorA={home.clubLogo.color}
					hexColorB={away.clubLogo.color}
				/>
				<Bar
					progress={home.avgShotsOnTarget}
					type={"double"}
					label={"AVG. SHOTS ON TARGET PER MATCH"}
					maxValue={home.avgShotsOnTarget + away.avgShotsOnTarget}
					hexColorA={home.clubLogo.color}
					hexColorB={away.clubLogo.color}
				/>
				<Bar
					progress={home.avgGoalsAgainst}
					type={"double"}
					label={"AVG. GOALS CONCEDED PER MATCH"}
					maxValue={home.avgGoalsAgainst + away.avgGoalsAgainst}
					hexColorA={home.clubLogo.color}
					hexColorB={away.clubLogo.color}
				/>
			</CssStatsWrapper>
		</CssNextMatchStatsPreviewWrapper>
	);
};
