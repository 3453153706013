export enum SpecialPlayerSkill {
	Captain = "Captain",
	ClutchTimePlayer = "Clutch time player",
	CoachOnThePitch = "Coach on the pitch",
	CornerSpecialist = "Corner specialist",
	Dribbler = "Dribbler",
	FoulKicker = "Foul kicker",
	Insuperable = "Insuperable",
	Kaiser = "Kaiser",
	KickerGK = "Kicker GK",
	Killer = "Killer",
	Leader = "Leader",
	PenaltyKicker = "Penalty kicker",
	PenaltyStopper = "Penalty stopper",
	SpeakerGK = "Speaker GK",
	SuperSubstitute = "Super substitute",
	TeamPlayer = "Team player",
	TheMagician = "The magician",
	Warrior = "Warrior"
}
