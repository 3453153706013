import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;
import styled, { css } from "styled-components";
export var CssDialogWrapper = styled.div(function (_ref) {
  var fullScreen = _ref.fullScreen,
    zIndex = _ref.zIndex,
    theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tposition: fixed;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\tbottom: 0;\n\t\tright: 0;\n\t\tz-index: ", ";\n\n\t\twidth: 100%;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\n\t\t", "\n\t"])), zIndex, !fullScreen && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\t\tbackground-color: ", ";\n\t\t"])), theme.palette.tint.tundora90));
});
var getWidthBySize = function getWidthBySize(size) {
  switch (size) {
    case "sm":
      return "420px";
    case "md":
      return "520px";
    case "lg":
      return "640px";
    case "xl":
      return "800px";
    case "xxl":
      return "1280px";
  }
};
export var CssContentWrapper = styled.div(function (_ref2) {
  var fullScreen = _ref2.fullScreen,
    maxScreenWidth = _ref2.maxScreenWidth,
    size = _ref2.size,
    backgroundImage = _ref2.backgroundImage,
    theme = _ref2.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\tposition: relative;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\n\t\t/* Prevent content growing affect top and side bars behavior. Each page should handle their own scroll */\n\t\toverflow: hidden;\n\t\tbackground: ", ";\n\t\tborder-radius: 8px;\n\n\t\t", "\n\n\t\t", "\n\n\t\t", "\n\n        ", "\n\t"])), theme.palette.backgrounds.cinder, backgroundImage && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\t\tbackground: url(", ");\n\t\t\tbackground-repeat: no-repeat;\n\t\t\tbackground-size: cover;\n\t\t"])), backgroundImage), !fullScreen && css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\t\t\twidth: ", ";\n\t\t\theight: fit-content;\n\t\t\tmax-height: 768px;\n\t\t"])), getWidthBySize(size)), fullScreen && css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t"]))), maxScreenWidth && css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\t\t\tmax-width: ", "px;\n\t\t\tborder-left: 1px solid ", ";\n\t\t\tborder-right: 1px solid ", ";\n\t\t"])), theme.breakpoints.values.desktopMax, theme.palette.greyscale.gondola, theme.palette.greyscale.gondola));
});
export var CssHeaderWrapper = styled.div(function (_ref3) {
  var theme = _ref3.theme;
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\theight: 64px;\n\t\tpadding: 24px;\n\t\tborder-bottom: 1px solid ", ";\n\t"])), theme.palette.greyscale.gondola);
});
export var CssContent = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n\tflex: 1 1 0%;\n\toverflow: auto;\n"])));
export var CssHeaderCrossClose = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n\tpadding-left: 24px;\n"])));
export var CssHeaderArrowClose = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n\tpadding-right: 24px;\n"])));
export var CssHeaderContent = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n\tflex: 1 1 0%;\n\toverflow: hidden;\n\twhite-space: nowrap;\n"])));
export var CssScrollingContainer = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n\t/* These two things are needed to make the page box scrollable */\n\tflex: 1 1 0%;\n\toverflow-y: auto;\n\n\tdisplay: flex;\n\tflex-direction: column;\n"])));
export var CssModalFooter = styled.div(function (_ref4) {
  var fullScreen = _ref4.fullScreen,
    theme = _ref4.theme;
  return css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n\t\tdisplay: flex;\n\t\twidth: 100%;\n\t\talign-items: center;\n\t\tgap: 16px;\n\t\tpadding: 20px 24px;\n\t\tborder-top: 1px solid ", ";\n\n\t\t", "\n\t"])), theme.palette.greyscale.gondola, fullScreen && css(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n\t\t\tjustify-content: flex-end;\n\t\t"]))));
});