import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled, { css } from "styled-components";
import { Chip, squaredChipStyles } from "../Chip";
export var ResultChipRoot = styled(Chip)(function (_ref) {
  var theme = _ref.theme,
    result = _ref.result;
  var backgroundColor, color;
  if (result === "won") {
    backgroundColor = theme.palette.tint.pastelGreen30;
    color = theme.palette.system.pastelGreen;
  } else if (result === "lost") {
    backgroundColor = theme.palette.tint.amaranth30;
    color = theme.palette.system.amaranth;
  } else {
    backgroundColor = theme.palette.tint.laserLemon30;
    color = theme.palette.system.laserLemon;
  }
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        && {\n            background-color: ", ";\n            color: ", ";\n\n            ", ";\n        }\n    "])), backgroundColor, color, squaredChipStyles);
});