import { useMSLState } from "@/modules/msl";
import getMSLDecorators from "@/modules/msl/utils/getMSLDecorators";
import getMSLRewards from "@/modules/msl/utils/getMSLRewards";
import { MsuToken } from "@/modules/shared/components/tokens";
import { useGlobalState } from "@/modules/shared/context";
import { getNumberOrdinal } from "@/modules/shared/helpers";
import { Col, Row, ThemeColor, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { MetaSoccerLeagueDivision, getDivisionIndexByName } from "@metasoccer/metasoccer-types";
import { memo, useMemo } from "react";
import { WrapperStyled } from "./EditionFinished.styles";

export const EditionFinished = memo(() => {
	const { palette } = useTheme();

	const { selectedTeam } = useGlobalState();
	const { group } = useMSLState();
	if (!group) throw new Error("No group");

	const divisionIndex = getDivisionIndexByName(group.division as MetaSoccerLeagueDivision);
	const position = group.standings.rankedTeams.find((team) => team.id === selectedTeam?.id)?.position ?? 0;

	const decorator = getMSLDecorators(divisionIndex)[position - 1]; // TODO -- change fixed divisionIndex
	const msuPrice = getMSLRewards(divisionIndex).find((reward) => reward.position.includes(position))?.msu; // TODO -- change fixed divisionIndex

	const positionColor = useMemo(() => {
		switch (position) {
			case 1:
				return palette.system.laserLemon as ThemeColor;
			case 2:
			case 3:
			case 4:
				return palette.system.pastelGreen as ThemeColor;
			case 13:
			case 14:
			case 15:
			case 16:
				return palette.system.amaranth as ThemeColor;
			default:
				return palette.text as ThemeColor;
		}
	}, [position]);

	return (
		<WrapperStyled>
			<Typography variant="h5">MSL edition finished</Typography>
			{position >= 1 && (
				<Col gap={12}>
					<Col gap={2}>
						<Typography variant="body2" light>
							Your position
						</Typography>
						<Typography variant="h5" color={positionColor}>
							{getNumberOrdinal(position)} Position
						</Typography>
					</Col>
					<Typography variant="description" light>
						Thanks for participating! Next edition will start soon
					</Typography>
				</Col>
			)}
			{(decorator || msuPrice) && (
				<Row gap={24} alignItems="center" justifyContent="center">
					{decorator}
					{msuPrice && (
						<Row gap={12} alignItems="center">
							<Typography variant="body2">Prize</Typography>
							<MsuToken quantity={msuPrice} />
						</Row>
					)}
				</Row>
			)}
		</WrapperStyled>
	);
});
