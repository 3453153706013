import { tournamentService } from "@/dependencies";
import { BaseServerStateKeys } from "@/modules/shared/constants";
import { IdType, MetaSoccerId } from "@metasoccer/metasoccer-types";

export const TOURNAMENT_QUERIES = {
	editionOfInterest: (teamId?: MetaSoccerId<IdType.Team>) => ({
		queryKey: [
			BaseServerStateKeys.TOURNAMENTS,
			BaseServerStateKeys.TOURNAMENT_EDITIONS,
			teamId ?? "no_team",
			"of-interest"
		],
		queryFn: () => tournamentService.findTournamentEditionOfInterest(teamId)
	})
};
