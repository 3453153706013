import styled, { css } from "styled-components";

export const CssTable = styled.table`
	border-collapse: collapse;
	width: 100%;
`;

export const CssTHead = styled("thead")(
	({ theme }) => css`
		background-color: ${theme.palette.backgrounds.codGray};
	`
);

export const CssTHeadTr = styled.tr`
	height: 40px;
`;

interface CssTHeadThProps {
	width: number;
}

export const CssTHeadTh = styled.th<CssTHeadThProps>(
	({ theme, width }) => css`
		width: ${width};

		border-bottom: 0.5px solid ${theme.palette.greyscale.gondola};
		border-left: 0.5px solid ${theme.palette.greyscale.gondola};
		border-left: 0.5px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssTBody = styled("tbody")(
	({ theme }) => css`
		background-color: ${theme.palette.backgrounds.cinder};
	`
);

export const CssTBodyTd = styled.td(
	({ theme }) => css`
		padding-left: 8px;
		border-right: 0.5px solid ${theme.palette.greyscale.gondola};
		border-left: 0.5px solid ${theme.palette.greyscale.gondola};
		border-bottom: 0.5px solid ${theme.palette.greyscale.gondola};
	`
);

export const CssTBodyTr = styled.tr(
	({ theme }) => css`
		height: 40px;
		cursor: pointer;

		&:hover {
			background-color: ${theme.palette.greyscale.gondola};
		}

		& td:first-child {
			padding-left: 0px;
		}
	`
);
