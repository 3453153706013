import { Col, Typography } from "@metasoccer/metasoccer-ds";
import styled from "styled-components";

export const WrapperStyled = styled.div<{ backgroundColor: string }>`
	background: ${({ backgroundColor }) => backgroundColor};
	cursor: pointer;
	height: 320px;
	position: relative;
`;

export const InnerBackgroundStyled = styled.div<{ backgroundUrl: string }>`
	background: url(${({ backgroundUrl }) => backgroundUrl});
	opacity: 0.1;
	position: absolute;
	inset: 0;
`;

export const InnerWrapperStyled = styled.div`
	position: absolute;
	inset: 0;
`;

export const IconWrapper = styled(Col)`
	align-items: center;
	height: 100%;
	padding: 24px;
	justify-content: center;

	& img {
		max-width: 100%;
	}
`;

export const LabelWrapper = styled(Col)`
	background: ${({ theme }) => theme.palette.greyscale.gondola};
	align-items: center;
	justify-content: center;
	padding: 12px 24px;
	text-transform: uppercase;
`;

export const QuantityTypography = styled(Typography)`
	font-size: 36px;
`;
