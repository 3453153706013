import { ThemePalette } from "@metasoccer/metasoccer-ds";
import {
	MSL_MSU_REWARDS_DISTRIBUTION,
	MSL_PROMOTION_POSITION,
	MSL_RELEGATION_POSITION
} from "@metasoccer/metasoccer-types";

export default function getMSLRankBorderColorsWithDivision(divisionIndex: number, palette: ThemePalette): string[] {
	return MSL_MSU_REWARDS_DISTRIBUTION[divisionIndex].map((_, positionIndex) => {
		const isPromotionPosition = MSL_PROMOTION_POSITION.includes(positionIndex);
		const relegationPositions = MSL_RELEGATION_POSITION;
		const isRelegationPosition =
			Array.isArray(relegationPositions) && relegationPositions[divisionIndex].includes(positionIndex);

		let color;
		if (isPromotionPosition) {
			color = palette.system.pastelGreen;
		} else if (isRelegationPosition && divisionIndex < 3) {
			color = palette.system.amaranth;
		} else {
			color = palette.greyscale.gondola;
		}

		return color;
	});
}
