const realLifeDaysInAYear: number = 365.25; // Considering leap years
const realLifeDaysInAMonth: number = realLifeDaysInAYear / 12;
const millisecondsPerRealLifeDay: number = 24 * 60 * 60 * 1000; // Milliseconds in a real-life day

// Acceleration factor: How many in-game days pass in one real-life day
const accelerationFactor: number = realLifeDaysInAYear / realLifeDaysInAMonth;

// Calculate how many milliseconds represent one in-game day in MetaSoccer
const millisecondsPerInGameDay: number = millisecondsPerRealLifeDay / accelerationFactor;

export const METASOCCER_DAY_MS = millisecondsPerInGameDay;
export const METASOCCER_WEEK_MS = millisecondsPerInGameDay * 7;
