import { DefaultTheme } from "styled-components";

// Note: drillType's string should be the value of the DrillType enum
// The enum is in the backend package so we cannot import it here
export const getDrillByType = (drillType: string) => {
	switch (drillType) {
		case "DEFENSE":
			return "defense";
		case "ATTACK":
			return "attack";
		case "PHYSICAL_AND_MENTAL":
			return "mental-physical";
		default:
			return "mental-physical";
	}
};

export const getDrillQualityText = (quality: string): string => {
	switch (quality) {
		case "NORMAL":
			return "";
		case "HARD":
			return "+50% Results";
		case "SPECIAL":
			return "Focused improvement";
		default:
			return "";
	}
};

export const getDrillQualityColor = (quality: string, theme: DefaultTheme): string => {
	switch (quality) {
		case "NORMAL":
			return "#";
		case "HARD":
			return theme.palette.system.pastelGreen;
		case "SPECIAL":
			return theme.palette.system.viking;
		default:
			return "";
	}
};

export const getDrillQualityBackgroundColor = (quality: string): string => {
	switch (quality) {
		case "NORMAL":
			return "#";
		case "HARD":
			return "linear-gradient(0deg, rgba(106, 213, 106, 0.3), rgba(106, 213, 106, 0.3)), #06060A;";
		case "SPECIAL":
			return "linear-gradient(0deg, rgba(77, 210, 202, 0.3), rgba(77, 210, 202, 0.3)), #06060A;";
		default:
			return "";
	}
};
