import { Row, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { PlayerTableItem } from "../PlayerTable";

export const QualityCell = memo<{ player: PlayerTableItem }>(({ player }) => {
	return (
		<Row>
			<Typography variant="body2">{player.overall}</Typography>
			<Typography variant="body2" light>
				/{player.potential}
			</Typography>
		</Row>
	);
});
