export class BackendResultError extends Error {
	readonly errorType?: string;
	readonly data?: any;

	constructor(error: any) {
		super(JSON.stringify(error));
		this.name = "BackendResultError";
		this.message = typeof error === "string" ? error : error?.message;
		this.errorType = error?.errorType;
		this.data = error?.data;
	}
}
