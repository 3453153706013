import React, { FC, useMemo, useState } from "react";
import { Button, Typography, Modal } from "@metasoccer/metasoccer-ds";
import { CssButtonWrapper, CssContent, CssContentRow } from "./FiltersSheet.styled";
import {
	TournamentStructureDivision,
	TournamentStructureDivisionGroup,
	TournamentStructureEdition
} from "@metasoccer/metasoccer-types";
import { StandingsFilters } from "../../../../../../Standings.state";
import { DivisionSheet, EditionSheet, GroupSheet } from "./components";

interface FiltersSheetProps {
	isOpen: boolean;
	tournamentStructureEditions: TournamentStructureEdition[];
	filters: StandingsFilters;
	onFilterChange: (filter: StandingsFilters) => void;
	onClose: () => void;
}

export const FiltersSheet: FC<FiltersSheetProps> = ({
	isOpen,
	tournamentStructureEditions,
	filters,
	onFilterChange,
	onClose
}) => {
	const [filter, setFilter] = useState(filters);
	const [isEditionOpen, setIsEditionOpen] = useState(false);
	const [isDivisionOpen, setIsDivisionOpen] = useState(false);
	const [isGroupOpen, setIsGroupOpen] = useState(false);

	const toggleEditionSheet = () => setIsEditionOpen((prevState) => !prevState);
	const toggleDivisionSheet = () => setIsDivisionOpen((prevState) => !prevState);
	const toggleGroupSheet = () => setIsGroupOpen((prevState) => !prevState);

	const divisions = useMemo(
		() => tournamentStructureEditions.find((edition) => edition.id === filters.editionId)?.divisions!,
		[tournamentStructureEditions, filters.editionId]
	);
	const groups = useMemo(
		() => divisions.find((division) => division.categoryId === filters.divisionId)?.groups!,
		[divisions, filters.divisionId]
	);

	const onEditionSelect = (edition: TournamentStructureEdition) => () => {
		setFilter({ ...filter, editionId: edition.id, editionName: edition.name });
		toggleEditionSheet();
	};

	const onDivisionSelect = (division: TournamentStructureDivision) => () => {
		setFilter({ ...filter, divisionId: division.categoryId, divisionName: division.name });
		toggleDivisionSheet();
	};

	const onGroupSelect = (group: TournamentStructureDivisionGroup) => () => {
		setFilter({ ...filter, groupId: group.id, groupName: group.name.split(" ")[1] });
		toggleGroupSheet();
	};

	const areFiltersEquals = (): boolean => JSON.stringify(filters) === JSON.stringify(filter);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} title="Filters">
				<CssContent>
					<CssContentRow onClick={toggleEditionSheet}>
						<Typography variant="body1" light>
							Edition
						</Typography>
						<Typography variant="buttonLg">{filter.editionName}</Typography>
					</CssContentRow>
					<CssContentRow onClick={toggleDivisionSheet}>
						<Typography variant="body1" light>
							Division
						</Typography>
						<Typography variant="buttonLg">{filter.divisionName}</Typography>
					</CssContentRow>
					<CssContentRow onClick={toggleGroupSheet}>
						<Typography variant="body1" light>
							Group
						</Typography>
						<Typography variant="buttonLg">{filter.groupName}</Typography>
					</CssContentRow>
					<CssButtonWrapper>
						<Button
							label="Apply"
							disabled={areFiltersEquals()}
							fullWidth
							onClick={() => {
								onFilterChange(filter);
								onClose();
							}}
						/>
					</CssButtonWrapper>
				</CssContent>
			</Modal>
			{isEditionOpen && (
				<EditionSheet
					isOpen={isEditionOpen}
					onClose={toggleEditionSheet}
					editions={tournamentStructureEditions}
					onEditionSelect={onEditionSelect}
				/>
			)}
			{isDivisionOpen && (
				<DivisionSheet
					isOpen={isDivisionOpen}
					onClose={toggleDivisionSheet}
					divisions={divisions}
					onDivisionSelect={onDivisionSelect}
				/>
			)}
			{isGroupOpen && (
				<GroupSheet
					isOpen={isGroupOpen}
					onClose={toggleGroupSheet}
					groups={groups}
					onGroupSelect={onGroupSelect}
				/>
			)}
		</>
	);
};
