import { useAccountGuard } from "@/modules/auth";
import { JoinModal, useMSLState } from "@/modules/msl";
import { Alert } from "@/modules/shared/components";
import { Button, Typography } from "@metasoccer/metasoccer-ds";
import { format } from "date-fns";
import { useState } from "react";

interface JoinMSLAlertProps {
	onSuccess: () => void;
}

export const JoinMSLAlert = ({ onSuccess }: JoinMSLAlertProps) => {
	const { executeIfTeam } = useAccountGuard();

	const { groupOfTeam, groupOfTopMSL, joined } = useMSLState();

	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			{!groupOfTeam && groupOfTopMSL && joined && (
				<Alert variant="success">
					<Typography variant="body2">
						You've joined the MSL! Next edition starts
						{groupOfTopMSL.tournamentEdition.nextEditionStartDate
							? " on " + format(groupOfTopMSL.tournamentEdition.nextEditionStartDate, "Pp")
							: " soon"}
					</Typography>
				</Alert>
			)}
			{!groupOfTeam && !joined && (
				<Alert variant="info">
					<Typography variant="body2">Join the MSL and compete for amazing rewards!</Typography>
					<Button variant="filled" label="Join" onClick={() => executeIfTeam(() => setIsModalOpen(true))} />
				</Alert>
			)}
			{isModalOpen && (
				<JoinModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSuccess={onSuccess} />
			)}
		</>
	);
};
