import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
import styled, { css } from "styled-components";
export var CssBaseSheetWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n\theight: 100%;\n\tbackground-color: ", ";\n\tmax-width: ", "px;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.palette.tint.tundora90;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.breakpoints.values.mobileMax;
});
export var CssBaseSheetOutterContainer = styled.div(function (_ref3) {
  var zIndex = _ref3.zIndex,
    fullScreen = _ref3.fullScreen;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tposition: relative;\n\t\tdisplay: ", ";\n\t\tz-index: ", ";\n\t\twidth: 100%;\n\t\theight: 100%;\n\t"])), fullScreen ? "none" : "block", zIndex);
});
export var CssBaseSheetInnerContainer = styled.div(function (_ref4) {
  var zIndex = _ref4.zIndex,
    fullScreen = _ref4.fullScreen,
    theme = _ref4.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\tposition: relative;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tz-index: ", ";\n\t\tbackground-color: ", ";\n\t\twidth: 100%;\n\t\tmax-width: ", "px;\n\t\tmax-height: 100%;\n\t\tborder-radius: 8px 8px 0px 0px;\n\t\theight: ", ";\n\t"])), zIndex, theme.palette.backgrounds.cinder, theme.breakpoints.values.mobileMax, fullScreen ? "100%" : "unset");
});
export var CssHeader = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\theight: 60px;\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 12px;\n\tborder-bottom: 1px solid ", ";\n"])), function (_ref5) {
  var theme = _ref5.theme;
  return theme.palette.greyscale.gondola;
});
export var CssHeaderCrossClose = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\tpadding-left: 12px;\n"])));
export var CssHeaderArrowClose = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\tpadding-right: 12px;\n"])));
export var CssContent = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\tflex: 1 1 0%;\n\toverflow-y: scroll;\n\tposition: relative;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n"])));
export var CssHeaderTitle = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\tflex: 1 1 0%;\n\toverflow: hidden;\n\twhite-space: nowrap;\n"])));
export var CssFooter = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\tgap: 12px;\n\tborder-top: 1px solid ", ";\n\tpadding: 20px 12px;\n"])), function (_ref6) {
  var theme = _ref6.theme;
  return theme.palette.greyscale.gondola;
});