import { useGlobalState } from "@/modules/shared/context";
import { LineupWithSkill, Player } from "@metasoccer/metasoccer-types";
import { FlatLineup } from "@metasoccer/metasoccer-types/dist/@types/lineup/FlatLineup";
import { useCallback } from "react";
import { getPlayerBySlot } from "../helpers";

export const useUpdateLineupPlayer = (
	lineupWithSkill: LineupWithSkill,
	updateLineup: (data: { lineup: FlatLineup; teamId: string }) => void
) => {
	const { selectedTeam } = useGlobalState();

	const updateBenchPlayer = useCallback(
		(benchedPlayer: Player, slotIndex: number, removeIfSourceEqualsTarget: boolean = true) => {
			// Is there an existing player in the bench slot we want to update?
			const existingPlayerInBenchSlot = lineupWithSkill.lineup.playersInBench[slotIndex];

			// Is the player we want to bench in the pitch?
			const isBenchedPlayerOnThePitch = lineupWithSkill.lineup.linedPlayers.find(
				({ player }: { player: Player }) => player.id === benchedPlayer.id
			);
			// Is the player we want to bench already in the bench?
			const isBenchedPlayerInTheBenchIndex = lineupWithSkill.lineup.playersInBench.findIndex(
				(player: Player) => player.id === benchedPlayer.id
			);

			const flatUpdatedLineup: FlatLineup = {
				owner: selectedTeam?.owner!,
				teamId: selectedTeam?.id,
				formationId: lineupWithSkill.lineup.formation.id,
				linedPlayers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					.map((index) =>
						isBenchedPlayerOnThePitch?.slotId === index
							? { playerId: existingPlayerInBenchSlot?.id, slotId: index }
							: { playerId: getPlayerBySlot(lineupWithSkill, index)?.id, slotId: index }
					)
					.filter((linedPlayer) => linedPlayer?.playerId !== undefined),
				playersInBenchIds: [0, 1, 2, 3]
					.map((index) =>
						index === slotIndex
							? !(existingPlayerInBenchSlot?.id === benchedPlayer.id && removeIfSourceEqualsTarget)
								? benchedPlayer.id
								: undefined
							: index === isBenchedPlayerInTheBenchIndex
							? existingPlayerInBenchSlot?.id
							: lineupWithSkill.lineup.playersInBench[index]?.id
					)
					.filter((i) => i !== undefined)
			};

			return updateLineup({ lineup: flatUpdatedLineup, teamId: selectedTeam?.id });
		},
		[selectedTeam, lineupWithSkill]
	);

	const updateLineupPlayer = useCallback(
		(linedPlayer: Player, slotIndex: number, removeIfSourceEqualsTarget: boolean = true) => {
			// Normalize lineupWithSkill so we can do index-based operations
			const normalizedLineup = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) =>
				getPlayerBySlot(lineupWithSkill, index)
			);

			// Is there an existing player in the slot we want to update?
			const existingPlayerInLineupSlot = getPlayerBySlot(lineupWithSkill, slotIndex);

			// Is the player we want to lineup in the bench?
			const linedPlayerInBenchIndex = lineupWithSkill.lineup.playersInBench.findIndex(
				({ id }: { id: number }) => id === linedPlayer.id
			);
			// Is the player we want to lineup already in the pitch?
			const linedPlayerOnThePitchIndex = normalizedLineup.findIndex((player) => player?.id === linedPlayer.id);

			const flatUpdatedLineup: FlatLineup = {
				owner: selectedTeam?.owner!,
				teamId: selectedTeam?.id,
				formationId: lineupWithSkill.lineup.formation.id,
				linedPlayers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					.map((index) =>
						index === slotIndex
							? !(existingPlayerInLineupSlot?.id === linedPlayer.id && removeIfSourceEqualsTarget)
								? { playerId: linedPlayer.id, slotId: slotIndex }
								: { playerId: undefined, slotId: index }
							: index === linedPlayerOnThePitchIndex
							? { playerId: existingPlayerInLineupSlot?.id, slotId: index }
							: { playerId: normalizedLineup[index]?.id, slotId: index }
					)
					.filter((linedPlayer) => linedPlayer?.playerId !== undefined),
				playersInBenchIds: [0, 1, 2, 3]
					.map((index) =>
						index == linedPlayerInBenchIndex
							? existingPlayerInLineupSlot?.id
							: lineupWithSkill.lineup.playersInBench[index]?.id
					)
					.filter((i) => i !== undefined)
			};

			return updateLineup({ lineup: flatUpdatedLineup, teamId: selectedTeam?.id });
		},
		[selectedTeam, lineupWithSkill]
	);

	return { update: updateLineupPlayer, updateBench: updateBenchPlayer };
};
