export enum ShootingMoment {
	OFFENSIVE_FREE_KICK = "OFFENSIVE_FREE_KICK",
	PENALTY = "PENALTY",
	CORNER_LEFT = "CORNER_LEFT",
	CORNER_RIGHT = "CORNER_RIGHT"
}

export const EMPTY_SHOOTER_INSTRUCTIONS = {
	[ShootingMoment.OFFENSIVE_FREE_KICK]: undefined,
	[ShootingMoment.PENALTY]: undefined,
	[ShootingMoment.CORNER_LEFT]: undefined,
	[ShootingMoment.CORNER_RIGHT]: undefined
};

export type ShooterInstructions = { [key in ShootingMoment]: string | undefined };

export interface TeamInstructions {
	shooters: ShooterInstructions;
}
