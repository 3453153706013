import { Account, HttpClient } from "@metasoccer/metasoccer-types";

export class AccountService {
	constructor(private readonly httpService: HttpClient) {}

	async get(): Promise<Account> {
		try {
			const { data } = await this.httpService.get("/account");
			return data;
		} catch (e) {
			throw e;
		}
	}

	async checkIn(address: string): Promise<boolean> {
		try {
			const { data } = await this.httpService.post("/account/hi", { address });
			return data;
		} catch (e) {
			throw e;
		}
	}
}
