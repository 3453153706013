import { useIsMobile } from "@/layouts/context";
import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { useGlobalState, useSelectedTeamState } from "@/modules/shared/context";
import { delay, getImgUrl } from "@/modules/shared/utils";
import { Button, Col, Typography } from "@metasoccer/metasoccer-ds";
import { useWallet } from "@thirdweb-dev/react";
import { memo, useCallback, useEffect, useState } from "react";
import { ModelViewerWrapperStyled, WrapperStyled } from "./AirdropInventory.styles";

import coinGif from "./coin.gif";

export const AirdropInventory = memo(() => {
	const { address } = useGlobalState();

	const wallet = useWallet();

	const { players, refetchPlayersData } = useSelectedTeamState();

	const [isRedeeming, setIsRedeeming] = useState(false);
	const [waitingForPlayers, setIsWaitingForPlayers] = useState(0);

	const { availableBoxes, hasAirdropStarted, hasPass, isLoading, claimStarterBox } = useAirdrop();

	const openBox = useCallback(async () => {
		if (isRedeeming) return;

		setIsRedeeming(true);
		await claimStarterBox();
		setIsWaitingForPlayers(1);
	}, [isRedeeming, claimStarterBox]);

	useEffect(() => {
		if (!waitingForPlayers) return;

		if (players.length) {
			setIsRedeeming(false);
			setIsWaitingForPlayers(0);
		} else {
			refetchPlayersData();
			delay(2000).then(() => {
				setIsWaitingForPlayers((prev) => prev + 1);
			});
		}
	}, [players, waitingForPlayers]);

	const isMobile = useIsMobile();

	if (hasAirdropStarted) {
		return (
			<WrapperStyled>
				<ModelViewerWrapperStyled>
					<Col style={{ marginTop: "-50%", padding: isMobile ? 12 : 24 }}>
						<img
							alt="Starter Box"
							src={getImgUrl("https://assets.metasoccer.com/airdrop/starter-box.png?v=2")}
							width="100%"
						/>
						<Col gap={8}>
							<Button
								appearance="secondary"
								disabled={!availableBoxes || !hasPass}
								label="Open Starter Box"
								loading={isLoading || isRedeeming}
								onClick={openBox}
							/>
							{!availableBoxes ? (
								<Typography variant="caption" light textAlign="center">
									There are no more Starter Boxes available for opening at the moment. Please check
									back in a few minutes to open yours!
								</Typography>
							) : (
								!hasPass && (
									<Typography variant="caption" light textAlign="center">
										Your Starter Box is almost ready! Please come back in a few minutes to open it
										and dive into the exciting world of MetaSoccer!
									</Typography>
								)
							)}
							{isRedeeming &&
								(wallet?.walletId === "embeddedWallet" ? (
									<Typography variant="caption" light textAlign="center">
										Opening your Starter Box… Please note it can take a few minutes to complete.
									</Typography>
								) : (
									<Typography variant="caption" light textAlign="center">
										Opening your Starter Box… Please sign the two transactions in your wallet. In a
										few minutes, your squad will be ready!
									</Typography>
								))}
						</Col>
					</Col>
				</ModelViewerWrapperStyled>
			</WrapperStyled>
		);
	}

	return (
		<WrapperStyled>
			{!hasPass ? (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						height: "100%"
					}}>
					<img src={coinGif} />
				</div>
			) : (
				<ModelViewerWrapperStyled>
					<img
						src={getImgUrl("https://assets.metasoccer.com/airdrop/starter-box.png?v=2")}
						width="100%"
						alt="Starter Box"
					/>
				</ModelViewerWrapperStyled>
			)}
		</WrapperStyled>
	);
});
