import { BaseServerStateKeys } from "@/modules/shared/constants";
import { inboxService } from "../dependencies";

export const INBOX_QUERIES = {
	getInbox: () => ({
		queryKey: [BaseServerStateKeys.INBOX, "getInbox"],
		queryFn: () => inboxService.getInbox()
	}),
	getMessage: (messageId: string) => ({
		queryKey: [BaseServerStateKeys.INBOX, "getInboxMessage", messageId],
		queryFn: () => inboxService.getMessage(messageId)
	}),
	getReadMessages: () => ({
		queryKey: [BaseServerStateKeys.INBOX, "getReadMessages"],
		queryFn: () => inboxService.getReadMessages()
	})
};
