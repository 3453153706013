import { ThemePalette } from "@metasoccer/metasoccer-ds";
import { TournamentDivisionReward } from "@metasoccer/metasoccer-types";

export const getPrizeColorByPosition = (
	position: number,
	isMyTeam: boolean,
	rewards: TournamentDivisionReward[],
	palette: ThemePalette
): string => {
	const emptyColor = isMyTeam ? palette.greyscale.gondola : palette.backgrounds.cinder;

	for (let idx = 0; idx < rewards.length; idx++) {
		const reward = rewards[idx];

		if (reward.position.includes(position)) {
			switch (idx) {
				case 0:
					return palette.system.laserLemon;
				case 1:
					return palette.greyscale.gray;
				case 2:
					return palette.system.neonCarrot;
				case 3:
					return palette.system.cornflowerBlue;
				case 4:
					return palette.system.perfume;
			}
		}
	}

	return emptyColor;
};
