import { useMSLState } from "@/modules/msl";
import { useGlobalState } from "@/modules/shared/context";
import { Checkbox, Typography } from "@metasoccer/metasoccer-ds";
import { memo, useLayoutEffect, useState } from "react";
import { CardStyled, RoundsWrapperStyled, WrapperStyled } from "./MatchesCard.styled";
import { RoundPreview } from "./components/RoundPreview";
import { getRoundsFromScheduledMatches } from "./helpers";

export const MatchesCard = memo(() => {
	const { selectedTeam } = useGlobalState();
	const { group, groupId } = useMSLState();
	if (!group) throw new Error("Group is undefined");
	const { matches, name } = group;

	const [showMyMatchesOnly, setShowMyMatchesOnly] = useState(false);

	const matchesFinal = showMyMatchesOnly
		? group.matches.filter((m) => m.teams.home.id === selectedTeam?.id || m.teams.away.id === selectedTeam?.id)
		: group.matches;

	const rounds = getRoundsFromScheduledMatches(matchesFinal);

	const handleCheckboxChange = () => {
		setShowMyMatchesOnly(!showMyMatchesOnly);
	};

	useLayoutEffect(() => {
		if (!rounds) return;

		try {
			setTimeout(() => {
				const earliestPendingRound = rounds
					.filter((round) => round.matches.some((match) => match.status == "Scheduled"))
					.sort((a, b) => b.date - a.date)
					.pop();

				if (earliestPendingRound) {
					const index = rounds.findIndex((round) => round === earliestPendingRound);
					const previousRound = rounds[index - 1];

					if (previousRound) {
						const roundElement = document.querySelector<HTMLElement>(`#round-${previousRound.date}`)!;
						document.querySelector("#rounds")?.scrollTo(0, roundElement.offsetTop - 93);
					}
				}
			}, 100);
		} catch (err) {
			console.warn(err);
		}
	}, [rounds, showMyMatchesOnly]);

	return (
		<CardStyled title="Matches" subtitle={name}>
			<WrapperStyled>
				<Checkbox
					size="md"
					label={<Typography variant="description">Show my matches only</Typography>}
					value={showMyMatchesOnly}
					onChange={handleCheckboxChange}
				/>
			</WrapperStyled>
			<RoundsWrapperStyled id="rounds">
				{rounds.map((r, i) => (
					<RoundPreview
						key={i}
						round={r}
						roundNumber={i + 1}
						selectedTeamId={selectedTeam?.id}
						tournamentType="League 2D"
						buildItemId={(roundDate) => `round-${roundDate}`}
					/>
				))}
			</RoundsWrapperStyled>
		</CardStyled>
	);
});
