import { Dropdown } from "@/modules/shared/components";
import { Expandable } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.greyscale.gondola};
	`
);

export const ExpandableStyled = styled(Expandable)(
	({ theme }) => css`
		padding: 8px 16px;

		.ExpandableDisplay {
			border: none;
		}
	`
);

export const DropdownStyled = styled(Dropdown)(
	({ theme }) => css`
		& > *:first-child {
			border: none;
			padding: 16px 16px 16px 24px;
		}

		& > *:last-child {
			padding: 0 4px;
		}
	`
);
