import { TextField } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const HeaderStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		padding: 24px;
	`
);

export const TextFieldStyled = styled(TextField)(
	({ theme }) => css`
		width: 42px;

		& .TextInput {
			min-width: 0;
			padding: 8px;
			width: 42px;
		}

		& input {
			text-align: center;
		}
	`
);
