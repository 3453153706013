import { FC } from "react";
import { CssChip } from "./FocusedImprovementChip.styled";

export interface FocusedImprovementChipProps {
	size?: "sm" | "md" | "lg";
}

export const FocusedImprovementChip: FC<FocusedImprovementChipProps> = ({ size = "lg" }) => {
	return <CssChip size={size} label="Focused Improvement" />;
};
