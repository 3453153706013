import { CLUB_ROUTES } from "@/modules/club/club.routes";
import { MSL_ROUTES } from "@/modules/msl/msl.routes";
import { SCOUT_ROUTES } from "@/modules/scout/scout.routes";
import { SQUAD_ROUTES } from "@/modules/squad/squad.routes";
import { TOURNAMENT_ROUTES } from "@/modules/tournament/tournament.routes";
import { memo, useEffect } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";

const Redirection = memo(
	({ replaceRegex, to, replace = false }: { replaceRegex?: RegExp; to: string; replace?: boolean }) => {
		const location = useLocation();
		const navigate = useNavigate();

		useEffect(() => {
			if (!replaceRegex) {
				navigate(to, { replace });
				return;
			}

			const newPath = location.pathname.replace(replaceRegex, to);
			navigate(newPath, { replace });
		}, [location, navigate]);

		return <></>;
	}
);

export const MigratedRoutes = [
	<Route
		key="assets/players"
		path="/assets/players"
		element={
			<Redirection replace replaceRegex={/^\/?assets\/players$/gi} to={CLUB_ROUTES.ROOT.buildPath("players")} />
		}
	/>,
	<Route
		key="assets/players"
		path="/assets/players/:id"
		element={
			<Redirection
				replace
				replaceRegex={/^\/?assets\/players\/(.*)$/gi}
				to={`${SCOUT_ROUTES.PROFILE.path.replace(":id", "")}/$1`}
			/>
		}
	/>,
	<Route
		key="assets/scouts"
		path="/assets/scouts"
		element={
			<Redirection replace replaceRegex={/^\/?assets\/scouts$/gi} to={CLUB_ROUTES.ROOT.buildPath("scouts")} />
		}
	/>,
	<Route
		key="assets/scouts"
		path="/assets/scouts/:id"
		element={
			<Redirection
				replace
				replaceRegex={/^\/?assets\/scouts\/(.*)$/gi}
				to={`${SCOUT_ROUTES.PROFILE.path.replace(":id", "")}/$1`}
			/>
		}
	/>,
	<Route
		key="home/msl"
		path="/home/msl/*"
		element={<Redirection replace replaceRegex={/^\/?home\/msl\/(.*)$/gi} to={`${MSL_ROUTES.ROOT.path}/$1`} />}
	/>,
	<Route
		key="home/tournaments"
		path="/home/tournaments"
		element={
			<Redirection replace replaceRegex={/^\/?home\/tournaments$/gi} to={`${TOURNAMENT_ROUTES.ROOT.path}`} />
		}
	/>,
	<Route
		key="squad"
		path="/squad/:tab"
		element={<Redirection replace replaceRegex={/^\/?squad\/(.*)$/gi} to={`${SQUAD_ROUTES.ROOT.path}?tab=$1`} />}
	/>
];
