import { teamService } from "@/dependencies";
import { BaseServerStateKeys } from "@/modules/shared/constants";

export const TEAM_QUERIES = {
	getManagedTeams: (userAddress: string) => ({
		queryKey: [BaseServerStateKeys.TEAM, "getManagedTeams", userAddress],
		queryFn: () => teamService.getManagedTeams(userAddress)
	}),
	getOwnedTeams: (userAddress: string) => ({
		queryKey: [BaseServerStateKeys.TEAM, "getOwnedTeams", userAddress],
		queryFn: () => teamService.getOwnedTeams(userAddress)
	}),
	getTeamsByNameLike: (nameLike: string) => ({
		queryKey: [BaseServerStateKeys.TEAM, "getTeamsByNameLike", nameLike],
		queryFn: () => teamService.findTeamsByNameLike(nameLike)
	}),
	getTeamSquadsByOwner: (owner: string) => ({
		queryKey: [BaseServerStateKeys.TEAM, "getTeamSquadsByOwner", owner],
		queryFn: () => teamService.getTeamSquadsByOwner(owner)
	}),
	getTeamStatsByOwner: (owner: string) => ({
		queryKey: [BaseServerStateKeys.TEAM, "getTeamStatsByOwner", owner],
		queryFn: () => teamService.getTeamStatsByOwner(owner)
	})
};
