import styled, { css } from "styled-components";
import { DropdownVariant } from "./Dropdown";

const VIEWPORT_MARGIN = 8;

export const CssDropdownWrapper = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
`;

export const CssDropdownContent = styled("div")<{ variant?: DropdownVariant }>(
	({ theme, variant = "menu" }) => css`
		display: flex;
		align-items: center;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		background-color: ${variant == "menu" ? "none" : theme.palette.greyscale.gondola};
		border: ${variant == "menu" ? "1px solid " + theme.palette.greyscale.gray : "none"};
		cursor: pointer;
		height: ${variant == "menu" ? "40px" : "100%"};
		justify-content: space-between;
		min-width: 140px;
		padding: 10px 16px;
		width: 100%;

		&:focus {
			outline: none;
		}
	`
);

interface CssOptionsWrapperProps {
	isOpen: boolean;
	fullWidth?: boolean;
	maxHeight?: number;
	maxWidth?: number;
	positionLeft: number;
	positionTop: number;
	variant?: DropdownVariant;
}

export const CssOptionsWrapper = styled("div")<CssOptionsWrapperProps>(
	({ variant = "menu", isOpen, fullWidth, maxHeight, maxWidth, positionLeft, positionTop, theme }) => css`
		display: ${isOpen ? "block" : "none"};
		background-color: ${variant == "menu" ? theme.palette.backgrounds.codGray : theme.palette.greyscale.gondola};
		left: ${positionLeft}px;
		max-height: ${maxHeight ? maxHeight - VIEWPORT_MARGIN + "px" : "none"};
		min-width: 140px;
		max-width: ${maxWidth ? maxWidth + "px" : "none"};
		overflow-y: auto;
		position: fixed;
		top: ${positionTop}px;
		width: ${variant == "menu" ? (fullWidth ? "100%" : "max-content") : "100%"};
		z-index: 5;
	`
);
