import { splitPayloadInChunks } from "@/modules/shared/helpers";
import { ConfigService } from "@/modules/shared/services";
import { Environment } from "@/modules/shared/types";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { AnalyticsService } from "./AnalyticsService";
import { DummyAnalyticsService } from "./DummyAnalyticsService";
import { FirebaseAnalyticsService } from "./FirebaseAnalyticsService";

export class AnalyticsServiceFactory {
	public static make(configService: ConfigService): AnalyticsService {
		const env = configService.get().env;
		if (env !== Environment.PROD) {
			return new DummyAnalyticsService(configService.get().dummyAnalyticsLogs);
		}

		const app = initializeApp({
			apiKey: configService.get().firebaseApiKey,
			authDomain: configService.get().firebaseAuthDomain,
			projectId: configService.get().firebaseProjectId,
			storageBucket: configService.get().firebaseStorageBucket,
			messagingSenderId: configService.get().firebaseMessagingSenderId,
			appId: configService.get().firebaseAppId,
			measurementId: configService.get().firebaseMeasurementId
		});
		const analytics = typeof window === "undefined" ? null : getAnalytics(app);

		return new FirebaseAnalyticsService(analytics, splitPayloadInChunks);
	}
}
