import { TrainingToken } from "@/modules/shared/components/tokens";
import { Button, Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { formatDate } from "date-fns";
import { FC } from "react";
import {
	CssCardActions,
	CssCardContent,
	CssCardContentTextWrapper,
	CssCardHeader,
	CssCardHeaderColoredText,
	CssCardHeaderTextWrapper,
	CssCardWrapper,
	CssSeparator
} from "./TrainingHistoryCard.styled";

export interface TrainingHistoryCardProps {
	variant?: "default" | "small";
	totalAttack: number;
	totalDefense: number;
	totalMentalAndPhysical: number;
	drillCount: number;
	playerCount: number;
	conditionCost: number;
	cost: number;
	timestamp: number;
	onLoadTrainingClicked?: () => void;
}

export const TrainingHistoryCard: FC<TrainingHistoryCardProps> = ({
	variant = "default",
	totalAttack,
	totalDefense,
	totalMentalAndPhysical,
	drillCount,
	playerCount,
	conditionCost,
	cost,
	timestamp,
	onLoadTrainingClicked = () => {}
}) => {
	const date = new Date(timestamp).toLocaleDateString();

	if (variant === "small") {
		return (
			<CssCardWrapper style={{ padding: "8px 24px" }}>
				<CssCardHeader>
					<Col gap={2}>
						<Row gap={8}>
							<CssCardContentTextWrapper>
								<Typography variant="body2">Date</Typography>
								<Typography variant="body2" light>
									{formatDate(timestamp, "Pp")}
								</Typography>
							</CssCardContentTextWrapper>
						</Row>
						<Row gap={8}>
							<CssCardContentTextWrapper>
								<Typography variant="body2">Drills</Typography>
								<Typography variant="body2" light>
									{drillCount}
								</Typography>
							</CssCardContentTextWrapper>
							<CssSeparator noGap />
							<CssCardContentTextWrapper>
								<Typography variant="body2">Condition</Typography>
								<Typography variant="body2" light>
									{conditionCost}%
								</Typography>
							</CssCardContentTextWrapper>
							<CssSeparator noGap />
							<CssCardContentTextWrapper>
								<Typography variant="body2">Cost</Typography>
								<TrainingToken quantity={cost} size="xs" />
							</CssCardContentTextWrapper>
						</Row>
						<Row gap={8}>
							<CssCardHeaderTextWrapper>
								<Typography variant="body2Bold">Attack</Typography>
								<CssCardHeaderColoredText variant="body2Bold" color="hotPink">
									{totalAttack}
								</CssCardHeaderColoredText>
							</CssCardHeaderTextWrapper>
							<CssSeparator noGap />
							<CssCardHeaderTextWrapper>
								<Typography variant="body2Bold">Defense</Typography>
								<CssCardHeaderColoredText variant="body2Bold" color="cornflowerBlue">
									{totalDefense}
								</CssCardHeaderColoredText>
							</CssCardHeaderTextWrapper>
							<CssSeparator noGap />
							<CssCardHeaderTextWrapper>
								<Typography variant="body2Bold">Men & Phy</Typography>
								<CssCardHeaderColoredText variant="body2Bold" color="perfume">
									{totalMentalAndPhysical}
								</CssCardHeaderColoredText>
							</CssCardHeaderTextWrapper>
						</Row>
					</Col>
				</CssCardHeader>
			</CssCardWrapper>
		);
	}

	return (
		<CssCardWrapper>
			<CssCardHeader>
				<CssCardHeaderTextWrapper desktopMinWidth={80}>
					<Typography variant="body2Bold">Attack</Typography>
					<CssCardHeaderColoredText variant="body2Bold" color="hotPink">
						{totalAttack}
					</CssCardHeaderColoredText>
				</CssCardHeaderTextWrapper>
				<CssCardHeaderTextWrapper desktopMinWidth={90}>
					<Typography variant="body2Bold">Defense</Typography>
					<CssCardHeaderColoredText variant="body2Bold" color="cornflowerBlue">
						{totalDefense}
					</CssCardHeaderColoredText>
				</CssCardHeaderTextWrapper>
				<CssCardHeaderTextWrapper desktopMinWidth={108}>
					<Typography variant="body2Bold">Men & Phy</Typography>
					<CssCardHeaderColoredText variant="body2Bold" color="perfume">
						{totalMentalAndPhysical}
					</CssCardHeaderColoredText>
				</CssCardHeaderTextWrapper>
			</CssCardHeader>
			<CssSeparator />
			<CssCardContent>
				<CssCardContentTextWrapper desktopMinWidth={56}>
					<Typography variant="body2">Drills</Typography>
					<Typography variant="body2" light>
						{drillCount}
					</Typography>
				</CssCardContentTextWrapper>
				<CssCardContentTextWrapper desktopMinWidth={80}>
					<Typography variant="body2">Players</Typography>
					<Typography variant="body2" light>
						{playerCount}
					</Typography>
				</CssCardContentTextWrapper>
				<CssCardContentTextWrapper desktopMinWidth={152}>
					<Typography variant="body2">Condition cost</Typography>
					<Typography variant="body2" light>
						{conditionCost}%
					</Typography>
				</CssCardContentTextWrapper>
				<CssCardContentTextWrapper desktopMinWidth={84}>
					<Typography variant="body2">Cost</Typography>
					<TrainingToken quantity={cost} />
				</CssCardContentTextWrapper>
				<CssSeparator noGap />
				<CssCardContentTextWrapper>
					<Typography variant="body2">Date</Typography>
					<Typography variant="body2" light>
						{date}
					</Typography>
				</CssCardContentTextWrapper>
			</CssCardContent>
			<CssCardActions>
				<Button
					appearance="secondary"
					size="sm"
					label="Load training"
					fullWidth
					onClick={onLoadTrainingClicked}
				/>
			</CssCardActions>
		</CssCardWrapper>
	);
};
