import styled, { css } from "styled-components";
import { Position } from "./types";

export const WrapperStyled = styled.div<{ qualityPosition: Position }>(
	({ qualityPosition }) => css`
		display: flex;
		flex-direction: ${qualityPosition === "center"
			? "column"
			: qualityPosition === "right"
			? "row-reverse"
			: "row"};
		gap: ${qualityPosition === "center" ? "4px" : "8px"};
	`
);

export const StarGroupWrapperStyled = styled.div<{ qualityPosition: Position }>(
	({ qualityPosition }) => css`
		margin-left: ${qualityPosition === "center" ? -2 : 0}px;
	`
);
