import { ExitIcon, IconButton, Row, Typography, TypographyProps } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

export const AddressTypography = memo<TypographyProps & { children: string }>(({ children, ...props }) => {
	return (
		<Row alignItems="center" gap={8}>
			<Typography {...props}>
				{children.slice(0, 4)}...{children.slice(-4)}
			</Typography>
			<IconButton
				size="sm"
				icon={<ExitIcon size="sm" />}
				onClick={() =>
					window.open(
						`https://green-giddy-denebola.explorer.mainnet.skalenodes.com/address/${children}`,
						"_blank"
					)
				}
			/>
		</Row>
	);
});
