export enum SideName {
	LEFT = "left",
	RIGHT = "right"
}
export type Side = SideName.LEFT | SideName.RIGHT;
export type OptionalSide = Side | undefined;
export interface SidedType<T> {
	[SideName.LEFT]: T;
	[SideName.RIGHT]: T;
}

interface FixedLengthArray<T, L extends number> extends Array<T> {
	0: T;
	length: L;
}
export type Array11<T> = FixedLengthArray<T, 11>;
export type ArrayNullable11<T> = Array11<T | null>;

export type ArrayNullable<T> = Array<T | null>;

export type XY = [number, number];
