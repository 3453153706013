import { ArrowUpIcon, Row, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { memo } from "react";

export const PromotionDecorator = memo(() => {
	const theme = useTheme();

	return (
		<Row gap={24} alignItems="center">
			<ArrowUpIcon size="sm" color={theme.palette.system.pastelGreen} />
			<Typography variant="body2">PROMOTION</Typography>
			<ArrowUpIcon size="sm" color={theme.palette.system.pastelGreen} />
		</Row>
	);
});
