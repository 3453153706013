import { TOURNAMENTS_TABS } from "@/modules/shared/constants";
import {
	AllowedToken,
	CustomTournamentInfoDTO,
	CustomTournamentTeamInfoDTO,
	ExploreTournamentsDTO,
	GameMode,
	HttpClient,
	IdType,
	MatchedTeam,
	MetaSoccerId,
	RegistrationRequestParams,
	Response,
	RewardsRequestParams,
	TournamentsOfInterestDTO
} from "@metasoccer/metasoccer-types";

export type ExploreFilters = {
	name?: string;
	status?: string[];
	entryFee?: number[];
	tokens?: AllowedToken[];
	overall?: number[];
	numberOfTeams?: number[];
	gameMode?: GameMode;
	region?: string;
	division?: string;
	startDateLte?: string;
};

export class CustomTournamentsService {
	constructor(private readonly httpService: HttpClient) {}

	async explore(
		page: number,
		divisionsPage: boolean,
		filters: ExploreFilters,
		selectedTab?: TOURNAMENTS_TABS,
		teamId?: MetaSoccerId<IdType.Team>,
		registeredIds?: MetaSoccerId<IdType.Tournament>[],
		owner?: string,
		partner?: string
	): Promise<ExploreTournamentsDTO> {
		let response: Response<ExploreTournamentsDTO>;
		try {
			let query = `/custom-tournament/explore?page=${page}`;
			const names: string[] = [];
			if (selectedTab === TOURNAMENTS_TABS["CREATED BY ME"]) {
				query += `&owner=${owner}`;
			} else if (selectedTab === TOURNAMENTS_TABS.JOINED) {
				if (registeredIds?.length === 0) {
					return { tournaments: [] };
				}
				query += `&ids=${registeredIds?.join(",")}`;
			} else if (selectedTab === TOURNAMENTS_TABS.INVITED) {
				query += `&invited=${teamId}`;
			} else if (selectedTab === TOURNAMENTS_TABS.AVAILABLE) {
				query += `&available=${teamId}`;
			}
			if (filters.region) {
				names.push(filters.region);
			}
			if (filters.division) {
				names.push(filters.division);
			}
			if (filters.name && filters.name.length > 2) {
				names.push(filters.name);
			}
			if (!!filters.gameMode) {
				query += `&game_mode=${filters.gameMode}`;
			}
			if (filters.status && filters.status.length > 0) {
				query += `&status=${filters.status.join(",")}`;
			}
			if (filters.entryFee && filters.entryFee.length > 0) {
				query += `&entry_fee=${filters.entryFee.join(",")}`;
			}
			if (filters.tokens && filters.tokens.length > 0) {
				query += `&tokens=${filters.tokens.join(",")}`;
			}
			if (filters.overall && filters.overall.length > 0) {
				query += `&overall=${filters.overall.join(",")}`;
			}
			if (filters.numberOfTeams && filters.numberOfTeams.length > 0) {
				query += `&number_of_teams=${filters.numberOfTeams.join(",")}`;
			}
			if (names.length > 0) {
				query += `&name=${names.join(",")}`;
			}
			if (filters.startDateLte) {
				query += `&start_date_lte=${filters.startDateLte}`;
			}
			if (partner) {
				query += `&partner=${partner}`;
			}
			response = await this.httpService.get<ExploreTournamentsDTO>(query);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getTournamentsOfInterest(
		teamId: MetaSoccerId<IdType.Team>,
		pendingIds: string[]
	): Promise<TournamentsOfInterestDTO> {
		let response: Response<TournamentsOfInterestDTO>;
		try {
			response = await this.httpService.get<TournamentsOfInterestDTO>(
				`/custom-tournament/of-interest/${teamId}?${
					pendingIds.length > 0 ? "pending=" + pendingIds.join(",") : ""
				}`
			);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async searchTeamsById(teamIds: MetaSoccerId<IdType.Team>[]): Promise<{ collection: MatchedTeam[] }> {
		let response: Response<{ collection: MatchedTeam[] }>;
		try {
			response = await this.httpService.get<{ collection: MatchedTeam[] }>(
				`/teams/searchByIds?${teamIds.length > 0 ? "ids=" + teamIds.join(",") : ""}`
			);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getTournamentTeamInfo(
		tournamentId: string,
		teamId: MetaSoccerId<IdType.Team>
	): Promise<CustomTournamentTeamInfoDTO> {
		let response: Response<CustomTournamentTeamInfoDTO>;
		try {
			response = await this.httpService.get<CustomTournamentTeamInfoDTO>(
				`/custom-tournament/${tournamentId}/team-info/${teamId}`
			);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getTournamentInfo(tournamentId: string): Promise<CustomTournamentInfoDTO> {
		let response: Response<CustomTournamentInfoDTO>;
		try {
			response = await this.httpService.get<CustomTournamentInfoDTO>(`/custom-tournament/${tournamentId}/info`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getRegistrationSignature(
		tournamentId: string,
		teamId: MetaSoccerId<IdType.Team>
	): Promise<RegistrationRequestParams> {
		let response: Response<RegistrationRequestParams>;
		try {
			response = await this.httpService.get<RegistrationRequestParams>(
				`/custom-tournament/${tournamentId}/register/${teamId}`
			);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getRewardsSignature(tournamentId: string): Promise<RewardsRequestParams> {
		let response: Response<RewardsRequestParams>;
		try {
			response = await this.httpService.get<RewardsRequestParams>(`/custom-tournament/${tournamentId}/rewards`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getTeamsForWhitelist(search: string): Promise<{ collection: MatchedTeam[] }> {
		let response: Response<{ collection: MatchedTeam[] }>;
		try {
			response = await this.httpService.get<{ collection: MatchedTeam[] }>(`/teams/search?nameLike=${search}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}

	async getBestPlayersOverall(teamId: MetaSoccerId<IdType.Team>): Promise<number> {
		let response: Response<number>;
		try {
			response = await this.httpService.get<number>(`/custom-tournament/best-players-overall/${teamId}`);
		} catch (e: any) {
			throw e.message;
		}

		return response.data;
	}
}
