import {
	CssContent,
	CssContentRow
} from "@/modules/home/views/Deprecated/Standings/components/FilteredStanding/components/FilteredStandingMobile/components/FiltersSheet/components/GroupSheet/GroupSheet.styled";
import { Modal, Typography } from "@metasoccer/metasoccer-ds";
import { TournamentStructureDivisionGroup } from "@metasoccer/metasoccer-types";
import { FC } from "react";

interface GroupSheetProps {
	isOpen: boolean;
	groups: TournamentStructureDivisionGroup[];
	onGroupSelect: (group: TournamentStructureDivisionGroup) => () => void;
	onClose: () => void;
}

export const GroupSheet: FC<GroupSheetProps> = ({ isOpen, groups, onGroupSelect, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Select group">
			<CssContent>
				{groups.map((group) => (
					<CssContentRow key={group.id} onClick={onGroupSelect(group)}>
						<Typography variant="buttonLg">{group.name.split(" ")[1]}</Typography>
					</CssContentRow>
				))}
			</CssContent>
		</Modal>
	);
};
