import { ReactNode, memo } from "react";
import { CssFlexScrollingWrapper } from "./FlexScrollingContainer.styled";

interface FlexScrollingContainerProps {
	children: ReactNode;
}

export const FlexScrollingContainer = memo<FlexScrollingContainerProps>(({ children }) => {
	return <CssFlexScrollingWrapper>{children}</CssFlexScrollingWrapper>;
});
