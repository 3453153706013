// KO prizes start at 100 to avoid clashing with League prizes starting at 0

export enum KoTournamentPrize {
	WINNER_TAKES_ALL = 100,
	FINAL_ONLY = 101,
	SEMIFINALS = 102,
	QUARTERS = 103
}

export const KO_REWARDS_DISTRIBUTION: Record<KoTournamentPrize, number[]> = {
	[KoTournamentPrize.WINNER_TAKES_ALL]: [100],
	[KoTournamentPrize.FINAL_ONLY]: [65, 35],
	[KoTournamentPrize.SEMIFINALS]: [45, 25, 15, 15],
	[KoTournamentPrize.QUARTERS]: [30, 22, 12, 12, 6, 6, 6, 6]
};
