import { getCoveringBySpecificRole } from "@/modules/player/helpers/getCoveringBySpecificRole";
import { RoleTypography, Typography } from "@metasoccer/metasoccer-ds";
import { memo } from "react";
import { PlayerTableItem } from "../PlayerTable";

export const LineupCell = memo<{ player: PlayerTableItem }>(({ player }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				flexDirection: "row",
				gap: 4,
				width: 52
			}}>
			{(player.slotSpecificRole || player.slotBenchTag) && (
				<Typography variant="subtitle2" style={{ minWidth: 16, textAlign: "center" }}>
					{player.shirtNumber}
				</Typography>
			)}
			{player.slotSpecificRole ? (
				<RoleTypography
					covering={getCoveringBySpecificRole(player.slotSpecificRole)}
					specificRoles={[player.slotSpecificRole]}
				/>
			) : player.slotBenchTag ? (
				<Typography variant="body2" light>
					{player.slotBenchTag}
				</Typography>
			) : (
				<Typography variant="buttonMd" light style={{ flexGrow: 1 }} textAlign="center">
					-
				</Typography>
			)}
		</div>
	);
});
