import { Scouting } from "@metasoccer/metasoccer-types";

export const getScoutingSecondsRemaining = (lastScouting?: Scouting): number => {
	if (!lastScouting) {
		return -1;
	}

	const now = Math.round(new Date().getTime() / 1000);
	const completedScoutingTimestamp = lastScouting.timestamp + lastScouting.scoutingPeriod;
	return Math.max(completedScoutingTimestamp - now, 0);
};
