export const ERC20Redeemer = {
  "name": "ERC20Redeemer",
  "address": "0xa8d58F4E5CAa4E40d001549F7Cba9491bC63eC9c",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_rewardToken"
        },
        {
          "type": "address",
          "name": "_passNFT"
        },
        {
          "type": "uint256",
          "name": "_claimAmount"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "ClaimAmountUpdated",
      "inputs": [
        {
          "type": "uint256",
          "name": "newAmount",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Claimed",
      "inputs": [
        {
          "type": "address",
          "name": "user",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "amount",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "OwnershipTransferred",
      "inputs": [
        {
          "type": "address",
          "name": "previousOwner",
          "indexed": true
        },
        {
          "type": "address",
          "name": "newOwner",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "PassNFTUpdated",
      "inputs": [
        {
          "type": "address",
          "name": "newAddress",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RewardTokenUpdated",
      "inputs": [
        {
          "type": "address",
          "name": "newAddress",
          "indexed": true
        }
      ]
    },
    {
      "type": "function",
      "name": "claim",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "claimAmount",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "emergencyWithdrawAllTokens",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasClaimed",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "owner",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "passNFT",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceOwnership",
      "constant": false,
      "payable": false,
      "inputs": [],
      "outputs": []
    },
    {
      "type": "function",
      "name": "rewardToken",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "transferOwnership",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newOwner"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "updateClaimAmount",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "newAmount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "updatePassNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "updateRewardToken",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "newAddress"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawTokens",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint256",
          "name": "amount"
        }
      ],
      "outputs": []
    }
  ]
}