import { ThemePalette } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export type FinalScore = "home" | "away" | "tie" | undefined;

interface CssMatchScoreRootProps {
	isMatchResultLayout: boolean;
	finalScore: FinalScore;
	side: "home" | "away";
}

export const CssMatchScoreRoot = styled.div<CssMatchScoreRootProps>(
	({ theme, isMatchResultLayout, finalScore, side }) => css`
		display: flex;
		align-items: center;
		background-color: ${isMatchResultLayout ? theme.palette.backgrounds.codGray : theme.palette.backgrounds.cinder};
		height: 40px;
		border-left: ${finalScore &&
		side === "home" &&
		`2px solid ${getColorByFinalScore(finalScore, side, theme.palette)}`};
		border-right: ${finalScore &&
		side === "away" &&
		`2px solid ${getColorByFinalScore(finalScore, side, theme.palette)}`};

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			background-color: ${theme.palette.backgrounds.codGray};
		}
	`
);

export const CssScore = styled.div<{ wide?: boolean }>`
	width: ${({ wide }) => (wide ? "40px" : "20px")};
	height: 20px;
	display: flex;
	justify-content: center;
	margin: 9px 12px;
`;

export const CssSeparator = styled.div(
	({ theme }) => css`
		width: 1px;
		height: 24px;
		background-color: ${theme.palette.backgrounds.tundora};
	`
);

const getColorByFinalScore = (finalScore: FinalScore, side: "home" | "away", palette: ThemePalette) => {
	if (finalScore === "away") {
		return side === "home" ? palette.system.amaranth : palette.system.pastelGreen;
	}

	if (finalScore === "home") {
		return side === "home" ? palette.system.pastelGreen : palette.system.amaranth;
	}

	if (finalScore === "tie") {
		return palette.system.laserLemon;
	}
};
