import { useServerState } from "@/modules/core/react-query/hooks";
import { HOME_QUERIES } from "@/modules/home/queries";
import { InfoMessage, Loading } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { useTournament } from "@/modules/shared/hooks";
import { Modal } from "@metasoccer/metasoccer-ds";
import { IdType, MetaSoccerId, isMetaSoccerId } from "@metasoccer/metasoccer-types";
import { useNavigate, useParams } from "react-router-dom";
import { HOME_ROUTES } from "../../../home.routes";
import { ResultsList } from "./components";

const ResultsPage = () => {
	const { tournamentId } = useParams();

	const { selectedTeam } = useGlobalState();

	const navigate = useNavigate();

	const { data: tournamentData } = useTournament();

	const tournamentStructureQuery = HOME_QUERIES.tournamentStructureEditions(
		tournamentId as MetaSoccerId<IdType.Tournament>
	);
	const { data: tournamentStructureEditions, isLoading: isLoadingTournamentStructure } = useServerState(
		tournamentStructureQuery,
		{ enabled: !!tournamentId && isMetaSoccerId(tournamentId, IdType.Tournament) }
	);

	const lastMatchesQuery = HOME_QUERIES.lastMatchesByTeamId(selectedTeam?.id);
	const lastMatchesNoConnectedQuery = HOME_QUERIES.lastMatches();
	const {
		data: lastMatchesOfTeam,
		isError: isLastMatchesOfTeamError,
		isLoading: isLoadingLastMatchesOfTeam
	} = useServerState(lastMatchesQuery, {
		enabled: !!selectedTeam
	});
	const {
		data: matchResultsNoConnected,
		isError: isMatchResultsNoConnectedError,
		isLoading: isLoadingMatchResultsNoConnected
	} = useServerState(lastMatchesNoConnectedQuery);

	const isJoined = Boolean(tournamentData?.joinedInfo.joined);
	const lastMatches = selectedTeam && isJoined ? lastMatchesOfTeam : matchResultsNoConnected;

	const isLoading = isLoadingTournamentStructure || isLoadingLastMatchesOfTeam || isLoadingMatchResultsNoConnected;
	const isError = selectedTeam && isJoined ? isLastMatchesOfTeamError : isMatchResultsNoConnectedError;
	const hasLastMatches = lastMatches && lastMatches.length > 0;
	const hasEditions = tournamentStructureEditions && tournamentStructureEditions.length > 0;

	const handleClose = () => navigate(HOME_ROUTES.PVE_ROOT.buildPath());

	return (
		<Modal isOpen onClose={handleClose} title="Results" fullScreen maxScreenWidth navigationType="arrow">
			{!tournamentId || !isMetaSoccerId(tournamentId, IdType.Tournament) || isError ? (
				<InfoMessage type="error" />
			) : isLoading ? (
				<Loading />
			) : !hasEditions || !hasLastMatches ? (
				<InfoMessage message="You haven't played a match yet" />
			) : (
				<ResultsList lastMatches={lastMatches} tournamentStructureEditions={tournamentStructureEditions} />
			)}
		</Modal>
	);
};

export default ResultsPage;
