import styled, { css } from "styled-components";

export const CssTournamentInfoOuterWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	overflow-y: hidden;
`;

export const CssTournamentInfoInnerWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
			overflow-y: hidden;
			height: 100%;
		}
	`
);
