import { ReactNode, memo } from "react";

export interface WithDecoratorProps {
	children: ReactNode;
	decorator: ReactNode;
}

export const WithDecorator = memo<WithDecoratorProps>(({ children, decorator }) => {
	return (
		<div style={{ position: "relative" }}>
			{children}
			<div style={{ position: "absolute", right: -4, bottom: -4 }}>{decorator}</div>
		</div>
	);
});
