import React from "react";
import { SelectedTeamContext } from "../SelectedTeamContext";

const useSelectedTeamState = () => {
	const context = React.useContext(SelectedTeamContext);
	if (context === undefined) {
		throw new Error("useSelectedTeamState must be used within a SelectedTeamContextProvider");
	}
	return context;
};

export { useSelectedTeamState };
