import styled, { css } from "styled-components";
import { animated } from "react-spring";

export const CssCardWrapper = styled.div`
	height: 125px;
	width: 100%;
	display: flex;
	background-color: ${({ theme }) => theme.palette.backgrounds.codGray};
	position: relative;
`;

export const CssImage = styled.div`
	flex: 0 0 auto;
	height: 100%;
	width: 100px;
	display: flex;
	background: url("https://assets.metasoccer.com/cdn-cgi/image/quality=90,metadata=none,format=auto/scouts/waist-up/generic.png");
	background-repeat: no-repeat;
	background-size: 75%;
	background-position: center;
	transform: scale3d(-1, 1, 1);
`;

export const CssLoadingWrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	padding: 12px 0;
`;

export const CssLoadingBar = styled(animated.div)`
	position: absolute;

	background-color: #ffffff;
	width: 25%;
	height: 1px;
`;

export const CssContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0 0 8px;
`;

export const CssVerticalWrapper = styled.div(
	({ theme }) => css`
		position: relative;
		min-width: 204px;
		max-width: 360px;
		height: 288px;
		background: ${theme.palette.backgrounds.codGray};
	`
);

export const CssVerticalBackground = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: url("https://assets2.metasoccer.com/file/assets2-metasoccer/img/formatted/generic.avif");
		background-repeat: no-repeat;
		background-size: 74%;
		background-position: 40px bottom;
	`
);

export const CssVerticalBackgroundOverlay = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 20%;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;

		background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0.8) 45%, #151515 100%);
	`
);

export const CssVerticalContent = styled.div(
	({ theme }) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 40px 0;
	`
);

export const CssVerticalLoadingWrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px 0;
	z-index: 4;
`;

export const CssVerticalLoadingBar = styled(animated.div)`
	position: absolute;

	background-color: #ffffff;
	width: 25%;
	height: 1px;
`;
