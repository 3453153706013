import { getDomain } from "./helpers";

export const getUnleashProxyUrl = () => {
	const domain = getDomain();
	if (domain === "localhost") {
		return "https://api.devsoccer.com/unleash/proxy";
	}

	return `https://api.${domain}/unleash/proxy`;
};
