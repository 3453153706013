import { ReactNode, memo } from "react";
import { ContainerStyled } from "./Container.styles";

export interface ContainerProps {
	children: ReactNode;
	className?: string;
	background?: string;
	gap?: number;
	height?: number | string;
	overflowX?: "auto" | "hidden" | "visible";
	overflowY?: "auto" | "hidden" | "visible";
	paddingX?: number;
	paddingY?: number;
}

export const Container = memo<ContainerProps>(
	({ children, className, background, gap, height, overflowX, overflowY, paddingX, paddingY }) => {
		return (
			<ContainerStyled
				className={className}
				background={background}
				gap={gap}
				height={height}
				overflowX={overflowX}
				overflowY={overflowY}
				paddingX={paddingX}
				paddingY={paddingY}>
				{children}
			</ContainerStyled>
		);
	}
);
