import {
	HttpClient,
	RequestConfig,
	RequestInterceptor,
	Response,
	ResponseInterceptor
} from "@metasoccer/metasoccer-types";
import axios, { AxiosInstance } from "axios";

export class AxiosHttpClient extends HttpClient {
	private readonly axiosClient: AxiosInstance;

	constructor(config: RequestConfig) {
		super(config);
		this.axiosClient = axios.create({
			...config,
			baseURL: config.baseUrl
		});
	}

	appendRequestInterceptor(interceptor: RequestInterceptor): void {
		this.axiosClient.interceptors.request.use(interceptor.onSuccess, interceptor.onError);
	}

	appendResponseInterceptor(interceptor: ResponseInterceptor): void {
		this.axiosClient.interceptors.response.use(interceptor.onSuccess, interceptor.onError);
	}

	//TODO: Fix types to return proper value types
	get<R = any>(url: string, config?: RequestConfig): Promise<Response<R>> {
		return this.axiosClient.get(url, config);
	}

	//TODO: Fix types to return proper value types
	post<P, R = any>(url: string, data?: P, config?: RequestConfig): Promise<Response<R>> {
		return this.axiosClient.post(url, data, config);
	}
}
