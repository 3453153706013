export function buildCustomConsoleError() {
	const original = console.error;

	return function (...args: any[]) {
		const argsAsString = JSON.stringify(args);

		if (
			argsAsString.includes("Warning: Invalid DOM property") ||
			argsAsString.match(/Warning: React does not recognize the `.*` prop on a DOM element/) ||
			argsAsString.match(/Warning: Received `.*` for a non-boolean attribute `.*`/) ||
			argsAsString.match(/Warning: findDOMNode is deprecated in StrictMode/)
		) {
			// do nothing
		} else {
			original.call(console, ...args);
		}
	};
}
