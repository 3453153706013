import { Row, Typography } from "@metasoccer/metasoccer-ds";
import { FC, memo } from "react";
import { CssMatchScoreRoot, CssScore, CssSeparator, FinalScore } from "./MatchScore.styles";

interface MatchScoreProps {
	homeGoals: number;
	homePenaltyShootoutScore?: number;
	awayGoals: number;
	awayPenaltyShootoutScore?: number;
	isMatchResultLayout: boolean;
	isFinished: boolean;
	side?: "home" | "away";
}

export const MatchScore: FC<MatchScoreProps> = memo(
	({
		homeGoals,
		homePenaltyShootoutScore,
		awayGoals,
		awayPenaltyShootoutScore,
		isMatchResultLayout,
		isFinished,
		side = "home"
	}) => {
		const finalScore: FinalScore = isFinished
			? homeGoals > awayGoals
				? "home"
				: awayGoals > homeGoals
				? "away"
				: "tie"
			: undefined;

		return (
			<CssMatchScoreRoot isMatchResultLayout={isMatchResultLayout} finalScore={finalScore} side={side}>
				<CssScore wide={homePenaltyShootoutScore !== undefined}>
					<Row gap={8} alignItems="center">
						{homePenaltyShootoutScore !== undefined && (
							<Typography variant="caption" light>{`(${homePenaltyShootoutScore})`}</Typography>
						)}
						<Typography variant="body1">{homeGoals}</Typography>
					</Row>
				</CssScore>
				<CssSeparator />
				<CssScore wide={homePenaltyShootoutScore !== undefined}>
					<Row gap={8} alignItems="center">
						<Typography variant="body1">{awayGoals}</Typography>
						{awayPenaltyShootoutScore !== undefined && (
							<Typography variant="caption" light>{`(${awayPenaltyShootoutScore})`}</Typography>
						)}
					</Row>
				</CssScore>
			</CssMatchScoreRoot>
		);
	}
);
