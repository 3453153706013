import { Environment } from "../types";

export const TOKENS_ADDRESSES = {
	[Environment.LOCAL]: {
		MSU: "0xfCf71d52d1aD105A4Dcf5809af5Bd560ABDaca0c",
		MSC: "0x0000000000000000000000000000000000000000"
	},
	[Environment.DEV]: {
		MSU: "0xfCf71d52d1aD105A4Dcf5809af5Bd560ABDaca0c",
		MSC: "0x0000000000000000000000000000000000000000"
	},
	[Environment.PROD]: {
		MSU: "0xe8377a076adabb3f9838afb77bee96eac101ffb1",
		MSC: "0x5d2375C6af4b7dE4e395ADa20aab937895B4fa70"
	},
	[Environment.TEST]: {
		MSU: "",
		MSC: ""
	}
};
