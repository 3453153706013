import { Container, FixedAndGrowLayout } from "@/modules/shared/components";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	Col,
	IconButton,
	Row,
	TextField,
	ThemePalette,
	Typography,
	useTheme
} from "@metasoccer/metasoccer-ds";
import { useEffect, useRef } from "react";
import { ClubLogoStyled, ColorButtonStyled, SquareStyled } from "./CreateYourClub.styled";

interface CreateYourClubProps {
	clubName: string;
	selectedColor: string;
	selectedLogoIndex: number;
	setClubName: (value: string) => void;
	setSelectedColor: (value: string) => void;
	setSelectedLogoIndex: (value: number) => void;
	onClubNameValidation: (valid: boolean) => void;
	onSubmit: () => void;
}

export const CreateYourClub = ({
	clubName,
	selectedColor,
	selectedLogoIndex,
	setClubName,
	setSelectedColor,
	setSelectedLogoIndex,
	onClubNameValidation,
	onSubmit
}: CreateYourClubProps) => {
	const textInputFocus = useRef<HTMLInputElement>(null);
	const theme = useTheme();

	useEffect(() => {
		if (textInputFocus.current) {
			textInputFocus.current.focus();
		}
	}, []);

	return (
		<Container height="100%" paddingY={32}>
			<FixedAndGrowLayout alignItems="center" overflow="hidden">
				<Col gap={32} style={{ width: "100%", maxWidth: 600 }}>
					<Typography variant="h4" textAlign="center">
						Create your club
					</Typography>

					<Typography variant="body1" textAlign="center" light>
						Name your club, choose a badge, and pick colors that'll make the fans cheer. This is where your
						legacy begins.
					</Typography>
				</Col>
				<Col
					alignItems="center"
					justifyContent="center"
					gap={32}
					style={{ width: "100%", maxWidth: 600, height: "100%" }}>
					<Row alignItems="center">
						<IconButton
							icon={<ArrowLeftIcon size="sm" />}
							onClick={() => setSelectedLogoIndex(selectedLogoIndex > 0 ? selectedLogoIndex - 1 : 0)}
						/>
						<ClubLogoStyled color={selectedColor} name={`Club${selectedLogoIndex + 1}`} />
						<IconButton
							icon={<ArrowRightIcon size="sm" />}
							onClick={() => setSelectedLogoIndex(selectedLogoIndex < 19 ? selectedLogoIndex + 1 : 19)}
						/>
					</Row>
					<Row alignItems="center" justifyContent="center" style={{ flexWrap: "wrap" }}>
						{Object.keys(theme.palette.system).map((key) => {
							return (
								<ColorButtonStyled
									appearance="group"
									onClick={() =>
										setSelectedColor(theme.palette.system[key as keyof ThemePalette["system"]])
									}>
									<SquareStyled
										key={key}
										color={theme.palette.system[key as keyof ThemePalette["system"]]}
									/>
								</ColorButtonStyled>
							);
						})}
					</Row>
					<TextField
						ref={textInputFocus}
						variant="outlined"
						fullWidth
						customValidators={[
							{
								message: "Name must contain between 5 and 18 characters",
								validate: (v: string) => {
									const valid = v.length >= 5 && v.length <= 18;
									onClubNameValidation(valid);
									return valid;
								}
							}
						]}
						label="Name"
						value={clubName}
						onChange={setClubName}
						onKeyDown={(e: KeyboardEvent) => {
							if (e.key === "Enter") {
								onSubmit();
							}
						}}
					/>
				</Col>
			</FixedAndGrowLayout>
		</Container>
	);
};
