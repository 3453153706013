import { useIsMobile } from "@/layouts/context";
import { ExtraImprovementChip, FocusedImprovementChip } from "@/modules/shared/components";
import { Button, Modal, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssContent, CssTextWrapper } from "./DrillsInfoDialog.styled";

export interface DrillsInfoDialogProps {
	isOpen: boolean;
	onClose?: () => void;
}

export const DrillsInfoDialog: FC<DrillsInfoDialogProps> = ({ isOpen, onClose = () => {} }) => {
	const isMobile = useIsMobile();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Drills info"
			size="xl"
			footerContent={<Button appearance="primary" label="Understood" fullWidth onClick={onClose} />}
			fullScreen={isMobile}>
			<CssContent>
				<CssTextWrapper>
					<Typography variant="body1Bold">
						Do you want to train your players and get them to improve?
					</Typography>
					<Typography variant="body1" light>
						You can configure a set of drills and assign players to those drills. Players will upgrade based
						on their age and condition. That means that some of the primary and secondary abilities shown in
						each drill will randomly be upgraded.
					</Typography>
				</CssTextWrapper>
				<CssTextWrapper>
					<ExtraImprovementChip />
					<Typography variant="body1Bold">With extra improvement</Typography>
					<Typography variant="body1" light>
						Drills with extra improvement have an additional boost to some of the abilities listed in the
						drill. These drills have an additional cost.
					</Typography>
				</CssTextWrapper>
				<CssTextWrapper>
					<FocusedImprovementChip />
					<Typography variant="body1Bold">With focused improvement</Typography>
					<Typography variant="body1" light>
						Drills focused on some key abilities for certain specific roles, allowing the overall of these
						players to improve faster.
					</Typography>
				</CssTextWrapper>
			</CssContent>
		</Modal>
	);
};
