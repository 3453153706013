import DevelopmentAppConfig from "@/config/development.json";
import LocalAppConfig from "@/config/local.json";
import ProductionAppConfig from "@/config/production.json";
import TestAppConfig from "@/config/test.json";
import { Environment } from "@/modules/shared/types";
import { AppConfig } from "./AppConfig";

type AppConfigWithEnvironment = AppConfig & { env: Environment };

export class ConfigService {
	private readonly config: AppConfigWithEnvironment;

	constructor(environment: Environment) {
		switch (environment) {
			case Environment.LOCAL:
				this.config = LocalAppConfig as unknown as AppConfigWithEnvironment;
				this.config.env = Environment.LOCAL;
				break;
			case Environment.DEV:
				this.config = DevelopmentAppConfig as unknown as AppConfigWithEnvironment;
				this.config.env = Environment.DEV;
				break;
			case Environment.PROD:
				this.config = ProductionAppConfig as unknown as AppConfigWithEnvironment;
				this.config.env = Environment.PROD;
				break;
			case Environment.TEST:
				this.config = TestAppConfig as unknown as AppConfigWithEnvironment;
				this.config.env = Environment.TEST;
				break;
		}
	}

	get(): AppConfigWithEnvironment {
		return this.config;
	}
}
