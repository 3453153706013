import { HttpClient, Response } from "@metasoccer/metasoccer-types";

export class RewardService {
	constructor(private readonly httpService: HttpClient) {}

	async getMSCLocked(address: string): Promise<number> {
		let response: Response<number>;
		try {
			response = await this.httpService.get<number>(`/rewards/msc-locked/${address}`);
		} catch (e) {
			throw e;
		}

		return response.data;
	}
}
