import styled, { css } from "styled-components";

export const CssTrainingHeaderWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding: 12px;
		border-bottom: 0.063rem solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			border-bottom-color: transparent;
			padding: 22px 24px;
		}
	`
);
