import { SQUAD_ROUTES } from "@/modules/squad/squad.routes";
import { Button, InfoIcon, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CssLineupNoticesWrapper, CssNoticeWrapper, CssNoticesWrapper } from "./LineupNotices.styled";

interface LineupNoticesProps {
	notices: string[];
}

export const LineupNotices: FC<LineupNoticesProps> = ({ notices }) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const goToLineup = () => {
		navigate(SQUAD_ROUTES.ROOT.buildPath());
	};
	return (
		<CssLineupNoticesWrapper>
			<Typography variant="h6">Check Lineup</Typography>
			<CssNoticesWrapper>
				{notices.map((n, key) => (
					<CssNoticeWrapper key={key}>
						<InfoIcon size={"sm"} color={theme.palette.system.amaranth}></InfoIcon>
						<Typography variant={"body2"}>{n}</Typography>
					</CssNoticeWrapper>
				))}
			</CssNoticesWrapper>
			<Button appearance="secondary" label="Go to lineup" onClick={goToLineup}></Button>
		</CssLineupNoticesWrapper>
	);
};
