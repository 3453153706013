import { BaseTeamsTable, ColumnHeader, InfoMessage, Loading } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { getPrizeColorByPosition } from "@/modules/shared/helpers";
import { Button, FiltersIcon, Typography } from "@metasoccer/metasoccer-ds";
import {
	TournamentDetailedStandingRow,
	TournamentDivisionSimplified,
	TournamentStructureEdition
} from "@metasoccer/metasoccer-types";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { FC, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { StandingsFilters } from "../../../../Standings.state";
import {
	CssFilteredStandingMobileWrapper,
	CssHeaderWrapper,
	CssPointsWrapper,
	CssPosition,
	CssTableWrapper,
	CssTitleWrapper
} from "./FilteredStandingMobile.styled";
import { FiltersSheet } from "./components";

interface FilteredStandingMobileProps {
	isLoadingFilteredStanding: boolean;
	tournamentStructureEditions: TournamentStructureEdition[];
	filters: StandingsFilters;
	rankedTeams?: TournamentDetailedStandingRow[];
	divisionsWithRewards: TournamentDivisionSimplified[];
	onFilterChange: (filter: StandingsFilters) => void;
	toggleRewardsSheet: () => void;
}

const columnHelper = createColumnHelper<TournamentDetailedStandingRow>();

export const FilteredStandingMobile: FC<FilteredStandingMobileProps> = ({
	isLoadingFilteredStanding,
	tournamentStructureEditions,
	filters,
	rankedTeams,
	divisionsWithRewards,
	onFilterChange,
	toggleRewardsSheet
}) => {
	const { selectedTeam } = useGlobalState();
	const theme = useTheme();
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const rewards = useMemo(
		() => divisionsWithRewards.find((division) => division.id === filters.divisionId)?.rewards ?? [],
		[divisionsWithRewards, filters.divisionId]
	);
	const standingTitle = `Edition ${filters.editionName} - ${filters.divisionName} - Group ${filters.groupName}`;
	const toggleFiltersSheet = () => setIsFiltersOpen((prevState) => !prevState);

	const columns = useMemo(
		() => [
			columnHelper.accessor("position", {
				id: "position",
				header: () => <Typography variant={"body2"}>#</Typography>,
				cell: (info) => {
					const position = info.getValue();
					const isMyTeam = info.row.original.id === selectedTeam?.id;
					const color = getPrizeColorByPosition(position, isMyTeam, rewards, theme.palette);

					return (
						<CssPosition color={color}>
							<Typography variant={"body2"} light>
								{position}
							</Typography>
						</CssPosition>
					);
				},
				size: 200
			}),
			columnHelper.accessor("clubName", {
				id: "team",
				header: () => <ColumnHeader title="Team" />,
				cell: ({ getValue, row }) => <Typography variant={"body2Bold"}>{getValue()}</Typography>,
				size: 1000
			}),
			columnHelper.accessor("played", {
				id: "played",
				header: () => <ColumnHeader title="P" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("won", {
				id: "won",
				header: () => <ColumnHeader title="W" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("tied", {
				id: "tied",
				header: () => <ColumnHeader title="T" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),
			columnHelper.accessor("lost", {
				id: "lost",
				header: () => <ColumnHeader title="L" />,
				cell: ({ getValue }) => (
					<Typography variant={"body2"} light>
						{getValue()}
					</Typography>
				)
			}),

			columnHelper.accessor("points", {
				id: "points",
				header: () => <ColumnHeader title="Pts" />,
				cell: ({ getValue }) => (
					<CssPointsWrapper>
						<Typography variant={"body2Bold"}>{getValue()}</Typography>
					</CssPointsWrapper>
				)
			})
		],
		[selectedTeam?.id, rewards, theme.palette]
	);

	const table = useReactTable({
		data: rankedTeams ?? [],
		columns,
		getCoreRowModel: getCoreRowModel()
	});
	return (
		<>
			<CssFilteredStandingMobileWrapper>
				<CssHeaderWrapper>
					<Button label="Rewards info" appearance="secondary" fullWidth onClick={toggleRewardsSheet} />
					<Button
						label="Filter"
						appearance="secondary"
						leftIcon={<FiltersIcon size="sm" />}
						fullWidth
						onClick={toggleFiltersSheet}
					/>
				</CssHeaderWrapper>
				<CssTitleWrapper>
					<Typography variant="body2Bold">{standingTitle}</Typography>
				</CssTitleWrapper>
				<CssTableWrapper>
					{isLoadingFilteredStanding ? (
						<Loading />
					) : !rankedTeams ? (
						<InfoMessage message="There are no standings to show" />
					) : (
						<BaseTeamsTable table={table} />
					)}
				</CssTableWrapper>
			</CssFilteredStandingMobileWrapper>
			{isFiltersOpen && (
				<FiltersSheet
					isOpen={isFiltersOpen}
					tournamentStructureEditions={tournamentStructureEditions}
					filters={filters}
					onFilterChange={onFilterChange}
					onClose={toggleFiltersSheet}
				/>
			)}
		</>
	);
};
