import { Paragraph } from "@/modules/shared/components/Paragraph";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { format } from "date-fns";
import { ReactNode, memo, useEffect, useState } from "react";
import { useMSLState } from "../../context";

export interface InfoProps {
	gap?: number;
	After?: ReactNode | ReactNode[];
}

export const Info = memo<InfoProps>(({ gap, After }) => {
	const { group } = useMSLState();
	const startTimestamp = group ? group.tournamentEdition.startDate : new Date();

	const [registrationDateTimeLocal, setRegistrationDateTimeLocal] = useState<string | null>(null);
	const [matchTimezoneLocal, setMatchTimezoneLocal] = useState<string>("");

	useEffect(() => {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		const registrationDateTimeUTC = new Date(startTimestamp);
		const registrationDateTimeLocal = new Date(
			registrationDateTimeUTC.toLocaleString("en-US", { timeZone: userTimezone })
		);

		const matchTimezoneUTC = 16;
		const matchTimezoneLocal = new Date();
		matchTimezoneLocal.setUTCHours(matchTimezoneUTC, 0, 0, 0);
		const matchTimeLocal = new Date(matchTimezoneLocal.toLocaleString("en-US", { timeZone: userTimezone }));

		const formattedRegistrationDateTimeLocal = format(registrationDateTimeLocal, "EEEE, MMMM do, yyyy, h:mm a");
		const formattedMatchTimeLocal = format(matchTimeLocal, "h:mm a");

		setRegistrationDateTimeLocal(formattedRegistrationDateTimeLocal);
		setMatchTimezoneLocal(formattedMatchTimeLocal);
	}, [startTimestamp]);

	return (
		<Col gap={gap ?? 24}>
			<Paragraph variant="body2" light textAlign="justify">
				<p>Welcome to the MetaSoccer League (MSL), the premier competition in the MetaSoccer Universe.</p>
				<p>
					It consists of a league system with four divisions (A to D), each containing one or more groups of
					16 teams. The top tier, Division A, features the top 16 teams in the metaverse. Below this premier
					tier, we have four Division B leagues, each comprising 16 teams, with further divisions extending
					down the hierarchy.
				</p>
				<p>Upon signing up, you&rsquo;ll start in the lowest division.</p>
				<p>
					In each group, teams engage in a home-and-away fixture system, with 16 clubs playing one match per
					day. A season consists of 30 matches, lasting approximately a month. At the end of the season, the
					two leading teams in each division's series are promoted, while the bottom eight face relegation to
					a division below. Division D represents the league's lowest tier, with no further relegation
					possible.
				</p>
				<p>
					At the end of each season, the top 2 teams are promoted to the higher division (except for Division
					A), while the bottom 8 teams are relegated to the lower division. The remaining teams retain their
					current division and category.
				</p>
			</Paragraph>
			<Typography variant="body2">RULES & LOGISTICS</Typography>
			<Paragraph variant="body2" light textAlign="justify">
				<p>Registration is currently open and will close {registrationDateTimeLocal}.</p>
				<p>
					<ul style={{ display: "flex", flexDirection: "column", gap: 8, paddingLeft: 24 }}>
						<li>
							<b>Calendar:</b> All league matches commence automatically at {matchTimezoneLocal} every day
							without requiring user interaction.
						</li>
						<li>
							<b>Lineup and Tactics:</b> Teams will initiate matches using the last lineup and tactics set
							up in the Squad page.
						</li>
						<li>
							<b>Player eligibility:</b> At the beginning of each season, we take a snapshot of your
							team's players and register them for the MSL. Only registered players are eligible to
							participate in MSL matches.
						</li>
						<li>
							<b>Player energy:</b> Each player who participates in league matches will lose 20 points of
							condition. If a player plays less than 90 minutes, the condition loss is proportional.
						</li>
					</ul>
				</p>
			</Paragraph>
			{After}
		</Col>
	);
});
