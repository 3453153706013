import {
	MSL_MSU_REWARDS_DISTRIBUTION,
	MSL_PROMOTION_POSITION,
	MSL_RELEGATION_POSITION
} from "@metasoccer/metasoccer-types";
import { PromotionDecorator } from "../components/PromotionDecorator";
import { RelegationDecorator } from "../components/RelegationDecorator";

export default function getMSLDecorators(divisionIndex: number) {
	return MSL_MSU_REWARDS_DISTRIBUTION[divisionIndex].map((_, index) =>
		MSL_PROMOTION_POSITION.includes(index) && divisionIndex !== 0 ? (
			<PromotionDecorator />
		) : MSL_RELEGATION_POSITION[divisionIndex].includes(index) && divisionIndex !== 3 ? (
			<RelegationDecorator />
		) : (
			<></>
		)
	);
}
