import { useMSLState } from "@/modules/msl";
import { StatsCard as SharedStatsCard } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";

export const StatsCard = () => {
	const { selectedTeam } = useGlobalState();
	const { group, teamStats } = useMSLState();
	const isError = teamStats && teamStats.playedMatches > 0 ? false : true;
	const error = "Team stats don't appear until you play a match";

	return (
		<>
			{isError && (
				<SharedStatsCard
					stats={teamStats}
					selectedTeam={selectedTeam}
					isError={isError}
					error={error}
					contextText={group?.name}
				/>
			)}
			{!isError && <SharedStatsCard stats={teamStats} selectedTeam={selectedTeam} contextText={group?.name} />}
		</>
	);
};
