import { useIsMobile } from "@/layouts/context";
import { TrainingToken } from "@/modules/shared/components/tokens";
import { useBalanceContext } from "@/modules/shared/context";
import { Button, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssTrainingHeaderWrapper } from "./TrainingHeader.styled";

export interface TrainingHeaderProps {
	onTrainingHistoryClicked: () => void;
}

export const TrainingHeader: FC<TrainingHeaderProps> = ({ onTrainingHistoryClicked }) => {
	const { trainingTokens } = useBalanceContext();

	const isMobile = useIsMobile();

	return (
		<>
			<CssTrainingHeaderWrapper>
				{!isMobile && <Typography variant="h4">Training Plan</Typography>}
				{isMobile && <TrainingToken quantity={trainingTokens} />}
				<Button appearance="secondary" size="sm" label="Training History" onClick={onTrainingHistoryClicked} />
			</CssTrainingHeaderWrapper>
		</>
	);
};
