import { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle(
	({ theme }) => css`
		:root {
			--hover-translate-y: -0.125rem;
			--hover-background: ${theme.palette.greyscale.gondola};
			--hover-transition-duration: 150ms;
			--hover-transition-curve: cubic-bezier(0, 0, 0.2, 1);

			--bracket-connectors-horizontal-gap: 20px;
			--bracket-connectors-color: ${theme.palette.backgrounds.tundora};
		}

		/**
    	* Make some general resets to everything
    	*/
		* {
			padding: 0;
			margin: 0;
			box-sizing: border-box;
		}

		/**
	 	 * Style scrollbar
	 	 */
		*::-webkit-scrollbar-track {
			border-radius: 10px;
		}

		*::-webkit-scrollbar {
			width: 2px;
			background-color: transparent;
		}

		*::-webkit-scrollbar:vertical {
			width: 2px;
		}

		*::-webkit-scrollbar:horizontal {
			height: 2px;
		}

		*:hover::-webkit-scrollbar:horizontal {
			height: 2px;
		}

		*::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #555;
		}

		/**
		 * Material UI overrides
		 */
		.Alert {
			${theme.breakpoints.up("desktopMin")} {
				max-width: 512px;
			}
		}
	`
);
