import {
	CustomTournamentTeamInfoDTO,
	HttpClient,
	IdType,
	MetaSoccerId,
	TournamentGroupInfoDTO
} from "@metasoccer/metasoccer-types";

export class HomeService {
	constructor(private readonly httpService: HttpClient) {}

	async getGroupInfoByTeam(teamId: MetaSoccerId<IdType.Team>): Promise<TournamentGroupInfoDTO> {
		try {
			const { data } = await this.httpService.get<CustomTournamentTeamInfoDTO | undefined>(
				`/home/groupInfo/${teamId}`
			);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getGroupInfoOfTopMSL(): Promise<TournamentGroupInfoDTO> {
		try {
			const { data } = await this.httpService.get<CustomTournamentTeamInfoDTO | undefined>(`/home/groupInfo`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getTeamInfo(teamId: MetaSoccerId<IdType.Team>): Promise<CustomTournamentTeamInfoDTO | undefined> {
		try {
			const { data } = await this.httpService.get<CustomTournamentTeamInfoDTO | undefined>(
				`/home/teamInfo/${teamId}`
			);
			return data;
		} catch (err) {
			throw err;
		}
	}
}
