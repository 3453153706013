import { Row } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssHeader = styled(Row)(
	({ theme }) => css`
		align-items: center;
		justify-content: space-between;
		padding: 10px 12px;

		${theme.breakpoints.up("desktopMin")} {
			border-bottom: 1px solid ${theme.palette.greyscale.gondola};
			padding: 24px;
		}
	`
);

export const CssContent = styled.div(
	({ theme }) => css`
		padding: 10px 12px;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
		}
	`
);
