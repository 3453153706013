import { useServerRefresh } from "@/modules/shared/context";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Query, QueryOptions } from "./types";

export const useServerState = <S>(
	query: Query<S>,
	instanceOptions: QueryOptions = { enabled: true, refetchInterval: 5 * 60 * 1000 }
) => {
	const [serverRefresh, _, setServerRefreshToFalse] = useServerRefresh();

	const {
		queryKey,
		queryFn,
		options: {
			cacheTime = 60 * 60 * 1000,
			enabled = true,
			refetchInterval = 5 * 60 * 1000,
			refetchIntervalInBackground = true,
			refetchOnWindowFocus = false,
			retry = 1
		} = {}
	} = query;

	const q = useQuery<S>({
		queryKey,
		queryFn,
		cacheTime,
		enabled,
		refetchInterval,
		refetchIntervalInBackground,
		refetchOnWindowFocus,
		retry,
		...instanceOptions
	});

	useEffect(() => {
		if (serverRefresh) {
			q.refetch();
			setServerRefreshToFalse();
		}
	}, [serverRefresh]);

	return q;
};
