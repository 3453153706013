import styled, { css } from "styled-components";

export const ContentStyled = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		max-width: 70%;
		min-height: 240px;
		padding: 24px 0;

		${theme.breakpoints.up("desktopMin")} {
			min-height: unset;
			gap: 32px;
		}
	`
);

export const MSLIconWrapperStyled = styled.div`
	opacity: 0.3;
`;
