import styled, { css } from "styled-components";
import { Size } from "../../types";

export const STAR_OFFSET_SM = 3;
export const STAR_OFFSET_MD = 5;
export const STAR_SIZE_SM = 12;
export const STAR_SIZE_MD = 16;

export const StarsWrapperStyled = styled.div<{ size: Size }>(
	({ size }) => css`
		height: ${size === "sm" ? STAR_SIZE_SM : STAR_SIZE_MD}px;
		white-space: nowrap;
		width: ${size === "sm"
			? (STAR_SIZE_SM + 0.75) * 5 - STAR_OFFSET_SM * 4
			: (STAR_SIZE_MD + 1) * 5 - STAR_OFFSET_MD * 4}px;
	`
);

export const StarImageStyled = styled.img<{ size: Size }>(
	({ size }) => css`
		position: relative;
		width: ${size === "sm" ? STAR_SIZE_SM + 0.75 : STAR_SIZE_MD + 1}px;
		height: ${size === "sm" ? STAR_SIZE_SM : STAR_SIZE_MD}px;
	`
);
