import styled from "styled-components";

export const CssCancelDelegationWrapper = styled.div`
	display: flex;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;

	.cancelDelegationIcon {
		display: flex !important;
	}
`;
