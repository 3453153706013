import { Sort } from "@/modules/shared/helpers/sorting-comparators/Sort";

type ThingWithPotential = any & { potential: number };

export function makePlayersByPotentialComparator(
	sort: Sort = Sort.ASC,
	selectorA: (a: any) => number = (a) => a.potential,
	selectorB: (b: any) => number = (b) => b.potential
): (a: ThingWithPotential, b: ThingWithPotential) => number {
	return (a: ThingWithPotential, b: ThingWithPotential): number => {
		if (sort === Sort.ASC) {
			return selectorA(a) - selectorB(b);
		}

		return selectorB(b) - selectorA(a);
	};
}
