import { Row, Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { StarGroupWrapperStyled, WrapperStyled } from "./LineupQualityStars.styles";
import { StarGroup } from "./components/StarGroup";
import { getStarsByQualityAndPotential } from "./helpers/getStarsByPotential";
import { Position, Size } from "./types";

export interface LineupQualityStarsProps {
	potential?: number;
	quality: number;
	qualityPosition?: Position;
	size?: Size;
}

export const LineupQualityStars: FC<LineupQualityStarsProps> = ({
	potential,
	quality,
	qualityPosition = "center",
	size = "md"
}) => {
	const qualityLabelVariant = size === "sm" ? "caption" : "body2";

	const starQualityAndPotential = getStarsByQualityAndPotential(quality, potential ?? quality);

	const starUrls = Array.isArray(starQualityAndPotential)
		? starQualityAndPotential
		: typeof starQualityAndPotential === "string"
		? [starQualityAndPotential]
		: [];

	return (
		<WrapperStyled qualityPosition={qualityPosition}>
			<Row gap={8}>
				{qualityPosition !== "left" && qualityPosition !== "right" && (
					<Typography variant={qualityLabelVariant} light>
						Quality
					</Typography>
				)}
				<Typography variant={qualityLabelVariant}>{quality}</Typography>
			</Row>

			<StarGroupWrapperStyled qualityPosition={qualityPosition}>
				<StarGroup starsUrls={starUrls} size={size} />
			</StarGroupWrapperStyled>
		</WrapperStyled>
	);
};
