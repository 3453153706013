import { Sort } from "@/modules/shared/helpers/sorting-comparators/Sort";

type ThingWithCondition = any & { condition: number };

export function makePlayersByConditionComparator(
	sort: Sort = Sort.ASC,
	selectorA: (a: any) => number = (a) => a.condition,
	selectorB: (b: any) => number = (b) => b.condition
): (a: ThingWithCondition, b: ThingWithCondition) => number {
	return (a: ThingWithCondition, b: ThingWithCondition): number => {
		if (sort === Sort.ASC) {
			return selectorA(a) - selectorB(b);
		}

		return selectorB(b) - selectorA(a);
	};
}
