import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import { AppBar, Row } from "@peersyst/react-components";
import styled, { css } from "styled-components";
import { CoreButton } from "../../../Atoms/Button";
export var BaseTopbar = styled(AppBar)(function (_ref) {
  var theme = _ref.theme,
    showMobile = _ref.showMobile;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tbackground-color: ", ";\n\t\theight: 64px;\n\t\tz-index: 2;\n\t\t.logo g path:nth-child(2),\n\t\t.logo g path:nth-child(3) {\n\t\t\tfill: ", "!important;\n\t\t\tstroke: ", ";\n\t\t}\n\t\t.AppBarContent {\n\t\t\tbackground-image: none;\n\t\t\tbox-shadow: none;\n\t\t}\n\t\tborder-bottom: 1px solid ", ";\n\t\t@media (max-width: ", "px) {\n\t\t\tposition: fixed;\n\t\t\ttop: 0;\n\t\t\tleft: 0;\n\t\t\twidth: 100%;\n\t\t\theight: 64px;\n\t\t\t", "\n\t\t}\n\t"])), theme.palette.backgrounds.cinder, theme.palette.backgrounds.codGray, theme.palette.backgrounds.codGray, theme.palette.greyscale.gondola, theme.breakpoints.values.desktopMin - 1, showMobile === "coin" ? "\n                .ContainerTitle{\n                    display:none\n                }\n                .ContainerComponets{\n                    align-items: center;\n                    justify-content: center;\n                }\n                .ContainerCoin{\n                    flex: none;\n                    height: 2.5rem;\n                    border: 1px solid ".concat(theme.palette.greyscale.gondola, ";\n                    border-radius: 7.5rem;\n                    .Button.Secondary{\n                        border:none;\n                    }\n                    .Typography{\n                        line-height: 1.3rem;\n                    }\n                }\n            ") : "\n                .ContainerCoin{\n                    display:none\n                }\n                .ContainerTitle{\n                    flex:1;\n                    justify-content:center;\n                }\n            ");
});
export var InnerWrapper = styled(Row)(function (_ref2) {
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\talign-items: center;\n\t\theight: 100%;\n\t"])));
});
export var LogoButtonRoot = styled(CoreButton).attrs({
  appearance: "text"
})(function (_ref3) {
  var theme = _ref3.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\twidth: 5rem;\n\t\theight: 100%;\n\t\tbackground-color: ", ";\n\t\tpadding: 0rem 1rem;\n\t\tborder-left: 0.063rem solid ", ";\n\n\t\t@media (max-width: ", "px) {\n\t\t\tborder-bottom-color: ", ";\n\t\t\tborder-left: none;\n\t\t\theight: 64px;\n\t\t\twidth: 58px;\n\t\t}\n\t"])), theme.palette.backgrounds.cinder, theme.palette.greyscale.gondola, theme.breakpoints.values.desktopMin - 1, theme.palette.greyscale.gondola);
});
export var CssHoverMenu = styled("div")(function (_ref4) {
  var theme = _ref4.theme;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tmax-height: 64px;\n\n\t\t@media (max-width: ", "px) {\n\t\t\theight: 58px;\n\t\t\tsvg {\n\t\t\t\theight: 32px;\n\t\t\t\twidth: 32px;\n\t\t\t}\n\t\t}\n\t"])), theme.breakpoints.values.desktopMin - 1);
});
export var ContainerBadgeButton = styled("div")(function (_ref5) {
  var theme = _ref5.theme;
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\t\tborder-right: 0.063rem solid ", ";\n\t\t.Button,\n\t\t.Button.Xl {\n\t\t\theight: 64px;\n\t\t\twidth: 64px;\n\t\t\tborder: none;\n\n\t\t\tsvg {\n\t\t\t\theight: 40px;\n\t\t\t\twidth: 40px;\n\t\t\t}\n\t\t}\n\n\t\t@media (max-width: ", "px) {\n\t\t\tborder-right: none;\n\n\t\t\t.Button,\n\t\t\t.Button.Xl {\n\t\t\t\theight: 64px;\n\t\t\t\twidth: auto;\n\t\t\t\t&:hover {\n\t\t\t\t\tbackground: none;\n\t\t\t\t}\n\t\t\t\tsvg {\n\t\t\t\t\theight: 32px;\n\t\t\t\t\twidth: 32px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"])), theme.palette.greyscale.gondola, theme.breakpoints.values.desktopMin - 1);
});
export var ContainerCoinRow = styled("div")(function (_ref6) {
  var theme = _ref6.theme;
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tpadding: 0 24px;\n\t\t.Typography {\n\t\t\tline-height: 150%;\n\t\t}\n\t"])));
});
export var CssCoinComponentWrapper = styled("div")(function (_ref7) {
  var theme = _ref7.theme;
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\t\tborder-left: 1px solid ", ";\n\t\tborder-right: 1px solid ", ";\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\n\t\theight: 100%;\n\n\t\t@media (max-width: ", "px) {\n\t\t\tborder: none;\n\t\t\tpadding: 0px;\n\t\t}\n\t"])), theme.palette.greyscale.gondola, theme.palette.greyscale.gondola, theme.breakpoints.values.desktopMin - 1);
});
export var CssTopbarContentWrapper = styled("div")(function (_ref8) {
  var theme = _ref8.theme;
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex-grow: 1;\n\t\theight: 100%;\n\t\tjustify-content: center;\n\t\twidth: 0;\n\n\t\t", " {\n\t\t\tjustify-content: space-between;\n\t\t}\n\t"])), theme.breakpoints.up("desktopMin"));
});