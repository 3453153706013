import { Response, ResponseInterceptor } from "@metasoccer/metasoccer-types";
import { BackendResultError } from "@/modules/core/http/errors/BackendResultError";

export class ResponseResultInterceptor implements ResponseInterceptor {
	public onSuccess(response: Response): Response | Promise<Response> {
		const data = response.data;
		if (
			typeof data.success === "undefined" ||
			(typeof data.error === "undefined" && typeof data.result === "undefined")
		) {
			return response;
		}

		if (!data.success) {
			return Promise.reject(new BackendResultError(data.error));
		}

		return {
			...response,
			data: data.result
		};
	}

	public onError(error: any): any {
		return Promise.reject(error);
	}
}
