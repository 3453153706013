import styled, { css } from "styled-components";

export const ContainerStyled = styled.div<{
	background?: string;
	gap?: number;
	height?: number | string;
	overflowX?: "auto" | "hidden" | "visible";
	overflowY?: "auto" | "hidden" | "visible";
	paddingTop?: number;
	paddingX?: number;
	paddingY?: number;
}>(
	({ background, gap, height, overflowX, overflowY, paddingX, paddingY, theme }) => css`
		display: flex;
		background: ${background ?? "unset"};
		flex-direction: column;
		gap: ${gap ?? 0}px;
		height: ${height ?? "unset"};
		overflow-x: ${overflowX ?? "unset"};
		overflow-y: ${overflowY ?? "unset"};
		padding: ${paddingY ?? 12}px ${paddingX ?? 12}px ${paddingY ?? 12}px ${paddingX ?? 12}px;

		${theme.breakpoints.up("desktopMin")} {
			padding: ${paddingY ?? 24}px ${paddingX ?? 24}px;
		}
	`
);
