export function convertToAcronym(clubName: string): string {
	// Define common prefixes to ignore
	const commonPrefixes = ["FC", "AFC", "CF", "SC"];

	// Split the club name into words and filter out common prefixes
	const significantWords = clubName.split(" ").filter((word) => !commonPrefixes.includes(word.toUpperCase()));

	let acronym: string;
	if (significantWords.length >= 3) {
		// If there are three or more significant words, take the first letter of the first three
		acronym = significantWords
			.slice(0, 3)
			.map((word) => word[0])
			.join("");
	} else if (significantWords.length === 2) {
		// If there are two significant words, take the first letter of each and the first letter of the first word again
		acronym = `${significantWords[0][0]}${significantWords[1][0]}${significantWords[0][1]}`.substring(0, 3);
	} else {
		// If there is only one significant word, take the first three letters of it
		acronym = significantWords[0].substring(0, 3);
	}

	if (acronym.length === 3) {
		return acronym.toUpperCase();
	} else {
		return `${acronym}XXX`.slice(0, 3);
	}
}
