import styled, { css } from "styled-components";

export const CssTrainingContentWrapper = styled.div(
	({ theme }) => css`
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: ${theme.palette.backgrounds.codGray};
	`
);

export const CssTrainingContentListWrapper = styled.div(
	({ theme }) => css`
		height: 100%;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		gap: 16px;

		${theme.breakpoints.up("desktopMin")} {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: minmax(min-content, max-content);
			gap: 24px;
		}
	`
);

export const CssTrainingContentImage = styled.img`
	width: 100%;
	max-width: 616px;
`;
