import styled, { css } from "styled-components";
import { getDrillQualityBackgroundColor, getDrillQualityColor } from "./helpers";

export const CssDrillWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const CssInnerWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${(props) => props.theme.palette.backgrounds.codGray};
	cursor: pointer;
`;

export const CssDeleteWrapper = styled.div`
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 2;
	cursor: pointer;
`;

interface CssDrillImageWrapperProps {
	imageUrl: string;
}

export const CssDrillImageWrapper = styled.div<CssDrillImageWrapperProps>`
	width: 100%;
	height: 136px;
	background-color: ${({ theme }) => theme.palette.backgrounds.tundora};
	background-image: ${({ imageUrl }) => `url("${imageUrl}")`};
	background-size: 496px 136px;
	background-repeat: no-repeat;
	background-position: top center;
`;

export const CssDrillChip = styled.div`
	position: absolute;
	top: 12px;
	left: 12px;
`;

interface CssDrillTagProps {
	quality: string;
}

export const CssDrillTag = styled.div<CssDrillTagProps>(
	({ theme, quality }) => css`
		position: absolute;
		top: 124px;
		right: 12px;
		padding: 6px;
		color: ${getDrillQualityColor(quality, theme)};
		background: ${getDrillQualityBackgroundColor(quality)};
	`
);
