import { IdType } from "@/id/IdType";

export type MetaSoccerId<T extends IdType> = `${T}:${string}`;

//This gives us ~1 thousand years at a rythm of 1KIds/hour
const minMetasoccerIdLength = 12;

//This allows us to to have ids that happen to be wallets (i.e. default team ids)
const maxMetasoccerIdLength = 44;

export const isMetaSoccerId = <T extends IdType>(value: string, type: T): value is MetaSoccerId<T> => {
	const index = value.indexOf(":");
	const [typeCode, tail] = [value.slice(0, index), value.slice(index + 1)];

	if (tail.length < minMetasoccerIdLength || tail.length > maxMetasoccerIdLength) {
		return false;
	}

	return typeCode === type;
};
