import { useServerState } from "@/modules/core/react-query/hooks";
import { useGlobalState } from "@/modules/shared/context";
import { TOURNAMENT_QUERIES } from "@/modules/shared/queries";
import { TournamentEditionOfInterest } from "@metasoccer/metasoccer-types";
import { useInterval } from "react-use";
import { getTimeIntegersFromMilliseconds } from "../../tournament/helpers/getTimeIntegersFromMilliseconds";

const refetchInterval = 60000;

interface TournamentDates {
	isOpen: boolean;
	isLive: boolean;
	isFinished: boolean;
	isStarted: boolean;
	startsIn: string;
	endsIn: string;
	startsInTimestamp: number;
	endsInTimestamp: number;
}

export interface UseTournamentData extends TournamentEditionOfInterest, TournamentDates {}

export const useTournament = () => {
	const { selectedTeam } = useGlobalState();

	const query = TOURNAMENT_QUERIES.editionOfInterest(selectedTeam?.id);
	const {
		data: editionOfInterest = null,
		isLoading,
		isError,
		isRefetching,
		isSuccess,
		refetch
	} = useServerState(query, { refetchInterval });
	const dates = getDates();
	const data = getData();

	useInterval(refetch, refetchInterval);

	function getDates(): TournamentDates | null {
		if (!editionOfInterest) {
			return null;
		}

		const now = Date.now();
		const { startDate, endDate, openDate } = editionOfInterest.schedule;
		const isLive = startDate <= now && endDate >= now;
		const isFinished = endDate < now;
		const isStarted = startDate < now;
		const isOpen = now > openDate;
		const startsInTimestamp = (!isLive && startDate - now) || 0;
		const endsInTimestamp = endDate - now;
		const startsIn = getTimeIntegersFromMilliseconds(startsInTimestamp);
		const endsIn = getTimeIntegersFromMilliseconds(endsInTimestamp);

		return {
			isOpen,
			isLive,
			isStarted,
			isFinished,
			startsIn,
			endsIn,
			startsInTimestamp,
			endsInTimestamp
		};
	}

	function getData(): UseTournamentData | null {
		if (!editionOfInterest || !dates) {
			return null;
		}

		return {
			...editionOfInterest,
			...dates
		};
	}

	return {
		data,
		isLoading,
		isError,
		isSuccess,
		isRefetching
	};
};
