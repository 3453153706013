import styled from "styled-components";

export const CssPlayerContentBottomPart = styled.div`
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
	height: 40px;
	justify-content: space-between;
	padding: 8px 0;
	width: 100%;
`;

export const CssPlayerConditionsWrapper = styled.div`
	display: flex;
	padding-right: 10px;
	gap: 14px;
`;
