import styled, { css } from "styled-components";

export const CssTrainingFooterWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: 16px;
		padding: 16px 12px;
		border-top: 0.063rem solid ${(props) => props.theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			justify-content: end;
			padding: 24px;
		}
	`
);
