import styled from "styled-components";

export const CssContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	overflow-y: auto;
	padding-bottom: 24px;
	height: 100%;
`;

export const CssContentRow = styled.div`
	display: flex;
	justify-content: center;
	padding: 20px 32px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
	cursor: pointer;
`;
