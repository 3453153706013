import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import { AppBar, Col } from "@peersyst/react-components";
import styled, { css } from "styled-components";
var horizontalStyles = css(function (_ref) {
  var theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tpadding: 0.5rem 0;\n\t\tborder-top: 0.063rem solid ", ";\n\t"])), theme.palette.greyscale.gondola);
});
var verticalStyles = css(function (_ref2) {
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tpadding: 16px 0;\n\t\tmax-width: 65px;\n\t\tborder-right: 0.063rem solid ", ";\n\t"])), theme.palette.greyscale.gondola);
});
var navbarStyles = {
  horizontal: horizontalStyles,
  vertical: verticalStyles
};
export var NavBarBase = styled(AppBar)(function (_ref3) {
  var theme = _ref3.theme,
    direction = _ref3.direction;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\t", "\n\t\tbackground-color: ", ";\n\t\tz-index: 2;\n\t\t.AppBarContent {\n\t\t\tbackground-image: none;\n\t\t}\n\n\t\t@media (max-width: ", "px) {\n\t\t\tposition: fixed;\n\t\t\tbottom: 0;\n\t\t\tleft: 0;\n\t\t\twidth: 100%;\n\t\t\tpadding: 0;\n\t\t\theight: 64px;\n\t\t}\n\t"])), navbarStyles[direction], theme.palette.backgrounds.cinder, theme.breakpoints.values.desktopMin - 1);
});
export var ContainerSidebar = styled(Col)(function (_ref4) {
  var direction = _ref4.direction,
    theme = _ref4.theme;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\tflex-direction: ", ";\n\t\tgap: ", ";\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n\n\t\t@media (max-width: ", "px) {\n\t\t\tflex-direction: row;\n\t\t\tgap: 0;\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t}\n\t"])), direction === "vertical" ? "column" : "row", direction === "vertical" ? "1.25rem" : "0rem", theme.breakpoints.values.desktopMin - 1);
});