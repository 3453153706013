import { AxiosHttpClient } from "@/modules/core/http";

const DATABASE_ID = "d8ab323e594a48059deacfc2c5c5945f";

interface DatabasePage {
	id: string;
	properties: Record<
		string,
		{
			id: string;
			type: string;
		} & any
	>;
}

interface PageDetails {
	data: string;
	pageId: string;
	subject: string;
}

export class MaintenanceService {
	constructor(private readonly notionToMdClient = new AxiosHttpClient({ baseUrl: "https://md.metasoccer.com" })) {}

	async getMaintenanceLog(): Promise<DatabasePage[]> {
		try {
			const { data } = await this.notionToMdClient.get<DatabasePage[]>(`/database?database_id=${DATABASE_ID}`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getMessage(messageId: string): Promise<PageDetails> {
		try {
			const { data } = await this.notionToMdClient.get<PageDetails>(`/json?page_id=${messageId}&html=true`);
			return data;
		} catch (err) {
			throw err;
		}
	}
}
