import { useIsMobile } from "@/layouts/context";
import { Modal, Tab, TabGroup, Tabs } from "@metasoccer/metasoccer-ds";
import { InjuredLockedPlayer, NextPvpScheduledMatch } from "@metasoccer/metasoccer-types";
import { FC, useState } from "react";
import { CssModalContentWrapper, CssPrepareMatchModalWrapper, CssTabsWrapper } from "./PrepareMatchModal.styled";
import { MatchPreparation } from "./components/MatchPreparation";
import { MatchSquads } from "./components/MatchSquads";

export interface PrepareMatchModalProps {
	isOpen: boolean;
	onClose: () => void;
	nextMatch?: NextPvpScheduledMatch;
	injuredPlayers?: InjuredLockedPlayer[];
}

enum PrepareMatchTab {
	PREPARATION = 0,
	SQUADS = 1
}

export const PrepareMatchModal: FC<PrepareMatchModalProps> = ({ isOpen, onClose, nextMatch, injuredPlayers }) => {
	const [activeTab, setActiveTab] = useState<PrepareMatchTab>(PrepareMatchTab.PREPARATION);
	const isMobile = useIsMobile();
	return (
		<Modal isOpen={isOpen} onClose={onClose} title={"Prepare Match"} size="xl" fullScreen={isMobile}>
			<CssPrepareMatchModalWrapper>
				<CssTabsWrapper>
					<Tabs
						index={activeTab}
						onIndexChange={(tab: PrepareMatchTab) => {
							setActiveTab(tab);
						}}>
						<TabGroup>
							<Tab index={PrepareMatchTab.PREPARATION}>Preparation</Tab>
							<Tab index={PrepareMatchTab.SQUADS}>Squads</Tab>
						</TabGroup>
					</Tabs>
				</CssTabsWrapper>
				<CssModalContentWrapper>
					{activeTab === PrepareMatchTab.PREPARATION && nextMatch && (
						<MatchPreparation nextMatch={nextMatch} />
					)}
					{activeTab === PrepareMatchTab.SQUADS && nextMatch && (
						<MatchSquads
							home={nextMatch.teams.home}
							away={nextMatch.teams.away}
							date={nextMatch.match.date}
							notices={nextMatch.notices}
							injuredPlayers={injuredPlayers}
						/>
					)}
				</CssModalContentWrapper>
			</CssPrepareMatchModalWrapper>
		</Modal>
	);
};
