export interface SplitPayloadInChunksOptions {
	chunkSize: number;
	payload: Record<string, unknown> | undefined;
}

export interface SplitPayloadInChunks {
	(options: SplitPayloadInChunksOptions): string[] | null;
}

export const splitPayloadInChunks: SplitPayloadInChunks = (options) => {
	const { chunkSize, payload } = options;
	const chunks: string[] = [];
	const stringifiedObject = JSON.stringify(payload);
	const iterations = Math.ceil(stringifiedObject.length / chunkSize);

	if (chunkSize < 1) {
		return null;
	}

	for (let i = 0; i < iterations; i++) {
		const start = i * chunkSize;
		const end = start + chunkSize;

		const chunk = stringifiedObject.slice(start, end);

		chunks.push(chunk);
	}

	return chunks;
};
