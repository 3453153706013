import { LinedPlayer, LineupWithSkill, Player } from "@metasoccer/metasoccer-types";

export const getPlayerBySlot = (lineupWithSkill: LineupWithSkill, slotIndex: number): Player | null => {
	let player = null;
	lineupWithSkill.lineup.linedPlayers.forEach((linedPlayer: LinedPlayer) => {
		if (linedPlayer.slotId === slotIndex) {
			player = linedPlayer.player;
		}
	});

	return player;
};
