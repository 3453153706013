import { NextMatchPreview } from "@/modules/shared/components/NextMatchPreview";
import styled, { css } from "styled-components";

export const CssMatchSquadsWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`
);

export const CssLastLineupsWrapper = styled.div(
	({ theme }) => css`
		padding: 12px 12px 0 12px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 24px;
		border-top: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
		}
	`
);

export const CssLineupTablesWrapper = styled.div`
	width: 100%;
	display: flex;
	gap: 24px;
`;

export const CssToggleButtonWrapper = styled.div`
	display: flex;
	width: 100%;
`;

export const CssMobileLineupWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 12px;

		${theme.breakpoints.up("desktopMin")} {
			gap: 24px;
		}
	`
);

export const NextMatchPreviewStyled = styled(NextMatchPreview)(
	({ theme }) => css`
		padding: 40px 0;
	`
);
