import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssPrizePoolWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		padding: 24px 12px;
		max-height: 600px;

		${theme.breakpoints.up("desktopMin")} {
			flex: 1 1 0%;
			padding: 24px;
		}
	`
);

export const CssTitle = styled(Typography)`
	min-height: 22px;
`;
