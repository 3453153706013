import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const TypographyStyled = styled(Typography)(
	({ theme }) => css`
		& p {
			margin-bottom: 12px;
		}

		& p:last-child {
			margin-bottom: 0;
		}
	`
);
