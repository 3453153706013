import { useIsMobile } from "@/layouts/context";
import { getPlayerBySlot, getPlayerSkillBySlot } from "@/modules/lineup/helpers";
import { getImgUrl } from "@/modules/shared/utils";
import {
	FormationSlot,
	LineupWithSkill,
	LockedPlayer,
	Player,
	PlayerShirtNumberDict
} from "@metasoccer/metasoccer-types";
import { memo, useLayoutEffect, useRef, useState } from "react";
import { LineupPlayer } from "../LineupPlayer";
import { CssPitch, CssWrapper, CssWrapperContent } from "./LineupOnThePitch.styles";

interface LineupOnThePitchProps {
	variant?: "default" | "small";
	lineupWithSkill: LineupWithSkill;
	lockedPlayers?: LockedPlayer[];
	playersIdsOff?: number[];
	shirtNumbers: PlayerShirtNumberDict;
	onPlayerSelected: (player: Player, slotIndex: number) => void;
}

export const LineupOnThePitch = memo<LineupOnThePitchProps>(
	({ variant = "default", lineupWithSkill, playersIdsOff = [], shirtNumbers, onPlayerSelected, lockedPlayers }) => {
		const wrapperRef = useRef<HTMLDivElement>(null);

		const [width, setWidth] = useState(0);

		useLayoutEffect(() => {
			setWidth(wrapperRef?.current?.clientWidth ?? 0);
		}, [wrapperRef?.current]);

		const isMobile = useIsMobile();
		const isSmall = isMobile || variant === "small";

		const background = getImgUrl(
			`https://assets.metasoccer.com/ui/lineup/${isSmall ? "Field-Mobile.svg" : "Field.svg"}`
		);
		const pitchAspectRatio = isSmall ? 490 / 373 : 239 / 313;

		return (
			<CssWrapper ref={wrapperRef} background={background}>
				<CssWrapperContent aspectRatio={pitchAspectRatio}>
					<CssPitch>
						{lineupWithSkill.lineup.formation.slots.map((slot: FormationSlot, index: number) => {
							const player = getPlayerBySlot(lineupWithSkill, index) ?? undefined;
							const playerSkill = getPlayerSkillBySlot(lineupWithSkill, index) ?? undefined;

							return (
								<LineupPlayer
									key={index}
									disabled={player && playersIdsOff.includes(player.id)}
									lineupWithSkill={lineupWithSkill}
									lockedPlayers={lockedPlayers}
									parentWidth={width}
									parentHeight={width * pitchAspectRatio}
									player={player}
									playerSkill={playerSkill}
									shirtNumber={player ? shirtNumbers[player.id] : undefined}
									showPlayerSpecificRole={false}
									showSlotId={false}
									slot={{
										...slot,
										position: { ...slot.position, x: 0.125 + slot.position.x }
									}}
									slotId={`L${index + 1}`}
									onPlayerSelected={(selected) => onPlayerSelected(selected, index)}
								/>
							);
						})}
					</CssPitch>
				</CssWrapperContent>
			</CssWrapper>
		);
	}
);
