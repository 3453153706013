import React from "react";
import { GlobalContext } from "../GlobalContext";

const useGlobalState = () => {
	const context = React.useContext(GlobalContext);
	if (context === undefined) {
		throw new Error("useGlobalState must be used within a GlobalContextProvider");
	}
	return context.globalState;
};

const useSetGlobalState = () => {
	const context = React.useContext(GlobalContext);
	if (context === undefined) {
		throw new Error("useGlobalState must be used within a GlobalContextProvider");
	}
	return context.setGlobalState;
};

const useServerRefresh = () => {
	const context = React.useContext(GlobalContext);
	if (context === undefined) {
		throw new Error("useServerRefresh must be used within a GlobalContextProvider");
	}
	return [
		context.globalState.serverRefresh,
		() => context.setGlobalState((prev) => ({ ...prev, serverRefresh: true })),
		() => context.setGlobalState((prev) => ({ ...prev, serverRefresh: false }))
	] as [boolean, () => void, () => void];
};

export { useGlobalState, useServerRefresh, useSetGlobalState };
