import { persistentStorageService, volatileStorageService } from "@/dependencies";
import { getAuthKey } from "@/modules/core/auth";
import { VolatileStorageKeys } from "@/modules/shared/types";
import { RequestInterceptor } from "@metasoccer/metasoccer-types";
import { AxiosRequestConfig } from "axios";

export class RequestAuthenticationInterceptor implements RequestInterceptor {
	public onSuccess(config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> {
		const walletAddress = volatileStorageService.getString(VolatileStorageKeys.USER_WALLET_ADDRESS);

		if (walletAddress === null) {
			return config;
		}

		const authTokenKey = getAuthKey(walletAddress);
		const authToken = persistentStorageService.getString(authTokenKey);

		if (authToken === null) {
			return config;
		}

		config.headers = config.headers ?? {};
		config.headers["Authorization"] = `Bearer ${authToken}`;

		return config;
	}

	public onError(error: any): any {
		return Promise.reject(error);
	}
}
