import { Col, Tooltip, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { useTheme } from "styled-components";
import { WrapperStyled } from "./AlphaDisclaimer.styles";

export const AlphaDisclaimer = () => {
	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;

	const theme = useTheme();

	if (isPlayToAirdropEnabled) {
		return (
			<WrapperStyled>
				<Typography
					variant="caption"
					color={theme.palette.system.cornflowerBlue}
					textAlign="center"
					style={{ opacity: 0.42 }}>
					AIRDROP VERSION
				</Typography>
			</WrapperStyled>
		);
	}

	return (
		<WrapperStyled>
			<Tooltip position="rightEnd" maxWidth="240px" hoverOnPopper>
				{{
					popper: (
						<Col gap={4}>
							<Typography variant="captionBold">Alpha version</Typography>

							<Typography variant="description" light>
								MetaSoccer is in alpha release. We are actively working on developing more features, and
								you may encounter occasional issues.
							</Typography>

							<Typography variant="description" light>
								Is there a feature you'd love to see in MetaSoccer?{" "}
								<a href="https://metasoccer.com/request-feature" target="_blank">
									Let us know!
								</a>
							</Typography>
						</Col>
					),
					content: (
						<Typography
							variant="caption"
							color={theme.palette.system.cornflowerBlue}
							textAlign="center"
							style={{ opacity: 0.42 }}>
							ALPHA VERSION
						</Typography>
					)
				}}
			</Tooltip>
		</WrapperStyled>
	);
};
