import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
import { ProgressBar, Typography } from "@peersyst/react-components";
import styled, { css } from "styled-components";
export var Progress = styled("div")(function (_ref) {
  var theme = _ref.theme,
    type = _ref.type,
    width = _ref.width,
    color = _ref.color;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\tbackground: ", ";\n\t\theight: 0.25rem;\n\t\twidth: ", "%;\n\t"])), color ? color : type === "first" ? theme.palette.system.perfume : "linear-gradient(0deg, rgba(253, 247, 100, 0.6), rgba(253, 247, 100, 0.6)), #06060A", width);
});
var primaryStyles = css(function (_ref2) {
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tcolor: ", ";\n\t"])), theme.palette.greyscale.white);
});
var opacityStyles = css(function (_ref3) {
  var theme = _ref3.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\tcolor: ", ";\n\t\tfont-size: 0.75rem;\n\t"])), theme.palette.greyscale.gray);
});
var secondatyStyles = css(function (_ref4) {
  var _theme$fonts;
  var theme = _ref4.theme;
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\tfont-family: ", ";\n\t\tfont-size: 1.125rem;\n\t"])), (_theme$fonts = theme.fonts) == null ? void 0 : _theme$fonts.pfdInt);
});
var secondatyColorStyles = css(function (_ref5) {
  var theme = _ref5.theme;
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\t\tcolor: ", ";\n\t\tfont-size: 1.125rem;\n\t"])), theme.palette.system.viking);
});
var incrementStyles = css(function (_ref6) {
  var theme = _ref6.theme;
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\t\tcolor: ", ";\n\t\tfont-size: 0.75rem;\n\t"])), theme.palette.system.pastelGreen);
});
var textStyles = {
  primary: primaryStyles,
  secondary: secondatyStyles,
  "secondary-color": secondatyColorStyles,
  increment: incrementStyles,
  opacity: opacityStyles
};
export var TypographyBar = styled(Typography)(function (_ref7) {
  var _theme$fonts2;
  var theme = _ref7.theme,
    type = _ref7.type;
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\t\tfont-size: 0.875rem;\n\t\tfont-family: ", ";\n\t\t", ";\n\t"])), (_theme$fonts2 = theme.fonts) == null ? void 0 : _theme$fonts2.spaceGrotesk, textStyles[type]);
});
export var TypographyPotential = styled(Typography)(function (_ref8) {
  var _theme$fonts3;
  var theme = _ref8.theme;
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\t\tfont-size: 1rem;\n\t\tfont-family: ", ";\n\t\tline-height: 1.5rem;\n\t\tcolor: ", ";\n\t"])), (_theme$fonts3 = theme.fonts) == null ? void 0 : _theme$fonts3.pfdInt, theme.palette.greyscale.gray);
});
export var BarBase = styled(ProgressBar)(function (_ref9) {
  var theme = _ref9.theme,
    type = _ref9.type,
    increment = _ref9.increment,
    value = _ref9.value,
    color = _ref9.color;
  return css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n\t\t.ProgressBarTrack {\n\t\t\tbackground: ", ";\n\t\t\t.ProgressBarTrack {\n\t\t\t\tbackground: ", ";\n\t\t\t}\n\t\t}\n\t\t", "\n\n\t\tbackground-color: transparent;\n\t\tflex-grow: 1;\n\t\theight: 0.25rem;\n\t\twidth: 0;\n\t"])), theme.palette.backgrounds.tundora, type === "special" ? theme.palette.system.viking : color ? color : theme.palette.greyscale.white, increment ? "&.ProgressBar > .ProgressBarTrack::after{\n            content: \"\";\n            position: absolute;\n            width: ".concat(increment, "%;\n            height: 100%;\n            background: ").concat(theme.palette.system.pastelGreen, ";\n            left: ").concat(value - increment, "%;\n            transform: translateX(100%);\n        }") : "");
});