import { ClickAwayListener, DownIcon, Typography, UpIcon, useTheme } from "@metasoccer/metasoccer-ds";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { CssDropdownContent, CssDropdownWrapper, CssOptionsWrapper } from "./Dropdown.styled";

const DROPDOWN_HEIGHT = 40;
const DROPDOWN_GAP = 0;

export type DropdownVariant = "form" | "menu";
interface DropdownProps {
	variant?: DropdownVariant;
	children: any;
	className?: string;
	height?: string;
	placeholder?: string;
	selectedValue?: string;
	renderSelectedValue?: (value: string) => ReactNode;
	onClick?: () => void;
}

export const Dropdown: FC<DropdownProps> = ({
	variant = "menu",
	children,
	className,
	height = `${DROPDOWN_HEIGHT}px`,
	placeholder = "Option",
	selectedValue,
	renderSelectedValue,
	onClick
}) => {
	const theme = useTheme();
	const componentRef = useRef<HTMLDivElement>(null);
	const componentBoundingRect = componentRef?.current?.getBoundingClientRect();

	const [contentLeft, setContentLeft] = useState(0);
	const [contentTop, setContentTop] = useState(0);
	const [maxHeight, setMaxHeight] = useState<number>();
	const [maxWidth, setMaxWidth] = useState<number>();
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = useCallback(() => {
		if (onClick) onClick();
		setIsOpen(!isOpen);
	}, [isOpen, onClick]);

	useEffect(() => {
		if (!componentBoundingRect) return;

		const top = componentBoundingRect.top + DROPDOWN_HEIGHT + DROPDOWN_GAP;
		setContentLeft(componentBoundingRect.left);
		setContentTop(top);
		setMaxHeight(window.innerHeight - top);
		setMaxWidth(componentBoundingRect.width);
	}, [componentBoundingRect]);

	const font = variant == "menu" ? "buttonMd" : "body2";
	const fontColor = variant == "menu" ? "none" : theme.palette.greyscale.gray;

	return (
		<ClickAwayListener onClickAway={() => setIsOpen(false)}>
			<CssDropdownWrapper className={className} onClick={toggleDropdown} ref={componentRef} style={{ height }}>
				<CssDropdownContent variant={variant}>
					{selectedValue && renderSelectedValue ? (
						renderSelectedValue(selectedValue)
					) : (
						<Typography variant={font} style={{ color: fontColor }}>
							{selectedValue ?? placeholder}
						</Typography>
					)}
					{isOpen ? <UpIcon size="sm" /> : <DownIcon size="sm" />}
				</CssDropdownContent>
				<CssOptionsWrapper
					variant={variant}
					isOpen={isOpen}
					children={children}
					fullWidth
					maxHeight={maxHeight}
					maxWidth={maxWidth}
					positionLeft={contentLeft}
					positionTop={contentTop}
				/>
			</CssDropdownWrapper>
		</ClickAwayListener>
	);
};
