import { PveDifficulty } from "@metasoccer/metasoccer-types";

export * from "./BaseServerStateKeys";
export * from "./TokensAddress";
export * from "./CustomTournamentsTabs";
export * from "./Divisions";

export const SELECTED_TEAM_STORAGE_KEY = "METASOCCER_TEAMID";
export const REAL_LIFE_SECONDS_PER_IN_GAME_MINUTES = 3;
export const MATCH_DURATION_MINUTES_REAL_LIFE = 90;
export const MATCH_DURATION_SECONDS_REAL_LIFE = MATCH_DURATION_MINUTES_REAL_LIFE * 60;
export const PVE_TEAM_SUFFIX_BY_DIFFICULTY: Record<PveDifficulty, string> = {
	[PveDifficulty.STARTER]: "#1",
	[PveDifficulty.AMATEUR]: "#2",
	[PveDifficulty.BEGINNER]: "#3",
	[PveDifficulty.SEMI_PRO]: "#4",
	[PveDifficulty.PRO]: "#5",
	[PveDifficulty.LEGEND]: "#6"
};

export const MATCH_SIMULATION_TIME_SECONDS = MATCH_DURATION_MINUTES_REAL_LIFE * REAL_LIFE_SECONDS_PER_IN_GAME_MINUTES;
export const MATCH_SIMULATION_TIME_MS = MATCH_SIMULATION_TIME_SECONDS * 1000;
export const SIMULATION_SECONDS_PER_REAL_LIFE_SECOND = MATCH_DURATION_SECONDS_REAL_LIFE / MATCH_SIMULATION_TIME_SECONDS;
