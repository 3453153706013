import { Typography } from "@metasoccer/metasoccer-ds";
import { FC } from "react";
import { CssStatWrapper } from "./Stat.styled";

interface StatProps {
	title: string;
	value?: string;
}

export const Stat: FC<StatProps> = ({ title, value }) => {
	const label = value ?? "-";

	return (
		<CssStatWrapper>
			<Typography variant="caption" light>
				{title}
			</Typography>
			<Typography variant="subtitle1">{label}</Typography>
		</CssStatWrapper>
	);
};
