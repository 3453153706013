import { configService } from "@/dependencies";
import { HomeButton } from "@/modules/home/components";
import { MATCH_ROUTES } from "@/modules/match/match.routes";
import { MourinhoMatch } from "@/modules/match/types/MourinhoMatch";
import { NextMatchPreview } from "@/modules/shared/components";
import { formatSecondsToMinutes } from "@/modules/shared/helpers";
import { ArrowRightIcon, Row, Typography } from "@metasoccer/metasoccer-ds";
import { MatchedTeam } from "@metasoccer/metasoccer-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentStyled, HeaderStyled } from "./LiveMatch.styles";

interface LiveMatchProps {
	height?: number;
	match: MourinhoMatch;
}

export const LiveMatch = memo<LiveMatchProps>(({ height, match }) => {
	const [matchTime, setMatchTime] = useState<[string, string]>(["00", "00"]);

	useEffect(() => {
		const matchSteps = configService.get().playMourinhoSteps;
		const secondsElapsed = ((match.step * 90) / matchSteps) * 60;
		const [minutes, seconds] = formatSecondsToMinutes(secondsElapsed).split(":");
		setMatchTime([minutes, seconds]);
	}, [match.step]);

	const buildTeamInfo = useCallback(
		(matchTeam: MatchedTeam) => ({
			teamId: matchTeam.id,
			teamName: matchTeam.name,
			teamAcronym: matchTeam.clubAcronym,
			clubLogo: matchTeam.clubLogo,
			overall: matchTeam.lineupOverall,
			// empty data
			attackZones: {
				left: 0,
				center: 0,
				right: 0
			},
			avgGoals: 0,
			avgGoalsAgainst: 0,
			avgPossession: 0,
			avgShotsOnTarget: 0,
			lastLinedPlayers: [],
			registeredNonLinedPlayers: [],
			streak: [],
			streakText: "",
			winRate: 0
		}),
		[]
	);

	const navigate = useNavigate();

	return (
		<>
			<HomeButton
				height={height ?? 332}
				width="100%"
				onClick={() => navigate(MATCH_ROUTES.ROOT.buildPath(match.id))}>
				<ContentStyled>
					<HeaderStyled>
						<Typography variant="h5">Live Match</Typography>
					</HeaderStyled>
					<NextMatchPreview
						awayTeamInfo={buildTeamInfo(match.rightTeamInfo)}
						direction="column"
						homeTeamInfo={buildTeamInfo(match.leftTeamInfo)}
						liveMatch={true}
						matchDate={new Date(match.startedDatetime!).getTime()}
						matchScore={match.score}
						matchTime={matchTime}
						notices={[]}
						showNotices
						size="md"
					/>
					<Row gap={4}>
						<Typography variant="buttonMd">WATCH</Typography>
						<ArrowRightIcon size="sm" />
					</Row>
				</ContentStyled>
			</HomeButton>
		</>
	);
});
