export const MetaSoccerTournamentsV2 = {
  "name": "MetaSoccerTournamentsV2",
  "address": "0x83cf3694b3166B13882113aD370834286e7b7C19",
  "abi": [
    {
      "type": "constructor",
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_beneficiary"
        },
        {
          "type": "uint8",
          "name": "_beneficiaryFeePct"
        },
        {
          "type": "uint8",
          "name": "_creatorFeePct"
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Paused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleAdminChanged",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "previousAdminRole",
          "indexed": true
        },
        {
          "type": "bytes32",
          "name": "newAdminRole",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleGranted",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "RoleRevoked",
      "inputs": [
        {
          "type": "bytes32",
          "name": "role",
          "indexed": true
        },
        {
          "type": "address",
          "name": "account",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "TeamRegistered",
      "inputs": [
        {
          "type": "bytes32",
          "name": "teamId",
          "indexed": true
        },
        {
          "type": "tuple",
          "name": "tournament",
          "indexed": false,
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "address",
              "name": "paymentToken"
            },
            {
              "type": "address",
              "name": "creator"
            },
            {
              "type": "uint256",
              "name": "entryFee"
            },
            {
              "type": "uint256",
              "name": "registrationCloseTimestamp"
            },
            {
              "type": "uint8",
              "name": "numberOfTeams"
            },
            {
              "type": "bool",
              "name": "refunded"
            },
            {
              "type": "bool",
              "name": "rewardsClaimed"
            }
          ]
        },
        {
          "type": "tuple",
          "name": "registration",
          "indexed": false,
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "bytes32",
              "name": "teamId"
            },
            {
              "type": "string",
              "name": "registeredPlayerIds"
            },
            {
              "type": "address",
              "name": "owner"
            },
            {
              "type": "address",
              "name": "manager"
            },
            {
              "type": "address",
              "name": "sender"
            },
            {
              "type": "uint8",
              "name": "rewardsSplitPct"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "TournamentRefunded",
      "inputs": [
        {
          "type": "bytes32",
          "name": "tournamentId",
          "indexed": true
        },
        {
          "type": "tuple",
          "name": "tournament",
          "indexed": false,
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "address",
              "name": "paymentToken"
            },
            {
              "type": "address",
              "name": "creator"
            },
            {
              "type": "uint256",
              "name": "entryFee"
            },
            {
              "type": "uint256",
              "name": "registrationCloseTimestamp"
            },
            {
              "type": "uint8",
              "name": "numberOfTeams"
            },
            {
              "type": "bool",
              "name": "refunded"
            },
            {
              "type": "bool",
              "name": "rewardsClaimed"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "TournamentRewardsClaimed",
      "inputs": [
        {
          "type": "bytes32",
          "name": "tournamentId",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "totalPrizeAmount",
          "indexed": false
        },
        {
          "type": "tuple",
          "name": "tournament",
          "indexed": false,
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "address",
              "name": "paymentToken"
            },
            {
              "type": "address",
              "name": "creator"
            },
            {
              "type": "uint256",
              "name": "entryFee"
            },
            {
              "type": "uint256",
              "name": "registrationCloseTimestamp"
            },
            {
              "type": "uint8",
              "name": "numberOfTeams"
            },
            {
              "type": "bool",
              "name": "refunded"
            },
            {
              "type": "bool",
              "name": "rewardsClaimed"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "TournamentSponsored",
      "inputs": [
        {
          "type": "bytes32",
          "name": "tournamentId",
          "indexed": true
        },
        {
          "type": "address",
          "name": "sender",
          "indexed": true
        },
        {
          "type": "uint256",
          "name": "amount",
          "indexed": false
        },
        {
          "type": "tuple",
          "name": "tournament",
          "indexed": false,
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "address",
              "name": "paymentToken"
            },
            {
              "type": "address",
              "name": "creator"
            },
            {
              "type": "uint256",
              "name": "entryFee"
            },
            {
              "type": "uint256",
              "name": "registrationCloseTimestamp"
            },
            {
              "type": "uint8",
              "name": "numberOfTeams"
            },
            {
              "type": "bool",
              "name": "refunded"
            },
            {
              "type": "bool",
              "name": "rewardsClaimed"
            }
          ]
        }
      ]
    },
    {
      "type": "event",
      "anonymous": false,
      "name": "Unpaused",
      "inputs": [
        {
          "type": "address",
          "name": "account",
          "indexed": false
        }
      ]
    },
    {
      "type": "function",
      "name": "DEFAULT_ADMIN_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "PAUSER_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "SIGN_REGISTRATION_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "SIGN_REWARDS_ROLE",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "beneficiary",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "beneficiaryFeePct",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint8"
        }
      ]
    },
    {
      "type": "function",
      "name": "claimEntryFees",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "claimRewards",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        },
        {
          "type": "bytes32[]",
          "name": "_teamIds"
        },
        {
          "type": "uint8[]",
          "name": "_prizePcts"
        },
        {
          "type": "bytes",
          "name": "_signature"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "creatorFeePct",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "uint8"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRegisteredTeams",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        }
      ],
      "outputs": [
        {
          "type": "tuple[]",
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "bytes32",
              "name": "teamId"
            },
            {
              "type": "string",
              "name": "registeredPlayerIds"
            },
            {
              "type": "address",
              "name": "owner"
            },
            {
              "type": "address",
              "name": "manager"
            },
            {
              "type": "address",
              "name": "sender"
            },
            {
              "type": "uint8",
              "name": "rewardsSplitPct"
            }
          ]
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleAdmin",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "bytes32"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMember",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "uint256",
          "name": "index"
        }
      ],
      "outputs": [
        {
          "type": "address"
        }
      ]
    },
    {
      "type": "function",
      "name": "getRoleMemberCount",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getTournament",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        }
      ],
      "outputs": [
        {
          "type": "tuple",
          "components": [
            {
              "type": "bytes32",
              "name": "tournamentId"
            },
            {
              "type": "address",
              "name": "paymentToken"
            },
            {
              "type": "address",
              "name": "creator"
            },
            {
              "type": "uint256",
              "name": "entryFee"
            },
            {
              "type": "uint256",
              "name": "registrationCloseTimestamp"
            },
            {
              "type": "uint8",
              "name": "numberOfTeams"
            },
            {
              "type": "bool",
              "name": "refunded"
            },
            {
              "type": "bool",
              "name": "rewardsClaimed"
            }
          ]
        }
      ]
    },
    {
      "type": "function",
      "name": "getTournamentPrizePool",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "getTournamentsForTeam",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_teamId"
        }
      ],
      "outputs": [
        {
          "type": "bytes32[]"
        }
      ]
    },
    {
      "type": "function",
      "name": "grantRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "hasRole",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "isTournamentFull",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "paused",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "register",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        },
        {
          "type": "uint256",
          "name": "_entryFee"
        },
        {
          "type": "uint256",
          "name": "_registrationCloseTimestamp"
        },
        {
          "type": "bytes32",
          "name": "_teamId"
        },
        {
          "type": "uint8",
          "name": "_numberOfTeams"
        },
        {
          "type": "uint8",
          "name": "_rewardsSplitPct"
        },
        {
          "type": "address[]",
          "name": "_addresses"
        },
        {
          "type": "string",
          "name": "_registeredPlayerIds"
        },
        {
          "type": "bytes",
          "name": "_signature"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "registrations",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "bytes32",
          "name": "tournamentId"
        },
        {
          "type": "bytes32",
          "name": "teamId"
        },
        {
          "type": "string",
          "name": "registeredPlayerIds"
        },
        {
          "type": "address",
          "name": "owner"
        },
        {
          "type": "address",
          "name": "manager"
        },
        {
          "type": "address",
          "name": "sender"
        },
        {
          "type": "uint8",
          "name": "rewardsSplitPct"
        }
      ]
    },
    {
      "type": "function",
      "name": "registrationsByTeamId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "registrationsByTeamIdAndTournamentId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32"
        },
        {
          "type": "bytes32"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "registrationsByTournamentId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "renounceRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "revokeRole",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "role"
        },
        {
          "type": "address",
          "name": "account"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setBeneficiary",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_beneficiary"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setBeneficiaryFee",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint8",
          "name": "_beneficiaryFeePct"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setCreatorFee",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "uint8",
          "name": "_creatorFeePct"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "setPause",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bool",
          "name": "pause"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "sponsorTournament",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "bytes32",
          "name": "_tournamentId"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "sponsorships",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "bytes32",
          "name": "tournamentId"
        },
        {
          "type": "uint256",
          "name": "amount"
        },
        {
          "type": "address",
          "name": "sender"
        }
      ]
    },
    {
      "type": "function",
      "name": "sponsorshipsBySender",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "address"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "sponsorshipsByTournamentId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32"
        },
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "supportsInterface",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes4",
          "name": "interfaceId"
        }
      ],
      "outputs": [
        {
          "type": "bool"
        }
      ]
    },
    {
      "type": "function",
      "name": "tournaments",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "uint256"
        }
      ],
      "outputs": [
        {
          "type": "bytes32",
          "name": "tournamentId"
        },
        {
          "type": "address",
          "name": "paymentToken"
        },
        {
          "type": "address",
          "name": "creator"
        },
        {
          "type": "uint256",
          "name": "entryFee"
        },
        {
          "type": "uint256",
          "name": "registrationCloseTimestamp"
        },
        {
          "type": "uint8",
          "name": "numberOfTeams"
        },
        {
          "type": "bool",
          "name": "refunded"
        },
        {
          "type": "bool",
          "name": "rewardsClaimed"
        }
      ]
    },
    {
      "type": "function",
      "name": "tournamentsByTournamentId",
      "constant": true,
      "stateMutability": "view",
      "payable": false,
      "inputs": [
        {
          "type": "bytes32"
        }
      ],
      "outputs": [
        {
          "type": "uint256"
        }
      ]
    },
    {
      "type": "function",
      "name": "withdrawERC20",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_amount"
        }
      ],
      "outputs": []
    },
    {
      "type": "function",
      "name": "withdrawNFT",
      "constant": false,
      "payable": false,
      "inputs": [
        {
          "type": "address",
          "name": "_token"
        },
        {
          "type": "uint256",
          "name": "_tokenId"
        }
      ],
      "outputs": []
    }
  ]
}