import styled, { css } from "styled-components";
import { Container } from "../Container";

export const WrapperStyled = styled.div<{ gap?: number }>(
	({ gap, theme }) => css`
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
			gap: ${gap ?? 0}px;
		}
	`
);

export const ContainerStyled = styled(Container)<{ divider?: boolean }>(
	({ divider, theme }) => css`
		flex: 1;
		flex-direction: row;
		flex-shrink: 0;

		${theme.breakpoints.up("desktopMin")} {
			${(divider ?? true) &&
			`
				&:first-child {
					border-right: 1px solid ${theme.palette.greyscale.gondola};
				}
			`}
		}
	`
);
