import { MatchResult } from "./MatchResult";
import { IdType, MetaSoccerId } from "@/id";
import { ClubLogo } from "@/shared/club";
import { EachSide } from "./EachSide";

export enum PvpRewardType {
	TrainingToken = "TrainingToken"
}

type PvpRewards = EachSide<Partial<Record<PvpRewardType, number>>>;
type PenaltiesType = EachSide<number>;

export interface FriendlyPvpMatchResult extends MatchResult {
	awayClubLogo: ClubLogo;
	awayTeamName: string;
	awayTeamId: MetaSoccerId<IdType.Team>;
	homeTeamId: MetaSoccerId<IdType.Team>;
	liveOffsetSeconds: number;
	rewards?: PvpRewards;
	penalties?: PenaltiesType;
}
