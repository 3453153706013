import { useIsMobile } from "@/layouts/context";
import {
	TournamentDetailedStandingRow,
	TournamentDivisionSimplified,
	TournamentStructureEdition
} from "@metasoccer/metasoccer-types";
import { FC, useState } from "react";
import { StandingsFilters } from "../../Standings.state";
import { FilteredStandingDesktop, FilteredStandingMobile, RewardsInfoModal } from "./components";

interface FilteredStandingProps {
	isLoadingFilteredStanding: boolean;
	tournamentStructureEditions: TournamentStructureEdition[];
	filters: StandingsFilters;
	rankedTeams?: TournamentDetailedStandingRow[];
	divisionsWithRewards: TournamentDivisionSimplified[];
	onEditionChange: (editionId: number) => () => void;
	onDivisionChange: (divisionId: string) => () => void;
	onGroupChange: (groupId: number) => () => void;
	onFilterChange: (filter: StandingsFilters) => void;
}

export const FilteredStanding: FC<FilteredStandingProps> = ({
	isLoadingFilteredStanding,
	tournamentStructureEditions,
	filters,
	rankedTeams,
	divisionsWithRewards,
	onEditionChange,
	onDivisionChange,
	onGroupChange,
	onFilterChange
}) => {
	const isMobile = useIsMobile();
	const [isOpen, setIsOpen] = useState(false);
	const toggleModal = () => setIsOpen((prevState) => !prevState);

	const teamsSorted = rankedTeams?.sort((teamA, teamB) => teamA.position - teamB.position);

	return (
		<>
			{!isMobile ? (
				<FilteredStandingDesktop
					isLoadingFilteredStanding={isLoadingFilteredStanding}
					tournamentStructureEditions={tournamentStructureEditions}
					filters={filters}
					rankedTeams={teamsSorted}
					divisionsWithRewards={divisionsWithRewards}
					onEditionChange={onEditionChange}
					onDivisionChange={onDivisionChange}
					onGroupChange={onGroupChange}
					toggleModal={toggleModal}
				/>
			) : (
				<FilteredStandingMobile
					isLoadingFilteredStanding={isLoadingFilteredStanding}
					tournamentStructureEditions={tournamentStructureEditions}
					filters={filters}
					rankedTeams={teamsSorted}
					divisionsWithRewards={divisionsWithRewards}
					onFilterChange={onFilterChange}
					toggleRewardsSheet={toggleModal}
				/>
			)}

			{isOpen && (
				<RewardsInfoModal
					isOpen={isOpen}
					onClose={toggleModal}
					divisions={divisionsWithRewards}
					selectedDivision={filters.divisionName}
				/>
			)}
		</>
	);
};
