import getBestSpecificRoleForPlayer from "@/modules/lineup/helpers/getBestSpecificRoleForPlayer";
import {
	InjuredLockedPlayer,
	LinedPlayer,
	LineupWithSkill,
	Player,
	TournamentCardFrontend
} from "@metasoccer/metasoccer-types";
import { PlayerTableItem } from "../PlayerTable";

export function playerToPlayerTableItem(
	player: Player,
	injuredPlayer?: InjuredLockedPlayer,
	lineupWithSkill?: LineupWithSkill,
	playerNotRegisteredForNextMatch?: boolean,
	sanctionedPlayerCards?: TournamentCardFrontend[],
	shirtNumber?: number
): PlayerTableItem {
	const { age, condition, id, lastName, name, overall, potential, specialAbilities, specificRole } = player;
	const playerInBenchIndex =
		lineupWithSkill?.lineup?.playersInBench?.findIndex((playerInBench: Player) => playerInBench.id === id) ?? -1;
	const playerOnThePitch: LinedPlayer = lineupWithSkill?.lineup?.linedPlayers?.find(
		(linedPlayer: LinedPlayer) => linedPlayer.player.id === id
	);

	const slotBenchTag = playerInBenchIndex >= 0 ? `B${playerInBenchIndex + 1}` : undefined;

	const slotSpecificRole =
		lineupWithSkill && playerOnThePitch
			? getBestSpecificRoleForPlayer(
					lineupWithSkill.lineup.formation.slots[playerOnThePitch.slotId].playerSpecificRoles,
					player
			  )
			: undefined;

	return {
		id,
		_player: player,
		age,
		condition,
		fullName: `${name.charAt(0)}. ${lastName}`,
		injuredPlayer: injuredPlayer,
		overall,
		playerRegisteredForNextMatch: !playerNotRegisteredForNextMatch ?? true,
		potential,
		sanctionedPlayerCards: sanctionedPlayerCards ?? [],
		shirtNumber,
		slotBenchTag,
		slotSpecificRole,
		specialAbilities,
		specificRole
	};
}
