import { CssToggleButtonGroupRoot } from "./ToggleButtonGroup.style";
import { FC } from "react";

interface ToggleButtonGroupProps {
	children: any;
}

export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({ children }) => {
	return <CssToggleButtonGroupRoot>{children}</CssToggleButtonGroupRoot>;
};
