import styled, { css } from "styled-components";

export const ContentStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: stretch;
		padding-top: 24px;
		width: 100%;

		${theme.breakpoints.up("desktopMin")} {
			height: 100%;
		}
	`
);

export const HeaderStyled = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 0 24px;
	`
);
