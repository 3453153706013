export const SCOUT_ROUTES = {
	ROOT: {
		path: "/scout",
		buildPath: () => "/scout"
	},
	PROFILE: {
		path: "/scout/:id",
		buildPath: (id: number) => `${SCOUT_ROUTES.PROFILE.path.replace(":id", id.toString())}`
	}
};
