import { Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssCardWrapper = styled.div(
	({ theme }) => css`
		flex: 1 1 0%;
		display: flex;
		flex-direction: column;
		background: ${theme.palette.backgrounds.codGray};
		cursor: pointer;
		transition-property: transform, background;
		transition-duration: var(--hover-transition-duration);
		transition-timing-function: var(--hover-transition-curve);

		${theme.breakpoints.up("desktopMin")} {
			flex-direction: row;
			align-items: center;
			padding: 12px;
		}

		@media (hover: hover) {
			&:hover {
				transform: translate3d(0, var(--hover-translate-y), 0);
				background: var(--hover-background);
			}
		}
	`
);

export const CssCardHeader = styled.div(
	({ theme }) => css`
		padding: 16px;
		display: flex;
		gap: 16px;
		border-top: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			padding: 0;
			border-top: none;
		}
	`
);

type DesktopMinWidthProps = { desktopMinWidth?: number };
export const CssCardHeaderTextWrapper = styled.div<DesktopMinWidthProps>(
	({ desktopMinWidth, theme }) => css`
		display: flex;
		gap: 4px;

		${theme.breakpoints.up("desktopMin")} {
			min-width: ${desktopMinWidth || 0}px;
		}
	`
);

export type TextColor = "hotPink" | "cornflowerBlue" | "perfume";
export const CssCardHeaderColoredText = styled(Typography)<{ color: TextColor }>(
	({ color, theme }) => css`
		color: ${theme.palette.system[color]};
	`
);

export const CssCardContent = styled.div(
	({ theme }) => css`
		padding: 12px 16px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border-top: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			padding: 0;
			flex-direction: row;
			gap: 16px;
			border-top: none;
			flex: 1 1 0%;
		}
	`
);

export const CssCardContentTextWrapper = styled.div<DesktopMinWidthProps>(
	({ desktopMinWidth, theme }) => css`
		display: flex;

		${theme.breakpoints.up("desktopMin")} {
			gap: 4px;
			min-width: ${desktopMinWidth || 0}px;
		}
	`
);

export const CssCardContentTextLabel = styled(Typography)(
	({ theme }) => css`
		flex: 1 1 0%;

		${theme.breakpoints.up("desktopMin")} {
			flex: 0 1 auto;
		}
	`
);

export const CssCardActions = styled.div(
	({ theme }) => css`
		padding: 12px 16px;
		border-top: 1px solid ${theme.palette.greyscale.gondola};

		${theme.breakpoints.up("desktopMin")} {
			padding: 0;
			border-top: none;
		}
	`
);

export const CssSeparator = styled.div.attrs<{ noGap?: boolean }>((props) => ({
	noGap: props.noGap || false
}))<{ noGap?: boolean }>(
	({ noGap, theme }) => css`
		display: none;

		${theme.breakpoints.up("desktopMin")} {
			display: block;
			width: 1px;
			height: 16px;
			background: ${theme.palette.backgrounds.tundora};
			${!noGap &&
			css`
				margin: 0 16px;
			`}
		}
	`
);
