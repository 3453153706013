export const TOURNAMENT_ROUTES = {
	ROOT: {
		path: "/tournament",
		buildPath: () => "/tournament"
	},
	COMMUNITY: {
		path: "/play",
		buildPath: () => `${TOURNAMENT_ROUTES.ROOT.path}/community`
	},
	PARTNER: {
		path: "/:partner",
		buildPath: (partner: string) => `${TOURNAMENT_ROUTES.ROOT.path}/${partner}`
	}
};
