import { FixedAndGrowLayout } from "@/modules/shared/components";
import { getImgUrl } from "@/modules/shared/utils";
import { Col, Typography } from "@metasoccer/metasoccer-ds";
import { ReactNode, memo } from "react";
import {
	IconWrapper,
	InnerBackgroundStyled,
	InnerWrapperStyled,
	LabelWrapper,
	QuantityTypography,
	WrapperStyled
} from "./Product.styles";

interface ProductProps {
	backgroundColor: string;
	callToAction: ReactNode;
	iconUrl: string;
	name: string;
	quantity?: number;
	onClick: () => void;
}

export const Product = memo(({ backgroundColor, callToAction, iconUrl, name, quantity, onClick }: ProductProps) => {
	return (
		<WrapperStyled backgroundColor={backgroundColor} onClick={onClick}>
			<InnerBackgroundStyled
				backgroundUrl={getImgUrl("https://assets.metasoccer.com/store/product_bg_pattern.svg")}
			/>
			<InnerWrapperStyled>
				<FixedAndGrowLayout gap={0} reverse>
					<LabelWrapper>{callToAction}</LabelWrapper>
					<IconWrapper>
						<Col gap={24} alignItems="center">
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									minHeight: 80
								}}>
								<img alt={name} src={iconUrl} />
							</div>
							<Col alignItems="center">
								{quantity && <QuantityTypography variant="subtitle1">{quantity}</QuantityTypography>}
								<Typography variant="subtitle1" textAlign="center">
									{name}
								</Typography>
							</Col>
						</Col>
					</IconWrapper>
				</FixedAndGrowLayout>
			</InnerWrapperStyled>
		</WrapperStyled>
	);
});
