import YouTube from "react-youtube";
import styled, { css } from "styled-components";

export const WrapperStyled = styled.div(
	() => css`
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
	`
);

export const YouTubeStyled = styled(YouTube)<{ hidden: boolean; isMobile: boolean }>`
	display: ${({ hidden }) => (hidden ? "none" : "block")};
	outline: 2px solid black !important;
	outline-offset: -1px;
	pointer-events: none;

	${({ isMobile }) =>
		isMobile
			? `
		overflow: hidden;
		position: relative;
		width: 100vw;
		height: 100vh;

		& iframe {
			height: 100vh;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			// width: 100vw;
			width: 177.78vh;
		}
	`
			: `
		position: absolute;
		inset: -128px;
	`}
`;

export const VignetteStyled = styled.div(
	() => css`
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		inset: 0;
	`
);

export const ContentStyled = styled.div(
	() => css`
		align-items: center;
		background: radial-gradient(transparent, #000 90%), url("https://lorempixel.com/1024/1024");
		background-size: cover;
		height: 100%;
		display: flex;
		padding: 24px;
		justify-content: center;
		width: 100%;
	`
);
