import { CoreButton } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CoreButtonStyled = styled(CoreButton)(() => {
	return css`
		border: none;
		padding: 0 24px;
		text-align: left;
		text-transform: none;

		&:active {
			background-color: white !important;
		}

		&:active svg path {
			fill: black;
		}
	`;
});
