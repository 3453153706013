import { ArrowRightIcon, Typography } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssLeftTrophy = styled.div(
	({ theme }) => css`
		height: 100%;
		width: 200px;
		left: 0;
		top: 0;
		position: absolute;
		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			display: none;
		}
		background: url("https://assets.metasoccer.com/pvp/join_tournament/image_custom_left.png") no-repeat;
		background-size: contain;
	`
);
export const CssRightTrophy = styled.div(
	({ theme }) => css`
		height: 100%;
		width: 200px;
		right: -33px;
		top: 0;
		position: absolute;
		background-position: right;
		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			display: none;
		}
		background: url("https://assets.metasoccer.com/pvp/join_tournament/image_custom_right.png") no-repeat;
		background-size: contain;
	`
);

export const CssPvpJoinTournamentContainer = styled("div")(
	({ theme }) => css`
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 32px 0px;
		flex: 1 1 0%;

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			width: 320px;
		}
	`
);

export const CssTitle = styled(Typography)(
	({ theme }) => css`
		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			text-align: center;
			height: 100px;
		}
	`
);

export const CssTournamentInfo = styled.div(
	({ theme }) => css`
		display: block;
		width: 320px;
		height: 100%;

		@media (max-width: ${theme.breakpoints.values.desktopMin - 1}px) {
			padding-top: 30px;
		}
	`
);

export const CssInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;

export const CssInfoEdition = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CssDate = styled(Typography)<{ variant: string }>(
	({ theme }) => css`
		margin-top: 2px;
		color: ${theme.palette.system.cornflowerBlue};
	`
);

export const CssInfo = styled(Typography)<{ variant: string }>(
	({ theme }) => css`
		text-align: center;
		color: ${theme.palette.greyscale.scorpion};
	`
);

export const CssJoin = styled("div")(
	({ theme }) => css`
		display: contents;
		align-items: center;
	`
);

export const CssJoinNow = styled(Typography)(
	() => css`
		display: flex;
		align-items: center;
		gap: 6px;
	`
);

export const CssArrowRightIcon = styled(ArrowRightIcon)(
	() => css`
		margin-left: 4px;
	`
);

export const CssJoinedWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

export const CssTooltipIconWrapper = styled.div`
	width: 16px;
	height: 16px;
`;
