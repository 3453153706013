import { getPlayerShortName } from "@/modules/player/helpers/getPlayerShortName";
import { PLAYER_ROUTES } from "@/modules/player/player.routes";
import PlayerCardTooltip from "@/modules/shared/components/PlayerCardTooltip/PlayerCardTooltip";
import { Typography } from "@metasoccer/metasoccer-ds";
import { Player } from "@metasoccer/metasoccer-types";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { LinkStyled } from "./PlayerLink.styles";

export const PlayerLink = memo<{ player: Player }>(({ player }) => {
	const navigate = useNavigate();
	return (
		<PlayerCardTooltip player={player}>
			<LinkStyled onClick={() => navigate(PLAYER_ROUTES.PROFILE.buildPath(player.id))}>
				<Typography
					variant="subtitle2"
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						flex: "1"
					}}>
					{getPlayerShortName(player.name, player.lastName)}
				</Typography>
			</LinkStyled>
		</PlayerCardTooltip>
	);
});
