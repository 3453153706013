import { persistentStorageService } from "@/dependencies";
import { useIsMobile } from "@/layouts/context";
import { useMutableServerState, useServerState } from "@/modules/core/react-query/hooks";
import { Dropdown, DropdownItem } from "@/modules/shared/components";
import { BaseServerStateKeys, SELECTED_TEAM_STORAGE_KEY } from "@/modules/shared/constants";
import { useGlobalState, useSetGlobalState } from "@/modules/shared/context";
import { TEAM_MUTABLE_QUERIES, TEAM_QUERIES } from "@/modules/team";
import { Button, ClubLogo, CrossIcon, Modal, Row, Typography } from "@metasoccer/metasoccer-ds";
import { Team } from "@metasoccer/metasoccer-types";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { CssCancelDelegationWrapper } from "./TeamSelector.styles";

export const TeamSelector = () => {
	const { address, selectedTeam } = useGlobalState();
	const setGlobalState = useSetGlobalState();
	const isMobile = useIsMobile();
	const queryClient = useQueryClient();
	const [isCancelDelagationDialogOpen, setCancelDelagationDialogOpen] = useState(false);
	const [cancelDelegationTeam, setCancelDelegationTeam] = useState<Team | undefined>(undefined);
	const { data: managedTeams } = useServerState(TEAM_QUERIES.getManagedTeams(address!), { enabled: !!address });

	const { mutateAsync: renounceDelegation } = useMutableServerState(TEAM_MUTABLE_QUERIES.renounceDelegation());

	const handleOnChange = (event: string) => {
		const findSelectedTeam = managedTeams?.find((managedTeam: Team) => managedTeam.id === event);

		if (findSelectedTeam) {
			persistentStorageService.save(SELECTED_TEAM_STORAGE_KEY, findSelectedTeam.id);
			setGlobalState((prev) => ({ ...prev, selectedTeam: findSelectedTeam }));
		}
	};

	const scrollToSelectedTeam = async () => {
		if (managedTeams && managedTeams.length > 5 && selectedTeam) {
			const teamIndex = managedTeams.map((team) => team.id).indexOf(selectedTeam.id);
			// We need to wait until the pulldown is actually pulled down
			await setTimeout(() => {}, 100);
			document.querySelector(`.TeamOption${teamIndex}`)?.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	const handleCancelDelegationClicked = (ev: any, team: Team) => {
		ev.stopPropagation();
		setCancelDelegationTeam(team);
		setCancelDelagationDialogOpen(true);
	};

	const submitRenounceDelegation = async () => {
		if (!!cancelDelegationTeam) {
			await renounceDelegation({ teamId: cancelDelegationTeam.id });
			queryClient.invalidateQueries({ queryKey: [BaseServerStateKeys.MANAGED_TEAMS] });
			setCancelDelagationDialogOpen(false);
		}
	};

	const renderDropdownItem = (team: Team) => (
		<Row alignItems="center" gap={16}>
			<ClubLogo name={team?.clubLogo.name} color={team?.clubLogo.color} url={team?.clubLogo.url} size="xs" />
			<Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
				{team.name}
			</Typography>
			{!!team.owner && !!team.manager && team.owner !== team.manager && selectedTeam!.id !== team.id && (
				<CssCancelDelegationWrapper onClick={(ev) => handleCancelDelegationClicked(ev, team)}>
					<CrossIcon className={"cancelDelegationIcon"} />
				</CssCancelDelegationWrapper>
			)}
		</Row>
	);

	return (
		<>
			<div onClick={scrollToSelectedTeam} style={{ padding: "0 24px" }}>
				{managedTeams && selectedTeam && (
					<Dropdown
						variant="form"
						placeholder="MetaSoccer"
						selectedValue={selectedTeam!.id}
						renderSelectedValue={(teamId) => (
							<Typography variant="subtitle2">
								{managedTeams.find((team) => team.id === teamId)?.name}
							</Typography>
						)}>
						{managedTeams?.map((team: Team, index) => (
							<DropdownItem key={team.id} onClick={() => handleOnChange(team.id)}>
								{renderDropdownItem(team)}
							</DropdownItem>
						))}
					</Dropdown>
				)}
			</div>
			<Modal
				isOpen={isCancelDelagationDialogOpen}
				onClose={() => {
					setCancelDelegationTeam(undefined);
					setCancelDelagationDialogOpen(false);
				}}
				title={`Cancel Delegation`}
				footerContent={<Button fullWidth label={"Confirm"} onClick={() => submitRenounceDelegation()} />}
				size="sm"
				fullScreen={isMobile}>
				<Typography variant="body2" light style={{ padding: "24px", textAlign: "center" }}>
					Are you sure you want to cancel {cancelDelegationTeam?.name} delegation? This action cannot be
					undone, only club owner will be able to redelegate again.
				</Typography>
			</Modal>
		</>
	);
};
