import { persistentStorageService } from "@/dependencies";
import { useMutableServerState } from "@/modules/core/react-query/hooks";
import { DifficultyTabs } from "@/modules/friendly-match/DifficultyTabs";
import { TeamsBoard } from "@/modules/friendly-match/TeamsBoard";
import { MATCH_ROUTES } from "@/modules/match/match.routes";
import { useGlobalState } from "@/modules/shared/context";
import { Col, Modal, Typography } from "@metasoccer/metasoccer-ds";
import { PveDifficulty } from "@metasoccer/metasoccer-types";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PLAY_MUTABLE_QUERIES } from "../../queries";
import { CssButtonWrapper, CssFriendlyMatchModalContent, CssPlayButton } from "./PlayToHaveFunPvEModal.styles";
import { ErrorModal } from "./components/ErrorModal";

export interface PlayToHaveFunPvEModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const PlayToHaveFunPvEModal: FC<PlayToHaveFunPvEModalProps> = ({ isOpen, onClose }) => {
	const navigate = useNavigate();

	const { selectedTeam } = useGlobalState();

	const [selectedDifficulty, setSelectedDifficulty] = useState<PveDifficulty>(0);
	const [showError, setShowError] = useState(false);
	console.log(selectedDifficulty, "pve difficulty");

	useEffect(() => {
		persistentStorageService.save("METASOCCER_MATCH_DIFFICULTY_" + selectedTeam?.id, selectedDifficulty.toString());
	}, [selectedDifficulty]);

	console.log(persistentStorageService, "persisten storage service");

	const handleChangeDifficulty = (difficulty: PveDifficulty) => {
		setSelectedDifficulty(difficulty);
	};

	const {
		mutateAsync: playPvEMatch,
		isPending: isLoading,
		error: playPvEMatchError
	} = useMutableServerState(PLAY_MUTABLE_QUERIES.playPvEMatch(selectedTeam?.id));

	const playMatchAgainstTeam = async () => {
		try {
			const { queue } = await playPvEMatch(selectedDifficulty);
			navigate(MATCH_ROUTES.ROOT.buildPath(queue!));
		} catch (err) {
			setShowError(true);
		}
	};

	const modalContent = () => {
		return (
			<CssFriendlyMatchModalContent>
				<DifficultyTabs onClick={handleChangeDifficulty}></DifficultyTabs>
				<TeamsBoard selectedTeam={selectedTeam!} difficulty={selectedDifficulty} />
				<CssButtonWrapper>
					<CssPlayButton loading={isLoading} onClick={playMatchAgainstTeam}>
						PLAY
					</CssPlayButton>
				</CssButtonWrapper>
				{showError && playPvEMatchError && (
					<ErrorModal title="Players unable to play" isOpen={showError} onClose={() => setShowError(false)}>
						<Col gap={16}>
							<Typography variant="body2" light textAlign="center">
								{playPvEMatchError}
							</Typography>
						</Col>
					</ErrorModal>
				)}
			</CssFriendlyMatchModalContent>
		);
	};

	return (
		<Modal title={"Play PvE Match"} isOpen={isOpen} onClose={onClose}>
			{modalContent()}
		</Modal>
	);
};
