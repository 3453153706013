import { Checkbox, Expandable, Typography } from "@metasoccer/metasoccer-ds";
import { memo, useCallback, useState } from "react";

import { DropdownItem } from "@/modules/shared/components";
import { IdType, MetaSoccerId, TournamentGroupInfoDTO } from "@metasoccer/metasoccer-types";
import { DropdownStyled, ExpandableStyled, WrapperStyled } from "./TournamentSelector.styles";

interface TournamentSelectorProps {
	selectedTournamentId?: MetaSoccerId<IdType.TournamentGroup>;
	showMyMatchesOnly: boolean;
	tournaments: TournamentGroupInfoDTO[];
	onShowMyMatchesOnlyChange: (value: boolean) => void;
	onTournamentIdSelected: (groupId: MetaSoccerId<IdType.TournamentGroup>) => void;
}

export const TournamentSelector = memo<TournamentSelectorProps>(
	({ selectedTournamentId, showMyMatchesOnly, tournaments, onShowMyMatchesOnlyChange, onTournamentIdSelected }) => {
		const [isExpanded, setIsExpanded] = useState(false);

		const renderSelectedValue = useCallback(
			(id: string) => {
				const tournament = tournaments.find((tournament) => tournament.tournamentEditionGroupId === id)!;
				return (
					<Typography variant="body2">
						{`${tournament.tournamentEdition.name} | ${tournament.name}`}
					</Typography>
				);
			},
			[tournaments]
		);

		return (
			<WrapperStyled>
				<ExpandableStyled
					open={isExpanded}
					onOpen={() => setIsExpanded(true)}
					onClose={() => setIsExpanded(false)}>
					<Expandable.Display>
						{tournaments.length > 1 ? (
							<DropdownStyled
								selectedValue={selectedTournamentId}
								renderSelectedValue={renderSelectedValue}>
								{tournaments.map((tournament) => (
									<DropdownItem
										onClick={() => onTournamentIdSelected(tournament.tournamentEditionGroupId!)}>
										<Typography variant="body2">
											{`${tournament.tournamentEdition.name} | ${tournament.name}`}
										</Typography>
									</DropdownItem>
								))}
							</DropdownStyled>
						) : (
							<Typography variant="body2">
								{`${tournaments[0].tournamentEdition.name} | ${tournaments[0].name}`}
							</Typography>
						)}
					</Expandable.Display>
					<Expandable.Body>
						<Expandable.Content>
							<Checkbox
								size="md"
								label={<Typography variant="description">Show my matches only</Typography>}
								value={showMyMatchesOnly}
								onChange={onShowMyMatchesOnlyChange}
							/>
						</Expandable.Content>
					</Expandable.Body>
				</ExpandableStyled>
			</WrapperStyled>
		);
	}
);
