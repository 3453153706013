import getMSLRankBorderColors from "@/modules/msl/utils/getMSLRankBorderColors";
import { ColumnHeader, InfoMessage, Table } from "@/modules/shared/components";
import { Typography } from "@metasoccer/metasoccer-ds";
import { TournamentDetailedStandingRow } from "@metasoccer/metasoccer-types";
import { FC, useCallback } from "react";
import { useTheme } from "styled-components";
import { CssStandingMobileWrapper, CssTableWrapper } from "./StandingMobile.styled";

interface FilteredStandingMobileProps {
	rankedTeams?: TournamentDetailedStandingRow[];
}

export const StandingMobile: FC<FilteredStandingMobileProps> = ({ rankedTeams }) => {
	const theme = useTheme();
	const borderColors = getMSLRankBorderColors(theme.palette);

	const getRowIndicatorColor = useCallback(
		(row: TournamentDetailedStandingRow) => {
			const position = row.position;
			return borderColors[position - 1];
		},
		[borderColors]
	);

	return (
		<CssStandingMobileWrapper>
			<CssTableWrapper>
				{!rankedTeams ? (
					<InfoMessage message="There are no standings to show" />
				) : (
					<Table items={rankedTeams ?? []} getRowIndicatorColor={getRowIndicatorColor}>
						{[
							{
								id: "position",
								maxSize: 48,
								header: () => (
									<Typography variant={"body2"} style={{ paddingLeft: 12 }}>
										#
									</Typography>
								),
								cell: ({ row }) => (
									<Typography variant={"body2"} light style={{ paddingLeft: 12 }}>
										{row.original.position}
									</Typography>
								)
							},
							{
								id: "team",
								meta: { tdStyle: { flexGrow: 1 }, thStyle: { flexGrow: 1 } },
								header: () => <ColumnHeader title="Team" />,
								cell: ({ row }) => (
									<Typography variant={"body2Bold"}>{row.original.clubName}</Typography>
								)
							},
							{
								id: "played",
								maxSize: 56,
								header: () => <ColumnHeader title="P" />,
								cell: ({ row }) => <Typography variant={"body2"}>{row.original.played}</Typography>
							},
							{
								id: "won",
								maxSize: 56,
								header: () => <ColumnHeader title="W" />,
								cell: ({ row }) => <Typography variant={"body2"}>{row.original.won}</Typography>
							},
							{
								id: "tied",
								maxSize: 56,
								header: () => <ColumnHeader title="T" />,
								cell: ({ row }) => <Typography variant={"body2"}>{row.original.tied}</Typography>
							},
							{
								id: "lost",
								maxSize: 56,
								header: () => <ColumnHeader title="L" />,
								cell: ({ row }) => <Typography variant={"body2"}>{row.original.lost}</Typography>
							},
							{
								id: "points",
								maxSize: 56,
								header: () => <ColumnHeader title="Pts" />,
								cell: ({ row }) => <Typography variant={"body2"}>{row.original.points}</Typography>
							}
						]}
					</Table>
				)}
			</CssTableWrapper>
		</CssStandingMobileWrapper>
	);
};
