import { CoreButton } from "@metasoccer/metasoccer-ds";
import styled, { css } from "styled-components";

export const CssFriendlyMatchModalContent = styled("div")(
	() => css`
		display: flex;
		flex-direction: column;
	`
);

export const CssButtonWrapper = styled("div")(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.greyscale.gondola};
		width: 100%;
		padding: 12px;

		${theme.breakpoints.up("desktopMin")} {
			padding: 24px;
		}
	`
);

export const CssPlayButton = styled(CoreButton)(
	() => css`
		width: 100%;
	`
);
