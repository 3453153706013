import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import { Tab } from "@peersyst/react-components";
import styled, { css } from "styled-components";
export var TabRoot = styled(Tab)(function (_ref) {
  var size = _ref.size,
    theme = _ref.theme;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t\t", ";\n\n\t\ttransition: color 300ms, background-color 300ms;\n\n\t\t&.Disabled {\n\t\t\tpointer-events: none;\n\t\t}\n\n\t\t@media (hover: hover) {\n\t\t\t&:hover {\n\t\t\t\tbackground-color: ", ";\n\t\t\t}\n\t\t}\n\t"])), sizeStyles[size], theme.palette.greyscale.gondola);
});
var lgStyles = css(function (_ref2) {
  var theme = _ref2.theme;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\tpadding: 1rem 0;\n\t\tcolor: ", ";\n\t\t", ";\n\n\t\t@media (hover: hover) {\n\t\t\t&:hover {\n\t\t\t\tcolor: ", ";\n\t\t\t}\n\t\t}\n\n\t\t&.Active,\n\t\t&:focus {\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t&.Disabled {\n\t\t\tcolor: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.scorpion, theme.typography.h4.style, theme.palette.greyscale.gray, theme.palette.greyscale.white, theme.palette.backgrounds.tundora);
});
var mdStyles = css(function (_ref3) {
  var theme = _ref3.theme;
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\tpadding: 1rem;\n\t\tcolor: ", ";\n\t\t", ";\n\n\t\t&.Active {\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t@media (hover: hover) {\n\t\t\t&:hover {\n\t\t\t\tbackground-color: ", ";\n\t\t\t\tcolor: ", ";\n\t\t\t}\n\t\t}\n\n\t\t&:active {\n\t\t\tcolor: ", ";\n\t\t\tbackground-color: transparent;\n\t\t}\n\n\t\t&.Disabled {\n\t\t\tcolor: ", ";\n\t\t}\n\t"])), theme.palette.greyscale.gray, theme.typography.buttonLg.style, theme.palette.greyscale.white, theme.palette.greyscale.gondola, theme.palette.greyscale.white, theme.palette.greyscale.white, theme.palette.greyscale.scorpion);
});
var sizeStyles = {
  lg: lgStyles,
  md: mdStyles
};