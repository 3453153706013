import { MetaSoccerId, IdType } from "..";

export enum TournamentCardType {
	RED = "Red",
	YELLOW = "Yellow"
}

export enum TournamentType {
	CUSTOM = "Custom",
	MSL = "MSL"
}

export type TournamentCard = {
	playerId: number;
	tournamentType: TournamentType;
	tournamentId: MetaSoccerId<IdType.Tournament>;
	tournamentEditionId?: MetaSoccerId<IdType.TournamentEdition>;
	type: TournamentCardType;
	expirationTimestamp: number;
	reason?: string;
};

export type TournamentCardFrontend = TournamentCard & {
	tournamentName: string;
	sanctionedGames?: number;
	maxYellowCards?: number;
};
