import { CLUB_ROUTES } from "@/modules/club/club.routes";
import { HOME_ROUTES } from "@/modules/home/home.routes";
import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { MARKET_ROUTES } from "@/modules/market/market.routes";
import { useGlobalState } from "@/modules/shared/context";
import { defineIf } from "@/modules/shared/utils";
import { SQUAD_ROUTES } from "@/modules/squad/squad.routes";
import { TRAINING_ROUTES } from "@/modules/training/training.routes";
import { ClubLogo, Mark, Row } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { FC, useEffect, useState } from "react";
import { useIsMobile, usePWA } from "../context";
import { CssContainer, CssSidebar, CssTopbar, CssWrapper } from "./ResponsiveLayout.styled";
import {
	AlphaDisclaimer,
	Maintenance,
	SidebarOption,
	TeamSelector,
	TeamsSelectorSheet,
	TopBarCoins,
	TopBarMenu,
	WalletMenu
} from "./components";

const ResponsiveLayout: FC = ({ children }) => {
	const isAdminFeaturesEnabled = useFlag(FeatureFlag.AdminFeatures);
	const isMaintenanceMode = useFlag(FeatureFlag.Maintenance);

	const isPlayToAirdropFlagEnabled = useFlag(FeatureFlag.PlayToAirdrop);
	const isPlayToAirdropEnabled = isPlayToAirdropFlagEnabled;
	const isAirdropGameplayPhase3Enabled = useFlag(FeatureFlag.PlayToAirdropGameplayPhase3);

	const { hasAirdropStarted } = useAirdrop();

	const isGameplayDisabled = !(!isPlayToAirdropEnabled || (isPlayToAirdropEnabled && hasAirdropStarted));

	const isEmbeddedMarketEnabled = useFlag(FeatureFlag.EmbeddedMarket);

	const { address, isConnected, selectedTeam } = useGlobalState();

	const hasTeam = Boolean(isConnected && selectedTeam);

	const [isOpen, setIsOpen] = useState(false);

	const isMobile = useIsMobile();

	const { safeAreaInsets } = usePWA();

	useEffect(() => {
		if (!isMobile && isOpen) {
			setIsOpen(false);
		}
	}, [isMobile, isOpen]);

	if (!isAdminFeaturesEnabled && isMaintenanceMode) {
		return <Maintenance />;
	}

	return (
		<CssWrapper>
			<CssContainer safeAreaInsets={safeAreaInsets}>
				<CssTopbar
					titleComponent={
						!isMobile && <Row alignItems={"center"}>{isGameplayDisabled ? <div /> : <TeamSelector />}</Row>
					}
					coinComponent={defineIf(address, () => (
						<Row alignItems="center">
							<TopBarCoins />
							{!isGameplayDisabled && <WalletMenu />}
						</Row>
					))}
					badge={
						selectedTeam ? (
							<div onClick={() => isMobile && hasTeam && setIsOpen(true)} style={{ padding: "0 12px" }}>
								<ClubLogo
									color={selectedTeam?.clubLogo.color ?? "white"}
									name={selectedTeam?.clubLogo.name ?? "Club1"}
									url={selectedTeam?.clubLogo?.url}
								/>
							</div>
						) : (
							<div style={{ padding: "0 12px" }}>
								<Mark style={{ width: "28px", height: "30px" }} />
							</div>
						)
					}
					showMobile={"title"}
					children
					menuComponent={<TopBarMenu />}
				/>
				<CssSidebar direction={isMobile ? "horizontal" : "vertical"}>
					<SidebarOption
						navigationType="relative"
						type="home"
						label="Home"
						route={HOME_ROUTES.ROOT.buildPath()}
					/>
					<SidebarOption
						navigationType="relative"
						disabled={isGameplayDisabled}
						type="squad"
						label="Squad"
						route={SQUAD_ROUTES.ROOT.buildPath()}
					/>
					<SidebarOption
						navigationType="relative"
						disabled={isGameplayDisabled}
						type="training"
						label="Training"
						route={TRAINING_ROUTES.ROOT}
					/>
					<SidebarOption
						navigationType="relative"
						disabled={isGameplayDisabled}
						type="club"
						label="Club"
						route={CLUB_ROUTES.ROOT.buildPath()}
					/>
					{isEmbeddedMarketEnabled ? (
						<SidebarOption
							navigationType="relative"
							disabled={isPlayToAirdropEnabled && !isAirdropGameplayPhase3Enabled}
							type="market"
							label="Market"
							route={MARKET_ROUTES.ROOT.buildLongPath()}
						/>
					) : (
						<SidebarOption type="market" label="Market" route="https://market.metasoccer.com" />
					)}
					{!isMobile && <AlphaDisclaimer />}
				</CssSidebar>

				{children}
			</CssContainer>

			{isOpen && <TeamsSelectorSheet isOpen={isOpen} onClose={() => setIsOpen(false)} />}
		</CssWrapper>
	);
};

export default ResponsiveLayout;
