import { getImgUrl } from "@/modules/shared/utils";
import { magicLink } from "@thirdweb-dev/react";
import { ConfiguredMagicLinkWallet } from "@thirdweb-dev/react/dist/declarations/src/wallet/wallets/magic/types";

export function magicWallet(): ConfiguredMagicLinkWallet {
	const base = magicLink({
		apiKey: "pk_live_FD54ECBCDFABC2C5",
		oauthOptions: { providers: ["apple", "google"] },
		smsLogin: false
	});

	return {
		...base,
		category: "walletLogin",
		meta: {
			name: "Magic (Deprecated)",
			iconURL: getImgUrl("https://assets.metasoccer.com/auth/MagicIcon.png", { size: 128 })
		},
		selectUI: (props) => {
			return base.selectUI!({ ...props, modalSize: "wide" });
		}
	};
}
