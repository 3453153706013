import { useHomeState } from "@/modules/home";
import { memo } from "react";
import { MSLButton } from "./components/MSLButton";
import { MSLPreview } from "./components/MSLPreview";

export const MSL = memo(() => {
	const { groupOfTeam, groupOfTopMSL } = useHomeState();

	if (groupOfTeam && groupOfTeam.division) {
		return <MSLPreview groupOfTeam={groupOfTeam} />;
	} else if (groupOfTopMSL) {
		return <MSLPreview groupOfTeam={groupOfTopMSL} />;
	} else {
		return <MSLButton />;
	}
});
