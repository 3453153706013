import styled from "styled-components";

export const CssOptionWrapper = styled.div`
	min-width: 124px;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 12px 20px;
	justify-content: space-between;

	&:hover {
		background-color: ${({ theme }) => theme.palette.backgrounds.tundora};
		cursor: pointer;
	}
`;
