export default function getCoveringInitials(covering) {
  switch (covering) {
    case "Defender":
      return "DF";
    case "Midfielder":
      return "MF";
    case "Forward":
      return "FW";
    case "Goalkeeper":
      return "GK";
    case "None":
      return "-";
    default:
      return covering.toUpperCase();
  }
}