import { Environment } from "../types/Environment";

export const getEnvFromDomain = (developmentDomainHostname: string, productionDomainHostname: string): Environment => {
	if (typeof window === "undefined") {
		return Environment.TEST;
	}

	const currentHostname = window.location.hostname.toLowerCase();
	if ("airdrop.metasoccer.com".toLowerCase() === currentHostname) {
		return Environment.DEV;
	}

	if ("skale.metasoccer.com".toLowerCase() === currentHostname) {
		return Environment.DEV;
	}

	if (developmentDomainHostname.toLowerCase() === currentHostname) {
		return Environment.DEV;
	}

	if (productionDomainHostname.toLowerCase() === currentHostname) {
		return Environment.PROD;
	}

	return Environment.LOCAL;
};
