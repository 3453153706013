import { IdType, MetaSoccerId } from "@/id";

export enum LockedPlayerReason {
	PlayingMatch = "PlayingMatch",
	Injured = "Injured"
}

export type BaseLockedPlayer = {
	playerId: number;
	reason: LockedPlayerReason;
	lockedTimestamp: number;
};

export type PlayingMatchLockedPlayer = BaseLockedPlayer & {
	reason: LockedPlayerReason.PlayingMatch;
	matchId: MetaSoccerId<IdType.Match | IdType.MatchScheduled>;
	startingCondition: number;
};

// Type below is for reference only whenever we need to extend this repo to support injuries and other kinds of locks
export type InjuredLockedPlayer = BaseLockedPlayer & {
	reason: LockedPlayerReason.Injured;
	injury: string;
	expirationTimestamp: number;
};

export type LockedPlayer = PlayingMatchLockedPlayer | InjuredLockedPlayer;
