import styled from "styled-components";

export const CssContent = styled.div`
	flex: 1 1 0%;
	overflow-y: auto;
	height: 100%;
`;

export const CssLoading = styled.div`
	height: 150px;
`;

export const CssTeamWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 12px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale.gondola};
	cursor: pointer;
`;

export const CssTeamContent = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;
